import React from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import VisibleKeywordsList from './VisibleKeywordsList';
import HiddenKeywordsList from './HiddenKeywordsList';
import AutoSuggestionList from './AutoSuggestionList';

function KeywordsTabBody({activeTab}) {
  return (
    <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
       {activeTab == 1 && <VisibleKeywordsList />}
       {activeTab == 2 && <HiddenKeywordsList />}
       {activeTab == 3 && <AutoSuggestionList />}
    </CustomBox>
  )
}

export default KeywordsTabBody