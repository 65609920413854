
export const chequePaymentSelected = [

  {
    label: "Credit/Debit Card (VISA / Master Card / RuPay)",
    id: 1,
    value: "credit-debit-card",
  },
  {

    label: "Net Banking (Pay using Net Banking)",
    id: 2,
    value: "net-banking",
  },
  {

    label: "American Express",
    id: 3,
    value: "american-express",
  },
  {

    label: "Cheque / Demand Draft",
    id: 4,
    value: "cheque-demand-draft",
  },
  {

    label: "NEFT / RTGS / wise Transfer",
    id: 5,
    value: "neft-rtgs-wire-transfer",
  },
  {

    label: "Paypal",
    id: 6,
    value: "paypal",
  },
  {

    label: "Pay10",
    id: 7,
    value: "pay10",
  },
  {

    label: "ZaakPay",
    id: 8,
    value: "zaakpay",
  },
  {

    label: "CCAvenue",
    id: 9,
    value: "ccavenue",
  },
];