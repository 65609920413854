import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { LeftArrow } from '../../Asset/svg';
import { Typography, useTheme } from '@mui/material';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import OrderDetailsPage from '../../Components/Order/OrderDetailsPage';
import { useDispatch } from 'react-redux';
import { setOrderDetails } from '../../redux/orderListing';
import ModelImagesPage from '../../Components/Models/ModelImagesPage';
import ShootsImagesPage from '../../Components/Image/ShootsImagesPage';

function ShootsImages() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { orderId, sno, name } = useParams();
  const [showCheckbox, setShowCheckbox] = useState(false);
  const dispatch = useDispatch();
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            cursor: 'pointer'
          }} onClick={() => {
            navigate(-1)
            dispatch(setOrderDetails({}));
            setShowCheckbox(false)
          }}>
            <LeftArrow fill={theme.palette.textButton.main} />
            <Typography variant='h5' sx={{
              marginLeft: theme.spacing(1),
              color: theme.palette.textButton.main
            }}>Back</Typography>
          </Box>
          <Typography variant='h5' sx={{ marginLeft: (theme) => theme.spacing(1) }}>{name}</Typography>
          {/* <CustomButton sx={{ padding: '12px 15px' }} color={'buttonPrimary'}>Print</CustomButton> */}
          <div style={{ paddingLeft: 30 }}></div>
          {/* <Typography */}
        </Box>
      </CustomBox>
      <Divder spacing={1} />
      <ShootsImagesPage orderId={orderId} showCheckbox={showCheckbox} setShowCheckbox={setShowCheckbox} />
      <Divder spacing={1.2} />
    </Box>
  );
}

export default ShootsImages;
