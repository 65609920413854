import { Close } from '@material-ui/icons';
import { Box, Typography } from '@mui/material';
import React from 'react';
import ApiConfig from '../../config/ApiConfig';
import CustomImage from '../CommonComp/CustomImage/CustomImage';

const OrderItem = ({ item, handleDelete, index }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: (theme) => theme.backgroundColor.box.primary,
        p: 1,
        borderRadius: 5,
        mb: 1,
        position: "relative"
      }}
    >
      <Box className="fontWeight500" alignItems={'center'} display={'flex'} flexDirection={'column'}>
          <Typography variant="p" alignItems={'center'} display={'flex'} style={{
            color: "#fff", padding: 10, flex: 1, borderRadius: 7, backgroundColor: '#404d93'}}>
              {item?.plan_type?.toLowerCase()?.includes("web")
                ? "Web Size Pack"
                : item?.plan_type?.toLowerCase()?.includes("large")
                ? "Large Size Pack"
                : item?.plan_type?.toLowerCase()?.includes("small")
                ? "Small Size Pack"
                : "Pack"}
          </Typography>
      </Box>
      <div>
        <Typography variant="p" component={'p'} pl={1}>
          Id: {item?.plan_id}
        </Typography>
        <Typography variant="p" component={'p'} pl={1}>
          Quality: {item?.plan_type || 'N/A'}
        </Typography>
        <Typography variant="p" component={'p'} pl={1}>
          Price: {item?.plan_price || 'N/A'}
        </Typography>
        <Typography variant="p" component={'p'} pl={1}>
          Max Download: {item?.maximum_downloads || 'N/A'}
        </Typography>
        <Typography variant="p" component={'p'} pl={1}>
          Validity: {item?.plan_validity || 'N/A'}
        </Typography>
      </div>
      <div onClick={()=>handleDelete()} style={{position: 'absolute', top: 4, cursor: 'pointer', right: 4, height: 20, width: 20, background: "#fff"}}>
        <Close style={{fontSize: 20}}/>
      </div>
    </Box>
  );
};

function IVOrderSummary({ ivData, item, handleDelete, index }) {
  return (
    <>
      {Object.keys(ivData || {})?.length ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <OrderItem
            item={ivData}
            handleDelete={handleDelete}
          />
        </Box>
      ) : (
        <Typography
          variant="p"
          component={'p'}
          className="fontWeight500"
          textAlign={'center'}
          py={4}
        >
          No Summary Available
        </Typography>
      )}
    </>
  );
}

export default IVOrderSummary;
