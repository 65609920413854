import React, {  } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ApiConfig from '../../config/ApiConfig';

const ProposalCheckoutSummary = ({proposalData}) => {
  return (
    <Box sx={{ height: 'auto', maxHeight: '400px', overflowY: 'auto' }}>
    {proposalData?.proposal_details?.map((data, index) => {
      return (<React.Fragment key={index}>
        {proposalData?.proposal_type == 'IV'? <Box key={index} sx={{display: 'flex', gap: '15px', marginTop: '10px', '@media only screen and (minWidth: 280px) and (maxWidth: 1024px)': { flexDirection: 'column' }}} > 
          <div className="text-center padding-6"
            style={{
              textAlign: "center",
              minWidth: 100
            }}>
            <Box className="fontWeight500" alignItems={'center'} height={'100%'}  display={'flex'} flexDirection={'column'}>
              <Typography variant="p" alignItems={'center'} display={'flex'} style={{
                  color: "#fff", padding: 10, flex: 1, borderRadius: 7, 
                  ...(data?.quality?.toLowerCase()?.includes('large')? 
                      {backgroundColor: "green"} : 
                        data?.quality?.toLowerCase()?.includes('web') ?
                          {backgroundColor: '#ba36c1'}: 
                        data?.quality?.toLowerCase()?.includes('small') ?
                          {backgroundColor: 'rgb(58 152 235)'}: 
                          {backgroundColor: '#404d93'}),
                  }}>
                  {data?.quality?.toLowerCase()?.includes("web")
                    ? "Web Size Pack"
                    : ""}
                  {data?.quality?.toLowerCase()?.includes("small")
                    ? "Small Size Pack"
                    : ""}
                  {data?.quality?.toLowerCase()?.includes("large")
                    ? "Large Size Pack"
                    : ""}</Typography>
            </Box>
          </div>
          <div>
              <Typography variant="h6"  className="boldFont"  component="div"  sx={{ marginBottom: '5px' }}>{`Images/Videos : ${data?.image_name}`}</Typography>
              <Typography variant="p" className="boldFont" component="div" sx={{ marginBottom: '5px' }}>{`Validity: ${data?.duration} Days`}</Typography>
              <Typography variant="p" className="boldFont" component="div" sx={{ marginBottom: '5px' }}>{`INR: ${data?.price}`}</Typography>
          </div>
        </Box> : <Box key={index} sx={{   display: 'flex',   gap: '15px',   marginTop: '10px',   alignItems: 'center',   '@media only screen and (minWidth: 280px) and (maxWidth: 1024px)':     {       flexDirection: 'column',     }, }} > 
          <img style={{   width: '100%',   maxWidth: '150px',   '@media only screen and (minWidth: 280px) and (maxWidth: 1024px)':     {       maxWidth: '100px',     },   height: 'auto',   borderRadius: '5px', }} src={`${ApiConfig.BASE_IMAGE_URL}${data?.shootid}/${data?.rank}-${data?.image_name}.jpg`} alt="ImageOfCartItem"/>
          <div>
              <Typography variant="h6"  className="boldFont"  component="div"  sx={{ marginBottom: '5px' }}>
              {`ID : ${data?.image_name}`}
              </Typography>
              <Typography variant="p" component="div" sx={{ marginBottom: '5px' }}>
              {`${data?.type == "2" ? "Video Type" :"Image Type"}: ${data?.quality}`}
              </Typography>
              <Typography variant="p" component="div" sx={{ marginBottom: '5px' }}>
              {`File Type: ${ data?.type == "2"? data?.quality :
                  data?.quality?.toLowerCase()?.includes('large') ||
                  data?.quality?.toLowerCase()?.includes('medium')
                  ? 'TIFF'
                  : 'JPEG'
              }`}
              </Typography>
              <Typography variant="p" component="div" sx={{ marginBottom: '5px' }}>
              {`INR: ${data?.price}`}
              </Typography>
              {data?.right == 'Exclusive' &&  <Typography variant="p" component="div" sx={{ marginBottom: '5px' }}>
              {`Duration: ${data?.duration}`}
              </Typography>}
          </div>
        </Box> }
        
      </React.Fragment>
      );
    })}
  </Box>
  )
}

export default ProposalCheckoutSummary