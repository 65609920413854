import { Typography } from '@mui/material'
import React from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import AllAdminUserList from './AllAdminUserList';

function AdminUserTabBody({activeTab}) {
  return (
    <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
       {activeTab == 1 && <AllAdminUserList />}
    </CustomBox>
  )
}

export default AdminUserTabBody