import React, { useEffect, useState } from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { commonItemsSelector } from '../../redux/common';
import { useDispatch, useSelector } from 'react-redux';
import { appSettingApi } from '../../Services/commonService';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import { DATA_LIMIT } from '../../data/constants';
import EditAppSettingDataModal from '../Modals/EditAppSettingDataModal';
import { Edit } from '@material-ui/icons';
import AddAppSettingDataModal from '../Modals/AddAppSettingDataModal';
import { currencyConversion } from '../../lib/helper';

const getInitialDataFormikVlidationSchema = (arr, key) => {
  let validations = {}
  if (arr.length) {
    arr.forEach(element => {
      validations[element[key]] = Yup.string().matches(/^[\d .]+$/, 'It should be number only.').required(`Please enter ${element[key]}`)
    });
  }
  return validations
}

const validationAddSchema = {
  key_value: Yup.string().matches(/^[\d .]+$/, 'It should be number only.').required(`This field is required.`)
}


const limit = DATA_LIMIT;

const column = [
  {
    label: 'Category',
    id: 'key_name',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Price(INR)',
    id: 'key_value',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          wordBreak: 'break-word',
        }}
   
      >
        {columnData && currencyConversion(columnData) || 'N/A'}
      </span>
    ),
  }
];

function StarsCategories({ data, initialData, parentKey }) {
  const [tableListing, setTableListing] = useState([]);
  const [selectedField, setSelectedField] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const { loading } = useSelector(commonItemsSelector);
  const dispatch = useDispatch();

  const getListing = () => {
    dispatch(appSettingApi());
  };

  //making data format for table input
  useEffect(() => {
    if (data?.length >= 0) {
      setTableListing(data || []);
    }
  }, [data]);

  const handleModal = (value = false) => {
    setOpenModal(value);
    if (!value) {
      setTimeout(() => {
        setSelectedField([]);
      }, 100);
    }
  };

  const handleAddModal = (value = false) => {
    setOpenAddModal(value);
  };


  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton toolTipTittle={'Edit Pricing'}
            onClick={() => {
              handleModal(true);
              setSelectedField([data]);
            }}
            className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Edit /></CustomButton>
        </div>
      </>
    );
  };

  return (
    <>
      <CustomBox padding={0} sx={{ minHeight: 200 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap', pt: 2, px: 2 }}>
          <Typography variant="h3">Category Pricing</Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "10px" }}>
            <CustomButton color={'buttonPrimary'} onClick={() => {
              handleAddModal(true);
              setSelectedField(null);
            }}>
              Add
            </CustomButton>
          </Box>
        </Box>
        <Divder spacing={1} />
        <MatTable
          loading={loading}
          showCheckbox={false}
          columns={column}
          data={tableListing}
          action={actionBody}
          disableSearch={true}
        />
        {openModal && <EditAppSettingDataModal open={openModal} handleOpen={handleModal} extraData={initialData} subParentKey={'category'} jsonParsing={true}
          data={selectedField} parentKey={parentKey} getListing={getListing} headerTitle={'Edit Pricing'} getInitialDataFormikVlidationSchema={getInitialDataFormikVlidationSchema} />}
        {openAddModal && <AddAppSettingDataModal open={openAddModal} handleOpen={handleAddModal} extraData={initialData} subParentKey={'category'}
          jsonParsing={true} parentKey={parentKey} getListing={getListing} headerTitle={'Add Pricing'} validationAddSchema={validationAddSchema}
          keyLabelOne={'Category'} keyLabelTwo={'Price'}
        />}
      </CustomBox>
    </>
  );
}
export default StarsCategories;
