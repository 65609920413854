import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { formatDate, handleFileReader } from '../../lib/helper';
import { fileUpload } from '../../Services/commonService';
import { uploadWeeklyImages } from '../../Services/DashboardApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

export default function WeeklyImageModal({ open, handleOpen }) {
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState([]);

  const handleFileUpload = async(values, { resetForm })=>{
    try{
      setLoading(true)
      let uploadedUrls = {
        zipFile: '',
        imageFile: '',
      }
      for await (const [key, value] of Object.entries(values)) {
        if(key != 'weeklyValidity'){
          const formData = new FormData();
          formData.append("uploaded_file", value);
          let data = await fileUpload(formData,  {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          uploadedUrls[key] = data.URL
        }
      }
      if(uploadedUrls.zipFile && uploadedUrls.imageFile){
        let payload = {
          weekly_image_name: uploadedUrls.imageFile,
          weekly_image_validity: values.weeklyValidity,
          weekly_zip_url: uploadedUrls.zipFile,
        }
        let resp = await uploadWeeklyImages(payload)
        toast.success(resp.message)
        handleOpen(false);
        setLoading(false)
        resetForm({
          zipFile: '',
          imageFile: '',
          weeklyValidity: '',
        });
      }
      
    }catch(e){
      setLoading(false)
      console.log(e, 'error')
    }
  }

  const formik = useFormik({
    initialValues: {
      zipFile: '',
      imageFile: '',
      weeklyValidity: '',
    },
    validationSchema: Yup.object({
      zipFile: Yup.mixed()
        .required('Zip file is required.')
        .test('fileType', "Unsupported File Format. It should be ZIP file only.", value => ['application/zip', 'application/x-zip-compressed'].includes(value?.type)),
      imageFile: Yup.mixed()
        .required('Thumbnail file is required.')
        .test('fileType', "Unsupported File Format. It should be Image(.jpeg) file only.", value => ['image/jpeg'].includes(value?.type)),
      weeklyValidity: Yup.date()
        .min(new Date(), 'Date should be greater then today date.')
        .required('Please select validity of the file.'),
    }),
    onSubmit: handleFileUpload,
  });


  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle>Upload Weekly Image Files</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select Zip and Thumbnail to upload.
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="weeklyZipFile"
                  label="Zip File"
                  placeholder="Zip File"
									type="file"
                  // value={formik.values.order_status}
                  onChange={(e) => {
                    formik.setFieldValue('zipFile', e.target.files[0]);
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.zipFile &&
                    Boolean(formik.errors.zipFile)
                  }
                  helperText={
                    formik.touched.zipFile && formik.errors.zipFile
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="weeklyThumb"
                  label="Thumbnail"
                  placeholder="Thumbnail"
									type="file"
                  // value={formik.values.order_status}
                  onChange={(e) => {
                    formik.setFieldValue('imageFile', e.target.files[0]);
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.imageFile &&
                    Boolean(formik.errors.imageFile)
                  }
                  helperText={
                    formik.touched.imageFile && formik.errors.imageFile
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="weeklyValidity"
                  label="Zip Validity"
                  placeholder="Zip Validity"
									type="date"
                  // value={formik.values.order_status}
                  onChange={(e) => {
                    formik.setFieldValue('weeklyValidity', formatDate(e.target.value, 'iso'));
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.weeklyValidity &&
                    Boolean(formik.errors.weeklyValidity)
                  }
                  helperText={
                    formik.touched.weeklyValidity && formik.errors.weeklyValidity
                  }
                />
              </Grid>
            </Grid>
            <div style={{display: "flex", justifyContent: "end", marginTop: 15}}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Upload</CustomButton>
              <CustomButton onClick={() => handleOpen(false)} disabled={loading}>Close</CustomButton>
            </div>
          </Box>
        </DialogContent>
        {/* <DialogActions>
					<CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Upload</CustomButton>
					<CustomButton onClick={() => handleOpen(false)}>Close</CustomButton>
        </DialogActions> */}
      </Dialog>
    </>
  );
}
