import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { addVisibleKeyword } from '../../Services/keywordsApis';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput'
//'../CommonComp/CustomInput/CustomPhoneInput';
import {
  getCountry,
  getJobDescription,
  getState,
} from '../../Services/commonService';
import {
  ContributorBankDetailUpdate,
  contributorBankAndOtherInfo,
  createContributor,
  editContributor,
} from '../../Services/ContributorApis';
import { commonItemsSelector } from '../../redux/common';
import { useDispatch, useSelector } from 'react-redux';
import { contributorItemsSelector } from '../../redux/contributor';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { useParams } from 'react-router-dom';
import ConfirmationModal from '../Modals/ConfirmationModal';
import Divder from '../CommonComp/Divder';
const limit = 10;

function ContributorBankDetail({ open, handleOpen, getListing }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { contributorId } = useParams();
  const { countryData, stateData } = useSelector(commonItemsSelector);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [countryShortName, setCountryShortName] = useState('in');
  const [selectedCountry, setSelcetedCountry] = useState('');
  const [selectedState, setSelectedState] = useState({});
  const { contributorBasicInfo } = useSelector(contributorItemsSelector);
  const [confirmSave, setConfirmSave] = useState(false);
  const selectedData = contributorBasicInfo?.items?.contributorbank;
  const handleConfirm = async () => {
    setLoading(true);
    const updatedValues = {
      ...formik.values,
      contributor_id: contributorId,
    };
    let resp = await ContributorBankDetailUpdate(updatedValues);

    ContributorDataFetch();
    setLoading(false);
  };
  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  }, [stateData]);
  useEffect(() => {
    if (countryData && Array.isArray(countryData?.items)) {
      let data = countryData?.items?.map((item) => {
        return {
          label: item?.country_name,
          value: item?.country_id,
        };
      });
      setCountryList(data);
    }
  }, [countryData]);
  useEffect(() => {
    getCountryDetails();
  }, []);

  const getCountryDetails = async () => {
    dispatch(getCountry());
  };

  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobile', val);
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmSave(false);
    setLoading(false);
  }

  const handleChangeCountry = async (e) => {
    formik.setFieldValue('country', e.target.value);
    formik.setFieldValue('state', '');
    setSelcetedCountry(e.target.value || '');
    setSelectedState({});
    let country_id = 0;
    countryData?.items?.filter((val) => {
      if (val.country_name === e.target.value) {
        country_id = val.country_id;
        setCountryShortName(val?.country_short_name?.toLowerCase());
        formik.setFieldValue('mobile', '');
      }
    });
    dispatch(getState(country_id));
  };

  const handleStateChange = (val) => {
    let stateSelected = stateData?.items?.filter(
      (item) => item.state_name == val,
    );

    if (stateSelected.length) {
      setSelectedState(stateSelected[0]);
    }
  };
  const getStateData = async (user) => {
    if (user?.items?.countries) {
      dispatch(getState(user?.items?.countries?.country_id));
    }
  };
  const showModel = () => {
    setConfirmSave(true);
  }
  const formik = useFormik({
    initialValues: {
      pan_number: selectedData?.txt_pan_no || '',
      benf_name: selectedData?.txt_benf_name || '',
      address: selectedData?.txt_address || '',
      city: selectedData?.txt_city || '',
      // country: selectedData?.country || '',
      bank_name: selectedData?.txt_bank_name || '',
      mobile: selectedData?.txt_mobile || '',
      accnumber: selectedData?.txt_accno || '',
      email: selectedData?.Emailid || '',
      neft_code: selectedData?.txt_neft_code || ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      pan_number: Yup.string()
        .required('Please enter PAN No.')
        .length(10)
        .matches('[A-Z]{5}[0-9]{4}[A-Z]{1}', 'Enter Valid PAN Number'),
      benf_name: Yup.string().required(' Beneficiary Name is required'),
      address: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      mobile: Yup.string()
        .max(18, 'Mobile Number is Invalid')
        .min(10, 'Mobile Number is Invalid')
        .required('Mobile Number is required'),
      accnumber: Yup.string()
        .required('Account No is required')
        .matches('^[0-9]{9,18}$', 'Enter Valid Account Number'),
      neft_code: Yup.string().required('NEFT code is required'),
      bank_name: Yup.string()
        .required('Bank Name is required'),
      email: Yup.string()
        .email('Please enter Email')
        .required('Please enter Email'),

    }),
    onSubmit: showModel,
  });

  const ContributorDataFetch = async () => {
    if (contributorId) {
      let resp = await dispatch(contributorBankAndOtherInfo({ contributor_id: contributorId }));
    }
  }

  const handleFormVal = (val) => {
    if (val) {
      handleConfirm();
    }
  }

  return (
    <div>
      <CustomBox>

        <Box
          noValidate
          component="form"
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="pan_number"
                label="PAN No"
                placeholder="PAN No"
                value={formik.values.pan_number}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.pan_number && Boolean(formik.errors.pan_number)}
                helperText={formik.touched.pan_number && formik.errors.pan_number}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="benf_name"
                label="Beneficiary Name"
                placeholder="Beneficiary Name"
                value={formik.values.benf_name}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.benf_name && Boolean(formik.errors.benf_name)}
                helperText={formik.touched.benf_name && formik.errors.benf_name}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="address"
                label="Address"
                placeholder="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={
                  formik.touched.address && Boolean(formik.errors.address)
                }
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="city"
                label="City"
                placeholder="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomPhoneInput
                label="Mobile"
                id="mobile"
                name="mobile"
                className="main-login-form"
                fullWidth
                placeholder="Mobile Number"
                native={true}
                value={formik.values.mobile}
                defaultCountry={`${countryShortName}`}
                country={`${countryShortName}`}
                divStyle={{
                  paddingTop: 4,
                }}
                variant="outlined"
                onChange={(newValue, info) => {
                  handleChangeMobile(newValue);
                }}
                onlyCountries={[countryShortName]}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="bank_name"
                label="Bank Name"
                placeholder="Bank Name"
                value={formik.values.bank_name}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={
                  formik.touched.bank_name &&
                  Boolean(formik.errors.bank_name)
                }
                helperText={
                  formik.touched.bank_name && formik.errors.bank_name
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="accnumber"
                label="Account No"
                placeholder="Account No"
                value={formik.values.accnumber}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={
                  formik.touched.accnumber &&
                  Boolean(formik.errors.accnumber)
                }
                helperText={
                  formik.touched.accnumber && formik.errors.accnumber
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="neft_code"
                label="NEFT Code"
                placeholder="NEFT Code"
                value={formik.values.neft_code}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={
                  formik.touched.neft_code &&
                  Boolean(formik.errors.neft_code)
                }
                helperText={
                  formik.touched.neft_code && formik.errors.neft_code
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="email"
                label="Email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          </Grid>
          <div
            style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
          >
            <CustomButton
              color={'buttonPrimary'}
              type="submit"
              loading={loading}
            >
              Confirm
            </CustomButton>

          </div>
        </Box>
      </CustomBox>
      {confirmSave && <ConfirmationModal open={confirmSave} handleConfirm={handleFormVal} setOpen={handleCofirmModalOpen} />}
    </div>
  );
}

export default ContributorBankDetail;
