import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  categoryList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },

  starCategoryList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },

  subCategoryList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  videoCategoryList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCategoryListingLoading: (state, action) => {
      state.categoryList.loading = action.payload
    },
    setCategoryListing: (state, action) => {
      state.categoryList.items = action.payload;
      state.categoryList.error = {
        isError: false,
        message: '',
      };
    },
    setCategoryListingError: (state, action) => {
      state.categoryList.items = {};
      state.categoryList.error = action.payload;
    },


    setStarCategoryListing: (state, action) => {
      state.starCategoryList.items = action.payload;
      state.starCategoryList.error = {
        isError: false,
        message: '',
      };
    },
    setStarCategoryListingError: (state, action) => {
      state.starCategoryList.items = {};
      state.starCategoryList.error = action.payload;
    },
    setStarCategoryListingLoading: (state, action) => {
      state.starCategoryList.loading = action.payload
    },
    setSubCategoryListingLoading: (state, action) => {
      state.subCategoryList.loading = action.payload
    },
    setSubCategoryListing: (state, action) => {
      state.subCategoryList.items = action.payload;
      state.subCategoryList.error = {
        isError: false,
        message: '',
      };
    },
    setSubCategoryListingError: (state, action) => {
      state.subCategoryList.items = {};
      state.subCategoryList.error = action.payload;
    },
    setVideoCategoryListingLoading: (state, action) => {
      state.videoCategoryList.loading = action.payload
    },
    setVideoCategoryListing: (state, action) => {
      state.videoCategoryList.items = action.payload;
      state.videoCategoryList.error = {
        isError: false,
        message: '',
      };
    },
    setVideoCategoryListingError: (state, action) => {
      state.videoCategoryList.items = {};
      state.videoCategoryList.error = action.payload;
    },
  },
});

export default categorySlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setCategoryListing,
  setCategoryListingError,
  setCategoryListingLoading,
  setSubCategoryListing,
  setSubCategoryListingError,
  setSubCategoryListingLoading,
  setVideoCategoryListing,
  setVideoCategoryListingLoading,
  setVideoCategoryListingError,
  setStarCategoryListingLoading,
  setStarCategoryListingError,
  setStarCategoryListing
} = categorySlice.actions;

export const categoryItemsSelector = (state) => state.category;
