import { ReactComponent as AppLogo } from './logo.svg';
import { ReactComponent as AppLogoNew } from './ib_logo.svg';
// import {ReactComponent as Group} from './Group (6).svg';
import { ReactComponent as ActiveFilter } from './Active-filter.svg';
import { ReactComponent as InactiveFilter } from './inactive-filter.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as DashBoardIcon } from './dashboard-icon.svg';
import { ReactComponent as UserIcon } from './user-icon.svg';
import { ReactComponent as MasterIcon } from './master-icon.svg';
import { ReactComponent as PhotographerIcon } from './photographer-icon.svg';
import { ReactComponent as KeywordIcon } from './keyword-icon.svg';
import { ReactComponent as ImageIcon } from './image-icon.svg';
import { ReactComponent as OrderIcon } from './order-icon.svg';
import { ReactComponent as LogsIcon } from './logs-icon.svg';
import { ReactComponent as CRMIcon } from './crm-icon.svg';
import { ReactComponent as ReportsIcon } from './reports-icon.svg';
import { ReactComponent as MailIcon } from './mail-icon.svg';
import { ReactComponent as LoginLock } from './login-lock.svg';
import { ReactComponent as LoginEye } from './visibility-on.svg';
import { ReactComponent as LoginEyeOff } from './visibility-off.svg';
import { ReactComponent as GreenCircleIcon } from './green-circle.svg';
import { ReactComponent as LeftArrow } from './left-arrow.svg';
import { ReactComponent as Settings } from './setting.svg';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as CategoryIcon } from './category.svg';
import { ReactComponent as CompanyIcon } from './company.svg';
import { ReactComponent as StarIndustriesIcon } from './industries.svg';
import { People, VideoCameraBack } from '@mui/icons-material';

export {
  AppLogo,
  AppLogoNew,
  // Group,
  ActiveFilter,
  InactiveFilter,
  Delete,
  DashBoardIcon,
  UserIcon,
  MasterIcon,
  PhotographerIcon,
  KeywordIcon,
  ImageIcon,
  OrderIcon,
  LogsIcon,
  CRMIcon,
  ReportsIcon,
  MailIcon,
  LoginLock,
  LoginEye,
  LoginEyeOff,
  GreenCircleIcon,
  LeftArrow,
  Settings,
  Send,
  CategoryIcon,
  CompanyIcon,
  StarIndustriesIcon,
  VideoCameraBack, 
  People
};
