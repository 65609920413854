import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { deleteApiPayload, errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setBuyerUserListing,
  setBuyerUserListingError,
  setLoading,
  setSingleUserCartDetails,
  setSingleUserCartDetailsError,
  setSingleUserDetails,
  setSingleUserDetailsError,
  setSingleUserProposalDetails,
  setSingleUserProposalDetailsError,
  setSingleUserWishlistDetails,
  setSingleUserWishlistDetailsError,
  setTemporaryUserListing,
  setTemporaryUserListingError,
  setBuyerUserListingLoading,
  setTemporaryUserListingLoading,
  setSingleUserDetailsLoading,
  setSingleUserCartDetailsLoading,
  setSingleUserWishlistDetailsLoading,
  setSingleUserProposalDetailsLoading,
  setAllUserListingLoading,
  setAllUserListing,
  setAllUserListingError,
  setSingleUserOutstandingDetailsLoading,
  setSingleUserOutstandingDetails,
  setSingleUserOutstandingDetailsError,
  setSingleUserActiveIvsOrderDetailsLoading,
  setSingleUserActiveIvsOrderDetails,
  setSingleUserActiveIvsOrderDetailsError,
  setSingleUserImageDownloadDetailsLoading,
  setSingleUserImageDownloadDetails,
  setSingleUserImageDownloadDetailsError,
  setSingleUserPrivillegeImageDownloadDetailsLoading,
  setSingleUserPrivillegeImageDownloadDetailsError,
  setSingleUserPrivillegeImageDownloadDetails,
} from '../redux/user';

export const allUserListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllUserListingLoading(true));
        const resp = await axios.post(`${ApiConfig.adminUsersList}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllUserListing(resp.data.data));
          dispatch(setAllUserListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) { 
        dispatch(
          setAllUserListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllUserListingLoading(false));
      }
    };
  }
};

export const allUserListingExportFile = async(payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.adminUsersList}`, payload, {
        responseType: 'arraybuffer'
      });
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        return resp
      } else {
        throw resp;
      }
    } catch (e) { 
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const buyerUserListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setBuyerUserListingLoading(true));
        const resp = await axios.post(`${ApiConfig.adminUsersList}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setBuyerUserListing(resp.data.data));
          dispatch(setBuyerUserListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setBuyerUserListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setBuyerUserListingLoading(false));
      }
    };
  }
};

export const temporaryUserListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setTemporaryUserListingLoading(true));
        const resp = await axios.post(`${ApiConfig.adminUsersList}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setTemporaryUserListing(resp.data.data));
          dispatch(setTemporaryUserListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setTemporaryUserListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setTemporaryUserListingLoading(false));
      }
    };
  }
};

export const userDetailsApi = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleUserDetailsLoading(true));
        const resp = await axios.post(`${ApiConfig.userListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleUserDetails(resp?.data?.data?.users[0]));
          dispatch(setSingleUserDetailsLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleUserDetailsError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleUserDetailsLoading(false));
      }
    };
  }
};

export const userDetailsApiNoRedux =async(payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.userListing}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp?.data?.data?.users[0]
      } else {
        throw resp
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return null
    }
  }
};

export const userWishlistApi = (userId, payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleUserWishlistDetailsLoading(true));
        const resp = await axios.post(
          `${ApiConfig.userWishList}/${userId}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleUserWishlistDetails(resp?.data?.data));
          dispatch(setSingleUserWishlistDetailsLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleUserWishlistDetailsError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleUserWishlistDetailsLoading(false));
      }
    };
  }
};

export const userDownloadImagelistApi = (userId, payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleUserImageDownloadDetailsLoading(true));
        const resp = await axios.post(
          `${ApiConfig.userDownloadHistory}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleUserImageDownloadDetails(resp?.data?.data));
          dispatch(setSingleUserImageDownloadDetailsLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleUserImageDownloadDetailsError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleUserImageDownloadDetailsLoading
          (false));
      }
    };
  }
};

export const privllegeUserDownloadImagelistApi = (userId, payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleUserPrivillegeImageDownloadDetailsLoading(true));
        const resp = await axios.post(
          `${ApiConfig.privilegeUserImage}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleUserPrivillegeImageDownloadDetails(resp?.data?.data));
          dispatch(setSingleUserPrivillegeImageDownloadDetailsLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleUserPrivillegeImageDownloadDetailsError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleUserPrivillegeImageDownloadDetailsLoading
          (false));
      }
    };
  }
};

export const upgradeImage = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.upgradeImage}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const upgradeImagePrice = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.orderTypeById}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        // toast.success(resp.data?.message, {
        //   autoClose: 2500,
        // });
        return resp?.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};


export const userOutstandingDetailApi = (userId, payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleUserOutstandingDetailsLoading(true));
        const resp = await axios.post(
          `${ApiConfig.listAdminOrders}`, payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleUserOutstandingDetails(resp?.data?.data));
          dispatch(setSingleUserOutstandingDetailsLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleUserOutstandingDetailsError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleUserOutstandingDetailsLoading(false));
      }
    };
  }
};

export const userCartsApi = (userId, payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleUserCartDetailsLoading(true));
        const resp = await axios.post(
          `${ApiConfig.userCart}/${userId}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleUserCartDetails(resp?.data?.data));
          dispatch(setSingleUserCartDetailsLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleUserCartDetailsError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleUserCartDetailsLoading(false));
      }
    };
  }
};
export const SingleUserCartList = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleUserCartDetailsLoading(true));
        const resp = await axios.post(
          `${ApiConfig.singleUserCart}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleUserCartDetails(resp?.data?.data));
          dispatch(setSingleUserCartDetailsLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleUserCartDetailsError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleUserCartDetailsLoading(false));
      }
    };
  }
};
export const userProposalApi = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleUserProposalDetailsLoading(true));
        const resp = await axios.post(`${ApiConfig.proposalListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSingleUserProposalDetails(resp?.data?.data));
          dispatch(setSingleUserProposalDetailsLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleUserProposalDetailsError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleUserProposalDetailsLoading(false));
      }
    };
  }
};

export const updateUser = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.updateUser}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return resp;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return e;
    }
  }
};

export const deleteFromWishlist = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.userWishList}`,
        deleteApiPayload(payload),
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteFromCartlist = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.userCart}`,
        deleteApiPayload(payload),
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const createProposalApi = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createProposal}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return resp.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const proposalDetailApi = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(`${ApiConfig.proposalDetails}?proposal_id=${payload?.proposal_id}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const addToCartFromAdmin = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.addToCartAdmin}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const addToWishlistFromAdmin = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.addToWishListAdmin}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};


export const getUserDatalist = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(`${ApiConfig.userDatalist}/${payload?.user_name}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const getSubscribedOrdersUser = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSingleUserActiveIvsOrderDetailsLoading(true));
        const resp = await axios.post(`${ApiConfig.subscribedOrdersAdmin}`,payload);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          dispatch(setSingleUserActiveIvsOrderDetails(resp?.data?.data));
          dispatch(setSingleUserActiveIvsOrderDetailsLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSingleUserActiveIvsOrderDetailsError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSingleUserActiveIvsOrderDetailsLoading(false));
      }
    };
  }
};

export const getSubscribedOrdersUserNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(`${ApiConfig.subscribedActiveOrdersAdmin}?user_id=${payload}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const downloadImageAdminUser = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.downloadImageAdmin}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return resp.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteUser = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.delete(`${ApiConfig.deleteUser}`, deleteApiPayload(payload));
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const isUserPrivilleged= async(payload) => {
  try{
    const resp=await axios.post(`${ApiConfig.isPrivillegeUser}`, payload);
    if(resp?.status>=STATUS_200 && resp?.status<STATUS_300){
       return resp?.data;
    }
  }
  catch(e){
    toast.error(errorMessage(e),{autoClose:2500})
  }

};