import { FormControl, FormHelperText } from '@mui/material';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './editor.css';

function TextEditor({ content, modules, formats, handleChange, error, helperText }) {
  const handleProcedureContentChange = (content, delta, source, editor) => {
    handleChange(content, delta, source, editor)
    //let has_attribues = delta.ops[1].attributes || "";
    //console.log(has_attribues);
    //const cursorPosition = e.quill.getSelection().index;
    // this.quill.insertText(cursorPosition, "★");
    //this.quill.setSelection(cursorPosition + 1);
  };
  return (
    <>
    <FormControl
        required
        error={error}
        sx={{
          width: '100%'
        }}
        component="fieldset"
        variant="standard"
      >
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={content}
        onChange={handleProcedureContentChange}
      />
       {(helperText && error) ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </>
  );
}

export default TextEditor

TextEditor.defaultProps = {
  content: '',
  handleChange: ()=>{},
  modules: {
    toolbar: [
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      // [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: ["red", "#785412", '#000'] }],
      [{ background: ["red", "#785412", '#fff'] }]
    ]
  },
  formats : [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font"
  ],
}