import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { DATA_LIMIT } from '../../data/constants';
import { editImagePrice } from '../../Services/cmsService';

const limit = DATA_LIMIT;

function EditImagePrice({ data, open, handleOpen, setPage, handleLast }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = await editImagePrice(values)
    if (resp) {
      resetForm({
        image_type_id: "",
        size: "",
        price: "",
        status: "",
        image_type: "",
        Dimensions: "",
        file_format: "",
        resolution: "",
      })
      handleLast();
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      image_type_id: data?.image_type_id || "",
      size: data?.size || "",
      price: data?.price || "",
      image_type: data?.image_type || "",
      Dimensions: data?.Dimensions || "",
      status: data?.status || "",
      file_format: data?.file_format || "",
      resolution: data?.resolution || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      image_type_id: Yup.string().required('Id is required.'),
      image_type: Yup.string().required('Image Type is required.'),
      price: Yup.string().required('Plan price is required.').matches(/^[0-9]+$/, "Must be only digits"),
      resolution: Yup.string().required('Resolution is required.'),
      file_format: Yup.string().required('File Format is required.'),
      size: Yup.string().required('Size is required.'),
      Dimensions: Yup.string().required('Dimensions is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Edit Image
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="image_type"
                  label="Image Type"
                  placeholder="Image Type"
                  value={formik.values.image_type}
                  select
                  SelectProps={{
                    native: true
                  }}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.image_type && Boolean(formik.errors.image_type)}
                  helperText={formik.touched.image_type && formik.errors.image_type}
                >
                  <option key={'WEB'} value={"WEB"}>WEB</option>
                  <option key={'LARGE'} value={"LARGE"}>LARGE</option>
                  <option key={'MEDIUM'} value={"MEDIUM"}>MEDIUM</option>
                  <option key={'SMALL'} value={"SMALL"}>SMALL</option>
                </CustomInput>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="Dimensions"
                  label="Dimensions"
                  placeholder="Dimensions"
                  value={formik.values.Dimensions}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.Dimensions && Boolean(formik.errors.Dimensions)}
                  helperText={formik.touched.Dimensions && formik.errors.Dimensions}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="file_format"
                  label="File Format"
                  placeholder="File Format"
                  value={formik.values.file_format}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.file_format && Boolean(formik.errors.file_format)}
                  helperText={formik.touched.file_format && formik.errors.file_format}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="price"
                  label="Price"
                  placeholder="Price"
                  value={formik.values.price }
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="resolution"
                  label="Resolution"
                  placeholder="Resolution"
                  value={formik.values.resolution}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.resolution && Boolean(formik.errors.resolution)}
                  helperText={formik.touched.resolution && formik.errors.resolution}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="size"
                  label="size"
                  placeholder="size"
                  value={formik.values.size}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.size && Boolean(formik.errors.size)}
                  helperText={formik.touched.size && formik.errors.size}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="status"
                  label="Status"
                  placeholder="Status"
                  value={formik.values.status}
                  select
                  SelectProps={{
                    native: true
                  }}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                >
                  <option key={'0'} value={"0"}>Inactive</option>
                  <option key={'1'} value={"1"}>Active</option>
                </CustomInput>
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    image_type_id: "",
                    size: "",
                    price: "",
                    image_type: "",
                    Dimensions: "",
                    file_format: "",
                    resolution: "",
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditImagePrice;
