import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { deleteApiPayload, errorMessage } from '../lib/helper';
import { DATA_LIMIT, STATUS_200, STATUS_300 } from '../data/constants';
import {
  setAllImagesListing,
  setAllImagesListingError,
  setLoading,
  setSoldImagesByGroupIdsListing,
  setSoldImagesByGroupIdsListingError,
  setSoldImagesByGroupListing,
  setSoldImagesByGroupListingError,
  setSoldImagesListing,
  setSoldImagesListingError,
  setSuspendedImagesListing,
  setSuspendedImagesListingError,
  setAllImagesListingLoading,
  setSuspendedImagesListingLoading,
  setSoldImagesListingLoading,
  setSoldImagesByGroupListingLoading,
  setSoldImagesByGroupIdsListingLoading,
  setSoldImagesPendingListingLoading,
  setSoldImagesPendingListing,
  setSoldImagesPendingListingError,
  setStarImagesListingLoading,
  setStarImagesListing,
  setStarImagesListingError,
  setAllImagesNewListing,
  setAllImagesNewListingError,
  setAllImagesNewListingLoading,
  setAllShootListing,
  setAllShootListingLoading,
  setModelShootImageListingLoading,
  setModelShootImageListingError,
  setModelShootImageListing,
  setShootImageIDListingLoading,
  setShootImageIDListingError,
  setShootImageIDListing,
  setSoldImagesDescListing,
  setSoldImagesDescListingLoading,
  setSoldImagesDescListingError,
  setAllImagesViewMostListingLoading,
  setAllImagesViewMostListingError,
  setAllImagesViewMostListing
} from '../redux/image';

export const imageListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllImagesListingLoading(true));
        const resp = await axios.post(`${ApiConfig.imageListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllImagesListing(resp.data.data));
          dispatch(setAllImagesListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllImagesListingLoading(false));
      }
    };
  }
};

export const imageListingsViewMost = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllImagesListingLoading(true));
        const resp = await axios.post(`${ApiConfig.imageListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllImagesViewMostListing(resp.data.data));
          dispatch(setAllImagesViewMostListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllImagesViewMostListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllImagesViewMostListingLoading(false));
      }
    };
  }
};

export const imageListingsNew = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllImagesNewListingLoading(true));
        const resp = await axios.post(`${ApiConfig.imageListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllImagesNewListing(resp.data.data));
          dispatch(setAllImagesNewListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllImagesNewListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllImagesNewListingLoading(false));
      }
    };
  }
};



export const imageStartListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setStarImagesListingLoading(true));
        const resp = await axios.post(`${ApiConfig.imageListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setStarImagesListing(resp.data.data));
          dispatch(setStarImagesListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setStarImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setStarImagesListingLoading(false));
      }
    };
  }
};

export const suspendedImageListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSuspendedImagesListingLoading(true));
        const resp = await axios.post(`${ApiConfig.imageListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSuspendedImagesListing(resp.data.data));
          dispatch(setSuspendedImagesListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSuspendedImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSuspendedImagesListingLoading(false));
      }
    };
  }
};

export const soldImageListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSoldImagesListingLoading(true));
        const resp = await axios.post(`${ApiConfig.listOfSoldImages}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSoldImagesListing(resp.data.data));
          dispatch(setSoldImagesListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSoldImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSoldImagesListingLoading(false));
      }
    };
  }
};

export const soldImageDescListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSoldImagesDescListingLoading(true));
        const resp = await axios.post(`${ApiConfig.imageListSoldCount}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSoldImagesDescListing(resp.data.data));
          dispatch(setSoldImagesDescListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSoldImagesDescListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSoldImagesDescListingLoading(false));
      }
    };
  }
};


export const getSoldImagesReport = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.imageListSoldCount}`, payload, { responseType: 'blob' });
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        return { status: true, data: resp?.data }
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return { status: false, data: null }
    }
  }
};

//imageListSoldCount

export const exclusiveImageData = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSoldImagesListingLoading(true));
        const resp = await axios.post(`${ApiConfig.listOfSoldImages}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSoldImagesListing(resp.data.data));
          dispatch(setSoldImagesListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSoldImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSoldImagesListingLoading(false));
      }
    };
  }
};

export const soldImagesByGroupListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSoldImagesByGroupListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.listOfImagesByGroup}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSoldImagesByGroupListing(resp.data.data));
          dispatch(setSoldImagesByGroupListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSoldImagesByGroupListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSoldImagesByGroupListingLoading(false));
      }
    };
  }
};

export const soldImagesByGroupIdsListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSoldImagesByGroupIdsListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.listOfSoldImagesbyGroupIds}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSoldImagesByGroupIdsListing(resp.data.data));
          dispatch(setSoldImagesByGroupIdsListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSoldImagesByGroupIdsListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSoldImagesByGroupIdsListingLoading(false));
      }
    };
  }
};

export const imageListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      let url = `${ApiConfig.imagesByName}?image_name=${payload.search}&content_type=${payload.content_type}`;
      if (payload?.type === 'Video' || payload?.type === 'video')
        url += `&type=2`;
      else if (payload?.type === 'Image' || payload?.type === 'image' || payload?.type === 'Star' || payload?.type === 'star')
        url += `&type=1`;
      const resp = await axios.get(url);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};
export const updateWaterMark = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(ApiConfig.updatewaterMark, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};
export const imageShootsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(ApiConfig.shootsIdsSearch, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};
export const mergeShoots = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.mergeShootIds}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return resp.data;
    } else {
      throw resp;
    }
    // return true
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const updateImage = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.updateImage}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp.data;
    } else {
      throw resp;
    }
    // return true
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const insertImages = async (payload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.insertImage}`, payload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return resp.data;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const suspendImagesBulk = async (payload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.bulkSuspendImages}`, payload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return resp.data;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const insertImagesKeywords = async (payload, header) => {
  try {
    const resp = await axios.post(
      `${ApiConfig.repairKeywordsImage}`,
      payload,
      header,
    );
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return resp.data;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const suspendImage = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.suspendImage}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};

export const soldImagePendingListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSoldImagesPendingListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.listOfSoldPendingImages}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSoldImagesPendingListing(resp.data.data));
          dispatch(setSoldImagesPendingListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSoldImagesPendingListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSoldImagesPendingListingLoading(false));
      }
    };
  }
};

export const insertIntoElasticSearchImage = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.insertElasticImage}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const insertIntoElasticSearchAllImage = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.insertElasticImageBulk}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateImageNew = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.editTempImages}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp.data;
    } else {
      throw resp;
    }
    // return true
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const getListOfVideoKeywords = async (payload) => {
  try {
    const resp = await axios.get(`${ApiConfig?.videoKeyword}/${payload}`);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp?.data;
    }
  } catch (e) {
    toast.error(errorMessage(e), { autoClose: 2500 });
  }
};

export const linkKeywordToImageNew = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.imageKeywordNew}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};
export const updateImageTemp = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.editTempImages}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp.data;
    } else {
      throw resp;
    }
    // return true
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const deleteTempImage = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.deleteTempImage}`,
        deleteApiPayload(payload),
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const shootIdsListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllImagesListingLoading(true));
        const resp = await axios.post(`${ApiConfig.shootIds}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllShootListing(resp.data.data));
          dispatch(setAllShootListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllShootListingLoading(false));
      }
    };
  }
};

export const blockShootids = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.blockShootIds}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const unblockShootids = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.unblockShootIds}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const imagesShootsListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setModelShootImageListingLoading(true));
        const resp = await axios.post(`${ApiConfig.shootsImages}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setModelShootImageListing(resp.data.data));
          dispatch(setModelShootImageListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setModelShootImageListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setModelShootImageListingLoading(false));
      }
    };
  }
};

export const chnageShootCategory = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.upadetCategoryShoot}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const searchExclusiveImageByName = async (keywords) => {
  if (keywords !== undefined) {
    try {
      const response = await axios.post(
        `${ApiConfig.exclusiveImagesearchByName}?keywords=${keywords?.trim()}`,
      );
      if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
        return response;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2000,
      });
      return e;
    }
  }
};

export const searchExclusiveImageResult = async (
  keyword,
  size,
  showPerPage,
  sortBy,
) => {
  if (keyword !== undefined) {
    try {
      const response = await axios.post(
        `${ApiConfig.getExclusiveImagesSearchList
        }?keyword=${keyword?.trim()}&size=${size}&page=${showPerPage}${sortBy ? `&sortBy=${sortBy}` : ''
        }`,
      );
      if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
        return response;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2000,
      });
      return e;
    }
  }
};

export const AutoSuggestForExclusiveImage = async (keyword) => {
  if (keyword !== undefined) {
    try {
      const response = await axios.post(
        `${ApiConfig.autosuggest}?keyword=${keyword}`,
      );
      if (response?.status >= STATUS_200 && response?.status < STATUS_300) {
        return response.data?.data;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2000,
      });
      return e;
    }
  }
};

export const imagesForShootsListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setShootImageIDListingLoading(true));
        const resp = await axios.post(`${ApiConfig.shootImageByShootId}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setShootImageIDListing(resp.data.data));
          dispatch(setShootImageIDListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setShootImageIDListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setShootImageIDListingLoading(false));
      }
    };
  }
};