import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import IvsCheckout from './IvsCheckout';

function IvsCheckoutContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: 'IVS Checkout',
    navSubTitle: 'Welcome to ImagesBazaar',
  });
  return (
    <MainLayout
      navTitle={navParams.navTitle}
      navSubTitle={navParams.navSubTitle}
    >
      <IvsCheckout />
    </MainLayout>
  );
}

export default IvsCheckoutContainer;
