import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DATA_LIMIT } from '../../data/constants';
import MatTable from '../Tables/MatTable';
import { crypt, formatDate, fetchFile, getParameters } from '../../lib/helper';
import { Link, useParams } from 'react-router-dom';
import ApiConfig from '../../config/ApiConfig';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import UpgradeImageOrVideoModal from '../Modals/UpgradeImageOrVideoModal';
import { useDispatch, useSelector } from 'react-redux';
import { userItemsSelector } from '../../redux/user';
import { userDownloadImagelistApi } from '../../Services/userApis';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';

const column = [
  {
    label: 'Image',
    id: 'image_url',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
      >
        <img style={{ maxWidth: 200, maxHeight: 200 }} src={columnData} />
      </span>
    ),
  },
  {
    label: 'Name',
    id: 'image_name',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", cursor: "pointer" }}>{columnData}</span>)
  },
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>)
  },
  {
    label: 'Plan Type',
    id: 'quality',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  }
];

function UserImageDownloadHistory({ imageDownloadListAllData, imageDownloadValue, rightSection, handleCartList }) {
  const [tableListing, setTableListing] = useState([])
  const { singleDownloadImagelistDetail } = useSelector(userItemsSelector);
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [page, setPage] = useState(1);
  const [userData, setUserData] = useState(null);
  const [open, setOpen] = useState(false);
  const [allParams, setAllParams] = useState(getParameters());
  const [limit, setLimit] = useState(20);

  const handleAdd = (value) => {
    setOpen(value);
  }

  useEffect(() => {
    getUserDownloadImageList();
  }, [limit]);

  const getUserDownloadImageList = () => {
    let downloadPayload = {
      page: String(1),
      limit: String(limit),
      user_id: userId
    }
    dispatch(userDownloadImagelistApi(userId, downloadPayload))
  }

  const getListing = async (params = {}, setPageState = () => { }) => {
    const payload = {
      page: String(1),
      limit: limit,
      user_id: userId,
      ...params,
    };
    dispatch(userDownloadImagelistApi(userId, payload));
    if (setPageState) {
      setPageState(Number(payload.page));
    }
  };

  const handleDownloadImage = (data) => {
    let planType = "WEB";
    if (data?.quality?.toLowerCase()?.includes("web") || data?.quality === "Extra Small") {
      planType = "WEB";
    }
    if (data?.quality?.includes("LARGE") || data?.quality?.toLowerCase()?.includes("large") || data?.quality?.includes("Large") || data?.quality?.toLowerCase()?.includes("high") || data?.quality?.toLowerCase()?.includes("xxl") || data?.quality?.toLowerCase()?.includes("xl")) {
      planType = "LARGE";
    }
    if (data?.quality?.includes("MEDIUM") || data?.quality?.toLowerCase()?.includes("super")) {
      planType = "MEDIUM";
    }
    if (data?.quality === "Small" || data?.quality === "SMALL" || data?.quality?.toLowerCase()?.includes("small") || data?.quality === "small" || data?.quality?.includes("Medium")
    ) {
      planType = "SMALL";
    }

    const encryptedImageName = CryptoJS.AES.encrypt(
      String(data?.image_name),
      process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY).toString();
    const encryptedShootId = CryptoJS.AES.encrypt(String(data?.shoot_id), process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY).toString();
    const encryptedImageType = CryptoJS.AES.encrypt(String(planType), process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY).toString();
    const encryptedPayload = { image_name: encryptedImageName, imageType: encryptedImageType, shootid: encryptedShootId, };
    const fileTypeOfDownload = data?.image_name?.substring(0, 2)?.toLowerCase() == "vd" ? "2" : "1";
    toast.promise(fetchFile(`${ApiConfig.ibdownloadimage}?image_name=${encryptedPayload?.image_name}&image_type=${encryptedPayload?.imageType}&shootid=${encryptedPayload?.shootid}&file_type=${fileTypeOfDownload}`),
      {
        pending: "Downloading your file....",
        success: "Please wait while your Image/s is/are downloading. It may take a few moments.",
        error: "Failed to download file.",
      }
    );
  };

  useEffect(() => {
    if (singleDownloadImagelistDetail?.items?.rows?.length >= 0) {
      let data = singleDownloadImagelistDetail?.items?.rows?.map((item) => {
        return {
          Discount: item?.Discount,
          amount: item?.amount,
          order_by: item?.order_by,
          rank: item?.rank,
          shoot_id: item?.shoot_id,
          user_name: item?.user_name,
          sold_image_id: item?.sold_image_id,
          download_on: item?.Download_on,
          client: item?.client,
          order_id: item?.order_id,
          order: item?.order,
          image_name: item?.image_name,
          quality: item?.quality,
          type: item?.type,
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shoot_id}/${item?.rank}-${item?.image_name}.jpg`,
        }
      })
      setTableListing(data)
    }
  }, [singleDownloadImagelistDetail?.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          {data?.quality != 'LARGE' && data?.type == '1' && <CustomButton className="noMinWidth buttonDense" onClick={() => {
            handleAdd(true)
            setUserData(data);
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>Upgrade</CustomButton>
          }
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            handleDownloadImage(data)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>Download</CustomButton>
        </div>

      </>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: (theme) => theme.spacing(3), py: (theme) => theme.spacing(2) }}>
      </Box>
      <MatTable
        showCheckbox={false}
        columns={column}
        loading={singleDownloadImagelistDetail?.loading}
        data={tableListing}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20, 50, 100, 200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        action={actionBody}
        total={singleDownloadImagelistDetail?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        toolbarEnable={true}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          }, setPage)
        }}
        pagination={true}
      />
      {open && <UpgradeImageOrVideoModal open={open} handleOpen={handleAdd} userData={userData} userId={userId} handleCartList={handleCartList} />}

    </>
  );
}

export default UserImageDownloadHistory;
