import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@material-ui/icons';
import { DATA_LIMIT } from '../../data/constants';
import {
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
} from '../../lib/helper';
import { companyItemsSelector } from '../../redux/company';
import {
  companyGroupsListings,
  companyListings,
  companyMasterListings,
  deleteCompany,
  deleteCompanyGroup,
} from '../../Services/companyApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import AddCompanyGroup from '../Modals/AddCompanyGroup';
import ConfirmationModal from '../Modals/ConfirmationModal';
import MatTable from '../Tables/MatTable';
import AddNewCompanyMaster from '../Modals/AddNewCompanyMaster';
import EditCompanyMasterDetails from '../Modals/EditCompanyMasterDetails';
import AllFilters from '../Order/AllFilters';
import { SearchByAssignModel } from '../../data/modelData';

import { isVisible } from '@testing-library/user-event/dist/utils';
import { companyMasterByNameListingsNoRedux } from '../../Services/CRMFollowups';
// companyMasterByNameListingsNoRedux
const column = [
  {
    label: 'S. No.',
    id: 'company_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Company Name',
    id: 'company_name',
    // format: (columnData) => (
    //   <span
    //     style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
    //   >
    //     {columnData || 'N/A'}
    //   </span>
    //to={`contributorDetail/${row?.contributor_id}/${row?.email}`}
    // ),
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => {
      return columnData ? <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span> : ''
      return null
    },
  },




  {
    label: 'Short Company Name',
    id: 'similar_company_name',
    format: (columnData) => (
      <Link className='textDecoration-none link-text' to={`shortCompanyName/${columnData}`}  >
        <span
          style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
        >
          {columnData || 'N/A'}
        </span>
      </Link>
    ),
  },
  {
    label: 'Company Group',
    id: 'company_group_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Discount(%)',
    id: 'discount',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
];

function CompanyMasterList() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [editOpen, setEditOpen] = useState(false);
  const [limit, setLimit] = useState(20);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, companyGroupsList, companyMasterList } =
    useSelector(companyItemsSelector);
  const dispatch = useDispatch();
  const [addCompanyMasterModal, setAddCompanyMasterModal] = useState(false);
  const [confirmDeleteCompanyGroup, setConfirmDeleteCompanyGroup] =
    useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [deleteCompanyGroupData, setDeleteCompanyGroupData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [companyData, setCompanyData] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [companySearch, setCompanySearch] = useState(null);
  const [userDataByCompanyState, setUserDataByCompanyState] = useState([])
  const selectRefCompany = useRef(null);

  const getCompanyListing = async (inputValue, index) => {
    if (inputValue && inputValue?.length > 2) {
      let data = await companyMasterByNameListingsNoRedux({
        company_name: inputValue,
        limit: 4,
        page:1
      })
      if (data) {
        let updatedData = data?.map((item, index) => {
          return {
            value: item.company_name,
            label: item.company_name,
            states: item.state
          }
        })
        setCompanyData(updatedData)
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          // newState[0]['listingData'] =updatedData
          newState[0]['value'] = inputValue
          return newState;
        });

        return updatedData || []
      }
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        // newState[index]['listingData'] =[]
        newState[0]['value'] = inputValue;
        return newState;
      });
      setCompanyData([])
      return []
    }
  }
  const setResetListingData = () => {
    // setFiltersArray((prevState) => {
    //   let newState = [...prevState];
    //   newState[0]['value'] = '';
    //   newState[1]['value'] = '';
    //   newState[0]['listingData'] = '';
    //   newState[1]['isVisible'] = false;
    //   newState[1]['dataArr'] = []
      
    //   return newState;
    // });
    setCompanyData([]);
    handleClear();

  }

  const handleCompanyOrGroupSelect = async (e, index) => {
    let companyDataCopy = { ...e };
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0]['value'] = companyDataCopy?.value;
      newState[0]['listingData'] = companyDataCopy;
      if (companyDataCopy?.states) {
        let newArr = companyDataCopy?.states?.map((a) => {
          return {
            value: a?.state, label: a?.state
          }
        })
        newArr?.unshift({ 'label': 'Select', value: '' })
        setStateData(newArr);
        newState[1]['isVisible'] = true;
        newState[1]['dataArr'] = newArr;
      }
      return newState;
    });
    setCompanyData(companyDataCopy);

    blurAsyncCompany();
  }

  const blurAsyncCompany = () => {
    selectRefCompany.current?.blur();
  };
  const [filtersArray, setFiltersArray] = useState([

    // {
    //   label: 'From Date',
    //   id: 'start_date',
    //   type: 'input',
    //   linkedWith: null,
    //   value: '',
    //   onChange: (e, index, value) => {
    //     setFiltersArray((prevState) => {
    //       let newState = [...prevState];
    //       newState[index].value = e.target.value;
    //       newState[1].value = e.target.value;
    //       return newState;
    //     });
    //     // handleChangeFollowUpDate(e.target.value, 1, 'start_date');
    //     // handleChangeFollowUpDate(e.target.value, 2, 'end_date');

    //   },
    //   style: {},
    //   dataArr: [],
    //   props: { type: 'date' },
    //   isVisible: true,
    // },
    // {
    //   label: 'To Date',
    //   id: 'end_date',
    //   type: 'input',
    //   linkedWith: null,
    //   value: '',
    //   onChange: (e, index, value) => {
    //     setFiltersArray((prevState) => {
    //       let newState = [...prevState];
    //       newState[index].value = e.target.value;
    //       return newState;
    //     });
    //     // handleChangeFollowUpDate(e.target.value, 2, 'end_date');
    //   },
    //   style: {},
    //   dataArr: [],
    //   props: { type: 'date' },
    //   isVisible: true,
    // },

    // {
    //   label: 'Search Company',
    //   id: 'search',
    //   type: 'asyncInput',
    //   linkedWith: null,
    //   value: allParams?.search || '',
    //   onChange: (e, index, value) => {

    //       handleCompanyOrGroupSelect(e,index)
    //       // return newState;

    //   },
    //   style: {},
    //   dataArr: [],
    //   props: {},
    //   isVisible: true,
    // },
    {
      label: 'Search Company',
      id: 'company_name',
      type: 'async-select',
      linkedWith: null,
      value: allParams?.search || '',
      handleChange: (e, index, value) => {
      },
      onChange: (e, index, value) => {
        getCompanyListing(e, index);
        //  setFiltersArray((prevState) => {
        //   let newState = [...prevState];
        //   // newState[index]['listingData'] =[]
        //   newState[0][value] =e.target.value;
        //   return newState;
        // });
        handleCompanyOrGroupSelect(e)

      },
      style: {},
      dataArr: [],
      getListings: getCompanyListing,
      setListingData: setResetListingData,
      handleChangeAsyncSelect: handleCompanyOrGroupSelect,
      asyncRef:blurAsyncCompany,
      props: {},
      listingData: companyData,
      selectRefCompany:selectRefCompany,
      isVisible: true,
    },
    {
      label: 'Select State',
      id: 'state',
      type: 'select',
      linkedWith: null,
      value: stateData,
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });

      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
      ],
      props: {},
      isVisible: false,
    },

  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';
      newState[1].isVisible = false;
      newState[0]['listingData'] = '';
      return newState;
    });
  }



  const handleAdd = (value = false) => {
    setAddCompanyMasterModal(value);
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params,
    };
    dispatch(companyMasterListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getListing();
  }, [allParams]);

  //making data format for table input
  useEffect(() => {
    if (companyMasterList?.items?.rows?.length >= 0) {
      let data = companyMasterList?.items?.rows?.map((item) => {
        return {
          company_id: item.company_id,
          company_name: item.company_name,
          similar_company_name: item.similar_company_name,
          company_group_name: item?.company_group_name,
          discount: item?.discount,
          state: item?.state,
        };
      });
      setTableListing(data);
    }
  }, [companyMasterList.items]);

  const handleConfirmDeleteCompanyGroup = async (value) => {
    if (value && deleteCompanyGroupData) {
      let resp = deleteCompanyGroup({
        company_group_id: deleteCompanyGroupData,
      });
      getListing();
    } else {
      setDeleteCompanyGroupData(null);
    }
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDeleteCompanyGroup(value);
  };
  const handleEdit = (value, data = {}) => {
    setEditOpen(value);
    setSelectedData(data);
  };
  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            toolTipTittle={'Edit Company Master'}
            onClick={() => {
              handleEdit(true, data);
            }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonPrimary.main',
              color: 'buttonPrimary.contrastText',
            }}
          >
            <Edit />
          </CustomButton>
          <CustomButton
            disabled={true}
            toolTipTittle={'Delete Company Master'}
            // onClick={() => {
            //   // handleEdit(true, data)
            //   handleCofirmModalOpen(true);
            //   setDeleteData(data?.contributor_id);
            // }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonDanger.main',
              color: 'buttonDanger.contrastText',
            }}
          >
            <Delete />
          </CustomButton>
        </div>
      </>
    );
  };

  // const actionHead = (data) => {
  //   return (<div style={{display: "flex"}}>
  //      hi
  //   </div>);
  // };

  const handleAddNewItem=()=>{
    setAddCompanyMasterModal(true);
  }


  return (
    <>
      <MatTable
        loading={companyMasterList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        showPageLimit={true}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        // setFilters={setFilters}
        // filters={filters}
        total={companyMasterList?.items?.count || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        // toolBarButton={() => {
        //   return (
        //     <>
        //       <CustomButton
        //         onClick={() => {
        //           handleAdd(true);
        //         }}
        //         color={'buttonPrimary'}
        //         sx={(theme) => ({
        //           padding: '10px 10px',
        //           marginRight: 1,
        //           [theme.breakpoints.down('md')]: {
        //             flex: 1,
        //           },
        //         })}
        //       >
        //         Add Company Master
        //       </CustomButton>
        //     </>
        //   );
        // }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        // filterEnable
        // rangeDateFilter
        disableSearch

        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          // hasDownloadExcelButton={true}
          // handleDownloadExcel={getExportFile}
          hasAddNew={true}
          handleAddNewModal={handleAddNewItem}
          // hasUploadCSV={true}
          // handleAddCSV={handleAddCSV}

          />
        )}
      />
      {addCompanyMasterModal && (
        <AddNewCompanyMaster
          open={addCompanyMasterModal}
          handleOpen={handleAdd}
        />
      )}
      {confirmDeleteCompanyGroup && (
        <ConfirmationModal
          open={confirmDeleteCompanyGroup}
          handleConfirm={handleConfirmDeleteCompanyGroup}
          setOpen={handleCofirmModalOpen}
        />
      )}
      {editOpen && (
        <EditCompanyMasterDetails
          open={editOpen}
          handleOpen={handleEdit}
          setPage={setPage}
          getListing={getListing}
          selectedData={selectedData}
        />
      )}
    </>
  );
}

export default CompanyMasterList;
