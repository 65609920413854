import { Box, Typography } from '@mui/material';
import { bgcolor, maxWidth } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { commonItemsSelector } from '../../redux/common';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import Divder from '../CommonComp/Divder';

function IVSelectionComp({ data, handleData, updateData, getIVListing, onConfirm, error, helperText, ivListingCheck, handleCheckState }) {
  const { appSetting } = useSelector(commonItemsSelector);
  return (
    <>
        <Typography variant="h5">Select Plan</Typography>
        <Divder spacing={0.5} />
        <Box sx={{display: "flex", flex: 1, justifyContent: 'space-between'}}>
            <CustomAsyncSelect
                promiseOptions={getIVListing}
                error={error}
                helperText={helperText}
                closeMenuOnSelect={false}
                controlStyle={{
                    padding: 5
                }}
                isMulti={false}
                cacheOptions={false}
                value={data}
                handleChange={(e) => {
                    handleData(e);
                }}
                width={'100%'}
            />
            <CustomButton checkBoxEnable={true} 
              checkBoxLeftText={'Standard'}
              checked={ivListingCheck == 'standard' ? false : true }
              onChange={(event)=>{
                if(event.target.checked){
                  handleCheckState('all')
                }else{
                  handleCheckState('standard')
                }
              }}
              containerStyle = {{ml:2}}
             checkBoxRightText={'All'} />
        </Box>
    </>
  );
}

export default IVSelectionComp;
