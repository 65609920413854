import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import UserDetails from './UserDetails'

function UserDetailsContainer() {
  return (
    <MainLayout navTitle={"User Details"} navSubTitle={"Welcome to ImagesBazaar"}>
      <UserDetails/>
    </MainLayout>
  )
}

export default UserDetailsContainer