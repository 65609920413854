import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  companyList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },

  companyOverviewUserList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },

  companyOverviewOrderList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },

  companyAssignedList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  companyUnassignedList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  companyGroupsList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  companyMasterList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  companyDataRedux:{
    items:{}
  },
  companyEarningList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },

};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCompanyListingLoading: (state, action) => {
      state.companyList.loading = action.payload;
    },
    setCompanyListing: (state, action) => {
      state.companyList.items = action.payload;
      state.companyList.error = {
        isError: false,
        message: '',
      };
    },
    setCompanyListingError: (state, action) => {
      state.companyList.items = {};
      state.companyList.error = action.payload;
    },


    setCompanyOverviewUserListingLoading: (state, action) => {
      state.companyOverviewUserList.loading = action.payload;
    },
    setCompanyOverviewUserListing: (state, action) => {
      state.companyOverviewUserList.items = action.payload;
      state.companyOverviewUserList.error = {
        isError: false,
        message: '',
      };
    },
    setCompanyOverviewUserListingError: (state, action) => {
      state.companyOverviewUserList.items = {};
      state.companyOverviewUserList.error = action.payload;
    },



    setCompanyOverViewOrderListingLoading: (state, action) => {
      state.companyOverviewOrderList.loading = action.payload;
    },
    setCompanyOverViewOrderListing: (state, action) => {
      state.companyOverviewOrderList.items = action.payload;
      state.companyOverviewOrderList.error = {
        isError: false,
        message: '',
      };
    },

    setResetCompanyOverviewList:(state,action)=>{
      state.companyOverviewUserList.items = {};
      state.companyOverviewUserList.error = {
        isError: false,
        message: '',
      };
      state.companyOverviewUserList.loading = false
    },
    setCompanyOverViewOrderListingError: (state, action) => {
      state.companyOverviewOrderList.items = {};
      state.companyOverviewOrderList.error = action.payload;
    },

    //companyOverviewUserList

    setCompanyAssignedListLoading: (state, action) => {
      state.companyAssignedList.loading = action.payload;
    },
    setCompanyAssignedListing: (state, action) => {
      state.companyAssignedList.items = action.payload;
      state.companyAssignedList.error = {
        isError: false,
        message: '',
      };

    },
    setCompanyAssignedListingError: (state, action) => {
      state.companyAssignedList.items = {};
      state.companyAssignedList.error = action.payload;
    },
    setCompanyUnassignedListLoading: (state, action) => {
      state.companyUnassignedList.loading = action.payload;
    },
    setCompanyUnassignedListing: (state, action) => {
      state.companyUnassignedList.items = action.payload;
      state.companyUnassignedList.error = {
        isError: false,
        message: '',
      };
    },
    setCompanyUnassignedListingError: (state, action) => {
      state.companyUnassignedList.items = {};
      state.companyUnassignedList.error = action.payload;
    },
    setCompanyGroupsListingLoading: (state, action) => {
      state.companyGroupsList.loading = action.payload;
    },
    setCompanyGroupsListing: (state, action) => {
      state.companyGroupsList.items = action.payload;
      state.companyGroupsList.error = {
        isError: false,
        message: '',
      };
    },
    setCompanyGroupsListingError: (state, action) => {
      state.companyGroupsList.items = {};
      state.companyGroupsList.error = action.payload;
    },
    setCompanyMasterListingLoading: (state, action) => {
      state.companyMasterList.loading = action.payload;
    },
    setCompanyMasterListing: (state, action) => {
      state.companyMasterList.items = action.payload;
      state.companyMasterList.error = {
        isError: false,
        message: '',
      };
    },
    setCompanyMasterListingError: (state, action) => {
      state.companyMasterList.items = {};
      state.companyMasterList.error = action.payload;
    },
    setCompanyRedux:(state,action)=>{
      state.companyDataRedux.items=action.payload;
    },
    setResetCompanyReduxData:(state,action)=>{
      state.companyDataRedux={};
    },
    setCompanyMasterListingLoading: (state, action) => {
      state.companyMasterList.loading = action.payload;
    },
    setCompanyMasterListing: (state, action) => {
      state.companyMasterList.items = action.payload;
      state.companyMasterList.error = {
        isError: false,
        message: '',
      };
    },
    setCompanyMasterListingError: (state, action) => {
      state.companyMasterList.items = {};
      state.companyMasterList.error = action.payload;
    },
    //companyEarningList

    setCompanyListEarningLoading: (state, action) => {
      state.companyEarningList.loading = action.payload;
    },
    setCompanyListEarning: (state, action) => {
      state.companyEarningList.items = action.payload;
      state.companyEarningList.error = {
        isError: false,
        message: '',
      };
    },
    setCompanyListEarningError: (state, action) => {
      state.companyEarningList.items = {};
      state.companyEarningList.error = action.payload;
    },
  },
});

export default companySlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setCompanyListingLoading,
  setCompanyListing,
  setCompanyListingError,
  setCompanyAssignedListLoading,
  setCompanyAssignedListing,
  setCompanyAssignedListingError,
  setCompanyUnassignedListLoading,
  setCompanyUnassignedListing,
  setCompanyUnassignedListingError,
  setCompanyGroupsListing,
  setCompanyGroupsListingError,
  setCompanyGroupsListingLoading,
  setCompanyMasterListingLoading,
  setCompanyMasterListing,
  setCompanyMasterListingError,
  setCompanyOverViewOrderListingError,
  setCompanyOverViewOrderListing,
  setCompanyOverViewOrderListingLoading,
  setCompanyOverviewUserListingError,
  setCompanyOverviewUserListing,
  setCompanyOverviewUserListingLoading,
  setResetCompanyOverviewList,
  setCompanyRedux,
  setResetCompanyReduxData,
  setCompanyListEarningLoading,
  setCompanyListEarning,
  setCompanyListEarningError
} = companySlice.actions;

export const companyItemsSelector = (state) => state.company;
