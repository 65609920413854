import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { deleteApiPayload, errorMessage } from '../lib/helper';
import {
  setAllOrdersListing,
  setAllOrdersListingError,
  setAllOrdersListingLoading,
  setConfirmedOrderListing,
  setConfirmedOrderListingError,
  setConfirmedOrderListingLoading,
  setIvsDownloadListing,
  setIvsDownloadListingError,
  setIvsDownloadListingLoading,
  setIvsOrderListing,
  setIvsOrderListingError,
  setIvsOrderListingLoading,
  setLoading,
  setOrderDetails,
  setOrderDetailsError,
  setOrderDetailsLoading,
  setPendingOrderListing,
  setPendingOrderListingError,
  setPendingOrderListingLoading,
  setProposalListing,
  setProposalListingError,
  setProposalListingLoading,
  setRejectedOrderListing,
  setRejectedOrderListingError,
  setRejectedOrderListingLoading,
  setSubscriptionListing,
  setSubscriptionListingError,
  setSubscriptionListingLoading,
  setCheckoutOrderLoading,
  setCheckoutOrder,
  setCheckoutOrderError,
} from '../redux/orderListing';
import { STATUS_200, STATUS_300 } from '../data/constants';

export const AllOrderListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllOrdersListingLoading(true));
        const resp = await axios.post(`${ApiConfig.orderListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllOrdersListing(resp.data.data));
          dispatch(setAllOrdersListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllOrdersListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllOrdersListingLoading(false));
      }
    };
  }
};

export const PendingOrderListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setPendingOrderListingLoading(true));
        const resp = await axios.post(`${ApiConfig.pendingOrderListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setPendingOrderListing(resp.data.data));
          dispatch(setPendingOrderListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setPendingOrderListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setPendingOrderListingLoading(false));
      }
    };
  }
};

export const RejectedOrderListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setRejectedOrderListingLoading(true));
        const resp = await axios.post(`${ApiConfig.orderListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setRejectedOrderListing(resp.data.data));
          dispatch(setRejectedOrderListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setRejectedOrderListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setRejectedOrderListingLoading(false));
      }
    };
  }
};

export const ConfirmedOrderListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setConfirmedOrderListingLoading(true));
        const resp = await axios.post(`${ApiConfig.confirmOrderListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setConfirmedOrderListing(resp.data.data));
          dispatch(setConfirmedOrderListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setConfirmedOrderListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setConfirmedOrderListingLoading(false));
      }
    };
  }
};

export const ConfirmedOrderListingsExportXLS = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.orderListing}`, payload, {
        responseType: 'arraybuffer'
      });
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        return resp
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const orderDetailsApi = (orderId) => {
  if (orderId !== undefined || orderId != '') {
    return async (dispatch) => {
      try {
        dispatch(setOrderDetailsLoading(true));
        const resp = await axios.get(`${ApiConfig.orderDetails}/${orderId}`);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setOrderDetails(resp.data.data));
          dispatch(setOrderDetailsLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setOrderDetailsError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setOrderDetailsLoading(false));
      }
    };
  }
};

export const getProposalListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setProposalListingLoading(true));
        const resp = await axios.post(`${ApiConfig.proposalListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setProposalListing(resp.data.data));
          dispatch(setProposalListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setProposalListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setProposalListingLoading(false));
      }
    };
  }
};

export const getSubscriptionListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSubscriptionListingLoading(true));
        const resp = await axios.post(`${ApiConfig.orderListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSubscriptionListing(resp.data.data));
          dispatch(setSubscriptionListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSubscriptionListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSubscriptionListingLoading(false));
      }
    };
  }
};

export const getIvsDownloadListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setIvsDownloadListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.ivsDownloadImages}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setIvsDownloadListing(resp.data.data));
          dispatch(setIvsDownloadListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setIvsDownloadListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setIvsDownloadListingLoading(false));
      }
    };
  }
};

export const getIvsOrderListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setIvsOrderListingLoading(true));
        const resp = await axios.post(`${ApiConfig.ivsOrders}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setIvsOrderListing(resp.data.data));
          dispatch(setIvsOrderListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setIvsOrderListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setIvsOrderListingLoading(false));
      }
    };
  }
};

export const getIvsOrderListingExportFile = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.ivsOrders}`, payload, {
        responseType: 'arraybuffer'
      });
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        return resp
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const getInvoiceDetails = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.invoiceDetails}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const sendMail = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.expiryMail}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};

export const sendPromotionalMail = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.promotionalMail}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};

//promotionalMail

export const confirmOrder = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.confirmOrder}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};

export const editOrderInvoice = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.editInvoice}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, { autoClose: 2500 });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};

export const editInvoiceItemOrder = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.editInvoiceItem}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, { autoClose: 2500 });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};

export const rejectInvoice = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.invoiceReject}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, { autoClose: 2500 });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};

export const markAsPaidOrder = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.orderPaid}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};

export const deletePendingOrders = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.deleteOrder}`,
        deleteApiPayload(payload),
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const CreateUserOrder = async (payload) => {
  if (payload !== undefined) {
    try {
      const response = await axios.post(`${ApiConfig.createOrder}`, payload);
      if (
        response?.data?.status >= STATUS_200 &&
        response?.data?.status < STATUS_300
      ) {
        toast.success(response?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw response;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2000,
      });
      return false
    }
  }
};

export const CreateUserProposalOrder = async (payload) => {
  if (payload !== undefined) {
      try {
        const response = await axios.post(`${ApiConfig.createUserProposalOrder}`, payload);
        if (
          response?.data?.status >= STATUS_200 &&
          response?.data?.status < STATUS_300
        ) {
          toast.success(response?.data?.message, {
            autoClose: 2500,
          });
          return true
        } else {
          throw response;
        }
      } catch (e) {
        toast.error(errorMessage(e), {
          autoClose: 2000,
        });
        return true
      }
  }
};

export const editIvsDownloadOrder= async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.editIvsDownload}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, { autoClose: 2500 });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};

export const uploadPOFile = async (payload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.uploadPo}`, payload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};