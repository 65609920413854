import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, MenuItem } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { createCommonSettingCategoryTableData, formatDate, handleFileReader } from '../../lib/helper';
import { fileUpload } from '../../Services/commonService';
import { uploadWeeklyImages } from '../../Services/DashboardApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import { ImageSelectData } from '../../data/imageData';
import { updateImage } from '../../Services/imageApis';
import { commonItemsSelector } from '../../redux/common';
import { useSelector } from 'react-redux';

export default function EditImageDetailModal({ open, handleOpen, data, getListing, type }) {
  const [loading, setLoading] = React.useState(false);
  const { appSetting } = useSelector(commonItemsSelector)
  const [categoryTableData, setCategoryTableData] = React.useState([]);

  const handleUpload = async (values, { resetForm }) => {
    try {
      setLoading(true)
      let payload = {
        image_name: data.Name,
        image_type: values.image_type,
        image_rank: Number(values.image_rank),
        rank: Number(values.rank),
        is_star: values.is_star,
        is_contributor: values?.is_contributor,
        watermark: values?.watermark,
        pricing: Number(values.pricing),
        ...(type == "starimage" && { category: values.category })
      }
      let resp = await updateImage(payload)
      if (resp) {
        toast.success(resp?.message)
        getListing();
        handleOpen(false);
      }
      setLoading(false);
      resetForm();
    } catch (e) {
      setLoading(false)
      console.log(e, 'error')
    }
  }
  const formik = useFormik({
    initialValues: {
      image_name: data.Name || "",
      image_type: data.type || "",
      image_rank: data.rank || "",
      is_star: data.is_star || "0",
      is_contributor: data?.is_contributor || '0',
      watermark: data?.watermark || '0',
      rank: data.rank1 || "",
      modelreleaseid: data?.modelreleaseid || "",
      pricing: data.pricing || "",
      ...(type == "starimage" && { category: data.category || "" }),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      image_name: Yup.string()
        .required('Image name is required.'),
      image_type: Yup.string()
        .required('Image type is required.'),
      image_rank: Yup.string()
        .required('Image rank is required.')
        .matches(/^\d+$/, 'Image rank should be number only.'),
      rank: Yup.string()
        .required('Rank is required.'),
      is_contributor: Yup.string()
        .required('Rank is required.')
        .matches(/^\d+$/, 'Rank should be number only.'),
      watermark: Yup.string()
        .required('Wallmark is required.')
        .matches(/^\d+$/, 'Wallmark should be number only.'),
      pricing: Yup.string()
        .required('Pricing is required.')
        .matches(/^\d+$/, 'Pricing should be number only.'),
      ...(type == "starimage" && {
        category: Yup.string()
          .required('Category is required.')
      })

    }),
    onSubmit: handleUpload,
  });

  React.useEffect(() => {
    if (type == "starimage") {
      setCategoryTableData(createCommonSettingCategoryTableData(appSetting?.items?.features?.stars[0]?.key_value, 'stars'));
    }
  }, [appSetting?.items?.features?.stars])

  return (
    <>
      <Dialog
        maxWidth={'lg'}
        open={open}
        onClose={() => {
        }}
      >
        <DialogTitle variant='h3'>Edit Image Details</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={4} md={3}>
                <CustomImage
                  style={{ width: '100%', borderRadius: 8 }}
                  src={data.image_url}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Grid container spacing={2} mt={0}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="image_name"
                      label="Image name"
                      placeholder="Image name"
                      value={formik.values.image_name}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.image_name &&
                        Boolean(formik.errors.image_name)
                      }
                      helperText={
                        formik.touched.image_name && formik.errors.image_name
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="image_type"
                      label="Image type"
                      placeholder="Image type"
                      value={formik.values.image_type}
                      select
                      onChange={(e) => {
                        formik.setFieldValue('image_type', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.image_type &&
                        Boolean(formik.errors.image_type)
                      }
                      helperText={
                        formik.touched.image_type && formik.errors.image_type
                      }
                    >
                      {ImageSelectData?.imageType?.map((option) => (<MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                      ))}
                    </CustomInput>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="image_rank"
                      label="Image rank"
                      placeholder="Image rank"
                      value={formik.values.image_rank}
                      onChange={(e) => {
                        formik.setFieldValue('image_rank', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.image_rank &&
                        Boolean(formik.errors.image_rank)
                      }
                      helperText={
                        formik.touched.image_rank && formik.errors.image_rank
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="rank"
                      label="Rank"
                      placeholder="Rank"
                      value={formik.values.rank}
                      onChange={(e) => {
                        formik.setFieldValue('rank', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.rank &&
                        Boolean(formik.errors.rank)
                      }
                      helperText={
                        formik.touched.rank && formik.errors.rank
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="pricing"
                      label="Pricing"
                      placeholder="Pricing"
                      value={formik.values.pricing}
                      onChange={(e) => {
                        formik.setFieldValue('pricing', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.pricing &&
                        Boolean(formik.errors.pricing)
                      }
                      helperText={
                        formik.touched.pricing && formik.errors.pricing
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      name="is_star"
                      label="Is Star"
                      id="is_star"
                      placeholder="Is Star"
                      SelectProps={{
                        native: true,
                      }}
                      select
                      value={formik.values.is_star}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={formik.handleChange}
                      fullWidth
                      error={formik.touched.is_star && Boolean(formik.errors.is_star)}
                      helperText={formik.touched.is_star && formik.errors.is_star}
                    >
                      <option key={'0'} value={'0'}>
                        Inactive
                      </option>
                      <option key={'1'} value={'1'}>
                        Active
                      </option>
                    </CustomInput>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      name="is_contributor"
                      label="Is Contributor"
                      id="is_contributor"
                      placeholder="Is Contributor"
                      SelectProps={{
                        native: true,
                      }}
                      select
                      value={formik.values.is_contributor}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={formik.handleChange}
                      fullWidth
                      error={formik.touched.is_contributor && Boolean(formik.errors.is_contributor)}
                      helperText={formik.touched.is_contributor && formik.errors.is_contributor}
                    >
                      <option key={'0'} value={'0'}>
                        Inactive
                      </option>
                      <option key={'1'} value={'1'}>
                        Active
                      </option>
                    </CustomInput>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      name="watermark"
                      label="Is Watermark"
                      id="watermark"
                      placeholder="Is Watermark"
                      SelectProps={{
                        native: true,
                      }}
                      select
                      disabled={data?.watermark === 0 ? true : false}
                      value={formik.values.watermark}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={formik.handleChange}
                      fullWidth
                      error={formik.touched.watermark && Boolean(formik.errors.watermark)}
                      helperText={formik.touched.watermark && formik.errors.watermark}
                    >
                      <option key={'0'} value={'0'}>
                        Inactive
                      </option>
                      <option key={'1'} value={'1'}>
                        Active
                      </option>
                    </CustomInput>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="modelreleaseid"
                      label="Model Release ID"
                      placeholder="Model Release ID"
                      value={formik.values.modelreleaseid}
                      onChange={(e) => {
                        formik.setFieldValue('modelreleaseid', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.modelreleaseid &&
                        Boolean(formik.errors.modelreleaseid)
                      }
                      helperText={
                        formik.touched.modelreleaseid && formik.errors.modelreleaseid
                      }
                    />
                  </Grid>

                  {type == "starimage" && <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      name="category"
                      label="Category"
                      id="category"
                      placeholder="Category"
                      SelectProps={{
                        native: true,
                      }}
                      select
                      value={formik.values.category}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={formik.handleChange}
                      fullWidth
                      error={formik.touched.category && Boolean(formik.errors.category)}
                      helperText={formik.touched.category && formik.errors.category}
                    >
                      {categoryTableData &&
                        categoryTableData?.map((item) => (
                          <option key={item.key_name} value={item.key_name}>
                            {item.key_name}
                          </option>
                        ))}
                    </CustomInput>
                  </Grid>}
                </Grid>
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "end", marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Save</CustomButton>
              <CustomButton onClick={() => handleOpen(false)} disabled={loading}>Close</CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}


EditImageDetailModal.defaultProps = {
  open: false,
  handleOpen: () => { },
  data: null,
  getListing: () => { },
  type: 'allimage'
}