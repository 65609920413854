import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import ConfirmProposal from './ConfirmProposal'

function ConfirmProposalContainer() {
  return (
    <MainLayout navTitle={"Confirm Proposal"} navSubTitle={"Welcome to ImagesBazaar"}>
      <ConfirmProposal/>
    </MainLayout>
  )
}

export default ConfirmProposalContainer