import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import Irn from './Irn'

function IrnContainer() {
  return (
    <MainLayout navTitle={"IRN"} navSubTitle={"Welcome to ImagesBazaar"}>
      <Irn/>
    </MainLayout>
  )
}

export default IrnContainer