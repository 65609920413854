import { Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate } from '../../lib/helper';
import { commonItemsSelector } from '../../redux/common';
import { getCountry, getState } from '../../Services/commonService';
import { companyGroupListingsNoRedux } from '../../Services/companyApis';
import { companyShortNameListingsNoRedux } from '../../Services/CRMFollowups';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomAsyncSelect, { CustomAsyncCreatableSelect } from '../CommonComp/CustomInput/CustomAsyncSelect';
import CustomAsyncCreatable from '../CommonComp/CustomInput/CustomAsyncSelect';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import RadioField from '../CommonComp/CustomInput/RadioField';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import UserDetailInputField from '../User/UserDetailInputField';

function UserDetailsFollowUps({ formik, userData }) {
  const [data, setData] = useState(null);
  const { countryData, stateData } = useSelector(commonItemsSelector);
  const [countryList, setCountryList] = useState([]);
  const [countryShortName, setCountryShortName] = useState(userData ? userData?.data?.countries?.country_short_name?.toLowerCase() : 'in');
  const [stateList, setStateList] = useState([]);
  const [selectedCountry, setSelcetedCountry] = useState('india');
  const [selectedState, setSelectedState] = useState({});
  const selectRef = useRef(null);
  const selectAssociationRef = useRef(null);
  const dispatch = useDispatch()

  useEffect(() => {
    setCountryShortName(userData ? userData?.data?.countries?.country_short_name?.toLowerCase() : 'in');
    defaultCountry('');
  }, [userData])

  const getShortNameListing = async (inputValue) => {
    if (inputValue && inputValue.length > 2) {
      let data = await companyShortNameListingsNoRedux({
        short_company_name: inputValue
      })
      if (data) {
        let updatedData = data?.map((item, index) => {
          return {
            value: item.short_company_name,
            label: item.short_company_name,
            data: item
          }
        })
        return updatedData || []
      }
      return []
    }

  }

  const blurAsync = () => {
    selectRef.current?.blur();
    selectAssociationRef.current?.blur();
  };

  useEffect(() => {
    if (countryData && Array.isArray(countryData?.items)) {
      // let updatedCountryData = countryData?.items?.filter((item) => {
      //   if (item.states_available == 1) {
      //     return item;
      //   }
      // });
      let data = countryData?.items?.map((item) => {
        return {
          label: item?.country_name,
          value: item?.country_id,
        };
      });
      setCountryList(data);
      getStateData(userData);
      if (userData?.data?.countries) {
        setCountryShortName(userData?.data?.countries?.country_short_name?.toLowerCase())
      }
    }
  }, [countryData]);

  const handleChangeCountry = async (e) => {
    formik.setFieldValue("country", e.target.value);
    formik.setFieldValue("state", "");
    setSelcetedCountry(e.target.value || "");
    setSelectedState({});
    let country_id = 0;
    countryData?.items?.filter((val) => {
      if (val.country_name === e.target.value) {
        country_id = val.country_id;
        setCountryShortName(val?.country_short_name?.toLowerCase())
        // formik.setFieldValue("mobile_number", "");
      }
    });
    dispatch(getState(country_id));
  };


  const defaultCountry = async (cont) => {
    if (cont) {
      formik.setFieldValue("country", cont);
      formik.setFieldValue("state", "");
      setSelcetedCountry(cont || "");
      setSelectedState({});
      let country_id = 0;
      countryData?.items?.filter((val) => {
        if (val.country_name === cont) {
          country_id = val.country_id;
          setCountryShortName(val?.country_short_name?.toLowerCase())
          // formik.setFieldValue("mobile_number", "");
        }
      });
      dispatch(getState(country_id));
    }
  };


  const getStateData = async (userData) => {
    // if (user?.country) {
    //   dispatch(getState(user?.country?.country_id));
    // }
    if (userData?.data?.countries) {
      dispatch(getState(userData?.data?.countries?.country_id));
    }
  };

  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  }, [stateData]);

  const getCountryDetails = async () => {
    dispatch(getCountry());
  };

  const handleStateChange = (val) => {
    let stateSelected = stateData?.items?.filter(
      (item) => item.state_name == val
    );
    if (stateSelected.length) {
      setSelectedState(stateSelected[0]);
    }
  };

  useEffect(() => {
    getCountryDetails();
    // getIvsData()
  }, []);

  useEffect(() => {
    if (userData) {
      setSelcetedCountry(userData?.data?.country || "")
      setSelectedState(userData?.data?.states);
      getStateData(userData);
    }
    setData(null)
  }, [userData]);

  const handleGroupSelect = (e) => {
    setData(e);
    formik.setValues((values) => {
      return {
        ...values,
        company_name: e.data?.company_name || "",
        short_company_name: e.data?.short_company_name || "",
        group_company_name: e.data?.company_group_name || "",
      }
    })
    blurAsync();
  }

  return (
    <>
      <Typography variant='h5'>User Details</Typography>
      <Divder spacing={1} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="email"
            label="Email"
            placeholder="Email"
            InputLabelProps={{ shrink: true, required: true }}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="alternate_email"
            label="First Alternative Email"
            placeholder="First Alternative Email"
            value={formik.values.alternate_email}
            onChange={formik.handleChange}
            error={formik.touched.alternate_email && Boolean(formik.errors.alternate_email)}
            helperText={formik.touched.alternate_email && formik.errors.alternate_email}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="alternate_second_email"
            label="Second Alternative Email"
            placeholder="Second Alternative Email"
            value={formik.values.alternate_second_email}
            onChange={formik.handleChange}
            error={formik.touched.alternate_second_email && Boolean(formik.errors.alternate_second_email)}
            helperText={formik.touched.alternate_second_email && formik.errors.alternate_second_email}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="first_name"
            label="First Name"
            placeholder="First Name"
            InputLabelProps={{ shrink: true, required: true }}
            value={formik.values.first_name}
            onChange={formik.handleChange}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            helperText={formik.touched.first_name && formik.errors.first_name}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="last_name"
            label="Last Name"
            placeholder="Last Name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
            helperText={formik.touched.last_name && formik.errors.last_name}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="country"
            label="Country"
            id="country"
            placeholder="Country"
            SelectProps={{
              native: true,
            }}
            select
            value={formik.values.country}
            InputLabelProps={{ shrink: true, required: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            // onChange={(e) => {
            //   formik.setFieldValue('country', e.target.value);
            // }}
            onChange={(e) => handleChangeCountry(e)}
            error={
              formik.touched.country &&
              Boolean(formik.errors.country)
            }
            helperText={
              formik.touched.country && formik.errors.country
            }
          >
            <option key={'Select Country'} value={''}>
              Select Country
            </option>
            {countryList &&
              countryList?.map((country) => (
                <option key={country.value} value={country.label}>
                  {country.label}
                </option>
              ))}
          </UserDetailInputField>
        </Grid>
        {stateData.isStateAvailable === true ? (<Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="state"
            label="State"
            placeholder="State"
            SelectProps={{
              native: true,
            }}
            select
            value={formik.values.state}
            InputLabelProps={{ shrink: true, required: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            // onChange={(e) => {
            //   formik.setFieldValue('state', e.target.value);
            // }}
            onChange={(e) => {
              formik.handleChange(e);
              handleStateChange(e.target.value);
            }}
            error={
              formik.touched.state &&
              Boolean(formik.errors.state)
            }
            helperText={
              formik.touched.state && formik.errors.state
            }
          >
            <option key={'Select State'} value={''}>
              {formik.values.country ? "Select state" : "Please select country first"}
            </option>
            {stateList &&
              stateList?.map((state) => {
                return (
                  <option key={state.value} value={state.label}>
                    {state.label}
                  </option>
                );
              })}
          </UserDetailInputField>
        </Grid>) : null}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          {/* <CustomPhoneInput
                  name="mobile_number"
                  id="mobile"
                  label="Mobile No"
                  placeholder="Mobile No"
                  value={formik.values.mobile_number}
                  error={formik.touched.mobile_number && Boolean(formik.errors.mobile_number)}
                  helperText={ formik.touched.mobile_number && formik.errors.mobile_number}
                  className="main-login-form"
                  fullWidth
                  native={true}
                  defaultCountry={`${countryShortName}`}
                  country={`${countryShortName}`}
                  divStyle={{
                    paddingTop: 4
                  }}
                  variant="outlined"
                  onChange={(newValue, info) => {
                    formik.setFieldValue("mobile_number", newValue);
                  }}
                  onlyCountries={[countryShortName]}
              /> */}
          <UserDetailInputField
            name="mobile_number"
            label="Mobile Number"
            placeholder="Mobile Number"
            InputLabelProps={{ shrink: true, required: true }}
            value={formik.values.mobile_number}
            onChange={formik.handleChange}
            error={formik.touched.mobile_number && Boolean(formik.errors.mobile_number)}
            helperText={formik.touched.mobile_number && formik.errors.mobile_number}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomBox spacing={3} sx={{
            background: '#f5f8f8',
            borderRadius: 0
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomAsyncCreatableSelect
                  promiseOptions={getShortNameListing}
                  // error={error}
                  // helperText={helperText}
                  closeMenuOnSelect={false}
                  controlStyle={{
                    padding: 5,
                    height: 56
                  }}
                  cacheOptions={false}
                  value={data}
                  handleChange={(e) => {
                    handleGroupSelect(e)
                  }}
                  ref={selectRef}
                  width={'100%'}
                  dropDownZIndex={100000}
                  labelShrink={true}
                  label="Search Short Company"
                  isMulti={false}

                // error={formik.touched.short_company_name && Boolean(formik.errors.short_company_name)}
                // helperText={formik.touched.short_company_name && formik.errors.short_company_name}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <UserDetailInputField
                  name="company_name"
                  label="Company Name"
                  placeholder="Company Name"
                  InputLabelProps={{ shrink: true, required: true }}
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                  helperText={formik.touched.company_name && formik.errors.company_name}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <UserDetailInputField
                  name="short_company_name"
                  label="Short Company Name"
                  placeholder="Short Company Name"
                  value={formik.values.short_company_name}
                  onChange={formik.handleChange}
                  error={formik.touched.short_company_name && Boolean(formik.errors.short_company_name)}
                  helperText={formik.touched.short_company_name && formik.errors.short_company_name}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <UserDetailInputField
                  name="group_company_name"
                  label="Group Name"
                  placeholder="Group Name"
                  value={formik.values.group_company_name}
                  onChange={formik.handleChange}
                  error={formik.touched.group_company_name && Boolean(formik.errors.group_company_name)}
                  helperText={formik.touched.group_company_name && formik.errors.group_company_name}
                />
              </Grid>
            </Grid>
          </CustomBox>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="phone_number"
            label="Phone Number"
            placeholder="Phone Number"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
            helperText={formik.touched.phone_number && formik.errors.phone_number}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <RadioField
            name="user_type"
            label="User Type"
            row
            value={formik.values.user_type}
            onChange={formik.handleChange}
            error={formik.touched.user_type && Boolean(formik.errors.user_type)}
            helperText={formik.touched.user_type && formik.errors.user_type}
            radioData={[{ radioLable: 'New', value: 'New' }, { radioLable: 'Existing', value: 'Existing' }]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="alternate_name"
            label="Alternate Name"
            placeholder="Alternate Name"
            value={formik.values.alternate_name}
            onChange={formik.handleChange}
            error={formik.touched.alternate_name && Boolean(formik.errors.alternate_name)}
            helperText={formik.touched.alternate_name && formik.errors.alternate_name}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="alternate_contact_number"
            label="Alternate Contact Number"
            placeholder="Alternate Contact Number"
            value={formik.values.alternate_contact_number}
            onChange={formik.handleChange}
            error={formik.touched.alternate_contact_number && Boolean(formik.errors.alternate_contact_number)}
            helperText={formik.touched.alternate_contact_number && formik.errors.alternate_contact_number}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="designation"
            label="Designation"
            placeholder="Designation"
            value={formik.values.designation}
            onChange={formik.handleChange}
            error={formik.touched.designation && Boolean(formik.errors.designation)}
            helperText={formik.touched.designation && formik.errors.designation}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UserDetailInputField
            name="order_id"
            label="Order ID"
            placeholder="Order ID"
            // value={formik.values.order_id}
            onChange={formik.handleChange}
            error={formik.touched.order_id && Boolean(formik.errors.order_id)}
            helperText={formik.touched.order_id && formik.errors.order_id}
          />
        </Grid>

      </Grid>
    </>
  );
}

export default UserDetailsFollowUps;
