import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import LogsPage from './LogsPage'

function LogsContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "All",
    navSubTitle: "Welcome to ImagesBazaar"
  })

  const handleNavParams = (obj)=>{
    setNavParams({
      ...navParams,
      ...obj
    })
  }

  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
      <LogsPage handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default LogsContainer