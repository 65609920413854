import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton'
import MainLayout from '../Layouts/MainLayout'
import Image from './Image'

function ImageContainer() {
  const [navParams, setNavParams] = useState({
      navTitle: "All Images",
      navSubTitle: "Welcome to ImagesBazaar"
  })

  const handleNavParams = (obj)=>{
    setNavParams({
        ...navParams,
        ...obj
    })
  }

  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}
       navBarRightComp={<>
          <Link to="/addImages" className="textDecoration-none">
            <CustomButton sx={{ padding: '12px 15px', ml: (theme)=>(theme.spacing(2)) }} color={'buttonPrimary'} className="textDecoration-none">
                Add Images
            </CustomButton>
          </Link>
        </>}
    >
        <Image handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default ImageContainer