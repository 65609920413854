import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { deleteApiPayload, errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setContributorListingError,
  setContributorListing,
  setLoading,
  setContributorSoldImagesListing,
  setContributorSoldImagesListingError,
  setContributorListingLoading,
  setContributorSoldImagesListingLoading,
  setContributorBasicLoading,
  setContributorBasicDataError,
  setContributorBasicData,
  setContributorPaymentHistory,
  setContributorPaymentLoading,
  setContributorPaymentLoadingForImage,
  setContributorPaymentHistoryForImage,
  setContributorPaymentDivisionForImage,
  setContributorDivisionLoadingForImage,
  setContributorDailySalesReportListingLoading,
  setContributorDailySalesReportListing,
  setContributorDailySalesReportListingError,
  setContributorUploadedImagesListingError,
  setContributorUploadedImagesListingLoading,
  setContributorUploadedImagesListing,
  setContributorPaymentListingLoading,
  setContributorPaymentListing,
  setContributorPaymentError,
  setContributorFolderListingLoading,
  setContributorFolderListing,
  setContributorFolderListingError,
  setContributorUploadListingLoading,
  setContributorUploadListing,
  setContributorUploadListingError,
} from '../redux/contributor';

export const contributorListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setContributorListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.contributorListing}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setContributorListing(resp.data.data));
          dispatch(setContributorListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setContributorListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContributorListingLoading(false));
      }
    };
  }
};

export const contributorPaymentDivList = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setContributorPaymentListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.contributorPaymentListing}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setContributorPaymentListing(resp.data.data));
          dispatch(setContributorPaymentListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setContributorPaymentError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContributorListingLoading(false));
      }
    };
  }
};

export const contributorSoldImagesListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setContributorSoldImagesListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.contributorsoldimages}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          setTimeout(() => {
            dispatch(setContributorSoldImagesListing(resp.data.data));
            dispatch(setContributorSoldImagesListingLoading(false));
          }, 2000);
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setContributorSoldImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContributorSoldImagesListingLoading(false));
      }
    };
  }
};
export const contributorFolderListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setContributorFolderListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.contributorFolderList}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          setTimeout(() => {
            dispatch(setContributorFolderListing(resp.data.data));
            dispatch(setContributorFolderListingLoading(false));
          }, 2000);
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setContributorFolderListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContributorFolderListingLoading(false));
      }
    };
  }
};
export const uploadImagesContributorListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setContributorUploadListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.contributorListuploadedimages}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          setTimeout(() => {
            dispatch(setContributorUploadListing(resp.data.data));
            dispatch(setContributorUploadListingLoading(false));
          }, 2000);
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setContributorUploadListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContributorUploadListingLoading(false));
      }
    };
  }
};
export const contributorUploadImagesListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setContributorUploadedImagesListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.contributoruploadedimages}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          setTimeout(() => {
            dispatch(setContributorUploadedImagesListing(resp.data.data));
            dispatch(setContributorUploadedImagesListingLoading(false));
          }, 2000);
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setContributorUploadedImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContributorUploadedImagesListingLoading(false));
      }
    };
  }
};
export const createContributor = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createContributor}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const editContributor = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editContributor}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const updateImageStatus = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.imageStatusUpdate}`,
        payload,
      );
      if (
        resp?.data?.status >= STATUS_200 &&
        resp?.data?.status < STATUS_300
      ) {
        return resp?.data?.data
      }
    } catch (e) {

      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};

export const downloadImageContributor = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(`${ApiConfig.downloadFolderImages}?contributor_id=${payload?.contributor_id}&folder_name=${payload?.folder_name}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return resp.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const updateFolderLimit = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.folderLimitUpdate}`,
        payload,
      );
      if (
        resp?.data?.status >= STATUS_200 &&
        resp?.data?.status < STATUS_300
      ) {
        return resp?.data?.data
      }
    } catch (e) {

      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};
export const deleteContributor = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.deleteContributor}`,
        deleteApiPayload(payload),
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const contributorBankAndOtherInfo = (payload) => {
  if (payload !== undefined) {

    return async (dispatch) => {
      try {
        dispatch(setContributorBasicLoading(true));
        const resp = await axios.post(
          `${ApiConfig.contributorBasicInfoAndBankData}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          setTimeout(() => {
            dispatch(setContributorBasicData(resp.data.data));
            dispatch(setContributorBasicLoading(false));
          }, 2000);
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setContributorBasicDataError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContributorBasicLoading(false));
      }
    };
  }
};

export const contributorBankAndOtherInfoWithNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.contributorBasicInfoAndBankData}`,
        payload,
      );
      if (
        resp?.data?.status >= STATUS_200 &&
        resp?.data?.status < STATUS_300
      ) {
        return resp?.data?.data
      }
    } catch (e) {

      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};


export const contributorPaymentHistory = (payload) => {
  if (payload !== undefined) {

    return async (dispatch) => {
      try {
        dispatch(setContributorPaymentLoading(true));
        const resp = await axios.post(
          `${ApiConfig.contributorPaymentHistory}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          setTimeout(() => {
            dispatch(setContributorPaymentHistory(resp.data.data));
            dispatch(setContributorPaymentLoading(false));
          }, 2000);
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setContributorBasicDataError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContributorPaymentLoading(false));
      }
    };
  }
};

//

export const BlockAndUnBlockContributor = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.blockContributor}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });

      return false;
    }
  }
};

export const ContributorBankDetailUpdate = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.contributorBankDataAdd}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });

      return false;
    }
  }
};

export const addPayment = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.paymentMade}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const editPayment = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editContrubutorHistory}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};
export const addPaymentForImage = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.paymentMadeCreate}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const editPaymentForImage = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.updatePayment}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const contributorPaymentLists = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setContributorPaymentLoadingForImage(true));
        const resp = await axios.post(
          `${ApiConfig.listOfPayments}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          setTimeout(() => {
            dispatch(setContributorPaymentHistoryForImage(resp.data.data));
            dispatch(setContributorPaymentLoadingForImage(false));
          }, 2000);
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setContributorBasicDataError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContributorPaymentLoadingForImage(false));
      }
    };
  }
};

export const contributorPaymentDivisionLists = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setContributorDivisionLoadingForImage(true));
        const resp = await axios.post(
          `${ApiConfig.paymentDivision}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          setTimeout(() => {
            dispatch(setContributorPaymentDivisionForImage(resp.data.data));
            dispatch(setContributorDivisionLoadingForImage(false));
          }, 2000);
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setContributorBasicDataError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContributorDivisionLoadingForImage(false));
      }
    };
  }
};

export const ContributorPaidMade = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.markSoldAsPaid}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });

      return false;
    }
  }
};
//


export const getReportListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setContributorDailySalesReportListingLoading(true));
        const resp = await axios.post(`${ApiConfig.contributorDailySalesReport}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          //setAllReportsListing
          dispatch(setContributorDailySalesReportListing(resp.data.data));
          dispatch(setContributorDailySalesReportListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setContributorDailySalesReportListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContributorDailySalesReportListingLoading(false));
      }
    };
  }
};


export const getDailySalesReport = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.contributorDailySalesReport}`, payload, { responseType: 'blob' });
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        return { status: true, data: resp?.data }
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return { status: false, data: null }
    }
  }
};