import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { formatDate } from '../../lib/helper';
import {
  creatMultipleeOrderFollowUps,
  createOrderFollowUps,
} from '../../Services/CRMFollowups';
import { loginItemsSelector } from '../../redux/login';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function CreateMultipleOrderFollowups({
  open,
  handleOpen,
  getListing,
  orderIdList,
  invoiceIdList,
}) {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(loginItemsSelector);
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    const payloadData = {
      order_id: values?.orderId,
      followup_status: values?.followUpsStatus,
      followup_mode: values?.followUpsMode,
      next_followup_date: values?.nextFollowUpsDate,
      heading: values?.heading,
      assigned_to: values?.assign,
      description: values?.description,
      remarks: values?.remarks,
      created_by: values?.createdBy,
      user_name: values?.email || '',
    };
    let resp = await creatMultipleeOrderFollowUps(payloadData);
    if (resp) {
      resetForm();
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      orderId:
        (orderIdList && orderIdList?.length > 0 && orderIdList?.join(',')) ||
        '',
      followUpsStatus: 'open',
      followUpsMode: 'email',
      nextFollowUpsDate: formatDate(new Date(), 'yyyy-mm-dd') || '',
      heading:invoiceIdList ||
        '',
      description: '',
      remarks: '',
      assign: 'aastha',
      createdBy: user
        ? `${user?.userDetails?.first_name} ${user?.userDetails?.last_name}`
        : 'admin',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      orderId: Yup.string().required('Order ID is required'),
      followUpsStatus: Yup.string().required('Follow Up Status is required'),
      followUpsMode: Yup.string().required('Follow Up Mode is required'),
      nextFollowUpsDate: Yup.string().required(
        'Next Followups Date is requried.',
      ),
      heading: Yup.string().required('Heading is required'),
      assign: Yup.string().required('Assign is required'),
      description: Yup.string().required('Description is required'),
      remarks: Yup.string('Remarks should be String'),
      createdBy: Yup.string().required('Created By is required'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant="h3">Create Pending Order Follow ups</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <strong>Order ID:</strong> {formik.values.orderId}
                  <br/>
                  <strong>Invoice ID:</strong> {invoiceIdList}
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="followUpsStatus"
                  label="Follow Ups Status"
                  placeholder="Follow Ups Status"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.followUpsStatus}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.followUpsStatus &&
                    Boolean(formik.errors.followUpsStatus)
                  }
                  helperText={
                    formik.touched.followUpsStatus &&
                    formik.errors.followUpsStatus
                  }
                >
                  <option key={'Select Status'} value={''}>
                    Select Follow Ups Status
                  </option>
                  <option key="close" value="close">
                    Close
                  </option>
                  <option key="open" value="open">
                    Open
                  </option>
                  <option key="requested" value="requested">
                    Requested
                  </option>
                  <option key="answered" value="answered">
                    Answered
                  </option>
                </CustomInput>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="followUpsMode"
                  label="Follow Ups Mode"
                  placeholder="Follow Ups Mode"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.followUpsMode}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.followUpsMode &&
                    Boolean(formik.errors.followUpsMode)
                  }
                  helperText={
                    formik.touched.followUpsMode && formik.errors.followUpsMode
                  }
                >
                  <option key={'Select Status'} value={''}>
                    Select Follow Ups Mode
                  </option>
                  <option key="call" value="call">
                    Call
                  </option>
                  <option key="email" value="email">
                    Email
                  </option>
                  <option key="whatsapp" value="whatsapp">
                   WhatsApp
                  </option>
                </CustomInput>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  name="nextFollowUpsDate"
                  type="date"
                  label="Next Follow Ups Date"
                  placeholder="Next Follow Ups Date"
                  value={formatDate(
                    formik.values.nextFollowUpsDate,
                    'yyyy-mm-dd',
                  )} //2017-06-13T13:00
                  onChange={(e) => {
                    const date = new Date(e.target.value);
                    if (date.getDay() === 0) {
                      toast.error('You cannot choose Sunday.');
                    } else {
                      formik.handleChange(e);
                    }
                  }}
                  inputProps={{ min: formatDate(new Date(), 'yyyy-mm-dd') }}
                  error={
                    formik.touched.nextFollowUpsDate &&
                    Boolean(formik.errors.nextFollowUpsDate)
                  }
                  helperText={
                    formik.touched.nextFollowUpsDate &&
                    formik.errors.nextFollowUpsDate
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="heading"
                  label="Heading"
                  placeholder="Heading"
                  value={formik.values.heading}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.heading && Boolean(formik.errors.heading)
                  }
                  helperText={formik.touched.heading && formik.errors.heading}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="assign"
                  label="Assign"
                  placeholder="Assign"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.assign}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.assign && Boolean(formik.errors.assign)}
                  helperText={formik.touched.assign && formik.errors.assign}
                >
                  <option key={'Select Status'} value={''}>
                    Select Assign
                  </option>
                  <option key="admin" value="admin">
                    Admin
                  </option>
                  <option key="aastha" value="aastha">
                    Aastha
                  </option>
                </CustomInput>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="description"
                  label="Description"
                  placeholder="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  multiline
                  rows={3}
                  // maxRows={3}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="remarks"
                  label="Remarks"
                  placeholder="Remarks"
                  value={formik.values.remarks}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  multiline
                  rows={3}
                  // maxRows={3}
                  error={
                    formik.touched.remarks && Boolean(formik.errors.remarks)
                  }
                  helperText={formik.touched.remarks && formik.errors.remarks}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Confirm
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm();
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateMultipleOrderFollowups;
