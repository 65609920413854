import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import EditInvoice from './EditInvoice'

function EditInvoiceContainer() {
  return (
    <MainLayout navTitle={"Edit Invoice"} navSubTitle={"Welcome to ImagesBazaar"}>
        <EditInvoice/>
    </MainLayout>
  )
}

export default EditInvoiceContainer