import { Box } from '@mui/system';
import React, { useState } from 'react';
import Sidebar from '../../Components/Header/Sidebar';
import Navbar from '../../Components/Header/Navbar';
import Divder from '../../Components/CommonComp/Divder';
import { commonItemsSelector } from '../../redux/common';
import { useSelector } from 'react-redux';

const drawerWidth = 260;
function MainLayout(props) {
  
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const {sideMenuOpen}=useSelector(commonItemsSelector)

  const [showSmallNav, setShowSmallNav] = useState(true);
  let drawerWidth = sideMenuOpen?.isopen == true ? 260 : 140;


  return (
    <div>
      {/* <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}/> */}
      <Sidebar mobileOpen={mobileOpen} showSmallNav={showSmallNav} setShowSmallNav={setShowSmallNav}/>
      <Box sx={{ 
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px`, xs: 2 },
        paddingTop: 2, paddingRight: 2}}
      >
        <Navbar headingTitle={props.navTitle} headingSubtitle={props.navSubTitle} rightComp={props.navBarRightComp||null} handleDrawerToggle={handleDrawerToggle} />
        <Divder/>
        {props.children}
      </Box>
    </div>
  );
}

export default MainLayout;
