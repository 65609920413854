import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getObjectSubset } from '../../lib/helper';
import MatTable from '../Tables/MatTable';
import { followUpsItemSelector } from '../../redux/followUps';
import { followUpsByOrderIdsListings } from '../../Services/CRMFollowups';
import CustomBox from '../CommonComp/CustomBox/CustomBox';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Follow Ups Status',
    id: 'followup_status',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Follow Ups Mode',
    id: 'followup_mode',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Next Follow Ups Date',
    id: 'next_followup_date',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'mon dd yyyy')}
      </span>
    ),
  },
  {
    label: 'Heading',
    id: 'heading',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Description',
    id: 'description',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Create Date',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData)}
      </span>
    ),
  },
  // {
  //   label: 'Status',
  //   id: 'followup_status',
  //   format: (columnData, rowIndex, formatCallback, options) => (
  //     <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
  //       {columnData}
  //     </span>
  //   ),
  // },
  {
    label: 'Created By',
    id: 'created_by',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Assigned To',
    id: 'assigned_to',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
];

function FollowUpListByOrderId({ orderId }) {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState({});
  const { orderFollowUpData } = useSelector(followUpsItemSelector);
  const dispatch = useDispatch();

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: String(limit),
      order_id: orderId,
      ...allParams,
      ...params,
    };
    dispatch(followUpsByOrderIdsListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  //making data format for table input
  useEffect(() => {
    if (orderFollowUpData?.items?.data?.length >= 0) {
      setTableListing(orderFollowUpData?.items?.data);
    }
  }, [orderFollowUpData.items]);

  return (
    <CustomBox padding={'50px 0 0'}>
      <MatTable
        loading={orderFollowUpData?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        // action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={orderFollowUpData?.items?.totalcount || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={false}
        pagination={true}
        // filterEnable
        // rangeDateFilter
      />
    </CustomBox>
  );
}

export default FollowUpListByOrderId;
