import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux';
import { loginItemsSelector } from '../../redux/login';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthProvider';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UpdateIcon from '@mui/icons-material/Update';

export default function MenusProfile() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user } = useSelector(loginItemsSelector);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { logout } = useAuth();
  
  const handleLogout = () => logout(null);
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
        <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
        <Tooltip title="">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 0,height:5 ,fontSize:15,hover:'none',}}
            style={{ backgroundColor: 'transparent' }} 
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {/* <Avatar sx={{ width: 20, height: 20 }}>{user?.userDetails?.first_name?.substring(0,1)}</Avatar> */}
            {`${user?.userDetails?.first_name}   ${user?.userDetails?.last_name}`}
          
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        style={{   'border-radius':'4px',color:'green'}}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            borderRadius:3,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={()=>{handleClose(),navigate('/myAccount')}} >
        
          {/* <Link to='/myAccount' className='textDecoration-none' style={{'color':'black',display: 'inline-block'}} > */}
          <AccountCircleIcon style={{fontSize:'15px',marginRight:'4px',marginLeft:'3px'}} /><span style={{fontSize:'12px',marginRight:'3px'}}> Profile</span>
         
        </MenuItem>
        <Divider  style={{ marginBottom: '1px',marginTop:'1px' }} />

        <MenuItem onClick={()=>{handleClose(),navigate('/updatePassword')}} >
        <UpdateIcon  style={{fontSize:'15px',marginRight:'4px',marginLeft:'3px'}}/> <span style={{fontSize:'12px',marginRight:'3px'}}>Change Password</span>
        </MenuItem>

         <Divider  style={{ marginBottom: '1px',marginTop:'1px' }} />
        <MenuItem onClick={()=>{handleClose(),handleLogout()}} >
        <LogoutIcon  style={{fontSize:'15px',marginRight:'4px',marginLeft:'3px'}}/> <span style={{fontSize:'12px',marginRight:'3px'}}>Sign Out</span>
        </MenuItem>
       
      </Menu>
    </React.Fragment>
  );
}
