import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { addCompanyMaster, companyGroupListingsNoRedux, companyListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { addSubscriptionPlan, subscriptionListings } from '../../Services/SubscriptionApis';
import { addStarIndustry, editStarIndustry } from '../../Services/starIndustriesApis';

const limit = DATA_LIMIT;

function AddStarIndustry({ open, handleOpen, setPage, handleLast, data, type }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    if (type === 'add') {
      let resp = await addStarIndustry(values)
      if (resp) {
        resetForm({
          group_id: "",
          category: "",
          sub_category: "",
        })
        setPage(1)
      }
      handleLast();
      handleOpen(false);
    }
    else if (type === 'edit') {
      let finalObj = { ...values, star_industry_id: data?.star_industry_id }
      let resp = await editStarIndustry(finalObj);
      if (resp) {
        resetForm({
          group_id: "",
          category: "",
          sub_category: "",
        })
        setPage(1)
      }
      handleLast();
      handleOpen(false)

    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      group_id: data?.group_id || "",
      category: data?.category || "",
      sub_category: data?.sub_category || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      category: Yup.string().required('Plan description is required.'),
      sub_category: Yup.string().required('Sub Category is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={1}>
          {type === 'add' ? 'Add' : 'Edit'} Star Industry
        </DialogTitle>
        <br/>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="group_id"
                  label="Group ID"
                  placeholder="Group ID"
                  value={formik.values.group_id}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="category"
                  label="Category"
                  placeholder="Category"
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.category && Boolean(formik.errors.category)}
                  helperText={formik.touched.category && formik.errors.category}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="sub_category"
                  label="Sub Category"
                  placeholder="Sub Category"
                  value={formik.values.sub_category}
                  onChange={formik.handleChange}
                  multiline
                  rows={3}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.sub_category && Boolean(formik.errors.sub_category)}
                  helperText={formik.touched.sub_category && formik.errors.sub_category}
                />
              </Grid>

            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    group_id: "",
                    category: "",
                    sub_category: "",
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddStarIndustry;
