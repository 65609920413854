import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { addCompanyMaster, companyGroupListingsNoRedux, companyListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { addSubscriptionPlan, subscriptionListings } from '../../Services/SubscriptionApis';
import { addStarIndustry, editStarIndustry } from '../../Services/starIndustriesApis';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import { addModel, assignImageModel, assignImageReleaseModel, editModel, modelListingsNoRedux } from '../../Services/modelApi';
import { fetchInPromiseFile, fetchInPromiseFileModel } from '../../lib/helper';
import ApiConfig from '../../config/ApiConfig';
import DownloadIcon from '@mui/icons-material/Download';
import { getItemFromStorage } from '../../storage/Storage';

const limit = DATA_LIMIT;

function ViewModelImage({ open, handleOpen, setPage, handleLast, data, type }) {
  const [loading, setLoading] = useState(false);

  const [selectedReleaseId, setSelectedReleaseId] = useState([]);
  const selectRef = useRef(null);
  let baseUrl = 'https://s3.ap-south-1.amazonaws.com/awsimages.imagesbazaar.com/model_users/';
  //12/closeup_image

  useEffect(() => {

  }, [data])

  const blurAsync = () => {
    selectRef.current?.blur();
  };

  const handleDownloadImage = async () => {
    try {
      const encryptedImageName = CryptoJS.AES.encrypt(
        String(data?.image_name),
        process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY).toString();
      const encryptedContributorId = CryptoJS.AES.encrypt(String(data?.user_id), process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY).toString();
      const encryptedFolderName = CryptoJS.AES.encrypt(String(data?.folder_name), process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY).toString();
      // const encryptedPayload = { image_name: encryptedImageName, folder_name: encryptedFolderName, contributor_id: encryptedContributorId, };
      let folderName = 'model_users'
      await toast.promise(fetchInPromiseFileModel(`${ApiConfig.downloadModelImages}?user_id=${data?.user_id}&folder_name=${folderName}`, {
        headers: {
          "Authorization": getItemFromStorage('token')
        }
      }, '', `user_id=${data?.user_id}`),
        // await toast.promise(fetchFile(`${ApiConfig.downloadFolderImages}?contributor_id=${encryptedPayload?.contributor_id}&folder_name=${encryptedPayload?.folder_name}`),
        {
          pending: "Downloading your file....",
          success: "Please wait while your Image/s is/are downloading. It may take a few moments.",
          error: "Failed to download file.",
        }

      );
    } catch (e) {
      console.log(e)
    }
  };




  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Model:{data?.name}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              width: 400
            }}

          >
            <Grid container spacing={2}>
              {data?.closeup_image && data?.midshot_image && data?.full_length_image ? <> <Grid item xs={12} sm={4} md={4}>
                <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight500'>Close Up</Typography> </span>

                <img src={`${baseUrl}${data?.user_id}/${data?.closeup_image}`} className='img-fluid' />

              </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight500'>Mid Shot</Typography> </span>

                  <img src={`${baseUrl}${data?.user_id}/${data?.midshot_image}`} className='img-fluid' />

                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight500'>Full Length</Typography> </span>

                  <img src={`${baseUrl}${data?.user_id}/${data?.full_length_image}`} className='img-fluid' />

                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomButton className="noMinWidth buttonDense" onClick={() => { handleDownloadImage() }}
                    toolTipTittle={'Download Images'}
                    sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}>
                    <DownloadIcon />
                  </CustomButton>
                </Grid>


              </> : <>
                <Grid item xs={12} sm={11} md={11} sx={{textAlign:'rigth'}} >
                  <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight500'>No Image Uploaded!</Typography> </span>


                </Grid>

              </>}

            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >

              <CustomButton
                onClick={() => {

                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel

              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewModelImage;
