import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { deleteApiPayload, errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {setAllModelImageListing, setAllModelListingError, setAllModelListingLoading, setLoading ,setModelAssignedImageListing,setModelAssignedListingLoading,setModelImagesListing,setModelImagesListingError,setModelImagesListingLoading,setModelListing, setModelListingError, setModelListingLoading, setModelShootImageListing, setModelShootImageListingError, setModelShootImageListingLoading, setModelUnAssignedImageListing, setModelUnAssignedListingLoading, setnewModelListingError,  setnewModelsListing, setnewModelsListingLoading } from '../redux/model';

export const ModelListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setModelListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.modelList}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setModelListing(resp.data.data));
          dispatch(setModelListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setModelListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setModelListingLoading(false));
      }
    };
  }
};

export const NewModelsListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setnewModelsListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.modelsListing}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setnewModelsListing(resp.data.data));
          dispatch(setnewModelsListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setnewModelListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setnewModelsListingLoading(false));
      }
    };
  }
};

export const downloadImageModelUploaded = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(`${ApiConfig.downloadModelImages}?user_id=${payload?.user_id}&folder_name=${payload?.folder_name}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return resp.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const addModel= async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createModel}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const assignImageModel= async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.assigneImageToModel}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};


export const assignImageReleaseModel= async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editimageReleaseId}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const editModel= async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editModel}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const deleteModel = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.delete(`${ApiConfig.deleteModel}`, deleteApiPayload(payload));
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const ModelImagesListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setModelImagesListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.modelImagesList}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setModelImagesListing(resp.data.data));
          dispatch(setModelImagesListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setModelImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setModelImagesListingLoading(false));
      }
    };
  }
};

export const modelExportList= async (payload, header) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.modelCSV}`, payload, header);
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return {status: false, data: null}
    }
  }
};

export const modelUnassignedExportList= async (payload, header) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.modelAssignCSV}`, payload, header);
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return {status: false, data: null}
    }
  }
};

///api/admin/updateReleaseId

export const UnAssinedImageModelListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setModelUnAssignedListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.modelImageAssignAndUnassign}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setModelUnAssignedImageListing({data:resp.data.data,totalCount:resp?.data?.totalCount}));
          dispatch(setModelUnAssignedListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setModelImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setModelUnAssignedListingLoading(false));
      }
    };
  }
};

export const AllImageModelListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllModelListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.modelImageAssignAndUnassign}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllModelImageListing(resp?.data));
          dispatch(setAllModelListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllModelListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllModelListingLoading(false));
      }
    };
  }
};
export const AssinedImageModelListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setModelAssignedListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.modelImageAssignAndUnassign}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setModelAssignedImageListing({data:resp.data.data,totalCount:resp?.data?.totalCount}));
          dispatch(setModelAssignedListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setModelImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setModelAssignedListingLoading(false));
      }
    };
  }
};

export const modelListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      let url = `${ApiConfig.modelList}`;
      // if (payload?.type === 'Video' || payload?.type === 'video')
      //   url += `&type=2`;
      // else if (payload?.type === 'Image' || payload?.type === 'image' || payload?.type === 'Star' || payload?.type === 'star')
      //   url += `&type=1`;
      const resp = await axios.post(url,payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};



