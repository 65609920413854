import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allVideos: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  allVideosNew: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllVideosListing: (state, action) => {
      state.allVideos.items = action.payload;
      state.allVideos.error = {
        isError: false,
        message: '',
      };
    },
    setAllVideosListingLoading: (state, action) => {
      state.allVideos.loading = action.payload;
    },
    setAllVideosListingError: (state, action) => {
      state.allVideos.items = {};
      state.allVideos.error = action.payload;
    },

    setAllVideosNewListing: (state, action) => {
      state.allVideosNew.items = action.payload;
      state.allVideosNew.error = {
        isError: false,
        message: '',
      };
    },
    setAllVideosNewListingLoading: (state, action) => {
      state.allVideosNew.loading = action.payload;
    },
    setAllVideosNewListingError: (state, action) => {
      state.allVideosNew.items = {};
      state.allVideosNew.error = action.payload;
    },
  },
});

export default videosSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllVideosListing,
  setAllVideosListingError,
  setAllVideosListingLoading,
  setAllVideosNewListing,
  setAllVideosNewListingError,
  setAllVideosNewListingLoading

} = videosSlice.actions;

export const videosItemsSelector = (state) => state.videos;
