import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { DATA_LIMIT } from '../../data/constants';
import { imageShootsNoRedux, mergeShoots } from '../../Services/imageApis';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';

const limit = DATA_LIMIT;

function MergeShootsModal({ open, handleOpen, data, setPage, handleLast, initialData }) {
  const [loading, setLoading] = useState(false);
  const [shootsData, setShootsData] = useState(initialData);
  const { shoot_id } = data;
  const shootRef=useRef(null);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let arrayOfShoots = shootsData?.map(a => a?.value);
    arrayOfShoots?.push(data?.shoot_id);
    let mergeArrayString = arrayOfShoots?.join(",");
    let resp = await mergeShoots({ shoot_ids: mergeArrayString })
    if (resp) {
      resetForm({
        shoot_ids: ""
      })
      setPage(1)
      handleLast();
      handleOpen(false)
    }
    setLoading(false)

  };

  const formik = useFormik({
    initialValues: {
      shoot_ids: shootsData,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      shoot_ids: Yup.array().min(1, "Please add Atleast one Shoot for merge")
    }),
    onSubmit: handleConfirm,
  });

  const getShootsListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      let allIds = [];
      if (initialData?.length > 0) {
        let tempArr = initialData?.map(a => a?.label);
        allIds = [...tempArr];
      }
      allIds?.push(shoot_id);
      let newShootString = allIds?.join(",");
      let payload = { insertion_status: "old", page: 1, limit: 10, search: inputValue, shoot_id: newShootString }
      let data = await imageShootsNoRedux(payload)
      let updatedData = data?.elements?.rows?.map((item, index) => {
        return {
          value: item?.shoot_id,
          label: item?.shoot_id,
          rank: item?.status,
          sno: item?.category,
        }
      })
      return updatedData || []
    }
  }

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        fullWidth
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Merge Shoots

        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Typography
              variant='span'
              paddingBottom={5}
            >
              Shoot:{data?.shoot_id}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>
                <CustomAsyncSelect
                  promiseOptions={getShootsListing}
                  closeMenuOnSelect={false}
                  controlStyle={{
                    padding: 5
                  }}
                  cacheOptions={true}
                  value={shootsData}
                  handleChange={(e) => {
                    setShootsData(e);
                    shootRef?.current?.blur();
                  }}
                  width={'100%'}
                  ref={shootRef}
                  dropDownZIndex={100000}
                  labelShrink={true}
                  label="Search"
                  error={formik.touched.shoot_ids && Boolean(formik.errors.shoot_ids)}
                  helperText={formik.touched.shoot_ids && formik.errors.shoot_ids}
                />
              </Grid>

            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={() => {
                  setShootsData('')
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MergeShootsModal;
