import { Grid, Typography, useTheme, Checkbox, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getNewParameters, getObjectSubset, getParameters, validDate } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import { contributorItemsSelector, setResetContributorPaymentDivision } from '../../redux/contributor';
import { ContributorPaidMade, contributorPaymentDivisionLists, contributorPaymentLists } from '../../Services/ContributorApis';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AddPaymentContributer from '../Modals/AddPaymentContributer';
import { PaidFiltersData } from '../../data/contactusData';


const limit = DATA_LIMIT;

const column = [
  // {
  //   label: 'Paid Process',
  //   id: 'paid_process',
  //   format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
  //     <>
  //       { row?.paid== '0' ? <Checkbox
  //         color="primary"
  //         checked={arr.fields.includes(row?.sold_image_id)}
  //         inputProps={{
  //             'aria-labelledby': row?.sold_image_id,
  //         }}
  //         onClick={(event) => {
  //           arr?.func(row, event)
  //         }}
  //         sx={{padding: 0}}
  //       /> : null }
  //     </>
  //   ),
  // },
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>)
  },
  {
    label: 'Image Name',
    id: 'image_name',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>)
  },
  {
    label: 'Client',
    id: 'client',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>)
  },
  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>)
  },
  {
    label: 'Download Date',
    id: 'Download_on',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{formatDate(columnData, 'dd-mm-yyyy')}</span>)
  },
  {
    label: 'Ordered By',
    id: 'order_by',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Amount',
    id: 'amount',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>{columnData || 'N/A'} </span>)
  },
  {
    label: 'Discount',
    id: 'Discount',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>{columnData} </span>)
  },
  {
    label: 'Net Payable Amount',
    id: 'discounted_price',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>{columnData} </span>)
  },
  {
    label: 'Royalty',
    id: 'royalty',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>{columnData} </span>)
  },
  //paid_on
  {
    label: 'Payment Status',
    id: 'paid',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>

      {/* {columnData ? 'Paid' : 'Unpaid'} */}
      <Typography
        variant="span"
        sx={{
          color:
            columnData
              ? 'success.main'
              : 'danger.main',

          width: 90,
          display: 'inline-block',
        }}
      >
        {columnData
          ? 'Paid'
          : 'Unpaid'
        }
      </Typography>
      <Typography variant="span" component={'div'}>{row?.paid_on ? ` ${formatDate(row?.paid_on, 'dd/mm/yyyy')}` : ''}</Typography>
    </span>)
  },
  // {
  //   label: 'Paid On',
  //   id: 'paid_on',
  //   format: (columnData, rowIndex, formatCallback, arr, errors, row) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}> { formatDate(columnData,'dd/mm/yyyy')}</span>)
  // },



  //   {
  //     label: 'Joined At',
  //     id: 'created_at',
  //     format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{formatDate(columnData, 'dd-mm-yyyy')}</span>)
  //   },
];

function PaymentMadeDivision() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allParams, setAllParams] = useState(getParameters());
  const { contributorPaymentDivisionForImages } = useSelector(contributorItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const theme = useTheme()
  const [paidProcessArr, setPaidProcessArr] = useState([])
  const [paidProcessArrData, setPaidProcessArrData] = useState([])
  const [paidProcessUser, setPaidProcessUser] = useState('')
  const { emailId, contributorId, start_date, end_date } = useParams();
  const [clearSelectedCheckbox, setClearSelectedCheckbox] = useState(false);
  const [intialData,setIntialData]=useState(false);
  const [savePayload,setSavePayload]=useState(null);

  useEffect(() => {
    if (contributorPaymentDivisionForImages?.items?.rows?.length >= 0) {
      setTableListing(contributorPaymentDivisionForImages?.items?.rows);
    }
    else {
      setTableListing([]);
    }
  }, [contributorPaymentDivisionForImages.items]);

  useEffect(() => {
    dispatch(setResetContributorPaymentDivision())
  }, [])

  useEffect(() => {
    getOrderListing();
  }, [])

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);


  useEffect(() => {
    let data = getNewParameters(allParams);
    // if (formik?.values?.start_date && formik?.values?.end_date) {
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: true })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
        getOrderListing();
      }
      // }
     
    }
  }, [allParams])

  const getOrderListing = async (params = {}) => {
    let data = getNewParameters(allParams);
    const payload = {
      page: String(1),
      limit: limit,
      start_date: data?.parameters?.start_date,
      end_date: data?.parameters.end_date,
      contributor_id: emailId,
      // ...allParams,
      ...params
    }
    setSavePayload(payload);
    dispatch(contributorPaymentDivisionLists(payload));
    setPaidProcessArrData([]);
    setPaidProcessArr([]);
    setPage(Number(payload.page))
  }

  const handleShowData = () => {
    if (formik?.values.to && formik?.values.from) {
      getOrderListing()
    }
    else {
      toast.error("To and From Date Required")
    }
  }

  const formik = useFormik({
    initialValues: {
      from: "",
      to: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      from: Yup.string().required('From Date is required.'),
      to: Yup.string().required('To Date is required.'),
    }),
    onSubmit: checking,
  });

  async function checking() {
    setLoading(true)
    let resp = await getDailySalesReport(formik.values)
    if (resp.status) {
      setFileResp(resp)
      // formik?.resetForm()
      downloadBlobFile(resp.data, `DailySalesReport${formik?.values.from}-/-${formik?.values.to}`)
    }
    setLoading(false)
  }

  const handlePaidProcess = async (data, e) => {
    let updateArr = [...paidProcessArr];
    let index = updateArr.findIndex(item => item == data?.sold_image_id);
    setPaidProcessUser(true);
    if (index === -1) {
      updateArr.push(data?.sold_image_id)
      setPaidProcessArr(updateArr);

    }
    else {
      updateArr.splice(index, 1);
      setPaidProcessArr(updateArr);
      if (updateArr?.length === 0) {
        setPaidProcessUser(false);
        e.target.checked = false
      }
    }
  }
  const handleOpen = (value) => {
    setOpen(value);
  }

  const handleSoldImagePayment = async (data, resetState, dataArr, setResetState2) => {
    setAddModal(true);
    setPaidProcessArr(data);
    let arr = dataArr?.filter(a => {
      let item = data?.findIndex(id => id == a?.sold_image_id)
      if (item >= 0) return true;
      else return false
    })
    setPaidProcessArrData(arr);

  }


  const handlingLast=()=>{
    setIntialData(true);
    getOrderListing(savePayload);
   // window.location.reload();

  }

  const handleSoldOrder = async () => {
    let payload = { sold_image_ids: JSON.stringify(paidProcessArr), paid: "1" };
    let resp = await ContributorPaidMade(payload);
    if (resp) {
      setPaidProcessArr([]);
      setPaidProcessArrData([])
      setPaidProcessUser(false);
      getOrderListing();
    }
  }

  const handleAdd = (value = false) => {
    setAddModal(value);
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          {/* <CustomButton toolTipTittle={'Payment Status'}  
            className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}>{data?.paid==='0'?'Unpaid':'Paid'}</CustomButton>
         */}
          {data?.paid === '0' ? 'Unpaid' : 'Paid'}
          {/* <CheckboxField
                labelPlacement={'end'}
                value={true}
                // handleChange={(e) => { handleCheckBox(e, item?.key, index) }}
              /> */}
        </div>
      </>
    );
  };

  const handleClearSelectedCheckbox = () => {
    setClearSelectedCheckbox(false);
    setPaidProcessArr([]);
    setPaidProcessArrData([]);
  }

  return (
    <CustomBox padding={0}>

      <MatTable
        loading={contributorPaymentDivisionForImages?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getOrderListing({
            page: String(1),
            start_date: "",
            end_date: "",
            payment_type: "",
            ...getObjectSubset(data, 'start_date', 'end_date', "payment_type"),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date', "payment_type"),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        total={contributorPaymentDivisionForImages?.items?.count || 0}
        columnWiseData={[
          {
            id: "paid_process",
            value: {
              fields: paidProcessArr,
              func: handlePaidProcess
            }
          },
        ]}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getOrderListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
 
        toolBarButton={() => {
          return <>
           
          </>
        }}

        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        dropdownFilter={true}
        disableSearch={true}
        rangeDateFilter
        checkboxEnable={true}
        customCheckboxEnable={true}
        customCheckboxDataKey={'sold_image_id'}
        customCheckboxStatusKey={'paid'}
        onTableToolbarRightClick={handleSoldImagePayment}
        customLabel={'Make Payment'}
        clearSelectedCheckbox={clearSelectedCheckbox}
        intialData={intialData}
        handleClearSelectedCheckbox={handleClearSelectedCheckbox}// requred to clear filter and refresh state after api call 
        onTableToolbarRightSection={<CustomButton sx={{
          fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
          [theme.breakpoints.down("md")]: {
            flex: 1,
          },
        }}
        >Mark as paid</CustomButton>}

        selectFilterArray={[
          {
            name: 'payment_type',
            label: 'Payment Type',
            placeholder: 'Payment Type',
            options: [{ label: "Select Payment Status", value: '' }, ...PaidFiltersData.related_to]
          }
        ]}
      />
      {addModal && <AddPaymentContributer open={addModal} handleOpen={handleAdd} setPage={setPage} paidProcessArrData={paidProcessArrData} handleLast={handlingLast} arr={paidProcessArr} orderAndImageArr={tableListing} contributorId={emailId} setClearSelectedCheckbox={setClearSelectedCheckbox} />}

    </CustomBox>

  );
}


export default PaymentMadeDivision;
