import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { addCompanyMaster, companyGroupListingsNoRedux, companyListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { addSubscriptionPlan, subscriptionListings } from '../../Services/SubscriptionApis';
import { addStarIndustry, editStarIndustry } from '../../Services/starIndustriesApis';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import { addModel, assignImageModel, editModel, modelListingsNoRedux } from '../../Services/modelApi';

const limit = DATA_LIMIT;

function AssignImageToModel({ open, handleOpen, setPage, handleLast, data, type }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [selectedReleaseId, setSelectedReleaseId] = useState([]);
  const selectRef = useRef(null);

  const blurAsync = () => {
    selectRef.current?.blur();
  };

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let newReleaseIdArr = selectedReleaseId?.map((a) => a?.value)?.join(",");
    let payload = { imageid: values?.imageid, modelid: newReleaseIdArr }
    let resp = await assignImageModel(payload);
    if (resp) {
      resetForm({
        imageid: "",
        modelid: "",
      })
      setPage(1)
    }
    handleLast();
    handleOpen(false);


    setLoading(false)
  };

  const getModelListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      let data = await modelListingsNoRedux({ search: inputValue, limit: 10, page: 1, search_by: 'releaseid' })
      let updatedData = data?.data?.map((item, index) => {
        return {
          value: item?.releaseid,
          label: item?.releaseid,

        }
      })

      return updatedData || []
    }
  }


  const formik = useFormik({
    initialValues: {
      imageid: data?.Name || "",
      modelid: selectedReleaseId || [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      imageid: Yup.string().required('Image Id is required.'),
      modelid: Yup.array().required('Model Id is required.').min(1,"Atleast 1 Model Id is required"),

    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Assign Image:{data?.Name}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              width: 400
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <CustomAsyncSelect
                  promiseOptions={getModelListing}
                  error={formik.touched.modelid && Boolean(formik.errors.modelid)}
                  helperText={formik.touched.modelid && formik.errors.modelid}
                  ref={selectRef}
                  closeMenuOnSelect={false}
                  controlStyle={{
                    padding: 5
                  }}
                  cacheOptions={true}
                  value={selectedReleaseId}
                  handleChange={(e) => {
                    setSelectedReleaseId(e);
                    blurAsync()
                  }}
                  width={'100%'}
                  dropDownZIndex={100000}
                  labelShrink={true}
                  label="Search Model Release Id"
                />
              </Grid>

            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    imageid: "",
                    modelid: ""
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AssignImageToModel;
