import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import './App.css';
import { theme } from './config/mui-theme';
import { AuthProvider, useAuth } from './Context/AuthProvider';
import AuthRoute from './Route/AuthRoute';
import './AxiosInterceptor';
import { store } from './redux/store'
import { Provider } from 'react-redux'
import './Asset/style/login.css';

function App() {
  console.log('Welcome to imagesbazaar!!')
  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <AuthProvider>
        <Provider store={store}>
          <AuthRoute />
        </Provider>
      </AuthProvider>
    </ThemeProvider>
    </>
  );
}

export default App;
