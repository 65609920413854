import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import Divder from '../CommonComp/Divder';
import UserDetailInputField from '../User/UserDetailInputField';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import RadioField from '../CommonComp/CustomInput/RadioField';
import { CreditPeriodFollowUp, DiscountTerm, KnowAboutIb, RequirementDescriptionType, RequirementFollowups, RequirementType } from '../../data/followUpsdata';
import { toast } from 'react-toastify';
import { formatDate } from '../../lib/helper';

const FollowUpsCheckDetails = React.memo(({data, handleChange, formik, index}) => {
  return<>
    <CustomBox spacing={3} sx={{
      background: '#feffea',
      borderRadius: 0
    }}>
      <Typography variant='h5'>{data?.subLableText}</Typography>
      <Divder spacing={0.2} />
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioField
              name="followup_status"
              label={"Requirement Followups"}
              row
              // value={formik.values.email}
              value={data?.followup_status}
              onChange={(e)=>{handleChange('followup_status', e.target.value)}}
              // error={formik.touched.email && Boolean(formik.errors.email)}
              // helperText={ formik.touched.email && formik.errors.email}
              radioData={RequirementFollowups}
          />
          </Grid>
          <Grid item xs={12} md={6}>
            <UserDetailInputField
                variant="outlined"
                fullWidth
                name="description_type"
                label="Description Type"
                placeholder="Description Type"
                // value={formik.values.description_type}
                error={formik.errors.requirement_type && formik.touched.requirement_type && Array.isArray(formik.errors.requirement_type) && formik.touched.requirement_type[index]?.description_type && formik.errors.requirement_type[index] && Boolean(formik.errors.requirement_type[index]?.description_type)}
                helperText={formik.errors.requirement_type && formik.touched.requirement_type && Array.isArray(formik.errors.requirement_type) && formik.touched.requirement_type[index]?.description_type && formik.errors.requirement_type[index] && formik.errors.requirement_type[index]?.description_type}

                SelectProps={{
                    native: true,
                }}
                select
                // onChange={(e) => {
                //   formik.setFieldValue('state', e.target.value);
                // }}
                onChange={(e) => {
                    // formik.handleChange(e);
                    // handleStateChange(e.target.value);
                    handleChange('description_type', e.target.value)
                }}
            >
                {RequirementDescriptionType?.map((item,index)=>{
                  return <option key={item.value + data?.subLableText} value={item.value}>{item.label}</option>
                })}
            </UserDetailInputField>
          </Grid>
          {data?.followup_status!=3 &&
          <Grid item xs={12} md={6}>
            <UserDetailInputField
                variant="outlined"
                fullWidth
                type="date"
                name="followup_date"
                label="Followup Date"
                placeholder="Followup Date"
                value={formatDate(data.followup_date, 'yyyy-mm-dd')}
                // error={formik.touched.state && Boolean(formik.errors.state)}
                // helperText={formik.touched.state && formik.errors.state}

                // onChange={(e) => {
                //   formik.setFieldValue('state', e.target.value);
                // }}
                inputProps={{
                  min: formatDate(new Date(),'yyyy-mm-dd')
                }}
                onChange={(e)=>{handleChange('followup_date', formatDate(e.target.value, 'yyyy-mm-dd'))}}
            />
          </Grid>
           }
          <Grid item xs={12} md={6}>
            <UserDetailInputField
              variant="outlined"
              fullWidth
              name="description"
              label="Description"
              placeholder="Description"
              value={data.description}
              // error={formik.touched.state && Boolean(formik.errors.state)}
              // helperText={formik.touched.state && formik.errors.state}
              // onChange={(e) => {
              //   formik.setFieldValue('state', e.target.value);
              // }}
              onChange={(e)=>{handleChange('description', e.target.value)}}
            />
          </Grid>
        </Grid>
    </CustomBox>
    <Divder spacing={1} />
  </>
})

const FollowUpsDetails = React.memo(({formik, userData})=> {

  const [requirenmentType, setRequirenmentType] = useState([])

  const handleRequirenmentCheck = (e, item, index) =>{
    let arr = [...requirenmentType];
    let checkedArr = arr.filter((item)=>item.value)
    if(checkedArr.length < 2){
      arr[index].value = e.target.checked || false
    }else{
      if(!arr[index].value){
        toast.error('Only two selection is allowed.')
      }
      arr[index].value = false  
    }
    if(!e.target.checked){
      arr[index].followup_status = '0'
      arr[index].description_type = ''
      arr[index].followup_date = formatDate(new Date(), 'yyyy-mm-dd')
      arr[index].description = ''
    }
    // formik?.setValues((values)=>{
    //   return {
    //     ...values,
    //     requirement_type: checkedArr
    //   }

    // })
    // setSelectedRequirenmentType(checkedArr)
    setRequirenmentType(arr)
  }

  const handleRquirementItemChange = (key, value, index) =>{
    formik?.setValues((values)=>{
      let data = [...values.requirement_type]
      data[index][key] = value
      return {
        ...values,
        requirement_type: data
      }
    })
  }

  useEffect(()=>{
    if(requirenmentType.length){
      let arr = [...requirenmentType]
      let newArr = arr.filter((item)=>item.value)
      formik?.setValues((values)=>{
        return {
          ...values,
          requirement_type: newArr
        }
  
      })
      // setSelectedRequirenmentType(newArr)
    }
  },[requirenmentType])


  useEffect(()=>{
     setRequirenmentType(RequirementType?.map(a=>({...a,value:false})));
  },[userData])

  return (
    <>
      <Typography variant="h5">Last User Follows-Ups</Typography>
      <Divder spacing={1} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <UserDetailInputField
              variant="outlined"
              fullWidth
              name="ib_option"
              label="How they can to know about IB"
              placeholder="How they can to know about IB"
              value={formik.values.ib_option}
              // error={formik.touched.state && Boolean(formik.errors.state)}
              // helperText={formik.touched.state && formik.errors.state}

              SelectProps={{
                  native: true,
              }}
              select
              // onChange={(e) => {
              //   formik.setFieldValue('state', e.target.value);
              // }}
              onChange={formik.handleChange}
          >
            {KnowAboutIb?.map((item,index)=>{
              return <option key={item.value + 'ib_option'} value={item.value}>{item.label}</option>
            })}
          </UserDetailInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <UserDetailInputField
              variant="outlined"
              fullWidth
              name="discount"
              label="Discount Terms"
              placeholder="Discount Terms"
              InputLabelProps={{ shrink: true, required: true }}
              value={formik.values.discount}
              // error={formik.touched.state && Boolean(formik.errors.state)}
              // helperText={formik.touched.state && formik.errors.state}

              SelectProps={{
                  native: true,
              }}
              select
              // onChange={(e) => {
              //   formik.setFieldValue('state', e.target.value);
              // }}
              onChange={formik.handleChange}
          >
              {DiscountTerm?.map((item,index)=>{
                return <option key={item.value + 'discount'} value={item.value}>{item.label}</option>
              })}
          </UserDetailInputField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <UserDetailInputField
              variant="outlined"
              fullWidth
              name="credit_period"
              label="Credit Period"
              placeholder="Credit Period"
              value={formik.values.credit_period}
              // error={formik.touched.state && Boolean(formik.errors.state)}
              // helperText={formik.touched.state && formik.errors.state}

              SelectProps={{
                  native: true,
              }}
              select
              // onChange={(e) => {
              //   formik.setFieldValue('state', e.target.value);
              // }}
              onChange={formik.handleChange}
          >
              {CreditPeriodFollowUp?.map((item,index)=>{
                return <option key={item.value + 'credit_period'} value={item.value}>{item.label}</option>
              })}
          </UserDetailInputField>
        </Grid>
        <Grid item xs={12}>
          <CustomCheckbox
            name="requirement_type"
            label={<>Requirement Type<span style={{color: "red"}}>*</span></>}
            value={false}
            handleChange={handleRequirenmentCheck}
            row
            formGroupStyle={{mt: 1}}
            checkBoxRenderData={requirenmentType}
            error={ !Array.isArray(formik.errors.requirement_type) && formik.touched.requirement_type && Boolean(formik.errors.requirement_type) }
            helperText={ !Array.isArray(formik.errors.requirement_type) && formik.touched.requirement_type && formik.errors.requirement_type }
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {formik?.values?.requirement_type?.map((item, index)=>{
              return <Grid key={`req${index}`} item xs={12} md={6}>
                <FollowUpsCheckDetails key={index.toString()} formik={formik} index={index} data={item} handleChange={(key, value)=>{
                  handleRquirementItemChange(key, value, index)
                }}/>
                {/* {selectedRequirenmentType?.length -1 == index && <Divder spacing={2} />} */}
              </Grid>
            })}
          </Grid>
        </Grid>
       
        <Grid item xs={12} sm={6} md={4}>
          <CustomCheckbox
            name="important"
            // label={"Important follow ups"}
            labelPlacement={'start'}
            subLableText={'Important follow ups'}
            value={formik.values.important}
            handleChange={formik.handleChange}
            // checkBoxRenderData={[
            //   { value: false, name: "ib", subLableText: 'Ib'},
            //   { value: false, name: "star_collection", subLableText: 'IStar Collection'},
            //   { value: false, name: "packs", subLableText: 'Packs'},
            //   { value: false, name: "legal", subLableText: 'Legal'},
            //   { value: false, name: "others", subLableText: 'Others'},
            // ]}
            // error={
            //   formik.touched.subscription_discount &&
            //   Boolean(formik.errors.subscription_discount)
            // }
            // helperText={
            //   formik.touched.subscription_discount && formik.errors.subscription_discount
            // }
          />
        </Grid>
      </Grid>
    </>
  );
})

export default FollowUpsDetails;
