
export const CreateProposalData = {
  createPeriod: [
    {
      value: '0',
      label: '0',
    },
    {
      value: '7',
      label: '7',
    },
    {
      value: '15',
      label: '15',
    },
    {
      value: '30',
      label: '30',
    },
    {
      value: '45',
      label: '45',
    },
    {
      value: '60',
      label: '60',
    },
    {
      value: '90',
      label: '90',
    },
  ]
};

export const ProposalData = {
  chequePaymentSelected: [
    {
      label: "Cheque/Demand Draft",
      value: "cheque-demand-draft",
    },
    {
      label: "NEFT/RTGS/Wire Transfer",
      value: "neft-rtgs-wire-transfer",
    }
  ],
}
export const CreateProposalTabData = {
  tabs: [
    {
      label: 'Normal',
      id: 1,
    },
    {
      label: 'Star',
      id: 2,
    },
  ],
};