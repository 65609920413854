import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchFile, formatDate, generateArrayOfMonths, generateArrayOfYears, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { contributorItemsSelector } from '../../redux/contributor';
import { contributorSoldImagesListings, contributorUploadImagesListings, downloadImageContributor, updateImageStatus } from '../../Services/ContributorApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CryptoJS from 'crypto-js';
import MatTable from '../Tables/MatTable';
import ApiConfig from '../../config/ApiConfig';
import ConfirmationImageModal from '../Modals/ConfirmationImageModal';
import { toast } from 'react-toastify';
import { SearchByConfirmed, SearchImageBy } from '../../data/userData';
import AllFilters from '../Order/AllFilters';
const limit = 10;
const yearsList = generateArrayOfYears(20)
const monthsList = generateArrayOfMonths()

const column = [
    // {
    //     label: 'Email',
    //     id: 'email',
    //     format: (columnData, rowIndex, formatCallback, options, errors, row) => (
    //         <Typography
    //             variant='span'
    //             style={{ whiteSpace: 'nowrap' }}
    //         >
    //             {columnData}
    //         </Typography>
    //     )
    // },
    {
        label: 'Image',
        id: 'image_name',
        format: (columnData, rowIndex, formatCallback, options, errors, row) => (
            <span
                style={{ display: 'flex', width: '100%', justifyContent: 'left' }}
                onClick={() => {
                    // navigate(`${ApiConfig?.S3_BASE_URL}contributor/${data?.contributor_id}/${data?.image_name}`);
                }}
            >
                <Link className='textDecoration-none link-text' to={row?.folder_name != null ? `${ApiConfig?.S3_BASE_URL}contributor/${row?.contributor_id}/${row?.folder_name}/${columnData}` : `${ApiConfig?.S3_BASE_URL}contributor/${row?.contributor_id}/${columnData}`} target={'_blank'}>
                    <img style={{ maxWidth: 200, maxHeight: 200 }} src={row?.folder_name != null ? `${ApiConfig?.S3_BASE_URL}contributor/${row?.contributor_id}/${row?.folder_name}/${columnData}` : `${ApiConfig?.S3_BASE_URL}contributor/${row?.contributor_id}/${columnData}`} />
                </Link>
            </span >
        )
    },
    {
        label: 'Image Name',
        id: 'image_name',
        format: (columnData, rowIndex, formatCallback, options, errors, row) => (
            <span
                style={{ display: 'flex', width: '100%', justifyContent: 'left' }}
                onClick={() => {
                    // navigate(`${ApiConfig?.S3_BASE_URL}contributor/${data?.contributor_id}/${data?.image_name}`);
                }}
            >{columnData || 'N/A'}
                {/* <Link className='textDecoration-none link-text' to={row?.folder_name != null ? `${ApiConfig?.S3_BASE_URL}contributor/${row?.contributor_id}/${row?.folder_name}/${columnData}` : `${ApiConfig?.S3_BASE_URL}contributor/${row?.contributor_id}/${columnData}`} target={'_blank'}>
                    <img style={{ maxWidth: 200, maxHeight: 200 }} src={row?.folder_name != null ? `${ApiConfig?.S3_BASE_URL}contributor/${row?.contributor_id}/${row?.folder_name}/${columnData}` : `${ApiConfig?.S3_BASE_URL}contributor/${row?.contributor_id}/${columnData}`} />
                </Link> */}
            </span >
        )
    },
    {
        label: 'Status',
        id: 'status',
        format: (columnData) => (<Typography
            variant='span'
            sx={{
                ...(columnData == 1 ? { color: 'success.main' } : columnData == 2 ? { color: 'danger.main' } : columnData == 0 ? { color: 'info.main' } : {}),
                whiteSpace: "nowrap", display: 'inline-block'
            }}>
            {columnData == 0 ? 'Pending' : columnData == 1 ? 'Approved' : columnData == 2 ? 'Rejected' : 'N/A'}
        </Typography>)
    },
    {
        label: 'Comment',
        id: 'comment',
        format: (columnData) => (
            <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>
        )
    },

];

function ContributorUploadedImageList() {
    const [tableListing, setTableListing] = useState([])
    const [page, setPage] = useState(1);
    const [updateData, setUpdateData] = useState(null);
    const [value, setValue] = useState({});
    const [allParams, setAllParams] = useState(getParameters());
    const { contributorUploadedImagesList } = useSelector(contributorItemsSelector);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();

    const folderName = location.state;
    const [foldername, setFoldername] = useState(folderName);
    const handleCofirmModalOpen = (value) => {
        setConfirmDelete(value);
    };
    const [filtersArray, setFiltersArray] = useState([
        {
            label: 'Search By',
            id: 'search_by',
            type: 'select',
            linkedWith: null,
            value: '',
            onChange: (e, index, value) => {
                setFiltersArray((prevState) => {
                    let newState = [...prevState];
                    newState[index].value = e.target.value;
                    return newState;
                });
            },
            style: {},
            dataArr: SearchImageBy,
            props: {},
            isVisible: true,
        },
        {
            label: 'Search Text',
            id: 'search',
            type: 'input',
            linkedWith: null,
            value: '',
            onChange: (e, index, value) => {
                setFiltersArray((prevState) => {
                    let newState = [...prevState];
                    // newState[index].value = e.target.value;
                    newState[index].value = e?.target?.value?.trim();
                    return newState;
                });
            },
            style: {},
            dataArr: [],
            props: {},
            isVisible: true,
        },
    ]);
    const handleFilter = (data, allDatavalues, dataWithValue) => {
        getListing(
            {
                page: String(1),
                folder_name: foldername,
                ...dataWithValue,
            },
            true,
        );
        setAllParams({
            page: String(1),
            folder_name: foldername,
            ...dataWithValue,
        });
    };
    const handleClear = () => {
        const data = getObjectSubset({ ...allParams }, 'tab', 'page');
        setAllParams(data);
        setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            // newState[2].isVisible = true;
            // newState[3].isVisible = true;
            // newState[4].isVisible = true;

            newState[0].value = '';
            newState[1].value = '';
            // newState[2].value = '';
            // newState[3].value = '';
            // newState[4].value = '';
            // newState[5].isVisible = true;
            return newState;
        });
    };
    const getListing = async (params = {}) => {
        const payload = {
            page: String(1),
            limit: limit,
            folder_name: foldername,
            // ...getObjectSubset({
            //     start_date: formatDate(new Date().setDate(new Date().getDate() - 30), "yyyy-mm-dd") + ' 00:00:00',
            //     end_date: formatDate(new Date(), "yyyy-mm-dd") + ' 23:59:59'
            // }, 'start_date', 'end_date'),
            ...allParams,
            ...params
        }
        dispatch(contributorUploadImagesListings(payload))
        setPage(Number(payload.page))
    }

    useEffect(() => {
        getListing();
    }, []);
    useEffect(() => {
        let params = getParameters()
        setAllParams({
            folder_name: foldername,
            ...params
        })
    }, [location?.search]);
    useEffect(() => {
        getListing();
    }, [allParams])
    const handleDownloadImage = async (data) => {
        try {
            const encryptedImageName = CryptoJS.AES.encrypt(
                String(data?.image_name),
                process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY).toString();
            const encryptedContributorId = CryptoJS.AES.encrypt(String(data?.contributor_id), process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY).toString();
            const encryptedFolderName = CryptoJS.AES.encrypt(String(data?.folder_name), process.env.REACT_APP_DOWNLOAD_AES_SECRET_KEY).toString();
            const encryptedPayload = { image_name: encryptedImageName, folder_name: encryptedFolderName, contributor_id: encryptedContributorId, };
            toast.promise(fetchFile(`${ApiConfig.downloadImages}?contributor_id=${data?.contributor_id}&folder_name=${data?.folder_name}&image_name=${data?.image_name}`),
                // toast.promise(fetchFile(`${ApiConfig.downloadImages}?contributor_id=${encryptedPayload?.contributor_id}&folder_name=${encryptedPayload?.folder_name}&image_name=${encryptedPayload?.image_name}`),
                {
                    pending: "Downloading your file....",
                    success: "Please wait while your Image/s is/are downloading. It may take a few moments.",
                    error: "Failed to download file.",
                }

            );
        } catch (e) {
            console.log(e)
        }
    };
    //making data format for table input
    useEffect(() => {
        if (contributorUploadedImagesList?.items?.rows?.length >= 0) {
            let data = contributorUploadedImagesList?.items?.rows?.map((item) => {
                return {
                    comment: item?.comment,
                    image_name: item?.image_name,
                    contributor_id: item?.contributor_id,
                    email: item?.contributor?.email,
                    name: item?.contributor?.name,
                    status: item?.status,
                    folder_name: item?.folder_name,
                    contributor_upload_image_id: item?.contributor_upload_image_id
                }
            })
            setTableListing(data)
        }
    }, [contributorUploadedImagesList?.items]);


    const actionBody = (data) => {
        return (
            <>
                <div style={{ display: "flex" }}>
                    <CustomButton className="buttonDense" sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '135px' }} onClick={() => { handleDownloadImage(data) }}>Download</CustomButton>
                    {/* {data?.status == 0 ? <> */}
                    <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '110px', bgcolor: 'buttonInfo.main' }} onClick={() => { handleApprove(1, data.contributor_upload_image_id) }}>Approve</CustomButton>
                    <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '135px' }} onClick={() => { handleCofirmModalOpen(true); setUpdateData(data.contributor_upload_image_id); setValue(2) }}>Reject</CustomButton>

                    {/* </> : null} */}

                </div>
            </>
        );
    };

    const handleApprove = async (value, updateData) => {
        if (value && updateData) {
            const resp = await updateImageStatus({ contributor_upload_image_id: updateData, status: value });
            if (resp) {
                getListing({ page: '1' });
                setPage(1);
                setUpdateData(null);
            }
        } else {
            setUpdateData(null);
        }
    };
    return (
        <CustomBox padding={0} sx={{ minHeight: 200, display: "flex", marginTop: "20px", justifyContent: "center", alignItems: "center" }}>
            <MatTable
                loading={contributorUploadedImagesList?.loading}
                showCheckbox={false}
                columns={column}
                data={tableListing}
                onApplyFilter={(data = {}) => {
                    getListing({
                        page: String(1),
                    });
                    if (Object.keys(data).length > 0) {
                        setAllParams({
                            ...allParams,
                            page: String(1),
                            ...getObjectSubset(data, 'start_date', 'end_date'),
                        });
                    } else {
                        setAllParams({
                            page: String(1),
                        });
                    }
                }}
                action={actionBody}
                page={page}
                perPage={limit}
                total={contributorUploadedImagesList?.items?.count || 0}
                setPage={setPage}
                handlePageChange={(newPage) => {
                    getListing({
                        page: String(newPage)
                    })
                    setAllParams({
                        ...allParams,
                        page: String(newPage)
                    })
                }}
                columnWiseData={[
                    {
                        id: 'contributor_id',
                        value: getNewParameters(allParams)?.search,
                    },
                ]}
                toolbarEnable={true}
                pagination={true}
                filtersUrlData={allParams || {}}
                // filterEnable
                disableSearch
                rangeDateFilter
                customFilter={() => (
                    <AllFilters
                        filtersArray={filtersArray}
                        handleClick={handleFilter}
                        handleClear={handleClear}
                    />
                )}
            />
            {confirmDelete && (
                <ConfirmationImageModal
                    open={confirmDelete}
                    status={value}
                    updateData={updateData}
                    setUpdateData={setUpdateData}
                    getListing={getListing}
                    setPage={setPage}
                    // handleConfirm={handleReject}
                    setOpen={handleCofirmModalOpen}
                />
            )}
        </CustomBox>
    );
}

export default ContributorUploadedImageList;
