import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { addCompanyMaster, companyGroupListingsNoRedux, companyListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { commonItemsSelector } from '../../redux/common';
import { getState } from '../../Services/commonService';

const limit = DATA_LIMIT;

function AddCompanyMaster({ open, handleOpen }) {
  const [loading, setLoading] = useState(false);
  const [groupData, setGroupData] = useState(null);
  const { stateData } = useSelector(commonItemsSelector);
  const [selectedState, setSelectedState] = useState({});
  const [stateList, setStateList] = useState([]);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await addCompanyMaster(values)
    if (resp) {
      resetForm({
        company_group_id: "",
        company_name: "",
        short_company_name: "",
        state: "",
        gst_number: "",
        company_address: ""
      })
      dispatch(companyListings({
        page: String(1),
        limit: limit,
        status: String(1),
      }))
      handleOpen(false)
      setGroupData(null)
    }
    setLoading(false)
  };

  const getGroupsListing = async (inputValue) => {
    if (inputValue && inputValue.length > 2) {
      let data = await companyGroupListingsNoRedux({
        company_group_name: inputValue,
        page: "1",
        limit: '20'
      })
      if (data) {
        let updatedData = data?.map((item, index) => {
          return {
            value: item.company_group_id,
            label: item.company_group_name
          }
        })
        return updatedData || []
      }
      return []
    }
  }

  const handleData = (data, index) => {
    if (data) {
      setGroupData(data)
      formik.setFieldValue('company_group_id', data?.value)
    } else {
      formik.setFieldValue('company_group_id', "")
    }

  }

  const handleStateChange = (val) => {
    let stateSelected = stateData?.items?.filter(
      (item) => item.state_name == val
    );

    if (stateSelected.length) {
      setSelectedState(stateSelected[0]);
    }
  };

  useEffect(() => {
    // getCountryDetails();
    dispatch(getState(1));
  }, []);

  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  }, [stateData]);

  const formik = useFormik({
    initialValues: {
      company_group_id: "",
      company_name: "",
      short_company_name: "",
      state: "",
      gst_number: "",
      company_address: ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      company_group_id: Yup.string().required('Company group is required.'),
      company_name: Yup.string().required('Company name is required.'),
      short_company_name: Yup.string().required('Company short name is required.'),
      state: Yup.string().required('State is required.'),
      // gst_number: Yup.string().required('GST number is required.'),
      company_address: Yup.string().required('Company address is required.'),
      gst_number: Yup.string().required('GST number is required.')
        .trim()
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Please enter valid GST Number"
        )
        .test(
          "isValidGST",
          "Please enter correct GST Number",
          function (value) {
            let isValid = value?.startsWith(
              selectedState?.state_code?.toString().length > 1
                ? selectedState?.state_code
                : `0${selectedState?.state_code}`
            );
            return value === "" || value === null || value === undefined
              ? true
              : isValid;
          }
        ),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Add Company
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomAsyncSelect
                  promiseOptions={getGroupsListing}
                  closeMenuOnSelect={false}
                  controlStyle={{
                    padding: 10
                  }}
                  isMulti={false}
                  width={'100%'}
                  dropDownZIndex={2000}
                  cacheOptions={true}
                  value={groupData}
                  handleChange={(e) => {
                    handleData(e);
                  }}
                  error={formik.touched.company_group_id && Boolean(formik.errors.company_group_id)}
                  helperText={formik.touched.company_group_id && formik.errors.company_group_id}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="company_name"
                  label="Company Name"
                  placeholder="Company Name"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                  helperText={formik.touched.company_name && formik.errors.company_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="short_company_name"
                  label="Company Short Name"
                  placeholder="Company Short Name"
                  value={formik.values.short_company_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.short_company_name && Boolean(formik.errors.short_company_name)}
                  helperText={formik.touched.short_company_name && formik.errors.short_company_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="state"
                  label="Company State"
                  placeholder="Company State"
                  value={formik.values.state}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}

                  SelectProps={{
                    native: true,
                  }}
                  select
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleStateChange(e.target.value);
                  }}
                >
                  <option key={'Select State'} value={''}>
                    Select state
                  </option>
                  {stateList &&
                    stateList?.map((state) => {
                      return (
                        <option key={state.value} value={state.label}>
                          {state.label}
                        </option>
                      );
                    })}
                </CustomInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="gst_number"
                  label="Company GST Number"
                  placeholder="Company GST Number"
                  value={formik.values.gst_number}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.gst_number && Boolean(formik.errors.gst_number)}
                  helperText={formik.touched.gst_number && formik.errors.gst_number}

                  onChange={(e) => {
                    formik.setFieldTouched("gst_number", true, true);
                    formik.setFieldValue("gst_number", e.target.value.toUpperCase());
                  }}
                  inputProps={{
                    maxLength: 15
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="company_address"
                  label="Company Address"
                  placeholder="Company Address"
                  value={formik.values.company_address}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.company_address && Boolean(formik.errors.company_address)}
                  helperText={formik.touched.company_address && formik.errors.company_address}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    company_group_id: "",
                    company_name: "",
                    short_company_name: "",
                    state: "",
                    gst_number: "",
                    company_address: ""
                  });
                  setGroupData(null)
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddCompanyMaster;
