import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  //   pendingOrders: {
  //     error: {
  //       isError: false,
  //       message: '',
  //     },
  //     items: [],
  //   },
  cmsData: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },
  cmsfooterData: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },
  testiMonialData: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },
  faqsData: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },
  imagePriceData: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },
  downloadTimeData: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },
  videoPriceData: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },
  bannersImagesData: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },
  downloadTimeData: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },
  horizonatlData: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },
   verticalData: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false,
  },


};

export const cmsSlice = createSlice({
  name: 'cmsData',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCMSLoading: (state, action) => {
      state.cmsData.loading = action.payload;
    },
    setCmsData: (state, action) => {
      state.cmsData.items = action.payload;
      state.cmsData.error = {
        isError: false,
        message: '',
      };
    },
    setCmsError: (state, action) => {
      state.cmsData.items = [];
      state.cmsData.error = action.payload;
    },

    setCMSFooterLoading: (state, action) => {
      state.cmsfooterData.loading = action.payload;
    },
    setCmsFooterData: (state, action) => {
      state.cmsfooterData.items = action.payload;
      state.cmsfooterData.error = {
        isError: false,
        message: '',
      };
    },
    setCmsFooterError: (state, action) => {
      state.cmsfooterData.items = [];
      state.cmsfooterData.error = action.payload;
    },

    setTestmonialLoading: (state, action) => {
      state.testiMonialData.loading = action.payload;
    },
    setTestmonialData: (state, action) => {
      state.testiMonialData.items = action.payload;
      state.testiMonialData.error = {
        isError: false,
        message: '',
      };
    },
    setTestmonialError: (state, action) => {
      state.testiMonialData.items = [];
      state.testiMonialData.error = action.payload;
    },
    setFAQSDataLoading: (state, action) => {
      state.faqsData.loading = action.payload;
    },
    setFAQSDataData: (state, action) => {
      state.faqsData.items = action.payload;
      state.faqsData.error = {
        isError: false,
        message: '',
      };
    },
    setFAQSDataError: (state, action) => {
      state.faqsData.items = [];
      state.faqsData.error = action.payload;
    },

    setImagePriceDataLoading: (state, action) => {
      state.imagePriceData.loading = action.payload;
    },
    setImagePriceListData: (state, action) => {
      state.imagePriceData.items = action.payload;
      state.imagePriceData.error = {
        isError: false,
        message: '',
      };
    },
    setImagePriceDataError: (state, action) => {
      state.imagePriceData.items = [];
      state.imagePriceData.error = action.payload;
    },

    setDownloadTimeDataLoading: (state, action) => {
      state.downloadTimeData.loading = action.payload;
    },
    setDownloadTimeData: (state, action) => {
      state.downloadTimeData.items = action.payload;
      state.downloadTimeData.error = {
        isError: false,
        message: '',
      };
    },
    setDownloadTimeDataError: (state, action) => {
      state.downloadTimeData.items = [];
      state.downloadTimeData.error = action.payload;
    },

    setHorizontalDataLoading: (state, action) => {
      state.horizonatlData.loading = action.payload;
    },
    setHorizontalData: (state, action) => {
      state.horizonatlData.items = action.payload;
      state.horizonatlData.error = {
        isError: false,
        message: '',
      };
    },
    setHorizontalDataError: (state, action) => {
      state.horizonatlData.items = [];
      state.horizonatlData.error = action.payload;
    },
    setVerticalLoading: (state, action) => {
      state.verticalData.loading = action.payload;
    },
    setVerticalData: (state, action) => {
      state.verticalData.items = action.payload;
      state.verticalData.error = {
        isError: false,
        message: '',
      };
    },
    setVerticalDataError: (state, action) => {
      state.verticalData.items = [];
      state.verticalData.error = action.payload;
    },


    //verticalData

    setVideoPriceDataLoading: (state, action) => {
      state.videoPriceData.loading = action.payload;
    },
    setVideoPriceListData: (state, action) => {
      state.videoPriceData.items = action.payload;
      state.videoPriceData.error = {
        isError: false,
        message: '',
      };
    },
    setVideoPriceDataError: (state, action) => {
      state.videoPriceData.items = [];
      state.videoPriceData.error = action.payload;
    },
    setbannersImagesDataLoading: (state, action) => {
      state.bannersImagesData.loading = action.payload;
    },
    setbannersImagesListData: (state, action) => {
      state.bannersImagesData.items = action.payload;
      state.bannersImagesData.error = {
        isError: false,
        message: '',
      };
    },
    setbannersImagesDataError: (state, action) => {
      state.bannersImagesData.items = [];
      state.bannersImagesData.error = action.payload;
    },
  },
});

export default cmsSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setCmsData,
  setCmsError,
  setCMSLoading,
  setCMSFooterLoading,
  setCmsFooterData,
  setCmsFooterError,
  setTestmonialData,
  setTestmonialLoading,
  setTestmonialError,
  setFAQSDataData,
  setFAQSDataError,
  setFAQSDataLoading,
  setImagePriceDataLoading,
  setImagePriceListData,
  setImagePriceDataError,
  setVideoPriceDataLoading,
  setVideoPriceListData,
  setVideoPriceDataError,
  setDownloadTimeDataLoading,
  setDownloadTimeData,
  setDownloadTimeDataError,
  setbannersImagesDataLoading,
  setbannersImagesListData,
  setbannersImagesDataError,
  setHorizontalDataLoading,
  setHorizontalData,
  setHorizontalDataError,
  setVerticalDataError,
  setVerticalData,
  setVerticalLoading
} = cmsSlice.actions;

export const cmsItemsSelector = (state) => state.cmsData;
