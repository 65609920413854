import { Typography } from '@mui/material'
import React from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import AllUserList from './AllUserList';
import BuyerUserList from './BuyerUserList';
import TemporaryUserList from './TemporaryUserList';

function UserTabBody({activeTab}) {
  return (
    <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
       {activeTab == 1 && <AllUserList />}
       {activeTab == 2 && <BuyerUserList />}
       {activeTab == 3 && <TemporaryUserList />}
    </CustomBox>
  )
}

export default UserTabBody