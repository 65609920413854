import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { LeftArrow } from '../../Asset/svg';
import { Typography, useTheme } from '@mui/material';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import CreateProposalTabBody from '../../Components/ProposalManagement/CreateProposalTabBody';
import { ProposalData } from '../../data/OrderData';
import { CreateProposalTabData } from '../../data/CreateProposalData';


function CreateProposal({ handleNavParams }) {
  const [tabValue, setTabValue] = useState(1);
  const theme = useTheme();
  const navigate = useNavigate()
  const { userId } = useParams();
  const [email] = useSearchParams();

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            cursor: 'pointer'
          }} onClick={() => {
            navigate(-1)
          }}>
            <LeftArrow fill={theme.palette.textButton.main} />
            <Typography variant='h5' sx={{
              marginLeft: theme.spacing(1),
              color: theme.palette.textButton.main
            }}>Back</Typography>
          </Box>
          {/* <Typography variant='h5' sx={{marginLeft: (theme)=> theme.spacing(1)}}>User Details</Typography>
            <div style={{width: 100}}></div> */}
          {/* <CustomButton sx={{ padding: '12px 15px' }} color={'buttonPrimary'}>Print</CustomButton> */}
          {/* <Typography */}
        </Box>
      </CustomBox>
      <Divder spacing={1} />
      <CustomBox padding={0}>
        <CustomTabs tabList={CreateProposalTabData.tabs} tabClick={handleTabs} />
      </CustomBox>
      <Divder spacing={1} />
      <CreateProposalTabBody activeTab={tabValue} userId={userId} email={email} />
      <Divder spacing={1.2} />
    </Box>
  );
}

export default CreateProposal;
