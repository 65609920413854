import { Delete, Edit } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { UserIdentityData } from '../../data/userData';
import { currencyConversion, downloadBlobXLSFile, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { userItemsSelector } from '../../redux/user';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { allUserListing, allUserListingExportFile, deleteUser } from '../../Services/userApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import ConfirmationModal from '../Modals/ConfirmationModal';
import MatTable from '../Tables/MatTable';
import { getImagePricing } from '../../Services/cmsService';
import { cmsItemsSelector } from '../../redux/cms';
import EditImagePrice from '../Modals/EditImagePrice';
import orderListing from '../../redux/orderListing';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'ID',
    id: 'image_type_id',
   
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Image Type',
    id: 'image_type',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word' }}>{columnData === 1 ? 'Active' : 'Inactive' || 'N/A'}</span>)
  },
  {
    label: 'Resolution',
    id: 'resolution',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Dimensions',
    id: 'Dimensions',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Size',
    id: 'size',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word' }}>{columnData || '-'}</span>)
  },
  {
    label: 'File Format',
    id: 'file_format',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },

  {
    label: 'Price(INR)',
    id: 'price',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>
      {columnData && currencyConversion(columnData) || 'N/A'}
      </span>)
  }
];

function ImagePricing() {
  const [tableListing, setTableListing] = useState([])
  const [expLoading, setExpLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, imagePriceData } = useSelector(cmsItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [selectedImagePrice, setSelectedImagePrice] = useState(null);
  const [editModal, setEditModal] = useState(false);


  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // user_type: "2",
      ...allParams,
      ...params
    }
    dispatch(getImagePricing(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  useEffect(() => {
    if (imagePriceData?.items?.image_types?.length >= 0) {
      setTableListing(imagePriceData?.items?.image_types)
    }
  }, [imagePriceData.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            setSelectedImagePrice(data)
            handleEdit(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Edit /></CustomButton>
        </div>
      </>
    );
  };


  const handleEdit = (value = false) => {
    setEditModal(value);
  }


  return (
    <>
      <MatTable
        loading={imagePriceData?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date', 'identity'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date', 'identity'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={imagePriceData?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
        dropdownFilter={true}

      />
      <EditImagePrice open={editModal} handleOpen={handleEdit} data={selectedImagePrice} setPage={setPage} handleLast={getListing} />

    </>
  );
}

export default ImagePricing;
