import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import CreateUserModal from '../../Components/Modals/CreateUserModal';
import { FollowUpsData } from '../../data/followUpsdata';
import FollowUpsTabBody from '../../Components/FollowUps/FollowUpsTabBody';
import LastUserFollowUpsFullView from '../../Components/FollowUps/LastUserFollowUpsFullView';
import FullUserSearchList from '../../Components/FollowUps/FullUserSearchList';

function FollowUpsSearch({handleNavParams, addUserModal, handleAddUserModal}) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      {/* <CustomBox padding={0}>
        <CustomTabs tabList={FollowUpsData.tabs} tabClick={handleTabs} />
      </CustomBox> */}
      {/* <Divder spacing={1} /> */}
      {/* <FollowUpsTabBody activeTab={tabValue} /> */}
      {/* <Divder spacing={1.2} /> */}
      <FullUserSearchList />
    </Box>
  );
}

export default FollowUpsSearch;
