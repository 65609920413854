import { Link, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  crypt,
  formatDate,
  getNewParameters,
  getObjectSubset,
  updateUrl,
  getParameters,
} from '../../lib/helper';
import { orderListingItemsSelector } from '../../redux/orderListing';
import { customHistory } from '../../Route/CustomBrowserRouter';
import {
  deletePendingOrders,
  PendingOrderListing,
} from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import CreateOrderFollowUps from '../Modals/CreateOrderFollowUps';

const limit = 10;

const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Payment Mode',
    id: 'payment_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Payment Status',
    id: 'payment_status',
    format: (columnData, row) => (
      <>
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          {columnData == '0' ? 'Unpaid' : 'Paid'}
        </span>
        {row?.paid_on ? (
          <Typography variant="p" display={'block'} className="colorHiglight2">
            {formatDate(row.paid_on, 'dd/mm/yyyy')}
          </Typography>
        ) : null}
        {row?.previous_order_id ? (
          <Typography variant="p" display={'block'} className="colorHiglight">
            RefID:{row?.previous_order_id}
          </Typography>
        ) : null}
      </>
    ),
  },
  {
    label: 'Order Status',
    id: 'order_status',
    format: (row) => (
      <>
        <Typography
          variant="span"
          sx={{ color: 'info.main', width: 90, display: 'inline-block' }}
        >
          Pending
        </Typography>
        <Typography variant="span" component={'div'}>
          {row?.order_type}
        </Typography>
      </>
    ),
  },
  {
    label: 'Order Date',
    id: 'ordered_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {' '}
        {formatDate(columnData)}
      </span>
    ),
  },
];

function PendingOrder() {
  const [orderListing, setOrderListing] = useState([]);
  const [page, setPage] = useState(1);
  const [openCreateFollowup, setOpenCreateFollowup] = useState(false);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, pendingOrders } = useSelector(orderListingItemsSelector);
  const dispatch = useDispatch();
  const [selectedOrderData, setSelectedOrderData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const getOrderListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      order_status: String(0),
      ...allParams,
      ...params,
    };
    dispatch(PendingOrderListing(payload));
    setPage(Number(payload.page));
  };

  // const handlePendingOrderDelete = async (data, resetState) => {
  //   let resp = await deletePendingOrders({
  //     order_id: data,
  //   });
  //   if (resp) {
  //     getOrderListing();
  //     resetState([]);
  //   }
  // };
  const handleAdd = (value) => {
    setOpenCreateFollowup(value);
  };
  useEffect(() => {
    getOrderListing();
  }, []);

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getOrderListing();
  }, [allParams]);

  //making data format for table input
  useEffect(() => {
    if (pendingOrders?.items?.orders?.length >= 0) {
      let data = pendingOrders?.items?.orders?.map((item) => {
        return {
          order_id: item.order_id,
          user_id: item.user_id,
          email: item.user.email,
          payment_type: item.payment_type,
          payment_status: item.payment_status,
          ordered_on: item.ordered_on,
          order_type: item.order_type,
          order_status: item.order_status,
          invoice_id: item?.invoice?.invoice_id || null,
          paid_on: item?.paid_on,
          previous_order_id: item.previous_order_id,
        };
      });
      setOrderListing(data);
    }
  }, [pendingOrders.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              width: '150px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
            onClick={() => {
              handleAdd(true);
              setSelectedOrderData(data);
            }}
          >
            CREATE
          </CustomButton>
          <CustomButton
            onClick={() => {
              if (data && data != null) {
                customHistory.push(`crm/orderHistory/${data?.order_id}`);
              }
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              width: '135px',
            }}
          >
            VIEW
          </CustomButton>
        </div>
      </>
    );
  };
  return (
    <>
      <MatTable
        loading={pendingOrders?.loading}
        columns={column}
        data={orderListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data = {}) => {
          getOrderListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={pendingOrders?.items?.totalCount || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getOrderListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
        // onTableToolbarRightClick={handlePendingOrderDelete}
      />
      {openCreateFollowup && (
        <CreateOrderFollowUps
          open={openCreateFollowup}
          handleOpen={handleAdd}
          setPage={setPage}
          getListing={getOrderListing}
          selectedData={selectedOrderData}
        />
      )}
    </>
  );
}

export default PendingOrder;
