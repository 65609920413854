import { Grid, Typography, useTheme, Checkbox, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getNewParameters, getObjectSubset, getParameters, validDate } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import {setResetContributorPaymentDivision } from '../../redux/contributor';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { companyItemsSelector } from '../../redux/company';
import { companyOverviewUserListings } from '../../Services/companyApis';


const limit = DATA_LIMIT;

const column = [
  {
    label: 'Id',
    id: 'user_id',
    // format: (columnData) => (<Typography
    //   variant='span'
    //   style={{ whiteSpace: 'nowrap' }}
    //   className={'link-text'}
    //   onClick={()=>{
    //     customHistory.push(`user/userDetail/${columnData}`);
    //   }}
    //  > {columnData}</Typography>)

    format: (columnData) => (
    
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
          // className={'link-text'}
          // onClick={() => {
          //   customHistory.push(`order/orderDetails/${columnData}`);
          // }}
        >
          {columnData}
        </Typography>
   
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'First Name',
    id: 'first_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Last Name',
    id: 'last_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  // {
  //   label: 'Signup As',
  //   id: 'signup_as',
  //   format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content"}}>{columnData || 'N/A'}</span>)
  // },
  // {
  //   label: 'Job Description',
  //   id: 'job_description',
  //   format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content"}}>{columnData || 'N/A'}</span>)
  // },
  // {
  //   label: 'State',
  //   id: 'state',
  //   format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content"}}>{columnData || 'N/A'}</span>)
  // },
   {
    label: 'Identity',
    id: 'identity',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content"}}>{columnData || '-'}</span>)
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word'}}>{columnData || '-'}</span>)
  },
  {
    label: 'Job Description',
    id: 'job_description',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  // {
  //   label: 'Telephone',
  //   id: 'phone',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  // },
  {
    label: 'Mobile',
    id: 'mobile',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  // {
  //   label: 'Zip',
  //   id: 'zip_code',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  // },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, minWidth: 200, wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  // {
  //   label: 'Group Company Name',





  //   id: 'company_group_name',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  // },
  // {
  //   label: 'GST Number',
  //   id: 'gst_number',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  // },
  // {
  //   label: 'ISD Number',
  //   id: 'isd_number',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  // },
  // {
  //   label: 'Discount',
  //   id: 'discount',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData >= 0 ? columnData : 'N/A'}</span>)
  // },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (<Typography 
      variant='span'
      sx={{ ...(columnData == 1 ?{color: 'success.main'} : columnData == 2 ?{color: 'danger.main'} : columnData == 0 ? {color: 'info.main'} : {} ),
       whiteSpace: "nowrap",  display: 'inline-block' }}>
      {columnData == 0 ? 'Inactive' : columnData == 1 ? 'Active' : columnData == 2  ? 'Deleted' : 'N/A'}
    </Typography>)
  },
  {
    label: 'Joined At',
    id: 'created_at',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{formatDate(columnData, 'dd-mm-yyyy')}</span>)
  },
];

function CompanyOverviewUserList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const {loading, companyOverviewUserList } = useSelector(companyItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const theme = useTheme()
  const { shortCompanyName  } = useParams();

  useEffect(() => {
    if (companyOverviewUserList?.items?.rows?.length >= 0) {
      setTableListing(companyOverviewUserList?.items?.rows);     
    }
    else{
     setTableListing([]);
    }
  }, [companyOverviewUserList.items]);

  useEffect(() => {
    dispatch(setResetContributorPaymentDivision())
  }, [])

  useEffect(() => {
    getListing();
  }, [])

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  
  useEffect(() => {
    let data = getNewParameters(allParams);
    // if (formik?.values?.start_date && formik?.values?.end_date) {
      if (data?.search !== location.search) {
        navigate(data.newUrl, { replace: true })
        if (data?.parameters?.page) {
          setPage(Number(data?.parameters?.page))
        }
      // }
      getListing();
    }
  }, [allParams])

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      order_status: String(1),
      // start_date: formik?.values?.from,
      // end_date: formik?.values?.to,
      short_company_name: shortCompanyName,
      ...allParams,
      ...params
    }
    dispatch(companyOverviewUserListings(payload))
    setPage(Number(payload.page))
  }

  return (
    <CustomBox padding={0}>

      <MatTable
        loading={companyOverviewUserList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        // onApplyFilter={(data = {}) => {
        //   getListing({
        //     page: String(1),
        //     start_date: "",
        //     end_date: "",
        //     payment_type:"",
        //     ...getObjectSubset(data, 'start_date', 'end_date',"payment_type"),
        //   });
        //   if (Object.keys(data).length > 0) {
        //     setAllParams({
        //       ...allParams,
        //       page: String(1),
        //       ...getObjectSubset(data, 'start_date', 'end_date',"payment_type"),
        //     });
        //   } else {
        //     setAllParams({
        //       page: String(1),
        //     });
        //   }
        // }}
        // showFilterButton={true}
        // showSerialNo={false}
        // action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={companyOverviewUserList?.items?.count || 0}
      
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        // toolBarButton={() => {
        //   return <>
        //     <CustomButton onClick={() => { handleAdd(true) }} color={'buttonPrimary'} sx={(theme) => ({
        //       padding: '10px 10px', marginRight: 1,
        //       [theme.breakpoints.down("md")]: {
        //         flex: 1,
        //       }
        //     })}>Add Payment For images</CustomButton>
        //   </>
        // }}
        toolBarButton={() => {
          return <>
            {/* <CustomButton sx={(theme)=>({ fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
              [theme.breakpoints.down("md")]: {
                flex: 1,
              }
            })} onClick={()=>{
              getExportFile()
            }} loading={expLoading}>Export XLS</CustomButton> */}

            {/* <CustomButton sx={{ fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
              [theme.breakpoints.down("md")]: {
                flex: 1,
              }
            }} onClick={()=>{
              if(paidProcessArr.length && paidProcessUser){
                // handleOpen(true);
                handleSoldOrder();

              }else{
                toast.error('Atleast one order selection is required.')
              }
            }}>Mark as paid</CustomButton> */}
          </>
        }}

        // toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        dropdownFilter={true}
        disableSearch={true}
        rangeDateFilter
        customCheckboxEnable={true}
    // requred to clear filter and refresh state after api call 
   
      />

    </CustomBox>

  );
}


export default CompanyOverviewUserList;
