import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import {
  formatDate,
  handleFileReader,
  handleParse,
  processCSV,
} from '../../lib/helper';
import { fileUpload } from '../../Services/commonService';
import { uploadWeeklyImages } from '../../Services/DashboardApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { insertImages } from '../../Services/imageApis';
import { uploadPOFile } from '../../Services/OrderApis';

export default function UploalPDFFileModal({ open, handleOpen, data, getListing }) {
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [loading, setLoading] = React.useState(false);

  const handleFileUpload = async (values, { resetForm }) => {
    try {
      if (values.uploaded_file) {
          setLoading(true);
          const formData = new FormData();
          formData.append("uploaded_file", values.uploaded_file);
          formData.append("order_id", values.order_id);
          let data = await uploadPOFile(formData,  {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          if(data){
            handleOpen(false);
            resetForm({
              order_id: data?.order_id,
              uploaded_file: '',
            });
            getListing()
          }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e, 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      order_id: data?.order_id || '',
      uploaded_file: '',
    },
    validationSchema: Yup.object({
      order_id: Yup.string().required('Order id required.'),
      uploaded_file: Yup.mixed()
        .required('File is required.')
    //     .test(
    //       'fileType',
    //       'Unsupported File Format. It should be PDF file only.',
    //       (value) => ['.pdf', 'text/pdf', 'application/pdf'].includes(value?.type),
    //     ),
    }),
    onSubmit: handleFileUpload,
  });

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={() => {
          if(!loading){
            handleOpen(false);
          }
        }}
      >
        <DialogTitle>Upload PO File</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="uploaded_file"
                  label="PO File"
                  placeholder="PO File"
                  type="file"
                  // value={formik.values.order_status}
                  onChange={(e) => {
                    formik.setFieldValue('uploaded_file', e.target.files[0]);
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.uploaded_file && Boolean(formik.errors.uploaded_file)
                  }
                  helperText={formik.touched.uploaded_file && formik.errors.uploaded_file}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Upload
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    order_id: "",
                    uploaded_file: '',
                  });
                  handleOpen(false)
                  }}
                disabled={loading}
              >
                Close
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
