import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import ImageTabBody from '../../Components/Image/ImageTabBody';
import { ImageData } from '../../data/imageData';
import { Typography, useTheme } from '@mui/material';
import { LeftArrow } from '../../Asset/svg';
import { useNavigate, useParams } from 'react-router-dom';
import ImageslistForGroupId from '../../Components/Image/ImageslistForGroupId';
import { clearImagesListings } from '../../redux/image';
import { useDispatch } from 'react-redux';

function ImageByGroupId({ handleNavParams }) {
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {groupId} = useParams();
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox>
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}> 
            <Box sx={{
                display: "flex",
                alignItems: "center",
                cursor: 'pointer'
            }} onClick={()=>{
                navigate(-1)
                dispatch(clearImagesListings())
            }}>
                <LeftArrow fill={theme.palette.textButton.main}/>
                <Typography variant='h5' sx={{
                    marginLeft: theme.spacing(1),
                    color: theme.palette.textButton.main
                }}>Back</Typography>
            </Box>
            <Typography variant='h5' sx={{marginLeft: (theme)=> theme.spacing(1)}}>Sold Images By GroupId</Typography>
            <div style={{paddingRight: 50}}></div>
            {/* <Typography */}
        </Box>
      </CustomBox>
      <Divder spacing={1} />
      <ImageslistForGroupId groupId={groupId}/>
      <Divder spacing={1.2} />
    </Box>
  );
}

export default ImageByGroupId;
