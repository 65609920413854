import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { breakStringIntoLines, createSubString, currencyConversion, downloadBlobFile, formatDate, getNewParameters, getObjectSubset, getParameters, validDate } from '../../lib/helper';
import { countryListings, countryListingsUnpaid, getDailySalesReport, getReportListing, stateListings, stateListingsUnpaid } from '../../Services/reportsApis';
import * as Yup from 'yup';
import { reportListingItemsSelector } from '../../redux/reports';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import { setResetDailyReportData } from '../../redux/reports';
import AllFilters from '../Order/AllFilters';
import { isVisible } from '@testing-library/user-event/dist/utils';
import { commonItemsSelector } from '../../redux/common';

const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (

      <Typography
        variant='span'
        style={{ whiteSpace: 'nowrap' }}
      >
        {columnData}
      </Typography>

    ),
  },

  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Order Type',
    id: 'order_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Plan Name',
    id: 'plan_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Country',
    id: 'country',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Invoice Date',
    id: 'invoice_date',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd/mm/yyyy')}
      </span>
    ),
  },
  {
    label: 'Initial Price(INR)',
    id: 'initial_price',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData && currencyConversion(columnData) || 'N/A'}
      </span>
    ),
    nextLine:true,
  },

  {
    label: 'Discount (INR)',
    id: 'discount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData && currencyConversion(columnData) || 'N/A'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Before Tax(INR)',
    id: 'before_tax',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData && currencyConversion(columnData) || 'N/A'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'After Tax(INR)',
    id: 'after_tax',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData && currencyConversion(columnData) || 'N/A'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Address',
    id: 'address',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}

      </span>
    ),
  },
  {
    label: 'Country',
    id: 'country',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}

      </span>
    ),
  },

  {
    label: 'Zip Code',
    id: 'zip_code',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },

  {
    label: 'Payment Type',
    id: 'payment_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Comment',
    id: 'comment',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}

      </span>
    ),
  },

  {
    label: 'Special Comment',
    id: 'special_comment',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}

      </span>
    ),
  },
  {
    label: 'GST Number',
    id: 'gst_number',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
];


function DailyReportMultiple() {
  const [fileResp, setFileResp] = useState({
    status: false,
    data: null
  })
  const [loading, setLoading] = useState(false)
  const [tableListing, setTableListing] = useState([]);
  const { allIReports, mainLoading } = useSelector(reportListingItemsSelector);
  const { appSetting } = useSelector(commonItemsSelector)
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [totalAmount, setTotalAmount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'From Date',
      id: 'from',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          if (e.target.value) {
            if (e.target.value && newState[1].value) {
              CountryListCalling(e.target.value, newState[1].value)
            }
            if (e.target.value && newState[0].value && newState[4]?.value) {
              stateListCallingPaid(e.target.value, newState[1].value, newState[4]?.value)
            }
          }
          return newState;
        });

      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'to',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          if (newState[0].value > e.target.value) {
            toast.error("To date should less than from date")
          }
          else {
            newState[index].value = e.target.value;
            if (e.target.value && newState[0].value) {
              CountryListCalling(newState[0].value, e.target.value)
            }
            if (e.target.value && newState[0].value && newState[4]?.value) {
              stateListCallingPaid(newState[0].value, e.target.value, newState[4]?.value)
            }
          }
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Order Type',
      id: 'image_type',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { label: 'IV', value: 'IV' },
        { label: 'NPlan', value: 'NPlan' },
      ],
      props: {},
      isVisible: true,
    },
    {
      label: 'Payment Type',
      id: 'payment_type',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr:
        [
          { label: 'Select', value: '' },
          { label: 'Cheque/demand draft', value: 'Cheque/demand draft' },
          { label: 'NEFT/RTGS/WT', value: 'NEFT/RTGS/WT' },
          { label: 'RazorPay', value: 'RazorPay' },
          { label: 'American Express', value: 'American Express' },
          { label: 'Pay10', value: 'Pay10' },
          { label: 'Zaakpay', value: 'Zaakpay' },
          { label: 'Paypal', value: 'Paypal' },
          { label: 'CC avenue', value: 'ccavenue' }
        ]
      ,
      props: {},
      isVisible: true,
    },
    {
      label: 'Select Country',
      id: 'country_id',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          if (e.target.value) {
            newState[0].isVisible = true;
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            if (newState[0].value && newState[1].value && e.target.value) {
              stateListCallingPaid(newState[0].value, newState[1].value, e.target.value);
            }
            newState[5].value = '';
            newState[5].dataArr = [{ label: 'Select', value: '' }]
          }
          else {
            newState[0].isVisible = true;
            newState[5].isVisible = false
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = true;
            newState[4].isVisible = true;
            newState[5].value = '';

          }
          return newState;
        });

      },
      style: {},
      dataArr: [{ label: 'Select', value: '' }],
      props: {},
      isVisible: true,
    },
    {
      label: 'Select State',
      id: 'state',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });

      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
      ],
      props: {},
      isVisible: false,
    },


    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },


  ]);

  const [expLoading, setExpLoading] = useState(false);

  const handleStateListCalling = async (val, index) => {
    let payload = { country_id: val };
    let resp = await stateListings(payload);
    let final_arr = resp?.data?.map((st) => {
      return { label: st?.state_name, value: st?.state_name }
    })
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[5].dataArr = [
        { label: 'Select', value: '' },
        ...final_arr,
      ];
      if (resp?.data?.length) {
        newState[5]['isVisible'] = true
      }
      else {
        newState[5]['isVisible'] = false
      }
      return newState;
    });
  }


  useEffect(() => {
    if (allIReports?.items?.result?.length >= 0) {
      setTableListing(allIReports?.items?.result)
    }
  }, [allIReports.items]);

  useEffect(() => {
    dispatch(setResetDailyReportData())
  }, [])

  const handleShowData = () => {
    if (formik?.values.to && formik?.values.from) {
      getOrderListing()
    }
    else {
      toast.error("To and From Date Required")
    }
  }

  const stateListCallingPaid = async (date1, date2, country) => {
    let payload = { type: "2" };
    if (date1) payload['from'] = formatDate(date1, 'yyyy-mm-dd') + " 00:00:00";
    if (date2) payload['to'] = formatDate(date2, 'yyyy-mm-dd') + " 23:59:00";
    if (country) payload['country_id'] = country;
    let resp = await stateListingsUnpaid(payload);
    if (resp?.status == 200) {
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        if (resp?.data?.length) {
          newState[5]['isVisible'] = true;
          let final_arr = resp?.data?.map((cont) => {
            return { label: cont?.state_name, value: cont.state_name }
          })
          newState[5].dataArr = [
            { label: 'Select', value: '' },
            ...final_arr,
          ];
        }
        else {
          newState[5]['isVisible'] = false;
        }
        return newState;
      });
    }
  }

  const CountryListCalling = async (from, to) => {
    let payload = { type: "2" };
    if (from) payload['from'] = formatDate(from, 'yyyy-mm-dd') + " 00:00:00"
    if (to) payload['to'] = formatDate(to, 'yyyy-mm-dd') + " 23:59:00"
    let resp = await countryListingsUnpaid(payload);
    if (resp?.status == 200) {
      //cont?.country_name
      let final_arr = resp?.data?.map((cont) => {
        return { label: cont?.country_name, value: cont.country_id, id: cont.country_id }
      })
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[4].dataArr = [
          { label: 'Select', value: '' },
          ...final_arr,
        ];
        return newState;
      });
    }
  }

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (formik?.values?.start_date && formik?.values?.end_date) {
      if (data?.search !== location.search) {
        navigate(data.newUrl, { replace: false })
        if (data?.parameters?.page) {
          setPage(Number(data?.parameters?.page))
        }
      }

      getOrderListing();
    }
  }, [allParams])

  const getOrderListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,

      ...allParams,
      ...params
    }
    if (payload?.to) {
      payload['to'] = formatDate(payload?.to, 'yyyy-mm-dd') + " 23:59:59"
    }
    if (payload?.from) {
      payload['from'] = formatDate(payload?.from, 'yyyy-mm-dd') + " 00:00:00"
    }
    if (payload?.to && payload.from) {
      dispatch(getReportListing(payload))
      setPage(Number(payload.page))
    }
    else {
      toast.error("To and From Date Required")
    }
  }


  const handleConfirm = async () => {
    setLoading(true)
    let resp = await getDailySalesReport(formik.values)
    if (resp.status) {
      setFileResp(resp)
      formik?.resetForm()
      downloadBlobFile(resp.data, `DailySalesReport${formik?.values.from}-/-${values.to}`)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      from: "",
      to: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      from: Yup.string().required('From Date is required.'),
      to: Yup.string().required('To Date is required.'),
    }),
    onSubmit: checking,
  });

  async function checking() {
    setLoading(true)
    let resp = await getDailySalesReport(formik.values)
    if (resp.status) {
      setFileResp(resp)
      // formik?.resetForm()
      downloadBlobFile(resp.data, `DailySalesReport${formik?.values.from}-/-${formik?.values.to}`)
    }
    setLoading(false)
  }
  const actionBody = (data) => {
    return (
      <>

      </>
    );
  };

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getOrderListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    let obj = { tab: allParams?.tab, ...dataWithValue, page: String(1) };
    setAllParams({
      ...obj
    });

  };

  const getExportFile = async (params = {}, b, prepop) => {
    try {

      let payload = {
        ...allParams,
        ...params
      }
      delete payload.page
      delete payload.limit

      setExpLoading(true)
      let newPayload = { ...prepop };

      if (newPayload?.to) {
        newPayload['to'] = formatDate(newPayload?.to, 'yyyy-mm-dd') + " 23:59:59"
      }
      if (newPayload?.from) {
        newPayload['from'] = formatDate(newPayload?.from, 'yyyy-mm-dd') + " 00:00:00"
      }
      if (newPayload?.from && newPayload?.to) {

        let resp = await getDailySalesReport(newPayload)
        if (resp.status) {
          setFileResp(resp)
          // formik?.resetForm(
          downloadBlobFile(resp.data, `DailySalesReport`)
        }
        else {
          toast.error('To and From date required!')
        }
        setExpLoading(false)

      }

      else {
        toast.error('To and From date required!')
      }


    } catch (e) {
      console.log(e)
      setExpLoading(false)
    }
  }
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[3].isVisible = true;
      newState[4].isVisible = true;
      newState[5].isVisible = true;

      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      newState[3].value = '';
      newState[4].value = '';
      newState[5].value = '';
      return newState;
    });
  };

  return (
    <>

      <Grid container spacing={2} p={1.5}>
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>

            Total Records: {allIReports?.items?.totalEarning ? allIReports?.items?.totalCount : 0}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>

            Total Earnings(INR): {allIReports?.items?.totalEarning ? currencyConversion(allIReports?.items?.totalEarning)
              : '0'}


          </Typography>
        </Grid>

        <MatTable
          loading={allIReports?.loading}
          showCheckbox={false}
          columns={column}
          data={tableListing}
          onApplyFilter={(data = {}) => {
            getListing({
              page: String(1),
              start_date: '',
              end_date: '',
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
            if (Object.keys(data).length > 0) {
              setAllParams({
                ...allParams,
                page: String(1),
                ...getObjectSubset(data, 'start_date', 'end_date'),
              });
            } else {
              setAllParams({
                page: String(1),
              });
            }
          }}

          page={page}
          perPage={limit}
          total={allIReports?.items?.totalCount || 0}
          setPage={setPage}
          showPageLimit={true}
          pageLimitArray={[20, 50, 100, 200]}
          handleChangePageLimit={(e) => {
            setLimit(e.target.value);
            setAllParams({
              ...allParams,
              page: String('1'),
              limit: Number(e.target.value),
            });
          }}
          toolbarEnable={true}
          pagination={true}
          filtersUrlData={allParams || {}}
          rangeDateFilter
          disableSearch
          handleSearch={(searchTerm) => {
            getListing({
              page: String(1),
              search: searchTerm,
            });
            setAllParams({
              ...allParams,
              page: String(1),
              search: searchTerm,
            });
          }}
          handlePageChange={(newPage) => {
            getOrderListing({
              page: String(newPage),
            });
            setAllParams({
              ...allParams,
              page: String(newPage),
            });
          }}


          customFilter={() => (
            <AllFilters
              filtersArray={filtersArray}
              handleClick={handleFilter}
              handleClear={handleClear}
              hasDownloadExcelButton={true}
              handleDownloadExcel={getExportFile}
            />
          )}
        />
      </Grid>
    </>


  );
}

export default DailyReportMultiple;
