import React from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import VisibleHiddenKeywordsList from '../../Components/Keywords/VisibleHiddenKeywordsList';
import { useParams } from 'react-router-dom';
import { decrypt } from '../../lib/helper';
import ApiConfig from '../../config/ApiConfig';

function VisibleHiddenKeywords() {
  const routeParams = useParams()
  const vid = decrypt(ApiConfig.REACT_APP_SALT + 'vid',routeParams?.visibleKeywordId) || ""

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
        <VisibleHiddenKeywordsList vid={vid} />
      </CustomBox>
      <Divder spacing={1.2} />
    </Box>
  );
}

export default VisibleHiddenKeywords;
