import React from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import SubscriptionList from './SubscriptionList';
import StandardSubscriptionList from './standardSubcriptionList';
import SpecialSubscriptionList from './specialSubscriptionList';

function SubscriptionTabBody({activeTab}) {
  return (
    <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
       {activeTab == 1 && <SubscriptionList />}
       {activeTab == 2 && <StandardSubscriptionList/>}
       {activeTab == 3 && <SpecialSubscriptionList/>}
    </CustomBox>
  )
}

export default SubscriptionTabBody;