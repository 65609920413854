export const ContactUsData = {
  tabs: [
    {
      label: 'Contact Us',
      id: 1,
    },
    {
      label: 'Image Research',
      id: 2,
    }
  ],
};

export const ContactUsFiltersData = {
  related_to: [
    {label: "Sales", value: "Sales"},
    {label: "Orders", value: "Orders"},
    {label: "General", value: "General"},
    {label: "Marketing", value: "Marketing"},
    {label: "Feedback", value: "Feedback"},
    {label: "Photoshoots", value: "Photoshoots"},
    {label: "Careers", value: "Careers"},
    {label: "Contribution", value: "Contribution"},
    {label: "ImageResearch", value: "ImageResearch"}
  ]
}

export const PaidFiltersData = {
  related_to: [
    {label: "All", value: "All"},
    {label: "Paid", value: "1"},
    {label: "Unpaid", value: "0"},
  ]
}
