import { Grid, MenuItem, Typography } from '@mui/material';
import React from 'react';
import CustomInput from '../CommonComp/CustomInput/CustomInput';

function AddImagesFields({
  value,
  index,
  handleChange,
  name,
  label,
  placeholder,
  width,
  options,
  error,
  helperText,
  ...props
}) {
  return (
    <div style={{ width: width || 150 }}>
      <CustomInput
        variant="outlined"
        fullWidth
        name={name}
        // label={label}
        placeholder={placeholder}
        value={value}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          classes: {
            notchedOutline: 'rounded',
          },
        }}
        inputProps={{
          style: {
            padding: '10px 10px',
            fontSize: 14,
          },
        }}
        onChange={(e) => !props?.select && handleChange(index, e)}
        {...props}
        error={error[name]}
        helperText={error[name] && <Typography variant='span'>{helperText}</Typography>}
      >
        {props?.select
          ? options?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))
          : null}
      </CustomInput>
    </div>
  );
}
export default React.memo(AddImagesFields);

AddImagesFields.defaultProps = {
  value: '',
  index: null,
  handleChange: () => {},
  name: '',
  label: '',
  placeholder: '',
  width: 150,
  options: [],
};
