import { Grid, Typography, useTheme, Checkbox, IconButton, Box, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DATA_LIMIT } from '../../data/constants';
import { createSubString, crypt, currencyConversion, decrypt, formatDate, getNewParameters, getObjectSubset, getParameters, useQuery, validDate } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import {setResetContributorPaymentDivision } from '../../redux/contributor';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { companyItemsSelector } from '../../redux/company';
import { companyOverviewOrderListings, companyOverviewUserListings } from '../../Services/companyApis';


const limit = DATA_LIMIT;

const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
     
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
          // className={'link-text'}
          // onClick={() => {
          //   customHistory.push(`order/orderDetails/${columnData}`);
          // }}
        >
          {columnData ||'N/A'}
        </Typography>
    
    ),
  },
  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData) => (
     
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
        >
          {columnData || 'N/A'}
        </Typography>
    
    ),
  },
  {
    label: 'Invoice Date',
    id: 'invoice_date',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
     
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
        >
            {columnData?formatDate(columnData, 'dd/mm/yyyy'):'N/A'}
        </Typography>
    
    ),
  },
  {
    label: 'Client',
    id: 'client',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A' }
      </span>
    ),
  },
  {
    label: 'Order Date',
    id: 'ordered_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData?formatDate(columnData, 'dd/mm/yyyy'):'N/A'}
      </span>
    ),
  },
  {
    label: 'Amount(INR)',
    id: 'amount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          {columnData>=0?currencyConversion(columnData) : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Due Amount(INR)',
    id: 'due_amount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
         {columnData>=0?currencyConversion(columnData) : 'N/A'}
      </span>
    ),
  },
  // {
  //   label: 'Order Status',
  //   id: 'order_status',
  //   format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
  //     <>
  //       <Typography
  //         variant="span"
  //         sx={{
  //           color:
  //             columnData == 1
  //               ? 'success.main'
  //               : columnData == '2'
  //               ? 'danger.main'
  //               : columnData == '0'
  //               ? 'info.main'
  //               : 'N/A',
  //           width: 90,
  //           display: 'inline-block',
  //         }}
  //       >
  //         {columnData == 1
  //           ? 'Confirmed'
  //           : columnData == '2'
  //           ? 'Rejected'
  //           : columnData == '0'
  //           ? 'Pending'
  //           : 'N/A'}
  //       </Typography>
  //       <Typography variant="span" component={'div'}>{row?.order_type}</Typography>
  //     </>
  //   ),
  // },
  {
    label: 'Clearence Date',
    id: 'payment_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData?formatDate(columnData, 'dd/mm/yyyy'):'N/A'}
      </span>
    ),
  },

  {
    label: 'Payment Status',
    id: 'payment_status',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => ( <>
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          {columnData == '0'? 'Unpaid' : 'Paid'}
        </span>
        {row?.paid_on ? <Typography variant='p' display={'block'} className='colorHiglight2'>
           {formatDate(row.paid_on, 'dd/mm/yyyy')}
        </Typography>: null}
        {row?.previous_order_id ? <Typography variant='p' display={'block'} className='colorHiglight'>
        RefID:{row?.previous_order_id}
      </Typography>: null}
    </>
    ),
  },

];

function CompanyOverviewOrderList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [searchParams, setSearchParams] = useSearchParams();
  const [userId,setUserId]=useState(new URLSearchParams(useLocation().search).get("user_id"));
  const [type,setType]=useState(new URLSearchParams(useLocation().search).get("order_type"));
  const {loading, companyOverviewOrderList } = useSelector(companyItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  let order_type=localStorage.getItem("order_type");
  let user_id=localStorage.getItem("user_id");
  const theme = useTheme()
  const { shortCompanyName  } = useParams();

  useEffect(() => {
    if (companyOverviewOrderList?.items?.rows?.length >= 0) {
      let newArrr=companyOverviewOrderList?.items?.rows?.map((comp)=>{
        return {
          order_id:comp?.order_id,
          ordered_on:comp?.ordered_on,
          order_status:comp?.order_status,
          payment_on:comp?.payment_on,
          payment_status:comp?.payment_status,
          previous_order_id:comp?.previous_order_id,
          paid_on:comp?.paid_on,
          order_type:comp?.order_type,
          discount:comp?.grouping_manage?.discount,
          special_comment:comp?.grouping_manage?.special_comment,
          period:comp?.grouping_manage?. period,
          client:comp?.invoice?.client,
          amount:comp?.invoice?.final_price,
          email:comp?.user?.email,
          due_amount:comp?.due_amount,
          invoice_date:comp?.invoice?.invoice_date,
          invoice_id:comp?.invoice?.invoice_id
        }
      });
      setTableListing(newArrr);
    }
    else{
     setTableListing([]);
    }
  }, [companyOverviewOrderList.items]);

  useEffect(() => {
    getListing();
  }, [])

  useEffect(() => {
    let params = getParameters();
    setAllParams(params)
  }, [location?.search]);

  
  useEffect(() => {
    let data = getNewParameters(allParams);
    // if (formik?.values?.start_date && formik?.values?.end_date) {
      if (data?.search !== location.search) {
        navigate(data.newUrl, { replace: true })
        if (data?.parameters?.page) {
          setPage(Number(data?.parameters?.page))
        }
      // }
      getListing();
    }
  }, [allParams])

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      order_status: String(1),
      // start_date: formik?.values?.from,
      // end_date: formik?.values?.to,
      short_company_name: shortCompanyName,
      ...allParams,
      ...params
    }
    payload['user_id']=user_id;
    if(order_type){
      if(order_type=='all'){
        delete payload?.order_type;
      }
      else
      payload['order_type']=order_type;
    }
    if(payload?.start_date){
      delete payload?.start_date;
    }
    if(payload?.end_date){
      delete payload?.end_date;
    }
    dispatch(companyOverviewOrderListings(payload))
    setPage(Number(payload.page))
  }

  return (
    <CustomBox padding={0}>

      <MatTable
        loading={companyOverviewOrderList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        page={page}
        perPage={limit}
        total={companyOverviewOrderList?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolBarButton={() => {
          return <>
           
          </>
        }}

        // toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        dropdownFilter={true}
        disableSearch={true}
        rangeDateFilter
        customCheckboxEnable={true}
    // requred to clear filter and refresh state after api call 
   
      />

    </CustomBox>

  );
}


export default CompanyOverviewOrderList;
