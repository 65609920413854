import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton'
import MainLayout from '../Layouts/MainLayout'
import ImageByGroupId from './ImageByGroupId'

function ImageGroupContainer() {
  const [navParams, setNavParams] = useState({
      navTitle: "Sold Images By GroupId",
      navSubTitle: "Welcome to ImagesBazaar"
  })

  return (<MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
        <ImageByGroupId/>
    </MainLayout>)
}

export default ImageGroupContainer