import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import ContributorUploadedDetail from './ContributorUploadedDetail'





function ContributorUploadedDetailsContainer() {
    return (
        <MainLayout navTitle={"Contributor Folder List"} navSubTitle={"Welcome to ImagesBazaar"}>
            <ContributorUploadedDetail />
        </MainLayout>
    )
}

export default ContributorUploadedDetailsContainer