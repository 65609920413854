import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  irnList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const irnSlice = createSlice({
  name: 'irn',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIrnListing: (state, action) => {
      state.irnList.items = action.payload;
      state.irnList.error = {
        isError: false,
        message: '',
      };
    },
    setIrnListingLoading: (state, action) => {
      state.irnList.loading = action.payload;
    },
    setIrnListingError: (state, action) => {
      state.irnList.items = {};
      state.irnList.error = action.payload;
    },
  },
});

export default irnSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setIrnListing,
  setIrnListingError,
  setIrnListingLoading,
} = irnSlice.actions;

export const irnItemsSelector = (state) => state.irnList;
