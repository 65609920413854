import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import MainDropzone from '../CommonComp/DropZone/Dropzone';
import { Box, Divider, Grid, Typography } from '@mui/material';
import Divder from '../CommonComp/Divder';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { editAddCMS, getCMSFooter, getCMSInfo } from '../../Services/cmsService';
import { cmsItemsSelector } from '../../redux/cms';
import { useDispatch, useSelector } from 'react-redux';

function Footer() {
  const [countryShortName, setCountryShortName] = useState('in');
  const [loading, setLoading] = useState(false);
  const { cmsfooterData } = useSelector(cmsItemsSelector);
  let cmsDataHome = cmsfooterData ? cmsfooterData?.items?.length > 0 && JSON?.parse(cmsfooterData?.items[0]?.key_value) : [];
  const dispatch = useDispatch();
  const [footerData,setFooterData]=useState(cmsDataHome?.footerSection);
  const arr={footerSection:{mail:'info@imagesbazaar.com',telephone:'1800-11-6869',mobile:'99113-66666'},headerSection:{logo:"abcd"}}
  
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let cmsObj={...cmsDataHome,footerSection:values}
    let str1 = JSON.stringify(cmsObj);
    let obj = { pagename: 'layout', pagedata: str1 }
    let resp = await editAddCMS(obj);
    if(resp){
      getListing();
    }
    setLoading(false);
  };

  useEffect(() => {
    getListing();
  }, [])

  const getListing = () => {
    let payload = { pagename: 'layout' };
    dispatch(getCMSFooter(payload));
    // setFooterData(cmsDataHome?.footerSection)
  }

  const formik = useFormik({
    initialValues: {
      mail: cmsDataHome?.footerSection?.mail || '',
      mobile: cmsDataHome?.footerSection?.mobile || '',
      telephone: cmsDataHome?.footerSection?.telephone ||'',
      logo:cmsDataHome?.footerSection?.logo ||''
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      mail: Yup.string()
        .email('Please enter valid Email')
        .required('Please enter Email'),
  
      mobile: Yup.string()
        .max(18, 'Mobile Number is Invalid')
        .min(10, 'Mobile Number is Invalid')
        .required('Mobile Number is required'),
      telephone: Yup.string()
        .required('Telephone Number is required.')
        .matches(/^[\d -]+$/, 'It should be number only.')
        .max(50, 'Max length will be 50.'),
      logo:Yup?.string().required("Logo Image is Required!")
    
    }),
    onSubmit: handleConfirm,
  });

  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobile', val);
  };
  return (
    <>
    
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 800
            // }}
            marginTop={2}
            marginLeft={2}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="mail"
                  label="Email"
                  placeholder="Email"
                  value={formik.values.mail}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.mail && Boolean(formik.errors.mail)}
                  helperText={formik.touched.mail && formik.errors.mail}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomPhoneInput
                  label="Mobile"
                  id="mobile"
                  name="mobile"
                  className="main-login-form"
                  fullWidth
                  placeholder="Mobile Number"
                  native={true}
                  value={formik.values.mobile}
                  defaultCountry={`${countryShortName}`}
                  country={`${countryShortName}`}
                  divStyle={{
                    paddingTop: 4,
                  }}
                  variant="outlined"
                  onChange={(newValue, info) => {
                    handleChangeMobile(newValue);
                  }}
                  onlyCountries={[countryShortName]}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="telephone"
                  label="Telephone"
                  placeholder="Telephone"
                  value={formik.values.telephone}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.telephone && Boolean(formik.errors.telephone)
                  }
                  helperText={
                    formik.touched.telephone && formik.errors.telephone
                  }
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="logo"
                  label="Logo"
                  placeholder="Logo"
                  value={formik.values.logo}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.logo && Boolean(formik.errors.logo)
                  }
                  helperText={
                    formik.touched.logo && formik.errors.logo
                  }
                />
              </Grid>
             
             
           
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
            
            </div>
          </Box>
      <Divder spacing={1} />
    </>
  );
}

export default Footer;