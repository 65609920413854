import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { insertProposalToCart } from '../../Services/proposalApis';
import { getProposalListing } from '../../Services/OrderApis';
import { ProposalData } from '../../data/CreateProposalData';
const limit = 10;

function ProposalConfirmModal({ open, handleOpen, setPage, proposalId }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    // let resp = await insertProposalToCart(values)
    // if(resp){
    //   resetForm({
    //     user_name : "",
    //     proposal_id : "",
    //   })
    //   setPage(1)
    //   dispatch(getProposalListing({
    //     page: String(1),
    //     limit: limit
    //   }))
      handleOpen(false)
    // }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      user_name : "",
      proposal_id : proposalId || "",
      payment_mode : "cheque-demand-draft",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      user_name: Yup.string().email('Please enter valid email.').required('User Email is required.'),
      proposal_id: Yup.string().required('Proposal Id is required.'),
      payment_mode: Yup.string().required('Payment mode is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
        fullWidth
      >
        <DialogTitle variant='h3'>Confirm Proposal</DialogTitle>
        <DialogContent>
        <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="proposal_id"
                  label="Proposal Id"
                  placeholder="Proposal Id"
                  value={formik.values.proposal_id}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.proposal_id && Boolean(formik.errors.proposal_id)}
                  helperText={formik.touched.proposal_id && formik.errors.proposal_id}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="user_name"
                  label="User Email"
                  placeholder="User Email"
                  value={formik.values.user_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.user_name && Boolean(formik.errors.user_name)}
                  helperText={formik.touched.user_name && formik.errors.user_name}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="payment_mode"
                  label="Payment Mode"
                  placeholder="Payment Mode"
									select
                  SelectProps={{
                    native: true
                  }}
                  value={formik.values?.status}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched?.status &&
                    Boolean(formik.errors?.status)
                  }
                  helperText={
                    formik.touched?.status && formik.errors?.status
                  }
									onChange={formik.handleChange}
                >
                  {ProposalData?.chequePaymentSelected?.map((item, index)=>{
									  return <option key={item.value} value={item.value}>{item.label}</option>
                  })}
								</CustomInput>
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Confirm</CustomButton>
              <CustomButton
                onClick={() => {
                    formik.resetForm({
                      user_name : "",
                      proposal_id : "",
                    });
                    handleOpen(false)}
                }
                disabled={loading}
              >Cancel</CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ProposalConfirmModal;
