import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import AllUserList from './AllUserList';
import BuyerUserList from './BuyerUserList';
import TemporaryUserList from './TemporaryUserList';
import UserAllDetail from './UserAllDetail';
import { useParams, useSearchParams } from 'react-router-dom';
import UserWishlistData from './UserWishlistData';
import UserCartData from './UserCartData';
import UserOutStandingData from './UserOutStandingData';
import UserActiveIvsOrderData from './UserActiveIvsOrderData';
import UserImageDownloadHistory from './UserImageDownloadHistory';
import UserPrivllegeImageDownloadHistory from './UserPrivllegeImageDownloadHistory';
import { isUserPrivilleged } from '../../Services/userApis';

function SelectedUserTabBody({activeTab}) {
  const {userId}=useParams();
  const [isPrivillege,setIsPrivillege]=useState(null);
  useEffect(()=>{
    checkingPrivillege()
  },[userId])

  const checkingPrivillege=async()=>{
    let resp=await isUserPrivilleged({user_id:userId});
    setIsPrivillege(resp?.data);
  }

  
  return (
    <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
       {activeTab == 1 && <UserAllDetail />}
       {activeTab == 2 && <UserWishlistData/>}
       {activeTab == 3 && <UserCartData />}
       {activeTab == 4 && <UserOutStandingData />}
       {activeTab == 5 && <UserActiveIvsOrderData />}
       {activeTab == 6 && <UserImageDownloadHistory/>} 
       <>
       {activeTab == 7 && <UserPrivllegeImageDownloadHistory isPrivillege={isPrivillege}/>}
       </>
       
    </CustomBox>
  )
}

export default SelectedUserTabBody