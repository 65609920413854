import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { deleteApiPayload, errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';

export const insertProposalToCart= async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.insertToCartProposal}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const deleteProposal = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.delete(`${ApiConfig.deleteProposal}`, deleteApiPayload(payload));
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};
