import React from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import ModelList from './ModelList';
import UnAssignImageModel from './UnAssignImageModel';
import AssignImageModel from './AssignImageModel';
import AllImageModel from './AllImageModel';
import NewModels from './NewModels';


function ModelTabBody({activeTab}) {
  return (
    <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
       {activeTab == 1 && <ModelList />}
      {activeTab == 2 && <AllImageModel/>}
       {activeTab == 3 && <UnAssignImageModel/>}
       {activeTab == 4 && <AssignImageModel/>}
       {activeTab == 5 && <NewModels/>}
    </CustomBox>
  )
}

export default ModelTabBody;