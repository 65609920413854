import React, {  } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import { useParams } from 'react-router-dom';
import SendOrderEmailPage from '../../Components/Order/SendOrderEmailPage';
import { decrypt } from '../../lib/helper';
import ApiConfig from '../../config/ApiConfig';

function ExpiryOrderEmail() {
  const {orderId} = useParams();
  const orderIdData = decrypt(ApiConfig.REACT_APP_SALT + 'order_id', orderId);

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <SendOrderEmailPage orderId={orderIdData}/>
      <Divder spacing={1.2} />
    </Box>
  );
}

export default ExpiryOrderEmail;
