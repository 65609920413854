import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { addCompanyMaster, companyGroupListingsNoRedux, companyListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { addSubscriptionPlan, subscriptionListings } from '../../Services/SubscriptionApis';
import { addStarIndustry, editStarIndustry } from '../../Services/starIndustriesApis';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import { addModel, editModel } from '../../Services/modelApi';

const limit = DATA_LIMIT;

function AddEditModel({ open, handleOpen, setPage, handleLast, data, type }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [countryShortName, setCountryShortName] = useState('in');

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    if (type === 'add') {
      let resp = await addModel(values)
      if (resp) {
        resetForm({
          name: "",
          releaseid: "",
          mobile: "",
        })
        setPage(1)
      }
      handleLast();
      handleOpen(false);
    }
    else if (type === 'edit') {
      let finalObj = { ...values, model_id: data?.sno, modelid: data?.modelid }
      let resp = await editModel(finalObj);
      if (resp) {
        resetForm({
          name: "",
          releaseid: "",
          mobile: "",
        })
        setPage(1)
      }
      handleLast();
      handleOpen(false)
    }
    setLoading(false)
  };
  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobile', val);
  };

  const formik = useFormik({
    initialValues: {
      name: data?.Name || "",
      releaseid: data?.releaseid || "",
      mobile: data?.mobile || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required.'),
      releaseid: Yup.string().required('Release Id is required.'),
      mobile: Yup.string()
        .max(18, 'Mobile Number is Invalid')
        .min(10, 'Mobile Number is Invalid')
        .required('Mobile Number is required'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          {type === 'add' ? 'Add' : 'Edit'} Model
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="name"
                  label="Name"
                  placeholder="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="releaseid"
                  label="Release ID"
                  placeholder="Release ID"
                  value={formik.values.releaseid}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.releaseid && Boolean(formik.errors.releaseid)}
                  helperText={formik.touched.releaseid && formik.errors.releaseid}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <CustomPhoneInput
                  label="Mobile"
                  id="mobile"
                  name="mobile"
                  className="main-login-form"
                  fullWidth
                  placeholder="Mobile Number"
                  native={true}
                  value={formik.values.mobile}
                  defaultCountry={`${countryShortName}`}
                  country={`${countryShortName}`}
                  divStyle={{
                    paddingTop: 4,
                  }}
                  variant="outlined"
                  onChange={(newValue, info) => {
                    handleChangeMobile(newValue);
                  }}
                  onlyCountries={[countryShortName]}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    group_id: "",
                    category: "",
                    sub_category: "",
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddEditModel;
