import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { orderListingItemsSelector } from '../../redux/orderListing';
import { getSubscriptionListing } from '../../Services/OrderApis';
import MatTable from '../Tables/MatTable';

const limit = 10;

const column = [
    {
      label: 'Order ID',
      id: 'order_id',
      format: (columnData) => (
        <Link className='textDecoration-none link-text' to={`orderDetails/${columnData}`}>
          <Typography
            variant='span'
            style={{ whiteSpace: 'nowrap' }}
          >
            {columnData}
          </Typography>
        </Link>
      ),
    },
    {
    label: 'User Id',
    id: 'user_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}> {columnData}</span>)
    },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
        <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Payment Mode',
    id: 'payment_type',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}> {columnData || 'N/A'}</span>)
  },
  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}> {columnData || '-'}</span>)
  },
  {
    label: 'Plan Id',
    id: 'plan_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}> {columnData || 'N/A'}</span>)
  },
  {
    label: 'Company Name',
    id: 'company',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}> {columnData || 'N/A'}</span>)
  },
  {
    label: 'Order Date',
    id: 'ordered_on',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}> {formatDate(columnData)}</span>)
  },
  {
    label: 'Order Status',
    id: 'order_status',
    format: (columnData) => (
      <Typography
        variant="span"
        sx={{ color: columnData == 1 ? 'success.main' : columnData == "2" ? 'danger.main' : columnData == "0" ? 'info.main' : "N/A" , width: 120, display: 'inline-block' }}
      >
        {columnData == 1 ? "Success" : columnData == "2" ? "Rejected" : columnData == "0" ? "Pending" : "N/A"  }
      </Typography>
    ),
  },
];

function SubscriptionList() {
  const [orderListing, setOrderListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, subscriptionListing } = useSelector(orderListingItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const getOrderListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        subscription_list: String(1),
        ...allParams,
        ...params
    }
    dispatch(getSubscriptionListing(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getOrderListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getOrderListing();
  },[allParams])


  useEffect(() => {
    if(subscriptionListing?.items?.orders?.length >= 0){
      let data = subscriptionListing?.items?.orders?.map((item)=>{
        return {
          order_id: item.order_id,
          user_id: item.user_id,
          email: item.user.email,
          payment_type: item.payment_type,
          invoice_id: item?.invoice?.invoice_id,
          plan_id: item.plan_id,
          company: item.company,
          ordered_on: item.ordered_on,
          order_status: item.order_status
        }
      })
   
      setOrderListing(data)
    }
  }, [subscriptionListing.items]);


  return (
    <>
      <MatTable
        loading={subscriptionListing?.loading}
        showCheckbox={false}
        columns={column}
        data={orderListing}
        onApplyFilter={(data={})=>{
          getOrderListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
     
        page={page}
        perPage={limit}
        total={subscriptionListing?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getOrderListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getOrderListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
    </>
  );
}

export default SubscriptionList;
