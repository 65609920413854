import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginLayout from '../Screen/Layouts/LoginLayout';
import MainLayout from '../Screen/Layouts/MainLayout';
import { ProtectedRoute } from './ProtectedRoute';
import DashboardContainer from '../Screen/Dashboard/index';
import OrderContainer from '../Screen/Order';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageNotFound from '../Screen/NotFound/PageNotFound';
import AppSettingsContainer from '../Screen/AppSettings';
import SubscriptionContainer from '../Screen/Subscription';
import OrderDetailsContainer from '../Screen/Order/OrderDetailsContainer';
import ContactUsContainer from '../Screen/ContactUs';
import ContributorContainer from '../Screen/Contributor';
import UserContainer from '../Screen/User';
import CompanyContainer from '../Screen/Company';
import CategoryContainer from '../Screen/Category';
import StarIndustriesContainer from '../Screen/StarIndustries';
import ImageContainer from '../Screen/Image';
import UserDetailsContainer from '../Screen/User/UserDetailsContainer';
import AddImageContainer from '../Screen/Image/AddImageContainer';
import CreateProposalContainer from '../Screen/ProposalManagement';
import ImageGroupContainer from '../Screen/Image/ImageGroupContainer';
import InvoiceContainer from '../Screen/Receipts/InvoiceContainer';
import IrnContainer from '../Screen/Irn';
import ExpiryEmailContainer from '../Screen/Order/ExpiryEmailContainer';
import SuccessProposalContainer from '../Screen/ProposalManagement/SuccessProposalContainer';
import ProposalQuotationPage from '../Components/ProposalManagement/ProposalQuotationPage';
import { MaintenanceRoute } from './MaintenanceRoute';
import { useSelector } from 'react-redux';
import { commonItemsSelector } from '../redux/common';
import MaintenanceLayout from '../Screen/Layouts/MaintenanceLayout';
import CheckoutContainer from '../Screen/Checkout/CheckoutContainer';
import ConfirmProposalContainer from '../Screen/ProposalManagement/confirmProposalContainer';
import EditInvoiceContainer from '../Screen/Order/EditInvoiceContainer';
import SendOrderEmailContainer from '../Screen/Order/SendOrderEmailContainer';
import SubCategoryContainer from '../Screen/Category/SubCategoryContainer';
import RenewalCheckoutContainer from '../Screen/Checkout/RenewalCheckoutContainer';
import KeywordsContainer from '../Screen/Keywords';
import VisibleHiddenKeywordsContainer from '../Screen/Keywords/VisibleHiddenKeywordsContainer';
import CreditNoteContainer from '../Screen/Receipts/CreditNoteContainer';
import ImageDetailContainer from '../Screen/Image/ImageDetailContainer';
import ReportsContainer from '../Screen/Reports';
import VideoContainer from '../Screen/Video';
import AdminUsersContainer from '../Screen/AdminUsers';
import ContributorDetailsContainer from '../Screen/Contributor/ContributorDetailsContainer';
import LogsContainer from '../Screen/WebsiteLogs';
import { isAccessiblePath } from '../lib/helper';
import AddVideoContainer from '../Screen/Video/AddVideoContainer';
import UserFollowUpsList from '../Screen/FollowUps/UserFollowUpsList';
import PaymentFollowUpsList from '../Screen/FollowUps/PaymentFollowUpsList';
import PendingOrderFollowUpsContainer from '../Screen/FollowUps/PendingOrderFollowUpsContainer';
import CMSContainer from '../Screen/CMS';
import ContributorSoldImgContainer from '../Screen/Contributor/ContributorSoldImgContainer';
import DailySalesEntryCreateContainer from '../Screen/FollowUps/DailySalesEntryCreateContainer';
import DailySaleFollowupHistoryContainer from '../Screen/FollowUps/DailySaleFollowupHistoryContainer';
import ModelContainer from '../Screen/Model';
import ModelImageContainer from '../Screen/Model/ModelImageContainer';
import VideoKeywordContainer from '../Screen/Video/VideoKeywordContainer';
import ImageKeywordContainer from '../Screen/Image/ImageKeywordContainer';
import PartPayInvoiceContainer from '../Screen/Receipts/PartPayInvoiceContainer';
import ShootsImagesPage from '../Components/Image/ShootsImagesPage';
import ShootsImageMain from '../Screen/Image/ShootsImageMain';
import FollowUpsLastContainer from '../Screen/FollowUps/fullLastFollowUp';
import FollowUpsSearchContainer from '../Screen/FollowUps/fullFollowUpSearch';
import MyAccountContainer from '../Screen/MyAccount';
import LoaderSuspense from '../Components/CommonComp/LoaderSuspense';
import UserOneContainer from '../Screen/User/oneUserContainer';
import VideoKeywordContainerLive from '../Screen/Video/VideoKeywordContainerLive';
import IvsCheckoutContainer from '../Screen/Checkout/IvsCheckoutContainer';
import MasterContainer from '../Screen/Master';
import MyAccountPasswordContainer from '../Screen/AdminPasswordChange';
import ResetPasswordComponent from '../Screen/Layouts/ResetPasswordComponent';
import ShortCompanyContainer from '../Screen/Company/ShortCompanyContainer';
import ContributorPaymentDetailsContainer from '../Screen/Contributor/ContributorPaymentDetailsContainer';
import ContributorOneContainer from '../Screen/Contributor/ContributorOneContainer';
import UserCompanyDetailsContainer from '../Screen/Company/UserCompanyDetailsContainer';
import OrderCompanyDetailsContainer from '../Screen/Company/OrderCompanyDetailsContainer';
import ContributorFolderDetailsContainer from '../Screen/Contributor/ContributorFolderDetailContainer';
import ContributorUploadedDetailsContainer from '../Screen/Contributor/ContributorUploadedDetailContainer';
import MasterFollowUpsContainer from '../Screen/FollowUps/MasterFollowUpsContainer';
import MasterMultipleFollowUpsContainer from '../Screen/FollowUps/MasterMultipleFollowUpsContainer';
import CompanyOrdersDetailsContainer from '../Screen/Company/CompanyOrdersDetailsContainer';
const FollowUpsContainer = lazy(() => import('../Screen/FollowUps'));

const AllRoutes = ({ user, isMaintenance }) => {
  return (
    <>
      <Suspense fallback={<LoaderSuspense />}>
        <ToastContainer />
        <Routes>
          <Route element={<MaintenanceRoute isMaintenance={isMaintenance} />}>
            {/* ============= LOGEDOUT ROUTES ========== */}
            <Route index element={<LoginLayout />} />
            <Route path="login" element={<LoginLayout />} />
            <Route path="recoverpassword" element={<ResetPasswordComponent />} />

            {/* ============= LOGEDIN ROUTES ========== */}




            <Route element={<ProtectedRoute isAllowed={!!user} />}>
              <>
                <Route path="myAccount">
                  <Route index={true} element={<MyAccountContainer />} />
                </Route>
              </>
              <>
                <Route path="updatePassword">
                  <Route index={true} element={<MyAccountPasswordContainer />} />
                </Route>
              </>

              {isAccessiblePath('dashboard') && (
                <Route path="dashboard" element={<DashboardContainer />} />
              )}
              {isAccessiblePath('orders') && (
                <Route path="order">
                  <Route index={true} element={<OrderContainer />} />
                  <Route
                    path="orderDetails"
                    element={<Navigate to={'/order'} replace={true} />}
                  />
                  <Route
                    path="orderDetails/:orderId"
                    element={<OrderDetailsContainer />}
                  />
                  <Route
                    path="editInvoice"
                    element={<Navigate to={'/order'} replace={true} />}
                  />
                  <Route
                    path="editInvoice/:orderId"
                    element={<EditInvoiceContainer />}
                  />
                  <Route
                    path="sendEMailOrder"
                    element={<Navigate to={'/order'} replace={true} />}
                  />
                  <Route
                    path="sendEMailOrder/:orderId"
                    element={<SendOrderEmailContainer />}
                  />
                  <Route
                    path="expiryEmail"
                    element={<ExpiryEmailContainer />}
                  />
                </Route>
              )}
              {isAccessiblePath('settings') && (
                <Route path="settings" element={<AppSettingsContainer />} />
              )}
              {isAccessiblePath('users') && (
                <>
                  <Route path="user">
                    <Route index={true} element={<UserContainer />} />
                    <Route path="userDetail">
                      <Route
                        index={true}
                        element={<Navigate to={'/user'} replace={true} />}
                      />
                      <Route path=":userId" element={<UserOneContainer />} />
                    </Route>

                    {/* <Route index={true} element={<Navigate to={'/user'} replace={true}/>}/>
<Route path=":userId" element={<CreateProposalContainer/>} /> */}
                    {/* </Route> */}
                  </Route>
                  <Route path="checkout" element={<CheckoutContainer />} />
                  <Route
                    path="renewalCheckout"
                    element={<RenewalCheckoutContainer />}
                  />
                  <Route
                    path="ivsCheckout"
                    element={<IvsCheckoutContainer />}
                  />
                </>
              )}
              {isAccessiblePath('users') && (
                <>
                  <Route path="crm">
                    <Route index={true} element={<FollowUpsContainer />} />
                    <Route path="userHistory">
                      <Route
                        index={true}
                        element={<Navigate to={'/crm'} replace={true} />}
                      />
                      <Route path=":userId" element={<UserFollowUpsList />} />
                    </Route>
                    <Route path="orderHistory">
                      <Route
                        index={true}
                        element={<Navigate to={'/crm'} replace={true} />}
                      />
                      <Route
                        path=":orderId"
                        element={<PendingOrderFollowUpsContainer />}
                      />
                    </Route>
                    <Route path="pendingOrderFollowupsHistory">
                      <Route
                        index={true}
                        element={<Navigate to={'/crm'} replace={true} />}
                      />
                      <Route
                        path=":orderId"
                        element={<PendingOrderFollowUpsContainer />}
                      />
                      {/* <Route path=":orderId" element={<PaymentFollowUpsList />} /> */}
                    </Route>
                    <Route
                      path="orderManage/invoice"
                      element={<InvoiceContainer />}
                    />
                    <Route
                      path="partPay/invoice"
                      element={<PartPayInvoiceContainer />}
                    />
                    <Route
                      path="createDailySaleEntry/lastFollowUp/email/:email"
                      element={<FollowUpsLastContainer />}
                    />
                    <Route
                      path="createDailySaleEntry/lastFollowUp/mobile/:mobile"
                      element={<FollowUpsLastContainer />}
                    />
                    <Route
                      path="createDailySaleEntry/searchFollowUp/:companyName/:state/:association"
                      element={<FollowUpsSearchContainer />}
                    />
                    <Route
                      path="createDailySaleEntry"
                      element={<DailySalesEntryCreateContainer />}
                    />
                    

                    <Route path="masterFollowups">
                      <Route
                        index={true}
                        element={<Navigate to={'/crm'} replace={true} />}
                      />
                      <Route
                        path=":order_id"
                        element={<MasterFollowUpsContainer />}
                      />
                    </Route>

                    <Route path="masterMultipleFollowups" element={<MasterMultipleFollowUpsContainer />}>
                    </Route>
                    
                    <Route path="saleEntryHistory">
                      <Route
                        index={true}
                        element={<Navigate to={'/crm'} replace={true} />}
                      />
                      <Route
                        path=":dailySaleId"
                        element={<DailySaleFollowupHistoryContainer />}
                      />
                    </Route>
                  </Route>
                </>
              )}
              {isAccessiblePath('proposal')}
              <Route path="proposal">
                <Route
                  path="createProposal"
                  element={<CreateProposalContainer />}
                />
                <Route path="success" element={<SuccessProposalContainer />} />
                <Route path="confirm" element={<ConfirmProposalContainer />} />
                {/* <Route index={true} element={<Navigate to={'/user'} replace={true}/>}/>
<Route path=":userId" element={<CreateProposalContainer/>} /> */}
                {/* </Route> */}
              </Route>
              {/* {isAccessiblePath('users') && */}
              <Route path="quotation" element={<ProposalQuotationPage />} />
              {/* } */}
              {isAccessiblePath('companies') && (<>
                <Route path="company" element={<CompanyContainer />} />
                <Route path='company/shortCompanyName/:shortCompanyName' element={<ShortCompanyContainer />} />
                <Route path='company/shortCompanyName/:shortCompanyName/users' element={<UserCompanyDetailsContainer />} />
                {/* <Route path='company/shortCompanyName/:shortCompanyName/orders' element={<OrderCompanyDetailsContainer />} /> */}
                <Route path='company/shortCompanyName/:shortCompanyName/orders' element={<OrderCompanyDetailsContainer />} />
                <Route path='company/orders' element={<OrderCompanyDetailsContainer />} />
              </>
              )}
                              <Route path='company/allOrders' element={<CompanyOrdersDetailsContainer />} />

              {isAccessiblePath('report') && (
                <Route path="reports" element={<ReportsContainer />} />
              )}
              {isAccessiblePath('logs') && (
                <Route path="logs" element={<LogsContainer />} />
              )}

              {isAccessiblePath('categories') && (
                <Route path="category">
                  <Route index={true} element={<CategoryContainer />} />
                  <Route
                    path="subCategory"
                    element={<SubCategoryContainer />}
                  />
                </Route>
              )}
              {/* <Route path="image" element={<ImageContainer />} /> */}
              {isAccessiblePath('images') && (
                <>
                  <Route path="image">
                    <Route path="imageKeywords">
                      <Route
                        path=":imageId"
                        element={<ImageKeywordContainer />}
                      />
                    </Route>
                    <Route index={true} element={<ImageContainer />} />
                    <Route path="soldImagesByGroupId">
                      <Route
                        index={true}
                        element={<Navigate to={'/image'} replace={true} />}
                      />
                      <Route
                        path=":groupId"
                        element={<ImageGroupContainer />}
                      />
                    </Route>
                    <Route
                      path=":imageName"
                      element={<ImageDetailContainer />}
                    />
                    <Route path="shoots/:sid" element={<ShootsImageMain />} />
                  </Route>
                  <Route path="addImages" element={<AddImageContainer />} />
                </>
              )}
              {isAccessiblePath('videos') && (
                <>
                  <Route path="videos">
                    <Route path="videoKeywords">
                      <Route
                        path=":videoId"
                        element={<VideoKeywordContainer />}
                      />
                    </Route>
                    <Route path="videoAllKeywords">
                      <Route
                        path=":videoId"
                        element={<VideoKeywordContainerLive />}
                      />
                    </Route>
                    <Route index={true} element={<VideoContainer />} />
                    {/* <Route path="soldImagesByGroupId">
<Route index={true} element={<Navigate to={'/videos'} replace={true}/>}/>
<Route path=":groupId" element={<ImageGroupContainer />} />
</Route>*/}
                    <Route
                      path=":videoName"
                      element={<ImageDetailContainer />}
                    />
                  </Route>
                  <Route path="addVideo" element={<AddVideoContainer />} />
                </>
              )}

              <Route path="invoice" element={<InvoiceContainer />} />
              <Route path="creditNote" element={<CreditNoteContainer />} />

              {isAccessiblePath('star_indutries') && (
                <Route
                  path="starIndustries"
                  element={<StarIndustriesContainer />}
                />
              )}
              {/* <Route path="contributor" element={<ContributorContainer />} /> */}

              {isAccessiblePath('contributors') && (
                <Route path="contributor">
                  <Route index={true} element={<ContributorContainer />} />
                  <Route path="contributorDetail">
                    <Route
                      index={true}
                      element={<Navigate to={'/contributor'} replace={true} />}
                    />
                    <Route
                      path=":contributorId/:emailId/payment/:paymentId"
                      element={<ContributorPaymentDetailsContainer />}
                    />
                    <Route path=":contributorId/:emailId" element={<ContributorOneContainer />} />


                  </Route>
                  <Route path="fileFolderList">
                    <Route
                      index={true}
                      element={<Navigate to={'/contributor'} replace={true} />}
                    />
                    <Route
                      path=":contributorId"
                      element={<ContributorFolderDetailsContainer />}
                    />
                    <Route
                      path=":contributorId/:folder_name"
                      element={<ContributorUploadedDetailsContainer />}
                    />
                  </Route>
                  <Route path=":contributorId/:emailId" element={<ContributorOneContainer />} />
                  <Route
                    path="payments/:emailId"
                    element={<ContributorSoldImgContainer />}
                  />
                </Route>
              )}

              {/* <Route path="keywords" element={<KeywordsContainer />} /> */}
              {isAccessiblePath('keywords') && (
                <Route path="keywords">
                  <Route index={true} element={<KeywordsContainer />} />
                  <Route path="visibleKeyword">
                    <Route
                      index={true}
                      element={<Navigate to={'/keywords'} replace={true} />}
                    />
                    <Route
                      path=":visibleKeywordId"
                      element={<VisibleHiddenKeywordsContainer />}
                    />
                  </Route>
                </Route>
              )}
              {isAccessiblePath('subscriptions') && (
                <Route
                  path="subscription"
                  element={<SubscriptionContainer />}
                />
              )}
              {isAccessiblePath('irn') && (
                <Route path="irn" element={<IrnContainer />} />
              )}
              {isAccessiblePath('contact_us') && (
                <Route path="queryList" element={<ContactUsContainer />} />
              )}
              {isAccessiblePath('admin_users') && (
                <Route path="adminUsersAndAccess">
                  <Route index={true} element={<AdminUsersContainer />} />
                </Route>
              )}
              {isAccessiblePath('cms') && (
                <Route path="cms">
                  <Route index={true} element={<CMSContainer />}></Route>
                </Route>
              )}

              {isAccessiblePath('model') && (
                <Route path="model">
                  <Route index={true} element={<ModelContainer />}></Route>
                  <Route path=":sno/:name" element={<ModelImageContainer />} />
                </Route>
              )}
              {isAccessiblePath('master') && (
                <Route path="master">
                  <Route index={true} element={<MasterContainer />}></Route>
                </Route>
              )}
            </Route>

            {/* <Route
            path="analytics"
            element={
<ProtectedRoute
                redirectPath="/home"
                isAllowed={
                  !!user && user?.permissions?.includes('analyze')
                }
>
<Analytics />
</ProtectedRoute>
            }
          />
<Route
            path="admin"
            element={
<ProtectedRoute
                redirectPath="/home"
                isAllowed={!!user && user?.roles?.includes('admin')}
>
<Admin />
</ProtectedRoute>
            }
          /> */}

            {/* ============= COMMON ROUTES ========== */}
          </Route>
          <Route path="*" element={<PageNotFound />} />
          <Route path="maintenance" element={<MaintenanceLayout />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AllRoutes;
