import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import Reports from './Reports'

function ReportsContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "Daily Sales Report",
    navSubTitle: "Welcome to ImagesBazaar"
  })

  const handleNavParams = (obj)=>{
    setNavParams({
      ...navParams,
      ...obj
    })
  }

  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
      <Reports handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default ReportsContainer