import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import {
  downloadBlobXLSFile,
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
} from '../../lib/helper';
import { userItemsSelector } from '../../redux/user';
import { customHistory } from '../../Route/CustomBrowserRouter';
import {
  allUserListing,
  allUserListingExportFile,
} from '../../Services/userApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import CreateUserFollowUps from '../Modals/CreateUserFollowUps';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Id',
    id: 'user_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'First Name',
    id: 'first_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Last Name',
    id: 'last_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Job Description',
    id: 'job_description',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Mobile',
    id: 'mobile',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 200, minWidth: 200, wordBreak: 'break-word' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (
      <Typography
        variant="span"
        sx={{
          ...(columnData == 1
            ? { color: 'success.main' }
            : columnData == 2
            ? { color: 'danger.main' }
            : columnData == 0
            ? { color: 'info.main' }
            : {}),
          whiteSpace: 'nowrap',
          display: 'inline-block',
        }}
      >
        {columnData == 0
          ? 'Inactive'
          : columnData == 1
          ? 'Active'
          : columnData == 2
          ? 'Deleted'
          : 'N/A'}
      </Typography>
    ),
  },
  {
    label: 'Joined At',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd-mm-yyyy')}
      </span>
    ),
  },
];

function OrderFollowUps() {
  const [tableListing, setTableListing] = useState([]);
  const [expLoading, setExpLoading] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState({});
  const [openCreateFollowup, setOpenCreateFollowup] = useState(false);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, allUserList } = useSelector(userItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params,
    };
    dispatch(allUserListing(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);
  const handleAdd = (value) => {
    setOpenCreateFollowup(value);
  };
  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getListing();
  }, [allParams]);

  //making data format for table input
  useEffect(() => {
    if (allUserList?.items?.users?.length >= 0) {
      setTableListing(allUserList?.items?.users);
    }
  }, [allUserList.items]);

  const getExportFile = async (params = {}) => {
    try {
      let payload = {
        ...allParams,
        ...params,
      };
      delete payload.page;
      delete payload.limit;

      setExpLoading(true);
      let resp = await allUserListingExportFile(payload);
      if (resp) {
        downloadBlobXLSFile(resp?.data, `UserList`);
      } else {
        toast.error('Something went wrong.');
      }
      setExpLoading(false);
    } catch (e) {
      console.log(e);
      setExpLoading(false);
    }
  };
  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          {/* <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '110px', bgcolor: 'buttonInfo.main' }}>USER DETIALS</CustomButton> */}
          <CustomButton
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              width: '150px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
            onClick={() => {
              handleAdd(true);
              setSelectedUserData(data);
            }}
          >
            CREATE
          </CustomButton>
          <CustomButton
            onClick={() => {
              if (data && data != null) {
                customHistory.push(`crm/userHistory/${data?.user_id}`);
              }
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              width: '135px',
            }}
          >
            VIEW
          </CustomButton>
          {/* <CustomButton variant="raised" sx={{ padding: '1px 1px', marginRight: 1, fontSize: '12px', bgcolor: '#fff0', minWidth: 20 }}><Delete/></CustomButton> */}
        </div>
      </>
    );
  };

  return (
    <>
      <MatTable
        loading={allUserList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={allUserList?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
      {openCreateFollowup && (
        <CreateUserFollowUps
          open={openCreateFollowup}
          handleOpen={handleAdd}
          setPage={setPage}
          getListing={getListing}
          selectedData={selectedUserData}
        />
      )}
    </>
  );
}

export default OrderFollowUps;
