import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currencyConversion, formatDate, getObjectSubset } from '../../lib/helper';
import { imageListingItemsSelector } from '../../redux/image';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { soldImagesByGroupIdsListings, soldImagesByGroupListings } from '../../Services/imageApis';
import ImageDetailModal from '../Modals/ImageDetailModal';
import MatTable from '../Tables/MatTable';

// const limit = DATA_LIMIT;

const column = [
  {
    label: 'Sold Image Id',
    id: 'sold_image_id',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
        onClick={() => {
        }}
      >
        {columnData}
      </span>
    ),
  },
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
        onClick={() => {
        }}
      >
        {columnData}
      </span>
    ),
  },
  {
    label: 'User',
    id: 'user_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Plan Id',
    id: 'plan_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Image Name',
    id: 'image_name',
    format: (columnData, rowIndex, formatCallback, options) => (
      <span  style={{ display: 'inline-block', whiteSpace: 'nowrap', borderBottom: '1px solid', cursor: 'pointer'}}
      onClick={() => {
        options(rowIndex, columnData)
      }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Pack',
    id: 'pack',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Download On',
    id: 'Download_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData)}
      </span>
    ),
  },
  {
    label: 'Quality',
    id: 'quality',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Amount',
    id: 'amount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {/* {columnData} */}
        {columnData ? currencyConversion(columnData) : '0'}
      </span>
    ),
  },
  {
    label: 'Discount',
    id: 'Discount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Client',
    id: 'client',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Order by',
    id: 'order_by',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Image Type',
    id: 'image_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  }
];

function ImageslistForGroupId({groupId}) {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState({});
  const [imageDetailModal, setImageDetialModal] = useState(false);
  const [imageName, setImageName] = useState(null);
  const { loading, listOfSoldImagesByGroupIds } = useSelector(imageListingItemsSelector);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(20);


  const handleModal = (value = false, image = null) => {
    setImageDetialModal(value);
    if (!value) {
      setTimeout(() => {
        setImageName(image);
      }, 500);
    }else{
      setImageName(image);
    }
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: String(limit),
      group_id: groupId,
      ...allParams,
      ...params,
    };
    dispatch(soldImagesByGroupIdsListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);


  useEffect(() => {
    if (listOfSoldImagesByGroupIds?.items?.elements?.length >= 0) {
      setTableListing(listOfSoldImagesByGroupIds?.items?.elements);
    }
  }, [listOfSoldImagesByGroupIds.items]);

  return (
    <>
      <MatTable
        loading={listOfSoldImagesByGroupIds?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20,50,100,200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={listOfSoldImagesByGroupIds?.items?.totalcount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        columnWiseData={[
            {
              id: "image_name",
              value: (index, data)=>{
                handleModal(true, data)
              }
            }
          ]}
        toolbarEnable={true}
        pagination={true}
      />
      <ImageDetailModal
        open={imageDetailModal}
        handleOpen={handleModal}
        imageName={imageName}
      />
    </>
  );
}

export default ImageslistForGroupId;
