import axios from 'axios';
import ApiConfig from '../config/ApiConfig';
import {
  setLoading,
  setUserDetails,
  setUserDetailsError,
  setUserMenus,
} from '../redux/login';
import { setItemInStorage } from '../storage/Storage';
import { toast } from 'react-toastify';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { sideMenu } from '../data/SideMenu';
import { setAllMenuListing } from '../redux/userMenus';

export const LoginUser = (payload, login) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const resp = await axios.post(`${ApiConfig.login}`, payload);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          setItemInStorage('token', resp.data.access_token);
          login(resp.data.data);
          dispatch(setUserDetails(resp.data.data));

          let menus = resp.data.data?.admin_menu;
          if (menus) {
            let newArr = sideMenu?.filter((a) => {
              if(a?.value==='admin_users'){
                if(resp?.data?.data?.is_superadmin==='1')return true;
                else return false;
              } 
              return menus[a?.value] === "1"
            })
            
            setItemInStorage('userMenu', JSON.stringify(newArr));
           // dispatch(setAllMenuListing(sideMenu));
            dispatch(setAllMenuListing(newArr));
            
          }
          dispatch(setLoading(false));
          toast.success(resp.data.message, {
            autoClose: 2500,
          });
        } else {
          throw resp
        }
      } catch (e) {
        dispatch(
          setUserDetailsError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        dispatch(setLoading(false));
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
      }
    };
  }
};

export const UserRegister = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.userRegister}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const ForgotPWD = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.emailForForgotPWD}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const recoverPassword= async(payload)=>{
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.forgotPwd}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }

}
