import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import SuccessProposal from './SuccessProposal'

function SuccessProposalContainer() {
  return (
    <MainLayout navTitle={"Success Proposal"} navSubTitle={"Welcome to ImagesBazaar"}>
      <SuccessProposal/>
    </MainLayout>
  )
}

export default SuccessProposalContainer