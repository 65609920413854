import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { companyItemsSelector } from '../../redux/company';
import { companyListings, deleteCompany } from '../../Services/companyApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import AddCompanyMaster from '../Modals/AddCompanyMaster';
import ConfirmationModal from '../Modals/ConfirmationModal';
import EditCompanyDetailModal from '../Modals/EditCompanyDetailModal';
import MatTable from '../Tables/MatTable';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'ID',
    id: 'company_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Group Id',
    id: 'company_group_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Group Name',
    id: 'company_group_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Company Short Name',
    id: 'short_company_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Company Address',
    id: 'company_address',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'GST Number',
    id: 'gst_number',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Date',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData)}
      </span>
    ),
  },
];

function CompanyList() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, companyList } = useSelector(companyItemsSelector);
  const dispatch = useDispatch();
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const [confirmDeleteCompany, setConfirmDeleteCompany] = useState(false);
  const [deleteCompanyData, setDeleteCompanyData] = useState({});
  const navigate = useNavigate()
  const location = useLocation();
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});


  const handleAdd = (value=false) =>{
    setAddCompanyModal(value)
  }
  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(companyListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  //making data format for table input
  useEffect(() => {
    if (companyList?.items?.data?.length >= 0) {
      let data = companyList?.items?.data?.map((item) => {
        return {
          company_id: item.company_id,
          company_group_id: item.company_group_id,
          created_at: item.created_at,
          short_company_name: item.short_company_name,
          company_name: item.company_name,
          state: item.state,
          gst_number: item.gst_number,
          company_address: item.company_address,
          status: item.status,
          company_group_name: item?.company_group?.company_group_name,
        };
      });
      setTableListing(data);
    }
  }, [companyList.items]);

  const handleConfirmDeleteCompany = async(value) =>{
    if(value && deleteCompanyData){
      let resp = await deleteCompany({company_id: deleteCompanyData})
      await getListing();
    }else{
      setDeleteCompanyData(null)
    }
  }

  const handleCofirmModalOpen = (value) => {
    setConfirmDeleteCompany(value);
  }
  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <CustomButton toolTipTittle={'Edit'}
            onClick={() => {
              handleModal(true);
              setEditData(data);
            }}
            className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit/></CustomButton>
          {/* <CustomButton
            variant="raised"
            sx={{
              padding: '1px 1px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: '#fff0',
              minWidth: 20,
            }}
            onClick={()=>{
              if(data?.company_id){
                handleCofirmModalOpen(true)
                setDeleteCompanyData(data?.company_id)
                // let resp = deleteCompany({company_id: data?.company_id})
                // getListing();
              }
            }}
          >
            <Delete />
          </CustomButton> */}
          <CustomButton className="noMinWidth buttonDense" onClick={()=>{
            if(data?.company_id){
              handleCofirmModalOpen(true)
              setDeleteCompanyData(data?.company_id)
            }
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete/></CustomButton>
        </div>
      </>
    );
  };

  const handleModal = (value = false) => {

    setEditModal(value);
    if (!value) {
      setTimeout(() => {
        setEditData({});
      }, 500);
    }
  };

  return (
    <>
      <MatTable
        loading={companyList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={companyList?.items?.totalCount || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolBarButton={()=>{
          return <>
            <CustomButton onClick={()=>{handleAdd(true)}} color={'buttonPrimary'}  sx={(theme)=>({ padding: '10px 10px', marginRight: 1, 
                [theme.breakpoints.down("md")]: {
                  flex: 1,
                }
              })}>Add Company</CustomButton>
          </>
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
      <AddCompanyMaster open={addCompanyModal} handleOpen={handleAdd}/>
      {editModal && <EditCompanyDetailModal
        open={editModal}
        handleOpen={handleModal}
        initialData={editData}
        getListing={getListing}
      />}
      {confirmDeleteCompany && <ConfirmationModal open={confirmDeleteCompany} handleConfirm={handleConfirmDeleteCompany} setOpen={handleCofirmModalOpen}/>}
    </>
  );
}

export default CompanyList;
