import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import {
  addCompanyMaster,
  addNewCompanyMaster,
  companyGroupListingsNoRedux,
  companyListings,
} from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';

const limit = DATA_LIMIT;

function EditCompanyMasterDetails({
  open,
  handleOpen,
  getListing,
  selectedData,
}) {
  const [loading, setLoading] = useState(false);
  const [groupData, setGroupData] = useState(null);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    const updatedValues = {
      ...values,
      company_id: selectedData?.company_id,
    };
    let resp = await addNewCompanyMaster(updatedValues);
    if (resp) {
      resetForm({
        company_name: '',
        similar_company_name: '',
        company_group_name: '',
        discount: '',
        state: '',
      });
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      company_name: selectedData?.company_name || '',
      similar_company_name: selectedData?.similar_company_name || '',
      company_group_name: selectedData?.company_group_name || '',
      discount: selectedData?.discount || '',
      state: selectedData?.state || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      company_name: Yup.string().required('Company name is required.'),
      similar_company_name: Yup.string().required(
        'Similar company name is required.',
      ),
      company_group_name: Yup.string().required(
        'Company group name is required.',
      ),
      discount: Yup.string().required('Discount is required.'),
      state: Yup.string().required('State is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Add Company Master
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="company_name"
                  label="Company Name"
                  placeholder="Company Name"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.company_name &&
                    Boolean(formik.errors.company_name)
                  }
                  helperText={
                    formik.touched.company_name && formik.errors.company_name
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="similar_company_name"
                  label="Similar Company"
                  multiline
                  rows={3}
                  placeholder="Similar Company"
                  value={formik.values.similar_company_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.similar_company_name &&
                    Boolean(formik.errors.similar_company_name)
                  }
                  helperText={
                    formik.touched.similar_company_name &&
                    formik.errors.similar_company_name
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="company_group_name"
                  label="Company Group"
                  placeholder="Company Group"
                  value={formik.values.company_group_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.company_group_name &&
                    Boolean(formik.errors.company_group_name)
                  }
                  helperText={
                    formik.touched.company_group_name &&
                    formik.errors.company_group_name
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="discount"
                  label="Discount"
                  placeholder="Discount (%)"
                  value={formik.values.discount}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.discount && Boolean(formik.errors.discount)
                  }
                  helperText={formik.touched.discount && formik.errors.discount}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="state"
                  label="Company State"
                  placeholder="Company State"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    company_group_id: '',
                    company_name: '',
                    short_company_name: '',
                    state: '',
                    gst_number: '',
                    company_address: '',
                  });
                  setGroupData(null);
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditCompanyMasterDetails;
