import { Typography } from '@mui/material'
import React, { useEffect } from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import PendingOrder from './PendingOrder'
import BookOrder from './ConfirmedOrder';
import RejectedOrder from './RejectedOrder';
import AllOrder from './AllOrder';
import ProposalManagement from './ProposalManagement';
import SubscriptionList from './SubscriptionList';
import IvsDownloadListing from './IvsDownloadListing';
import IvsOrderListing from './IvsOrderListing';
import { useDispatch } from 'react-redux';
import { setOrderDetails } from '../../redux/orderListing';
import IvsOrderListingNew from './IvsOrderListingNew';

function OrderTabBody({activeTab}) {
  const dispatch = useDispatch()
  
  //This useEffect is used for clearing the redux value of single order detail 
  useEffect(()=>{
    dispatch(setOrderDetails({}));
  },[])

  return (
    <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
       {activeTab == 1 && <AllOrder />}
       {activeTab == 2 && <PendingOrder />}
       {activeTab == 3 && <BookOrder/>}
       {activeTab == 4 && <RejectedOrder/>}
       {activeTab == 5 && <ProposalManagement/>}
       {activeTab == 6 && <IvsDownloadListing/>}
       {activeTab == 7 && <IvsOrderListing/>}
       {activeTab == 9 && <Typography variant=' h6' sx={{padding: 2}}>No data</Typography>}
    </CustomBox>
  )
}

export default OrderTabBody