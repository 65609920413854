import { Delete, Edit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import {
  getNewParameters,
  getObjectSubset,
  getParameters,
} from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import {
  getHorizontalImageData,
} from '../../Services/cmsService';
import { cmsItemsSelector } from '../../redux/cms';
import EditTechnicalDownloadTime from '../Modals/EditTechnicalDownloadTime';
import EditVerticalOrHorizontal from '../Modals/EditVerticalOrHorizontal';
import UploadZipModal from '../Modals/UploadZipModal';
import FolderZipIcon from '@mui/icons-material/FolderZip';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Image Type',
    id: 'image_type',
    // format: (columnData) => (
    //   <Link className='textDecoration-none link-text' to={`userDetail/${columnData}`}>
    //     <Typography
    //       variant='span'
    //       style={{ whiteSpace: 'nowrap' }}
    //       className={'link-text'}
    //       onClick={() => {
    //         customHistory.push(`order/orderDetails/${columnData}`);
    //       }}
    //     >
    //       {columnData}
    //     </Typography>
    //   </Link>
    // ),
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Dimension (Pixels)',
    id: 'dimension',
    // format: (columnData) => (
    //   <Link className='textDecoration-none link-text' to={`userDetail/${columnData}`}>
    //     <Typography
    //       variant='span'
    //       style={{ whiteSpace: 'nowrap' }}
    //       className={'link-text'}
    //       onClick={() => {
    //         customHistory.push(`order/orderDetails/${columnData}`);
    //       }}
    //     >
    //       {columnData}
    //     </Typography>
    //   </Link>
    // ),
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Size (Inches)',
    id: 'size',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Resolution',
    id: 'resolution',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Download Size',
    id: 'downloadsize',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          maxWidth: 200,
          width: 'max-content',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'File Format',
    id: 'fileformat',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordBreak: 'break-word',
        }}
      >
        {columnData || '-'}
      </span>
    ),
  },

];

function TechnicalHorizontalDimension() {
  const [tableListing, setTableListing] = useState([]);
  const [expLoading, setExpLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, imagePriceData, horizonatlData } =
    useSelector(cmsItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedImagePrice, setSelectedImagePrice] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [zipModal, setZipModal] = useState(false);

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      type: "Horizontal",
      ...allParams,
      ...params,

    };
    dispatch(getHorizontalImageData(payload));
    // dispatch(getImagePricing(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);
  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getListing();
  }, [allParams]);

  //making data format for table input
  useEffect(() => {
    if (horizonatlData?.items?.length >= 0) {
      setTableListing(horizonatlData?.items);
    }
  }, [horizonatlData?.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            className="noMinWidth buttonDense"
            toolTipTittle={'Edit'}
            onClick={() => {
              // handleCofirmModalOpen(true)
              // setDeleteUserData(data?.user_id)
              setSelectedImagePrice(data);
              handleEdit(true);
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            <Edit />
          </CustomButton>
          <CustomButton
            className="noMinWidth buttonDense"
            toolTipTittle={'Upload Zip'}
            onClick={() => {
              // handleCofirmModalOpen(true)
              // setDeleteUserData(data?.user_id)
              setSelectedImagePrice(data);
              handleZip(true);
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            <FolderZipIcon />
          </CustomButton>
        </div>
      </>
    );
  };

  // const actionHead = (data) => {
  //   return (<div style={{display: "flex"}}>
  //      hi
  //   </div>);
  // };

  const handleEdit = (value = false) => {
    setEditModal(value);
  };
  const handleZip = (value = false) => {
    setZipModal(value);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: (theme) => theme.spacing(3), py: (theme) => theme.spacing(2) }}>
        Horizontal Sample Images
      </Box>
      <MatTable
        loading={horizonatlData?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date', 'identity'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date', 'identity'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={horizonatlData?.items?.count || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });

          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        // toolbarEnable={true}
        // toolBarButton={()=>{
        //   return <CustomButton sx={(theme)=>({ fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
        //     [theme.breakpoints.down("md")]: {
        //       flex: 1,
        //     }
        //    })} onClick={()=>{
        //     getExportFile()
        //   }} loading={expLoading}>Export XLS</CustomButton>
        // }}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
        dropdownFilter={true}
      />
      <EditVerticalOrHorizontal
        open={editModal}
        handleOpen={handleEdit}
        data={selectedImagePrice}
        setPage={setPage}
        handleLast={getListing}
      />
      <UploadZipModal
        open={zipModal}
        handleOpen={handleZip}
        data={selectedImagePrice}
        setPage={setPage}
        handleLast={getListing}

       />
    </>
  );
}

export default TechnicalHorizontalDimension;
