import React, { useEffect, useState } from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import Divder from '../CommonComp/Divder';
import StarsCategories from './StarsCategories';
import StarsDuration from './StarsDuration';
import StarsStatewise from './StarsStatewise';
import StarsCountrywise from './StarsCountrywise';
import { createCommonSettingCategoryTableData, isJson } from '../../lib/helper';

function StarsSetting({data, parentKey}) {
  const [categoryTableData, setCategoryTableData] = useState([]);
  const [durationTableData, setDurationTableData] = useState([]);
  const [stateWiseData, setStateWiseData] = useState([]);
  const [countryWiseData, setCountryWiseData] = useState([]);

  useEffect(()=>{
    setCategoryTableData(createCommonSettingCategoryTableData(data[0]?.key_value, parentKey));
    setDurationTableData(createCommonSettingCategoryTableData(data[1]?.key_value, parentKey));
    setStateWiseData(createCommonSettingCategoryTableData(data[2]?.key_value, parentKey));
    setCountryWiseData(createCommonSettingCategoryTableData(data[3]?.key_value, parentKey));
  },[data])

  return (
    <>
      <CustomBox padding={0} sx={{minHeight: 200}}>
        <StarsCategories data={categoryTableData} initialData={isJson(data[0]?.key_value)} parentKey={parentKey}/>
      </CustomBox>
      <Divder spacing={1} />
      <CustomBox padding={0} sx={{minHeight: 200}}>
        <StarsDuration data={durationTableData} initialData={isJson(data[1]?.key_value)} parentKey={parentKey}/>
      </CustomBox>
      <Divder spacing={1} />
      <CustomBox padding={0} sx={{minHeight: 200}}>
        <StarsStatewise data={stateWiseData} initialData={isJson(data[2]?.key_value)} parentKey={parentKey}/>
      </CustomBox>
      <Divder spacing={1} />
      <CustomBox padding={0} sx={{minHeight: 200}}>
        <StarsCountrywise data={countryWiseData} initialData={isJson(data[3]?.key_value)} parentKey={parentKey}/>
      </CustomBox>
    </>
  );
}
export default StarsSetting;
