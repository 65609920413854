import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import UserFollowUps from './UserFollowUps';
import OrderFollowUps from './OrderFollowUps';
import MasterReportList from './MasterReportList';
import DailySalesEntry from './DailySalesEntry';
import OutstandingPayment from './OutstandingPayment';
import { useNavigate } from 'react-router-dom';
import NonRegisteredUsersList from './NonRegisteredUsersList';

function FollowUpsTabBody({ activeTab }) {
  const navigate = useNavigate()
  return (
    <CustomBox padding={0} sx={{ minHeight: 200 }}>
      {activeTab == 1 && <DailySalesEntry />}
      {/* {activeTab == 2 && <UserFollowUps />} */}

      {/* {activeTab == 2 && <OrderFollowUps />} */}
      {activeTab == 2 && <MasterReportList />}
      {activeTab == 3 && <OutstandingPayment />}
      {activeTab == 4 && <NonRegisteredUsersList />}
    </CustomBox>
  );
}

export default FollowUpsTabBody;
