import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { ContributorData } from '../../data/contributorsData';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import CreateUserModal from '../../Components/Modals/CreateUserModal';
import SelectedContributorTabBody from '../../Components/Contributor/SelectedContributorTabBody';

function ContributorOne({handleNavParams, addUserModal, handleAddUserModal}) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={0}>
        <CustomTabs tabList={ContributorData.tabs} tabClick={handleTabs} />
      </CustomBox>
      <Divder spacing={1} />
      <SelectedContributorTabBody activeTab={tabValue} />
      <Divder spacing={1.2} />
      <CreateUserModal open={addUserModal} handleOpen={handleAddUserModal}/>
    </Box>
  );
}

export default ContributorOne;

