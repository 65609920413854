import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchBy, SearchByUnRegistered } from '../../data/companyData';
import { DATA_LIMIT } from '../../data/constants';
import { UserIdentityData } from '../../data/userData';
import { formatDate, getArrayOfObjJoinToStringForKey, getNewParameters, getObjectSubset, getParameters, removeExtraSpace } from '../../lib/helper';
import { companyItemsSelector } from '../../redux/company';
import { companyListings, companyUnassignedListings, deleteCompany } from '../../Services/companyApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import AddCompanyMaster from '../Modals/AddCompanyMaster';
import ConfirmationModal from '../Modals/ConfirmationModal';
import EditCompanyDetailModal from '../Modals/EditCompanyDetailModal';
import UpdateShortCompanyNameModal from '../Modals/UpdateShortCompanyNameModal';
import MatTable from '../Tables/MatTable';
import { unRegisteredCompanyListing } from '../../Services/CRMFollowups';
import { followUpsItemSelector } from '../../redux/followUps';
import UpdateNonRegisteredCompanylModal from '../Modals/UpdateNonRegisteredComanyModal';

const column = [
    {
        label: 'Company Name',
        id: 'company_name',
        format: (columnData) => (
            <span
                style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
            >
                {columnData || 'N/A'}
            </span>
        ),
    },
    {
        label: 'Email',
        id: 'email',
        format: (columnData) => (
            <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                {columnData || 'N/A'}
            </span>
        ),
    },
    {
        label: 'Alt. Email',
        id: 'alternate_email',
        format: (columnData) => (
            <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                {columnData || 'N/A'}
            </span>
        ),
    },
    {
        label: 'Group Name',
        id: 'company_group_name',
        format: (columnData) => (
            <span
                style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
            >
                {columnData || '-'}
            </span>
        ),
    },
    {
        label: 'First Name',
        id: 'first_name',
        format: (columnData) => (
            <span
                style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
            >
                {columnData || '-'}
            </span>
        ),
    },
    {
        label: 'Last Name',
        id: 'last_name',
        format: (columnData) => (
            <span
                style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
            >
                {columnData || '-'}
            </span>
        ),
    },
    {
        label: 'Company Short Name',
        id: 'email',
        format: (columnData, rowIndex, formatCallback, option, errors, row) => (
            <CustomButton className='buttonDense' onClick={() => {
                option([columnData])
            }} sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}>Update</CustomButton>
        ),
    },
];

function NonRegisteredUsersList() {
    const [tableListing, setTableListing] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [allParams, setAllParams] = useState(getParameters());
    const { loading, unregisteredCompany } = useSelector(followUpsItemSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [selectedData, setSelectedData] = useState(null);
    const [clearSelectedCheckbox, setClearSelectedCheckbox] = useState(false);
    const [updateNonRegisteredCompanyModal, setUpdateNonRegisteredCompanyModal] = useState(false);
    const [editData, setEditData] = useState({});

    const handleAdd = (value = false) => {
        setAddCompanyModal(value)
    }
    const getListing = async (params = {}) => {
        const payload = {
            page: String(1),
            filled: "0",
            limit: limit,
            ...allParams,
            ...params,
        };
        dispatch(unRegisteredCompanyListing(payload));
        setPage(Number(payload.page));
    };

    useEffect(() => {
        getListing();
    }, []);

    useEffect(() => {
        let params = getParameters()
        setAllParams(params)
    }, [location?.search]);

    useEffect(() => {
        let data = getNewParameters(allParams)
        if (data?.search !== location.search) {
            navigate(data.newUrl, { replace: false })
            if (data?.parameters?.page) {
                setPage(Number(data?.parameters?.page))
            }
        }
        getListing();
    }, [allParams])

    //making data format for table input
    useEffect(() => {
        if (unregisteredCompany?.items?.rows?.length >= 0) {
            setTableListing(unregisteredCompany?.items?.rows);
        }
    }, [unregisteredCompany.items]);

    const handleClearSelectedCheckbox = (value = false) => {
        setClearSelectedCheckbox(value || false)
    }

    const handleModal = (value = false) => {
        setUpdateNonRegisteredCompanyModal(value);
        if (!value) {
            setTimeout(() => {
                setSelectedUserIds([]);
            }, 500);
        }
    };
    const handleBulkUpdate = async (data, resetState) => {

        handleModal(true);
        setSelectedUserIds(data)
    }

    return (
        <>
            <MatTable
                loading={unregisteredCompany?.loading}
                showCheckbox={false}
                columns={column}
                data={tableListing}
                onApplyFilter={(data = {}) => {
                    getListing({
                        page: String(1),
                        start_date: '',
                        end_date: '',
                        ...getObjectSubset(data, 'search_by'),
                    });
                    if (Object.keys(data).length > 0) {
                        setAllParams({
                            ...allParams,
                            page: String(1),
                            ...getObjectSubset(data, 'search_by'),
                        });
                    } else {
                        setAllParams({
                            page: String(1),
                        });
                    }
                }}
                page={page}
                perPage={limit}
                showPageLimit={true}
                handleChangePageLimit={(e) => {
                    setLimit(e.target.value);
                    setAllParams({
                        ...allParams,
                        page: String('1'),
                        limit: Number(e.target.value),
                    });
                }}
                total={unregisteredCompany?.items?.count || 0}
                setPage={setPage}
                handleSearch={(searchTerm) => {
                    getListing({
                        page: String(1),
                        search: searchTerm,
                    });
                    setAllParams({
                        ...allParams,
                        page: String(1),
                        search: searchTerm,
                    });
                }}
                handlePageChange={(newPage) => {
                    getListing({
                        page: String(newPage),
                    });
                    setAllParams({
                        ...allParams,
                        page: String(newPage),
                    });
                }}
                toolbarEnable={true}
                pagination={true}
                filtersUrlData={allParams || {}}
                filterEnable
                columnWiseData={[
                    {
                        id: 'email',
                        value: handleBulkUpdate,
                    }
                ]}

                checkboxEnable={true}
                customCheckboxDataKey={'email'}
                onTableToolbarRightClick={handleBulkUpdate}
                clearSelectedCheckbox={clearSelectedCheckbox}
                handleClearSelectedCheckbox={handleClearSelectedCheckbox}// requred to clear filter and refresh state after api call 
                onTableToolbarRightSection={<CustomButton sx={(theme) => ({
                    fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
                    [theme.breakpoints.down("md")]: {
                        flex: 1,
                    },
                })}
                >Bulk Update</CustomButton>}
                dropdownFilter={true}
                selectFilterArray={[
                    {
                        name: 'search_by',
                        label: 'Search By',
                        placeholder: 'Search By',
                        options: SearchByUnRegistered
                    },
                ]}
            />
            {updateNonRegisteredCompanyModal && <UpdateNonRegisteredCompanylModal
                open={updateNonRegisteredCompanyModal}
                handleOpen={handleModal}
                dataArray={selectedUserIds}
                getListing={getListing}
                handleClearSelectedCheckbox={handleClearSelectedCheckbox}
            />}
        </>
    );
}

export default NonRegisteredUsersList;
