import { Typography } from '@mui/material'
import React from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import CategoryList from './CategoryList';
import VideoCategoryList from './VideoCategoryList';
import StarCategoryList from './StarCategoryList';

function CategoryTabBody({activeTab}) {
  return (
    <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
       {activeTab == 1 && <CategoryList/>}
       {activeTab == 2 && <VideoCategoryList/>}
       {activeTab == 3 && <StarCategoryList/>}
    </CustomBox>
  )
}

export default CategoryTabBody