import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createSubString, downloadBlobFile, formatDate, getNewParameters, getParameters, validDate } from '../../lib/helper';
import { getDailySalesReport, getPrivilegeListing, getReportListing } from '../../Services/reportsApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import * as Yup from 'yup';
import { reportListingItemsSelector } from '../../redux/reports';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import { setResetDailyReportData } from '../../redux/reports';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
const limit = 10;

const column = [
  {
    label: 'Privilage ID',
    id: 'privilage_client_id',
    format: (columnData) => (
   
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
        >
          {columnData}
        </Typography>
    
    ),
  },

  {
    label: 'User ID',
    id: 'user_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'User Name',
    id: 'user_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Image Name',
    id: 'image_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Date',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd/mm/yyyy')}
      </span>
    ),
  },
  {
    label: 'Client',
    id: 'client',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || '-'}
      </span>
    ),
  },
];


function PrivilegeReport() {
  const [fileResp, setFileResp] = useState({
    status: false,
    data: null
  })
  const [loading, setLoading] = useState(false)
  const [tableListing, setTableListing] = useState([]);
  const { privillageReport,mainLoading } = useSelector(reportListingItemsSelector);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    if(privillageReport?.items?.elements?.length >= 0){
      setTableListing(privillageReport?.items?.elements)
    }
  }, [privillageReport?.items]);

  useEffect(()=>{
    dispatch(setResetDailyReportData())
  },[])

  const handleShowData=()=>{
    if(formik?.values.to && formik?.values.from){
      getOrderListing()
    }
    else{
      toast.error("To and From Date Required")
   
    } 
    getOrderListing()  
  }
  useEffect(()=>{
    getOrderListing();
  },[])

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams);
    if(formik?.values?.start_date && formik?.values?.end_date){
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
  
    getOrderListing();
  }
  },[allParams])

  const getOrderListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        // order_status: String(1),
        from:formik?.values?.from,
        to:formik?.values?.to,
        ...allParams,
        ...params
    }
    dispatch(getPrivilegeListing(payload))
    setPage(Number(payload.page))
  }


  const handleConfirm = async () => {
    setLoading(true)
    let resp = await getDailySalesReport(formik.values)
    if(resp.status){
      setFileResp(resp)
      formik?.resetForm()
      downloadBlobFile(resp.data, `DailySalesReport${formik?.values.from}-/-${values.to}`)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      from : "",
      to : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      from: Yup.string().required('From Date is required.'),
      to: Yup.string().required('To Date is required.'),
    }),
    onSubmit: checking,
  });

  async function checking (){
    setLoading(true)
    let resp = await getDailySalesReport(formik.values)
    if(resp.status){
      setFileResp(resp)
      // formik?.resetForm()
      downloadBlobFile(resp.data, `DailySalesReport${formik?.values.from}-/-${formik?.values.to}`)
    }
    setLoading(false)
  }

  return (
    <CustomBox padding={0} sx={{pt:8}}>
      <MatTable
        loading={privillageReport?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
      
        page={page}
        perPage={limit}
        total={privillageReport?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={false}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
    </CustomBox>
    
  );
}

export default PrivilegeReport;
