import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT, NEW_DATA_LIMIT } from '../../data/constants';
import { crypt, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { imageListingItemsSelector } from '../../redux/image';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { deleteTempImage, imageListings, imageListingsNew, insertIntoElasticSearchAllImage, insertIntoElasticSearchImage } from '../../Services/imageApis';
import { AllOrderListing } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import EditImageDetailModal from '../Modals/EditImageDetailModal';
import ImageKeywordModal from '../Modals/ImageKeywordModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SuspendImageModal from '../Modals/SuspendImageModal';
import MatTable from '../Tables/MatTable';
import { getListOfImageKeywords } from '../../Services/keywordsApis';
import ConfirmationModal from '../Modals/ConfirmationModal';
import EditImageDetailModalNew from '../Modals/EditImageDetailModalNew';
import { Delete } from '@material-ui/icons';

const limit = 100;

const column = [
  {
    label: 'Image',
    id: 'image_url',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
        onClick={() => {
          // customHistory.push(`order/orderDetails/${columnData}`);
        }}
      >
        <img style={{ maxWidth: 200, maxHeight: 200 }} src={`${columnData}`} />
      </span>
    ),
  },
  {
    label: 'Image ID',
    id: 'Name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap', }} onClick={() => {
        // customHistory.push(`order/orderDetails/${columnData}`);
      }} >
        {columnData}
      </span>
    ),

  },
  {
    label: 'Rank',
    id: 'rank',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Rank1',
    id: 'rank1',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Shoot ID',
    id: 'shootid',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Pricing(INR)',
    id: 'pricing',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },

  {
    label: 'Suspended Date',
    id: 'suspendate',
    format: (columnData) => (
      <>
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          {formatDate(columnData, 'dd/mm/yyyy')}
        </span>

      </>
    ),
  },
];

function AllImagesNew() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [imageEditModal, setImageEditModal] = useState(false);
  const [imageSuspendModal, setImageSuspendModal] = useState(false);
  const [imageKeyword, setImageKeyword] = useState(false);
  const [editImageData, setEditImageData] = useState({});
  const [confirmMove, setConfirmMove] = useState(false);
  const { loading, allImagesNew } = useSelector(imageListingItemsSelector);
  const dispatch = useDispatch();
  const [currentImageName, setCurrentImageName] = useState('');
  const [keywordForTheChoosenImage, setKeywordForTheChoosenImage] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const navigate = useNavigate()
  const location = useLocation();

  const handleModal = (value = false) => {

    setImageEditModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const handleSuspendModal = (value = false) => {
    setImageSuspendModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };
  const handleImageKeywordModal = (value = false) => {
    setImageKeyword(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const handleConfirmElastic = async (value) => {
    if (value && selectedData) {
      const resp = await insertIntoElasticSearchImage({ image_name: selectedData?.Name });
      if (resp) {
        getListing();
        setSelectedData(null);
      }
    } else {
      setSelectedData(null);
    }
  };

  const handleConfirmElasticAll = async (value) => {
    if (value) {
      const resp = await insertIntoElasticSearchAllImage({ image_flag: 'all' });
      if (resp) {
        getListing();

      }
    }
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      insertion_status: 'new',
      // order_status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(imageListingsNew(payload));
    setPage(Number(payload.page));
    setEditImageData({});
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  const handleAllKeywordsForimage = async (data) => {
    let response = await getListOfImageKeywords(data?.Name);
    if (response?.status == 200) {
      setKeywordForTheChoosenImage(response?.data);
      handleImageKeywordModal(true)
    }

  }

  const handleAllFunctions = (data) => {
    setEditImageData(data);
    handleAllKeywordsForimage(data);
  }

  const handleCofirmModalOpenElastic = (value) => {
    setConfirmMove(value);

  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };



  //making data format for table input
  useEffect(() => {
    if (allImagesNew?.items?.users?.length >= 0) {
      let data = allImagesNew?.items?.users?.map((item) => {
        return {
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.Name}.jpg`,
          Name: item?.Name,
          contributor: item,
          rank: item.rank, //=>edit
          shootid: item.shootid,
          pricing: item.pricing, //=>edit
          suspendate: item.suspendate,
          createddate: item?.createddate,
          contributor: item.contributor,
          available: item.available,
          type: item.type, //=>edit dropdown value [I, S]
          createddate: item.createdAt,
          imgstatus: item.imgstatus,
          suspend: item.imgstatus,
          rank1: item.rank1, //=>edit
          rank5: item.rank5,
          sno: item?.sno,
          keywords: item.Name, //=>edit
          sold_count: item.sold_count,
          modelreleaseid:item?.modelreleaseid,
          is_star: item?.is_star,
          watermark: item?.watermark
        };
      });
      setTableListing(data);
    }
  }, [allImagesNew.items]);

  const handleDelete = async (data) => {
    let payload = { image_name: data?.Name };
    let resp = await deleteTempImage(payload);
    if (resp) {
      getListing();
    }
    getListing();

    //deleteTempImage
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <Link className='textDecoration-none' to={`/image/imageKeywords/${data?.Name}`} >
            <CustomButton className="buttonDense" onClick={() => {

            }} sx={{ bgcolor: 'buttonInfo.main' }}>Keywords</CustomButton>
          </Link>

          <CustomButton className="buttonDense" onClick={() => {
            handleCofirmModalOpen(true)
            setSelectedData(data)


          }}>Live</CustomButton>
          <CustomButton
            className="buttonDense"
            sx={{
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
            onClick={() => {
              handleModal(true);
              setEditImageData(data);
            }}
          >
            Edit
          </CustomButton>
          <CustomButton variant="raised" sx={{ padding: '1px 1px', marginRight: 1, fontSize: '12px', bgcolor: '#fff0', minWidth: 20 }} onClick={() => handleDelete(data)}><Delete /></CustomButton>
        </div>
      </>
    );
  };


  return (
    <>
      <MatTable
        loading={allImagesNew?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={allImagesNew?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        toolBarButton={() => {
          return (
            <>
              {allImagesNew?.items?.totalCount > 0 &&

                <CustomButton
                  onClick={() => {
                    handleCofirmModalOpenElastic(true)
                  }}
                  color={'buttonPrimary'}
                  sx={(theme) => ({
                    padding: '10px 10px', marginRight: 1,
                    [theme.breakpoints.down("md")]: {
                      flex: 1,
                    }
                  })}
                >
                  Make it Live
                </CustomButton>
              }
            </>
          );
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
      // filterEnable
      // rangeDateFilter
      />
      <EditImageDetailModalNew
        open={imageEditModal}
        handleOpen={handleModal}
        data={editImageData}
        getListing={getListing}
      />
      <SuspendImageModal
        open={imageSuspendModal}
        handleOpen={handleSuspendModal}
        data={editImageData}
        getListing={getListing}
      />

      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleConfirmElastic}
          setOpen={handleCofirmModalOpen}
        />
      )}

      {confirmMove && (
        <ConfirmationModal
          open={confirmMove}
          handleConfirm={handleConfirmElasticAll}
          setOpen={handleCofirmModalOpenElastic}
        />
      )}
    </>
  );
}

export default AllImagesNew;
