export const keywordsData = {
    tabs: [
      {
        label: 'Visible Keywords',
        id: 1,
      },
      {
        label: 'Hidden Keywords',
        id: 2,
      },
      {
        label: 'Auto Suggestion',
        id: 3,
      }
    ],
  };
  
  