import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { companyGroupListingsNoRedux, editCompanyMaster, updateNonRegisteredCompany, updateShortCompany } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { CustomAsyncCreatableSelect } from '../CommonComp/CustomInput/CustomAsyncSelect';
import { commonItemsSelector } from '../../redux/common';
import CloseIcon from '@mui/icons-material/Close';
import CustomIconButtons from '../CommonComp/Buttons/IconButtons';
import { getState } from '../../Services/commonService';
import { companyShortNameListingsNoRedux } from '../../Services/CRMFollowups';
import { toast } from 'react-toastify';
import { loginItemsSelector } from '../../redux/login';
import { copyTextToClipBoard, getArrayOfObjJoinToStringForKey, removeExtraSpace } from '../../lib/helper';
import { CopyAll } from '@mui/icons-material';

const limit = DATA_LIMIT;

function UpdateNonRegisteredCompanylModal({ open, handleOpen, dataArray, getListing, handleClearSelectedCheckbox, selectedData }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    // const [associationData, setAssociationData] = useState([]);
    const { user } = useSelector(loginItemsSelector);
    const selectRef = useRef(null)
    const selectAssociationRef = useRef(null)
    const getShortNameListing = async (inputValue) => {
        if (inputValue) {
            let data = await companyShortNameListingsNoRedux({
                short_company_name: inputValue
            })
            if (data) {
                let updatedData = data?.map((item, index) => {
                    return {
                        value: item.short_company_name,
                        label: item.short_company_name,
                        data: item
                    }
                })
                return updatedData || []
            }
            return []
        }
    }

    const handleConfirm = async (values, { resetForm }) => {
        try {

            setLoading(true);
            const updatedValues = {
                ...values,
                assign: user?.userDetails?.first_name || 'admin',
                // associations: removeExtraSpace(getArrayOfObjJoinToStringForKey(values?.associations, 'value'), ' , ') || '',
                // ...(!values?.associations?.length && { is_association_empty: true })
            };
            if (dataArray.length) {
                let resp = await updateNonRegisteredCompany(updatedValues);
                if (resp) {
                    resetForm();
                    getListing();
                    handleOpen(false);
                    handleClearSelectedCheckbox(true)
                }
            } else {
                toast.error('Please select user first.')
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            company_group_name: selectedData?.company_group_name || '',
            short_company_name: '',
            email: dataArray,
            // email: JSON.stringify(dataArray),
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            short_company_name: Yup.string().required('Please enter short company name.'),
            company_group_name: Yup.string().required('Please enter compay group.'),
            // associations: Yup.array().min(1, 'Please enter atleast one associations'),
            // associations: Yup.array().min(1, 'Please enter atleast one associations.')
        }),
        onSubmit: handleConfirm,
    });


    useEffect(() => {
        if (selectedData?.user_associations?.length) {
            // let updatedData = selectedData?.user_associations?.map((item, index) => {
            //     return {
            //         value: item.association,
            //         label: item.association,
            //         data: item
            //     }
            // })
            // formik.setFieldValue('associations', updatedData)
            formik.setValues((values) => {
                return {
                    ...values,
                    // associations: updatedData,
                    company_group_name: selectedData?.company_group_name || "",
                    short_company_name: selectedData?.short_company_name
                }
            })
            setData({
                value: selectedData?.short_company_name, label: selectedData?.short_company_name, data: {
                    company_name: selectedData?.company_name,
                    company_group_name: selectedData?.company_group_name,
                    short_company_name: selectedData?.short_company_name
                }
            })
        } else if (selectedData) {
            formik.setValues((values) => {
                return {
                    ...values,
                    company_group_name: selectedData?.company_group_name || "",
                    short_company_name: selectedData?.short_company_name
                }
            })
            setData({
                value: selectedData?.short_company_name,
                label: selectedData?.short_company_name,
                data: {
                    company_name: selectedData?.company_name,
                    company_group_name: selectedData?.company_group_name,
                    short_company_name: selectedData?.short_company_name
                }
            })
        }
    }, [selectedData])

    const handleSelect = (e) => {
        setData({
            ...e,
            data: e.hasOwnProperty('data') ? data : {
                company_name: selectedData?.company_name,
                company_group_name: e?.label,
                short_company_name: e?.label,
            }
        });
        let assoc = e?.data?.user_associations?.map((item, index) => {
            return {
                value: item.association,
                label: item.association,
                data: item
            }
        })

        formik.setValues((values) => {
            return {
                ...values,
                // associations: assoc,
                short_company_name: e?.label,
                company_group_name: e?.data?.company_group_name || e?.label || "",
            }
        })
        blurAsync()
    }

    const blurAsync = () => {
        selectRef.current?.blur();
        selectAssociationRef.current?.blur();
    };

    return (
        <div>
            <Dialog
                maxWidth={'sm'}
                open={open}
                onClose={() => {
                    // handleOpen(false);
                }}
                fullWidth
            >
                <DialogTitle component='div' padding={3} sx={{ display: 'flex', alignItems: "center" }}>
                    <Typography variant="h3">Update Company</Typography>
                    {handleOpen && <CustomIconButtons onClick={() => { handleOpen(false); setData({}) }} sx={{ padding: '13px 15px', ml: 'auto' }}
                        color={'iconButtonPrimary'} disabled={loading}><CloseIcon /></CustomIconButtons>}
                </DialogTitle>
                <DialogContent>
                    <Box noValidate
                        component="form"
                        // sx={{
                        //   width: 400
                        // }}
                        onSubmit={formik.handleSubmit}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} display={'flex'}>
                                <CustomAsyncCreatableSelect
                                    promiseOptions={getShortNameListing}
                                    error={formik.touched.short_company_name && Boolean(formik.errors.short_company_name)}
                                    helperText={formik.touched.short_company_name && formik.errors.short_company_name}
                                    closeMenuOnSelect={false}
                                    controlStyle={{
                                        padding: 5,
                                        height: 57
                                    }}
                                    cacheOptions={false}
                                    value={data}
                                    handleChange={(e) => {
                                        handleSelect(e)
                                    }}
                                    ref={selectRef}
                                    width={'100%'}
                                    dropDownZIndex={100000}
                                    labelShrink={true}
                                    label="Short Company Name"
                                    isMulti={false}
                                />
                                <Box sx={{ pt: 1, pl: 1 }}>
                                    <CustomButton color={'buttonSecondary'} className="noMinWidth" onClick={() => { copyTextToClipBoard(data.label || "") }}><CopyAll /></CustomButton>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInput
                                    variant="outlined"
                                    fullWidth
                                    name="company_group_name"
                                    label="Company Group Name"
                                    placeholder="Company Group Name"
                                    value={formik.values.company_group_name}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: 'rounded',
                                        },
                                    }}
                                    error={formik.touched.company_group_name && Boolean(formik.errors.company_group_name)}
                                    helperText={formik.touched.company_group_name && formik.errors.company_group_name}
                                />
                            </Grid>
                            {/* <Grid item xs={12} display={'flex'}>
                                <CustomAsyncCreatableSelect
                                    promiseOptions={getShortNameListing}
                                    error={formik.touched.associations && Boolean(formik.errors.associations)}
                                    helperText={formik.touched.associations && formik.errors.associations}
                                    closeMenuOnSelect={false}
                                    controlStyle={{
                                        padding: 5,
                                        minHeight: 57
                                    }}
                                    cacheOptions={false}
                                    value={formik.values.associations}
                                    handleChange={(e) => {
                                        formik.setFieldValue('associations', e)
                                        blurAsync()
                                    }}
                                    ref={selectAssociationRef}
                                    width={'100%'}
                                    dropDownZIndex={100000}
                                    labelShrink={true}
                                    label="Associations"
                                />
                            </Grid> */}

                            <Grid item xs={12} textAlign={'end'}>
                                <CustomButton
                                    color={'buttonPrimary'}
                                    type="submit"
                                    loading={loading}
                                >
                                    Confirm
                                </CustomButton>
                                <CustomButton
                                    color={'buttonNormal'}
                                    type="button"
                                    disabled={loading}
                                    onClick={() => {
                                        handleOpen(false)
                                        setData({})
                                    }}
                                >
                                    Close
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default UpdateNonRegisteredCompanylModal;
