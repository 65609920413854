import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DATA_LIMIT } from '../../data/constants';
import MatTable from '../Tables/MatTable';
import { crypt, currencyConversion, formatDate } from '../../lib/helper';
import { Link, useParams } from 'react-router-dom';
import { userOutstandingDetailApi } from '../../Services/userApis';
import { useDispatch, useSelector } from 'react-redux';
import { userItemsSelector } from '../../redux/user';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => {
      return columnData ? <Link className='textDecoration-none link-text' to={`../../../invoice?orderId=${crypt('order_id', row?.order_id)}`} target={'_blank'}>{columnData}</Link> :
        <Typography variant='span' style={{ whiteSpace: 'nowrap' }}>{columnData}</Typography>
    },
  },
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", cursor: "pointer" }}>{columnData}</span>)
  },
  {
    label: 'Ordered On',
    id: 'ordered_on',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{formatDate(columnData, 'dd-mm-yyyy')}</span>)
  },
  {
    label: 'Payment On',
    id: 'payment_on',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{formatDate(columnData, 'dd-mm-yyyy')}</span>)
  },
  {
    label: 'Amount(INR)',
    id: 'final_price',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
      {columnData && currencyConversion(columnData) || 'N/A'}
    </span>)
  }
];

function UserOutStandingData({ }) {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const { singleUserOutstandingDetail } = useSelector(userItemsSelector);
  const dispatch = useDispatch();
  const { userId } = useParams();

  useEffect(() => {
    if (singleUserOutstandingDetail?.items?.rows?.length >= 0) {
      let data = singleUserOutstandingDetail?.items?.rows?.map((item) => {
        return {
          invoice_id: item?.invoice?.invoice_id,
          order_id: item.order_id,
          ordered_on: item?.ordered_on,
          payment_on: item?.payment_on,
          final_price: item?.invoice?.final_price
        }
      })
      setTableListing(data)
    }
  }, [singleUserOutstandingDetail?.items]);

  const getOutstandingListing = async (params = {}, setPage) => {
    const payload = {
      page: String(1),
      limit: limit,
      user_id: userId,
      order_status: String(1),
      payment_status: String(0),
      ivs_order: false,
      ...params,
    };
    dispatch(userOutstandingDetailApi(userId, payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getUserWishList();
  }, []);

  const getUserWishList = () => {
    let outstandingPayload = {
      page: String(1),
      limit: String(limit),
      user_id: userId,
      order_status: '1',
      payment_status: '0',
      ivs_order: false,
    };
    dispatch(userOutstandingDetailApi(userId, outstandingPayload));
  }

  return (
    <>



      <Box sx={{ display: "flex", flexDirection: 'column', width: '100%', px: (theme) => theme.spacing(3), py: (theme) => theme.spacing(2) }}>
        <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Total Amount Due(INR) :{singleUserOutstandingDetail?.items?.totalAmountDue ? currencyConversion(singleUserOutstandingDetail?.items?.totalAmountDue) : '0'}
        </Typography>
        <MatTable
          showCheckbox={false}
          columns={column}
          loading={singleUserOutstandingDetail?.loading}
          data={tableListing}
          page={page}
          perPage={limit}
          total={singleUserOutstandingDetail?.items?.count || 0}
          setPage={setPage}
          handlePageChange={(newPage) => {
            getOutstandingListing({
              page: String(newPage)
            }, setPage)
          }}
          pagination={true}
        />
      </Box>
    </>
  );
}

export default UserOutStandingData;
