import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Block, Delete, Edit } from '@material-ui/icons';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { contributorItemsSelector } from '../../redux/contributor';
import {
  BlockAndUnBlockContributor,
  contributorPaymentDivList,
  deleteContributor,
} from '../../Services/ContributorApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import AddContributor from '../Modals/AddContributor';
import ConfirmationModal from '../Modals/ConfirmationModal';
import BlockIcon from '@mui/icons-material/Block';
import EditContributor from '../Modals/EditContributor';
import MatTable from '../Tables/MatTable';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import EditPasswordAdminUserModal from '../Modals/EditPasswordAdminUserModal';
import LockResetIcon from '@mui/icons-material/LockReset';
import EditPasswordContributorModal from '../Modals/EditPasswordContributorModal';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import DoNotDisturbOffIcon from '@mui/icons-material/DoNotDisturbOff';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import LockIcon from '@mui/icons-material/Lock';


const limit = 10;

const column = [
  {
    label: 'Image ',
    id: 'image_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Payment Date',
    id: 'paid_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData,'dd-mm-yyyy') || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Payment Status',
    id: 'paid',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData===true?'Paid':'UnPaid' }
      </span>
    ),
  },
  {
    label: 'Image Size',
    id: 'image Size',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {row?.image_type}-{row?.quality}
      </span>
    ),
  },

  
  {
    label: 'Amount',
    id: 'amount',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {row?.amount-row?.Discount }
      </span>
    ),
  },

  {
    label: 'Price',
    id: 'discounted_price',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Discount',
    id: 'Discount',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData===0?0:columnData }
      </span>
    ),
  },
  {
    label: 'Royalty',
    id: 'royalty',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },

];

function ContributorPaymentList() {
  const [tableListing, setTableListing] = useState([]);
  const { paymentId } = useParams();
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { contributorPaymentList } = useSelector(contributorItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      contributor_payment_id:paymentId,
      ...allParams,
      ...params,
    };
    dispatch(contributorPaymentDivList(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params);
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  useEffect(() => {
    if (contributorPaymentList?.items?.rows?.length >= 0) {
      setTableListing(contributorPaymentList?.items?.rows);
    }
  }, [contributorPaymentList.items]);

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
        {/* <CustomButton className="noMinWidth buttonDense" toolTipTittle={'Update Password'} onClick={()=>{
            handleEditPasswordAdminModal(true)
            setSelectedData(data)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><LockResetIcon/>
          </CustomButton> */}
          
          {/* <CustomButton
            toolTipTittle={'Edit Visible Keyword'}
            onClick={() => {
              handleEdit(true, data);
            }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonPrimary.main',
              color: 'buttonPrimary.contrastText',
            }}
          >
            <Edit />
          </CustomButton> */}
          {/* <CustomButton
            toolTipTittle={'Delete Visible Keyword'}
            onClick={() => {
              // handleEdit(true, data)
              handleCofirmModalOpen(true);
              setDeleteData(data?.contributor_id);
            }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonDanger.main',
              color: 'buttonDanger.contrastText',
            }}
          >
            <Delete />
          </CustomButton> */}

         {data?.status==1?<>
          <CustomButton
            toolTipTittle={'Block'}
            onClick={() => {
              // handleEdit(true, data)
              handleCofirmModalOpen(true);
              setDeleteData(data);
              // setBlockUnBlock(data);
            }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonSuccess.main',
              color: 'buttonSuccess.contrastText',
            }}
          >
            {/* <Block /> */}
            {/* <DoNotDisturbOnIcon/> */}
            <LockPersonIcon/>
            
          </CustomButton>
          </>:<>
          <CustomButton
            toolTipTittle={'UnBlock'}
            onClick={() => {
              // handleEdit(true, data)
              handleCofirmModalOpen(true);
              setDeleteData(data);
              // setBlockUnBlock(data);
            }}
            className="buttonDense noMinWidth"
           
            sx={{
              bgcolor: 'buttonDanger.main',
              color: 'buttonDanger.contrastText',
            }}
          >
            {/* <DoNotDisturbOffIcon/> */}
            <LockIcon/>
          </CustomButton>
          </>}
        </div>
      </>
    );
  };
  //padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}
  return (
    <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}>
      <MatTable
        loading={contributorPaymentList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
      
        page={page}
        perPage={limit}
        total={contributorPaymentList?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
      
      

    </CustomBox>
  );
}

export default ContributorPaymentList;
