import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { addCompanyMaster, companyGroupListingsNoRedux, companyListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { addSubscriptionPlan, subscriptionListings } from '../../Services/SubscriptionApis';
import { formatDate, validDate } from '../../lib/helper';
import { addPayment, editPayment } from '../../Services/ContributorApis';

const limit = DATA_LIMIT;

function AddPayment({ open, handleOpen, setPage, handleLast, contributorId, selectedData, type }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    if (type == 'add') {
      let resp = await addPayment(values)
      if (resp) {
        resetForm({
          contributor_id: contributorId,
          description: "",
          amount: "",
          paid_on: ""
        })
        setPage(1)
        handleLast();
        handleOpen(false)
      }
    }
    else {
      let resp = await editPayment({ contributor_email: contributorId, contributor_payment_id: selectedData?.contributor_payment_id, ...values })
      if (resp) {
        resetForm({
          contributor_id: contributorId,
          description: "",
          amount: "",
          paid_on: ""
        })
        setPage(1)
        handleLast();
        handleOpen(false)
      }

    }

    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      contributor_id: contributorId,
      description: selectedData?.description || "",
      amount: selectedData?.amount || "",
      paid_on: selectedData?.paid_on || ""

    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      amount: Yup.string().required('Amount is required.').matches(/^[0-9]+$/, "Must be only digits"),
      description: Yup.string().required('description is required.'),
      paid_on: Yup.string().required('Paid Date is required.'),
      contributor_id: Yup.string().required("Contributor Id is Required.")

    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          {type === 'Add' ? Add : 'Edit'} Payment
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  name="paid_on"
                  type="date"
                  label="Paid Date"
                  placeholder="Paid Date"
                  value={formatDate(formik.values.paid_on, "yyyy-mm-dd")} //2017-06-13T13:00
                  onChange={(e) => {
                    if (validDate(new Date(), e.target.value, true)) {
                      formik.setValues((values) => ({
                        ...values,
                        'paid_on': e.target.value + ' 00:00:00',
                      }))
                    } else {
                      toast.error('Date should not be greater than Today date')
                    }
                    // }
                  }}
                  error={formik.touched.paid_on && Boolean(formik.errors.paid_on)}
                  helperText={formik.touched.paid_on && formik.errors.paid_on}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="description"
                  label="Description"
                  placeholder="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="amount"
                  label="Amount"
                  placeholder="Amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                />
              </Grid>


            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                {type === 'add' ? 'Add' : 'Edit'}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    paid_on: "",
                    amount: "",
                    description: "",

                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddPayment;
