import { Close, Delete } from '@material-ui/icons';
import { Box, Typography } from '@mui/material';
import React, { useRef, useState } from 'react'
import { humanize } from '../../lib/helper';
import { appSettingApi, fileUploadImage, updateSettingApi } from '../../Services/commonService';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';

function ImageUploadBackground({item, parentKey}) {
    const [imageData,setImageData] = useState(item?.key_value)
    //'https://d3nn873nee648n.cloudfront.net/backgrounds/backgroundImage.png'
    const [loading,setLoading] = useState(false)
    const [selectedImageData, setSelectedImageData] = useState(item?.key_value)
    const imageInputRef = useRef(null)
    const dispatch = useDispatch()
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImageData(URL.createObjectURL(event.target.files[0]));
            let fileName = event.target.files[0].name.split('.');
            fileName = fileName[fileName?.length-1]
            let newFile = new File([event.target.files[0]], `${item.key_name}.${fileName || 'jpeg'}`, { type: event.target.files[0].type });
            setSelectedImageData(newFile)
        }
    }

    const getListing = async() => {
        dispatch(appSettingApi());
    };
    
    const handleSubmit = async()=>{
        try{
            if(selectedImageData){
                setLoading(true)
                const formData = new FormData();
                formData.append("uploaded_file", selectedImageData);
                formData.append("folder_name", 'backgrounds');

                let data = await fileUploadImage(formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                if(data?.status == 200){
                    setSelectedImageData(null)
                    setImageData(`${ApiConfig?.S3_BASE_URL}backgrounds/${data.data}`)
                    let payload = {
                        parent_key: parentKey,
                        key_name: item.key_name,
                        key_value: `${ApiConfig?.S3_BASE_URL}backgrounds/${data.data}`
                    }      
                    let resp = await updateSettingApi(payload)
                    if(resp){
                    await getListing()
                    }
                    setLoading(false)
                }
            }
        }catch(e){console.log(e)}
    }

    return (<>
        <Typography variant='h5'  >{humanize(item?.key_name)}</Typography>
        <div className='borderBottom-1'></div>
        <Box sx={(theme) => ({
            display: "flex",
        })} className="my3">
            <div className='' style={{position:'relative'}}>
            <Typography
            variant="h5"
            sx={{ marginBottom: "2px", marginTop: "2px", marginLeft: '10px' }}
          >
            Recommended Size : <span style={{fontWeight:'700'}}>1920 X 1080</span>

          </Typography>
                <CustomButton variant="contained" component="label" sx={{padding: 0}}>
                    <img id={`${item?.key_name}`} src={imageData} style={{ maxWidth: 500 }} className="img-fluid rounded-2"/>
                    <input type="file" ref={imageInputRef} hidden onChange={onImageChange}/>
                </CustomButton>
                {(imageData && imageData.includes('blob:')) && <div style={{position: "absolute", top: 2, right: -35, zIndex:10, padding: 2, color: "red", cursor: 'pointer'}}>
                    <Delete  onClick={()=>{
                        imageInputRef.current.value = null; 
                        setImageData(item?.key_value)
                        setSelectedImageData(null)
                    }}/>
                </div>}
            </div>
        </Box>
        <Box textAlign={'right'}>
            <CustomButton type="button" onClick={()=>{handleSubmit()}} loading={loading} color={'buttonPrimary'} sx={{ padding: '8px 20px' }}>Save</CustomButton>
        </Box>
        {/* <AppSettingsFields  
            name={`${item?.key_name}`}
            label={`${humanize(item?.key_name)}`}
            placeholder={`Enter ${humanize(item?.key_name)} Email`}
            // value={formik.values[item.key_name]}
            // onChange={(e) => {
            //     formik.setFieldValue(item.key_name, e.target.value);
            // }}
            // error={
            //     formik.touched[item.key_name]&& Boolean(formik.errors[item.key_name])
            // }
            // helperText={
            //     formik.touched[item.key_name]&& formik.errors[item.key_name]
            // }
        /> */}
    </>
  )
}

export default ImageUploadBackground;