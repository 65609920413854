import { Typography } from '@mui/material';
import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AllImages from './AllImages';
import ImagesGroup from './ImagesGroup';
import SoldImages from './SoldImages';
import SuspendedImages from './SuspendedImages';
import SoldImagesPending from './SoldImagesPending';
import StarImages from './StarImages';
import ShootIdListing from './ShootIdListing';
import ExclusiveImages from './ExclusiveImages';

function ImageTabBody({ activeTab }) {
  return (
    <>
      {activeTab != 3 && (
        <CustomBox
          padding={0}
          sx={{
            minHeight: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: activeTab != 3 && 'center',
          }}
        >
          {activeTab == 1 && <AllImages />}
          {activeTab == 2 && <StarImages />}
          {activeTab == 4 && <SoldImages />}
          {/* {activeTab == 5 && <SoldImagesPending />} */}
          {activeTab == 6 && <ImagesGroup />}
          {activeTab == 7 && <SuspendedImages />}
          {activeTab == 8 && <ShootIdListing />}
        </CustomBox>
      )}
      {activeTab == 3 && <ExclusiveImages />}
    </>
  );
}

export default ImageTabBody;
