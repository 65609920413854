import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { LeftArrow } from '../../Asset/svg';
import { Typography, useTheme } from '@mui/material';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CreateProposalPage from '../../Components/ProposalManagement/CreateProposalPage';
import { decrypt } from '../../lib/helper';
import ApiConfig from '../../config/ApiConfig';
import ProposalCheckoutPage from '../../Components/ProposalManagement/ProposalCheckoutPage';

function ConfirmProposal() {
  const theme = useTheme();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const [proposalId, setProposalId] = useState(null)
  useEffect(()=>{
    let data = decrypt(ApiConfig.REACT_APP_SALT + 'proposal_id' ,searchParams.get('proposal_id'))
    if(!data){
      navigate(-1)
    }else{
      setProposalId(data)
    }
  },[searchParams])
  
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox>
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}> 
            <Box sx={{
                display: "flex",
                alignItems: "center",
                cursor: 'pointer'
            }} onClick={()=>{
                navigate(-1)
            }}>
                <LeftArrow fill={theme.palette.textButton.main}/>
                <Typography variant='h5' sx={{
                    marginLeft: theme.spacing(1),
                    color: theme.palette.textButton.main
                }}>Back</Typography>
            </Box>
            {/* <Typography variant='h5' sx={{marginLeft: (theme)=> theme.spacing(1)}}>User Details</Typography>
            <div style={{width: 100}}></div> */}
            {/* <CustomButton sx={{ padding: '12px 15px' }} color={'buttonPrimary'}>Print</CustomButton> */}
            {/* <Typography */}
        </Box>
      </CustomBox>
      <Divder spacing={1} />
      <ProposalCheckoutPage proposalId={proposalId}/>
      <Divder spacing={1.2} />
    </Box>
  );
}

export default ConfirmProposal;
