import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import MainLayout from '../Layouts/MainLayout';
import Dashboard from './Dashboard';

function DashboardContainer() {
  const [weeklyImageModal, setWeeklyImageModal] = useState(false)
  const navigate = useNavigate()
  const handleWeeklyImageModal = (value=false) =>{
    setWeeklyImageModal(value)
  }
  return (
    <MainLayout
      navTitle={'Dashboard'}
      navSubTitle={'Welcome to ImagesBazaar'}
      navBarRightComp={
        <>
          {/* <CustomButton sx={{ padding: '12px 15px'}} color={'buttonSuccess'} onClick={()=>handleWeeklyImageModal(true)}>
            Upload Weekly Image
          </CustomButton> */}
          <CustomButton sx={{ padding: '12px 15px', ml: (theme)=>(theme.spacing(2)) }} color={'buttonPrimary'}
            onClick={()=>{
              navigate(`../proposal/createProposal`)
            }}>
            Create proposal
          </CustomButton>
        </>
      }
    >
      <Dashboard weeklyImageModal={weeklyImageModal} handleWeeklyImageModal={handleWeeklyImageModal} />
    </MainLayout>
  );
}

export default DashboardContainer;
