import { Close } from '@material-ui/icons';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ApiConfig from '../../config/ApiConfig';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import Divder from '../CommonComp/Divder';

const OrderItem = ({ item, handleDelete, index, extraSection }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: (theme) => theme.backgroundColor.box.primary,
        p: 1,
        borderRadius: 5,
        mb: 1,
        position: "relative"
      }}
    >
      <CustomImage
        src={`${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.image_name}.jpg`}
        style={{
          maxWidth: 110,
          // marginTop: 5,
          maxHeight: 110,
          borderRadius: 10,
          width: 100,
          height: 80,
          objectFit: 'cover',
        }}
      />
      <div>
        <Typography variant="p" component={'p'} pl={1}>
          Id: {item?.image_name}
        </Typography>
        <Typography variant="p" component={'p'} pl={1}>
          Quality: {item?.quality || 'N/A'}
        </Typography>
        <Typography variant="p" component={'p'} pl={1}>
          Price: {item?.price || 'N/A'}
        </Typography>
        {item.content_type == "3" && item?.duration ? <Typography variant="p" component={'p'} pl={1}>
          Duration: {item?.duration || 'N/A'}
        </Typography> : null}
        {item?.country ? <Typography variant="p" component={'p'} pl={1}>
          Country: {item?.country || 'N/A'}
        </Typography> : null}
        {item?.image_states ? <Typography variant="p" component={'p'} pl={1}>
          States: {item?.image_states || 'N/A'}
        </Typography> : null}
        {extraSection && (
          <Box pl={1}>
            <Divder spacing={0.3} />
            {extraSection()}
          </Box>
        )}
      </div>
      <div onClick={() => handleDelete(index)} style={{
        position: 'absolute', top: 4, cursor: 'pointer',
        right: 4, height: 20, width: 20, background: "#fff", borderRadius: "6px", boxShadow: "0 0 9px -3px #000"
      }}>
        <Close style={{ fontSize: 20 }} />
      </div>
    </Box>
  );
};

function ImageOrderSummary({ imagesData, item, handleDelete, exclusiveCheck, index, extraSection }) {
  const [showAllDone, setShowAllDone] = useState(false)

  useEffect(() => {
    let updateCheck = imagesData?.length ? imagesData?.filter((item, index) => exclusiveCheck == 2 ? (item.price && item.quality && item.duration) : (item.price && item.quality)) : []
    if (!updateCheck.length) {
      setShowAllDone(true)
    } else {
      setShowAllDone(false)
    }
  }, [imagesData])
  return (
    <>
      {imagesData?.length ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: "515px", overflow: "hidden", overflowY: "auto" }}>
          {imagesData?.map((item, index) => {
            // if (((item.price && item.quality) && exclusiveCheck != 2) || (exclusiveCheck == 2 && (item.price && item.quality && item.duration))) {
            return (
              <OrderItem
                item={item}
                handleDelete={handleDelete}
                index={index}
                key={index}
                // {...(exclusiveCheck == 2 ? {
                {...(item.price && item.quality && item.duration ? {
                  extraSection: () => extraSection(item, index) || <></>
                } : {})}
              />
            );
            // }
          })}
          {showAllDone && <Typography variant="p"
            component={'p'}
            className="fontWeight500"
            textAlign={'center'}
            py={4}
          >Please Select Images</Typography>}

        </Box>
      ) : (
        <Typography
          variant="p"
          component={'p'}
          className="fontWeight500"
          textAlign={'center'}
          py={4}
        >
          No Summary Available
        </Typography>
      )}
    </>
  );
}

export default ImageOrderSummary;
