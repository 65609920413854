import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, MenuItem } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import { ImageSelectData } from '../../data/imageData';
import { addJobDescription, updateJobDescription } from '../../Services/commonService';

export default function JobDescriptionModal({
  open,
  handleOpen,
  data,
  getListing,
}) {
  const [loading, setLoading] = React.useState(false);

  const handleDataSubmit = async (values, { resetForm }) => {
    try {
			setLoading(true)
			if(data){
				let resp = await updateJobDescription({
					job_description_id: data.job_description_id,
					name: values.name,
					status: values.status,
				})
				if(resp){
					getListing()
					handleOpen(false)
				}
				setLoading(false)
			}else{
				let resp = await addJobDescription({
					name: values.name,
					status: values.status
				})
				if(resp){
					getListing()
					handleOpen(false)
				}
				setLoading(false)
			}
    } catch (e) {
      console.log(e, 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      status: data?.status || '1',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter job description.'),
      status: Yup.string().required('Please enter status.'),
    }),
    onSubmit: handleDataSubmit,
  });

  return (
    <>
      <Dialog
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
				fullWidth
      >
        <DialogTitle variant="h3">Job Description</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="name"
                  label="Job Description"
                  placeholder="Job Description"
                  value={formik.values?.name}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched?.name &&
                    Boolean(formik.errors?.name)
                  }
                  helperText={
                    formik.touched?.name && formik.errors?.name
                  }
									onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="status"
                  label="Status"
                  placeholder="Status"
									select
                  value={formik.values?.status}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched?.status &&
                    Boolean(formik.errors?.status)
                  }
                  helperText={
                    formik.touched?.status && formik.errors?.status
                  }
									onChange={formik.handleChange}
                >
									<MenuItem key={"0"} value={"0"}>Inactive</MenuItem>
									<MenuItem key={"1"} value={"1"}>Active</MenuItem>
								</CustomInput>
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
              <CustomButton
                onClick={() => {
                  handleOpen(false);
                  formik.resetForm()
                }}
                disabled={loading}
              >
                Close
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
