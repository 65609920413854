import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import PasswordUpdate from '../../Components/AdminAccount/PasswordUpdate';

function AdminPasswordUpdate({handleNavParams}) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      {/* <CustomBox padding={0}>
        <CustomTabs tabList={AdminUserData.tabs} tabClick={handleTabs} />
      </CustomBox> */}
      <Divder spacing={1} />
      {/* <AdminUserTabBody activeTab={tabValue} /> */}
      <PasswordUpdate   />
      <Divder spacing={1.2} />
      
    </Box>
  );
}

export default AdminPasswordUpdate;
