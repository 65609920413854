import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import PartPayInvoice from './PartPayInvoice';

function PartPayInvoiceContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: 'Part Pay Invoice',
    navSubTitle: 'Welcome to ImagesBazaar',
  });
  return (
    <MainLayout
      navTitle={navParams.navTitle}
      navSubTitle={navParams.navSubTitle}
    >
      <PartPayInvoice />
    </MainLayout>
  );
}

export default PartPayInvoiceContainer;
