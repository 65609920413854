import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import CustomIconButtons from '../CommonComp/Buttons/IconButtons';
import ApiConfig from '../../config/ApiConfig';
import { useNavigate } from 'react-router-dom';
import { checkForEnablePack, crypt } from '../../lib/helper';
import { useDispatch, useSelector } from 'react-redux';
import { appSettingApi } from '../../Services/commonService';
import { commonItemsSelector } from '../../redux/common';
import Divder from '../CommonComp/Divder';

function SelectIVSPlanModal({ open, handleOpen, heading, userId }) {
  const [loading, setLoading] = useState(false);
  const { appSetting } = useSelector(commonItemsSelector);
  const [selectedIvsPlan, setSelectedIvsPlan] = useState();
  const dispatch = useDispatch();
  const getListing = () => {
    dispatch(appSettingApi());
  };
  useEffect(() => {
    getListing();
  }, []);
  const navigate = useNavigate();
  return (
    <div>
      <Dialog
        maxWidth={'xl'}
        open={open}
        fullWidth
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle
          component="div"
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 24px 0px',
          }}
        >
          <Typography variant="h3">{heading}</Typography>
          {handleOpen && (
            <CustomIconButtons
              onClick={() => {
                handleOpen(false);
              }}
              sx={{ padding: '13px 15px', ml: 'auto' }}
              color={'iconButtonPrimary'}
              disabled={loading}
            >
              <CloseIcon />
            </CustomIconButtons>
          )}
        </DialogTitle>
        <DialogContent>
          <>
            <Box className="top-reverse-table">
              {checkForEnablePack('', appSetting?.items?.subscriptions)?.packAvailable && <Typography
                variant="h5"
                className="boldFont text-center"
                component="div"
                sx={{ marginBottom: '15px' }}
              >
                Subscribe & Save Upto 60%
              </Typography>}
              {checkForEnablePack('web', appSetting?.items?.subscriptions)?.status && (<>
                <Box
                  component="div"
                  className="main-single-image-price-table"
                  sx={{
                    marginTop: '25px',
                    padding: '20px 40px 20px 40px',
                    backgroundColor: 'pricingBoxColors.webPackBackgroundColor',
                    overflow: 'auto',
                  }}
                >
                  <Box
                    className="subscriber-package-details"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginbottom: '15px',
                    }}
                  >
                    <div className="subscriber-package-instructions">
                      <Typography
                        className="fontWeight600"
                        variant="h5"
                        component="div"
                      >
                        Web Size Pack
                      </Typography>
                      <Typography
                        className="fontWeight600"
                        variant="h6"
                        component="div"
                      >
                        1200 X 1800 at 72 dpi (JPEG)
                      </Typography>
                    </div>
                    <CustomButton
                      sx={{
                        bgcolor: 'buttonSecondary.main',
                        color: 'buttonSecondary.contrastText',
                      }}
                      onClick={() => {
                        navigate(
                          `/ivsCheckout?userId=${crypt(
                            ApiConfig.REACT_APP_SALT + 'user_id',
                            userId,
                          )}&planId=${crypt(
                            ApiConfig.REACT_APP_SALT + 'plan_id',
                            selectedIvsPlan?.plan_id,
                          )}`,
                        );
                      }}
                    >
                      Subscribe
                    </CustomButton>
                  </Box>
                  <Table aria-label="Large Pack">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Select
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Images/Videos
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Discount
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Price (INR)
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Per Image/Video (INR)
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Download Within
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appSetting?.items &&
                        appSetting?.items?.subscriptions?.webSizePack?.map(
                          (data, index) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  backgroundColor:
                                    selectedIvsPlan?.plan_id === data?.plan_id
                                      ? 'pricingBoxColors.webPackSelectedBackgroundColor'
                                      : 'inherit',
                                }}
                              >
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <div className="sub-package-main-checkbox">
                                    <input
                                      className="sip-table-checkbox"
                                      id={`SelectedPlan${index}`}
                                      type="radio"
                                      value={data?.plan_id}
                                      style={{ cursor: 'pointer' }}
                                      name="payment-method-name"
                                      checked={
                                        data?.plan_id ===
                                        selectedIvsPlan?.plan_id
                                      }
                                      onChange={() => {
                                        setSelectedIvsPlan(data);
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {data?.maximum_downloads}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {`${data?.discount_percentage}%`}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {data?.plan_price.toLocaleString()}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {data?.per_image.toLocaleString()}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {`${data?.plan_validity} days`}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          },
                        )}
                    </TableBody>
                  </Table>
                  <div className="subscribe-package-perios-time">
                    <Typography
                      component="div"
                      variant="h6"
                      className="text-center fontWeight600"
                      sx={{ mt: 3 }}
                    >
                      The downloaded images/videos can be used for Lifetime.
                    </Typography>
                  </div>
                </Box>
                <Divder />
                </>
              )} 
              {checkForEnablePack('small', appSetting?.items?.subscriptions)?.status && (<>
                <Box
                  component="div"
                  className="main-single-image-price-table"
                  sx={{
                    marginTop: '25px',
                    padding: '20px 40px 20px 40px',
                    backgroundColor: 'pricingBoxColors.smallPackBackgroundColor',
                    overflow: 'auto',
                  }}
                >
                  <Box
                    className="subscriber-package-details"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginbottom: '15px',
                    }}
                  >
                    <div className="subscriber-package-instructions">
                      <Typography
                        className="fontWeight600"
                        variant="h5"
                        component="div"
                      >
                        Small Size Pack
                      </Typography>
                      <Typography
                        className="fontWeight600"
                        variant="h6"
                        component="div"
                      >
                        1200 X 1800 at 72 dpi (JPEG)
                      </Typography>
                    </div>
                    <CustomButton
                      sx={{
                        bgcolor: 'buttonSecondary.main',
                        color: 'buttonSecondary.contrastText',
                      }}
                      onClick={() => {
                        navigate(
                          `/ivsCheckout?userId=${crypt(
                            ApiConfig.REACT_APP_SALT + 'user_id',
                            userId,
                          )}&planId=${crypt(
                            ApiConfig.REACT_APP_SALT + 'plan_id',
                            selectedIvsPlan?.plan_id,
                          )}`,
                        );
                      }}
                    >
                      Subscribe
                    </CustomButton>
                  </Box>
                  <Table aria-label="Large Pack">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Select
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Images/Videos
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Discount
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Price (INR)
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Per Image/Video (INR)
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Download Within
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appSetting?.items &&
                        appSetting?.items?.subscriptions?.smallSizePack?.map(
                          (data, index) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  backgroundColor:
                                    selectedIvsPlan?.plan_id === data?.plan_id
                                      ? 'pricingBoxColors.smallPackSelectedBackgroundColor'
                                      : 'inherit',
                                }}
                              >
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <div className="sub-package-main-checkbox">
                                    <input
                                      className="sip-table-checkbox"
                                      id={`SelectedPlan${index}`}
                                      type="radio"
                                      value={data?.plan_id}
                                      style={{ cursor: 'pointer' }}
                                      name="payment-method-name"
                                      checked={
                                        data?.plan_id ===
                                        selectedIvsPlan?.plan_id
                                      }
                                      onChange={() => {
                                        setSelectedIvsPlan(data);
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {data?.maximum_downloads}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {`${data?.discount_percentage}%`}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {data?.plan_price.toLocaleString()}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {data?.per_image.toLocaleString()}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {`${data?.plan_validity} days`}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          },
                        )}
                    </TableBody>
                  </Table>
                  <div className="subscribe-package-perios-time">
                    <Typography
                      component="div"
                      variant="h6"
                      className="text-center fontWeight600"
                      sx={{ mt: 3 }}
                    >
                      The downloaded images/videos can be used for Lifetime.
                    </Typography>
                  </div>
                </Box>
                <Divder />
                </>
              )}
              {checkForEnablePack('large', appSetting?.items?.subscriptions)?.status && (<>
                <Box
                  component="div"
                  className="main-single-image-price-table"
                  sx={{
                    marginTop: '25px',
                    padding: '20px 40px 20px 40px',
                    backgroundColor:
                      'pricingBoxColors.largePackBackgroundColor',
                    overflow: 'auto',
                  }}
                >
                  <Box
                    className="subscriber-package-details"
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginbottom: '15px',
                    }}
                  >
                    <div className="subscriber-package-instructions">
                      <Typography
                        className="fontWeight600"
                        variant="h5"
                        component="div"
                      >
                        Large Size Pack
                      </Typography>
                      <Typography
                        className="fontWeight600"
                        variant="h6"
                        component="div"
                      >
                        5400 X 8100 pixels at 300 dpi (18” X 27” TIFF)
                      </Typography>
                    </div>
                    <CustomButton
                      sx={{
                        bgcolor: 'buttonSecondary.main',
                        color: 'buttonSecondary.contrastText',
                      }}
                      onClick={() => {
                        navigate(
                          `/ivsCheckout?userId=${crypt(
                            ApiConfig.REACT_APP_SALT + 'user_id',
                            userId,
                          )}&planId=${crypt(
                            ApiConfig.REACT_APP_SALT + 'plan_id',
                            selectedIvsPlan?.plan_id,
                          )}`,
                        );
                      }}
                    >
                      Subscribe
                    </CustomButton>
                  </Box>
                  <Table aria-label="Large Pack">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Select
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Images/Videos
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Discount
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Price (INR)
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Per Image/Video (INR)
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: 'inherit',
                            '&:hover': { backgroundColor: 'inherit' },
                          }}
                        >
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            Download Within
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appSetting?.items &&
                        appSetting?.items?.subscriptions?.largeSizePack?.map(
                          (data, index) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  backgroundColor:
                                    selectedIvsPlan?.plan_id === data?.plan_id
                                      ? 'pricingBoxColors.largePackSelectedBackgroundColor'
                                      : 'inherit',
                                }}
                              >
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <div className="sub-package-main-checkbox">
                                    <input
                                      className="sip-table-checkbox"
                                      id={`SelectedPlan${index}`}
                                      type="radio"
                                      value={data?.plan_id}
                                      style={{ cursor: 'pointer' }}
                                      name="payment-method-name"
                                      checked={
                                        data?.plan_id ===
                                        selectedIvsPlan?.plan_id
                                      }
                                      onChange={() => {
                                        setSelectedIvsPlan(data);
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {data?.maximum_downloads}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {`${data?.discount_percentage}%`}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {data?.plan_price.toLocaleString()}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {data?.per_image.toLocaleString()}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    backgroundColor: 'inherit',
                                    '&:hover': { backgroundColor: 'inherit' },
                                  }}
                                >
                                  <Typography
                                    component="div"
                                    variant="sectiondata"
                                    className="alignText-center boldFont"
                                  >
                                    {`${data?.plan_validity} days`}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          },
                        )}
                    </TableBody>
                  </Table>
                  <div className="subscribe-package-perios-time">
                    <Typography
                      component="div"
                      variant="h6"
                      className="text-center fontWeight600"
                      sx={{ mt: 3 }}
                    >
                      The downloaded images/videos can be used for Lifetime.
                    </Typography>
                  </div>
                </Box>
                <Divder />
                </>
              )}
              {!checkForEnablePack('', appSetting?.items?.subscriptions)?.packAvailable && <div
                  style={{
                    fontWeight: '600',
                    marginTop: '10px',
                    marginBottom: '10px',
                    textAlign: 'center',
                  }}
                >
                  No Data Found
                </div>}
            </Box>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SelectIVSPlanModal;
