import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { AppBar, Box, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { AppLogoNew } from '../../Asset/svg';
import { IconArr, sideMenu } from '../../data/SideMenu';
import { Link, useParams, useLocation } from 'react-router-dom';
import { Lock } from '@material-ui/icons';
import { useAuth } from '../../Context/AuthProvider';
import { getItemFromStorage } from '../../storage/Storage';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginItemsSelector } from '../../redux/login';
import { menuListingItemsSelector } from '../../redux/userMenus';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { commonItemsSelector, setSideMenuOpen } from '../../redux/common';
const drawerWidth = 210;
const logoWidth = '100%';
const logoHeight = '30px';
const drawerHeight = '95%';

const drawerWidthSmall = 90;
const logoWidthSmall = '50%';
const logoHeightSmall = '15px';
const drawerHeightSmall = '95%';


const container = window !== undefined ? () => window().document.body : undefined;

function ListItemLink({ disabled = false, Icon, primary, to,textShow }) {
  const location = useLocation();

  const renderLink = React.useMemo(() => {
    return React.forwardRef((itemProps, ref) => (
      <Link to={to} ref={ref} {...itemProps} style={{ background: '#0000' }} />
    ));
  }, [to]);

  const makeMenuIcon = (iconIndex) => {
    let IconComponent = IconArr[iconIndex] || null
    return IconComponent && <IconComponent fill={to === location?.pathname ? '#fff' : '#000'} style={{ color: to === location?.pathname ? '#fff' : '#000' }} />
  }


  return (
    <ListItem button disabled={disabled ?? false} component={renderLink}>
       <Tooltip  title={textShow?'':primary}> 
      <ListItemButton selected={to === location.pathname} className={'sidebar-item'}>
        {Icon >= 0 ? (
          <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
            {makeMenuIcon(Icon)}
          </ListItemIcon>
        ) : null}
       {textShow &&<ListItemText primary={primary} classes={{ primary: `fontWeight600 ${to === location.pathname ? "colorWhite" : "colorBlack"}` }} />}
      </ListItemButton>
      </Tooltip>
    </ListItem>
  );
}

ListItemLink.defaultProps = {
  disabled: false,
};

export default function Sidebar({ mobileOpen, handleDrawerToggle,showSmallNav, setShowSmallNav }) {
  const theme = useTheme();
  const [menuList, setMenuList] = useState([]);
  const { userDetails } = useSelector(loginItemsSelector);
  const {sideMenuOpen}=useSelector(commonItemsSelector)
  const dispatch = useDispatch();
  const { allMenus } = useSelector(menuListingItemsSelector);
  const [list, setlist] = useState([])
  const { logout } = useAuth();

  const handleLogout = () => logout(null);

  useEffect(() => {
    handleAddedMenu()
  }, [])

  
  const handleSideMenu=(value)=>{
    dispatch(setSideMenuOpen(value))
  }

  const handleAddedMenu = async () => {
    let data = getItemFromStorage('user');
    if (data) {
      let data1 = JSON.parse(data);
      let resp = data1?.admin_menu;
      if (resp) {
        let array = [...sideMenu];
        array = array?.filter((a) => {
          return resp[a?.value] === '1'
        })
        setMenuList(array);

      }
    }
  }


  const handleSome = () => {
    if (allMenus?.items) {
      setlist()
      setlist(allMenus);
    }
  }

  return (
    <>
    
       {sideMenuOpen?.isopen ?
       <>
      <Drawer
        sx={{
          display: { xs: 'none', md: 'block' },
          width: drawerWidth,
          height: drawerHeight,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            height: drawerHeight,
            boxSizing: 'border-box',
            margin: 2,
            backgroundColor: theme.backgroundColor.box.main,
            border: 'none',
            borderRadius: theme.shape.borderRadius(9),
          },
        }}
        variant="permanent"
        anchor="left"
        open
      >
        <List>
          <ListItem key={'AppLogo'}>
            <Box
              sx={{
                width: logoWidth,
                height: logoHeight,
                padding: `0 ${theme.spacing(1)}`,
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(1.5),
              }}
              onClick={() => {setShowSmallNav(false)
                handleSideMenu(false);
              }}
            >
              <AppLogoNew style={{
                maxWidth: 180,
                maxHeight: 37,
                width: '100%',
                cursor: 'pointer',
              }} />
            </Box>
          </ListItem>
          {allMenus?.map((item, index) => {
            return (
              <ListItemLink
                Icon={item?.icon}
                primary={item?.label}
                to={item?.pathName}
                key={item?.label}
                textShow={sideMenuOpen?.isopen}
              />
            );
            
          })}
           
          <ListItem key={'Logout'}>
            <ListItemButton onClick={() => {
              handleLogout()
            }} className={'sidebar-item'}>
              <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
                <Lock style={{ color: "#000" }} />
              </ListItemIcon>
              <ListItemText primary={'Sign Out'} classes={{ primary: `fontWeight600 colorBlack` }} />
            </ListItemButton>
          </ListItem>
     
          <Box sx={{ marginTop: '50px', padding: '0 30px 30px 30px' }}>
            <Typography variant="12A3" component="div">
              ©Imagesbazaar.com. A division of Mash Audio Visuals Pvt. Ltd. All rights reserved.
            </Typography>
          </Box>
        </List>
      </Drawer>
      <Drawer
        sx={{
          display: { xs: 'block', md: 'none' },
          width: drawerWidth,
          height: drawerHeight,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            height: "100%",
            boxSizing: 'border-box',
            // margin: 2,
            backgroundColor: theme.backgroundColor.box.main,
            border: 'none',
            // borderRadius: theme.shape.borderRadius(9),
          },
        }}
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <List>
          <ListItem key={'AppLogo'}>
            <Box
              sx={{
                width: logoWidth,
                height: logoHeight,
                padding: `0 ${theme.spacing(1)}`,
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(1.5),
              }}
              onClick={() => {setShowSmallNav(false)
                handleSideMenu(false);
              }}
            >
              <AppLogoNew style={{
                maxWidth: 90,
                maxHeight: 12,
                width: '50%',
                cursor: 'pointer'
              }} />
            </Box>
          </ListItem>
          {allMenus?.map((item, index) => {
            return (
              <ListItemLink
                Icon={item?.icon}
                primary={item?.label}
                to={item?.pathName}
                key={item?.label}
                textShow={sideMenuOpen?.isopen}
              />
            );
            {
              /* <ListItem key={text}>
            <ListItemButton>
              <ListItemIcon sx={{marginRight: "10px", minWidth: '24px'}}>
                <MailIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem> */
            }
          })}
         
          <ListItem key={'Logout'} >
          <Tooltip  title="Logout"> 
            <ListItemButton onClick={() => {
              handleLogout()
            }} className={'sidebar-item'}>
              <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
                <Lock style={{ color: "#000" }} />
              </ListItemIcon>
            </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>
      </Drawer>
      </>
      :
      <>
      <Drawer
        sx={{
          display: { xs: 'none', md: 'block' },
          width: drawerWidthSmall,
          height: drawerHeightSmall,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidthSmall,
            height: drawerHeightSmall,
            boxSizing: 'border-box',
            margin: 2,
            backgroundColor: theme.backgroundColor.box.main,
            border: 'none',
            borderRadius: theme.shape.borderRadius(9),
          },
        }}
        variant="permanent"
        anchor="left"
        open
      >
        <List>
          <ListItem key={'AppLogo'}>
            <Box
              sx={{
                width: logoWidth,
                height: logoHeight,
                padding: `0 ${theme.spacing(1)}`,
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(1.5),
              }}
              onClick={() => {setShowSmallNav(true)
                handleSideMenu(true);
              }}
            >
              <AppLogoNew style={{
                maxWidth: 180,
                maxHeight: 37,
                width: '100%',
                cursor: 'pointer',
              }} />
            </Box>
          </ListItem>
          {allMenus?.map((item, index) => {
            return (
              <ListItemLink
                Icon={item?.icon}
                primary={item?.label}
                to={item?.pathName}
                key={item?.label}
                textShow={sideMenuOpen?.isopen}
              />
            );
            
          })}
          <ListItem key={'Logout'}>
          <Tooltip  title="Logout"> 
            <ListItemButton onClick={() => {
              handleLogout()
            }} className={'sidebar-item'}>
              <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
                <Lock style={{ color: "#000" }} />
              </ListItemIcon>
            </ListItemButton>
            </Tooltip>
          </ListItem>
          
        </List>
      </Drawer>
      <Drawer
        sx={{
          display: { xs: 'block', md: 'none' },
          width: drawerWidthSmall,
          height: drawerHeightSmall,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidthSmall,
            height: "100%",
            boxSizing: 'border-box',
            backgroundColor: theme.backgroundColor.box.main,
            border: 'none',
          },
        }}
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >

        <List>
          <ListItem key={'AppLogo'}>
            <Box
              sx={{
                width: logoWidth,
                height: logoHeight,
                padding: `0 ${theme.spacing(1)}`,
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(1.5),
              }}
              onClick={() => setShowSmallNav(true)}
            >
              <AppLogoNew style={{
                maxWidth: 180,
                maxHeight: 37,
                width: '100%',
                cursor: 'pointer',
              }} />
            </Box>
          </ListItem>
          {allMenus?.map((item, index) => {
            return (
              <ListItemLink
                Icon={item?.icon}
                primary={item?.label}
                to={item?.pathName}
                key={item?.label}
                ListItem={sideMenuOpen?.isopen}
              />
            );
          })}
          <ListItem key={'Logout'}>
          <Tooltip  title="Logout"> 
            <ListItemButton onClick={() => {
              handleLogout()
            }} className={'sidebar-item'}>
              <ListItemIcon sx={{ marginRight: '10px', minWidth: '24px' }}>
                <Lock style={{ color: "#000" }} />
              </ListItemIcon>
            </ListItemButton>
            </Tooltip>
          </ListItem>
         
        </List>
      </Drawer>
      </>
      
      }
      
       {sideMenuOpen?.isopen ?
        <button className="sidebar_toggle" id="toggleBtn"  onClick={() => {setShowSmallNav(false); handleSideMenu(false)}}> <KeyboardDoubleArrowLeftIcon /> </button>
        :
        ''
      }
    </>
  );
}
