import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import FollowUps from './FollowUps'
import FollowUpsLast from './FollowUpsLast'

function FollowUpsLastContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "Follow Ups",
    navSubTitle: "Welcome to ImagesBazaar"
})
const [addUserModal, setAddUserModal] = useState(false)
const handleAddUserModal = (value=false) =>{
  setAddUserModal(value)
}

const handleNavParams = (obj)=>{
  setNavParams({
      ...navParams,
      ...obj
  })
}
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
      <FollowUpsLast handleNavParams={handleNavParams} />
    </MainLayout>
  )
}

export default FollowUpsLastContainer