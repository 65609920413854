import { IconButton, InputAdornment, Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { loginItemsSelector } from '../../redux/login';
import { adminUsersListing, getAdminById, updateAdminUser, updateLoggedInAdminPassword } from '../../Services/adminUsersApis';
import { adminUsersItemsSelector } from '../../redux/adminUsers';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { DepartmentData } from '../../data/contributorsData';
import { LoginEye, LoginEyeOff, LoginLock } from '../../Asset/svg';
const limit = 10;

function PasswordUpdate() {

  const { user } = useSelector(loginItemsSelector);
  const [selectedData, setSelectedData] = useState();
  const [loading, setLoading] = useState(false);
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [isoldPassVisible, setIsOldPassVisible] = useState(false);
  const [isConfirmPassVisible, setConfirmPassVisible] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
    }
  }, [user]);

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await updateAdminUser(values);
    if (resp) {
      resetForm();
      getListing()
    }
    setLoading(false)
  };

  const handleChangePwd = async (values, { resetForm }) => {
    try {
      setLoading(true)
      let resp = await updateLoggedInAdminPassword(values);
      if (resp) {
        resetForm();
        navigate('/dashboard');
      }
      setLoading(false)

    } catch (e) {
      console.log(e, 'error');
    }
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  const handleClickShowPassword = () => {
    setIsPassVisible(!isPassVisible);
  };

  const handleMouseDownOldPassword = (e) => {
    e.preventDefault();
  };
  const handleClickShowOldPassword = () => {
    setIsOldPassVisible(!isoldPassVisible);
  };

  const handleMouseDownConfirmPassword = (e) => {
    e.preventDefault();
  };
  const handleClickShowConfirmPassword = () => {
    setConfirmPassVisible(!isConfirmPassVisible);
  };

  const formik = useFormik({
    initialValues: {

      old_password: '',
      new_password: "",
      confirm_password: ""
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required('Please Enter your password'),
      new_password: Yup.string().required('Please Enter your password'),
      confirm_password: Yup.string().required('Please Enter your password')
        .oneOf([Yup.ref('new_password')], 'Passwords must match'),
    }),
    onSubmit: handleChangePwd,
  });

  return (
    <>
      <CustomBox>
        <Box
          noValidate
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // m: 'auto',
            width: 'fit-content',
            justifyContent: 'flex-start',
          }}
          onSubmit={formik.handleSubmit}
        >

          <div className="login-main-form" style={{ width: '630px' }}>

            <div className="login-form-content login-space-divider">
              <CustomInput
                label="Old Password"
                className="main-login-form"
                fullWidth
                id="old_password"
                name="old_password"
                type={isPassVisible ? 'text' : 'password'}
                placeholder="Old Password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LoginLock className="loginform-field-icons" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {isPassVisible ? (
                          <LoginEyeOff className="loginform-field-icons login-eye-icon" />
                        ) : (
                          <LoginEye className="loginform-field-icons login-eye-icon" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formik.values.password}
                onChange={(e) => {
                  formik.setFieldValue('old_password', e.target.value);
                }}
                error={
                  formik.touched.old_password && Boolean(formik.errors.old_password)
                }
                helperText={
                  formik.touched.old_password && formik.errors.old_password
                }
              />
            </div>
            <div className="login-form-content login-space-divider">
              <CustomInput
                label="New Password"
                className="main-login-form"
                fullWidth
                id="new_password"
                name="new_password"
                type={isoldPassVisible ? 'text' : 'password'}
                placeholder="New Password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LoginLock className="loginform-field-icons" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownOldPassword}
                      >
                        {isoldPassVisible ? (
                          <LoginEyeOff className="loginform-field-icons login-eye-icon" />
                        ) : (
                          <LoginEye className="loginform-field-icons login-eye-icon" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formik.values.new_password}
                onChange={(e) => {
                  formik.setFieldValue('new_password', e.target.value);
                }}
                error={
                  formik.touched.new_password && Boolean(formik.errors.new_password)
                }
                helperText={
                  formik.touched.new_password && formik.errors.new_password
                }
              />
            </div>
            <div className="login-form-content login-space-divider">
              <CustomInput
                label=" Confirm Password"
                className="main-login-form"
                fullWidth
                id="confirm_password"
                name="confirm_password"
                type={isConfirmPassVisible ? 'text' : 'password'}
                placeholder=" Confirm Password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LoginLock className="loginform-field-icons" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                      >
                        {isConfirmPassVisible ? (
                          <LoginEyeOff className="loginform-field-icons login-eye-icon" />
                        ) : (
                          <LoginEye className="loginform-field-icons login-eye-icon" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formik.values.confirm_password}
                onChange={(e) => {
                  formik.setFieldValue('confirm_password', e.target.value);
                }}
                error={
                  formik.touched.confirm_password && Boolean(formik.errors.confirm_password)
                }
                helperText={
                  formik.touched.confirm_password && formik.errors.confirm_password
                }
              />
            </div>
          </div>


          <div
            style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
          >
            <CustomButton
              color={'buttonPrimary'}
              type="submit"
              loading={loading}
            >
              Submit
            </CustomButton>
            <CustomButton
              onClick={() => {
                formik.resetForm({
                  email: '',
                  password: '',
                  first_name: '',
                  last_name: '',
                });
              }
              }
              disabled={loading}
            >
              Cancel
            </CustomButton>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}></Grid>

            <Grid item xs={12} md={6} lg={4}></Grid>

            <Grid item xs={12} md={6} lg={4}></Grid>
          </Grid>
        </Box>
      </CustomBox>

    </>
  );
}

export default PasswordUpdate;
