import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
// import CategoryList from '../../Components/Category/CategoryList';
import CategoryTabBody from '../../Components/Category/CategoryTabBody';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import { CategoryData } from '../../data/categoryData';

function Category({handleNavParams}) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={0}>
        <CustomTabs tabList={CategoryData.tabs} tabClick={handleTabs} />
      </CustomBox>
      <Divder spacing={1} />
      <CategoryTabBody activeTab={tabValue} />
      <Divder spacing={1.2} />
    </Box>
  );
}

export default Category;
