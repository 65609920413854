import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import UserTabBody from '../../Components/User/UserTabBody';
import { AdminUserData, UserData } from '../../data/userData';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import CreateUserModal from '../../Components/Modals/CreateUserModal';
import AdminUserTabBody from '../../Components/AdminUsers/AdminUserTabBody';

function AdminUsers({handleNavParams}) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={0}>
        <CustomTabs tabList={AdminUserData.tabs} tabClick={handleTabs} />
      </CustomBox>
      <Divder spacing={1} />
      <AdminUserTabBody activeTab={tabValue} />
      <Divder spacing={1.2} />
      
    </Box>
  );
}

export default AdminUsers;
