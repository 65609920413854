import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import { updateAdminUser } from '../../Services/adminUsersApis';
import { DepartmentData } from '../../data/contributorsData';
import { RequirementFollowups, RequirementFollowupsText, RequirementType } from '../../data/followUpsdata';
import { formatDate } from '../../lib/helper';
import { updateSaleFollowup } from '../../Services/CRMFollowups';

function EditFollowupHistoryEntryModal({ open, handleOpen, getListing = () => { }, selectedData }) {
  const [loading, setLoading] = useState(false);
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await updateSaleFollowup({
      ...values
    })
    if (resp) {
      resetForm();
      getListing()
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      daily_sale_id: selectedData?.daily_sale_id || "",
      follow_up_id: `${selectedData?.follow_up_id}` || "",
      description: selectedData?.description || '',
      order_id: selectedData?.order_id || '',
      requirement: selectedData?.requirement || '',
      contact_number: String(selectedData?.contact_number) || '',
      status: selectedData?.status || '0',
      next_followup_date: selectedData?.next_followup_date || ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      daily_sale_id: Yup.string().required('Daily sale id is required.'),
      follow_up_id: Yup.string().required('Follow up id is required.'),
      description: Yup.string().required('Desciption is required.'),
      order_id: Yup.string(),
      requirement: Yup.string().required('Requirement is required.'),
      contact_number: Yup.string()
        .required("Contact number is required")
        .matches(/^\+?[0-9]+$/, "Must be only digits")
        .min(10, 'Contact number is required')
        .max(18, 'Contact number is required'),
      status: Yup.string().required('Status is required.'),
      next_followup_date: Yup.string().required('Next followup date is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Edit Entry
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  name="next_followup_date"
                  type="date"
                  label="Next Follow Ups Date"
                  placeholder="Next Follow Ups Date"
                  value={formatDate(formik.values.next_followup_date, 'yyyy-mm-dd')} //2017-06-13T13:00
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  inputProps={{
                    min: formatDate(new Date(), 'yyyy-mm-dd')
                  }}
                  error={
                    formik.touched.next_followup_date &&
                    Boolean(formik.errors.next_followup_date)
                  }
                  helperText={
                    formik.touched.next_followup_date &&
                    formik.errors.next_followup_date
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="description"
                  label="Description"
                  placeholder="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="contact_number"
                  id="mobile"
                  label="Contact Number"
                  placeholder="Contact Number"
                  value={formik.values.contact_number}
                  error={formik.touched.contact_number && Boolean(formik.errors.contact_number)}
                  helperText={formik.touched.contact_number && formik.errors.contact_number}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    formik.setFieldValue("contact_number", e.target.value);
                  }}
                />

              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="status"
                  label="Status"
                  placeholder="Status"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                >
                  <option key={'Select Status'} value={''}>Please Select</option>
                  {RequirementFollowupsText?.map((item, index) => {
                    return <option key={item?.value} value={item?.value}>{item?.label}</option>
                  })}
                </CustomInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="requirement"
                  label="Requirement"
                  placeholder="Requirement"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.requirement}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.requirement && Boolean(formik.errors.requirement)}
                  helperText={formik.touched.requirement && formik.errors.requirement}
                >
                  <option key={'Select Status'} value={''}>Please Select</option>
                  {RequirementType?.map((item, index) => {
                    return <option key={item?.subLableText} value={item?.subLableText}>{item?.subLableText}</option>
                  })}
                </CustomInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="order_id"
                  label="Order ID"
                  placeholder="Order ID"
                  value={formik.values.order_id}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.order_id && Boolean(formik.errors.order_id)}
                  helperText={formik.touched.order_id && formik.errors.order_id}
                />
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Submit</CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    password: '',
                    first_name: '',
                    last_name: '',
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditFollowupHistoryEntryModal;
