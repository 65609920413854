export const modelData = {
    tabs: [
      {
        label: 'Model List',
        id: 1,
      },
      
      {
        label: 'All',
        id: 2,
      },
      {
        label: 'Unassigned List',
        id: 3,
      },
      {
        label: 'Assigned List',
        id: 4,
      },
      {
        label: 'New Models',
        id: 5,
      },

    ],
  };


  export const SearchByModel= [
    {
      label: 'Select',
      value: ''
    },
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Model ID',
      value: 'model_id',
    },
    {
      label: 'Mobile Number',
      value: 'mobile',
    },
    {
      label: 'Release ID ',
      value: 'releaseid',
    }, 
  ];

  export const SearchByAssignModel= [
    {
      label: 'Select',
      value: ''
    },
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Model ID',
      value: 'model_id',
    },
    {
      label: 'Release ID ',
      value: 'releaseid',
    },
  ];
  export const SearchByNewModel= [
    {
      label: 'Select',
      value: ''
    },
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Mobile',
      value: 'mobile',
    },
  ];
  export const GenderOptions= [
    {
      label: 'Select',
      value: ''
    },
    {
      label: 'Male',
      value: 'male',
    },
    {
      label: 'Female',
      value: 'female',
    }
  ];

  export const PaymentOprions= [
    {
      label: 'Select',
      value: ''
    },
    {
      label: 'Paid',
      value: '1',
    },
    {
      label: 'Unpaid',
      value: '0',
    }
  ];

  export const SearchByAllAndUnassignedModel= [
    {
      label: 'Select',
      value: ''
    },
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Shoot ID',
      value: 'shoot_id',
    },
    {
      label: 'Rank',
      value: 'rank',
    }
  ];
  