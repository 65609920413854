import React, { useCallback, useMemo, useState } from 'react'
import { Typography } from '@mui/material';
import {useDropzone} from 'react-dropzone';
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  
};



const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


function MainDropzone({heading,img}) {
   const [paths, setPaths] = useState(img);
   const onDrop = useCallback(acceptedFile => {
    setPaths( URL.createObjectURL(acceptedFile[0]));
  }, [setPaths]);

 
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused
  } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.png']
    }
    ,onDrop
  });
    const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);
  return (
    <>
    <div className="container" >
    <div {...getRootProps({style})}>
      <input  type='file' {...getInputProps()} />
      {paths?<img style={{height:'100px'}} src={paths} />:<>
      {isDragAccept && (<p>All files will be accepted</p>)}
        {isDragReject && (<p>Some files will be rejected</p>)}
        {!isDragActive && (<p>Drop Image here ...</p>)}
        </>}
    </div>
   
  </div>
  </>
  )
}
export default MainDropzone;