import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import FollowUpsByOrderId from './FollowUpsByOrderId';

function PendingOrderFollowUpsContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: 'Order Follow Ups By Order ID',
    navSubTitle: 'Welcome to ImagesBazaar',
  });

  return (
    <MainLayout
      navTitle={navParams.navTitle}
      navSubTitle={navParams.navSubTitle}
    >
      <FollowUpsByOrderId />
    </MainLayout>
  );
}

export default PendingOrderFollowUpsContainer;
