import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT } from '../../data/constants';
import { crypt, currencyConversion, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { imageListingItemsSelector } from '../../redux/image';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { imageListings } from '../../Services/imageApis';
import { AllOrderListing } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import EditImageDetailModal from '../Modals/EditImageDetailModal';
import ImageKeywordModal from '../Modals/ImageKeywordModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SuspendImageModal from '../Modals/SuspendImageModal';
import MatTable from '../Tables/MatTable';
import { getListOfImageKeywords } from '../../Services/keywordsApis';
import { videosListings } from '../../Services/videosApis';
import { videosItemsSelector } from '../../redux/videos';
import SuspendVideoModal from '../Modals/SuspendVideoModal';
import EditVideoDetailModal from '../Modals/EditVideoDetailModal';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Video',
    id: 'image_url',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
        onClick={() => {

        }}
      >
        <img style={{ maxWidth: 200, maxHeight: 200 }} src={`${columnData}`} />
      </span>
    ),
  },
  {
    label: 'Video ID',
    id: 'Name',
    format: (columnData) => (
      <span style={{display: 'inline-block', whiteSpace: 'nowrap',}} onClick={() => {
         
        }} >
        {columnData}
      </span>
    ),
  },
  {
    label: 'Rank',
    id: 'rank',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Shoot ID',
    id: 'shootid',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Price(INR)',
    id: 'pricing',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? currencyConversion(columnData) : '0'}
      </span>
    ),
  },
  
  {
    label: 'Suspended Date',
    id: 'suspendate',
    format: (columnData) => (
      <>
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          {formatDate(columnData, 'dd/mm/yyyy')}
        </span>
       
      </>
    ),
    nextLine:true,
  },
];

function AllVideos() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [imageEditModal, setImageEditModal] = useState(false);
  const [imageSuspendModal, setImageSuspendModal] = useState(false);
  const [imageKeyword,setImageKeyword]=useState(false);
  const [editImageData, setEditImageData] = useState({});
  const { loading, allVideos } = useSelector(videosItemsSelector);
  const dispatch = useDispatch();
  const [currentImageName,setCurrentImageName]=useState('');
  const [keywordForTheChoosenImage,setKeywordForTheChoosenImage]=useState([]);
  const navigate = useNavigate()
  const location = useLocation();

  const handleModal = (value = false) => {

    setImageEditModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const handleSuspendModal = (value = false) => {
    setImageSuspendModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };
  const handleImageKeywordModal = (value = false) => {
    setImageKeyword(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      insertion_status:'old',
      // order_status: String(1),
      ...allParams,
      ...params,

    };
    dispatch(videosListings(payload));
    setPage(Number(payload.page));
    setEditImageData({});
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  const handleAllKeywordsForimage=async(data)=>{
   
      let response=await getListOfImageKeywords(data?.Name);
      if( response?.status==200){
        setKeywordForTheChoosenImage(response?.data);
        handleImageKeywordModal(true)
      }
   
  }

  const handleAllFunctions=(data)=>{
    setEditImageData(data);
    handleAllKeywordsForimage(data);
  }

  useEffect(() => {
    if (allVideos?.items?.users?.length >= 0) {
      let data = allVideos?.items?.users?.map((item) => {
        return {
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.video_name}.jpg`,
          Name: item?.video_name,
          contributor: item,
          rank: item.rank, //=>edit
          shootid: item.shootid,
          pricing: item.pricing, //=>edit
          suspendate: item.suspendate,
          contributor: item.contributor,
          available: item.available,
          type: item.countirbute_type, //=>edit dropdown value [I, S]
          createddate: item.createddate,
          imgstatus: item.imgstatus,
          suspend: item.imgstatus,
          rank1: item.rank1, //=>edit
          keywords: item.Name, //=>edit
          is_star: item?.is_star
        };
      });
      setTableListing(data);
    }
  }, [allVideos.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}> 
         <Link className='textDecoration-none'  to={`/videos/videoAllKeywords/${data?.Name}`} >
          <CustomButton className="buttonDense" onClick={() => {
            }} sx={{ bgcolor: 'buttonInfo.main' }}>Keywords</CustomButton>
          </Link> 
          <CustomButton className="buttonDense" onClick={() => {
              handleSuspendModal(true);
              setEditImageData(data);
            }}>Suspend</CustomButton>
          <CustomButton
            className="buttonDense"
            sx={{
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
            onClick={() => {
              handleModal(true);
              setEditImageData(data);
            }}
          >
            Edit
          </CustomButton>
        </div>
      </>
    );
  };


  return (
    <>
      <MatTable
        loading={allVideos?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={allVideos?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
      />
      <EditVideoDetailModal
        open={imageEditModal}
        handleOpen={handleModal}
        data={editImageData}
        getListing={getListing}
      />
      <SuspendVideoModal
        open={imageSuspendModal}
        handleOpen={handleSuspendModal}
        data={editImageData}
        getListing={getListing}
      />
    </>
  );
}

export default AllVideos;
