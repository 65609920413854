import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { Typography, useTheme } from '@mui/material';
import { LeftArrow } from '../../Asset/svg';
import { useNavigate, useParams } from 'react-router-dom';
import { clearImagesListings } from '../../redux/image';
import { useDispatch } from 'react-redux';
import FollowUpListByOrderId from '../../Components/FollowUps/FollowUpListByOrderId';

function FollowUpsByOrderId({ handleNavParams }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate(-1);
              dispatch(clearImagesListings());
            }}
          >
            <LeftArrow fill={theme.palette.textButton.main} />
            <Typography
              variant="h5"
              sx={{
                marginLeft: theme.spacing(1),
                color: theme.palette.textButton.main,
              }}
            >
              Back
            </Typography>
          </Box>
          <Typography
            variant="h5"
            sx={{ marginLeft: (theme) => theme.spacing(1) }}
          >
            Pending Order Follow ups - History
          </Typography>
          <div style={{ paddingRight: 50 }}></div>
          {/* <Typography */}
        </Box>
      </CustomBox>
      <Divder spacing={1} />
      <FollowUpListByOrderId orderId={orderId} />
      <Divder spacing={1.2} />
    </Box>
  );
}

export default FollowUpsByOrderId;
