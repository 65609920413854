import { Close } from '@material-ui/icons';
import { Box, Grid, Typography } from '@mui/material';
import { bgcolor, maxWidth } from '@mui/system';
import React, { useEffect, useState } from 'react';
import ApiConfig from '../../config/ApiConfig';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import Divder from '../CommonComp/Divder';
import PreviewImageComp from './PreviewImageComp';
import PreviewStarImageComp from './PreviewStarImageComponent';
import ProposalFields from './ProposalFields';
import { useFormik } from 'formik';
import { getCountry, getState } from '../../Services/commonService';
import { useDispatch, useSelector } from 'react-redux';
import { commonItemsSelector, setCountryData } from '../../redux/common';
import { loginItemsSelector } from '../../redux/login';
import { toast } from 'react-toastify';
import { crypt, encryptData, findObjectInArray, getDataFromAppSetting } from '../../lib/helper';
function StarImageSelectionComp({ data, handleData, updateData, proposal_of, handleDelete, formik, getImageListing, onConfirm,
    error, helperText, exclusiveCheck, extraSection }) {
    const { appSetting } = useSelector(commonItemsSelector);
    const [showAllDone, setShowAllDone] = useState(false)
    useEffect(() => {
        let updateCheck = data?.length ? data?.filter((item, index) => exclusiveCheck == 2 ? (!item.price && !item.quality && !item.duration) : (!item.price && !item.quality)) : []
        if (!updateCheck.length) {
            setShowAllDone(true)
        } else {
            setShowAllDone(false)
        }
    }, [data])
    const dispatch = useDispatch();
    const { countryData, stateData } = useSelector(commonItemsSelector);
    const { user } = useSelector(loginItemsSelector);
    // const { appSettingData } = useSelector(appSettingDataSelector);

    const [finalPriceForFlexiblePlan, setFinalPriceForFlexiblePlan] = useState(0);
    const [appSettingDurationData, setAppSettingDurationData] = useState([]);
    const [appSettingPriceData, setAppSettingPriceData] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [countryShortName, setCountryShortName] = useState('in');
    const [stateList, setStateList] = useState([]);
    const [itemData, setItemData] = useState({});
    const [selectedState, setSelectedState] = useState({});
    const [selectedCountry, setSelectedCountry] = useState({});
    const [duration, setDuration] = useState("2");
    // const formik = useFormik({
    //     initialValues: {
    //         country: "",
    //         duration: "",
    //         // size: "",
    //         state: "",
    //         industry: "",
    //     },
    //     // validationSchema: Yup.object(validationSchema),
    //     enableReinitialize: true,
    //     onSubmit: onConfirm,
    // });

    useEffect(() => {
        if (appSetting && appSetting?.items) {
            const durationPriceDataFromAppSetting = getDataFromAppSetting(
                appSetting?.items?.features?.stars,
                "key_name",
                "duration"
            );
            const durationData = JSON.parse(
                durationPriceDataFromAppSetting[0]?.key_value || "{}"
            );
            const outputArray = [];
            for (const label in durationData) {
                const value = durationData[label];
                outputArray.push({ label, value });
            }
            setAppSettingDurationData(outputArray);
        }
    }, [appSetting]);

    const getCountryDetails = async () => {
        dispatch(getCountry());
    };
    useEffect(() => {
        getCountryDetails();
        dispatch(getState(1));
    }, []);

    const handleChangeCountry = async (e) => {
        formik.setFieldValue("country", e.target.value);
        formik.setFieldValue("multiState", "");
        // setSelectedState({});
        setSelectedCountry(e.target.value)
        let country_id = 0;
        countryData?.items?.filter((val) => {
            if (val.country_name === e.target.value) {
                country_id = val.country_id;
                setCountryShortName(val?.country_short_name?.toLowerCase())
                // formik.setFieldValue("mobile", "");
            }
        });
        // dispatch(getState(country_id));
    };

    useEffect(() => {
        if (countryData?.items?.length > 0) {
            // let indiaData = countryData?.items?.filter(
            //   (data) => data?.country_name === "India"
            // );
            let modifyData = [...countryData?.items];
            const indiaIndex = modifyData.findIndex(
                (obj) => obj.country_name === "India"
            );
            if (indiaIndex !== -1) {
                formik.setFieldValue("country", modifyData[indiaIndex]?.country_name);
                dispatch(getState(modifyData[indiaIndex]?.country_id));
                const movedArray = modifyData.splice(indiaIndex, 1);
                modifyData.unshift(...movedArray);
                modifyData.splice(1, 0, {
                    country_id: 999,
                    country_name: "Worldwide",
                    country_short_name: "",
                    status: "1",
                    states_available: "0",
                },
                    {
                        country_id: 9999,
                        country_name: "PAN India",
                        country_short_name: "",
                        status: "1",
                        states_available: "0",
                    },
                );
                let data = modifyData?.map((item) => {
                    return {
                        label: item?.country_name,
                        value: item?.country_id,
                    };
                });
                setCountryList(data);
                setCountryShortName(modifyData[0]?.country_short_name?.toLowerCase())
                setCountryData(modifyData);
                // dispatch(getState(data[0]?.country_id));
                setSelectedCountry("India")
            }
        }
    }, [countryData]);
    useEffect(() => {
        if (stateData && Array.isArray(stateData?.items)) {
            let data = stateData?.items?.map((item) => {
                return {
                    label: item?.state_name,
                    value: item?.state_id,
                };
            });
            setStateList(data);
        }
    }, [stateData]);
    const handleModifyPricing = () => {
        if (formik.values.duration) {
            const categoryPriceDataFromAppSetting = getDataFromAppSetting(
                appSetting?.items?.features?.stars,
                "key_name",
                "category"
            );
            const differentCategoryPrices =
                categoryPriceDataFromAppSetting?.length > 0 &&
                JSON.parse(categoryPriceDataFromAppSetting[0]?.key_value || "");
            const selectedCategoryPrice =
                differentCategoryPrices[itemData?.category];

            const durationPriceDataFromAppSetting = getDataFromAppSetting(
                appSetting?.items?.features?.stars,
                "key_name",
                "duration"
            );
            const differentDurationPrices =
                durationPriceDataFromAppSetting?.length > 0 &&
                JSON.parse(durationPriceDataFromAppSetting[0]?.key_value || "");
            const selectedDurationPrice = formik.values.duration;

            const stateWisePriceDataFromAppSetting = getDataFromAppSetting(
                appSetting?.items?.features?.stars,
                "key_name",
                "statewise"
            );
            const differentStateWisePrices =
                stateWisePriceDataFromAppSetting?.length > 0 &&
                JSON.parse(stateWisePriceDataFromAppSetting[0]?.key_value || "");

            const countryPriceDataFromAppSetting = getDataFromAppSetting(
                appSetting?.items?.features?.stars,
                "key_name",
                "countrywise"
            );
            const differentCountryPrices =
                countryPriceDataFromAppSetting?.length > 0 &&
                JSON.parse(countryPriceDataFromAppSetting[0]?.key_value || "");

            let finalPriceForOrder = 0;
            if (formik.values.country !== "Worldwide" || formik.values.country !== "PAN India") {
                let tempValue =
                    Number(selectedCategoryPrice) *
                    Number(differentCountryPrices?.single);

                finalPriceForOrder = finalPriceForOrder + tempValue;
            }
            if (formik.values.country === "Worldwide" || formik.values.country === "PAN India") {
                let tempValue =
                    Number(selectedCategoryPrice) *
                    Number(differentCountryPrices?.worldwide);

                finalPriceForOrder = finalPriceForOrder + tempValue;
            }
            if (selectedDurationPrice) {
                let tempValue =
                    Number(selectedCategoryPrice) * Number(selectedDurationPrice);

                finalPriceForOrder = finalPriceForOrder + tempValue;
            }
            if (formik.values.multiState && formik.values.multiState?.length > 0 && differentStateWisePrices) {
                let tempValue = 0;
                if (formik.values.multiState?.length === 1) {
                    tempValue =
                        Number(selectedCategoryPrice) *
                        Number(differentStateWisePrices["1"]);
                }
                if (formik.values.multiState?.length === 2) {
                    tempValue =
                        Number(selectedCategoryPrice) *
                        Number(differentStateWisePrices["2"]);
                }
                if (formik.values.multiState?.length === 3) {
                    tempValue =
                        Number(selectedCategoryPrice) *
                        Number(differentStateWisePrices["3"]);
                }
                if (formik.values.multiState?.length === 4) {
                    tempValue =
                        Number(selectedCategoryPrice) *
                        Number(differentStateWisePrices["4"]);
                }
                if (formik.values.multiState?.length >= 5) {
                    tempValue =
                        Number(selectedCategoryPrice) *
                        Number(differentStateWisePrices["5"]);
                }
                // if (right?.length >= 6) {
                //   tempValue =
                //     Number(selectedCategoryPrice) *
                //     Number(differentStateWisePrices["6"]);
                // }
                finalPriceForOrder = finalPriceForOrder + tempValue;
            }
            setFinalPriceForFlexiblePlan(Number(finalPriceForOrder));
        } else {
            setFinalPriceForFlexiblePlan(0);
        }
    };
    // const handleModifyPricing = () => {
    //     if (formik.values.duration) {
    //         const categoryPriceDataFromAppSetting = getDataFromAppSetting(
    //             appSetting?.items?.features?.stars,
    //             "key_name",
    //             "category"
    //         );
    //         const differentCategoryPrices =
    //             categoryPriceDataFromAppSetting?.length > 0 &&
    //             JSON.parse(categoryPriceDataFromAppSetting[0]?.key_value || "");
    //         const selectedCategoryPrice =
    //             differentCategoryPrices[itemData?.category];
    //         setAppSettingPriceData(selectedCategoryPrice);
    //         const durationPriceDataFromAppSetting = getDataFromAppSetting(
    //             appSetting?.items?.features?.stars,
    //             "key_name",
    //             "duration"
    //         );
    //         const differentDurationPrices =
    //             durationPriceDataFromAppSetting?.length > 0 &&
    //             JSON.parse(durationPriceDataFromAppSetting[0]?.key_value || "");
    //         const selectedDurationPrice = formik.values.duration;

    //         const stateWisePriceDataFromAppSetting = getDataFromAppSetting(
    //             appSetting?.items?.features?.stars,
    //             "key_name",
    //             "statewise"
    //         );
    //         const differentStateWisePrices =
    //             stateWisePriceDataFromAppSetting?.length > 0 &&
    //             JSON.parse(stateWisePriceDataFromAppSetting[0]?.key_value || "");
    //         const countryPriceDataFromAppSetting = getDataFromAppSetting(
    //             appSetting?.items?.features?.stars,
    //             "key_name",
    //             "countrywise"
    //         );
    //         const differentCountryPrices =
    //             countryPriceDataFromAppSetting?.length > 0 &&
    //             JSON.parse(countryPriceDataFromAppSetting[0]?.key_value || "");
    //         let finalPriceForOrder = 0;
    //         if (formik.values.country !== "Worldwide" || formik.values.country !== "PAN India") {
    //             let tempValue =
    //                 Number(selectedCategoryPrice) *
    //                 Number(differentCountryPrices?.single);

    //             finalPriceForOrder = finalPriceForOrder + tempValue;
    //         }
    //         if (formik.values.country === "Worldwide" || formik.values.country === "PAN India") {
    //             let tempValue =
    //                 Number(selectedCategoryPrice) *
    //                 Number(differentCountryPrices?.worldwide);

    //             finalPriceForOrder = finalPriceForOrder + tempValue;
    //         }
    //         if (selectedDurationPrice) {
    //             let tempValue =
    //                 Number(selectedCategoryPrice) * Number(selectedDurationPrice);

    //             finalPriceForOrder = finalPriceForOrder + tempValue;
    //         }
    //         if (formik.values.multiState && formik.values.multiState?.length > 0 && differentStateWisePrices) {
    //             let tempValue = 0;
    //             if (formik.values.multiState?.length === 1) {
    //                 tempValue =
    //                     Number(selectedCategoryPrice) *
    //                     Number(differentStateWisePrices["1"]);
    //             }
    //             if (formik.values.multiState?.length === 2) {
    //                 tempValue =
    //                     Number(selectedCategoryPrice) *
    //                     Number(differentStateWisePrices["2"]);
    //             }
    //             if (formik.values.multiState?.length === 3) {
    //                 tempValue =
    //                     Number(selectedCategoryPrice) *
    //                     Number(differentStateWisePrices["3"]);
    //             }
    //             if (formik.values.multiState?.length === 4) {
    //                 tempValue =
    //                     Number(selectedCategoryPrice) *
    //                     Number(differentStateWisePrices["4"]);
    //             }
    //             if (formik.values.multiState?.length >= 5) {
    //                 tempValue =
    //                     Number(selectedCategoryPrice) *
    //                     Number(differentStateWisePrices["5"]);
    //             }
    //             finalPriceForOrder = finalPriceForOrder + tempValue;
    //         }
    //         setFinalPriceForFlexiblePlan(Number(finalPriceForOrder));
    //     } else {
    //         setFinalPriceForFlexiblePlan(0);
    //     }
    // }
    useEffect(() => {
        handleModifyPricing();
    }, [formik.values.multiState, formik.values.duration, formik.values.country]);
    const getStateListing = async (inputValue) => {
        if (stateList) {
            let updatedData = stateList?.map((state, index) => {
                return {
                    value: state?.value,
                    label: state.label,
                }
            })
            return updatedData || []
        }
    }
    return (
        <>
            <Typography variant="h5">Select Images/Videos</Typography>
            <Divder spacing={0.5} />
            <Box sx={{ display: "flex" }}>
                <CustomAsyncSelect
                    promiseOptions={getImageListing}
                    error={error}
                    helperText={helperText}
                    closeMenuOnSelect={false}
                    controlStyle={{
                        padding: 5
                    }}
                    cacheOptions={false}
                    value={data}
                    handleChange={(e) => {
                        formik.setFieldValue("duration", "");
                        formik.setFieldValue("multiState", "");
                        formik.setFieldValue("country", "India");
                        handleData(e);
                    }}
                    width={'100%'}
                />
                {/* <div style={{ paddingTop: 7, marginLeft: 10 }}>
                    <CustomButton color={"buttonPrimary"} sx={{ marginLeft: 2, mt: { md: 0, xs: 0.4 } }} onClick={() => { onConfirm(data) }}>
                        Confirm
                    </CustomButton>
                </div> */}
            </Box>
            <Box
                className="border-1 rounded-3"
                mt={2}
                px={2}
                py={1}
                minHeight={150}
            >
                <Typography variant="h6">Images/Videos Preview</Typography>
                {data?.length ? <Box sx={{ display: "flex", overflow: "hidden", overflowX: "auto" }}>
                    {data?.map((item, index) => {
                        if ((exclusiveCheck == 3 && (!item.price || !item.quality || !item.duration)) || (item.country == "India" && !item.image_states)) {
                            return (
                                <PreviewStarImageComp
                                    item={item}
                                    handleData={handleData}
                                    handleDelete={handleDelete}
                                    updateData={updateData}
                                    index={index}
                                    key={index}
                                    formik={formik}
                                    onConfirm={onConfirm}
                                    packDetails={
                                        item?.type === 'image' ?
                                            appSetting?.items?.subscriptions?.singleImagePrice :
                                            item?.type === 'video' ?
                                                appSetting?.items?.subscriptions?.singleVideoPrice
                                                : [] || []
                                    }
                                    extraSection={(currentItem, i) => {
                                        return <>
                                            <Box>
                                                {item?.type === 'image' || item?.type === 'Image' || item?.type === '1' ? (
                                                    <>
                                                        {/* <Typography variant="h6" mt={1} pl={1}>
                                                            {finalPriceForFlexiblePlan}
                                                        </Typography> */}
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} md={6}>
                                                                <ProposalFields
                                                                    name="duration"
                                                                    label="Duration"
                                                                    placeholder="Duration"
                                                                    SelectProps={{
                                                                        native: true,
                                                                    }}
                                                                    select
                                                                    value={formik.values.duration}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    InputProps={{
                                                                        classes: {
                                                                            notchedOutline: 'rounded',
                                                                        },
                                                                    }}
                                                                    onChange={(e) => {
                                                                        // formik.handleChange(e);
                                                                        setItemData(item);
                                                                        formik.setFieldValue('duration', e.target.value);
                                                                    }}
                                                                    error={
                                                                        formik.touched.duration &&
                                                                        Boolean(formik.errors.duration)
                                                                    }
                                                                    helperText={
                                                                        formik.touched.duration && formik.errors.duration
                                                                    }
                                                                >
                                                                    <option key={'Select Duration'} value={''}>
                                                                        Select Duration
                                                                    </option>
                                                                    {appSettingDurationData?.map(
                                                                        (duration, index) => (
                                                                            <option
                                                                                key={index}
                                                                                value={duration?.value}
                                                                                name="duration"
                                                                            >
                                                                                {duration?.label}
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </ProposalFields>
                                                            </Grid>
                                                            <Grid item xs={12} md={6}>
                                                                <ProposalFields
                                                                    name="country"
                                                                    label="Country"
                                                                    id="country"
                                                                    placeholder="Country"
                                                                    SelectProps={{
                                                                        native: true,
                                                                    }}
                                                                    select
                                                                    value={formik.values.country}
                                                                    defaultCountry={`${countryShortName}`}
                                                                    country={`${countryShortName}`}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    InputProps={{
                                                                        classes: {
                                                                            notchedOutline: 'rounded',
                                                                        },
                                                                    }}
                                                                    // onChange={(e) => {
                                                                    //   formik.setFieldValue('country', e.target.value);
                                                                    // }}
                                                                    onChange={(e) => handleChangeCountry(e)}
                                                                    error={
                                                                        formik.touched.country &&
                                                                        Boolean(formik.errors.country)
                                                                    }
                                                                    helperText={
                                                                        formik.touched.country && formik.errors.country
                                                                    }
                                                                >
                                                                    <option key={'Select Country'} value={''}>
                                                                        Select Country
                                                                    </option>
                                                                    {countryList &&
                                                                        countryList?.map((country) => (
                                                                            <option key={country.value} value={country.label}>
                                                                                {country.label}
                                                                            </option>
                                                                        ))}
                                                                </ProposalFields>
                                                            </Grid>
                                                            {formik.values.country === "India" ?
                                                                <Grid item xs={12} md={6}>
                                                                    <CustomAsyncSelect
                                                                        promiseOptions={getStateListing}
                                                                        // error={error}
                                                                        // helperText={helperText}
                                                                        closeMenuOnSelect={false}
                                                                        controlStyle={{
                                                                            padding: 5,
                                                                            minHeight: "56px",
                                                                        }}
                                                                        cacheOptions={true}
                                                                        value={formik.values.multiState}
                                                                        // onChange={(e) => handleChangeCountry(e)}
                                                                        error={
                                                                            formik.touched.multiState &&
                                                                            Boolean(formik.errors.multiState)
                                                                        }
                                                                        helperText={
                                                                            formik.touched.multiState && formik.errors.multiState
                                                                        }
                                                                        handleChange={(e) => {
                                                                            setSelectedState(e);
                                                                            formik.setFieldValue("multiState", e);
                                                                            if (e.length > 5) {
                                                                                formik.setFieldValue("territory", 1);
                                                                            }
                                                                            // setStatesData(e);
                                                                            // handleStateChange(e);
                                                                        }}
                                                                        width={'100%'}
                                                                        dropDownZIndex={100000}
                                                                        labelShrink={true}
                                                                        label="Select Image State"
                                                                    />
                                                                </Grid>
                                                                : null}
                                                            <Grid item xs={12} md={6} mt={1}>
                                                                <CustomButton color={"buttonPrimary"} sx={{ marginLeft: 2, mt: { md: 0, xs: 0.4 } }} onClick={() => {
                                                                    const durationLabel = appSettingDurationData?.find(
                                                                        (data) => data?.value == formik?.values?.duration
                                                                    );
                                                                    if (!formik.values.duration)
                                                                        toast.error("Please select duration.")
                                                                    else if (formik.values.country == "India" && !formik.values.multiState)
                                                                        toast.error("Please select states.")
                                                                    else
                                                                        updateData({ duration: durationLabel?.label, price: Number(finalPriceForFlexiblePlan), country: formik.values.country, image_states: formik.values.multiState?.length ? stateData?.items?.length === formik.values.multiState?.length || formik.values.multiState?.length > 5 ? "Pan India" : formik.values.multiState?.map((i) => `${i.label}`).join(',') : "", }, index)
                                                                }}>
                                                                    Add
                                                                </CustomButton>
                                                            </Grid>
                                                        </Grid>

                                                    </>
                                                ) : (
                                                    <></>
                                                )}

                                            </Box>
                                        </>

                                    }}
                                />
                            );
                        }
                    })}
                    {/* {showAllDone && <Typography variant="p" component={'p'} flex="1" className="fontWeight500" textAlign={"center"} pt={4}>All Images/Videos Are Selected</Typography>} */}

                </Box> : <Typography variant="p" component={'p'} className="fontWeight500" textAlign={"center"} pt={4}>No Image/Video Available</Typography>}
            </Box>
        </>
    );
}

export default StarImageSelectionComp;
