import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT } from '../../data/constants';
import { breakStringIntoLines, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { imageListingItemsSelector } from '../../redux/image';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { imageListings, suspendedImageListings } from '../../Services/imageApis';
import { AllOrderListing } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import EditImageDetailModal from '../Modals/EditImageDetailModal';
import MatTable from '../Tables/MatTable';

// const limit = DATA_LIMIT;

const column = [
  {
    label: 'Image',
    id: 'image_url',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
      >
        <img style={{ maxWidth: 200, maxHeight: 200 }} src={`${columnData}`} />
      </span>
    ),
  },
  {
    label: 'Image ID',
    id: 'Name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap', }} >
        {columnData?columnData:'N/A'}
      </span>
    ),
  },
  {
    label: 'Contributor',
    id: 'contributor',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData?breakStringIntoLines(columnData,20):'N/A'}
      </span>
    ),
  },
  {
    label: 'Suspend Date',
    id: 'suspendate',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd/mm/yyyy')}

      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Type',
    id: 'type',
    format: (columnData) => (
      <Typography variant="span">{columnData || 'N/A'}</Typography>
    ),
  },
];

function SuspendedImages() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [imageEditModal, setImageEditModal] = useState(false);
  const [editImageData, setEditImageData] = useState({});
  const { loading, suspendedImages } = useSelector(imageListingItemsSelector);
  const [limit, setLimit] = useState(20);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const handleModal = (value = false) => {
    setImageEditModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      suspended: '1',
      // order_status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(suspendedImageListings(payload));
    setPage(Number(payload.page));
    setEditImageData({});
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (suspendedImages?.items?.users?.length >= 0) {
      let data = suspendedImages?.items?.users?.map((item) => {
        return {
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.Name}.jpg`,
          Name: item?.Name,
          suspendate: item.suspendate,
          contributor: item.contributor,
          type: item.type,
          is_star: item?.is_star,
          watermark: item?.watermark
        };
      });
      setTableListing(data);
    }
  }, [suspendedImages.items]);

  return (
    <>
      <MatTable
        loading={suspendedImages?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20, 50, 100, 200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={suspendedImages?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
      />
      <EditImageDetailModal
        open={imageEditModal}
        handleOpen={handleModal}
        data={editImageData}
        getListing={getListing}
      />
    </>
  );
}

export default SuspendedImages;
