export const appSettingsData = {
  tabs: [
    {
      label: 'Email setup',
      id: 1,
    },
    {
      label: 'Job Description',
      id: 2,
    },
    {
      label: 'Country',
      id: 3,
    },
    {
      label: 'State',
      id: 4,
    },
    {
      label: 'Notifications',
      id: 5,
    },
    {
      label: 'Exclusive Pricing',
      id: 6,
    },
    {
      label: 'Stars',
      id: 7,
    },
    // {
    //   label: 'Banners',
    //   id: 8,
    // },
    {
      label: 'Payment Methods',
      id: 8,
    }
  ],
};

export const categoryOfShoot=[
  {label:'A',value:'A'},
  {label:'B',value:'B'},
  {label:'C',value:'C'}
]
