import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { addCompanyMaster, companyGroupListingsNoRedux, companyListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { addSubscriptionPlan, editSubscriptionPlan, subscriptionListings } from '../../Services/SubscriptionApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';

const limit = DATA_LIMIT;

function ViewSubscription({ data, open, setOpen }) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();


    const formik = useFormik({
        initialValues: {
            plan_id: data?.plan_id,
            plan_name: data?.plan_name || "",
            maximum_downloads: data?.maximum_downloads || "",
            plan_validity: data?.plan_validity || "",
            plan_price: data?.plan_price || "",
            plan_type: data?.plan_type || "",
            plan_description: data?.plan_description || "",
            status: data?.status || "",
            coupon_name: data?.coupon_name || "",
            coupon_amount: data?.coupon_amount || "",
            discount_percentage: data?.discount_percentage || "",
            shot: data?.shot || "",
            sort: data?.sort || '',
            pack_type: data?.pack_type || '',
            per_image: data?.per_image || ''
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            plan_name: Yup.string().required('Plan name is required.'),
            maximum_downloads: Yup.string().required('Max download is required.').matches(/^[0-9]+$/, "Must be only digits"),
            plan_validity: Yup.string().required('Plan validity is required.').matches(/^[0-9]+$/, "Must be only digits"),
            plan_price: Yup.string().required('Plan price is required.').matches(/^[0-9]+$/, "Must be only digits"),
            plan_type: Yup.string().required('Plan type is required.'),
            plan_description: Yup.string().required('Plan description is required.'),
            // status: Yup.string().required('Status is required.'),
            coupon_name: Yup.string(),
            coupon_amount: Yup.string().matches(/^[0-9]+$/, "Must be only digits").when("plan_price", (plan_price, schema) => {
                return schema.test({
                    test: (coupon_amount) => {
                        if (!coupon_amount) return true;
                        return +coupon_amount < +plan_price;
                    },
                    message: "Coupon Amount should not greater then Plan Price",
                });
            }),
            discount_percentage: Yup.string().matches(/^100(\.0{0,2})? *%?$|^\d{1,2}(\.\d{1,2})? *%?$/, "Must be digit").max(100, "should be Less than 100"),
            pack_type: Yup.string().required('Pack Type is required.'),
            per_image: Yup.string().required('Per Image is required.').matches(/^[0-9]+$/, "Must be only digits").min(0)
                .when("plan_price", (plan_price, schema) => {
                    return schema.test({
                        test: (per_image) => {
                            if (!per_image) return true;
                            return +per_image < +plan_price;
                        },
                        message: "Per Image Amount should not greater then Plan Price",
                    });
                }),

            sort: Yup.string().required('sort is required').matches(/^[0-9]+$/, "Must be only digits"),

            // shot: Yup.string().required('Shot is required.').matches(/^[0-9]+$/, "Must be only digits"),
        }),
        // onSubmit: handleConfirm,
    });

    return (
        <div>
            <Dialog
                maxWidth={'md'}
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <DialogTitle variant="h3" padding={3}>
                    View Subscription
                </DialogTitle>
                <DialogContent>
                    <CustomBox padding={1}>
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <Box>
                                    <strong>Plan Name:</strong> {formik.values.plan_name}
                                </Box>
                                <Box>
                                    <strong>Plan Type:</strong> {formik.values.plan_type}
                                </Box>
                                <Box>
                                    <strong>Per Image:</strong> {formik.values.per_image}
                                </Box>
                                <Box>
                                    <strong>Plan Validity:</strong> {formik.values.plan_validity}
                                </Box>
                                <Box>
                                    <strong>Plan Price:</strong> {formik.values.plan_price}
                                </Box>
                                <Box>
                                    <strong>Maximum Downloads:</strong>
                                    {formik.values.maximum_downloads}
                                </Box>
                                <Box>
                                    <strong>Plan Description:</strong> {formik.values.plan_description}
                                </Box>
                            </Grid>
                            <Grid item md={6}>

                                <Box>
                                    <strong>Status:</strong> {formik.values.status}
                                </Box>
                                <Box>
                                    <strong>Coupon Name:</strong> {formik.values.coupon_name}
                                </Box>
                                <Box>
                                    <strong>Coupon Amount:</strong> {formik.values.coupon_amount}
                                </Box>
                                <Box>
                                    <strong>Sort:</strong> {formik.values.sort}
                                </Box>
                                <Box>
                                    <strong>Discount Percentage:</strong> {formik.values.discount_percentage}
                                </Box>
                                <Box>
                                    <strong>Shot:</strong> {formik.values.shot}
                                </Box>
                                <Box>
                                    <strong>Pack Type:</strong> {formik.values.pack_type}
                                </Box>
                            </Grid>
                        </Grid>
                    </CustomBox>
                    <div
                        style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
                    >
                        <CustomButton
                            onClick={() => {
                                formik.resetForm({
                                    plan_name: "",
                                    maximum_downloads: "",
                                    plan_validity: "",
                                    plan_price: "",
                                    plan_type: "",
                                    plan_description: "",
                                    status: "",
                                    coupon_name: "",
                                    coupon_amount: "",
                                    discount_percentage: "",
                                    shot: "",
                                    pack_type: ""
                                });
                                setOpen(false)
                            }
                            }
                            disabled={loading}
                        >
                            Close
                        </CustomButton>
                    </div>
                </DialogContent>
                {/* <DialogActions>
					<CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Upload</CustomButton>
					<CustomButton onClick={() => handleOpen(false)}>Close</CustomButton>
        </DialogActions> */}
            </Dialog>
        </div>
    );
}

export default ViewSubscription;
