import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { crypt, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { imageListingItemsSelector } from '../../redux/image';
import { blockShootids, imageListings, shootIdsListings, unblockShootids } from '../../Services/imageApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import LockIcon from '@mui/icons-material/Lock';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import CategoryIcon from '@mui/icons-material/Category';
import ConfirmationModal from '../Modals/ConfirmationModal';
import ChangeShootsCategoryModal from '../Modals/ChangeShootsCategoryModal';
import MergeIcon from '@mui/icons-material/Merge';
import MergeShootsModal from '../Modals/MergeShootsModal';

const column = [

  {
    label: 'Shoot ID',
    id: 'shoot_id',
    format: (columnData, rowIndex, formatCallback, func, errors, row) => (
      <Link className='textDecoration-none link-text' to={`shoots/${row?.shoot_id}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
        >
          {row?.shoot_id}
        </Typography>
      </Link>
    )
  },
  {
    label: 'Category',
    id: 'category',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap', }} >
        {columnData ? columnData : 'No Category'}
      </span>
    ),
  },
  {
    label: 'Merged Shoots',
    id: 'shoot_ids',

    format: (columnData) => (
      <span style={{
        display: 'inline-block', width: '250px',
        wordWrap: 'break-word'
      }} onClick={() => {
      }} >
        {columnData ? columnData : 'N/A'}
      </span>
    ),

  },

];

function ShootIdListing() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());

  const [editImageData, setEditImageData] = useState({});
  const { loading, allShootIds } = useSelector(imageListingItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteData, setDeleteData] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [limit, setLimit] = useState(20);
  const [shootCategory, setShootCategory] = useState(false);
  const [shootIds, setShootIds] = useState(false);
  const [categoryData, setCategoryData] = useState(null);
  const [initialData, setInitialData] = useState([])

  const handleModal = (value = false) => {
    setImageEditModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const handleCategoryModel = (value = false) => {
    setShootCategory(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const handleShootsModel = (value = false) => {
    setShootIds(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };



  const handleShootCategoryModal = (value = fasle) => {
    setShootCategory(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  }
  const handleShootIdsModal = (value = fasle) => {
    setShootIds(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  }

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      insertion_status: 'old',
      // order_status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(shootIdsListings(payload));
    setPage(Number(payload.page));
    setEditImageData({});
  };

  const handleOpen = (value) => {
    setOpen(value);
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])



  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };


  const handleConfirmBlock = async (value) => {
    if (value && deleteData) {
      let payload = { shootid: deleteData?.shoot_id };
      if (deleteData?.status == 1) {
        let resp = await blockShootids(payload);
        if (resp) {
          getListing({ page: '1' });
          setPage(1);
          setDeleteData(null);
        }
      }
      else if (deleteData?.status == 0) {
        let resp = await unblockShootids(payload);
        if (resp) {
          getListing({ page: '1' });
          setPage(1);
          setDeleteData(null);
        }
      }
      else {
        setDeleteData(null);
      }

    }
  }

  useEffect(() => {
    if (allShootIds?.items?.elements?.rows?.length >= 0) {
      let data = allShootIds?.items?.elements?.rows
        ?.map((item) => {
          return {
            shoot_id: item?.shoot_id,
            status: item?.status,
            category: item?.category,
            shoot_ids: item?.shoot_ids
          };
        });
      setTableListing(data);
    }
  }, [allShootIds.items]);

  const handleShootsMerged = (data) => {
    let array = [];
    if (data?.shoot_ids) {
      let newArr = data?.shoot_ids?.split(",");
      let tempArray = newArr?.map(a => ({ label: a, value: a, category: data?.category }))
      array = tempArray;
    }
    setInitialData(array);


  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton className="buttonDense"
            toolTipTittle={'Change Category'}
            onClick={() => {
              handleCategoryModel(true);
              setCategoryData(data);
            }}><CategoryIcon /></CustomButton>
          <CustomButton className="buttonDense"
            toolTipTittle={'Merge Shoot'}
            onClick={() => {
              handleShootsModel(true);
              setCategoryData(data);
              handleShootsMerged(data)
            }}><MergeIcon /></CustomButton>
          {data?.status == 1 ? <>
            <CustomButton
              toolTipTittle={'Block'}
              onClick={() => {
                handleCofirmModalOpen(true);
                setDeleteData(data);
              }}
              className="buttonDense noMinWidth"
              sx={{
                bgcolor: 'buttonSuccess.main',
                color: 'buttonSuccess.contrastText',
              }}
            >
              <LockPersonIcon />

            </CustomButton>
          </> : <>
            <CustomButton
              toolTipTittle={'UnBlock'}
              onClick={() => {
                handleCofirmModalOpen(true);
                setDeleteData(data);
              }}
              className="buttonDense noMinWidth"

              sx={{
                bgcolor: 'buttonDanger.main',
                color: 'buttonDanger.contrastText',
              }}
            >
              <LockIcon />
            </CustomButton>
          </>}

        </div>

      </>
    );
  };

  return (
    <>
      <MatTable
        loading={allShootIds?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20, 50, 100, 200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={allShootIds?.items?.elements?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
      />

      {
        open && (<ChangeShootsCategoryModal
          open={shootCategory}
          handleOpen={handleShootCategoryModal}
          handleLast={getListing}
          data={categoryData}
          setPage={setPage}
        />)
      }
      {
        shootIds && (<MergeShootsModal
          open={shootIds}
          handleOpen={handleShootsModel}
          handleLast={getListing}
          data={categoryData}
          initialData={initialData}
          setPage={setPage}
        />)
      }


      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleConfirmBlock}
          setOpen={handleCofirmModalOpen}
        />
      )}
    </>
  );
}

export default ShootIdListing;
