import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import ApiConfig from '../../config/ApiConfig';

const ProposalCheckoutSummary = ({ selectedPlanData }) => {
  return (
    <Box sx={{ height: 'auto', maxHeight: '400px', overflowY: 'auto' }}>
      <Box
        sx={{
          display: 'flex',
          gap: '15px',
          marginTop: '10px',
          '@media only screen and (minWidth: 280px) and (maxWidth: 1024px)': {
            flexDirection: 'column',
          },
        }}
      >
        <div
          className="text-center padding-6"
          style={{
            textAlign: 'center',
            minWidth: 100,
          }}
        >
          <Box
            className="fontWeight500"
            alignItems={'center'}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
          >
            <Typography
              variant="p"
              alignItems={'center'}
              display={'flex'}
              style={{
                color: '#fff',
                padding: 10,
                flex: 1,
                borderRadius: 7,
                ...(selectedPlanData?.plan_type?.toLowerCase()?.includes('large')
                  ? { backgroundColor: 'green' }
                  : selectedPlanData?.plan_type?.toLowerCase()?.includes('web')
                  ? { backgroundColor: '#ba36c1' }
                  : selectedPlanData?.plan_type?.toLowerCase()?.includes('small')
                  ? { backgroundColor: 'rgb(58 152 235)' }
                  : { backgroundColor: '#404d93' }),
              }}
            >
              {selectedPlanData?.plan_type?.toLowerCase()?.includes("web")
                ? "Web Size Pack"
                : ""}
              {selectedPlanData?.plan_type?.toLowerCase()?.includes("small")
                ? "Small Size Pack"
                : ""}
              {selectedPlanData?.plan_type?.toLowerCase()?.includes("large")
                ? "Large Size Pack"
                : ""}
            </Typography>
          </Box>
        </div>
        <div>
          <Typography
            variant="h6"
            className="boldFont"
            component="div"
            sx={{ marginBottom: '5px' }}
          >{`Images/Videos : ${selectedPlanData?.maximum_downloads}`}</Typography>
          <Typography
            variant="p"
            className="boldFont"
            component="div"
            sx={{ marginBottom: '5px' }}
          >{`Validity: ${selectedPlanData?.plan_validity} Days`}</Typography>
          <Typography
            variant="p"
            className="boldFont"
            component="div"
            sx={{ marginBottom: '5px' }}
          >{`INR: ${selectedPlanData?.plan_price}`}</Typography>
        </div>
      </Box>
    </Box>
  );
};

export default ProposalCheckoutSummary;
