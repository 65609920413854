import { Typography } from '@mui/material'
import React, { useEffect } from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import { useDispatch } from 'react-redux';
import { setOrderDetails } from '../../redux/orderListing';
import CreateProposalPage from './CreateProposalPage';
import CreateStarProposalPage from './CreateStarProposalPage';

function CreateProposalTabBody({ activeTab, userId, email }) {
    const dispatch = useDispatch()

    //This useEffect is used for clearing the redux value of single order detail 
    useEffect(() => {
        dispatch(setOrderDetails({}));
    }, [])

    return (
        <CustomBox padding={0} sx={{ minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
            {activeTab == 1 && <CreateProposalPage userId={userId} email={email.get('email') || []} />}
            {activeTab == 2 && <CreateStarProposalPage userId={userId} email={email.get('email') || []} />}

            {/* {activeTab == 8 && <IvsOrderListingNew/>} */}
            {/* {activeTab == 8 && <SubscriptionList/>} */}
            {activeTab == 3 && <Typography variant=' h6' sx={{ padding: 2 }}>No data</Typography>}
        </CustomBox>
    )
}

export default CreateProposalTabBody