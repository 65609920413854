import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DATA_LIMIT } from '../../data/constants';
import MatTable from '../Tables/MatTable';
import { checkForEnablePack, checkingPlanActive, crypt, formatDate } from '../../lib/helper';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import SelectPlanModal from '../Modals/SelectPlanModal';
import { useSelector, useDispatch } from 'react-redux';
import { commonItemsSelector } from '../../redux/common';
import {
  getSubscribedOrdersUser,
  getSubscribedOrdersUserNoRedux,
} from '../../Services/userApis';
import DownloadImagesUnderPackModal from '../Modals/DownloadImagesUnderPackModal';
import { userItemsSelector } from '../../redux/user';
import { getNewParameters, getParameters } from '../../lib/helper';
import SelectIVSPlanModal from '../Modals/SelectIVSPlanModal';
import { subscriptionListings } from '../../Services/SubscriptionApis';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => {
      return columnData ? (
        <Link
          className="textDecoration-none link-text"
          to={`../../../invoice?orderId=${crypt('order_id', row?.order_id)}`}
          target={'_blank'}
        >
          {columnData}
        </Link>
      ) : (
        <Typography variant="span" style={{ whiteSpace: 'nowrap' }}>
          {columnData}
        </Typography>
      );
    },
  },
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
        }}
      >
        {columnData}
      </span>
    ),
  },
  {
    label: 'Package',
    id: 'size',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Order Status',
    id: 'order_status',
    format: (columnData) => (
      <>
        <Typography
          variant="span"
          sx={{
            color:
              columnData == 1
                ? 'success.main'
                : columnData == '2'
                  ? 'danger.main'
                  : columnData == '0'
                    ? 'info.main'
                    : 'N/A',
            width: 90,
            display: 'inline-block',
            textAlign: 'center',
          }}
        >
          {columnData == 1
            ? 'C'
            : columnData == '2'
              ? 'R'
              : columnData == '0'
                ? 'P'
                : 'N/A'}
        </Typography>
      </>
    ),
  },
  {
    label: 'Validity',
    id: 'date',
    format: (columnData) => (
      <span style={{ display: 'inline-block' }}>
        {/* {columnData || 'N/A'} */}
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <Typography variant="span" className="fontWeight600">
            Created At:
          </Typography>{' '}
          {formatDate(columnData?.created_at)}
        </span>
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <Typography variant="span" className="fontWeight600">
            Confirmed On:
          </Typography>{' '}
          {formatDate(columnData?.confirmed_on)}
        </span>
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          <Typography variant="span" className="fontWeight600">
            Expiry:
          </Typography>{' '}
          {formatDate(columnData?.expired_on)}
        </span>
      </span>
    ),
  },
  {
    label: 'Remaining',
    id: 'remaining',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Client',
    id: 'client',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
];

function UserActiveIvsOrderData({ }) {
  const { appSetting } = useSelector(commonItemsSelector);
  const [smallAndLargePack, setSmallAndLargePack] = useState([]);
  const { singleUserActiveIvsOrderDetail } = useSelector(userItemsSelector);
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [selectRenewPlanModal, setSelectRenewPlanModal] = useState(false);
  const [openIvsPlanModal, setOpenIvsPlanModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [allParams, setAllParams] = useState(getParameters());

  const [selectRenewPlan, setSelectRenewPlan] = useState({
    planListing: [],
    currentPlan: null,
    type: '',
    order_id: null,
    userId: userId,
    client: null,
  });

  useEffect(() => {
    let largePack = appSetting?.items?.subscriptions?.largeSizePack;
    let smallPack = appSetting?.items?.subscriptions?.smallSizePack;
    let webPack = appSetting?.items?.subscriptions?.webSizePack;
    let newCombinedArray = [];
    if (largePack) newCombinedArray = largePack;
    if (smallPack) newCombinedArray = [...newCombinedArray, ...smallPack];
    if (webPack) newCombinedArray = [...newCombinedArray, ...webPack];
    if (newCombinedArray) setSmallAndLargePack(newCombinedArray)
  }, [appSetting])

  const [downloadImageUnderPackModalOpen, setDownloadImageUnderPackModalOpen] =
    useState(false);
  const [downloadVideoUnderPackModalOpen, setDownloadVideoUnderPackModalOpen] =
    useState(false);
  const [ivsDownloadActiveData, setIvsDownloadActiveData] = useState(null);

  useEffect(() => {
    if (singleUserActiveIvsOrderDetail?.items?.rows?.length >= 0) {
      let data = singleUserActiveIvsOrderDetail?.items?.rows?.map((item) => {
        return {
          invoice_id: item?.invoice?.invoice_id,
          order_id: item.order_id,
          size: item?.size,
          type: item?.type,
          plan_type: item?.plan_type,
          plan_id: item?.plan_id,
          ivs_order_id: item?.ivs_order_id,
          order_status: item?.order?.order_status,
          remaining: item?.remaining_images != null ? item?.remaining_images + '/' + item?.total_images : 0,
          client: item?.invoice?.client,
          renewedstatus: item?.renewedstatus,
          expired_on: item?.expired_on,
          date: {
            confirmed_on: item?.confirmed_on,
            expired_on: item?.expired_on,
            created_at: item?.created_at,
          },
        };
      });
      setTableListing(data);
    }
  }, [singleUserActiveIvsOrderDetail?.items?.rows]);

  const getData = (params = {}, setPageState = () => { }) => {
    const payload = {
      page: String(1),
      limit: limit,
      user_id: userId,
      type: '1',
      ...params,
    };
    dispatch(getSubscribedOrdersUser(payload));
    if (setPageState) {
      setPageState(Number(payload.page));
    }
  };

  const getIvsData = async () => {
    try {
      let data = await getSubscribedOrdersUserNoRedux(userId);
      if (data?.length) {
        setIvsDownloadActiveData(data);
      }
      getData(null, setPage);
    } catch (e) { }
  };

  useEffect(() => {
    getData(null, setPage);
    getIvsData();
  }, []);

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getData();
  }, [allParams]);

  const handleDownloadImageUnderPackModalOpen = (value) => {
    setDownloadImageUnderPackModalOpen(value);
  };
  const handleIvsPackModalOpen = (value) => {
    setOpenIvsPlanModal(value);
  };
  const handleDownloadVideosUnderPackModalOpen = (value) => {
    setDownloadVideoUnderPackModalOpen(value);
  };

  const getOrderListing = async (params = {}) => {
    const payload = {
      status: "1"
    }
    dispatch(subscriptionListings(payload))

  }

  const actionBody = (data) => {
    return (
      <>
        {checkingPlanActive(smallAndLargePack, data) && <>
          {new Date(formatDate(data?.expired_on, 'yyyy-mm-dd')) >=
            new Date(formatDate(new Date(), 'yyyy-mm-dd')) &&
            data?.renewedstatus === false &&
            data?.order_status != '2' && checkForEnablePack(data?.plan_type, appSetting?.items?.subscriptions)?.status ? (
            <div style={{ display: 'flex' }}>
              <CustomButton
                onClick={() => {
                  handleRenewModal(true, data);
                }}
                sx={{
                  padding: '5px 10px',
                  marginRight: 1,
                  fontSize: '12px',
                  bgcolor: 'buttonSecondary.main',
                  color: 'buttonSecondary.contrastText',
                }}
              >
                Renew
              </CustomButton>
            </div>
          ) : null}
        </>
        }
      </>
    );
  };

  const handleRenewModal = (value, data) => {
    setSelectRenewPlanModal(value);

    const largeSizePack =
      appSetting?.items?.subscriptions?.largeSizePack.filter(
        (ele) => ele?.plan_id == data?.plan_id,
      );
    const webSizePack = appSetting?.items?.subscriptions?.webSizePack.filter(
      (ele) => ele?.plan_id == data?.plan_id,
    );
    const smallSizePack = appSetting?.items?.subscriptions?.smallSizePack.filter(
      (ele) => ele?.plan_id == data?.plan_id,
    );

    if (largeSizePack?.length > 0) {
      setSelectRenewPlan({
        planListing: appSetting?.items?.subscriptions?.largeSizePack,
        currentPlan: largeSizePack[0],
        type: 'large',
        order_id: data?.order_id,
        userId: userId,
        client: data?.client,
      });
    }
    if (webSizePack?.length > 0) {
      setSelectRenewPlan({
        planListing: appSetting?.items?.subscriptions?.webSizePack,
        currentPlan: webSizePack[0],
        type: 'web',
        order_id: data?.order_id,
        userId: userId,
        client: data?.client,
      });
    }
    if (smallSizePack?.length > 0) {
      setSelectRenewPlan({
        planListing: appSetting?.items?.subscriptions?.smallSizePack,
        currentPlan: smallSizePack[0],
        type: 'small',
        order_id: data?.order_id,
        userId: userId,
        client: data?.client,
      });
    }

  };

  const handleSelectPlan = (data) => {
    setSelectRenewPlan({
      ...selectRenewPlan,
      currentPlan: data,
    });
  };

  return (
    <>
      <MatTable
        showCheckbox={false}
        columns={column}
        data={tableListing}
        loading={singleUserActiveIvsOrderDetail?.loading}
        action={actionBody}
        page={page}
        perPage={limit}
        total={singleUserActiveIvsOrderDetail?.items?.count || 0}
        handleSearch={(searchTerm) => {
          getData({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        setPage={setPage}
        handlePageChange={(newPage) => {
          getData(
            {
              page: String(newPage),
            },
            setPage,
          );
        }}
        pagination={true}
        toolbarEnable={true}
        toolBarButton={() => {
          return (
            <>
              <CustomButton
                onClick={() => {
                  handleIvsPackModalOpen(true);
                }}
                color={'buttonSecondary'}
                sx={(theme) => ({
                  padding: '10px 10px',
                  marginRight: 1,
                  [theme.breakpoints.down('md')]: {
                    flex: 1,
                  },
                })}
              >
                Create IVS Order
              </CustomButton>
              <CustomButton
                onClick={() => {
                  handleDownloadImageUnderPackModalOpen(true);
                }}
                color={'buttonPrimary'}
                sx={(theme) => ({
                  padding: '10px 10px',
                  marginRight: 1,
                  [theme.breakpoints.down('md')]: {
                    flex: 1,
                  },
                })}
              >
                Download Images/Videos
              </CustomButton>
            </>
          );
        }}
      />
      {selectRenewPlanModal && (
        <SelectPlanModal
          open={selectRenewPlanModal}
          handleOpen={handleRenewModal}
          heading={'Select Plan'}
          handleSelectPlan={handleSelectPlan}
          selectRenewPlan={selectRenewPlan}
        />
      )}
      {openIvsPlanModal && (
        <SelectIVSPlanModal
          open={openIvsPlanModal}
          handleOpen={handleIvsPackModalOpen}
          heading={'Select IVS Plan'}
          userId={userId}
        />
      )}
      {downloadImageUnderPackModalOpen && (
        <DownloadImagesUnderPackModal
          open={downloadImageUnderPackModalOpen}
          singleUserActiveIvsOrderDetail={ivsDownloadActiveData}
          handleOpen={handleDownloadImageUnderPackModalOpen}
          heading={'Download Images/Videos'}
          userId={userId}
          getActiveIvsData={getIvsData}
        />
      )}
    </>
  );
}

export default UserActiveIvsOrderData;
