import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allAutoSuggest: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const autoSuggestSlice = createSlice({
  name: 'autoSuggest',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllAutoSuggestListing: (state, action) => {
      state.allAutoSuggest.items = action.payload;
      state.allAutoSuggest.error = {
        isError: false,
        message: '',
      };
    },
    setAllAutoSuggestListingLoading: (state, action) => {
      state.allAutoSuggest.loading = action.payload;
    },
    setAllAutoSuggestListingError: (state, action) => {
      state.allAutoSuggest.items = {};
      state.allAutoSuggest.error = action.payload;
    },
  },
});

export default autoSuggestSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllAutoSuggestListing,
  setAllAutoSuggestListingError,
  setAllAutoSuggestListingLoading
} = autoSuggestSlice.actions;

export const autoSuggestionListingItemsSelector = (state) => state.autoSuggestList;
