import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import ImageTabBody from '../../Components/Image/ImageTabBody';
import { ImageData } from '../../data/imageData';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InvoicePage from '../../Components/Receipts/InvoicePage';
import { decrypt } from '../../lib/helper';
import { toast } from 'react-toastify';

function Invoice({ handleNavParams }) {
  const [tabValue, setTabValue] = useState(1);
  const [orderId, setOrderId] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
 
  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };

  React.useEffect(()=>{
    try{
      let orderId = decrypt('order_id', searchParams.get('orderId')) 
      if(!orderId || orderId == null){
        toast.error('Please provide order id.')  
        navigate('../dashboard', {replace: true})
      }else{
        setOrderId(orderId)
      }
    }catch(e){
      toast.error('Please provide order id.')  
      navigate('../dashboard', {replace: true})
    }
  },[searchParams])  
  
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <InvoicePage orderId={orderId}/>
      <Divder spacing={1.2} />
    </Box>
  );
}

export default Invoice;
