import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, FormHelperText, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { linkKeywordToImage } from '../../Services/keywordsApis';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import CrossButton from '../CommonComp/Buttons/CrossButton';
const limit = 10;

function ImageKeywordModal({ open, handleOpen, data, getListing, keywordForTheChoosenImage }) {
  const [loading, setLoading] = useState(false);
  const [groupData, setGroupData] = useState(false);
  const [allKeywordsRelatedToImage, setAllKeywordsRelatedToImage] = useState([]);
  const [newKeyword, setNewKeyword] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await linkKeywordToImage(values)
    if (resp) {
      resetForm({
        keywords: "",
        image_name: "",
      })
      getListing()
      handleOpen(false)
    }
    setLoading(false)
  };

  useEffect(() => {
    handleKeyword();
  }, [keywordForTheChoosenImage])

  const handleKeyword = () => {
    setAllKeywordsRelatedToImage(keywordForTheChoosenImage);
    if (keywordForTheChoosenImage) {
      formik.setFieldValue('keywords', keywordForTheChoosenImage?.join(","));
    }
  }




  const formik = useFormik({
    initialValues: {
      keywords: "",
      image_name: data?.Name,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      keywords: Yup.string().required('Minimum 2 keywords required.'),
      image_name: Yup.string().required('Image Name is required.')
    }),
    onSubmit: handleConfirm,
  });

  const handleCrossIcon = (a) => {
    let array = [...allKeywordsRelatedToImage];
    if (array?.length > 2) {
      array = array?.filter((img => img !== a));
      setAllKeywordsRelatedToImage(array);
      setFormErrors({});

    }
    else {
      setFormErrors({ keyword: 'Minimum 2 Keywords required' });

    }
  }

  const handleChange = (e) => {
    setNewKeyword(e.target.value);
  }
  const addnewKeyWord = (e) => {
    // e.preventdefault();
    if (newKeyword) {
      let array = [...allKeywordsRelatedToImage];
      let index = array?.findIndex(a => a === newKeyword);
      if (index < 0) {
        array?.push(newKeyword);
        setAllKeywordsRelatedToImage(array);
        formik.setFieldValue('keywords', array?.join(","));
        setNewKeyword('');
        if (array?.length > 2) {
          setFormErrors({});
        }
      }
    }
  }

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant='h3'>Add Keywords</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              width: 400
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} marginTop={1}>

              <Grid item xs={12}>

                {
                  allKeywordsRelatedToImage?.map((a, index) => (
                    <CrossButton size="small" handleCrossIcon={handleCrossIcon} key={index}>{a}</CrossButton>
                  )
                  )
                }
                {formErrors?.keyword && (<>
                  <FormHelperText style={{ color: "red" }}>{formErrors.keyword}</FormHelperText>
                </>
                )}
                
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'start', marginTop: 10 }}>
              <CustomInput label="keyword" onChange={handleChange} />
              <CustomButton onClick={(e) => addnewKeyWord(e)}>Add Keyword</CustomButton>

            </div>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Confirm</CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    hidden_keyword_id: "",
                    status: "0",
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >Cancel</CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ImageKeywordModal;
