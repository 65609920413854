import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import MainDropzone from '../CommonComp/DropZone/Dropzone';
import { Box, Divider, Grid, Typography } from '@mui/material';
import Divder from '../CommonComp/Divder';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { cmsItemsSelector } from '../../redux/cms';
import { editAddCMS, getCMSInfo } from '../../Services/cmsService';


function Purpose({ purposeData, cmsDataHome, handleLast }) {
  const dispatch = useDispatch();
  const { cmsData } = useSelector(cmsItemsSelector);
  const [purposeDataCopy, setPurposeCopy] = useState(purposeData);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let cmsObj = { ...cmsDataHome, sectionBottom: values }
    let str1 = JSON.stringify(cmsObj);
    let obj = { pagename: 'homePage', pagedata: str1 }
    let resp = await editAddCMS(obj);
    if (resp) {
      handleLast()
    }
    setLoading(false);
  };
  useEffect(() => {
    getListing();

  }, [purposeData])

  const getListing = () => {
    // let payload = { pagename: 'homePage' }
    // dispatch(getCMSInfo(payload));
    setPurposeCopy(purposeData)
  }

  const formik = useFormik({
    initialValues: {
      heading: purposeDataCopy?.heading || "",
      content: purposeDataCopy?.content || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      heading: Yup.string().required('Heading is required'),
      content: Yup.string().required('Content is required'),

    }),
    onSubmit: handleConfirm,
  });

  return (
    <>
      <Box
        noValidate
        component="form"
        // sx={{
        //   width: 800
        // }}
        marginTop={5}
        marginLeft={2}
        onSubmit={formik.handleSubmit}
      >
        <Typography variant='h5' mb={2} >About Section</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <CustomInput
              variant="outlined"
              fullWidth
              name="heading"
              label="Heading"
              placeholder="Heading"
              value={formik.values.heading}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: 'rounded',
                },
              }}
              error={formik.touched.heading && Boolean(formik.errors.heading)}
              helperText={formik.touched.heading && formik.errors.heading}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <CustomInput
              variant="outlined"
              fullWidth
              name="content"
              label="Content"
              placeholder="Content"
              value={formik.values.content}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: 'rounded',
                },
              }}
              multiline
              rows={10}
              error={
                formik.touched.content && Boolean(formik.errors.content)
              }
              helperText={
                formik.touched.content && formik.errors.content
              }
            />
          </Grid>
        </Grid>
        <div
          style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
        >
          <CustomButton
            color={'buttonPrimary'}
            type="submit"
            loading={loading}
          >
            Save
          </CustomButton>
        </div>
      </Box>
      <Divder spacing={1} />
    </>
  );
}

export default Purpose;