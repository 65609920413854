import { Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { currencyConversion, downloadBlobFile, formatDate, getNewParameters, getObjectSubset, getParameters, validDate } from '../../lib/helper';
import { getContributorReportListing, getContrubutorsReport, getReportListing } from '../../Services/reportsApis';
import * as Yup from 'yup';
import { contributorReportListingItemsSelector, setResetDailyContributorReportData } from '../../redux/contributorReport';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import AllFilters from '../Order/AllFilters';
import { SearchByContributorReport } from '../../data/reportsData';
import { listOfFollowedBy } from '../../Services/cmsService';

const limit = 10;

const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (

      <Typography
        variant='span'
        style={{ whiteSpace: 'nowrap' }}
      >
        {columnData}
      </Typography>

    ),
  },
  {
    label: 'Image',
    id: 'image_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Contributor Email',
    id: 'contributor',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Download On',
    id: 'Download_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? formatDate(columnData, 'dd/mm/yyyy') : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Paid On',
    id: 'paid_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? formatDate(columnData, 'dd/mm/yyyy') : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Quality',
    id: 'quality',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },

  {
    label: 'Discount (%)',
    id: 'user_discount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'User Name',
    id: 'user_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Amount (INR)',
    id: 'amount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData && currencyConversion(columnData) || 'N/A'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Discount (INR)',
    id: 'Discount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData && currencyConversion(columnData) || 'N/A'}
      </span>
    ),
    nextLine:true,
  },

  {
    label: 'Final Price(INR)',
    id: 'final_price',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData && currencyConversion(columnData) || 'N/A'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Royalty (INR)',
    id: 'royality_imcome',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData && currencyConversion(columnData) || 'N/A'}
      </span>
    ),
    nextLine:true,
  },

  {
    label: 'Payment Type',
    id: 'payment_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Total Tax',
    id: 'total_tax',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData && currencyConversion(columnData) || 'N/A'}
      </span>
    ),
    nextLine:true,
  },
];

function MultipleDailyReport() {
  const [fileResp, setFileResp] = useState({
    status: false,
    data: null
  })
  const [loading, setLoading] = useState(false);
  const [tableListing, setTableListing] = useState([]);
  const { allContributorReports, mainLoading } = useSelector(contributorReportListingItemsSelector);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [filtersArray, setFiltersArray] = useState([

    {
      label: 'From Date',
      id: 'from',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          return newState;
        });
           },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'to',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Managed By',
      id: 'created_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'All', value: '' },
      ],
      props: {},
      isVisible: true,
    },
    // {
    //   label: 'Search By',
    //   id: 'search_by',
    //   type: 'select',
    //   linkedWith: null,
    //   value: allParams?.search_by || '',
    //   onChange: (e, index, value) => {
    //     setFiltersArray((prevState) => {
    //       let newState = [...prevState];
    //       newState[index].value = e.target.value;
    //       return newState;
    //     });
    //   },
    //   style: {},
    //   dataArr: SearchByContributorReport,
    //   props: {},
    //   isVisible: true,
    // },
    // {
    //   label: 'Search Text',
    //   id: 'search',
    //   type: 'input',
    //   linkedWith: null,
    //   value: allParams?.search || '',
    //   onChange: (e, index, value) => {
    //     setFiltersArray((prevState) => {
    //       let newState = [...prevState];
    //       newState[index].value = e?.target?.value?.trim();
    //       return newState;
    //     });
    //   },
    //   style: {},
    //   dataArr: [],
    //   props: {},
    //   isVisible: true,
    // },

  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getOrderListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      newState[3].value = '';
      return newState;
    });
    dispatch(setResetDailyContributorReportData());
    setTableListing([])

  }
  useEffect(() => {
    // getListing();
    getUsersList()
  }, []);

  const getUsersList = useCallback(async () => {
    let data = await listOfFollowedBy()
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[2].dataArr = [{ label: 'All', value: '' },
      ...data?.map((el) => {
        return { label: el?.first_name, value: el?.first_name }
      })];
      return newState;
    });
  }, [])
  useEffect(() => {
    if (allContributorReports?.items?.result?.length >= 0) {
      setTableListing(allContributorReports?.items?.result)
    }
  }, [allContributorReports.items]);

  useEffect(() => {
    dispatch(setResetDailyContributorReportData())
  }, [])


  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await getContrubutorsReport(values)
    if (resp.status) {
      setFileResp(resp)
      resetForm()
      downloadBlobFile(resp.data, `ContributorsReport${values.from}-/-${values.to}`)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      from: "",
      to: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      from: Yup.string().required('From Date is required.'),
      to: Yup.string().required('To Date is required.'),
    }),
    onSubmit: checking,
  });

  async function checking() {
    setLoading(true)
    let resp = await getContrubutorsReport(formik.values)
    if (resp.status) {
      setFileResp(resp)
      downloadBlobFile(resp.data, `ContributorsReport${formik?.values.from}-/-${formik?.values.to}`)
    }
    setLoading(false)
  }
  const getOrderListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params
    }
    if (payload?.from) {
      payload['from'] = formatDate(payload?.from, 'yyyy-mm-dd') + "  00:00:00"
    }
    if (payload?.to) {
      payload['to'] = formatDate(payload?.to, 'yyyy-mm-dd') + "  23:59:59"
    }
    if (payload?.to && payload?.from) {
      dispatch(getContributorReportListing(payload))
      setPage(Number(payload.page))
    }
    else {
      toast.error("Start and End Date required!")
    }
  }

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (formik?.values?.start_date && formik?.values?.end_date) {
      if (data?.search !== location.search) {
        navigate(data.newUrl, { replace: false })
        if (data?.parameters?.page) {
          setPage(Number(data?.parameters?.page))
        }
      }
      getOrderListing();
    }
  }, [allParams])

  const getExportFile = async (params = {}, b, prepop) => {
    try {

      let payload = {
        ...allParams,
        ...params
      }
      delete payload.page
      delete payload.limit

      setLoading(true);
      if (prepop?.from && prepop?.to) {

        if (prepop?.from) {
          prepop['from'] = formatDate(prepop?.from, 'yyyy-mm-dd') + "  00:00:00"
        }
        if (prepop?.to) {
          prepop['to'] = formatDate(prepop?.to, 'yyyy-mm-dd') + "  23:59:59"
        }
        let resp = await getContrubutorsReport(prepop)
        if (resp.status) {
          setFileResp(resp)
          // formik?.resetForm()
          downloadBlobFile(resp.data, `ContributorsReport${formik?.values.from}-/-${formik?.values.to}`)
        }
      }
      else {
        toast.error("Start and End Date required!")
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  return (
    <>
      <MatTable
        loading={allContributorReports?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}

        page={page}
        perPage={limit}
        total={allContributorReports?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            // hasDownloadExcelButton={true}
            // handleDownloadExcel={getExportFile}
          />
        )}
        pagination={true}
        filtersUrlData={allParams || {}}
        disableSearch

      />

    </>
  );
}

export default MultipleDailyReport;
