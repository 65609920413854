import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import ApiConfig from '../../config/ApiConfig';
import { formatDate } from '../../lib/helper';
// import MainLogo from '../../Asset/image/MainLogo.png';
// import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomLoading from '../CommonComp/CustomLoading/CustomLoading';
import { AppLogoNew } from '../../Asset/svg';
import CustomButton from '../CommonComp/Buttons/CustomButton';

const renderExtraFields = (data) => {
  return <div className="width-100 display-flex">
    <div
      className="text-right padding-6 bold-font"
      style={{
        borderTop: "1px solid black",
        width:
          data?.proposal_type?.toLowerCase() === "nplan"
            ? "85%"
            : data?.proposal_type === "IV"
              ? "80%"
              : "80%",
      }}
      contentEditable suppressContentEditableWarning={true}
    >

    </div>
    <div
      className="text-center padding-6 bold-font"
      style={{
        borderTop: "1px solid black",
        borderLeft: "1px solid black",
        width: data?.proposal_type?.toLowerCase() === "nplan" ? "15%" : data?.proposal_type === "IV" ? "20%" : "20%",
      }}
      contentEditable suppressContentEditableWarning={true}
    >

    </div>
  </div>
}

export const ProposalQuotation = React.forwardRef(({ data }, ref) => {
  const [extraData, setExtraData] = useState([])
  if (!data) {
    return <Typography component={'div'}><CustomLoading loading={!data} /></Typography>;
  }
  const handleData = () => {
    let newData = [...extraData];
    newData.push(renderExtraFields(data))
    setExtraData([...newData])
  }

  const removeRow = () => {
    let newData = [...extraData];
    if (newData?.length) {
      newData.pop(renderExtraFields(data));
      setExtraData([...newData])

    }

  }



  return (
    <>
      <Box sx={{ position: 'fixed', right: 10 }}>
        <CustomButton sx={{ fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}
          onClick={() => {
            handleData()
          }}
        >Add Row</CustomButton>
        {extraData?.length ? <CustomButton sx={{ fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}
          onClick={() => {
            removeRow()
          }}
        >Remove Row</CustomButton> : ''}
      </Box>
      <Box component={'div'} ref={ref} id="quotation" className='print'>
        <table width="100%" cellSpacing="0" border="0" style={{ margin: '0px auto' }}>
          <tbody>
            <tr>
              <td width="165" height="48" align="left" valign="top">
                <AppLogoNew style={{
                  maxWidth: 150,
                  maxHeight: 40,
                  width: '100%'
                }} />
              </td>

              <td
                width="321"
                align="left"
                valign="middle"
              >
                <strong contentEditable suppressContentEditableWarning={true} >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;QUOTATION
                </strong>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="outer-section-invoice word-break">
          <div className="main-section-invoice font-12">
            <div className="display-flex width-100">
              <div style={{ width: "70%", height: "100%" }}>
                <div
                  className="padding-6"
                  style={{ minHeight: "33px", borderBottom: "1px solid black" }}
                >
                  <span className="bold-font" contentEditable suppressContentEditableWarning={true} >Quotation No.: </span><span contentEditable suppressContentEditableWarning={true} >{data?.proposal_id}</span>
                </div>
                <div
                  className="padding-6"
                  style={{ minHeight: "33px", borderBottom: "1px solid black" }}
                >
                  <span className="bold-font" contentEditable suppressContentEditableWarning={true} >Kind Attn : </span><span contentEditable suppressContentEditableWarning={true} >{data?.heading}</span>
                </div>
                {data?.client_name ? <div
                  className="padding-6"
                  style={{ minHeight: "33px", borderBottom: "1px solid black" }}
                >
                  <span className="bold-font" contentEditable suppressContentEditableWarning={true} >Client Name : </span><span contentEditable suppressContentEditableWarning={true} >{data?.client_name}</span>
                </div> : null}
                {data?.gst_number ? <div className="padding-6" style={{ minHeight: "33px", borderBottom: "1px solid black" }}>
                  <span className="bold-font" contentEditable suppressContentEditableWarning={true} >GSTIN : </span><span contentEditable suppressContentEditableWarning={true} >{data?.gst_number}</span>
                </div> : null}
                <div className="padding-6" style={{ minHeight: "33px", borderBottom: "1px solid black" }}>
                  <span className="bold-font" contentEditable suppressContentEditableWarning={true} >Place of Supply : </span><span contentEditable suppressContentEditableWarning={true} >{data?.state}</span>
                </div>
                {/* {data?.industry ? <div className="padding-6" style={{ minHeight: "33px" }}>
                  <span className="bold-font" contentEditable suppressContentEditableWarning={true} >Industry : </span><span contentEditable suppressContentEditableWarning={true} >{data?.industry}</span>
                </div> : null} */}
              </div>
              <div style={{ width: "30%", borderLeft: "1px solid black" }}>
                <div
                  className="padding-6"
                  style={{ minHeight: "33px", borderBottom: "1px solid black" }}
                >
                  <span className="bold-font" contentEditable suppressContentEditableWarning={true} >Date : </span><span contentEditable suppressContentEditableWarning={true} >{formatDate(data?.created_at, 'dd/mm/yyyy')}</span>
                </div>
                {!!data?.credit_period && <div
                  className="padding-6"
                  style={{ minHeight: "33px", borderBottom: "1px solid black" }}
                >
                  <span className="bold-font" contentEditable suppressContentEditableWarning={true} > Credit Period : </span><span contentEditable suppressContentEditableWarning={true} >{data?.credit_period || 0}</span>
                </div>}
              </div>
            </div>
            <div className="width-100">
              <table className="width-100 border-collapse">
                <thead>
                  <tr style={{ backgroundColor: "#EEEEEE" }}>
                    {data?.proposal_type?.toLowerCase() === "nplan" && (
                      <>
                        <th
                          className="padding-6"
                          style={{
                            borderTop: "1px solid black",
                            width: "15%",
                          }}
                        ></th>
                        <th
                          className="border-1px padding-6"
                          style={{ width: "10%" }}
                          contentEditable suppressContentEditableWarning={true}
                        >
                          Item ID
                        </th>
                        <th
                          className="border-1px padding-6"
                          style={{ width: "10%" }}
                          contentEditable suppressContentEditableWarning={true}
                        >
                          Type
                        </th>
                        <th
                          className="border-1px padding-6"
                          style={{ width: "25%" }}
                          contentEditable suppressContentEditableWarning={true}
                        >
                          Dimensions (Pixels)
                        </th>
                        <th
                          className="border-1px padding-6"
                          style={{ width: "25%" }}
                          contentEditable suppressContentEditableWarning={true}
                        >
                          Rights
                        </th>
                        <th
                          className="padding-6"
                          style={{
                            borderTop: "1px solid black",
                            width: "15%",
                          }}
                          contentEditable suppressContentEditableWarning={true}
                        >
                          Value
                        </th>
                      </>
                    )}
                    {data?.proposal_type === "IV" && (
                      <>
                        <th
                          className="padding-6"
                          style={{
                            borderTop: "1px solid black",
                            width: "20%",
                          }}
                        ></th>
                        <th
                          className="border-1px padding-6"
                          style={{ width: "15%" }}
                          contentEditable suppressContentEditableWarning={true}
                        >
                          Plan Name
                        </th>
                        <th
                          className="border-1px padding-6"
                          style={{ width: "10%" }}
                          contentEditable suppressContentEditableWarning={true}
                        >
                          No. of I/V
                        </th>
                        <th
                          className="border-1px padding-6"
                          style={{ width: "15%" }}
                          contentEditable suppressContentEditableWarning={true}
                        >
                          Validity of Packages
                        </th>
                        <th
                          className="padding-6"
                          style={{
                            borderTop: "1px solid black",
                            width: "15%",
                          }}
                          contentEditable suppressContentEditableWarning={true}
                        >
                          Value
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>

                  {data?.proposal_type?.toLowerCase() === "nplan" &&
                    data?.proposal_details?.map((item, index) => {
                      return <tr key={index}>
                        <td className="text-center padding-6"
                          style={{
                            borderTop: "1px solid black",
                            width: "15%",

                            textAlign: "center",
                          }}>
                          <img
                            alt="image"
                            width="80%"
                            style={{ minWidth: 100 }}
                            height="auto"
                            src={`${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.image_name}.jpg`}
                          />
                        </td>
                        <td className="text-center padding-6"
                          style={{
                            width: "10%",
                            borderLeft: "1px solid black",
                            borderTop: "1px solid black",
                            borderRight: "1px solid black",
                            wordBreak: "keep-all"
                          }} contentEditable suppressContentEditableWarning={true} >{item?.image_name}</td>
                        <td className="text-center padding-6"
                          style={{
                            width: "10%",
                            borderRight: "1px solid black",
                            borderTop: "1px solid black",
                          }} contentEditable suppressContentEditableWarning={true} >{item?.quality}</td>
                        <td className="text-center padding-6"
                          style={{
                            width: "10%",
                            borderRight: "1px solid black",
                            borderTop: "1px solid black",
                          }} contentEditable suppressContentEditableWarning={true} >{item?.dimension || 'N/A'}</td>
                        <td className="text-center padding-6"
                          style={{
                            width: "10%",
                            borderRight: "1px solid black",
                            borderTop: "1px solid black",
                          }} contentEditable suppressContentEditableWarning={true} >
                          <div>
                            {item?.right || "Non-Exclusive"}
                          </div>
                          {data?.industry ?
                            <div>
                              {data?.industry}
                            </div> : null}
                          {item?.country && item?.country !== "India" ? <div>
                            {item?.country}
                          </div> : null}
                          <div>
                            {item?.image_states}
                          </div>
                          {item?.duration ? <div>
                            {item?.duration}
                          </div> : null}

                        </td>
                        <td className="text-center padding-6"
                          style={{
                            width: "10%",
                            // borderRight: "1px solid black",
                            borderTop: "1px solid black",
                          }} contentEditable suppressContentEditableWarning={true} >{item?.price}</td>
                      </tr>
                    })
                  }
                  {data?.proposal_type === "IV" &&
                    data?.proposal_details?.map((item, index) => {
                      return <tr key={index}>
                        <td className="text-center padding-6"
                          style={{
                            borderTop: "1px solid black",
                            width: "15%",
                            textAlign: "center",
                            minWidth: 100
                          }}>
                          <Box className="fontWeight500" alignItems={'center'} display={'flex'} flexDirection={'column'}>
                            <Typography variant="p" alignItems={'center'} display={'flex'} style={{
                              color: "#fff", padding: 10, flex: 1, borderRadius: 7,
                              ...(item?.quality?.toLowerCase()?.includes('large') ?
                                { backgroundColor: "green" } :
                                item?.quality?.toLowerCase()?.includes('web') ?
                                  { backgroundColor: '#ba36c1' } :
                                  item?.quality?.toLowerCase()?.includes('small') ?
                                    { backgroundColor: 'rgb(58 152 235)' } :
                                    { backgroundColor: '#404d93' }),
                            }} contentEditable suppressContentEditableWarning={true} >{item?.quality}</Typography>
                          </Box>
                        </td>
                        <td className="text-center padding-6"
                          style={{
                            width: "10%",
                            borderLeft: "1px solid black",
                            borderTop: "1px solid black",
                            borderRight: "1px solid black",
                            wordBreak: "keep-all"
                          }} contentEditable suppressContentEditableWarning={true} >{item?.quality}-{item?.image_name}</td>
                        <td className="text-center padding-6"
                          style={{
                            width: "10%",
                            borderRight: "1px solid black",
                            borderTop: "1px solid black",
                            wordBreak: "keep-all"
                          }} contentEditable suppressContentEditableWarning={true} >{item?.image_name}</td>
                        <td className="text-center padding-6"
                          style={{
                            width: "10%",
                            borderRight: "1px solid black",
                            borderTop: "1px solid black",
                          }} contentEditable suppressContentEditableWarning={true} >{item?.duration} days</td>
                        <td className="text-center padding-6"
                          style={{
                            width: "10%",
                            // borderRight: "1px solid black",
                            borderTop: "1px solid black",
                          }} contentEditable suppressContentEditableWarning={true} >{item?.price}</td>
                      </tr>
                    })
                  }
                </tbody>
              </table>
            </div>
            <div className="width-100 display-flex">
              <div
                className="text-right padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  width:
                    data?.proposal_type?.toLowerCase() === "nplan"
                      ? "85%"
                      : data?.proposal_type === "IV"
                        ? "80%"
                        : "80%",
                }}
                contentEditable suppressContentEditableWarning={true}
              >
                Total
              </div>
              <div
                className="text-center padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  width:
                    data?.proposal_type?.toLowerCase() === "nplan"
                      ? "15%"
                      : data?.proposal_type === "IV"
                        ? "20%"
                        : "20%",
                }}
                contentEditable suppressContentEditableWarning={true}
              >
                {data?.amount || "N/A"}
              </div>
            </div>
            {!!data?.discount && <div className="width-100 display-flex">
              <div
                className="text-right padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  width:
                    data?.proposal_type?.toLowerCase() === "nplan"
                      ? "85%"
                      : data?.proposal_type === "IV"
                        ? "80%"
                        : "80%",
                }}
                contentEditable suppressContentEditableWarning={true}
              >
                Discount
              </div>
              <div
                className="text-center padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  width:
                    data?.proposal_type?.toLowerCase() === "nplan"
                      ? "15%"
                      : data?.proposal_type === "IV"
                        ? "20%"
                        : "20%",
                }}
                contentEditable suppressContentEditableWarning={true}
              >
                {data?.discount || "N/A"}
              </div>
            </div>}
            <div className="width-100 display-flex">
              <div
                className="text-right padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  width:
                    data?.proposal_type?.toLowerCase() === "nplan"
                      ? "85%"
                      : data?.proposal_type === "IV"
                        ? "80%"
                        : "80%",
                }}
                contentEditable suppressContentEditableWarning={true}
              >
                Total Amount before GST (SGST / UGST / CGST / IGST)
              </div>
              <div
                className="text-center padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  width:
                    data?.proposal_type?.toLowerCase() === "nplan"
                      ? "15%"
                      : data?.proposal_type === "IV"
                        ? "20%"
                        : "20%",
                }}
                contentEditable suppressContentEditableWarning={true}
              >
                {data?.discounted_amount || "N/A"}
              </div>
            </div>
            {!!data?.service_tax && <>
              {data?.state?.toLowerCase() != "delhi" ?
                <div className="width-100 display-flex">
                  <div className="text-right padding-6 bold-font" contentEditable suppressContentEditableWarning={true} style={{ borderTop: "1px solid black", width: data?.proposal_type?.toLowerCase() === "nplan" ? "85%" : data?.proposal_type === "IV" ? "80%" : "80%", }}>
                    IGST Value @18% (INR)
                  </div>
                  <div className="text-center padding-6 bold-font" contentEditable suppressContentEditableWarning={true} style={{ borderTop: "1px solid black", borderLeft: "1px solid black", width: data?.proposal_type?.toLowerCase() === "nplan" ? "15%" : data?.proposal_type === "IV" ? "20%" : "20%", }}>{data?.service_tax}</div>
                </div> : <>
                  <div className="width-100 display-flex">
                    <div className="text-right padding-6 bold-font" contentEditable suppressContentEditableWarning={true} style={{ borderTop: "1px solid black", width: data?.proposal_type?.toLowerCase() === "nplan" ? "85%" : data?.proposal_type === "IV" ? "80%" : "80%", }}>
                      SGST Value @9% (INR)
                    </div>
                    <div className="text-center padding-6 bold-font" contentEditable suppressContentEditableWarning={true} style={{ borderTop: "1px solid black", borderLeft: "1px solid black", width: data?.proposal_type?.toLowerCase() === "nplan" ? "15%" : data?.proposal_type === "IV" ? "20%" : "20%", }}>{(Number(data?.service_tax) / 2)?.toFixed(2)}</div>
                  </div>
                  <div className="width-100 display-flex">
                    <div className="text-right padding-6 bold-font" contentEditable suppressContentEditableWarning={true} style={{ borderTop: "1px solid black", width: data?.proposal_type?.toLowerCase() === "nplan" ? "85%" : data?.proposal_type === "IV" ? "80%" : "80%", }}>
                      CGST Value @9% (INR)
                    </div>
                    <div className="text-center padding-6 bold-font" contentEditable suppressContentEditableWarning={true} style={{ borderTop: "1px solid black", borderLeft: "1px solid black", width: data?.proposal_type?.toLowerCase() === "nplan" ? "15%" : data?.proposal_type === "IV" ? "20%" : "20%", }}>{(Number(data?.service_tax) / 2)?.toFixed(2)}</div>
                  </div>
                </>}
            </>}

            <div className="width-100 display-flex">
              <div
                className="text-right padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  width:
                    data?.proposal_type?.toLowerCase() === "nplan"
                      ? "85%"
                      : data?.proposal_type === "IV"
                        ? "80%"
                        : "80%",
                }}
                contentEditable suppressContentEditableWarning={true}
              >
                Total Amount Payable inclusive of GST (SGST / UGST / CGST / IGST)
              </div>
              <div
                className="text-center padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  width: data?.proposal_type?.toLowerCase() === "nplan" ? "15%" : data?.proposal_type === "IV" ? "20%" : "20%",
                }}
                contentEditable suppressContentEditableWarning={true}
              >
                {data?.final_amount || "N/A"}
              </div>
            </div>
            {extraData?.map((item, index) => {
              return <React.Fragment key={index.toString()}>{item}  </React.Fragment>
            })}
          </div>
          <div
            className="width-100  bold-font"
            style={{
              fontSize: "18px", borderBottom: "1px solid black",
              borderLeft: "1px solid black", borderRight: "1px solid black", padding: 10, paddingTop: 20, paddingBottom: 20, marginBottom: 20
            }}
          >
            <table
              width="98%"
              border="0"
              cellSpacing="0"
              cellPadding="6"
              align="center"
              style={{ fontFamily: "Verdana, Arial, Helvetica, sans-serif", fontSize: "11px", margin: '0px auto' }}
            >
              <tbody contentEditable suppressContentEditableWarning={true} >
                <tr>
                  <td style={{ height: "86px" }}>
                    <p>
                      <font color="#666666">
                        PAN No.: <strong>AADCM6333L</strong> <br />
                        GSTIN No.: <strong>07AADCM6333L1ZA</strong> <br />
                        HSN/SAC no. 998439 <br />
                        <br />
                        <span
                          style={{
                            color: "#666666", fontFamily: "Verdana", "msoFareastFontFamily": 'Times New Roman',
                            "msoBidiFontFamily": 'Times New Roman', "msoAnsiLanguage": "EN-US", "msoFareastLanguage": "EN-US",
                            "msoBidiLanguage": "AR-SA"
                          }}
                        >
                          Imagesbazaar is fully owned subsidiary of Mash Audio
                          Visuals Pvt. Ltd.{' '}
                        </span>
                        Usage of images subject to Mash
                        <font color="#666666"></font>&nbsp; Rights Agreement
                        mentioned on{' '}
                        <a
                          href={`${ApiConfig.LIVE_WEBSITE_URL}/licensing`}
                          target="_blank"
                        >
                          {`${ApiConfig.LIVE_WEBSITE_URL}/licensing`}
                        </a>
                      </font>
                    </p>
                  </td>
                </tr>

                <tr>
                  <td>
                    <font color="#666666">
                      This is a computer generated Quotation and does not
                      require any authorised signatory.
                      <br />
                    </font>
                    <span
                      style={{
                        color: "#666666", fontFamily: "Verdana", "msoFareastFontFamily": 'Times New Roman',
                        "msoBidiFontFamily": 'Times New Roman', "msoAnsiLanguage": "EN-US", "msoFareastLanguage": "EN-US",
                        "msoBidiLanguage": "AR-SA", paddingTop: "10px"
                      }}
                    >
                      <br />
                      Kindly send Cheque/Demand Draft in favour of MASH AUDIO
                      VISUALS PVT. LTD. Payable at NEW DELHI to the address
                      given below :{' '}
                    </span>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      <font color="#666666">
                        Mash Audio Visuals Pvt. Ltd.
                        <br />
                        505, Aggarwal Prestige Mall, <br />
                        Plot No.2, Road No.44,,
                        <br />
                        Pitam Pura, New Delhi-110034
                        <br />
                      </font>
                    </b>{' '}
                    <font color="#666666">
                      <a href={ApiConfig.LIVE_WEBSITE_URL} target="_blank">
                        {ApiConfig.LIVE_WEBSITE_URL}
                      </a>
                    </font>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <style>
          {`
          .main-section-invoice {
            width: 100%;
            max-width: 930px;
            margin-left: auto;
            margin-right: auto;
            border: 1px solid black;
          }
          .address-div {
            width: 40%;
          }
          .font-red {
            color: red;
          }
          .padding-6 {
            padding: 6px;
          }
          .width-100 {
            width: 100%;
          }
          .border-1px {
            border: 1px solid black;
          }
          .font-12 {
            font-size: 13px;
          }
          .border-collapse {
            border-collapse: collapse;
          }
          .date-div {
            width: 60%;
          }
          .text-center {
            text-align: center;
          }
          .text-left {
            text-align: left;
          }
          .bold-font {
            font-weight: bold;
          }
          .text-right {
            text-align: right;
          }
          .display-flex {
            display: flex;
          }
          .float-left-50 {
            width: 50%;
            border-top: 0px;
            border-bottom: 1px;
            border-left: 1px;
            border-right: 0px;
            border-style: solid;
            border-color: black;
          }
          .float-right-50 {
            width: 50%;
            border-top: 0px;
            border-bottom: 1px;
            border-left: 1px;
            border-right: 0px;
            border-style: solid;
            border-color: black;
          }
          .full-100 {
            width: 100%;
            border-left: 1px;
            border-top: 0px;
            border-right: 0px;
            border-bottom: 0px;
            border-style: solid;
            border-color: black;
          }
          .party-name {
            width: 33%;
            border-top: 1px;
            border-right: 1px;
            border-bottom: 1px;
            border-left: 0px;
            border-style: solid;
            border-color: black;
          }
          .client-name {
            width: 34%;
            border-top: 1px;
            border-right: 1px;
            border-bottom: 1px;
            border-left: 0px;
            border-style: solid;
            border-color: black;
          }
          .qr-code {
            width: 33%;
            border-top: 1px;
            border-right: 0px;
            border-bottom: 1px;
            border-left: 0px;
            border-style: solid;
            border-color: black;
          }
          .word-break{
            word-break: break-word;
          }
          @media print {
            body {
              -webkit-print-color-adjust: exact;
              -moz-print-color-adjust: exact;
              print-color-adjust: exact;
            }
          }
          `}
        </style>
      </Box>
    </>
  );
})