import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { DATA_LIMIT, STATUS_200, STATUS_300 } from '../data/constants';
import { setAllVideosListing, setAllVideosListingError, setAllVideosListingLoading, setAllVideosNewListing, setAllVideosNewListingError, setAllVideosNewListingLoading } from '../redux/videos';

export const videosListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllVideosListingLoading(true));
        const resp = await axios.post(`${ApiConfig.allVideoslist}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllVideosListing(resp.data.data));
          dispatch(setAllVideosListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllVideosListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllVideosListingLoading(false));
      }
    };
  }
};

export const videosListingsNew = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllVideosListingLoading(true));
        const resp = await axios.post(`${ApiConfig.allVideoslist}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllVideosNewListing(resp.data.data));
          dispatch(setAllVideosNewListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllVideosNewListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllVideosListingLoading(false));
      }
    };
  }
};

export const insertVideos = async (payload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.insertVideo}`, payload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return resp.data;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
export const insertVideoKeywords = async (payload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.repairKeywordsVideos}`, payload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return resp.data;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const insertIntoElasticSearch= async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.insertElasticsearch}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const insertIntoElasticSearchAll= async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.insertBulkElasticSearch}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const updateVideo = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.editVideo}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp.data;
    } else {
      throw resp;
    }
    // return true
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const getListOfVideoKeywords=async(payload)=>{
  try{
    const resp=await axios.get(`${ApiConfig?.videoKeyword}/${payload}`);
    if(resp?.status>=STATUS_200 && resp?.status<STATUS_300){
       return resp?.data;
    }
  }
  catch(e){
    toast.error(errorMessage(e),{autoClose:2500})
  }
}

export const linkKeywordToVideoNew = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.videoKeywordNew}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp?.data?.message,{
        autoClose: 2500
      })
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
}

export const suspendVideo = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.suspendVideo}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return true;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const updateVideoLive = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.updateVideo}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp.data;
    } else {
      throw resp;
    }
    // return true
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
//videoKeyword


