import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import ImageTabBody from '../../Components/Image/ImageTabBody';
import { ImageData } from '../../data/imageData';
import AddImages from '../../Components/Image/AddImages';
import UploadCsvModal from '../../Components/Modals/UploadCsvModal';
import { Typography } from '@mui/material';
import AllImagesNew from '../../Components/Image/AllImagesNew';

function AddImage({ handleNavParams, handleCsvModal, uploadCsvModal }) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };
  return (
    <>
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={5}>
        {/* <CustomTabs tabList={ImageData?.tabs} tabClick={handleTabs} /> */}
        {/* <button onClick={()=>{
         handleNavParams({
          navTitle: "Book Order"
         })
       }}>Hello</button> */}
        {/* <AddImages/> */}
        <Typography variant='h3'>Please click on Upload CSV then select a (.csv) file to upload images.</Typography>
      </CustomBox>
      {/* <Divder spacing={1} />
      <ImageTabBody activeTab={tabValue} /> */}
      <Divder spacing={1.2} />
      
      <UploadCsvModal open={uploadCsvModal} handleOpen={handleCsvModal}/>
    </Box>
    <AllImagesNew />
   
    </>
  );
}

export default AddImage;
