import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatDate, getObjectSubset } from '../../lib/helper';
import { categoryItemsSelector } from '../../redux/category';
import { categoryListings, deleteSubCategory, subCategoryListings } from '../../Services/categoryApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import AddEditSubCategoryModal from '../Modals/AddEditSubCategoryModal';
import ConfirmationModal from '../Modals/ConfirmationModal';
import MatTable from '../Tables/MatTable';

const limit = 10;

const column = [
  {
    label: 'S.No.',
    id: 'sno',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Ref ID',
    id: 'ref_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Name',
    id: 'name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Display Name',
    id: 'display_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 400, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Search ID',
    id: 'search_id',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Sort ID',
    id: 'sort_id',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Type',
    id: 'type',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  }
];

function SubCategoryList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState({});
  const { subCategoryList } = useSelector(categoryItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const categoryId = searchParams?.get('category_id')
  const [addSubCategoryModal, setAddSubCategoryModal] = useState(false);
  const [editSubCategoryModal, setEditSubCategoryModal] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [confirmDeleteSubCategory, setConfirmDeleteSubCategory] = useState(false);
  const [deleteSubCategoryData, setDeleteSubCategoryData] = useState({});

  if (!categoryId) {
    navigate('/category', { replace: true })
  }

  const handleAdd = (value = false) => {
    setAddSubCategoryModal(value)
  }
  const handleEdit = (value = false) => {
    setEditSubCategoryModal(value)
  }


  const getListing = async (params = {}) => {
    const payload = {
      category_id: categoryId,
      page: String(1),
      limit: limit,
      ...allParams,
      ...params
    }
    dispatch(subCategoryListings(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  //making data format for table input
  useEffect(() => {
    if (subCategoryList?.items?.data?.length >= 0) {
      setTableListing(subCategoryList?.items?.data)
    }
  }, [subCategoryList.items]);

  const handleConfirmDeleteSubCategory = async (value) => {
    if (value && deleteSubCategoryData) {
      const resp = await deleteSubCategory({ subcategory_id: deleteSubCategoryData });
      if (resp) {
        getListing({ page: '1' })
        setPage(1)
      }
    } else {
      setDeleteSubCategoryData(null)
    }
  }

  const handleCofirmModalOpen = (value) => {
    setConfirmDeleteSubCategory(value);
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            setSelectedSubCategory(data)
            handleEdit(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit /></CustomButton>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            handleCofirmModalOpen(true)
            setDeleteSubCategoryData(data?.sno)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete /></CustomButton>
        </div>
      </>
    );
  };

  return (
    <>
      <MatTable
        loading={subCategoryList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={subCategoryList?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolBarButton={() => {
          return <>
            <CustomButton onClick={() => { handleAdd(true) }} color={'buttonPrimary'} sx={(theme) => ({
              padding: '10px 10px', marginRight: 1,
              [theme.breakpoints.down("md")]: {
                flex: 1,
              }
            })}>Add Sub-Category</CustomButton>
          </>
        }}
        toolbarEnable={true}
        pagination={true}
        filterEnable
        rangeDateFilter
      />
      {addSubCategoryModal && <AddEditSubCategoryModal open={addSubCategoryModal} setPage={setPage} type={'add'} handleOpen={handleAdd} />}
      {confirmDeleteSubCategory && <ConfirmationModal open={confirmDeleteSubCategory} handleConfirm={handleConfirmDeleteSubCategory} setOpen={handleCofirmModalOpen} />}
      {editSubCategoryModal && <AddEditSubCategoryModal open={editSubCategoryModal} setPage={setPage} handleOpen={handleEdit} type={'edit'} data={selectedSubCategory} setSelectedSubCategory={setSelectedSubCategory} />}
    </>
  );
}

export default SubCategoryList;
