import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import ProposalQuotation from '../ProposalManagement/ProposalQuotation';
import { getImageInfo } from '../../Services/commonService';
import ApiConfig from '../../config/ApiConfig';
import { Close } from '@mui/icons-material';
import { formatDate } from '../../lib/helper';

function ImageDetailModal({ open, handleOpen, imageName }) {
  const [imageDetail, setImageDetail] = useState(null)

  const handleDetail = async(imageName) =>{
    let type='1';
    if(imageName){
      if(imageName?.substring(0,2)==='VD')type=2;
      let resp = await getImageInfo({name:imageName,type:type})
      if(resp){
        setImageDetail(resp)
      }else{
        setImageDetail(null)
      }
    }
  }

  useEffect(()=>{
    handleDetail(imageName)
  },[imageName])

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          handleOpen(false);
          setTimeout(()=>{
            setImageDetail(null)
          }, 100)
        }}
      >
        {imageDetail && <DialogTitle variant="h3" padding={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          Image Preview <Close sx={{cursor: "pointer"}} onClick={() => {
            handleOpen(false)
            setTimeout(()=>{
              setImageDetail(null)
            }, 100)
          }}/>
        </DialogTitle>}
        <DialogContent>
          <Box>
            {!imageDetail ? <Typography>Loading...</Typography> : <Box>
              <img src={imageDetail?.url}
                style={{
                  maxHeight: 600,
                  maxWidth: '100%',
                  marginBottom: -8
                }}
              />
              <div style={{display: 'flex', justifyContent: "space-between", backgroundColor: "#000000db", padding: 10}}>
                <Typography sx={{color: "#fff"}}>{imageDetail?.contributor}</Typography>
                <Typography sx={{color: "#fff"}}>{imageDetail?.Name}</Typography>
              </div>
            </Box>}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ImageDetailModal;
