import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { editIvsDownloadOrder } from '../../Services/OrderApis';
const limit = 10;

function EditIvsDownloadClientModal({ open, handleOpen, data, getListing }) {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await editIvsDownloadOrder(values)
    if (resp) {
      resetForm({
        client: "",
        ivs_download_id: "",
      })
      getListing()
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      client: data?.client || "",
      ivs_download_id: data?.ivs_download_id || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      client: Yup.string().required('Client is required.'),
      ivs_download_id: Yup.string().required('IVS download id is required.')
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant='h3'>Edit Client Name</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="ivs_download_id"
                  label="IVS Download Id"
                  placeholder="IVS Download Id"
                  value={formik.values.ivs_download_id}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.ivs_download_id && Boolean(formik.errors.ivs_download_id)}
                  helperText={formik.touched.ivs_download_id && formik.errors.ivs_download_id}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="client"
                  label="Client"
                  placeholder="Client"
                  value={formik.values.client}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.client && Boolean(formik.errors.client)}
                  helperText={formik.touched.client && formik.errors.client}
                />
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Confirm</CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    client: "",
                    ivs_download_id: "",
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >Cancel</CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditIvsDownloadClientModal;
