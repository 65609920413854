import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mainLoading: false,
  allIReports: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  privillageReport: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setResetDailyReportData:(state,action)=>{
      state.allIReports.items = {};
      state.allIReports.error = {
        isError: false,
        message: '',
      };
      state.allIReports.loading = false
    },
    setMainLoading: (state, action) => {
      state.mainLoading = action.payload;
    },
    setAllReportsListing: (state, action) => {
      state.allIReports.items = action.payload;
      state.allIReports.error = {
        isError: false,
        message: '',
      };
    },
    setAllReportsListingLoading: (state, action) => {
      state.allIReports.loading = action.payload;
    },
    setAllReportsListingError: (state, action) => {
        state.allIReports.items = {};
        state.allIReports.error = action.payload;
      },

      setAllPrivilegeListing: (state, action) => {
        state.privillageReport.items = action.payload;
        state.privillageReport.error = {
          isError: false,
          message: '',
        };
      },
      setAllPrivilegeListingLoading: (state, action) => {
        state.privillageReport.loading = action.payload;
      },
      setAllPrivilegeListingError: (state, action) => {
          state.privillageReport.items = {};
          state.privillageReport.error = action.payload;
        },
  },
});

export default reportSlice.reducer;
// Action creators are generated for each case reducer function
export const {
  setMainLoading,
  setAllReportsListing,
  setAllReportsListingError,
  setResetDailyReportData,
  setAllPrivilegeListing,
  setAllPrivilegeListingLoading,
  setAllPrivilegeListingError
  
} = reportSlice.actions;

export const reportListingItemsSelector = (state) => state.reportList;
