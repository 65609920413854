import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import UserCompanyDetail from './userCompanyDetail'
function UserCompanyDetailsContainer() {
  return (
    <MainLayout navTitle={"User OverView"} navSubTitle={"Welcome to ImagesBazaar"}>
      <UserCompanyDetail />
    </MainLayout>
  )
}

export default UserCompanyDetailsContainer