import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { TableCell, Typography } from '@mui/material';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import MatTable from '../../Components/Tables/MatTable';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import { OrderListing } from '../../Services/OrderApis';
import AppSettingsBody from '../../Components/AppSettings/AppSettingsBody';
import { appSettingsData } from '../../data/appSettingsData';
import CustomTabExpand from '../../Components/CommonComp/CustomTabs/CustomTabExpand';

function AppSettings({ handleNavParams }) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={0}>
        {/* <CustomTabs tabList={appSettingsData.tabs} tabClick={handleTabs} /> */}
        <CustomTabExpand tabList={appSettingsData.tabs} tabClick={handleTabs} />

        {/* <button onClick={()=>{
         handleNavParams({
          navTitle: "Book Order"
         })
       }}>Hello</button> */}
      </CustomBox>
      <Divder spacing={1} />
      <AppSettingsBody activeTab={tabValue} />
      <Divder spacing={1.2} />
    </Box>
  );
}

export default AppSettings;
