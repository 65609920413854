import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
// import { recoverPassword, ResetPassword } from "../../../services/ForgotPassword";
import { useDispatch } from "react-redux";
import LoginBackgorund from "../../Asset/image/login-1440.png";
import LoginVectorUp from '../../Asset/image/login_icon2.png';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as IBMainLogo } from "../../Asset/svg/ib_logo.svg";
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import { LoginEye, LoginEyeOff, LoginLock,AppLogoNew  } from '../../Asset/svg';
import CustomButton from "../../Components/CommonComp/Buttons/CustomButton";
import { recoverPassword } from "../../Services/LoginApi";
import { BackgroundImageNoRedux } from "../../Services/commonService";

const ResetPasswordComponent = () => {
  const dispatch = useDispatch();
  const [isNewPassVisible, setIsNewPassVisible] = useState(false);
  const [isConfPassVisible, setIsConfPassVisible] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [image,setImage]=useState('');
  const [searchParams] = useSearchParams();
  const token = searchParams?.get('token');


  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };
  
  const handleClickShowPassword = (state, setState) => {
    setState(!state);
  };

  useEffect(()=>{
    backgrorundImageData();
  },[])

  const backgrorundImageData = async () =>{
    let data = await BackgroundImageNoRedux()  ;
    if(data){
      setImage(data?.key_value)
  }
}

  const handleResetPassword = async (values, { resetForm }) => {
    let resp = await recoverPassword({
      password: values.new_password,
      accesstoken: token,
    });

    if(resp){
      resetForm({
        new_password: "",
        confirm_password: "",
      });
      navigate('/login')
    }
  };

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .required("new password is required!")
        .max(16, "password should be less then 16 characters")
        .min(8, "password should be more then 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "must contain 8 characters,1 uppercase, 1 lowercase,1 number and 1 special case character"
        ),
      confirm_password: Yup.string()
        .required("Please enter confirm password!")
        .when("new_password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("new_password")],
            "both password need to be the same"
          ),
        }),
    }),
    onSubmit: handleResetPassword,
  });

  useEffect(()=>{
    if(!token){
    //  navigate('/signin', {replace: true})
    }
  },[token])

  return (
    <>
      <img
        // src={LoginBackgorund}
        src={image?image:LoginBackgorund}
        alt="login_backgorund"
        className="login-background"
      />
      <div className="main-login-page">
        <div className="login-section">
          <div className="login-div">
          <img src={LoginVectorUp} className="login-vector-up" />
            {/* <img src={LoginVectorDown} className="login-vector-down" /> */}
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className="login-logo-section">
              <Link to="/">
                  {/* <img src={MainLogo} className="login-logo-image" /> */}
                  <AppLogoNew style={{
                    maxWidth: 210,
                    maxHeight: 45,
                    width: '100%'
                  }} />
                </Link>
              </div>
              <Typography
                component="div"
                variant="mainpagetitle"
                className="title-login secondaryColor boldFont"
              >
                Reset your password
              </Typography>
              <div className="login-main-form">
                <div className="login-form-content login-space-divider">
                  <TextField
                    label="New Password"
                    className="main-login-form"
                    fullWidth
                    id="new_password"
                    name="new_password"
                    type={isNewPassVisible ? "text" : "password"}
                    placeholder="New Password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LoginLock className="loginform-field-icons" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            className="BaseIconButton"
                            onClick={() => {
                              handleClickShowPassword(
                                isNewPassVisible,
                                setIsNewPassVisible
                              );
                            }}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {isNewPassVisible ? (
                              <LoginEyeOff className="loginform-field-icons login-eye-icon" />
                            ) : (
                              <LoginEye className="loginform-field-icons login-eye-icon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={formik.values.new_password}
                    onChange={(e) => {
                      formik.setFieldValue("new_password", e.target.value);
                    }}
                    error={
                      formik.touched.new_password &&
                      Boolean(formik.errors.new_password)
                    }
                    helperText={
                      formik.touched.new_password && formik.errors.new_password
                    }
                    autoComplete={"new-password"}
                  />
                </div>
                <div className="login-form-content login-space-divider">
                  <TextField
                    label="Confirm Password"
                    className="main-login-form"
                    fullWidth
                    id="confirm_password"
                    name="confirm_password"
                    type={isConfPassVisible ? "text" : "password"}
                    placeholder="Confirm Password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LoginLock className="loginform-field-icons" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            className="BaseIconButton"
                            onClick={() => {
                              handleClickShowPassword(
                                isConfPassVisible,
                                setIsConfPassVisible
                              );
                            }}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {isConfPassVisible ? (
                              <LoginEyeOff className="loginform-field-icons login-eye-icon" />
                            ) : (
                              <LoginEye className="loginform-field-icons login-eye-icon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    value={formik.values.confirm_password}
                    onChange={(e) => {
                      formik.setFieldValue("confirm_password", e.target.value);
                    }}
                    error={
                      formik.touched.confirm_password &&
                      Boolean(formik.errors.confirm_password)
                    }
                    helperText={
                      formik.touched.confirm_password &&
                      formik.errors.confirm_password
                    }
                    autoComplete={"confirm_password"}
                  />
                </div>
              </div>
              {/* <button type="submit" className="change-password-submit">Submit</button> */}
              <div className="signin-button-main">
              <CustomButton
                  fullWidth
                  sx={{
                    padding: '20px 10px',
                    marginRight: 1,
                    fontSize: '18px',
                    bgcolor: '#000',
                    color: 'buttonSecondary.contrastText',
                    '&:hover':{
                      bgcolor: '#d6d6d6',
                      color: '#000',
                    }
                  }}
                  loading={loading}
                  type="submit"
                >
                  Sign In
                </CustomButton>
              </div>
              <Typography
                component="div"
                variant="mainsectiontitle"
                sx={{ marginTop: "20px" }}
                className=" secondaryColor boldFont alignText-center"
              >
                
                <Typography
                  component="span"
                  variant="mainsectiontitle"
                  className="animationLink"
                  onClick={() => navigate("/login", {replace: true})}
                >
                  Back to login
                </Typography>
              </Typography>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordComponent;
