import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import DailySaleFollowupHistory from './DailySaleFollowupHistory'

function DailySaleFollowupHistoryContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "Daily Sales History",
    navSubTitle: "Welcome to ImagesBazaar"
  })
  const handleNavParams = (obj)=>{
    setNavParams({
        ...navParams,
        ...obj
    })
  }
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
      <DailySaleFollowupHistory handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default DailySaleFollowupHistoryContainer