import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, MenuItem, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { formatDate, handleFileReader } from '../../lib/helper';
import { fileUpload } from '../../Services/commonService';
import { uploadWeeklyImages } from '../../Services/DashboardApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import { ImageSelectData } from '../../data/imageData';
import { imageListingsNoRedux, updateImage } from '../../Services/imageApis';
import ApiConfig from '../../config/ApiConfig';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import PreviewImageComp from '../ProposalManagement/PreviewImageComp';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { Favorite } from '@mui/icons-material';
import { commonItemsSelector } from '../../redux/common';
import { useSelector } from 'react-redux';
import { editInvoiceItemOrder } from '../../Services/OrderApis';

export default function EditInvoiceItems({ open, handleOpen, data, getListing }) {
  const [loading, setLoading] = React.useState(false);
  const [imagesData, setImagesData] = React.useState(null);
  const { appSetting } = useSelector(commonItemsSelector);
  const [imagesDataError, setImagesDataError] = React.useState({
    error: false,
    helperText: "Please select image quality for item"
  });

  const handleUpload = async (values, { resetForm }) => {
    try {
      if (imagesData) {
        let isValid = handleImageConfirm([imagesData])
        if (isValid) {
          setLoading(true)
          let payload = {
            quality: imagesData.quality,
            type: values.type,
            dimension: imagesData?.dimension,
            new_image_name: imagesData?.image_name,
            old_image_name: data?.image_name,
            order_id: data?.order_id,
          }
          let resp = await editInvoiceItemOrder(payload)
          if (resp) {
            toast.success(resp?.message)
            getListing();
            handleOpen(false);
          }
          setLoading(false);
          resetForm();
        }
      } else {
        setImagesDataError({
          ...imagesDataError,
          error: true,
          helperText: "Please select item"
        })
      }
    } catch (e) {
      setLoading(false)
      console.log(e, 'error')
    }
  }

  const formik = useFormik({
    initialValues: {
      type: "image",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      type: Yup.string().required('Type is required.'),
    }),
    onSubmit: handleUpload,
  });

  const getImageListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      let data = await imageListingsNoRedux({ search: inputValue, type: formik.values.type })
      let updatedData = data?.map((item, index) => {
        return {
          value: item?.Name ? item?.Name : item?.video_name,
          label: item.Name ? item.Name : item?.video_name,
          rank: item.rank,
          sno: item?.sno ? item?.sno : item?.video_id,
          shootid: item.shootid,
          image_name: item?.Name ? item?.Name : item?.video_name,
          quality: null,
          price: null,
          dimension: null,
          type: item?.Name ? 'image' : 'video',
        }
      })

      return updatedData || []
    }
  }

  const handleUpdateImagesData = (data, index) => {
    let initialData = { ...imagesData }
    initialData = {
      ...initialData,
      ...data
    }
    setImagesData(initialData);

    if (initialData) {
      let isValid = handleImageConfirm([initialData])
      if (isValid) {
        setImagesDataError({
          ...imagesDataError,
          error: false,
          helperText: "Please select item"
        })
      }
    } else {
      setImagesDataError({
        ...imagesDataError,
        error: true,
        helperText: "Please select item"
      })
    }
  }

  const checkValidation = (data) => {
    let isValid = true;
    for (var i = 0; i < data?.length; i++) {
      let flag = Object?.keys(data[i])?.every((k) => data[i][k] != null && data[i][k] != undefined && data[i][k] != '')
      if (!flag) {
        isValid = false;
        break;
      }
    }
    return isValid
  }

  const handleImageConfirm = (data) => {
    try {
      let isValid = false;
      if (data?.length) {
        isValid = checkValidation(data)
        setImagesDataError({
          ...imagesDataError,
          error: !isValid,
          helperText: "Please select image quality for item"
        })
      } else {
        setImagesDataError({
          ...imagesDataError,
          error: !isValid,
          helperText: "Please select item"
        })
      }
      return isValid
    } catch (e) { }
  }

  return (
    <>
      <Dialog
        maxWidth={'lg'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
        fullWidth
      >
        <DialogTitle variant='h3'>Edit Item</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // m: 'auto',
              // width: 'fit-content',
              // minWidth: '800px'
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} md={4} sx={(theme) => ({
                borderRight: "2px solid #000",
                paddingRight: 10,
                paddingTop: 0,
                [theme.breakpoints.down("md")]: {
                  borderBottom: "2px solid #000",
                  borderRight: "0px solid #000",
                  paddingRight: 0,
                }
              })}>
                <Box style={{ padding: 10 }}>
                  <CustomImage
                    style={{ maxWidth: '100%', maxHeight: 300, borderRadius: 8 }}
                    src={`${ApiConfig?.BASE_IMAGE_URL}${data?.image?.shootid}/${data?.image?.rank}-${data?.type === '1' ? data?.image?.Name : data?.image?.video_name}.jpg`}
                  />
                  <div>
                    <div>
                      <Typography variant='h5' component={"span"}>Name:</Typography> <Typography variant='h5' component={"span"}>{data?.image_name}</Typography>
                    </div>
                    <div>
                      <Typography variant='h5' component={"span"}>Dimension:</Typography> <Typography variant='h5' component={"span"}>{data?.dimension}</Typography>
                    </div>
                    <div>
                      <Typography variant='h5' component={"span"}>Quality:</Typography> <Typography variant='h5' component={"span"}>{data?.quality}</Typography>
                    </div>
                    <div>
                      <Typography variant='h5' component={"span"}>Price:</Typography> <Typography variant='h5' component={"span"}>{data?.price}</Typography>
                    </div>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: "center", alignItems: "start", flexWrap: "wrap" }}>
                  <Box style={{ marginRight: 10, flex: 1 }}>
                    <CustomAsyncSelect
                      promiseOptions={getImageListing}
                      closeMenuOnSelect={false}
                      controlStyle={{
                        padding: 5
                      }}
                      cacheOptions={false}
                      value={imagesData}
                      handleChange={(e) => {
                        setImagesData(e);
                        handleImageConfirm([e])
                      }}
                      width={'100%'}
                      dropDownZIndex={100000}
                      labelShrink={true}
                      label="Search"
                      isMulti={false}
                      error={imagesDataError.error}
                      helperText={imagesDataError.helperText}
                    />
                  </Box>
                  <Box sx={(theme) => ({
                    [theme.breakpoints.down("md")]: {
                      flexBasis: "100%",
                      mt: 1,
                    }
                  })}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      select
                      SelectProps={{
                        native: true,
                        style: {
                          height: 48
                        }
                      }}
                      name="type"
                      label="Type"
                      placeholder="Type"
                      value={formik.values.type}
                      onChange={(e) => {
                        setImagesData(null)
                        formik.handleChange(e)
                      }}
                      style={{ maxWidth: 150 }}
                    >
                      <option key={'image'} value="image">Image</option>
                      <option key={'video'} value="video">Video</option>
                    </CustomInput>
                  </Box>
                </Grid>
                <Box>
                  <Box className="border-1 rounded-3" mt={2} px={2} py={1} minHeight={150}>
                    <Typography variant="h6">Preview</Typography>
                    {imagesData ?
                      <Box sx={(theme) => ({
                        display: "flex", overflow: "hidden", overflowX: "auto", pt: 0.5,
                        [theme.breakpoints.down("sm")]: {
                          flexDirection: "column"
                        }
                      })}>
                        <CustomImage
                          style={{ width: '100%', maxWidth: 190, borderRadius: 8 }}
                          src={`${ApiConfig.BASE_IMAGE_URL}${imagesData?.shootid}/${imagesData?.rank}-${imagesData?.image_name}.jpg`}
                        />
                        <div style={{ paddingLeft: 10 }}>
                          <div>
                            <Typography variant='h5' component={"span"}>Name:</Typography> <Typography variant='h5' component={"span"}>{imagesData?.image_name}</Typography>
                          </div>
                          <div>
                            <Typography variant='h5' component={"span"}>Dimension:</Typography> <Typography variant='h5' component={"span"}>{imagesData?.dimension}</Typography>
                          </div>
                          <div>
                            <Typography variant='h5' component={"span"}>Quality:</Typography> <Typography variant='h5' component={"span"}>{imagesData?.quality}</Typography>
                          </div>
                          <div>
                            <Typography variant='h5' component={"span"}>Price:</Typography> <Typography variant='h5' component={"span"}>{imagesData?.price}</Typography>
                          </div>

                          {(formik.values.type?.toLowerCase() == 'video' ? appSetting?.items?.subscriptions?.singleVideoPrice : appSetting?.items?.subscriptions?.singleImagePrice)?.map((itemData, i) => {
                            return (
                              <CustomButton
                                className="buttonDense"
                                sx={{
                                  fontSize: '11px !important',
                                  padding: '1px 10px !important',
                                  marginTop: 1,
                                  marginBottom: 1,
                                  minWidth: 'auto !important',
                                  bgcolor: '#ddd',
                                  // bgcolor: itemData?.quality == itemData?.image_type ? 'buttonSecondary.main' : 'red'
                                  ...(imagesData?.quality == (itemData?.image_type || itemData?.video_type) && {
                                    bgcolor: 'buttonSecondary.main',
                                  }),
                                  ...(imagesData?.quality == (itemData?.image_type || itemData?.video_type) && {
                                    color: 'buttonSecondary.contrastText',
                                  }),
                                }}
                                key={i.toString()}
                                onClick={() => {
                                  handleUpdateImagesData({
                                    price: itemData?.price,
                                    quality: itemData?.image_type || itemData?.video_type,
                                    dimension: formik.values.type?.toLowerCase() == 'video' ? itemData?.Dimensions : `${itemData?.Dimensions} at ${itemData?.resolution}`
                                  }, i);
                                }}
                              >
                                {itemData?.image_type || itemData?.video_type}
                              </CustomButton>
                            );
                          })}
                        </div>
                      </Box> : <Typography variant="p" component={'p'} className="fontWeight500" textAlign={"center"} pt={4}>No Data Available</Typography>}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <div style={{ display: "flex", justifyContent: "end", marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Save</CustomButton>
              <CustomButton onClick={() => handleOpen(false)} disabled={loading}>Close</CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
