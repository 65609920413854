import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import ShootsImages from './ShootsImages'

function ShootsImageMain() {
  return (
    <MainLayout navTitle={"Model Images"} navSubTitle={"Welcome to ImagesBazaar"}>
        <ShootsImages/>
    </MainLayout>
  )
}

export default ShootsImageMain;

