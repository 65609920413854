import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import ImageTabBody from '../../Components/Image/ImageTabBody';
import { ImageData } from '../../data/imageData';

function Image({ handleNavParams }) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={0}>
        
        <CustomTabs tabList={ImageData?.tabs} tabClick={handleTabs} />
        {/* <button onClick={()=>{
         handleNavParams({
          navTitle: "Book Order"
         })
       }}>Hello</button> */}
      </CustomBox>
      <Divder spacing={1} />
      <ImageTabBody activeTab={tabValue} />
      <Divder spacing={1.2} />
    </Box>
  );
}

export default Image;
