import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import * as Yup from 'yup';
import { getInitialDataFormik, humanize } from '../../lib/helper';
import AppSettingsFields from './AppSettingsFields';
import { Grid } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';

const getInitialDataFormikVlidationSchema = (arr, key)=>{
  let validations = {}
  if(arr.length){
    arr.forEach(element => {
      validations[element[key]] =  Yup.string().required(`Please enter ${humanize(element[key])}`)
    });
  }
  return validations
}

function S3Bucket({data}) {  
 
  const handleDataSubmit = async (values, { resetForm }) => {
    try {
      console.log(values)
    } catch (e) {
      console.log(e, 'error');
    }
  };
  
  const formik = useFormik({
    initialValues: getInitialDataFormik(data, 'key_name', 'key_value'),
    enableReinitialize: true,
    validationSchema: Yup.object({...getInitialDataFormikVlidationSchema(data, 'key_name')}),
    onSubmit: handleDataSubmit,
  });

  return (
    <>
      <CustomBox padding={3} sx={{minHeight: 200}}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            {
              data?.map((item, index)=>{
                return <Grid item xs={12} sm={6} md={4} key={item.key_name}>
                  <AppSettingsFields  
                    name={`${item?.key_name}`}
                    label={`${humanize(item?.key_name)}`}
                    placeholder={`Enter ${humanize(item?.key_name)} Email`}
                    value={formik.values[item.key_name]}
                    onChange={(e) => {
                      formik.setFieldValue(item.key_name, e.target.value);
                    }}
                    error={
                      formik.touched[item.key_name]&& Boolean(formik.errors[item.key_name])
                    }
                    helperText={
                      formik.touched[item.key_name]&& formik.errors[item.key_name]
                    }
                  />
                </Grid>
              })
            }
            <Grid item xs={12} textAlign={'right'}>
                <CustomButton type="submit" color={'buttonPrimary'} sx={{ padding: '8px 20px' }}>
                  Save
                </CustomButton>
            </Grid>
          </Grid>
        </form>
      </CustomBox>
    </>
  );
}

export default S3Bucket;
