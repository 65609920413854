import { Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Delete } from '../../Asset/svg';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT } from '../../data/constants';
import { crypt, formatDate } from '../../lib/helper';
import { deleteFromWishlist, userWishlistApi } from '../../Services/userApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
// import ProposalQuotationModal from '../ProposalManagement/ProposalQuotationModal';
import MatTable from '../Tables/MatTable';

const limit = DATA_LIMIT - 5;

const column = [
  {
    label: 'Proposal ID',
    id: 'proposal_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData}</span>)
  },
  {
    label: 'User ID',
    id: 'user_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}> {columnData}</span>)
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
        <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Created Date',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{formatDate(columnData) || 'N/A'}</span>
    )
  },
  {
    label: 'No. of Images/Video',
    id: 'total_images',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData}</span>
    )
  },
  {
    label: 'Amount(INR)',
    id: 'amount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Discount(INR)',
    id: 'discount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData}</span>
    )
  },
  {
    label: 'Tax(INR)',
    id: 'service_tax',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Total Amount(INR)',
    id: 'final_amount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>
    )
  },
];

function UserProposalData({proposalData, proposalValue, getProposalList}) {
  const [tableListing, setTableListing] = useState([])
  const [open, setOpen] = useState(false);
  const [proposalId, setProposalId] = useState("");
  const [page, setPage] = useState(1);
  const theme = useTheme();
  useEffect(() => {
    if(proposalValue?.length >= 0){
      setTableListing(proposalValue)
    }
  }, [proposalValue]);

  const handleOpen = (value, id = "") => {
    setProposalId(id);
    setOpen(value);
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{display: "flex"}}>
          {/* <CustomButton className="buttonDense" onClick={()=>{
            handleOpen(true, data?.proposal_id)
          }} sx={{ bgcolor: 'buttonInfo.main' }}>View</CustomButton> */}
          <CustomButton className="buttonDense" sx={{ bgcolor: 'buttonInfo.main' }}>
            <Link className='textDecoration-none' to={`/quotation?proposal_id=${crypt('proposal_id', data?.proposal_id)}`} 
              target={'_blank'} style={{color: theme.palette.primary.contrastText}}>View</Link></CustomButton>
          {/* <CustomButton className="buttonDense" sx={{ fontSize: '12px', width: '135px' }}>VIEW FOLLOWUPS</CustomButton> */}
          <CustomButton className="buttonDense" sx={{ fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>Mail</CustomButton>
          <CustomButton variant="raised" sx={{ padding: '1px 1px', marginRight: 1, fontSize: '12px', bgcolor: '#fff0', minWidth: 20 }}><Delete/></CustomButton>
        </div>
      </>
    );
  };


  return (
    <>
		 	<Typography variant='h3' sx={{padding: (theme)=>theme.spacing(3)}}>User Proposals</Typography>
      <MatTable
        showCheckbox={false}
        columns={column}
        data={tableListing}
        action={actionBody}
        page={page}
        perPage={limit}
        total={proposalData?.totalCount || 0}
        setPage={setPage}
        handlePageChange={(newPage)=>{
          getProposalList({
            page: String(newPage)
          }, setPage)
        }}
        pagination={true}
      />
      {/* <ProposalQuotationModal open={open} handleOpen={handleOpen} proposalId={proposalId} /> */}
    </>
  );
}

export default UserProposalData;
