import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { deleteApiPayload, errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { resetShortCompanyData, setMasterListing, setMasterListingError, setMasterListingLoading, setShortCompanyDetail, setShortCompanyDetailError, setShortCompanyDetailLoading } from '../redux/master';

export const MasterListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setMasterListingLoading(true));
        const resp = await axios.post(
          `${ApiConfig.earningByCompany}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setMasterListing(resp.data.data));
          dispatch(setMasterListingLoading(false));
          
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setMasterListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setMasterListingLoading(false));
      }
    };
  }
};

export const MasterListingsNew = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setShortCompanyDetailLoading(true));
        const resp = await axios.post(
          `${ApiConfig.earningByCompany}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setShortCompanyDetail(resp.data.data));
          dispatch(setShortCompanyDetailLoading(false));
         
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setShortCompanyDetailError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setMasterListingLoading(false));
      }
    };
  }
};



