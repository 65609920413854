import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { addCompanyMaster, companyGroupListingsNoRedux, companyListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { addSubscriptionPlan, subscriptionListings } from '../../Services/SubscriptionApis';
import { formatDate, validDate } from '../../lib/helper';
import { addPaymentForImage, editPaymentForImage } from '../../Services/ContributorApis';

const limit = DATA_LIMIT;

function EditPaymentForImages({data, open, handleOpen, setPage, handleLast}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await editPaymentForImage(values);
    if (resp) {
      resetForm({
        contributor_id: '',
        description: "",
        amount_paid: "",
        paid_date: "",
        order_id:"",
        image_id:"",
        payment_id:""
      })
      setPage(1)
      handleLast();
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      payment_id:data?.payment_id|| '',
      contributor_id: data?.contributor_id ||'',
      description: data?.description || "",
      amount_paid: data?.amount_paid || "",
      paid_date: data?.paid_date || "",
      order_id: data?.order_id || "",
      image_id: data?.image_id || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      amount_paid: Yup.string().required('Amount is required.').matches(/^[0-9]+$/, "Must be only digits"),
      description: Yup.string().required('Description is required.'),
      paid_date: Yup.string().required('Paid Date is required.'),
      contributor_id: Yup.string().required("Contributor Id is Required."),
      image_id:Yup.string().required('Image ID is required.'),
      order_id:Yup.string().required('Order ID is required.'),
      payment_id:Yup.string().required('Payment Id is required.')
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Edit Payment
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="order_id"
                  label="Order ID"
                  placeholder="Order ID"
                  value={formik.values.order_id}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.order_id && Boolean(formik.errors.order_id)}
                  helperText={formik.touched.order_id && formik.errors.order_id}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="image_id"
                  label="Image Id"
                  placeholder="Image Id"
                  value={formik.values.image_id}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.image_id && Boolean(formik.errors.image_id)}
                  helperText={formik.touched.image_id && formik.errors.image_id}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="amount_paid"
                  label="Amount"
                  placeholder="Amount"
                  value={formik.values.amount_paid}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.amount_paid && Boolean(formik.errors.amount_paid)}
                  helperText={formik.touched.amount_paid && formik.errors.amount_paid}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  name="paid_date"
                  type="date"
                  label="Paid Date"
                  placeholder="Paid Date"
                  value={formatDate(formik.values.paid_date, "yyyy-mm-dd")} //2017-06-13T13:00
                  onChange={(e) => {
                    if (validDate(new Date(), e.target.value, true)) {
                      formik.setValues((values) => ({
                        ...values,
                        'paid_date': e.target.value + ' 00:00:00',
                      }))
                    } else {
                      toast.error('Date should not be greater than Today date')
                    }
                    // }
                  }}
                  error={formik.touched.paid_date && Boolean(formik.errors.paid_date)}
                  helperText={formik.touched.paid_date && formik.errors.paid_date}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="description"
                  label="Description"
                  placeholder="Description"
                  multiline
                  rows={4}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Confirm
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    paid_on: "",
                    amount: "",
                    description: "",
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditPaymentForImages;
