import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, MenuItem } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { createCommonSettingCategoryTableData, formatDate, handleFileReader } from '../../lib/helper';
import { fileUpload } from '../../Services/commonService';
import { uploadWeeklyImages } from '../../Services/DashboardApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import { ImageSelectData } from '../../data/imageData';
import { updateImage } from '../../Services/imageApis';
import { commonItemsSelector } from '../../redux/common';
import { useSelector } from 'react-redux';
import { updateVideoLive } from '../../Services/videosApis';

export default function EditVideoDetailModal({ open, handleOpen, data, getListing, type }) {
  const [loading, setLoading] = React.useState(false);
  const {appSetting} = useSelector(commonItemsSelector)
  const [categoryTableData, setCategoryTableData] = React.useState([]);

  const handleUpload = async(values, { resetForm })=>{
    try{
      setLoading(true)
        let payload = {
          video_name: data.Name,
          rank: Number(values.rank),
          pricing: Number(values.pricing),
        }
        let resp = await updateVideoLive(payload)
        if(resp){
          toast.success(resp?.message)
          getListing();
          handleOpen(false);
        }
        setLoading(false);
        resetForm();
    }catch(e){
      setLoading(false)
      console.log(e, 'error')
    }
  }

  const formik = useFormik({
    initialValues: {
      video_name: data.Name || "",
      rank: data.rank || "",
      pricing: data.pricing || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      video_name: Yup.string()
        .required('Image name is required.'),
      rank: Yup.string()
        .required('Rank is required.')
        .matches(/^\d+$/, 'Rank should be number only.'),
      pricing: Yup.string()
        .required('Pricing is required.')
        .matches(/^\d+$/, 'Pricing should be number only.'),
      ...(type == "starimage" && {category: Yup.string()
        .required('Category is required.')})

    }),
    onSubmit: handleUpload,
  });

  React.useEffect(()=>{
    if(type == "starimage"){
      setCategoryTableData(createCommonSettingCategoryTableData(appSetting?.items?.features?.stars[0]?.key_value, 'stars'));
    }
  },[appSetting?.items?.features?.stars])

  return (
    <>
      <Dialog
        maxWidth={'lg'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant='h3'>Edit Video Details</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={4} md={3}>
                <CustomImage
                  style={{ width: '100%',  borderRadius: 8 }}
                  src={data.image_url}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Grid container spacing={2} mt={0}>
                    <Grid item xs={12} sm={6} md={4}>
                      <CustomInput
                        variant="outlined"
                        fullWidth
                        name="video_name"
                        label="Video name"
                        placeholder="Video name"
                        value={formik.values.video_name}
                        disabled
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        error={
                          formik.touched.video_name &&
                          Boolean(formik.errors.video_name)
                        }
                        helperText={
                          formik.touched.video_name && formik.errors.video_name
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <CustomInput
                        variant="outlined"
                        fullWidth
                        name="rank"
                        label="Rank"
                        placeholder="Rank"
                        value={formik.values.rank}
                        onChange={(e) => {
                          formik.setFieldValue('rank', e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        error={
                          formik.touched.rank &&
                          Boolean(formik.errors.rank)
                        }
                        helperText={
                          formik.touched.rank && formik.errors.rank
                        }
                      />
                    </Grid>
                  
                    <Grid item xs={12} sm={6} md={4}>
                      <CustomInput
                        variant="outlined"
                        fullWidth
                        name="pricing"
                        label="Pricing"
                        placeholder="Pricing"
                        value={formik.values.pricing}
                        onChange={(e) => {
                          formik.setFieldValue('pricing', e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        error={
                          formik.touched.pricing &&
                          Boolean(formik.errors.pricing)
                        }
                        helperText={
                          formik.touched.pricing && formik.errors.pricing
                        }
                      />
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div style={{display: "flex", justifyContent: "end", marginTop: 15}}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Save</CustomButton>
              <CustomButton onClick={() => handleOpen(false)} disabled={loading}>Close</CustomButton>
            </div>
          </Box>
        </DialogContent>
        {/* <DialogActions>
					<CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Upload</CustomButton>
					<CustomButton onClick={() => handleOpen(false)}>Close</CustomButton>
        </DialogActions> */}
      </Dialog>
    </>
  );
}


EditVideoDetailModal.defaultProps = {
  open: false,
  handleOpen: ()=>{}, 
  data: null, 
  getListing: ()=>{}, 
  type:'allimage'
}