import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import { Link } from 'react-router-dom';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'First Name',
    id: 'first_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Last Name',
    id: 'last_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Total Order',
    id: 'total_order',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {row?.total_orders>=0?row?.total_orders: 'N/A'}
      </span>
    ),
  },
  {
    label: 'Requirement Type',
    id: 'requirement_type',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Status',
    id: 'followup_status',
    format: (columnData) => (
      <Typography variant="span">{columnData?
        (columnData==='0'?'Open':
        columnData==='1'?'Close':
        columnData==='2'?'PClose':
        columnData==='3'?'Sold':
        columnData==='4'?'Upgrade Sold':
        columnData==='5'?'QPack Sold':
        columnData==='6'?'DQuery Sold':
        columnData==='7'?'DPack Sold'
        :'Exclude Sold'
        ):'-'}</Typography>
    ),
  },
  // {
  //   label: 'User Type',
  //   id: 'user_type',
  //   format: (columnData) => (
  //     <span
  //       style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
  //     >
  //       {columnData || 'N/A'}
  //     </span>
  //   ),
  // },
];

function UserSearchList({data, handleUserSelect,userData,companySearch}) {
  const [tableListing, setTableListing] = useState([]);

  //making data format for table input
  useEffect(() => {
    if (data?.length >= 0) {
      setTableListing(data);
    }
  }, [data]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{display: "flex", justifyContent: "end"}}>
          <CustomButton toolTipTittle={'Select'} onClick={()=>{ handleUserSelect({value: data.email, label: data.email, data}) }} className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}>Select</CustomButton>
         </div>
      </>
    );
  };

  return (
    <CustomBox padding={0}>
      {/* <Typography variant="h5" pt={2} px={2}></Typography> */}
      <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap', pt: 2, px: 2}}>
          <Typography variant="h5">User Search List</Typography>

          {/* <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "10px"}}>
            <CustomButton color={'buttonPrimary'}  onClick={()=>{ 
            }}>
              <Link to={`searchFollowUp/${companySearch?.company_name}/${companySearch?.state}`} target={'_blank'}  className='textDecoration-none' style={{'color':'white'}}>
              View All
              </Link>
            </CustomButton>
          </Box> */}
        </Box>

      <Divder spacing={1} />
      <MatTable
        loading={false}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        action={actionBody}
        tableMaxHeight={300}
        // onApplyFilter={(data = {}) => {
        //   getListing({
        //     page: String(1),
        //     start_date: '',
        //     end_date: '',
        //     ...getObjectSubset(data, 'start_date', 'end_date'),
        //   });
        //   if (Object.keys(data).length > 0) {
        //     setAllParams({
        //       ...allParams,
        //       page: String(1),
        //       ...getObjectSubset(data, 'start_date', 'end_date'),
        //     });
        //   } else {
        //     setAllParams({
        //       page: String(1),
        //     });
        //   }
        // }}
        // action={actionBody}
        // page={page}
        // perPage={limit}
        // total={allUserList?.items?.totalCount || 0}
        // setPage={setPage}
        // handleSearch={(searchTerm) => {
        //   getListing({
        //     page: String(1),
        //     search: searchTerm,
        //   });
        //   setAllParams({
        //     ...allParams,
        //     page: String(1),
        //     search: searchTerm,
        //   });
        // }}
        // handlePageChange={(newPage) => {
        //   getListing({
        //     page: String(newPage),
        //   });
        //   setAllParams({
        //     ...allParams,
        //     page: String(newPage),
        //   });
        // }}
        // toolbarEnable={true}
        // pagination={true}
        // filtersUrlData={allParams || {}}
        // filterEnable
        // rangeDateFilter
       
      />
    </CustomBox>
  );
}

export default UserSearchList;
