import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { addCompanyMaster, companyGroupListingsNoRedux, companyListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { addAutoSuggestion} from '../../Services/autoSuggestion';

const limit = DATA_LIMIT;

function AddAutoSuggestion({ open, handleOpen, setPage ,handleLast}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await addAutoSuggestion(values)
    if(resp){
      resetForm({
        keyword : "",
        display_keyword :"",
        rank:  "",
        
      })
      setPage(1)
      // dispatch(subscriptionListings({
      //   page: String(1),
      //   limit: limit
      // }))
      handleLast();
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      keyword : "",
      display_keyword :  "",
      rank: "",
      
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      rank: Yup.string().required('Rank is required.').matches(/^[0-9]+$/, "Must be only digits"),
      display_keyword : Yup.string().required('Display Keyword  is required.'),
      keyword: Yup.string().required('Keyword is required.')
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Add Suggestion
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="rank"
                  label="Rank"
                  placeholder="Rank"
                  value={formik.values.rank}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.rank&& Boolean(formik.errors.rank)}
                  helperText={formik.touched.rank && formik.errors.rank}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="keyword"
                  label="Keyword"
                  placeholder="Keyword"
                  value={formik.values?.keyword}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.keyword && Boolean(formik.errors.keyword)}
                  helperText={formik.touched.keyword && formik.errors.keyword}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="display_keyword"
                  label="Display Keyword"
                  placeholder="Display Keyword"
                  value={formik.values.display_keyword}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.display_keyword && Boolean(formik.errors.display_keyword)}
                  helperText={formik.touched.display_keyword && formik.errors.display_keyword}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    Keyword: "",
                    displayKeyword: "",
                    rank: "",
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddAutoSuggestion;
