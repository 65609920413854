import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { addCompanyMaster, companyGroupListingsNoRedux, companyListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { addSubscriptionPlan, subscriptionListings } from '../../Services/SubscriptionApis';
import { formatDate, validDate } from '../../lib/helper';
import { ContributorPaidMade, addPayment } from '../../Services/ContributorApis';
import { markAsPaidOrder } from '../../Services/OrderApis';

const limit = DATA_LIMIT;

function AddPaymentContributer({ open, handleOpen, setPage, handleLast, orderAndImageArr, contributorId, arr, setClearSelectedCheckbox, paidProcessArrData }) {
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    // let amt = paidProcessArrData?.reduce((acc, curr) => {
    //   return acc + curr?.royalty
    // }, 0)
    let newAmount=orderAndImageArr?.reduce((acc,curr)=>{
      let find=arr?.find((item)=>item===curr?.sold_image_id);
      if(find)return acc + curr?.royalty;
      else return acc;
    },0)
    setTotalAmount(newAmount);
  }, [arr])

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let newSelectedItem=orderAndImageArr?.filter((old)=>{
      let find=arr?.find((item)=>item===old?.sold_image_id);
      if(find)return true;
      else return false;
    });
    let newPayload=newSelectedItem?.map((a)=>({order_id: a?.order_id, image_name: a?.image_name }));
    //let newOrderArr = paidProcessArrData?.map((a) => ({ order_id: a?.order_id, image_name: a?.image_name }));
    let newObj = { ...values, order: JSON.stringify(newPayload) };
    let resp = await ContributorPaidMade(newObj);
    if (resp) {
      resetForm({
        contributor_id: contributorId,
        description: "",
        amount: "",
        paid_on: "",
        paid: "1"
      })
      setClearSelectedCheckbox(true)
      setPage(1)
      handleLast();
      handleOpen(false);
      setTotalAmount(0);
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      description: "",
      amount: totalAmount,
      paid_on: "",
      contributor_id: contributorId,
      paid: '1',
      sold_image_ids: JSON.stringify(arr)
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      amount: Yup.string().required('Amount is required.').matches(/^[0-9]+$/, "Must be only digits"),
      description: Yup.string().required('description is required.'),
      paid_on: Yup.string().required('Paid Date is required.'),
      contributor_id: Yup.string().required("Contributor Id is Required."),
      paid: Yup.string().required("Paid is Required."),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Add Payment
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  name="paid_on"
                  type="date"
                  label="Paid Date"
                  placeholder="Paid Date"
                  value={formatDate(formik.values.paid_on, "yyyy-mm-dd")} //2017-06-13T13:00
                  onChange={(e) => {
                    if (validDate(new Date(), e.target.value, true)) {
                      formik.setValues((values) => ({
                        ...values,
                        'paid_on': e.target.value + ' 00:00:00',
                      }))
                    } else {
                      toast.error('Date should not be greater than Today date')
                    }
                    // }
                  }}
                  error={formik.touched.paid_on && Boolean(formik.errors.paid_on)}
                  helperText={formik.touched.paid_on && formik.errors.paid_on}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="description"
                  label="Description"
                  placeholder="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="amount"
                  label="Amount"
                  placeholder="Amount"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  readOnly
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                />
              </Grid>


            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    paid_on: "",
                    amount: "",
                    description: "",

                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddPaymentContributer;
