import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import Category from './Category'

function CategoryContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "Image Categories",
    navSubTitle: "Welcome to ImagesBazaar"
  })

  const handleNavParams = (obj)=>{
    setNavParams({
      ...navParams,
      ...obj
    })
  }
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
      <Category handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default CategoryContainer