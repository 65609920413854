import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { deleteApiPayload, errorMessage } from '../lib/helper';
import { setHiddenKeywordListing, setHiddenKeywordListingError, setHiddenKeywordListingLoading, setVisibleHiddenKeywordListing, setVisibleHiddenKeywordListingError, setVisibleHiddenKeywordListingLoading, setVisibleKeywordListing, setVisibleKeywordListingError, setVisibleKeywordListingLoading } from '../redux/keyword';

export const getKeywordsList = async (payload) => {
    try {
      const resp = await axios.post(`${ApiConfig.getKeywords}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data
      }  else {
        throw resp
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
};


export const getVisibleKeywordListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setVisibleKeywordListingLoading(true));
        const resp = await axios.post(`${ApiConfig.visibleKeyword}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setVisibleKeywordListing(resp.data.data));
          dispatch(setVisibleKeywordListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setVisibleKeywordListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setVisibleKeywordListingLoading(false));
      }
    };
  }
};

export const getHiddenKeywordListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setHiddenKeywordListingLoading(true));
        const resp = await axios.post(`${ApiConfig.hiddenKeyword}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setHiddenKeywordListing(resp.data.data));
          dispatch(setHiddenKeywordListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setHiddenKeywordListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setHiddenKeywordListingLoading(false));
      }
    };
  }
};

export const getHiddenKeywordListingNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.hiddenKeyword}`, payload);
      if (
        resp?.data?.status >= STATUS_200 &&
        resp?.data?.status < STATUS_300
      ) {
        return resp?.data?.data?.rows
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return []
    }
  }
};

export const getVisibleKeywordListingNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.visibleKeyword}`, payload);
      if (
        resp?.data?.status >= STATUS_200 &&
        resp?.data?.status < STATUS_300
      ) {
        return resp?.data?.data?.rows
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return []
    }
  }
};

export const getVisibleHiddenKeywordListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setVisibleHiddenKeywordListingLoading(true));
        const resp = await axios.post(`${ApiConfig.hiddenKeyword}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setVisibleHiddenKeywordListing(resp.data.data));
          dispatch(setVisibleHiddenKeywordListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setVisibleHiddenKeywordListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setVisibleHiddenKeywordListingLoading(false));
      }
    };
  }
};


export const addVisibleKeyword = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.createVisibleKeyword}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp?.data?.message,{
        autoClose: 2500
      })
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const editVisibleKeyword = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.editVisibleKeyword}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp?.data?.message,{
        autoClose: 2500
      })
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const deleteVisibleKeyword = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.delete(`${ApiConfig.deleteVisibleKeyword}`, deleteApiPayload(payload));
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const addHiddenKeyword = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.createHiddenKeyword}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp?.data?.message,{
        autoClose: 2500
      })
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const editHiddenKeyword = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.editHiddenKeyword}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp?.data?.message,{
        autoClose: 2500
      })
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const deleteHiddenKeyword = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.delete(`${ApiConfig.deleteHiddenKeyword}`, deleteApiPayload(payload));
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const linkKeywordToVisible = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.linkKeyword}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp?.data?.message,{
        autoClose: 2500
      })
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const uploadVisibleKeywords = async (payload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.uploadVisibleKeywords}`, payload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return resp.data;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};

export const getListOfImageKeywords=async(payload)=>{
  try{
    const resp=await axios.get(`${ApiConfig?.getImageKeywordsList}/${payload}`);
    if(resp?.status>=STATUS_200 && resp?.status<STATUS_300){
       return resp?.data;
    }
  }
  catch(e){
    toast.error(errorMessage(e),{autoClose:2500})
  }
}

export const linkKeywordToImage = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.linkKeywordsToImage}`, payload);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      toast.success(resp?.data?.message,{
        autoClose: 2500
      })
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
}
