import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import AppSettings from './AppSettings'

function AppSettingsContainer() {
  const [navParams, setNavParams] = useState({
      navTitle: "All",
      navSubTitle: "Welcome to ImagesBazaar"
  })

  const handleNavParams = (obj)=>{
    setNavParams({
        ...navParams,
        ...obj
    })
  }

  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
        <AppSettings handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default AppSettingsContainer