export const CompanyData = {
  tabs: [
    {
      label: 'Unassigned',
      id: 1,
    },
    {
      label: 'Assigned',
      id: 2,
    },
    {
      label: 'Company Master',
      id: 3,
    },
  ],
};


export const SearchByAssigned = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'User Name',
    value: 'user_name',
  },
  {
    label: 'Company Name',
    value: 'company_name',
  },
  {
    label: 'Short Company Name',
    value: 'short_company_name',
  },
  {
    label: 'Company Group Name',
    value: 'company_group_name',
  }
];

export const SearchByUnAssigned = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Email',
    value: 'user_name',
  },
  {
    label: 'Company Name',
    value: 'company_name',
  }
];
export const SearchByUnRegistered = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Company Name',
    value: 'company_name',
  },
  {
    label: 'Short Company Name',
    value: 'short_company_name',
  },
  {
    label: 'Group Name',
    value: 'company_group_name',
  }
];