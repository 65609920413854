import { Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { UserIdentityData } from '../../data/userData';
import { breakStringIntoLines, downloadBlobXLSFile, formatDate, getNewParameters, getObjectSubset, getParameters, statusText } from '../../lib/helper';
import { followUpsItemSelector } from '../../redux/followUps';
import { userItemsSelector } from '../../redux/user';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { daliySalesEntryListing } from '../../Services/CRMFollowups';
import { allUserListing, allUserListingExportFile } from '../../Services/userApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import CreateDailySaleFollow from '../Modals/CreateDailySaleFollow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Add } from '@material-ui/icons';
import AllFilters from '../Order/AllFilters';
import { listOfFollowedBy } from '../../Services/cmsService';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'User Name',
    id: 'first_name',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>{row?.first_name} {row?.last_name ? ' ' + row?.last_name : "" || 'N/A'}</span>)
  },
  {
    label: 'Mobile',
    id: 'mobile_number',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'User Type',
    id: 'user_type',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Created By',
    id: 'first_created_by',
    format: (columnData, rowIndex, formatCallback, func, errors, row) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>{columnData || 'N/A'}
    </span>)
  },
  {
    label: 'Requirement Type',
    id: 'requirement_type',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>{columnData?columnData:'N/A'}</span>),
    nextLine:true,
  },
  {
    label: 'Description Type',
    id: 'description_type',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
      {columnData?breakStringIntoLines(columnData,20):'N/A'}
      </span>)
  },
];


function DailySalesEntry() {
  const [tableListing, setTableListing] = useState([])
  const [expLoading, setExpLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, dailySalesEntry } = useSelector(followUpsItemSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [openCreateDaliySale, setOpenCreateDaliySale] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Followups By',
      id: 'created_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'All', value: '' },
      ],
      props: {},
      isVisible: true,
    },
    {
      label: 'Search',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
    {
      label: 'Previous date',
      id: 'prevous_flag',
      type: 'checkbox',
      linkedWith: null,
      value: false,
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.checked;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);

  const getUsersList = useCallback(async () => {
    let data = await listOfFollowedBy()
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[2].dataArr = [{ label: 'All', value: '' },
      ...data?.map((el) => {
        return { label: el?.first_name, value: el?.first_name }
      })];
      return newState;
    });
  }, [])

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    let dataNew = {
      end_date: dataWithValue?.end_date ? `${dataWithValue?.end_date} 23:59:59` : undefined,
      search: dataWithValue?.search,
      prevous_flag: Number(dataWithValue?.prevous_flag),
      created_by: dataWithValue?.created_by ? dataWithValue?.created_by : undefined,
      start_date: dataWithValue?.start_date ? `${dataWithValue?.start_date} 00:00:00` : undefined,
    }
    // getListing(dataNew)
    let obj = { tab: allParams?.tab, ...dataNew, page: String(1) };
    setAllParams({
      ...obj
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[3].isVisible = true;
      newState[4].isVisible = true;

      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      newState[3].value = '';
      newState[4].value = false;

      return newState;
    });
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // user_type: "2",
      ...allParams,
      ...params,
      prevous_flag: Number(allParams?.prevous_flag),
    }
    dispatch(daliySalesEntryListing(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
    getUsersList()
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (dailySalesEntry?.items?.rows?.length >= 0) {
      setTableListing(dailySalesEntry?.items?.rows)
    }
  }, [dailySalesEntry.items]);

  const getExportFile = async (params = {}) => {
    try {
      let payload = {
        ...allParams,
        ...params
      }
      delete payload.page
      delete payload.limit

      setExpLoading(true)
      let resp = await allUserListingExportFile(payload)
      if (resp) {
        downloadBlobXLSFile(resp?.data, `UserList`)
      } else {
        toast.error('Something went wrong.')
      }
      setExpLoading(false)
    } catch (e) {
      console.log(e)
      setExpLoading(false)
    }
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth" onClick={() => { handleSelectedData(data) }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Add /></CustomButton>
          <Link className='textDecoration-none' to={`../crm/saleEntryHistory/${data?.daily_sale_id}`} target='_blank'>
            <CustomButton className="noMinWidth" sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><VisibilityIcon /> </CustomButton>
          </Link>

        </div>
      </>
    );
  };

  const handleCreate = (value) => {
    setOpenCreateDaliySale(value);
    if (!value) {
      setSelectedData(null)
    }
  };

  const handleSelectedData = (data) => {
    setSelectedData(data);
    handleCreate(true)
  }

  return (
    <>
      <MatTable
        loading={dailySalesEntry?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        action={actionBody}
        page={page}
        perPage={limit}
        total={dailySalesEntry?.items?.count || 0}
        setPage={setPage}

        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        dateValidation={true}
        disableSearch={true}

        pagination={true}
        filtersUrlData={allParams || {}}

        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          />
        )}
      />
      {openCreateDaliySale && <CreateDailySaleFollow
        open={openCreateDaliySale}
        handleOpen={handleCreate}
        getListing={getListing}
        selectedData={selectedData}
      />}
    </>
  );
}

export default DailySalesEntry;
