import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { imageListingItemsSelector } from '../../redux/image';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { soldImagePendingListings } from '../../Services/imageApis';
import { AllOrderListing } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import EditImageDetailModal from '../Modals/EditImageDetailModal';
import ImageDetailModal from '../Modals/ImageDetailModal';
import MatTable from '../Tables/MatTable';

const limit = DATA_LIMIT;

const column = [
//   {
//     label: 'Sold Image Id',
//     id: 'sold_image_id',
//     format: (columnData) => (
//       <span
//         style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
//         onClick={() => {
//           // customHistory.push(`order/orderDetails/${columnData}`);
//         }}
//       >
//         {columnData}
//       </span>
//     ),
//   },
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
        onClick={() => {
          // customHistory.push(`order/orderDetails/${columnData}`);
        }}
      >
        {columnData}
      </span>
    ),
  },
//   {
//     label: 'User',
//     id: 'user_name',
//     format: (columnData) => (
//       <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
//         {columnData}
//       </span>
//     ),
//   },
//   {
//     label: 'Plan Id',
//     id: 'plan_id',
//     format: (columnData) => (
//       <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
//         {columnData}
//       </span>
//     ),
//   },
  {
    label: 'Image Name',
    id: 'image_name',
    format: (columnData, rowIndex, formatCallback, options) => (
      <Typography
        variant='span'
        style={{ whiteSpace: 'nowrap' }}
        className={'link-text'}
        onClick={() => {
          // customHistory.push(`order/orderDetails/${columnData}`);
          options(rowIndex, columnData)
        }}>
        {columnData || 'N/A'}
      </Typography>
    ),
  },
  {
    label: 'Pack',
    id: 'pack',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
//   {
//     label: 'Download On',
//     id: 'Download_on',
//     format: (columnData) => (
//       <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
//         {formatDate(columnData)}
//       </span>
//     ),
//   },
  {
    label: 'Quality',
    id: 'quality',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Price',
    id: 'price',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
//   {
//     label: 'Discount',
//     id: 'Discount',
//     format: (columnData) => (
//       <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
//         {columnData}
//       </span>
//     ),
//   },
//   {
//     label: 'Client',
//     id: 'client',
//     format: (columnData) => (
//       <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
//         {columnData}
//       </span>
//     ),
//   },
//   {
//     label: 'Order by',
//     id: 'order_by',
//     format: (columnData) => (
//       <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
//         {columnData}
//       </span>
//     ),
//   },
  {
    label: 'Image Type',
    id: 'image_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  }
];

function SoldImagesPending() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [imageDetailModal, setImageDetialModal] = useState(false);
  const [imageName, setImageName] = useState(null);
  const [limit, setLimit] = useState(20);
  const { loading, soldImagesPending } = useSelector(imageListingItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleModal = (value = false, image = null) => {
    setImageDetialModal(value);
    if (!value) {
      setTimeout(() => {
        setImageName(image);
      }, 500);
    }else{
      setImageName(image);
    }
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // order_status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(soldImagePendingListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  //making data format for table input
  useEffect(() => {
    if (soldImagesPending?.items?.rows?.length >= 0) {
      setTableListing(soldImagesPending?.items?.rows);
    }
  }, [soldImagesPending.items]);


  return (
    <>
      <MatTable
        loading={soldImagesPending?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        // action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20,50,100,200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        // setFilters={setFilters}
        // filters={filters}
        total={soldImagesPending?.items?.count || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        columnWiseData={[
            {
              id: "image_name",
              value: (index, data)=>{
                handleModal(true, data)
              }
            }
          ]}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
      <ImageDetailModal
        open={imageDetailModal}
        handleOpen={handleModal}
        imageName={imageName}
      />
    </>
  );
}

export default SoldImagesPending;
