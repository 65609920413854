import { Search } from '@material-ui/icons';
import { Box, Grid, InputAdornment, MenuItem, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useMemo, useState } from 'react';
import ApiConfig from '../../config/ApiConfig';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ModelImagesListings } from '../../Services/modelApi';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { modelItemsSelector } from '../../redux/model';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { imagesForShootsListings, updateWaterMark } from '../../Services/imageApis';
import { imageListingItemsSelector } from '../../redux/image';
import { DataObject } from '@mui/icons-material';
import ConfirmationModal from '../Modals/ConfirmationModal';
import AllFilters from '../Order/AllFilters';
import { WatermarkData } from '../../data/imageData';
import { toast } from 'react-toastify';


const column = [

  {
    label: 'Image',
    id: 'image_url',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
        onClick={() => {
        }}
      >
        <img style={{ maxWidth: 200, maxHeight: 200 }} src={`${columnData}`} />
      </span>
    ),
  },
  {
    label: 'Name',
    id: 'name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Rank',
    id: 'rank',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Rank1',
    id: 'rank1',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },

];

function ShootIDListingPages({ showCheckbox, setShowCheckbox }) {
  const limit = 10;
  const { id } = useParams();
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, shootImagesIDList } = useSelector(imageListingItemsSelector);
  const [clearSelectedCheckbox, setClearSelectedCheckbox] = useState(false);
  const [imageWatermark, setImageWatermark] = useState({});
  const [confirmImageWatermark, setConfirmImageWatermark] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [filtersArray, setFiltersArray] = useState([
    
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },

  ]);
  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      shoot_id: id,
      ...allParams,
      ...params
    }
    dispatch(imagesForShootsListings(payload))
    setPage(Number(payload.page))
  }
  const handleFilter = (data, allDatavalues, dataWithValue) => {
    if (dataWithValue.watermark == 1)
      setShowCheckbox(true)
    else
      setShowCheckbox(false)
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    setShowCheckbox(false);
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[0].value = '';
      return newState;
    });
  };
  useEffect(() => {
    getListing();
  }, []);
  const handleCofirmModalOpen = (value) => {
    setConfirmImageWatermark(value);
  }
  const handleConfirmWatermark = async (value) => {
    if (value && imageWatermark) {
      const resp = await updateWaterMark({ image_id: imageWatermark, watermark: 0 });
      if (resp) {
        getListing({ page: '1' })
        setPage(1)
        handleClearSelectedCheckbox(true)
      }
    } else {
      setImageWatermark(null)
    }
  }
  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);
  const handleBulkUpdate = async (data, resetState) => {
    let stateSelected = shootImagesIDList.items.rows.filter(
      (item) => item.watermark == 1,
    );
    let userSelected = stateSelected.map(
      (item) => item.Name,
    );
    const intersection = userSelected.filter(element => data.includes(element));
    handleCofirmModalOpen(true)
    setImageWatermark(intersection)
  }
  const handleClearSelectedCheckbox = (value = false) => {
    setClearSelectedCheckbox(value || false)
  }

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (shootImagesIDList?.items?.rows?.length >= 0) {
      let data = shootImagesIDList?.items?.rows?.map((item) => {
        return {
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.Name}.jpg`,
          sno: item.sno,
          name: item.Name,
          rank: item?.rank,
          rank1: item.rank1,
          suspendate: item?.suspendate,
          // watermark: item?.watermark,
        }
      })
      setTableListing(data)
    }

  }, [shootImagesIDList?.items]);

  return (
    <div>
      <Divder spacing={1} />
      <MatTable
        loading={shootImagesIDList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        total={shootImagesIDList?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        columnWiseData={[
          {
            id: 'name' + 'watermark',
            value: handleBulkUpdate,
          }
        ]}

        checkboxEnable={showCheckbox}
        customCheckboxDataKey={'name'}
        onTableToolbarRightClick={handleBulkUpdate}
        clearSelectedCheckbox={clearSelectedCheckbox}
        handleClearSelectedCheckbox={handleClearSelectedCheckbox}// requred to clear filter and refresh state after api call 
        onTableToolbarRightSection={<CustomButton sx={(theme) => ({
          fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
          [theme.breakpoints.down("md")]: {
            flex: 1,
          },
        })}
        >Remove Watermark</CustomButton>}
        toolbarEnable={true}
        filtersUrlData={allParams || {}}
        rangeDateFilter
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          />
        )}
      />
      {confirmImageWatermark && <ConfirmationModal open={confirmImageWatermark} handleConfirm={handleConfirmWatermark} setOpen={handleCofirmModalOpen} />}
    </div>
  );
}

export default ShootIDListingPages
