import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { currencyConversion, fetchFile, formatDate, generateArrayOfMonths, generateArrayOfYears, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { contributorItemsSelector } from '../../redux/contributor';
import { contributorSoldImagesListings } from '../../Services/ContributorApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import MatTable from '../Tables/MatTable';
const limit = 10;
const yearsList = generateArrayOfYears(20)
const monthsList = generateArrayOfMonths()

const column = [
  {
    label: 'Contributor ID',
    id: 'contributor_id',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <Link className='textDecoration-none link-text' to={`payments/${columnData}${options}&payment_type=All`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
        >
          {columnData}
        </Typography>
      </Link>

    )
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (

      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Amount(INR)',
    id: 'amount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData&&currencyConversion(columnData)  || 'N/A'}</span>
    )
  },

];

function ContributorSoldImageList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [year, setYear] = useState(yearsList[0]);
  const [month, setMonth] = useState(monthsList[0].value);
  const [allParams, setAllParams] = useState(getParameters());
  const { contributorSoldImagesList } = useSelector(contributorItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...getObjectSubset({
        start_date: formatDate(new Date().setDate(new Date().getDate() - 30), "yyyy-mm-dd") + ' 00:00:00',
        end_date: formatDate(new Date(), "yyyy-mm-dd") + ' 23:59:59'
      }, 'start_date', 'end_date'),
      ...allParams,
      ...params
    }

    dispatch(contributorSoldImagesListings(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams({
      start_date: formatDate(new Date().setDate(new Date().getDate() - 30), "yyyy-mm-dd") + ' 00:00:00',
      end_date: formatDate(new Date(), "yyyy-mm-dd") + ' 23:59:59',
      ...params
    })
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  useEffect(() => {
    if (contributorSoldImagesList?.items?.data?.length >= 0) {
      let data = contributorSoldImagesList?.items?.data?.map((item) => {
        return {
          amount: item?.amount,
          email: item?.contributor?.email,
          contributor_id: item?.contributor?.contributor_id,
        }
      })
      setTableListing(data)
    }

  }, [contributorSoldImagesList.items]);


  const actionBody = ({ data }) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '110px', bgcolor: 'buttonInfo.main' }}>USER DETIALS</CustomButton>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '135px' }}>VIEW FOLLOWUPS</CustomButton>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '150px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>CREATE FOLLOWUPS</CustomButton>
        </div>
      </>
    );
  };

  const customFilter = (data) => {
    return (<Box sx={{ display: 'flex', justifyContent: "flex-end", flex: 1 }}>
      <CustomInput
        variant="outlined"
        fullWidth
        name="year"
        label="Year"
        placeholder="Year"
        select
        SelectProps={{
          native: true,
        }}
        // value={selectedCountry}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          classes: {
            notchedOutline: 'rounded',
          },
          style: {
            height: 40
          }
        }}

        style={{ width: 100, marginRight: 20 }}
        onChange={(e) => {
          getListing({
            ...allParams,
            year: e.target.value,
          })
          setYear(e.target.value)
        }}
      >
        {yearsList?.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </CustomInput>
      <CustomInput
        variant="outlined"
        fullWidth
        name="months"
        label="Months"
        placeholder="Months"
        select
        SelectProps={{
          native: true,
        }}
        // value={selectedCountry}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          classes: {
            notchedOutline: 'rounded',
          },
          style: {
            height: 40
          }
        }}
        style={{ width: 150 }}
        onChange={(e) => {
          getListing({
            ...allParams,
            month: e.target.value,
          })
          setMonth(e.target.value)
        }}
      >
        {monthsList?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </CustomInput>
    </Box>);
  };

  return (
    <CustomBox padding={0} sx={{ minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <MatTable
        loading={contributorSoldImagesList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            ...getObjectSubset({
              start_date: formatDate(new Date().setDate(new Date().getDate() - 30), "yyyy-mm-dd"),
              end_date: formatDate(new Date(), "yyyy-mm-dd")
            }, 'start_date', 'end_date'),
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        total={contributorSoldImagesList?.items?.totalCount || 0}
        setPage={setPage}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        columnWiseData={[
          {
            id: 'contributor_id',
            value: getNewParameters(allParams)?.search,
          },
        ]}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        disableSearch
        rangeDateFilter
      />
    </CustomBox>
  );
}

export default ContributorSoldImageList;
