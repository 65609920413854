import React, { useEffect, useState } from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { Box, Grid, Typography } from '@mui/material';
import ImageUpload from './ImageUpload';
import { useDispatch, useSelector } from 'react-redux';
import { commonItemsSelector } from '../../redux/common';
import ImageUploadBanners from './ImageUploadBanners';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import UploadImageModal from '../Modals/UploadImageModal';
import { cmsItemsSelector } from '../../redux/cms';
import { getBannersInfo } from '../../Services/cmsService';
import { toast } from 'react-toastify';
import AllConstant from '../../AllConstant';


function BannersCrousel({ }) {
  const { loading, bannersImagesData } = useSelector(cmsItemsSelector);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = (value = false) => {
    setOpen(value)
  }

  const getListing = async (params = {}) => {
    dispatch(getBannersInfo());
  };

  useEffect(() => {
    getListing();
  }, []);

  const handleModalOpenAndLimit = () => {
    if (bannersImagesData?.items?.message?.length >= 5 ) {
      toast.error((AllConstant?.alertMessages?.bannerImageMessage), {
        autoClose: 2500,
      });
    }
    else
      setOpen(true);
  }
  return (
    <>
      <CustomBox padding={3} sx={{ minHeight: 200 }}>
        <Grid container spacing={2}>
          <Grid item xs='12' sm={6} lg={6}> <Typography
            variant="h5"
            sx={{ marginBottom: "2px", marginTop: "2px", marginLeft: '10px' }}
          >
            You have uploaded {bannersImagesData?.items?.message?.length} out of 5

          </Typography>
          <Typography
            variant="h5"
            sx={{ marginBottom: "2px", marginTop: "2px", marginLeft: '10px' }}
          >
            Recommended Size : <span style={{fontWeight:'700'}}>1920 X 400</span>

          </Typography>
          </Grid>
          <Grid item xs='12' sm={6} lg={6}   >

            <Box display="flex" justifyContent="flex-end">
              <CustomButton type="button" onClick={(e) => { handleModalOpenAndLimit() }} loading={loading} color={'buttonPrimary'} sx={{ padding: '8px 20px' }}>Upload New Image</CustomButton>

            </Box>
          </Grid>

          {bannersImagesData?.items?.message?.map((item, index) => {
            return <Grid item xs={12} key={item?.banner_image_id}>
              <ImageUploadBanners item={item} parentKey={'bannersImages'} total={bannersImagesData?.items?.message?.length} handleLast={getListing} />
            </Grid>
          })
          }
        </Grid>
      </CustomBox>
      {open && <UploadImageModal open={open} handleOpen={handleOpen} handleLast={getListing} />}
    </>
  );
}

export default BannersCrousel;
