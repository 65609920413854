
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputAdornment, TextField } from "@mui/material";
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { ForgotPWD } from "../../Services/LoginApi";

export default function ForgotPasswordModal({ open, onClose }) {
  const dispatch = useDispatch();
  const handleForgotPassword = async (values, { resetForm }) => {
    try{
      let payload={...values};
       ForgotPWD(payload);
       resetForm({
        email: "",
      });

    }
    catch (e) {
      console.log(e, 'error');
    }
    onClose();
  };



  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter your email")
        .required("Please enter your email"),
    }),
    onSubmit: handleForgotPassword,
  });

  return (
    <>
      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={onClose}>
        <DialogContent>
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="mainpagetitle title-login title-forgotPass">
              Forgot Password
            </div>
            <div className="login-main-form">
              <div className="login-form-content">
                <TextField
                  label="Email"
                  className="main-login-form"
                  fullWidth
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        
                        {/* <MailIcon className="loginform-field-icons" /> */}
                        <MailOutlineOutlinedIcon style={{color:'lightgray'}}/>
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.setFieldValue("email", e.target.value);
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  autoComplete={"new-password"}
                />
              </div>
            </div>

            <div className="signin-button-main">
              <button
                className="mainpagetitle signin-button-login-page forgot-button-main"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
