import React from 'react'
import CustomInput from '../CommonComp/CustomInput/CustomInput'

function ProposalFields(props) {
  return (
    <CustomInput
        variant="outlined"
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{
            classes: {
            notchedOutline: 'rounded',
            },
        }}
        {...props}
    />
  )
}

export default ProposalFields