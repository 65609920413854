import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import { Delete, Edit } from '@material-ui/icons';
import { masterItemsSelector, setMasterListing } from '../../redux/master';
import { MasterListings } from '../../Services/masterApi';
import AllFilters from '../Order/AllFilters';
import { toast } from 'react-toastify';

const limit = 10;

const column = [
  {
    label: 'Total Earning',
    id: 'total_earning',
    format: (columnData) => (
    //   <Link className='textDecoration-none link-text' to={`modelImages/${columnData}`}>
    //   <Typography
    //     variant='span'
    //     style={{ whiteSpace: 'nowrap' }}
    //     // className={'link-text'}
    //     // onClick={() => {
    //     //   customHistory.push(`order/orderDetails/${columnData}`);
    //     // }}
    //   >
    //     {columnData}
    //   </Typography>
    // </Link>
        <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Total Users',
    id: 'total_users',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Total Orders',
    id: 'total_orders',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content"}}>{columnData || 'N/A'}</span>)
  },
];

function MastersList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, masterList,resetShortCompanyData } = useSelector(masterItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const getListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        order_status: String(1),
        ...allParams,
        ...params
    }
    if(payload?.short_company_name){
      dispatch(MasterListings(payload))
      setPage(Number(payload.page));
    }
  }

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Short Company',
      id: 'short_company_name',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
   
    // {
    //   label: 'Days',
    //   id: 'months',
    //   type: 'select',
    //   linkedWith: null,
    //   value: '',
    //   onChange: (e, index, value) => {
    //     setFiltersArray((prevState) => {
    //       let newState = [...prevState];
    //       newState[index].value = e.target.value;
    //       return newState;
    //     });
    //   },
    //   style: {},
    //   dataArr: [
    //     { label: 'Select', value: '' },
    //     { value: '1 Month', label: '1 Month' },
    //     { value: '4', label: '3 Months' },
    //     { value: '6', label: '6 Months', },
    //     { value: '12', label: '1 Year', },
    //     { value: '24', label: '2 Years', },
    //     { value: '60', label: '5 Years', },
    //     { value: '120', label: '10 Years', },
    //   ],
    //   props: {},
    //   isVisible: true,
    // },
    
    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value =  e.target.value;
          return newState;
        });
        // handleChangeFollowUpDate(e.target.value, 1, 'start_date');
        // handleChangeFollowUpDate(e.target.value, 2, 'end_date');

      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
        // handleChangeFollowUpDate(e.target.value, 2, 'end_date');
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
  

    {
      label: 'Payment Status',
      id: 'payment_status',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          if(e.target.value==1){
            newState[3].isVisible=true;
          }
          else{
            newState[3].isVisible=false;
          }
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        // { label: 'All', value: '' },
        { label: 'Paid', value: '1' },
        { label: 'Unpaid', value: '0' },
      ],
      props: {},
      isVisible: true,
    },
    {
      label: 'Payment Type',
      id: 'payment_type',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
        
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { label: 'Cheque/demand draft', value: 'Cheque/demand draft' },
        { label: 'NEFT/RTGS/WT', value: 'NEFT/RTGS/WT' },
        { label: 'RazorPay', value: 'RazorPay' },
        { label: 'American Express', value: 'American Express' },
        { label: 'Pay10', value: 'Pay10' },
        { label: 'Zaakpay', value: 'Zaakpay' },
        { label: 'Paypal', value: 'Paypal' }

      ],
      props: {},
      isVisible: false,
    },

    {
      label: 'Order Type',
      id: 'order_type',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          if (e.target.value) {
            newState[index].value = e.target.value;
          }
          else {
            newState[index].value = '';
          }

          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { label: 'IV', value: 'IV' },
        { label: 'NPlan', value: 'NPlan' },
      ],
      props: {},
      isVisible: true,
    },


  ]);


  // useEffect(() => {
  //   getListing();
  // }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  //making data format for table input
  useEffect(() => {
    if(masterList?.items?.length){
      setTableListing(masterList?.items)
    }
  
  }, [masterList?.items]);

  const handleModelUpload = (value) => {
    setOpenModelUpload(value);
  }

  const handleAdd = (value=false) =>{
    setAddModel(value);
  }

  const handleEdit = (value=false) =>{
    setEditModel(value)
  }

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    if(!dataWithValue?.short_company_name){
      toast.error("Company Name is required", {
        autoClose: 2500,
      });
    }
    else{
      getListing(
        {
          page: String(1),
          ...dataWithValue,
        },
        true,
      );
      setAllParams({
        page: String(1),
        ...dataWithValue,
      });

    }
   
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[3].isVisible = false;
      newState[4].isVisible = true;
     
      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      newState[3].value = '';
      newState[4].value = '';
      setTableListing([])
     
      // newState[5].isVisible = true;
      return newState;
    });
  };

  

  const handleCofirmModalOpen = (value) => {
    setSelectedData(value);
    setConfirmDelete(value)
  }
  
  const actionBody = (data) => {
    return (
      <>
        <div style={{display: "flex"}}>
          <CustomButton className="noMinWidth buttonDense" onClick={()=>{
            setSelectedData(data)
            handleEdit(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit/></CustomButton>
          <CustomButton className="noMinWidth buttonDense" onClick={()=>{
            handleCofirmModalOpen(true)
            setConfirmDelete(true);
            setModelDeleteData(data?.sno)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete/></CustomButton>
        </div>
      </>
    );
  };

  // const actionHead = (data) => {
  //   return (<div style={{display: "flex"}}>
  //      hi
  //   </div>);
  // };

  return (
    <>
      {/* <MatTable
        loading={masterList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={masterList?.items?.totalCount || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolBarButton={()=>{
          return <>
           <CustomButton onClick={()=>{handleModelUpload(true)}} color={'buttonPrimary'} 
              sx={(theme)=>({ padding: '10px 10px', marginRight: 1, 
                [theme.breakpoints.down("md")]: {
                  flex: 1,
                }
              })}>Upload CSV</CustomButton>
            <CustomButton onClick={()=>{handleAdd(true)}} color={'buttonPrimary'}  sx={(theme)=>({ padding: '10px 10px', marginRight: 1, 
                [theme.breakpoints.down("md")]: {
                  flex: 1,
                }
              })}>Add Model</CustomButton>
          </>
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      /> */}

<>
        <MatTable

          loading={masterList?.loading}
          showCheckbox={false}
          columns={column}
          data={tableListing}
          // requestSearch={requestSearch}
          // cancelSearch={cancelSearch}
          onApplyFilter={(data = {}) => {
            getListing({
              page: String(1),
              start_date: "",
              end_date: "",
              ...getObjectSubset(data, 'start_date', 'end_date', 'closed_by', 'remaining_days'),
            });
            if (Object.keys(data).length > 0) {
              setAllParams({
                ...allParams,
                page: String(1),
                ...getObjectSubset(data, 'start_date', 'end_date', 'closed_by', 'remaining_days'),
              });
            } else {
              setAllParams({
                page: String(1),
              });
            }
          }}
          // showFilterButton={true}
          // showSerialNo={false}
          // action={actionBody}
          // actionHead={actionHead}
          page={page}
          // changeFilter={changeFilter}
          // handleFilterPopup={toggleFilters}
          perPage={limit}
          showPageLimit={true}
          handleChangePageLimit={(e) => {
            setLimit(e.target.value);
            setAllParams({
              ...allParams,
              page: String('1'),
              limit: Number(e.target.value),
            });
          }}
          // setFilters={setFilters}
          // filters={filters}
          total={masterList?.items?.count || 0}
          // defaultSort="transactionTime"
          // defaultSortOrder={isMobile ? sortOrder : 'desc'}
          // searchTerm={searchTerm}
          setPage={setPage}
          handleSearch={(searchTerm) => {
            getListing({
              page: String(1),
              search: searchTerm
            })
            setAllParams({
              ...allParams,
              page: String(1),
              search: searchTerm
            })
          }}
          handlePageChange={(newPage) => {
            getListing({
              page: String(newPage)
            })
            setAllParams({
              ...allParams,
              page: String(newPage)
            })
          }}
          toolbarEnable={true}
          // toolBarButton={()=>{
          //   return <CustomButton sx={(theme)=>({ fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
          //     [theme.breakpoints.down("md")]: {
          //       flex: 1,
          //     }
          //    })} onClick={()=>{
          //     getExportFile()
          //   }} loading={expLoading}>Export XLS</CustomButton>
          // }}
          pagination={true}
          filtersUrlData={allParams || {}}
          // filterEnable
          rangeDateFilter
          // dropdownFilter={true}
          disableSearch
          customFilter={() => (
            <AllFilters
              filtersArray={filtersArray}
              handleClick={handleFilter}
              handleClear={handleClear}
              // hasDownloadExcelButton={false}
             
            />
          )}
        // selectFilterArray={[
        //   {
        //     name: 'closed_by',
        //     label: 'Closed By',
        //     placeholder: 'Closed By',
        //     options: FiltersData?.closedBy
        //   },
        //   {
        //     name: 'remaining_days',
        //     label: 'Remaining Days',
        //     placeholder: 'Remaining Days',
        //     options: [...FiltersData?.remainingDays, ...generateFilters(appSetting?.items?.features?.cms)]
        //   },

        // ]}
        />
      </>
    
    </>
  );
}

export default MastersList;
