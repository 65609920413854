import React from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import CustomInput from '../CommonComp/CustomInput/CustomInput'
import { Grid } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';

function OutstandingFilters({filtersArray, handleClick, handleClear,handleDownloadCSV}) {
    if(filtersArray?.length<0){
        return null
    }
  return (
    <>
      <Grid spacing={2} py={0.5} container>
      {filtersArray?.map((item, index)=>{
        if(!item?.isVisible){
          return null
        }
        return <Grid key={index} item xs={12} sm={6} md={4} lg={2}>
          {item?.type == 'select' ? <CustomInput
            select
            SelectProps={{
              native: true,
            }}
            name={item?.id}
          
            label={item?.label}
            placeholder={item?.label}
            value={item.value}
            onChange={(e)=>item?.onChange(e, index, item)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            sx={{
                width: '100%'
            }}
            {...item.props}
          >
            {item?.dataArr?.length &&
                item?.dataArr?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
            ))}
          </CustomInput> : <CustomInput
            name={item?.id}
            key = {index}
            label={item?.label}
            placeholder={item?.label}
            value={item.value}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            onChange={(e)=>item?.onChange(e, index, item)}
            sx={{
                width: '100%'
            }}
            {...item?.props}
          />}
        </Grid> 
      })}
        <Grid item xs={12} sm={12} md={12} >
            <CustomButton type="submit" color={'buttonPrimary'} sx={{mt: 1}} onClick={()=>{
              let arr = filtersArray?.filter((e)=>e.isVisible) || filtersArray
              let allDatavalues = {}
              let dataWithValue = {}
              arr?.forEach(element => {
                allDatavalues = {
                  ...allDatavalues,
                  [element?.id]: element?.value
                }
                if(element.value){
                  dataWithValue = {
                    ...dataWithValue,
                    [element?.id]: element?.value
                  }
                }
              })
              handleClick(arr, allDatavalues, dataWithValue)
            }}>Submit</CustomButton>
            <CustomButton type="submit" color={'buttonSecondary'} sx={{mt: 1}} onClick={()=>{
              handleClear()
            }}>Clear Filter</CustomButton>
             <CustomButton type="submit" color={'buttonPrimary'} sx={{mt: 1}} onClick={()=>{
              let arr = filtersArray?.filter((e)=>e.isVisible) || filtersArray
              let allDatavalues = {}
              let dataWithValue = {}
              arr?.forEach(element => {
                allDatavalues = {
                  ...allDatavalues,
                  [element?.id]: element?.value
                }
                if(element.value){
                  dataWithValue = {
                    ...dataWithValue,
                    [element?.id]: element?.value
                  }
                }
              })
              handleDownloadCSV(arr, allDatavalues, dataWithValue)
            }}>Download CSV</CustomButton>
        </Grid>

        <Grid item xs={12} sm={6} md={4} >
           
            {/* <CustomButton type="submit" color={'buttonSecondary'} sx={{mt: 1}} onClick={()=>{
              handleClear()
            }}>Clear Filter</CustomButton> */}
        </Grid>
      </Grid>
    </>
  )
}

export default OutstandingFilters;