import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import { contributorItemsSelector } from '../../redux/contributor';
import { contributorPaymentLists } from '../../Services/ContributorApis';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AddPayment from '../Modals/AddPayment';
import AddPaymentForImages from '../Modals/AddPaymentForImages';
import { Edit } from '@material-ui/icons';
import EditPaymentForImages from '../Modals/EditPaymentForImages';


const limit = DATA_LIMIT;

const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>)
  },
  {
    label: 'Image Id',
    id: 'image_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>)
  },
  {
    label: 'Payment Date',
    id: 'paid_date',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{formatDate(columnData, 'dd-mm-yyyy')}</span>)
  },
  {
    label: 'Description',
    id: 'description',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Amount',
    id: 'amount_paid',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>{columnData || 'N/A'} </span>)
  },


  //   {
  //     label: 'Joined At',
  //     id: 'created_at',
  //     format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{formatDate(columnData, 'dd-mm-yyyy')}</span>)
  //   },
];

function PaymentMade() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [editModal, setEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [allParams, setAllParams] = useState(getParameters());
  const { contributorPaymentDataForImages } = useSelector(contributorItemsSelector);
  const [addModal, setAddModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const { emailId, contributorId } = useParams();

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // user_type: "2",
      contributor_email: emailId,
      ...allParams,
      ...params
    }
    dispatch(contributorPaymentLists(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, [emailId]);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])


  useEffect(() => {
    if (contributorPaymentDataForImages?.items?.data?.length >= 0) {

      setTableListing(contributorPaymentDataForImages?.items?.data)
    }
  }, [contributorPaymentDataForImages?.items?.data]);


  const handleAdd = (value = false) => {
    setAddModal(value)
  }
  const handleEdit = (value, data={}) => {
    setEditModal(value);
    setSelectedData(data);
  }

  const actionBody = ( data ) => {
    return (
      <>
       <div style={{display: "flex"}}>
          <CustomButton toolTipTittle={'Edit Payment'} 
            onClick={()=>{
              handleEdit(true, data)
            }}
            className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit/></CustomButton>
          {/* <CustomButton toolTipTittle={'Delete Hidden Keyword'} 
            onClick={()=>{
              handleCofirmModalOpen(true)
              setDeleteData(data?.aid)
            }}
           className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonDanger.main', color: 'buttonDanger.contrastText' }}><Delete/></CustomButton> */}
          {/* <CustomButton variant="raised" sx={{ padding: '1px 1px', marginRight: 1, fontSize: '12px', bgcolor: '#fff0', minWidth: 20 }}><Delete/></CustomButton> */}
        </div>
      </>
    );
  };

  return (
    <CustomBox padding={3}>
      <Typography variant='subtitle1' align='center'  sx={{ mb: 3,fontSize:'18px',fontWeight:'600',color:'inherit' }}>Image Payment History</Typography>

      <MatTable
        loading={contributorPaymentDataForImages?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={contributorPaymentDataForImages?.items?.totalCount || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolBarButton={() => {
          return <>
            <CustomButton onClick={() => { handleAdd(true) }} color={'buttonPrimary'} sx={(theme) => ({
              padding: '10px 10px', marginRight: 1,
              [theme.breakpoints.down("md")]: {
                flex: 1,
              }
            })}>Add Payment For images</CustomButton>
          </>
        }}

        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />

      <AddPaymentForImages open={addModal} handleOpen={handleAdd} setPage={setPage} handleLast={getListing} contributorId={emailId} />
      <EditPaymentForImages open={editModal} handleOpen={handleEdit} data={selectedData} setPage={setPage} handleLast={getListing}/>

    </CustomBox>
  );
}

export default PaymentMade;
