import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import UploadVideoCsvModal from '../../Components/Modals/UploadVideoCsvModal';

function AddKeywords({ handleNavParams, handleImageCsvModal, uploadKeywords }) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={5}>
        {/* <CustomTabs tabList={ImageData?.tabs} tabClick={handleTabs} /> */}
        {/* <button onClick={()=>{
         handleNavParams({
          navTitle: "Book Order"
         })
       }}>Hello</button> */}
        {/* <AddImages/> */}
        {/* <Typography variant='h3'>Please click on Upload CSV then select a (.csv) file to upload images.</Typography> */}
      </CustomBox>
      {/* <Divder spacing={1} />
      <ImageTabBody activeTab={tabValue} /> */}
      <Divder spacing={1.2} />
      <UploadVideoCsvModal open={uploadKeywords} handleOpen={handleImageCsvModal}/>
    </Box>
  );
}

export default AddKeywords;
