import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import { useParams, useSearchParams } from 'react-router-dom';
import { isUserPrivilleged } from '../../Services/userApis';
import ContributorBankDetail from './ContributorBankDetail';
import PaymentHistory from './PaymentHistory';
import EditContributorInfo from './EditContributorInfo';

function SelectedContributorTabBody({activeTab}) {
  return (
      <>
       {activeTab == 1 && <EditContributorInfo />}
       {activeTab == 2 && <ContributorBankDetail/>}
       {activeTab == 3 && <PaymentHistory />}
       </>
  )
}

export default SelectedContributorTabBody