import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getNewParameters, getParameters } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Add } from '@material-ui/icons';
import { followUpByCompanyListingsWithRedux, followUpByEmailListingsWithRedux } from '../../Services/CRMFollowups';
import { useDispatch, useSelector } from 'react-redux';
import { followUpsItemSelector } from '../../redux/followUps';

const limit = 10;
const column = [
  {
    label: 'First Name',
    id: 'first_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Last Name',
    id: 'last_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Total Order',
    id: 'total_orders',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {row?.total_orders>=0?row?.total_orders: 'N/A'}
      </span>
    ),
  },
  {
    label: 'Requirement Type',
    id: 'requirement_type',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Status',
    id: 'followup_status',
    format: (columnData) => (
      <Typography variant="span">{columnData?
        (columnData==='0'?'Open':
        columnData==='1'?'Close':
        columnData==='2'?'PClose':
        columnData==='3'?'Sold':
        columnData==='4'?'Upgrade Sold':
        columnData==='5'?'QPack Sold':
        columnData==='6'?'DQuery Sold':
        columnData==='7'?'DPack Sold'
        :'Exclude Sold'
        ):'-'}</Typography>
    ),
  },
  // {
  //   label: 'User Type',
  //   id: 'user_type',
  //   format: (columnData) => (
  //     <span
  //       style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
  //     >
  //       {columnData || 'N/A'}
  //     </span>
  //   ),
  // },
];


function FullUserSearchList({data, handleSelectedData}) {
  const [tableListing, setTableListing] = useState([]);
  const {companyName,state,email,association} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const { loading, searchCompanyUserFollowUpData } = useSelector(followUpsItemSelector);
  const [allParams, setAllParams] = useState(getParameters());
  const [page, setPage] = useState(1);
  // useEffect(()=>{
  //   setTableListing(data)
  // },[data])
  const getListing = async (params={}) => {
    const payload = {
      page: String(page), 
      limit: limit,
      company_name:companyName,
      association:association,
      state:state,
      ...allParams,
      ...params
    }
   // let newPayload={page:String(1),limit:limit,email:email}
    dispatch(followUpByCompanyListingsWithRedux(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, [companyName]);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])


  useEffect(() => {
    if(searchCompanyUserFollowUpData?.items?.data?.rows?.length >= 0){
      setTableListing(searchCompanyUserFollowUpData?.items?.data?.rows)
    }
  }, [searchCompanyUserFollowUpData.items]);



//   let resp = await followUpByEmailListingsNoRedux({
//     email: data?.data?.email,
//     page: 1,
//     limit: 10
// })
// setFollowUpData(resp)

  const actionBody = (data) =>{
 
    return (<>
      <div style={{display: "flex"}}>
        <CustomButton className="noMinWidth" onClick={()=>{
          handleSelectedData(data)
        }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Add/></CustomButton>
        <Link className='textDecoration-none' to={`../../crm/saleEntryHistory/${data?.daily_sale_id}`} target='_blank'>
          <CustomButton className="noMinWidth" sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText'}}><VisibilityIcon/> </CustomButton>
        </Link>

        {/* <CustomButton variant="raised" sx={{ padding: '1px 1px', marginRight: 1, fontSize: '12px', bgcolor: '#fff0', minWidth: 20 }}><Delete/></CustomButton> */}
      </div>
    </>)
  }
  
  return (
    <CustomBox padding={0}>
       <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap', pt: 2, px: 2}}>
          <Typography variant="h5">User Search List</Typography>
       
          {/* <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "10px"}}>
            <CustomButton color={'buttonPrimary'}  onClick={()=>{
            
            }}>
              View All
            </CustomButton>
          </Box> */}
        </Box>
      <Divder spacing={1} />
      <MatTable
        loading={false}
        showCheckbox={false}
        columns={column}
        data={tableListing} 
        // action={actionBody}
        // tableMaxHeight={300}
        page={page}
        perPage={limit}
        total={searchCompanyUserFollowUpData?.items?.data?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
    </CustomBox>
  );
}

export default FullUserSearchList;
