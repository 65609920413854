import { Delete, Edit } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import {
  getNewParameters,
  getObjectSubset,
  getParameters,
} from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import {
  getDownloadTimingData,
  getImagePricing,
} from '../../Services/cmsService';
import { cmsItemsSelector } from '../../redux/cms';
import EditTechnicalDownloadTime from '../Modals/EditTechnicalDownloadTime';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'ID',
    id: 'download_time_id',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'File Size',
    id: 'file_size',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Compressed File Size',
    id: 'compressed_file_size',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: '56K Time',
    id: 'time',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'ISDN',
    id: 'isdn',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          maxWidth: 200,
          width: 'max-content',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Broadband',
    id: 'broadband',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
          wordBreak: 'break-word',
        }}
      >
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'T1',
    id: 'time_one',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          maxWidth: 200,
          width: 'max-content',
          wordBreak: 'break-word',
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
];

function TechnicalDownloadTime() {
  const [tableListing, setTableListing] = useState([]);
  const [expLoading, setExpLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, imagePriceData, downloadTimeData } =
    useSelector(cmsItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedImagePrice, setSelectedImagePrice] = useState(null);
  const [editModal, setEditModal] = useState(false);

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // user_type: "2",
      ...allParams,
      ...params,
    };
    dispatch(getDownloadTimingData(payload));
    // dispatch(getImagePricing(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);
  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getListing();
  }, [allParams]);

  //making data format for table input
  useEffect(() => {
    if (downloadTimeData?.items?.rows?.length >= 0) {
      setTableListing(downloadTimeData?.items?.rows);
    }
  }, [downloadTimeData.items]);
  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            className="noMinWidth buttonDense"
            onClick={() => {
              // handleCofirmModalOpen(true)
              // setDeleteUserData(data?.user_id)
              setSelectedImagePrice(data);
              handleEdit(true);
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            <Edit />
          </CustomButton>
          {/* <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '110px', bgcolor: 'buttonInfo.main' }}>USER DETIALS</CustomButton>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '135px' }}>VIEW FOLLOWUPS</CustomButton>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '150px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>CREATE FOLLOWUPS</CustomButton> */}
          {/* <CustomButton variant="raised" sx={{ padding: '1px 1px', marginRight: 1, fontSize: '12px', bgcolor: '#fff0', minWidth: 20 }}><Delete/></CustomButton> */}
        </div>
      </>
    );
  };

  // const actionHead = (data) => {
  //   return (<div style={{display: "flex"}}>
  //      hi
  //   </div>);
  // };

  const handleEdit = (value = false) => {
    setEditModal(value);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: (theme) => theme.spacing(3), py: (theme) => theme.spacing(2) }}>

        Download Times
      </Box>
      <MatTable
        loading={downloadTimeData?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date', 'identity'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date', 'identity'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={downloadTimeData?.items?.count || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        // toolbarEnable={true}
        // toolBarButton={()=>{
        //   return <CustomButton sx={(theme)=>({ fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
        //     [theme.breakpoints.down("md")]: {
        //       flex: 1,
        //     }
        //    })} onClick={()=>{
        //     getExportFile()
        //   }} loading={expLoading}>Export XLS</CustomButton>
        // }}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
        dropdownFilter={true}
      />
      <EditTechnicalDownloadTime
        open={editModal}
        handleOpen={handleEdit}
        data={selectedImagePrice}
        setPage={setPage}
        handleLast={getListing}
      />
    </>
  );
}

export default TechnicalDownloadTime;
