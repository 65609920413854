export const DashboardData = {
  boxes: [
    {
      subTitle: 'Active User',
      value: '200k',
      percent: '2.5%',
      chart: [30, 10, 135, 60, 149, 60, 70, 30, 135]
    },
    {
      subTitle: 'Pending Order',
      value: '200',
      percent: '2.5%',
      chart: [30, 10, 35, 60, 49, 60, 40, 30, 25]
    },
    {
      subTitle: 'New User',
      value: '4,750',
      percent: '2.5%',
      chart: [30, 10, 35, 60, 49, 60, 70, 30, 35]
    },
  ],
  serverRequest:{
    time: ["14.10", "14.20", "14.30", "14.40", "14.50", "14.60", "15.00", "15.10", "15.20", "15.30"],
    request: [[10, 15, 40, 30, 50, 60, 40, 70, 80, 70], [5, 10, 45, 30, 50, 60, 80, 70, 80, 40]]
  },
  sales:{
    weeks: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    data: [[10, 15, 40, 30, 50, 60, 40]]
  },
  chatSumary:{
    data: [10, 15, 40]
  },
};
