import { Box, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import * as Yup from 'yup';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import ProposalFields from './ProposalFields';
import Divder from '../CommonComp/Divder';
import { imageListingsNoRedux } from '../../Services/imageApis';
import ImageOrderSummary from './ImageOrderSummary';
import { toast } from 'react-toastify';
import { createProposalApi } from '../../Services/userApis';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { commonItemsSelector, setCountryData } from '../../redux/common';
import { loginItemsSelector } from '../../redux/login';
import { crypt, encryptData, findObjectInArray, getDataFromAppSetting } from '../../lib/helper';
import { CreateProposalData } from '../../data/CreateProposalData';
import { starIndustriesItemsSelector } from '../../redux/starIndustries';
import { starIndustriesListings } from '../../Services/starIndustriesApis';
import StarImageSelectionComp from './StarImageSelectionComponent';

const taxPercent = 18;

function CreateStarProposalPage({ userId, email }) {
    const dispatch = useDispatch();
    const loaction = useLocation();
    const { countryData, stateData } = useSelector(commonItemsSelector);
    const { starIndustriesList } = useSelector(starIndustriesItemsSelector);
    const { user } = useSelector(loginItemsSelector);
    const [industryList, setIndustryList] = useState([]);
    const [imagesData, setImagesData] = useState([]);
    const [ivData, setIVData] = useState(null);
    const [newImage, setNewImage] = useState(true);
    const [finalPriceForFlexiblePlan, setFinalPriceForFlexiblePlan] = useState(0);
    const [exclusiveCheck, setExclusiveCheck] = useState(3);
    const [appSettingDurationData, setAppSettingDurationData] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [selectedState, setSelectedState] = useState({});
    const [selectedCountry, setSelectedCountry] = useState({});
    const navigate = useNavigate()
    const [imagesDataError, setImagesDataError] = useState({
        error: false,
        helperText: "Please select image quality for all selected images('WEB','SMALL','MEDIUM','LARGE')"
    });
    useEffect(() => {
        if (stateData && Array.isArray(stateData?.items)) {
            let data = stateData?.items?.map((item) => {
                return {
                    label: item?.state_name,
                    value: item?.state_id,
                };
            });
            setStateList(data);
        }
    }, [stateData]);
    const handleConfirm = async (values, { resetForm }) => {

        let hostName = window.location.origin;
        let payload = {
            ...values,
        }

        if (values.proposal_type == 'NPlan') {
            let imageUpdateData = imagesData?.map((item) => {
                return {
                    ...item,
                    is_star:"1",
                    type: item?.type?.toLowerCase() == 'video' ? "2" : "1"
                }
            })
            let isValid = handleImageConfirm(imageUpdateData)
            if (isValid) {
                payload = {
                    ...values,
                    images: imageUpdateData,
                    total_images: imageUpdateData.length,
                    right: 'Non-Exclusive',
                    state: selectedState.state_name,
                }
                let resp = await createProposalApi(payload)
                if (resp) {
                    let encryptProposal = crypt('proposal_id', resp.data)
                    // navigate(`../../userDetail/${userId}`, { replace: true })
                    window.open(`${hostName}/proposal/success?proposal_id=${encryptProposal}`, "_blank")
                    navigate("/order?tab=5")
                }
            } else {
                toast.error('Either you forgot to add images for proposal or maybe skiped to select image quality', {
                    autoClose: 3000
                })
            }
        }
    };

    let gstValidationSchema = {};
    if (Object.keys(selectedState).length) {
        gstValidationSchema = {
            gst_number: Yup.string()
                .trim()
                .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    "Please enter valid GST Number"
                )
                .test(
                    "isValidGST",
                    "Please enter correct GST Number",
                    function (value) {
                        let isValid = value?.startsWith(
                            selectedState?.state_code?.toString().length > 1
                                ? selectedState?.state_code
                                : `0${selectedState?.state_code}`
                        );
                        return value === "" || value === null || value === undefined
                            ? true
                            : isValid;
                    }
                ),
        };
    }

    const formik = useFormik({
        initialValues: {
            // email: '',
            user_name: user?.userDetails?.email || '',
            client_name: '',
            heading: '',
            amount: '0',
            discount: 0,
            discounted_amount: '',
            duration: "",
            service_tax: 0,
            final_amount: 0,
            credit_period: "0",
            gst_number: '',
            state: '',
            multiState: "",
            country: '',
            industry: '',
            territory: 0,
            proposal_type: 'NPlan',
            proposal_of: 'image',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            discount: Yup.number('It should be a number.')
                .min(0, "Value should not be lesser than 0"),
            credit_period: Yup.string().required('Credit period is required.'),
            state: Yup.string().required('This field is required'),
            industry: Yup.string().required('This field is required'),
            heading: Yup.string().required('This field is required'),
            user_name: Yup.string().required('This field is required'),
            ...gstValidationSchema
        }),
        onSubmit: handleConfirm,
    });
    const handleImagesData = (data, index) => {
        let initialData = [...imagesData]
        setImagesData(data)
        if (!data.length) {
            formik.setFieldValue("amount", 0)
            setNewImage(true)
        } else {
            let data1 = data[data.length - 1]
            if (!data1.price)
                setNewImage(false)
            else
                handleImageConfirm(data)
        }
    }
    const handleStateChange = (val) => {
        let stateSelected = stateData?.items?.filter(
            (item) => item.state_name == val
        );
        if (stateSelected.length) {
            setSelectedState(stateSelected[0]);
        }
    };
    const checkValidation = (data) => {
        let isValid = true;
        for (var i = 0; i < data?.length; i++) {
            let flag = Object?.keys(data[i])?.every((k) => data[i][k] != null && data[i][k] != undefined && data[i][k] != '')
            if (!flag) {
                isValid = false;
                break;
            }
        }
        return isValid
    }
    const calculateImagePrice = (data) => {
        const res = data.reduce(function (acc, obj) { return acc + Number(obj.price); }, 0);
        return res || 0
    }

    const handleUpdateImagesData = (data, index) => {
        let initialData = [...imagesData]
        initialData[index] = {
            ...initialData[index],
            ...data,
        }
        setImagesData(initialData)
        setNewImage(true);
        handleImageConfirm(initialData)
    }

    const handleDelete = (index) => {
        let updatedData = [...imagesData]
        updatedData.splice(index, 1)
        setImagesData(updatedData)
        if (updatedData?.length != 0) {
            let data = updatedData?.filter((item) => {
                return item?.price
            })
            let price = calculateImagePrice([...data])
            formik.setFieldValue("amount", price)

        } else {
            formik.setFieldValue("amount", 0)
        }
    }
    const getIndustryListing = async (params = {}) => {
        const payload = {
            // page: String(1),
            // limit: 30,
            order_status: String(1),
            // ...allParams,
            ...params
        }
        dispatch(starIndustriesListings(payload))
        // setPage(1)
    }
    useEffect(() => {
        getIndustryListing();
    }, []);
    //making data format for table input
    useEffect(() => {
        if (starIndustriesList?.items?.data?.length >= 0) {
            let data = starIndustriesList?.items?.data?.map((item) => {
                return {
                    label: item?.category,
                    value: item?.star_industry_id,
                };
            });
            setIndustryList(data)
        }
    }, [starIndustriesList?.items]);
    const handleImageConfirm = (data) => {
        setNewImage(true)
        try {
            let isValid = false;
            if (data?.length) {
                isValid = checkValidation(data[data.length - 1])
                setImagesDataError({
                    ...imagesDataError,
                    error: !isValid,
                    helperText: "Please select duration and states"
                })
            } else {
                setImagesDataError({
                    ...imagesDataError,
                    error: !isValid,
                    helperText: "Please select images for proposal"
                })
            }
            if (isValid) {
                let price = calculateImagePrice([...data])
                formik.setFieldValue("amount", price)
            } else {
                let newData = data?.filter((item) => {
                    return item.price
                })
                let price = calculateImagePrice([...newData])
                formik.setFieldValue("amount", price)
            }
            return isValid
        } catch (e) { }
    }
    const getImageListing = async (inputValue) => {
        if (inputValue && inputValue.length > 1) {
            if (newImage == true) {
                let data = await imageListingsNoRedux({ search: inputValue, type: formik?.values?.proposal_of, content_type: exclusiveCheck })
                // let data = await imageListingsNoRedux({ search: inputValue, type: 2 })
                let updatedData = data?.map((item, index) => {
                    return {
                        value: item?.Name ? item?.Name : item?.video_name,
                        label: item.Name ? item.Name : item?.video_name,
                        rank: item.rank,
                        shootid: item.shootid,
                        image_name: item?.Name ? item?.Name : item?.video_name,
                        quality: null,
                        price: null,
                        category: item?.category,
                        type: item?.Name ? 'image' : 'video',
                        content_type: exclusiveCheck,
                        ...((exclusiveCheck == 3) && {
                            // duration: formik.values.duration,
                            quality: "LARGE",
                            price: 0,
                            right: 'Non-Exclusive'
                        })
                    }
                })
                return updatedData || []
            }
            else {
                toast.error("Click 'Add' to continue..")
            }
        }
    }
    useEffect(() => {
        let discount = Number(formik.values.discount)
        let amountInr = Number(formik.values.amount)
        let amountPay = Number(formik.values.discounted_amount)
        let service_tax = Number(formik.values.service_tax)
        if (discount >= 0 && amountInr >= 0) {
            let updatedAmountPay = amountInr - discount
            formik.setFieldValue("discounted_amount", updatedAmountPay)
            if (amountPay >= 0 && service_tax >= 0) {
                let updatedTax = (amountPay * taxPercent) / 100
                formik.setFieldValue("service_tax", updatedTax)
                formik.setFieldValue("final_amount", updatedAmountPay + updatedTax)
            }
        }
    }, [formik.values.discount, formik.values.amount, formik.values.discounted_amount, formik.values.service_tax])

    const handleExclusiveCheckState = (val) => {
        setExclusiveCheck(val)
        if (val === "3" || val == "2")
            formik.setFieldValue("proposal_type", "NPlan");
    };
    useEffect(() => {
        formik.setFieldTouched("gst_number", true, true);
    }, [selectedState])

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={8} xl={9}>
                    <form onSubmit={formik.handleSubmit} className='fontWeight600'>
                        <CustomBox padding={3}>
                            <Typography variant='h3' sx={{ mr: "auto", mb: 2 }}>
                                Your Proposal Cart Detail
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box display={'flex'} sx={{ justifyContent: { sm: 'start', xs: 'start' } }}>
                                        <CustomInput
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                classes: {
                                                    notchedOutline: 'rounded',
                                                },
                                            }}
                                            select
                                            SelectProps={{
                                                native: true,
                                                style: {
                                                    height: 40
                                                }
                                            }}
                                            name="type"
                                            label="Type"
                                            placeholder="Type"
                                            value={exclusiveCheck}
                                            onChange={(e) => {
                                                handleExclusiveCheckState(e.target.value)
                                                formik.handleChange(e)
                                            }}
                                            style={{ maxWidth: 150, marginRight: '10px' }}
                                        >
                                            <option key={'Star'} value={3}>Star</option>
                                        </CustomInput>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divder spacing={1} />
                            <StarImageSelectionComp data={imagesData} onConfirm={handleImageConfirm} updateData={handleUpdateImagesData} handleDelete={handleDelete} proposal_of={formik?.values?.proposal_of} formik={formik}
                                handleData={handleImagesData} getImageListing={getImageListing} error={imagesDataError.error} helperText={imagesDataError.helperText} exclusiveCheck={exclusiveCheck} extraSection={(currentItem, index) => {
                                }} />
                            <Divder spacing={1} />

                            <Typography variant='h5'>Detail</Typography>
                            <Divder spacing={1} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <ProposalFields
                                        name="client_name"
                                        label="Client Name"
                                        placeholder="Client Name"
                                        onChange={formik.handleChange}
                                        value={formik.values.client_name}
                                        error={
                                            formik.touched.client_name &&
                                            Boolean(formik.errors.client_name)
                                        }
                                        helperText={
                                            formik.touched.client_name && formik.errors.client_name
                                        }
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ProposalFields
                                        name="heading"
                                        label="Heading"
                                        placeholder="Heading"
                                        onChange={formik.handleChange}
                                        value={formik.values.heading}
                                        error={
                                            formik.touched.heading &&
                                            Boolean(formik.errors.heading)
                                        }
                                        helperText={
                                            formik.touched.heading && formik.errors.heading
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ProposalFields
                                        name="state"
                                        label="State"
                                        placeholder="State"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        select
                                        value={formik.values.state}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: 'rounded',
                                            },
                                        }}

                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            handleStateChange(e.target.value);
                                        }}
                                        error={
                                            formik.values.country == "India" ?
                                                formik.touched.state &&
                                                Boolean(formik.errors.state)
                                                : null
                                        }
                                        helperText={
                                            formik.values.country == "India" ?
                                                formik.touched.state && formik.errors.state
                                                : null
                                        }
                                    >
                                        <option key={'Select State'} value={''}>
                                            Select state
                                        </option>
                                        {stateList &&
                                            stateList?.map((state) => {
                                                return (
                                                    <option key={state.value} value={state.label}>
                                                        {state.label}
                                                    </option>
                                                );
                                            })}
                                    </ProposalFields>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ProposalFields
                                        name="industry"
                                        label="Industry"
                                        id="industry"
                                        placeholder="Industry"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        select
                                        value={formik.values.industry}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: 'rounded',
                                            },
                                        }}
                                        onChange={(e) => formik.setFieldValue("industry", e.target.value)}
                                        error={
                                            formik.touched.industry &&
                                            Boolean(formik.errors.industry)
                                        }
                                        helperText={
                                            formik.touched.industry && formik.errors.industry
                                        }
                                    >
                                        <option key={'Select Industry'} value={''}>
                                            Select Industry
                                        </option>

                                        {industryList &&
                                            industryList?.map((industry) => (

                                                <option key={industry.value} value={industry.label}>
                                                    {industry.label}
                                                </option>
                                            ))}
                                    </ProposalFields>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ProposalFields
                                        name="amount"
                                        label="Amount INR"
                                        placeholder="Amount INR"
                                        disabled
                                        value={formik.values.amount}
                                        error={
                                            formik.touched.amount &&
                                            Boolean(formik.errors.amount)
                                        }
                                        helperText={
                                            formik.touched.amount && formik.errors.amount
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ProposalFields
                                        name="discount"
                                        label="Discount INR"
                                        placeholder="Discount INR"
                                        value={formik.values.discount}
                                        onChange={(e) => {
                                            if (e.target.value <= formik.values.amount) {
                                                formik.setFieldValue('discount', e?.target?.value)
                                            }
                                        }}
                                        onBlur={(e) => {
                                            if (!e?.target?.value) {
                                                formik.setFieldValue('discount', 0)
                                            }
                                        }}
                                        error={
                                            formik.touched.discount &&
                                            Boolean(formik.errors.discount)
                                        }
                                        helperText={
                                            formik.touched.discount && formik.errors.discount
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ProposalFields
                                        name="discounted_amount"
                                        label="Amount payable INR"
                                        placeholder="Amount payable INR"
                                        disabled
                                        value={formik.values.discounted_amount}
                                        error={
                                            formik.touched.discounted_amount &&
                                            Boolean(formik.errors.discounted_amount)
                                        }
                                        helperText={
                                            formik.touched.discounted_amount && formik.errors.discounted_amount
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ProposalFields
                                        name="service_tax"
                                        label={`Tax (${taxPercent}) INR`}
                                        placeholder="Tax"
                                        disabled
                                        value={formik.values.service_tax}
                                        error={
                                            formik.touched.service_tax &&
                                            Boolean(formik.errors.service_tax)
                                        }
                                        helperText={
                                            formik.touched.service_tax && formik.errors.service_tax
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ProposalFields
                                        name="final_amount"
                                        label="Net Payable INR"
                                        placeholder="Net Payable INR"
                                        disabled
                                        value={formik.values.final_amount}
                                        error={
                                            formik.touched.final_amount &&
                                            Boolean(formik.errors.final_amount)
                                        }
                                        helperText={
                                            formik.touched.final_amount && formik.errors.final_amount
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ProposalFields
                                        name="credit_period"
                                        label="Credit Period"
                                        placeholder="Credit Period"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        select
                                        value={formik.values.credit_period}
                                        onChange={formik.handleChange}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: 'rounded',
                                            },
                                        }}
                                        error={
                                            formik.touched.credit_period &&
                                            Boolean(formik.errors.credit_period)
                                        }
                                        helperText={
                                            formik.touched.credit_period && formik.errors.credit_period
                                        }
                                    >
                                        {CreateProposalData?.createPeriod?.map((state) => {
                                            return (
                                                <option key={state.value} value={state.label}>
                                                    {state.label} {state.label == '0' ? 'day' : 'days'}
                                                </option>
                                            );
                                        })}
                                    </ProposalFields>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ProposalFields
                                        name="gst_number"
                                        label="GSTIN No."
                                        placeholder="GSTIN No."
                                        value={formik.values.gst_number}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            classes: {
                                                notchedOutline: 'rounded',
                                            },
                                        }}
                                        onChange={(e) => {
                                            formik.setFieldTouched("gst_number", true, true);
                                            formik.setFieldValue("gst_number", e.target.value.toUpperCase());
                                        }}
                                        inputProps={{
                                            maxLength: 15
                                        }}
                                        error={
                                            formik.touched.gst_number && Boolean(formik.errors.gst_number)
                                        }
                                        helperText={
                                            formik.touched.gst_number && formik.errors.gst_number
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} textAlign="right">
                                    <CustomButton type="submit" color={'buttonPrimary'}>
                                        Create Proposal
                                    </CustomButton>
                                </Grid>
                            </Grid>
                        </CustomBox>
                    </form>
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                    <CustomBox padding={3}>
                        <Typography variant='h5' className='fontWeight600' textAlign={'center'}>Order Proposal Cart Amount</Typography>
                        <Divder spacing={1} />
                        <Typography variant='p' component={'p'} textAlign={'center'}>Order Proposal Cart Amount</Typography>
                        <Divder spacing={1} />
                        <Typography variant='h2' component={'p'} textAlign={'center'}>INR {formik.values.final_amount}</Typography>
                        <Divder spacing={0.2} />
                        <Typography variant='p' component={'p'} textAlign={'center'}>(Inclusive of tax)</Typography>
                    </CustomBox>
                    <Divder spacing={1.5} />
                    <CustomBox padding={3}>
                        <Typography variant='h3' className='fontWeight600'>Order Summary</Typography>
                        <Divder spacing={1} />
                        <ImageOrderSummary handleDelete={handleDelete} imagesData={imagesData} exclusiveCheck={exclusiveCheck} extraSection={(currentItem, index) => {
                        }} />

                    </CustomBox>
                </Grid>
            </Grid>
        </div>
    )
}

export default CreateStarProposalPage