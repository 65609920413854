import {
  DashBoardIcon,
  UserIcon,
  MasterIcon,
  PhotographerIcon,
  KeywordIcon,
  ImageIcon,
  OrderIcon,
  LogsIcon,
  CRMIcon,
  ReportsIcon,
  Settings,
  Send,
  CategoryIcon,
  CompanyIcon,
  StarIndustriesIcon,
  VideoCameraBack,
  People
} from '../Asset/svg';
import { getItemFromStorage } from '../storage/Storage';

const IconArr = [DashBoardIcon,
  // MasterIcon,
  UserIcon,
  PhotographerIcon,
  KeywordIcon,
  ImageIcon,
  VideoCameraBack,
  OrderIcon,
  OrderIcon,
  KeywordIcon,
  Settings,
  Send,
  People,
  LogsIcon,
  CRMIcon,
  CategoryIcon,
  CompanyIcon,
  StarIndustriesIcon,
  ReportsIcon,
  ReportsIcon,
  People,
  People,

]

export { IconArr };
let sideMenu = [
  {
    pathName: '/dashboard',
    label: 'Dashboard',
    icon: 0,
    value: 'dashboard'
  },
  // {
  //   pathName: '/master',
  //   label: 'Master',
  //   icon: 1,
  //   value:'master'
  // },
  {
    pathName: '/user',
    label: 'Users',
    icon: 1,
    value: 'users'
  },
  {
    pathName: '/contributor',
    label: 'Contributors',
    icon: 2,
    value: 'contributors'
  },
  {
    pathName: '/keywords',
    label: 'Keywords',
    icon: 3,
    value: 'keywords'
  },
  {
    pathName: '/image',
    label: 'Images',
    icon: 4,
    value: 'images'
  },
  {
    pathName: '/videos',
    label: 'Videos',
    icon: 5,
    value: 'videos'
  },
  {
    pathName: '/order',
    label: 'Orders',
    icon: 6,
    value: 'orders'
  },
  // {
  //   pathName: '/irn',
  //   label: 'IRN',
  //   icon: 8,
  //   value:'irn'
  // },
  {
    pathName: '/subscription',
    label: 'Subscriptions',
    icon: 7,
    value: 'subscriptions'
  },
  {
    pathName: '/settings',
    label: 'Settings',
    icon: 8,
    value: 'settings'
  },
  {
    pathName: '/queryList',
    label: 'Contact Us',
    icon: 9,
    value: 'contact_us'
  },
  {
    pathName: '/adminUsersAndAccess',
    label: 'Admin Users & Access',
    icon: 10,
    value: 'admin_users'
  },
  {
    pathName: '/logs',
    label: 'Logs',
    icon: 11,
    value: 'logs'
  },
  {
    pathName: '/crm',
    label: 'CRM',
    icon: 12,
    value: 'crm'
  },
  {
    pathName: '/category',
    label: 'Categories',
    icon: 13,
    value: 'categories'
  },
  {
    pathName: '/company',
    label: 'Companies',
    icon: 14,
    value: 'companies'
  },
  {
    pathName: '/starIndustries',
    label: 'Star Industries',
    icon: 15,
    value: 'star_indutries'
  },
  {
    pathName: '/reports',
    label: 'Reports',
    icon: 16,
    value: 'report'
  },
  {
    pathName: '/cms',
    label: 'CMS',
    icon: 17,
    value: 'cms'
  },
  {
    pathName: '/model',
    label: 'Models',
    icon: 20,
    value: 'model'
  },
  // {
  //   pathName: '/new_model',
  //   label: 'New Model',
  //   icon: 20,
  //   value: 'new_model'
  // },
];


export { sideMenu };


export const MenuList = ['Dashboard',
  // 'Master',
  'Users', 'Contributors', 'Keywords', 'Images', 'Videos', 'Orders', 'IRN', 'Subscriptions', 'Settings', 'Contact Us', 'Admin Users & Access', 'Logs', 'CRM', 'Categories', 'Companies', 'Star Industries', 'Reports', 'CMS', 'Models']

export const NewMenuList = [
  { label: 'Dashboard', value: 'dashboard' },
  //  {label:'Master',value:'master'},
  { label: 'Users', value: 'users' },
  { label: 'Contributors', value: 'contributors' },
  { label: 'Keywords', value: 'keywords' },
  { label: 'Images', value: 'images' },
  { label: 'Videos', value: 'videos' },
  { label: 'Orders', value: 'orders' },
  { label: 'Subscriptions', value: 'subscriptions' },
  { label: 'IRN', value: 'irn' },
  { label: 'Settings', value: 'settings' },
  { label: 'Contact Us', value: 'contact_us' },
  { label: 'Admin Users & Access', value: 'admin_users' },
  { label: 'Logs', value: 'logs' },
  { label: 'CRM', value: 'crm' },
  { label: 'Categories', value: 'categories' },
  { label: 'Companies', value: 'companies' },
  { label: 'Star Industries', value: 'star_indutries' },
  { label: 'Reports', value: 'report' },
  { label: 'CMS', value: 'cms' },
  { label: 'Models', value: 'model' },
  // {label:'New model', value:'new_model'}
]


//model