import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT } from '../../data/constants';
import { crypt, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { imageListingItemsSelector } from '../../redux/image';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { imageListings } from '../../Services/imageApis';
import { AllOrderListing } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import EditImageDetailModal from '../Modals/EditImageDetailModal';
import ImageKeywordModal from '../Modals/ImageKeywordModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SuspendImageModal from '../Modals/SuspendImageModal';
import UploadSuspendImagesModal from '../Modals/UploadSuspendImagesModal';
import MatTable from '../Tables/MatTable';
import AllFilters from '../Order/AllFilters';
import { getListOfImageKeywords } from '../../Services/keywordsApis';


const column = [
  {
    label: 'Image',
    id: 'image_url',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
      >
        <img style={{ maxWidth: 200, maxHeight: 200 }} src={`${columnData}`} />
      </span>
    ),
  },
  {
    label: 'Image ID',
    id: 'Name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap', }} onClick={() => {
      }} >
        {columnData}
      </span>
    ),
  },
  {
    label: 'Rank',
    id: 'rank',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Rank1',
    id: 'rank1',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },

  {
    label: 'Contributor',
    id: 'is_contributor',
    format: (columnData, rowIndex, formatCallback, func, errors, row) => (<span style={{ display: 'inline-block' }}>
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>IsContributor:</Typography> {columnData == 1 ? 'yes' : 'No'}</span><br/>
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>Name:</Typography> {row?.contributor}</span>
    </span>)
  },

  {
    label: 'Shoot ID',
    id: 'shootid',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },

  {
    label: 'Sold Count',
    id: 'sold_count',
    format: (columnData) => (
      <span style={{ display: 'block', textAlign: "center", whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
    nextLine:true,
  },
 
  {
    label: 'Suspended Date',
    id: 'suspendate',
    format: (columnData) => (
      <>
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          {formatDate(columnData, 'dd/mm/yyyy')}
        </span>

      </>
    ),
    nextLine:true,
  },
];

function AllImages() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [imageEditModal, setImageEditModal] = useState(false);
  const [imageSuspendModal, setImageSuspendModal] = useState(false);
  const [imageKeyword, setImageKeyword] = useState(false);
  const [editImageData, setEditImageData] = useState({});
  const { loading, allImages } = useSelector(imageListingItemsSelector);
  const dispatch = useDispatch();
  const [suspendModalBulk,setSuspendModalBulk]=useState(false);
  const [currentImageName, setCurrentImageName] = useState('');
  const [keywordForTheChoosenImage, setKeywordForTheChoosenImage] = useState([]);
  const [limit, setLimit] = useState(20);
  const navigate = useNavigate();
  const location = useLocation();
  const [filtersArray, setFiltersArray] = useState([
   
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          // newState[index].value = e.target.value;
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[0].value = '';

      return newState;
    });
  };
  const handleAdditionButton = () => {
    // if (userId) {
    //   if (singleUserCartDetail.items?.wishlist?.rows?.length) {

    //     navigate({

    //       pathname: `/checkout`,

    //       search: `?userId=${crypt('user_Id', userId)}`,

    //     });

    //   } else {

    //     toast.error('Please add images/videos to cart first.');

    //   }

    // } else {

    //   toast.warning('Fetching data please wait for sometime.');

    // }



  }
  const handleAddNewItem = () => {
    setSuspendModalBulk(true)
  }

  const handleModal = (value = false) => {
    setImageEditModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const handleSuspendModal = (value = false) => {
    setImageSuspendModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const handleSuspendModalBulk = (value = false) => {
    setSuspendModalBulk(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const handleImageKeywordModal = (value = false) => {
    setImageKeyword(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      insertion_status: 'old',
      // order_status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(imageListings(payload));
    setPage(Number(payload.page));
    setEditImageData({});
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  const handleAllKeywordsForimage = async (data) => {
    let response = await getListOfImageKeywords(data?.Name);
    if (response?.status == 200) {
      setKeywordForTheChoosenImage(response?.data);
      handleImageKeywordModal(true)
    }
  }

  const handleAllFunctions = (data) => {
    setEditImageData(data);
    handleAllKeywordsForimage(data);
  }

  //making data format for table input
  useEffect(() => {
    if (allImages?.items?.users?.length >= 0) {
      let data = allImages?.items?.users?.map((item) => {
        return {
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.Name}.jpg`,
          Name: item?.Name,
          contributor: item,
          rank: item.rank, //=>edit
          rank1: item.rank1,
          shootid: item.shootid,
          is_contributor: item?.is_contributor,
          pricing: item.pricing, //=>edit
          suspendate: item.suspendate,
          createddate: item?.createddate,
          contributor: item.contributor,
          modelreleaseid:item?.modelreleaseid,
          available: item.available,
          type: item.type, //=>edit dropdown value [I, S]
          createddate: item.createdAt,
          imgstatus: item.imgstatus,
          suspend: item.imgstatus,
          rank1: item.rank1, //=>edit
          keywords: item.Name, //=>edit
          sold_count: item.sold_count,
          is_star: item?.is_star,
          watermark: item?.watermark,
          sold_count:item?.sold_count, 
        };
      });
      setTableListing(data);
    }
  }, [allImages.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <Link className='textDecoration-none' to={`${crypt(ApiConfig.REACT_APP_SALT + 'image_name', data?.Name)}`} target={'_blank'} >
            <CustomButton className="buttonDense" onClick={() => {
            }} sx={{ bgcolor: 'buttonInfo.main' }}>Keywords</CustomButton>
          </Link>
          <CustomButton className="buttonDense" onClick={() => {
            handleSuspendModal(true);
            setEditImageData(data);
          }}>Suspend</CustomButton>
          <CustomButton
            className="buttonDense"
            sx={{
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
            onClick={() => {
              handleModal(true);
              setEditImageData(data);
            }}
          >
            Edit
          </CustomButton>
        </div>
      </>
    );
  };

  return (
    <>
      <MatTable
        loading={allImages?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20, 50, 100, 200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={allImages?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}

        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            hasAddNew={true}
            handleAddNewModal={handleAddNewItem}
            addText={'Bulk Suspend'}
            // handleAddtionalButton={handleAdditionButton}
            // addionalButton={true}
            // addtionalText="Checkout"
          />
        )}
        disableSearch
      />
      <EditImageDetailModal
        open={imageEditModal}
        handleOpen={handleModal}
        data={editImageData}
        getListing={getListing}
      />
      <SuspendImageModal
        open={imageSuspendModal}
        handleOpen={handleSuspendModal}
        data={editImageData}
        getListing={getListing}
      />
      <>{
        
        <UploadSuspendImagesModal 
        open={suspendModalBulk}
        handleOpen={handleSuspendModalBulk}
        // data={editImageData}
        getListing={getListing}
        
        />
      }</>

    </>
  );
}

export default AllImages;
