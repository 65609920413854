import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { deleteFromWishlist, temporaryUserListing, userWishlistApi } from '../../Services/userApis';
import { useDispatch, useSelector } from 'react-redux';
import { addCompanyGroup, companyGroupsListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { imageListingsNoRedux } from '../../Services/imageApis';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import PreviewImageComp from '../ProposalManagement/PreviewImageComp';
import { commonItemsSelector, setCountryData } from '../../redux/common';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import CustomIconButtons from '../CommonComp/Buttons/IconButtons';
import Favorite from '@mui/icons-material/Favorite';
import { starIndustriesItemsSelector } from '../../redux/starIndustries';
import { starIndustriesListings } from '../../Services/starIndustriesApis';
import ProposalFields from '../ProposalManagement/ProposalFields';
import { getState } from '../../Services/commonService';
import { getDataFromAppSetting } from '../../lib/helper';

const limit = DATA_LIMIT;

function AddToCartWishListModal({ open, handleOpen, heading, from, handleAddFunctionality, initialData, userId }) {
  const [loading, setLoading] = useState(false);
  const selectRef=useRef('');
  const [imagesData, setImagesData] = useState(initialData);
  const { countryData, stateData } = useSelector(commonItemsSelector);
  const { appSetting } = useSelector(commonItemsSelector);
  const { starIndustriesList } = useSelector(starIndustriesItemsSelector);
  const [countryList, setCountryList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [countryShortName, setCountryShortName] = useState('in');
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [appSettingDurationData, setAppSettingDurationData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [finalPriceForFlexiblePlan, setFinalPriceForFlexiblePlan] = useState(0);
  const [type, setType] = useState(imagesData?.length > 0 ? imagesData[0]?.type?.toLowerCase() : 'image')
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let priceCalculation = 0;
    if (type == 'star') {
      priceCalculation = handleModifyPricing(selectedItem);
    }
    let resp = await handleAddFunctionality(selectedItem, selectedIndex, imagesData.length, values, selectedState, priceCalculation)
    if (resp) {
      handleDelete(selectedIndex)
      dispatch(userWishlistApi(userId, {
        page: 1,
        limit: 5,
      }));
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      state: [],
      country: '',
      industry: '',
      duration: '',
      is_star: "0",
      license: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      country: type == "star" ? Yup.string().required('Country is required.') : null,
      // state: selectedCountry.label === "India" ? Yup.string().required('This field is required') : null,
      duration: type == "star" ? Yup.string().required('Duration is required.') : null,
      industry: type == "star" ? Yup.string().required('Industry is required.') : null,
      // ...gstValidationSchema
      state: type == "star" ? Yup.array().when("country", {
        is: (v) => v == 'India',
        then: Yup.array().min(1, 'State is required'),
        otherwise: Yup.array().notRequired()
      }) : null,
    }),
    onSubmit: handleConfirm,
  });

  useEffect(() => {
    if (appSetting && appSetting?.items) {
      const durationPriceDataFromAppSetting = getDataFromAppSetting(
        appSetting?.items?.features?.stars,
        "key_name",
        "duration"
      );
      const durationData = JSON.parse(
        durationPriceDataFromAppSetting[0]?.key_value || "{}"
      );
      const outputArray = [];
      for (const label in durationData) {
        const value = durationData[label];
        outputArray.push({ label, value });
      }
      setAppSettingDurationData(outputArray);
    }
  }, [appSetting]);

  useEffect(() => {
    if (countryData?.items?.length > 0) {
      let modifyData = [...countryData?.items];
      const indiaIndex = modifyData.findIndex(
        (obj) => obj.country_name === "India"
      );
      if (indiaIndex !== -1) {
        formik.setFieldValue("country", modifyData[indiaIndex]?.country_name);
        dispatch(getState(modifyData[indiaIndex]?.country_id));
        const movedArray = modifyData.splice(indiaIndex, 1);
        modifyData.unshift(...movedArray);
        modifyData.splice(1, 0, {
          country_id: 999,
          country_name: "Worldwide",
          country_short_name: "",
          status: "1",
          states_available: "0",
        });
        let data = modifyData?.map((item) => {
          return {
            label: item?.country_name,
            value: item?.country_id,
          };
        });
        setCountryList(data);
        setCountryShortName(data[0]?.country_short_name?.toLowerCase())
        setCountryData(modifyData);
      }
    }
  }, [countryData,type]);

  useEffect(() => {
    getStateData();
  }, [stateData]);

  const getStateData = async () => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  };

  const getImageListing = async (inputValue) => {
    if (inputValue && inputValue.length > 1) {
      let data = await imageListingsNoRedux({ search: inputValue, type: type, content_type: type == "star" ? 3 : 1 })
      let updatedData = data?.map((item, index) => {
        return {
          value: item?.Name ? item?.Name : item?.video_name,
          label: item.Name ? item.Name : item?.video_name,
          rank: item.rank,
          sno: item?.sno ? item?.sno : item?.video_id,
          shootid: item.shootid,
          image_name: item?.Name ? item?.Name : item?.video_name,
          quality: null,
          price: null,
          type: item?.Name ? 'image' : 'video',
          category: item?.category ? item?.category : ''
        }
      })

      return updatedData || []
    }
  }
  const getStateListing = async (inputValue) => {
    if (stateList) {
      let updatedData = stateList?.map((state, index) => {
        return {
          value: state?.value,
          label: state.label,
        }
      })
      return updatedData || []
    }
  }
  useEffect(() => {
    if (initialData.length) {
      setImagesData(initialData)
    }
  }, [initialData])

  const handleImagesData = (data, index) => {
    setImagesData(data)
  }

  const handleUpdateImagesData = (data, index) => {
    let initialData = [...imagesData]
    initialData[index] = {
      ...initialData[index],
      ...data
    }
    setImagesData(initialData);
  }
  const handleStateChange = (val) => {
    let stateSelected = stateData?.items?.filter(
      (item) => item.state_name == val.label
    );
    if (stateSelected.length) {
      setSelectedState([...stateSelected, stateSelected[0]]);
    }
  };
  const getIndustryListing = async (params = {}) => {
    const payload = {
      order_status: String(1),
      ...params
    }
    dispatch(starIndustriesListings(payload))
  }

  useEffect(() => {
    getIndustryListing();
  }, []);
  useEffect(() => {
    if (starIndustriesList?.items?.data?.length >= 0) {
      let data = starIndustriesList?.items?.data?.map((item) => {
        return {
          label: item?.category,
          value: item?.star_industry_id,
        };
      });
      setIndustryList(data)
    }
  }, [starIndustriesList?.items]);
  const handleDelete = (index) => {
    let updatedData = [...imagesData]
    updatedData.splice(index, 1)
    setImagesData(updatedData);

  }

  const handleType = (value) => {
    setType(value);
  }
  const handleChangeCountry = async (e) => {
    formik.setFieldValue("country", e.target.value);
    formik.setFieldValue("state", []);
    setSelectedCountry(e.target.value)
    let country_id = 0;
    countryData?.items?.filter((val) => {
      if (val.country_name === e.target.value) {
        country_id = val.country_id;
        setCountryShortName(val?.country_short_name?.toLowerCase())
      }
    });
  };
  const handleModifyPricing = (item) => {
    let category = item?.category;
    if (formik.values.duration) {
      const categoryPriceDataFromAppSetting = getDataFromAppSetting(
        appSetting?.items?.features?.stars,
        "key_name",
        "category"
      );
      const differentCategoryPrices =
        categoryPriceDataFromAppSetting?.length > 0 &&
        JSON.parse(categoryPriceDataFromAppSetting[0]?.key_value || "");
      const selectedCategoryPrice =
        differentCategoryPrices[category];
      const durationPriceDataFromAppSetting = getDataFromAppSetting(
        appSetting?.items?.features?.stars,
        "key_name",
        "duration"
      );
      const differentDurationPrices =
        durationPriceDataFromAppSetting?.length > 0 &&
        JSON.parse(durationPriceDataFromAppSetting[0]?.key_value || "");
      const selectedDurationPrice = formik.values.duration;

      const stateWisePriceDataFromAppSetting = getDataFromAppSetting(
        appSetting?.items?.features?.stars,
        "key_name",
        "statewise"
      );
      const differentStateWisePrices =
        stateWisePriceDataFromAppSetting?.length > 0 &&
        JSON.parse(stateWisePriceDataFromAppSetting[0]?.key_value || "");

      const countryPriceDataFromAppSetting = getDataFromAppSetting(
        appSetting?.items?.features?.stars,
        "key_name",
        "countrywise"
      );
      const differentCountryPrices =
        countryPriceDataFromAppSetting?.length > 0 &&
        JSON.parse(countryPriceDataFromAppSetting[0]?.key_value || "");

      let finalPriceForOrder = 0;
      if (formik.values.country !== "Worldwide") {
        let tempValue =
          Number(selectedCategoryPrice) *
          Number(differentCountryPrices?.single);

        finalPriceForOrder = finalPriceForOrder + tempValue;
      }
      if (formik.values.country === "Worldwide") {
        let tempValue =
          Number(selectedCategoryPrice) *
          Number(differentCountryPrices?.worldwide);

        finalPriceForOrder = finalPriceForOrder + tempValue;
      }
      if (selectedDurationPrice) {
        let tempValue =
          Number(selectedCategoryPrice) * Number(selectedDurationPrice);

        finalPriceForOrder = finalPriceForOrder + tempValue;
      }
      if (formik.values.state && formik.values.state?.length > 0 && differentStateWisePrices) {
        let tempValue = 0;
        if (formik.values.state?.length === 1) {
          tempValue =
            Number(selectedCategoryPrice) *
            Number(differentStateWisePrices["1"]);
        }
        if (formik.values.state?.length === 2) {
          tempValue =
            Number(selectedCategoryPrice) *
            Number(differentStateWisePrices["2"]);
        }
        if (formik.values.state?.length === 3) {
          tempValue =
            Number(selectedCategoryPrice) *
            Number(differentStateWisePrices["3"]);
        }
        if (formik.values.state?.length === 4) {
          tempValue =
            Number(selectedCategoryPrice) *
            Number(differentStateWisePrices["4"]);
        }
        if (formik.values.state?.length >= 5) {
          tempValue =
            Number(selectedCategoryPrice) *
            Number(differentStateWisePrices["5"]);
        }
        finalPriceForOrder = finalPriceForOrder + tempValue;
      }
      return Number(finalPriceForOrder);
      setFinalPriceForFlexiblePlan(Number(finalPriceForOrder));
    } else {
      return 0;
      setFinalPriceForFlexiblePlan(0);
    }
  };

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
        }}
        fullWidth
      >
        <DialogTitle component='div' padding={3} sx={{ display: 'flex', alignItems: "center" }}>
          <Typography variant="h3">{heading}</Typography>
          {handleOpen && <CustomIconButtons onClick={() => { handleOpen(false); setImagesData([]) }} sx={{ padding: '13px 15px', ml: 'auto' }}
            color={'iconButtonPrimary'} disabled={loading}><CloseIcon /></CustomIconButtons>}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Box noValidate component="div" sx={{
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomInput
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    select
                    SelectProps={{
                      native: true,
                      style: {
                        height: 40
                      }
                    }}
                    name="proposal_of"
                    label="Type"
                    placeholder="Type"
                    value={type}
                    onChange={(e) => {
                      setImagesData([])
                      handleType(e?.target.value)
                      if (e?.target.value == "star") {
                        formik.setFieldValue("is_star", "1");
                        formik.setFieldValue("license", "flexible-license");
                      }
                      else {
                        formik.setFieldValue("is_star", "0");
                        formik.setFieldValue("license", "");
                        formik.setFieldValue("country", "");
                        formik.setFieldValue("state", []);
                        formik.setFieldValue("duration", "");
                        formik.setFieldValue("industry", "");
                      }

                    }}
                    style={{ maxWidth: 150 }}
                  >
                    <option key={'image'} value="image">Image</option>
                    <option key={'video'} value="video">Video</option>
                    <option key={'star'} value="star">Star</option>
                  </CustomInput>
                </Grid>
                {type == 'star' ?
                  <Grid item xs={12} md={6}>
                    <ProposalFields
                      name="industry"
                      label="Industry"
                      id="industry"
                      placeholder="Industry"
                      SelectProps={{
                        native: true,
                      }}
                      select
                      value={formik.values.industry}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={(e) => formik.setFieldValue("industry", e.target.value)}
                      error={
                        formik.touched.industry &&
                        Boolean(formik.errors.industry)
                      }
                      helperText={
                        formik.touched.industry && formik.errors.industry
                      }
                    >
                      <option key={'Select Industry'} value={''}>
                        Select Industry
                      </option>
                      {industryList &&
                        industryList?.map((industry) => (

                          <option key={industry.value} value={industry.label}>
                            {industry.label}
                          </option>
                        ))}
                    </ProposalFields>
                  </Grid>
                  : null}
                {type == 'star' ?
                  <Grid item xs={12} md={6}>
                    <ProposalFields
                      name="duration"
                      label="Duration"
                      id="duration"
                      placeholder="Duration"
                      SelectProps={{
                        native: true,
                      }}
                      select
                      value={formik.values.duration}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={(e) => formik.setFieldValue("duration", e.target.value)}
                      error={
                        formik.touched.duration &&
                        Boolean(formik.errors.duration)
                      }
                      helperText={
                        formik.touched.duration && formik.errors.duration
                      }
                    >
                      <option key={'Select Duration'} value={''}>
                        Select Duration
                      </option>
                      {appSettingDurationData?.map(
                        (duration, index) => (
                          <option
                            key={index}
                            value={duration?.value}
                            name="duration"
                          >
                            {duration?.label}
                          </option>
                        )
                      )}
                    </ProposalFields>
                  </Grid>
                  : null}
                {type == 'star' ?
                  <Grid item xs={12} md={6}>
                    <ProposalFields
                      name="country"
                      label="Country"
                      id="country"
                      placeholder="Country"
                      SelectProps={{
                        native: true,
                      }}
                      select
                      value={formik.values.country}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={(e) => {
                        if (e.target.value === "India" || e.target.value === "india")
                          getStateListing()
                        handleChangeCountry(e);

                      }}
                      error={
                        formik.touched.country &&
                        Boolean(formik.errors.country)
                      }
                      helperText={
                        formik.touched.country && formik.errors.country
                      }
                    >
                      <option key={'Select Country'} value={''}>
                        Select Country
                      </option>
                      {countryList &&
                        countryList?.map((country) => (
                          <option key={country.value} value={country.label}>
                            {country.label}
                          </option>
                        ))}
                    </ProposalFields>
                  </Grid>
                  : null}
                {formik.values.country === "India" && type == 'star' ?
                  <Grid item xs={12} md={6}>
                    <Grid item xs={12}>
                      <CustomAsyncSelect
                        promiseOptions={getStateListing}
                        closeMenuOnSelect={false}
                        controlStyle={{
                          padding: 5,
                          minHeight: "56px",
                        }}
                        cacheOptions={true}
                        value={formik.values.state}
                        error={
                          formik.touched.state &&
                          Boolean(formik.errors.state)
                        }
                        helperText={
                          formik.touched.state && formik.errors.state
                        }
                        handleChange={(e) => {
                          setSelectedState(e);
                          formik.setFieldValue("state", e);
                        }}
                        width={'100%'}
                        dropDownZIndex={100000}
                        labelShrink={true}
                        label="State"
                      />
                    </Grid>
                  </Grid>
                  : null}

                <Grid item xs={12}>
                  <CustomAsyncSelect
                    promiseOptions={getImageListing}
                    closeMenuOnSelect={false}
                    controlStyle={{
                      padding: 5
                    }}
                    cacheOptions={false}
                    value={imagesData}
                    handleChange={(e) => {
                      setImagesData(e);
                      selectRef?.current?.blur();
                    }}
                    width={'100%'}
                    dropDownZIndex={100000}
                    labelShrink={true}
                    label="Search"
                    ref={selectRef}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box className="border-1 rounded-3" mt={2} px={2} py={1} minHeight={150}>
                    <Typography variant="h6">Preview</Typography>
                    {imagesData?.length ?
                      < Box sx={{ display: "flex", overflow: "hidden", overflowX: "auto" }}>
                        {imagesData?.map((item, index) => {
                          return <>
                            <PreviewImageComp
                              item={item}
                              handleData={handleImagesData}
                              handleDelete={handleDelete}
                              updateData={handleUpdateImagesData}
                              index={index}
                              key={index}
                              showImageType={from == 0 ? true : false}
                              packDetails={
                                (item?.type === 'image' || item?.type === 'Image') ?
                                  appSetting?.items?.subscriptions?.singleImagePrice :
                                  (item?.type === 'video' || item?.type === 'Video') ?
                                    appSetting?.items?.subscriptions?.singleVideoPrice :
                                    (item?.type === 'star' || item?.type === 'Star') ?
                                      appSetting?.items?.subscriptions?.singleVideoPrice
                                      : [] || []
                              }
                              extraSection={() => {
                                return <Box textAlign={'right'} onClick={() => { setSelectedItem(item); setSelectedIndex(index) }}>
                                  <CustomButton sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }} color={'buttonPrimary'}
                                    className="buttonDense noMinWidth" loading={loading} type="submit"
                                  >{from == 0 ? <AddShoppingCartIcon /> : <Favorite />}</CustomButton>
                                </Box>
                              }}
                            />
                          </>
                        })}
                      </Box> : <Typography variant="p" component={'p'} className="fontWeight500" textAlign={"center"} pt={4}>No Data Available</Typography>}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div >
  );
}

export default AddToCartWishListModal;
