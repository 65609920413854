import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import Checkout from './Checkout';

function CheckoutContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: 'Checkout',
    navSubTitle: 'Welcome to ImagesBazaar',
  });
  return (
    <MainLayout
      navTitle={navParams.navTitle}
      navSubTitle={navParams.navSubTitle}
    >
      <Checkout />
    </MainLayout>
  );
}

export default CheckoutContainer;
