import React, {  } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import IrnList from '../../Components/Irn/IrnList';

function Irn() {

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={0}>
        <IrnList/>
      </CustomBox>
      <Divder spacing={1.2} />
    </Box>
  );
}

export default Irn;
