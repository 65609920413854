import { Close } from '@material-ui/icons';
import { Box, Typography } from '@mui/material';
import React from 'react';
import ApiConfig from '../../config/ApiConfig';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import Divder from '../CommonComp/Divder';

function PreviewImageComp({
  item,
  updateData,
  handleDelete,
  showImageType,
  index,
  packDetails,
  extraSection,
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        //   justifyContent: "center",
        flexDirection: 'column',
        maxWidth: 175,
        marginRight: 2,
        marginBottom: 2,
        bgcolor: (theme) => theme.backgroundColor.box.primary,
        px: 0.5,
        pb: 1,
        borderRadius: 5,
        position: 'relative',
      }}
    >
      <CustomImage
        src={`${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.image_name}.jpg`}
        style={{
          maxWidth: 170,
          marginTop: 5,
          maxHeight: 110,
          borderRadius: 10,
          width: 165,
          height: 110,
        }}
      />
      <Typography variant="h6" mt={1} pl={1}>
        {item?.image_name}
      </Typography>
      <Box>
        {item?.type === 'image' || item?.type === 'Image' || item?.type === '1' ? (
          <>
            {showImageType
              ? packDetails?.map((itemData, i) => {
                return (
                  <React.Fragment key={i}>
                    <CustomButton
                      className="buttonDense"
                      sx={{
                        fontSize: '9px !important',
                        padding: '1px 10px !important',
                        marginTop: 1,
                        minWidth: 'auto !important',
                        bgcolor: '#ddd',
                        // bgcolor: itemData?.quality == itemData?.image_type ? 'buttonSecondary.main' : 'red'
                        ...(item?.quality == itemData?.image_type && {
                          bgcolor: 'buttonSecondary.main',
                        }),
                        ...(item?.quality == itemData?.image_type && {
                          color: 'buttonSecondary.contrastText',
                        }),
                      }}
                      key={i}
                      onClick={() => {
                        updateData(
                          {
                            price: itemData?.price,
                            quality: itemData?.image_type,
                          },
                          index,
                        );
                      }}
                    >
                      {itemData?.image_type}
                    </CustomButton>
                  </React.Fragment>
                );
              })
              : null}
          </>
        ) : item?.type === 'video' || item?.type === 'Video' || item?.type === '2' ? (
          <>
            {showImageType
              ? packDetails?.map((itemData, i) => {
                return (
                  <>
                    <CustomButton
                      className="buttonDense"
                      sx={{
                        fontSize: '9px !important',
                        padding: '1px 10px !important',
                        marginTop: 1,
                        minWidth: 'auto !important',
                        bgcolor: '#ddd',
                        // bgcolor: itemData?.quality == itemData?.image_type ? 'buttonSecondary.main' : 'red'
                        ...(item?.quality == itemData?.video_type && {
                          bgcolor: 'buttonSecondary.main',
                        }),
                        ...(item?.quality == itemData?.video_type && {
                          color: 'buttonSecondary.contrastText',
                        }),
                      }}
                      key={i}
                      onClick={() => {
                        updateData(
                          {
                            price: itemData?.price,
                            quality: itemData?.video_type,
                          },
                          index,
                        );
                      }}
                    >
                      {itemData?.video_type}
                    </CustomButton>
                  </>
                );
              })
              : null}
          </>
        ) : (
          <></>
        )}

        {extraSection && (
          <>
            <Divder spacing={0.6} />
            {extraSection()}
          </>
        )}
      </Box>
      {handleDelete && (
        <div
          onClick={() => handleDelete(index)}
          style={{
            position: 'absolute',
            top: 4,
            cursor: 'pointer',
            right: 4,
            height: 20,
            width: 20,
            background: '#fff',
            borderRadius: '6px',
            boxShadow: '0 0 9px -3px #000',
          }}
        >
          <Close style={{ fontSize: 20 }} />
        </div>
      )}
    </Box>
  );
}

export default PreviewImageComp;

PreviewImageComp.defaultProps = {
  showImageType: true,
};
