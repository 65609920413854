import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import Keywords from './Keywords'

function KeywordsContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "All Visible Keyword",
    navSubTitle: "Welcome to ImagesBazaar"
  })

  const handleNavParams = (obj)=>{
    setNavParams({
        ...navParams,
        ...obj
    })
  }
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
      <Keywords handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default KeywordsContainer