import { formatDate } from "../lib/helper";

export const FollowUpsData = {
  tabs: [
    {
      label: 'Daily Sales Entry',
      id: 1,
    },
    {
      label: 'Manage Report List',
      id: 2,
    },
    {
      label: 'Outstanding Payment',
      id: 3,
    },
    {
      label: 'Non-registered users list',
      id: 4,
    },
  ],
};

export const RequirementType = Object.freeze([
  { name: "ib", subLableText: 'Ib', value: false, type: 'Ib', followup_status: '0', description_type: '', followup_date: formatDate(new Date(), 'yyyy-mm-dd'), description: '' },
  { name: "star_collection", subLableText: 'Star Collection', value: false, type: 'Star Collection', followup_status: '0', description_type: '', followup_date: formatDate(new Date(), 'yyyy-mm-dd'), description: '' },
  { name: "packs", subLableText: 'Packs', value: false, type: 'Packs', followup_status: '0', description_type: '', followup_date: formatDate(new Date(), 'yyyy-mm-dd'), description: '' },
  { name: "legal", subLableText: 'Legal', value: false, type: 'Legal', followup_status: '0', description_type: '', followup_date: formatDate(new Date(), 'yyyy-mm-dd'), description: '' },
  { name: "others", subLableText: 'Others', value: false, type: 'Others', followup_status: '0', description_type: '', followup_date: formatDate(new Date(), 'yyyy-mm-dd'), description: '' },
])

export const RequirementFollowups = [
  { value: "0", radioLable: 'Open' },
  { value: "1", radioLable: 'Close' },
  { value: "2", radioLable: 'PClose' },
  { value: "3", radioLable: 'Sold' },
  { value: "4", radioLable: 'Upgrade sold' },
  { value: "5", radioLable: 'QPack sold' },
  { value: "6", radioLable: 'DQuery sold' },
  { value: "7", radioLable: 'DPack sold' },
  { value: "8", radioLable: 'Exclude sold' }
]

export const RequirementFollowupsText = [
  { value: "Open", label: 'Open' },
  { value: "Close", label: 'Close' },
  { value: "PClose", label: 'PClose' },
  { value: "Sold", label: 'Sold' },
  { value: "Upgrade sold", label: 'Upgrade sold' },
  { value: "QPack sold", label: 'QPack sold' },
  { value: "DQuery sold", label: 'DQuery sold' },
  { value: "DPack sold", label: 'DPack sold' },
  { value: "Exclude sold", label: 'Exclude sold' }
]

export const RequirementDescriptionType = [
  { value: '', label: 'Select' },
  { value: 'Pricing and Procedure', label: 'Pricing and Procedure' },
  {
    value: 'Costing for selected images',
    label: 'Costing for selected images',
  },
  { value: 'Direct Order', label: 'Direct Order' },
  {
    value: 'Image details sent for previous record',
    label: 'Image details sent for previous record',
  },
  { value: 'Watermark query', label: 'Watermark query' },
  { value: 'Free Research', label: 'Free Research' },
  { value: 'Contact us Form', label: 'Contact us Form' },
  { value: 'Link for Similar images', label: 'Link for Similar images' },
  { value: 'Invoice resent', label: 'Invoice resent' },
  { value: 'Bank details', label: 'Bank details' },
  { value: 'Image usage Rights', label: 'Image usage Rights' },
  { value: 'PO pending', label: 'PO pending' },
  { value: 'Change in user profile', label: 'Change in user profile' },
  { value: 'Upgrade Image', label: 'Upgrade Image' },
  { value: 'Vendor form', label: 'Vendor form' },
  {
    value: 'Address confirmation for payment/TDS',
    label: 'Address confirmation for payment/TDS',
  },
  { value: 'Ecash query', label: 'Ecash query' },
  { value: 'Quotation sent', label: 'Quotation sent' },
  { value: 'Images not available', label: 'Images not available' },
  { value: 'Renewal', label: 'Renewal' },
  { value: 'Exclude', label: 'Exclude' },
  {
    value: 'Lead fwd from Direct order to upgrade',
    label: 'Lead fwd from Direct order to upgrade',
  },
  { value: 'Lead fwd from quotation', label: 'Lead fwd from quotation' },
  { value: 'Lead fwd from direct query', label: 'Lead fwd from direct query' },
  { value: 'Direct pack sold CS', label: 'Direct pack sold CS' },
  { value: 'Others', label: 'Others' }
];

export const KnowAboutIb = [
  { value: '', label: 'Select' },
  { value: 'Google', label: 'Google' },
  { value: 'Word of Mouth', label: 'Word of Mouth' },
  { value: 'Through Agency', label: 'Through Agency' },
  { value: 'Newspaper', label: 'Newspaper' },
  { value: 'Emailer', label: 'Emailer' },
  { value: 'Afaq', label: 'Afaq' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Know before', label: 'Know before' },
  { value: 'Site', label: 'Site' },
  { value: 'Seminar', label: 'Seminar' },
  { value: 'New registration', label: 'New registration' },
  { value: 'Others', label: 'Others' },
]

export const DiscountTerm = [
  { value: 'Commision', label: 'Commision' },
  { value: 'Corporate Deal', label: 'Corporate Deal' },
  { value: 'NO Discount', label: 'NO Discount' },
  { value: 'Performa', label: 'Performa' },
  { value: 'Publication Pckg', label: 'Publication Pckg' },
  { value: 'Publicis Group Discount', label: 'Publicis Group Discount' },
  { value: 'UpFront', label: 'UpFront' },
  { value: 'WPP Discount', label: 'WPP Discount' },
]

export const CreditPeriodFollowUp = [
  { value: '0', label: '0 day' },
  { value: '7', label: '7 days' },
  { value: '15', label: '15 days' },
  { value: '30', label: '30 days' },
  { value: '45', label: '45 days' },
  { value: '60', label: '60 days' },
  { value: '90', label: '90 days' },
]