import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import ContactUs from './ContactUs'

function ContactUsContainer() {
  const [navParams, setNavParams] = useState({
      navTitle: "Contact Us",
      navSubTitle: "Welcome to ImagesBazaar"
  })

  const handleNavParams = (obj)=>{
    setNavParams({
        ...navParams,
        ...obj
    })
  }

  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
        <ContactUs handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default ContactUsContainer