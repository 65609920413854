import * as React from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';

function CustomCheckbox({label, subLableText, formControlStyle, formGroupStyle, labelPlacement, value, name, handleChange, error, helperText, checkBoxRenderData, ...props}) {
  return (
      <FormControl
        required
        error={error?.constructor === Object ? name ? error[name] : null : error ? error: false}
        sx={{
            ...formControlStyle
        }}
        component="fieldset"
        variant="standard"
      >
        {label && <Typography variant="h5">{label}</Typography>}
        <FormGroup sx={{
          mt: '3px',
          ...formGroupStyle
        }} 
        {...props}>
          {checkBoxRenderData?.length ? checkBoxRenderData?.map((item,index)=>{
            return <FormControlLabel
              key={index}
              labelPlacement={item?.labelPlacement}
              control={
                <Checkbox checked={item?.value} sx={{padding: "0 10px"}} onChange={(e)=>handleChange(e, item, index)} name={item?.name}/>
              }
              sx={{fontSize: 10}}
              label={item?.subLableText || null}
            />
          }) : <FormControlLabel
            labelPlacement={labelPlacement}
            control={
              <Checkbox checked={value} sx={{padding: "0 10px"}} onChange={handleChange} name={name}/>
            }
            sx={{fontSize: 10}}
            label={subLableText || null}
          />}
        </FormGroup>
        {error?.constructor === Object ? (helperText && error[name]) ? <FormHelperText>{helperText}</FormHelperText>: null : (helperText && error) ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
  );
}

export default React.memo(CustomCheckbox)

CustomCheckbox.defaultProps = {
  labelPlacement: 'start',
  checkBoxRenderData: []
}