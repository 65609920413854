import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { companyGroupListingsNoRedux, editCompanyMaster } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { commonItemsSelector } from '../../redux/common';
import CloseIcon from '@mui/icons-material/Close';
import CustomIconButtons from '../CommonComp/Buttons/IconButtons';
import { getState } from '../../Services/commonService';

const limit = DATA_LIMIT;

function EditCompanyDetailModal({ open, handleOpen, initialData, getListing }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const { appSetting, stateData } = useSelector(commonItemsSelector);
  const [selectedState, setSelectedState] = useState({});
  const [stateList, setStateList] = useState([]);

  const dispatch = useDispatch();

  const getGroupsListing = async (inputValue) => {
    if (inputValue && inputValue.length > 2) {
      let data = await companyGroupListingsNoRedux({
        company_group_name: inputValue,
        page: "1",
        limit: '20'
      })
      if (data) {
        let updatedData = data?.map((item, index) => {
          return {
            value: item.company_group_id,
            label: item.company_group_name,
            discount: item.discount
          }
        })
        return updatedData || []
      }
      return []
    }
  }

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    const updatedValues = {
      ...values,
      discount: Number(values.discount),
    };
    let resp = await editCompanyMaster(updatedValues);
    if (resp) {
      resetForm();
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      company_id: initialData?.company_id || '',
      company_name: initialData?.company_name || '',
      company_group_id: initialData?.company_group_id || '',
      company_group_name: initialData?.company_group_name || '',
      short_company_name: initialData?.short_company_name || '',
      state: initialData?.state || '',
      gst_number: initialData?.gst_number || '',
      company_address: initialData?.company_address || '',
      discount: initialData?.discount || 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      company_id: Yup.string().required('Please enter company id'),
      company_name: Yup.string().required('Please enter company name'),
      short_company_name: Yup.string().required('Please enter short company name'),
      company_group_id: Yup.string().required('Please enter compay group id'),
      company_group_name: Yup.string().required('Please select compay group from select box'),
      gst_number: Yup.string()
        .trim()
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          "Please enter valid GST Number"
        )
        .test(
          "isValidGST",
          "Please enter correct GST Number",
          function (value) {
            let isValid = value?.startsWith(
              selectedState?.state_code?.toString().length > 1
                ? selectedState?.state_code
                : `0${selectedState?.state_code}`
            );
            return value === "" || value === null || value === undefined
              ? true
              : isValid;
          }
        ),
    }),
    onSubmit: handleConfirm,
  });

  const handleGroupSelect = (e) => {
    setData(e);
    formik.setValues((values) => {
      return {
        ...values,
        company_group_id: e.value,
        company_group_name: e.label,
        discount: e.discount
      }
    })
  }


  const handleStateChange = (val) => {
    let stateSelected = stateData?.items?.filter(
      (item) => item.state_name == val
    );

    if (stateSelected.length) {
      setSelectedState(stateSelected[0]);
    }
  };

  useEffect(() => {
    dispatch(getState(1));
  }, []);

  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);

      handleStateChange(initialData?.state)
    }
  }, [stateData]);

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
        fullWidth
      >
        <DialogTitle component='div' padding={3} sx={{ display: 'flex', alignItems: "center" }}>
          <Typography variant="h3">Update Company</Typography>
          {handleOpen && <CustomIconButtons onClick={() => { handleOpen(false); setData({}) }} sx={{ padding: '13px 15px', ml: 'auto' }}
            color={'iconButtonPrimary'} disabled={loading}><CloseIcon /></CustomIconButtons>}
        </DialogTitle>
        <DialogContent>
          <Box noValidate
            component="form"
            onSubmit={formik.handleSubmit}>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomAsyncSelect
                  promiseOptions={getGroupsListing}
                  closeMenuOnSelect={false}
                  controlStyle={{
                    padding: 5
                  }}
                  cacheOptions={false}
                  value={data}
                  handleChange={(e) => {
                    handleGroupSelect(e)
                  }}
                  width={'100%'}
                  dropDownZIndex={100000}
                  labelShrink={true}
                  label="Group Name"
                  isMulti={false}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="company_group_name"
                  label="Company Group Name"
                  placeholder="Company Group Name"
                  value={formik.values.company_group_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  disabled
                  error={formik.touched.company_group_name && Boolean(formik.errors.company_group_name)}
                  helperText={formik.touched.company_group_name && formik.errors.company_group_name}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="company_name"
                  label="Company Name"
                  placeholder="Company Name"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                  helperText={formik.touched.company_name && formik.errors.company_name}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="short_company_name"
                  label="Short Company Name"
                  placeholder="Short Company Name"
                  value={formik.values.short_company_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.short_company_name && Boolean(formik.errors.short_company_name)}
                  helperText={formik.touched.short_company_name && formik.errors.short_company_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="state"
                  label="Company State"
                  placeholder="Company State"
                  value={formik.values.state}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}

                  SelectProps={{
                    native: true,
                  }}
                  select
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleStateChange(e.target.value);
                  }}
                >
                  <option key={'Select State'} value={''}>
                    Select state
                  </option>
                  {stateList &&
                    stateList?.map((state) => {
                      return (
                        <option key={state.value} value={state.label}>
                          {state.label}
                        </option>
                      );
                    })}
                </CustomInput>
              </Grid>
              <Grid item xs={6} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="gst_number"
                  label="GST Number"
                  placeholder="GST Number"
                  value={formik.values.gst_number}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}

                  onChange={(e) => {
                    formik.setFieldValue("gst_number", e.target.value.toUpperCase());
                  }}
                  inputProps={{
                    maxLength: 15
                  }}

                  error={formik.touched.gst_number && Boolean(formik.errors.gst_number)}
                  helperText={formik.touched.gst_number && formik.errors.gst_number}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="company_address"
                  label="Company Address"
                  placeholder="Company Address"
                  value={formik.values.company_address}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.company_address && Boolean(formik.errors.company_address)}
                  helperText={formik.touched.company_address && formik.errors.company_address}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="discount"
                  label="Discount"
                  placeholder="Discount"
                  value={formik.values.discount}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.discount && Boolean(formik.errors.discount)}
                  helperText={formik.touched.discount && formik.errors.discount}
                />
              </Grid>
              <Grid item xs={12} textAlign={'end'}>
                <CustomButton
                  color={'buttonPrimary'}
                  type="submit"
                  loading={loading}
                >
                  Confirm
                </CustomButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditCompanyDetailModal;
