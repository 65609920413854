import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import ContributorFolderDetail from './ContributorFolderDetail'





function ContributorFolderDetailsContainer() {
    return (
        <MainLayout navTitle={"Contributor Folder List"} navSubTitle={"Welcome to ImagesBazaar"}>
            <ContributorFolderDetail />
        </MainLayout>
    )
}

export default ContributorFolderDetailsContainer