import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton'
import MainLayout from '../Layouts/MainLayout'
import Videos from './Videos'

function VideoContainer() {
  const [navParams, setNavParams] = useState({
      navTitle: "All Videos",
      navSubTitle: "Welcome to ImagesBazaar"
  })

  const handleNavParams = (obj)=>{
    setNavParams({
        ...navParams,
        ...obj
    })
  }

  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle} navBarRightComp={<>
      <Link to="/addVideo" className="textDecoration-none">
        <CustomButton sx={{ padding: '12px 15px', ml: (theme)=>(theme.spacing(2)) }} color={'buttonPrimary'} className="textDecoration-none">
            Add Videos
        </CustomButton>
      </Link>
    </>}>
        <Videos handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default VideoContainer