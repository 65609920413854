import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Grid, Typography } from '@mui/material';
import SmallBox from '../../Components/Dashboard/SmallBox';
import { DashboardData } from '../../data/DashboardData';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { GreenCircleIcon } from '../../Asset/svg';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardCountData } from '../../Services/DashboardApi';
import { dashboardItemsSelector } from '../../redux/dashboard';
import WeeklyImageModal from '../../Components/Modals/WeeklyImageModal';
function Dashboard({weeklyImageModal, handleWeeklyImageModal}) {
  const dispatch = useDispatch();
  const { loading, dashboardCount } = useSelector(dashboardItemsSelector);
  const [dashboardBoxesData, setDashboardBoxesData] = useState([]);
  const getDashboardCountData = async (params = {}) => {
    dispatch(dashboardCountData());
  };
  useEffect(() => {
    getDashboardCountData();
  }, []);

  useEffect(() => {
    const boxes = [
      {
        subTitle: 'Active User',
        value: dashboardCount?.items?.usersCount?.active,
        percent: '2.5%',
        chart: [30, 10, 135, 60, 149, 60, 70, 30, 135],
      },
      {
        subTitle: 'Pending Order',
        value: dashboardCount?.items?.ordersCount?.pending,
        percent: '2.5%',
        chart: [30, 10, 35, 60, 49, 60, 40, 30, 25],
      },
      {
        subTitle: 'Active Contributor',
        value: dashboardCount?.items?.contributorCount?.active,
        percent: '2.5%',
        chart: [30, 10, 35, 60, 49, 60, 70, 30, 35],
      },
    ];
    setDashboardBoxesData(boxes);
  }, [dashboardCount]);

  // const x = useTheme()
  const getColor = (data = []) => {
    const sum = data.reduce((a, b) => a + b, 0);
    const avg = sum / data.length;

    if (avg > 70) {
      return '#12B347';
    } else if (avg > 40) {
      return '#FFBB54';
    } else {
      return '#FF5B5B';
    }
  };

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      {/* <Box sx={{ display: 'flex' }}> */}
      <Grid container>
        <Grid xs={12} md={9} mb={3} item>
          <Grid container spacing={4}>
            {dashboardBoxesData?.map((item) => {
              return (
                <Grid xs={12} sm={6} md={4} xl={3} item key={item.subTitle}>
                  <SmallBox
                    heading={item.subTitle}
                    value={item.value}
                    percentValue={item.percent}
                    chartData={item.chart}
                    chartOptions={{
                      colors: item.chart ? [getColor(item.chart)] : ['#58CDFF'],
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid xs={12} md={3} item>
          <Box className='sectionLeftMargin'>
            <div>
              <Typography
                variant="h3"
                component="div"
                sx={{ marginBottom: '10px' }}
              >
                Latest Activity
              </Typography>
              <Typography variant="14A3" component="div">
                Sit et tempora dicta omnis ab quia quo quo.
              </Typography>
              <Box sx={{ marginTop: '30px' }}>
                <Timeline>
                  <TimelineItem sx={{ width: '90%' }}>
                    <TimelineSeparator>
                      <GreenCircleIcon />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <Box sx={{ marginLeft: '15px', paddingBottom: '25px' }}>
                      <Typography
                        variant="15px4625"
                        component="div"
                        sx={{ marginBottom: '10px' }}
                      >
                        January 2nd, 04:35 AM
                      </Typography>
                      <Typography variant="12A3" component="div">
                        Illum omnis quo illum nisi. Nesciunt est accusamus.
                        Blanditiis nisi quae eum nisi similique. Modi consequuntur
                        totam
                      </Typography>
                    </Box>
                  </TimelineItem>
                  <TimelineItem sx={{ width: '90%' }}>
                    <TimelineSeparator>
                      <GreenCircleIcon />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <Box sx={{ marginLeft: '15px', paddingBottom: '25px' }}>
                      <Typography
                        variant="15px4625"
                        component="div"
                        sx={{ marginBottom: '10px' }}
                      >
                        January 4th, 06:19 AM
                      </Typography>
                      <Typography variant="12A3" component="div">
                        Corrupti unde qui molestiae labore ad adipisci veniam
                        perspiciatis quasi. Quae labore vel.
                      </Typography>
                    </Box>
                  </TimelineItem>
                  <TimelineItem sx={{ width: '90%' }}>
                    <TimelineSeparator>
                      <GreenCircleIcon />
                    </TimelineSeparator>{' '}
                    <Box sx={{ marginLeft: '15px', paddingBottom: '25px' }}>
                      <Typography
                        variant="15px4625"
                        component="div"
                        sx={{ marginBottom: '10px' }}
                      >
                        January 5th, 12:34 AM
                      </Typography>
                      <Typography variant="12A3" component="div">
                        Maiores doloribus qui. Repellat accusamus minima ipsa
                        ipsam aut debitis quis sit voluptates. Amet necessitatibus
                        non minus quaerat et quis.
                      </Typography>
                    </Box>
                  </TimelineItem>
                </Timeline>
              </Box>
            </div>
          </Box>
        </Grid>
      </Grid>

      {/* </Box> */}
      {/* <Divder/>
      <SeverStats chartData={DashboardData.serverRequest}/>
      <Divder/>
      <Grid container spacing={4}>
        <Grid xs={6} item>
          <Sales chartData={DashboardData.sales} 
            chartOptions={{
              colors: ['#12B347'],
            }}/>
        </Grid>
        <Grid xs={6} item>
          <ChatSummary chartData={DashboardData.chatSumary} />
        </Grid>
      </Grid> */}
      <WeeklyImageModal open={weeklyImageModal} handleOpen={handleWeeklyImageModal}/>
    </Box>
  );
}

export default Dashboard;
Dashboard.defaultProps = {
  handleWeeklyImageModal: () => false,
  weeklyImageModal: false
}