import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { categoryItemsSelector } from '../../redux/category';
import { deleteCategory, deleteStarCategory, starCategoryListings } from '../../Services/categoryApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import AddEditCategoryModal from '../Modals/AddEditCategoryModal';
import MatTable from '../Tables/MatTable';

const limit = 10;

const column = [
  {
    label: 'Category ID',
    id: 'category_id',
    format: (columnData) => (<Link className='textDecoration-none link-text' to={`subCategory?category_id=${columnData}`} target={'_blank'}>{columnData}</Link>)
  },
  {
    label: 'Ref ID',
    id: 'reference_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>)
  },
  {
    label: 'Category Name',
    id: 'category_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Display Name',
    id: 'display_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 400, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Search ID',
    id: 'search_id',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{columnData}</span>)
  },
  {
    label: 'Sort ID',
    id: 'sort_id',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{columnData}</span>)
  },
];

function StarCategoryList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, starCategoryList } = useSelector(categoryItemsSelector);
  const dispatch = useDispatch();
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [confirmDeleteCategory, setConfirmDeleteCategory] = useState(false);
  const [deleteCategoryData, setDeleteCategoryData] = useState({});
  const navigate = useNavigate()
  const location = useLocation();

  const handleAdd = (value = false) => {
    setAddCategoryModal(value)
  }
  const handleEdit = (value = false) => {
    setEditCategoryModal(value)
  }

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params
    }
    dispatch(starCategoryListings(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (starCategoryList?.items?.data?.length >= 0) {
      setTableListing(starCategoryList?.items?.data)
    }
  }, [starCategoryList.items]);

  const handleConfirmDeleteCategory = async (value) => {
    if (value && deleteCategoryData) {
      const resp = await deleteStarCategory({ category_id: deleteCategoryData });
      if (resp) {
        getListing({ page: '1' })
        setPage(1)
      }
    } else {
      setDeleteCategoryData(null)
    }
  }

  const handleCofirmModalOpen = (value) => {
    setConfirmDeleteCategory(value);
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            setSelectedCategory(data)
            handleEdit(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit /></CustomButton>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            handleCofirmModalOpen(true)
            setDeleteCategoryData(data?.category_id)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete /></CustomButton>
        </div>
      </>
    );
  };

  return (
    <>
      <MatTable
        loading={starCategoryList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={starCategoryList?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolBarButton={() => {
          return <>
            <CustomButton onClick={() => { handleAdd(true) }} color={'buttonPrimary'} sx={(theme) => ({
              padding: '10px 10px', marginRight: 1,
              [theme.breakpoints.down("md")]: {
                flex: 1,
              }
            })}>Add Category</CustomButton>
          </>
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
      {addCategoryModal && <AddEditCategoryModal open={addCategoryModal} setPage={setPage} type={'add'} handleOpen={handleAdd} isStar={true} handleLast={getListing} />}
      {confirmDeleteCategory && <ConfirmationModal open={confirmDeleteCategory} handleConfirm={handleConfirmDeleteCategory} setOpen={handleCofirmModalOpen} />}
      {editCategoryModal && <AddEditCategoryModal open={editCategoryModal} setPage={setPage} handleOpen={handleEdit} type={'edit'} data={selectedCategory} setSelectedCategory={setSelectedCategory} isStar={true} handleLast={getListing} />}
    </>
  );
}

export default StarCategoryList;
