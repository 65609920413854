import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { orderListingItemsSelector } from '../../redux/orderListing';
import { orderDetailsApi, sendMail } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import Divder from '../CommonComp/Divder';
import { crypt } from '../../lib/helper';
import { useNavigate } from 'react-router-dom';

const generateHtml = (data, orderId, message) => {
    return `
    <div>
      <table style="width: 100%;">
        <tbody>
          <tr>
            <td style="width: 50%;"><strong>Order Number: ${orderId}</strong></td>
            <td style="width: 50%; text-align: right;"><strong>User Name: ${data?.user?.email}</strong></td>
          </tr>
        </tbody>
        </table>
          <table style="width: 100%; margin-top: 10px;">
            <tbody>
              <tr>
                <td style="width: 100%;"><strong>Dear ${data?.user?.first_name} ${data?.user?.last_name}</strong></td>
              </tr>
              <tr>
                <td style="width: 100%;">Thanks for placing an order at ImagesBazaar! You can download the image(s) you have purchased by clicking on the link given below:</td>
              </tr>
            </tbody>
          </table>
          <table style="width: 100%; margin-top: 10px;">
            <tbody>
              <tr>
                <td style="width: 100%;"><a href="${ApiConfig.LIVE_WEBSITE_URL}/deliverImages?order_id=${crypt(ApiConfig.REACT_APP_SALT + 'order_id',orderId)}" traget="_blank">Click Here</a></td>
              </tr>
              ${message ? `<tr>
                <td style="width: 100%;">Message: ${message}</td>
              </tr>` : `<tr><td></td></tr>`}
            </tbody>
          </table>
          <table style="width: 100%; margin-top: 10px;">
            <tbody>
              <tr>
                <td colspan="5" style="width: 100%;">
                  <div>******************************</div>
                  <div>Order Code: ${orderId}</div>
                  <div>******************************</div>
                </td>
              </tr>
              <tr>
                <td style="width: 100%;">Kindly write your order code at the back of your Cheque/Draft and send it in favour of Mash Audio Visuals Pvt. Ltd. payable at DELHI at:</td>
              </tr>
              <tr>
                <td style="width: 100%;">Mash Audio Visuals Pvt. Ltd.</td>
              </tr>
              <tr>
                <td style="width: 100%;">505, Aggarwal Prestige Mall,</td>
              </tr>
              <tr>
                <td style="width: 100%;">Plot No.2, Road No.44,</td>
              </tr>
              <tr>
                <td style="width: 100%;">Pitam Pura, New Delhi-110034</td>
              </tr>
              <tr>
                <td colspan="5" style="width: 100%; padding-top: 10px;">
                <div>******************************</div>
                <div>Customer Control Panel</div>
                <div>******************************</div>
              </td>
            </tr>
          </tbody>
        </table>
        <table style="width: 100%; margin-top: 10px;">
          <tbody>
            <tr>
              <td style="width: 100%;">You can Login to the [My Account] section on the right hand top Menu bar of <a href="${ApiConfig.LIVE_WEBSITE_URL}/signin" target="_blank">${ApiConfig.LIVE_WEBSITE_URL}/signin</a> and Print Invoice or Download Image(s) against this transaction by entering your Email and Password in the LOGIN details.</td>
            </tr>
            <tr>
              <td style="width: 100%;">Looking forward to a long term relationship.</td>
            </tr>
          <tr>
            <td style="width: 100%;">Thanks &amp; Regards,</td>
          </tr>
          <tr>
            <td style="width: 100%;">Customer Support Team</td>
          </tr>
          <tr>
            <td style="width: 100%;">${ApiConfig.LIVE_WEBSITE_URL}</td>
          </tr>
        </tbody>
      </table>
    </div>
  `
}

function SendOrderEmailPage({ orderId }) {
  const { singleOrderDetail } = useSelector(orderListingItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    try{
      let resp = await sendMail(values)
      if(resp){
        navigate(-1)
      }
      setLoading(false)
    }catch(e){
      setLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: {
      mail_to: singleOrderDetail?.items?.user?.email || '',
      mail_cc: singleOrderDetail?.items?.user?.cc_mail||'',
      message: "",
      subject: `Order Delivered`,
      content: '',
    },
    enableReinitialize: true,
    onSubmit: handleConfirm,
  });


  const getOrderDetails = async () => {
    dispatch(orderDetailsApi(orderId));
  };

  useEffect(() => {
    getOrderDetails();
  }, []);
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2} sx={{wordBreak: "break-word"}}>
            <Grid item xs={12} >
              <div>
              
                <table style={{"width": "100%"}}>
                  <tbody>
                    <tr>
                      <td style={{"width": "50%"}}><strong>Order Number: {orderId}</strong></td>
                      {!matches && <td style={{"width": "50%", textAlign: "right"}}><strong>User Name:	{singleOrderDetail?.items?.user?.email}</strong></td>}
                    </tr>
                    {matches && <tr>
                      <td style={{"width": "50%"}}><strong>User Name:	{singleOrderDetail?.items?.user?.email}</strong></td>
                    </tr> }
                  </tbody>
                </table>
                <table style={{"width": "100%", marginTop: 10}}>
                  <tbody>
                    <tr>
                      <td style={{"width": "100%"}}><strong>Dear {singleOrderDetail?.items?.user?.first_name} {singleOrderDetail?.items?.user?.last_name}</strong></td>
                    </tr>
                    <tr>
                      <td style={{"width": "100%"}}>Thanks for placing an order at ImagesBazaar! You can download the image(s) you have purchased by clicking on the link given below:</td>
                    </tr>
                  </tbody>
                </table>
                <table style={{"width": "100%", marginTop: 10}}>
                  <tbody>
                    <tr>
                      <td style={{"width": "100%"}}><a href={`${ApiConfig.LIVE_WEBSITE_URL}/deliverImages?order_id=${crypt(ApiConfig.REACT_APP_SALT + 'order_id',orderId)}`} target='_blank'>Click Here</a></td>
                    </tr>
                    {/* <tr>
                      <td style={{"width": "100%", paddingTop: 10}}>
                        <CustomInput
                          variant="outlined"
                          fullWidth
                          name="message"
                          sx={{
                            maxWidth: '600px',
                          }}
                          // label="message"
                          placeholder="Message"
                          multiline
                          rows={4}
                          value={formik.values.message}
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            classes: {
                              notchedOutline: 'rounded',
                            },
                          }}
                          error={formik.touched.message &&Boolean(formik.errors.message)}
                          helperText={formik.touched.message && formik.errors.message}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </table>
                <table style={{"width": "100%", marginTop: 10}}>
                  <tbody>
                    <tr>
                      <td style={{"width": "100%"}} colSpan={5}>
                        <div>******************************</div>
                        <div>Order Code: {orderId}</div>
                        <div>******************************</div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{"width": "100%"}} >
                        Kindly write your order code at the back of your Cheque/Draft and send it in favour of Mash Audio Visuals Pvt. Ltd. payable at DELHI at:
                      </td>
                    </tr>
                    <tr>
                      <td style={{"width": "100%"}}>
                        Mash Audio Visuals Pvt. Ltd.
                      </td>
                    </tr>
                    <tr>
                      <td style={{"width": "100%"}}>
                        505, Aggarwal Prestige Mall,
                      </td>
                    </tr>
                    <tr>
                      <td style={{"width": "100%"}}>
                        Plot No.2, Road No.44,
                      </td>
                    </tr>
                    <tr>
                      <td style={{"width": "100%"}}>
                        Pitam Pura, New Delhi-110034
                      </td>
                    </tr>
                    <tr>
                      <td style={{"width": "100%", paddingTop: 10}} colSpan={5}>
                        <div>******************************</div>
                        <div>Customer Control Panel</div>
                        <div>******************************</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style={{"width": "100%", marginTop: 10}}>
                  <tbody>
                    <tr>
                      <td style={{"width": "100%"}}>
                        You can Login to the [My Account] section on the right hand top Menu bar of <a href={`${ApiConfig.LIVE_WEBSITE_URL}/signin`} target={'_blank'}>{ApiConfig.LIVE_WEBSITE_URL}/signin</a> and Print Invoice or Download Image(s) against this transaction by entering your Email and Password in the LOGIN details.
                      </td>
                    </tr>
                    <tr>
                      <td style={{"width": "100%"}}>
                        Looking forward to a long term relationship.
                      </td>
                    </tr>
                    <tr>
                      <td style={{"width": "100%"}}>
                        Thanks & Regards,
                      </td>
                    </tr>
                    <tr>
                      <td style={{"width": "100%"}}>
                        Customer Support Team
                      </td>
                    </tr>
                    <tr>
                      <td style={{"width": "100%"}}>
                        <a href={`${ApiConfig.LIVE_WEBSITE_URL}`} target={'_blank'}>{ApiConfig.LIVE_WEBSITE_URL}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="mail_cc"
                label="Send CC TO"
                placeholder="Send CC TO"
                value={formik.values.mail_cc}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomButton type="submit" loading={loading} color={'buttonPrimary'} sx={{ padding: '8px 20px' }} onClick={()=>{
                let mailHTML = generateHtml(singleOrderDetail?.items, orderId, formik.values.message);
                formik.setFieldValue('content', mailHTML)
              }}>
                Send
              </CustomButton>
            </Grid>
          </Grid>
        </CustomBox>
        <Divder spacing={1} />
        
      </form>
    </div>
  );
}

export default SendOrderEmailPage;
