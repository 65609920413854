import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mainLoading: false,
  allContributorReports: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const reportSlice = createSlice({
  name: 'reportContributor',
  initialState,
  reducers: {
    setMainLoading: (state, action) => {
      state.mainLoading = action.payload;
    },
    setResetDailyContributorReportData:(state,action)=>{
        state.allContributorReports.items = {};
        state.allContributorReports.error = {
          isError: false,
          message: '',
        };
        state.allContributorReports.loading = false
      },

    setAllContributorReportsListing: (state, action) => {
      state.allContributorReports.items = action.payload;
      state.allContributorReports.error = {
        isError: false,
        message: '',
      };
    },
    setAllContributorReportsListingLoading: (state, action) => {
      state.allContributorReports.loading = action.payload;
    },
    setAllContributorReportsListingError: (state, action) => {
        state.allContributorReports.items = {};
        state.allContributorReports.error = action.payload;
      },
  },
});

export default reportSlice.reducer;
// Action creators are generated for each case reducer function
export const {
  setMainLoading,
  setAllContributorReportsListing,
  setAllContributorReportsListingError,
  setResetDailyContributorReportData
} = reportSlice.actions;

export const contributorReportListingItemsSelector = (state) => state.contributorReportList;
