import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import Invoice from './Invoice'

function InvoiceContainer() {
  const [navParams, setNavParams] = useState({
      navTitle: "Invoice",
      navSubTitle: "Welcome to ImagesBazaar"
  })
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
        <Invoice/>
    </MainLayout>
  )
}

export default InvoiceContainer