import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import MainDropzone from '../CommonComp/DropZone/Dropzone';
import { Divider, Grid, Typography } from '@mui/material';
import Divder from '../CommonComp/Divder';
import Footer from './Footer';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import Images from './HomePage';
import PopularSearch from './PopularSearch';



function CommonCMS() {

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomBox padding={3} >
          <Typography variant='h5' mb={2} >Footer And Header</Typography>
            <Footer />
          </CustomBox>
        </Grid>
      </Grid>
    </>

  );
}

export default CommonCMS;
