import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import { contributorItemsSelector } from '../../redux/contributor';
import { contributorPaymentHistory } from '../../Services/ContributorApis';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AddPayment from '../Modals/AddPayment';
import { Box } from '@material-ui/core';
import { Edit } from '@material-ui/icons';


const limit = DATA_LIMIT;

const column = [

  {
    label: 'Payment Date',
    id: 'paid_on',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{formatDate(columnData, 'dd-mm-yyyy')}</span>)
  },
  {
    label: 'Description',
    id: 'description',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Amount(INR)',
    id: 'amount',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>{columnData || 'N/A'} </span>)
  },

];

function PaymentHistory() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { contributorPaymentData } = useSelector(contributorItemsSelector);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const { emailId, contributorId } = useParams();

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      contributor_email: emailId,
      ...allParams,
      ...params
    }
    dispatch(contributorPaymentHistory(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, [emailId]);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])


  useEffect(() => {
    if (contributorPaymentData?.items?.data?.length >= 0) {

      setTableListing(contributorPaymentData?.items?.data)
    }
  }, [contributorPaymentData?.items?.data]);


  const handleAdd = (value = false) => {
    setAddModal(value)
  }

  const handleEdit = (value, data = {}) => {
    setEditModal(value);
    setSelectedData(data);
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton toolTipTittle={'Edit Payment'}
            onClick={() => {
              handleEdit(true, data)
            }}
            className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit /></CustomButton>

          <CustomButton toolTipTittle={'Click Here'}

            className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonPrimary.contrastText' }}><Link className='textDecoration-none link-text' to={`payment/${data?.contributor_payment_id}`} >View</Link></CustomButton>

        </div>
      </>
    );
  };
  return <>

    <CustomBox >

      <MatTable
        loading={contributorPaymentData?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={contributorPaymentData?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolBarButton={() => {
          return <>
            <CustomButton onClick={() => { handleAdd(true) }} color={'buttonPrimary'} sx={(theme) => ({
              padding: '10px 10px', marginRight: 1,
              [theme.breakpoints.down("md")]: {
                flex: 1,
              }
            })}>Add Payment</CustomButton>
          </>
        }}

        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />

      {addModal && <AddPayment type={'add'} open={addModal} handleOpen={handleAdd} setPage={setPage} handleLast={getListing} contributorId={emailId} />}
      {editModal && <AddPayment type={'edit'} open={editModal} handleOpen={handleEdit} setPage={setPage} handleLast={getListing} contributorId={emailId} selectedData={selectedData} />}

    </CustomBox>

  </>
}

export default PaymentHistory;
