import React from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import ZeroSearchLogs from './ZeroSearchLogs';
import AllSearchLog from './AllSearchLog';

function LogsTabBody({activeTab}) {
  return (
    <CustomBox padding={0} sx={{minHeight: 200}}>
       {activeTab == 1 && <AllSearchLog/>}
       {activeTab == 2 && <ZeroSearchLogs/>}
    </CustomBox>
  )
}

export default LogsTabBody