import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import ImageTabBody from '../../Components/Image/ImageTabBody';
import { ImageData } from '../../data/imageData';
import AddImages from '../../Components/Image/AddImages';
import UploadCsvModal from '../../Components/Modals/UploadCsvModal';
import { Typography } from '@mui/material';
import UploadImageCsvModal from '../../Components/Modals/UploadImageCsvModal';

function AddKeywords({ handleNavParams, handleImageCsvModal, uploadKeywords }) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };
  return (
    <>
      <UploadImageCsvModal open={uploadKeywords} handleOpen={handleImageCsvModal}/>
      </>
  );
}

export default AddKeywords;
