import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import VisibleHiddenKeywords from './VisibleHiddenKeywords'

function VisibleHiddenKeywordsContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "Hidden Keyword of Visible Keyword",
    navSubTitle: "Welcome to ImagesBazaar"
  })

  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
      <VisibleHiddenKeywords/>
    </MainLayout>
  )
}

export default VisibleHiddenKeywordsContainer