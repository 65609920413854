import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import * as Yup from 'yup';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { commonItemsSelector } from '../../redux/common';
import { useDispatch, useSelector } from 'react-redux';
import { getCountry, getCountryWithoutRedux, getState } from '../../Services/commonService';
import AppSettingsFields from './AppSettingsFields';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import { getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { DATA_LIMIT } from '../../data/constants';
import JobDescriptionModal from '../Modals/JobDescriptionModal';
import { Delete } from '../../Asset/svg';
import CountryListModal from '../Modals/CountryListModal';
import StateListModal from '../Modals/StateListModal';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import { useNavigate } from 'react-router-dom';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Country ID',
    id: 'country_id',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
       }}
        onClick={() => {
          if (columnData >= 0 && columnData != null) {
            // customHistory.push(`image/soldImagesByGroupId/${columnData}`);
          }
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'State ID',
    id: 'state_id',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
       }}
        onClick={() => {
          if (columnData >= 0 && columnData != null) {
            // customHistory.push(`image/soldImagesByGroupId/${columnData}`);
          }
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'State Name',
    id: 'state_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: "State's Short Name",
    id: 'state_short_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'State Code',
    id: 'state_code',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData=="1" ? "Active" : "Inactive"}
      </span>
    ),
  },
];

function StateList() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedField, setSelectedField] = useState(null);
  const [openModal, setOpenModal] = useState(false); 
  const [selectedCountry, setSelectedCountry] = useState('1'); 
  const [countryData, setCountryData] = useState([]); 
  const { loading, stateData } = useSelector(commonItemsSelector);
  const [allParams, setAllParams] = useState(getParameters());
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      country_id:selectedCountry,
      ...allParams,
      ...params,
    };
    dispatch(getState(payload, 'list'));
    setPage(Number(payload.page));
  };

  const getCountryData = async ()=>{
    let resp = await getCountryWithoutRedux();
    if(resp){
      if(resp.length){
        setCountryData(resp || [])
        setSelectedCountry(resp[94].country_id)
        getListing({country_id: resp[94].country_id})
      }
    }
  }

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  useEffect(() => {
    getCountryData();
  }, []);

  //making data format for table input
  useEffect(() => {
    if (stateData?.items?.rows?.length >= 0) {
      setTableListing(stateData?.items?.rows || []);
    }
  }, [stateData.items]);

  const handleModal = (value = false) => {
    setOpenModal(value);
    if (!value) {
      setTimeout(() => {
        setSelectedField(null);
      }, 100);
    }
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton className="buttonDense" sx={{ bgcolor: 'buttonInfo.main' }} onClick={() => {
              handleModal(true);
              setSelectedField(data);
            }}>Edit</CustomButton>
              </div>
      </>
    );
  }; 

  return (
    <>
      <CustomBox padding={3} sx={{minHeight: 200}}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap'}}>
          
        </Box>
        <Divder spacing={1} />
         <MatTable
        loading={loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
             country_id:selectedCountry,
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
             
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date','search'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
      
        action={actionBody}
        page={page}
        perPage={limit}
        total={stateData?.items?.count}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        toolBarButton={() => {
          return (
            <>
             <Box sx={{display: "flex", 
            mt:{md: 0, xs: 2}
          }}>
            <CustomInput
              variant="outlined"
              fullWidth
              name="countries"
              label="Select Country"
              placeholder="Select Country"
              select
              SelectProps={{
                native: true,
              }}
              value={selectedCountry}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: 'rounded',
                },
                style:{
                  height: 40, 
                  maxWidth: 226,
                  width:226
                }
              }}
              onChange={(e)=>{
                setSelectedCountry(e.target.value || '1')
                getListing({country_id: e.target.value || '1'})
              }}
            >
            
              {countryData?.map((option) => (
                  <option key={option.country_id} value={option.country_id}>
                    {option.country_name}
                  </option>
                ))}
            </CustomInput>
            </Box>
              <CustomButton
                onClick={()=>{
                  handleModal(true);
                  setSelectedField(null);
                }}
                color={'buttonPrimary'}
                 sx={(theme)=>({ padding: '10px 10px', marginRight: 1, 
                [theme.breakpoints.down("md")]: {
                  flex: 1,
                }
              })}
              >
                Add
              </CustomButton>
            </>
          );
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
        <StateListModal open={openModal} handleOpen={handleModal} data={selectedField} countryId={selectedCountry} getListing={getListing}/>
      </CustomBox>
    </>
  );
}
export default StateList;
