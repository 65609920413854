import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import OrderCompanyDetail from './orderCompanyDetail'
function OrderCompanyDetailsContainer() {
  return (
    <MainLayout navTitle={"Order List"} navSubTitle={"Welcome to ImagesBazaar"}>
      <OrderCompanyDetail />
    </MainLayout>
  )
}

export default OrderCompanyDetailsContainer