import { Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Delete } from '../../Asset/svg';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { irnItemsSelector } from '../../redux/irn';
import { irnListings } from '../../Services/irnApi';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import MatTable from '../Tables/MatTable';

const limit = 10;

const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
        <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Ordered By',
    id: 'ordered_by',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Client',
    id: 'client',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 200, wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Company',
    id: 'company',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 200, wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Country',
    id: 'country',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'IRN',
    id: 'irn',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 200, wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'GST Number',
    id: 'gst_number',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 200, wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Initial Amount',
    id: 'initial_amount',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Discount',
    id: 'discount',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData}</span>)
  },
  {
    label: 'Final Amount',
    id: 'final_amount',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Acknowledge Number',
    id: 'acknowledge_number',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Acknowledge Date',
    id: 'acknowledge_date',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{formatDate(columnData)}</span>)
  },
  {
    label: 'Invoice Date',
    id: 'invoice_date',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{formatDate(columnData)}</span>)
  },
];

function IrnList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, irnList } = useSelector(irnItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const getListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        order_status: String(1),
        ...allParams,
        ...params
    }
    dispatch(irnListings(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  //making data format for table input
  useEffect(() => {
    if(irnList?.items?.rows?.length >= 0){
      setTableListing(irnList?.items?.rows || [])
    }
  }, [irnList.items]);


  const actionBody = ({ data }) => {
    return (
      <>
        <div style={{display: "flex"}}>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '110px', bgcolor: 'buttonInfo.main' }}>USER DETIALS</CustomButton>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '135px' }}>VIEW FOLLOWUPS</CustomButton>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '150px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>CREATE FOLLOWUPS</CustomButton>
          {/* <CustomButton variant="raised" sx={{ padding: '1px 1px', marginRight: 1, fontSize: '12px', bgcolor: '#fff0', minWidth: 20 }}><Delete/></CustomButton> */}
        </div>
      </>
    );
  };

  // const actionHead = (data) => {
  //   return (<div style={{display: "flex"}}>
  //      hi
  //   </div>);
  // };

  return (
    <>
      <MatTable
        loading={irnList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        // action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={irnList?.items?.count || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
    </>
  );
}

export default IrnList;
