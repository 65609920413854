import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { decrypt, formatDate, getNewParameters, getParameters, statusText } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Add } from '@material-ui/icons';
import { followUpByEmailListingsWithRedux, followUpByMobileListingsWithRedux } from '../../Services/CRMFollowups';
import { useDispatch, useSelector } from 'react-redux';
import { followUpsItemSelector } from '../../redux/followUps';
import ApiConfig from '../../config/ApiConfig';
import CreateDailySaleFollow from '../Modals/CreateDailySaleFollow';

const limit = 10;

const column = [
  // {
  //   label: 'Id',
  //   id: 'user_id',
  //   format: (columnData) => (
  //     <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
  //       {columnData || 'N/A'}
  //     </span>
  //   ),
  // },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'User Name',
    id: 'first_name',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}
      >
        {row?.first_name} {row?.last_name ? ' ' + row?.last_name : "" || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Phone',
    id: 'phone_number',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Mobile',
    id: 'mobile_number',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'User Type',
    id: 'user_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Created By',
    id: 'created_by',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Status',
    id: 'followup_status',
    format: (columnData) => (
      <Typography variant="span">{statusText(columnData)}</Typography>
    ),
  },
  {
    label: 'Creation Date',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {formatDate(columnData, 'dd-mm-yyyy')}
      </span>
    ),
  },
  {
    label: 'Description Type',
    id: 'description_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Requirement Type',
    id: 'requirement_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Discount Terms',
    id: 'discount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData}
      </span>
    ),
  },
  // {
  //   label: 'FollowUps',
  //   id: 'followups',
  //   format: (columnData, rowIndex, formatCallback, options, errors, row) => (<>
  //     <CustomButton className="buttonDense"
  //       onClick={()=>{options(row)}}
  //       sx={{ fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>Create</CustomButton>
  //     </>
  //   ),
  // },
  // {
  //   label: 'History FollowUps',
  //   id: 'daily_sale_id',
  //   format: (columnData) => (
  //     <Link className='textDecoration-none' to={`../../crm/saleEntryHistory/${columnData}`} target='_blank'>
  //       {/* <Typography
  //         variant='span'
  //         style={{ whiteSpace: 'nowrap' }}
  //         // className={'link-text'}
  //         // onClick={() => {
  //         //   customHistory.push(`order/orderDetails/${columnData}`);
  //         // }}
  //       >
  //         View
  //       </Typography> */}
  //       <CustomButton className="buttonDense"
  //         // onClick={()=>{options(row)}}
  //         sx={{ fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>View</CustomButton>
  //     </Link>
  //   ),
  // },
];

function LastUserFollowUpsFullView({ data }) {
  const [tableListing, setTableListing] = useState([]);
  const [allParams, setAllParams] = useState(getParameters());
  // const {email,mobile} = allParams;
  const { email, mobile } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedData, setSelectedData] = useState(null);
  const [openCreateDaliySale, setOpenCreateDaliySale] = useState(false);
  // const emailcheck = decrypt(ApiConfig.REACT_APP_SALT + 'email',email)
  // const mobilecheck = decrypt(ApiConfig.REACT_APP_SALT + 'mobile',mobile)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const { loading, lastUserFollowUpData } = useSelector(followUpsItemSelector);

  const [page, setPage] = useState(1);

  // useEffect(()=>{
  //   setTableListing(data)
  // },[data])

  const getListing = async (params = {}) => {

    const payload = {
      page: String(page),
      limit: limit,
      // email:email,
      ...allParams,
      ...params
    }
    setPage(Number(payload.page))
    if (email) {
      payload['email'] = email;
    }
    if (mobile) {
      payload['mobile'] = mobile;
    }

    if (payload?.mobile) {
      dispatch(followUpByMobileListingsWithRedux(payload))
    }
    if (payload?.email) {
      dispatch(followUpByEmailListingsWithRedux(payload))
    }
    // let newPayload={page:String(1),limit:limit,email:email}
  }
  const handleCreate = (value) => {
    setOpenCreateDaliySale(value);
    if (!value) {
      setSelectedData(null)
    }
  };
  const handleSelectedData = (data) => {
    setSelectedData(data);
    handleCreate(true)
  }
  useEffect(() => {
    getListing();
  }, [email, mobile]);

  // useEffect(() => {
  //   let params = getParameters()
  //   setAllParams(params)
  // }, [location?.search]);

  // useEffect(()=>{
  //   let data = getNewParameters(allParams)
  //   if(data?.search !== location.search){
  //     // navigate(data.newUrl, {replace: false})
  //     if(data?.parameters?.page){
  //       setPage(Number(data?.parameters?.page))
  //     }
  //   }
  //   getListing();
  // },[allParams])


  useEffect(() => {//resp?.result?.rows
    if (lastUserFollowUpData?.items?.result?.rows?.length >= 0) {
      // let data = videoCategoryList?.items?.data?.map((item)=>{
      //   return {
      //     sno: item.sno,
      //     name: item.name,
      //     ref_id: item.ref_id,
      //     search_id: item.search_id,
      //     type: item.type,
      //     sort_id: item.sort_id,
      //     display_name: item.display_name,
      //   }
      // })
      setTableListing(lastUserFollowUpData?.items?.result?.rows)
    }
  }, [lastUserFollowUpData.items]);



  //   let resp = await followUpByEmailListingsNoRedux({
  //     email: data?.data?.email,
  //     page: 1,
  //     limit: 10
  // })
  // setFollowUpData(resp)

  const actionBody = (data) => {

    return (<>
      <div style={{ display: "flex" }}>
        <CustomButton className="noMinWidth" onClick={() => {
          handleSelectedData(data)
        }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Add /></CustomButton>
        <Link className='textDecoration-none' to={`../../crm/saleEntryHistory/${data?.daily_sale_id}`} target='_blank'>
          <CustomButton className="noMinWidth" sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><VisibilityIcon /> </CustomButton>
        </Link>

        {/* <CustomButton variant="raised" sx={{ padding: '1px 1px', marginRight: 1, fontSize: '12px', bgcolor: '#fff0', minWidth: 20 }}><Delete/></CustomButton> */}
      </div>
    </>)
  }

  return (
    <CustomBox padding={0}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap', pt: 2, px: 2 }}>
        <Typography variant="h5">Last User Follows-Ups</Typography>
        {/* <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "10px"}}>
            <CustomButton color={'buttonPrimary'}  onClick={()=>{
            
            }}>
              View All
            </CustomButton>
          </Box> */}
      </Box>
      <Divder spacing={1} />
      <MatTable
        loading={false}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        action={actionBody}
        // tableMaxHeight={300}
        page={page}
        perPage={limit}
        total={lastUserFollowUpData?.items?.result?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
      {openCreateDaliySale && <CreateDailySaleFollow
        open={openCreateDaliySale}
        handleOpen={handleCreate}
        getListing={getListing}
        selectedData={selectedData}
      />}
    </CustomBox>

  );
}

export default LastUserFollowUpsFullView;
