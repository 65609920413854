import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../CommonComp/Divder';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomTabs from '../CommonComp/CustomTabs/CustomTabs';
import ImageTabBody from '../Image/ImageTabBody';
import { ImageData } from '../../data/imageData';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InvoicePage from '../Receipts/InvoicePage';
import { decrypt, roundOffValue } from '../../lib/helper';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Divider, Grid, TextField, Typography, useTheme } from '@mui/material';
import CreateProposalPage from '../ProposalManagement/CreateProposalPage';
import { LeftArrow } from '../../Asset/svg';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import UserDetailInputField from '../User/UserDetailInputField';
import { useDispatch, useSelector } from 'react-redux';
import { commonItemsSelector } from '../../redux/common';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import PaymentMethods from '../../Asset/image/payment-methods.png';
import ImageOrderSummary from '../ProposalManagement/ImageOrderSummary';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { userItemsSelector } from '../../redux/user';
import { SingleUserCartList, userDetailsApi } from '../../Services/userApis';
import { getCountry, getState } from '../../Services/commonService';
import ApiConfig from '../../config/ApiConfig';
import { CreateUserOrder } from '../../Services/OrderApis';
import { chequePaymentSelected } from '../../data/CheckoutMethods';



function CheckoutPage({ userId }) {
  const { jobData, countryData, stateData } = useSelector(commonItemsSelector);
  const {
    singleUserDetail,
    singleUserCartDetail,
    singleUserWishlistDetail,
    singleUserProposalDetail,
  } = useSelector(userItemsSelector);

  const [tabValue, setTabValue] = useState(1);
  const [isCheckedTerms, setIsCheckedTerms] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState('credit-debit-card');
  const [orderId, setOrderId] = useState(null);
  const [isDisabledState, setIsDisabledState] = useState({
    companyName: false,
    country: false,
    state: false,
    zipCode: false,
    mobile: false,
    gst_number: false,
    address: false,
  });
  const [userDetails, setUserDetails] = useState({});
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);
  const [countryShortName, setCountryShortName] = useState('in');
  const [stateList, setStateList] = useState([]);
  const [selectedCountry, setSelcetedCountry] = useState('');
  const [selectedState, setSelectedState] = useState({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const theme = useTheme();
  React.useEffect(() => {
    getUserDetails();
    getCountryDetails();
    getUserCartData();
  }, [searchParams]);
  const getCountryDetails = async () => {
    dispatch(getCountry());
  };
  const getUserCartData = async () => {
    const payload = {
      user_id: userId,
    };
    dispatch(SingleUserCartList(payload));
  };
  const checkIndication = (method) => {
    if (selectedPaymentMethod === method) {
      return true;
    } else {
      return false;
    }
  };
  const changePaymentMethod = (method) => {
    setSelectedPaymentMethod(method);
  };

  useEffect(() => {
    if (countryData && Array.isArray(countryData?.items)) {
      let data = countryData?.items?.map((item) => {
        return {
          label: item?.country_name,
          value: item?.country_id,
        };
      });
      setCountryList(data);
      getStateData(singleUserDetail);
      if (singleUserDetail?.items?.countries) {
        setCountryShortName(
          singleUserDetail?.items?.countries?.country_short_name?.toLowerCase(),
        );
      }
    }
  }, [countryData, singleUserDetail]);

  useEffect(() => {
    if (singleUserDetail?.items?.countries) {
      setSelcetedCountry(singleUserDetail?.items?.countries?.country_name);
      setSelectedState(singleUserDetail?.items?.states);
    }
  }, [singleUserDetail]);

  useEffect(() => {
    let initialState = {
      companyName: false,
      country: false,
      state: false,
      zipCode: false,
      mobile: false,
      gst_number: false,
      address: false,
    };
    if (
      singleUserDetail?.items?.company_name === '' ||
      singleUserDetail?.items?.company_name === undefined ||
      singleUserDetail?.items?.company_name === null
    ) {
      initialState.companyName = false;
    } else {
      initialState.companyName = true;
    }
    if (
      singleUserDetail?.items?.countries === '' ||
      singleUserDetail?.items?.countries === undefined ||
      singleUserDetail?.items?.countries === null
    ) {
      initialState.country = false;
    } else {
      initialState.country = true;
    }
    if (
      singleUserDetail?.items?.states === '' ||
      singleUserDetail?.items?.states === undefined ||
      singleUserDetail?.items?.states === null
    ) {
      initialState.state = false;
    } else {
      initialState.state = true;
    }
    if (
      singleUserDetail?.items?.zip_code === '' ||
      singleUserDetail?.items?.zip_code === undefined ||
      singleUserDetail?.items?.zip_code === null
    ) {
      initialState.zipCode = false;
    } else {
      initialState.zipCode = true;
    }
    if (
      singleUserDetail?.items?.address === '' ||
      singleUserDetail?.items?.address === undefined ||
      singleUserDetail?.items?.address === null
    ) {
      initialState.address = false;
    } else {
      initialState.address = true;
    }
    if (
      singleUserDetail?.items?.mobile === '' ||
      singleUserDetail?.items?.mobile === undefined ||
      singleUserDetail?.items?.mobile === null ||
      singleUserDetail?.items?.mobile === '+'
    ) {
      initialState.mobile = false;
    } else {
      initialState.mobile = true;
    }
    if (
      singleUserDetail?.items?.gst_number === '' ||
      singleUserDetail?.items?.gst_number === undefined ||
      singleUserDetail?.items?.gst_number === null
    ) {
      initialState.gst_number = false;
    } else {
      initialState.gst_number = true;
    }
    setIsDisabledState(initialState);
  }, [singleUserDetail]);

  const returnPaymentMethod = (method) => {
    if (
      method === 'credit-debit-card' ||
      method === 'net-banking'
    ) {
      return 'RazorPay';
    }
    if (method === 'american-express') {
      return 'American Express';
    }
    if (method === 'paypal') {
      return 'Paypal';
    }
    // if (method === 'mobikwik') {
    //   return 'Mobikwik';
    // }
    if (method === 'cheque-demand-draft') {
      return 'Cheque/demand draft';
    }
    if (method === 'neft-rtgs-wire-transfer') {
      return 'NEFT/RTGS/WT';
    }
    if (method === 'pay10') {
      return 'Pay10';
    }
    if (method === 'zaakpay') {
      return 'Zaakpay';
    }
    if (method === 'ccavenue') {
      return 'ccavenue';
    }
  };
  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  }, [stateData]);
  const getUserDetails = async () => {
    let payload = {
      user_id: userId,
      page: 1,
      limit: 1,
    };
    dispatch(userDetailsApi(payload));
  };
  const getStateData = async (user) => {
    if (user?.items?.countries) {
      dispatch(getState(user?.items?.countries?.country_id));
    }
  };

  const handleConfirm = async (values, { resetForm }) => {
    if (isCheckedTerms === false) {
      toast.error('Please check policy', {
        autoClose: 2500,
      });
    } else {
      let masterCreateOrderPayload = {
        payment_type: '',
        client: values?.client,
        user_id: userId,
        ordered_by: `${values?.first_name} ${values?.last_name}`,
        plan_id: '',
        plan_name: '',
        f_maxallowed: '',
        f_days: '',
        gst_number: values?.gst_number,
        company_name: values?.company_name,
        country: values?.country,
        state: values?.state,
        zip_code: values?.zip_code,
        address: values?.address,
        order_type: '',
        razorpay_order_id: '',
        razorpay_payment_id: '',
        razorpay_signature: '',
        payment_status: '0',
        initial_price: 0,
        tax: 0,
        final_price: 0,
        orderDetails: [],
      };
      let orderImagePayloadNormal = [];
      // CHECK IF NOT IVS CHECKOUT then ITS Normal CHECKOUT SO Update the MAIN Payload and add order type to NPlan
      singleUserCartDetail &&
        singleUserCartDetail?.items &&
        singleUserCartDetail?.items?.cart &&
        singleUserCartDetail?.items?.cart?.map((data) => {
          const imagePayload = {
            image_name: data?.image_name,
            final_price: Number(roundOffValue(data?.price + roundOffValue((data?.price / 100) * 18))) ,
            initial_price: data?.price,
            discount: 0,
            cgst: 0,
            sgst: 0,
            igst: 0,
            tax: Math.round((data?.price / 100) * 18),
            quality: data?.image_type,
            image_type: data?.image_type, 
            image_type_up: data?.image_type_up, 
            type: String(data?.type),
            dimension: `${data?.dimension}${String(data?.type) != '2' ? ` at ${data?.image_type?.toLowerCase().includes("web") ? "72dpi" : "300dpi"}`: ""}`,
            is_star: data?.is_star == "1" ? "1" : "0",
            state_for_star: data?.state || "",
            industry: data?.industry || "",
            license: data?.license || "",
            country_for_star: data?.country || "",
            duration_for_star: data?.duration || "",
            rights:data?.rights,

          };
          orderImagePayloadNormal.push(imagePayload);
        });
      masterCreateOrderPayload = {
        ...masterCreateOrderPayload,
        order_type: 'NPlan',
        discount: singleUserCartDetail?.items?.discount || 0,
        orderDetails: orderImagePayloadNormal,
        final_price: Number(roundOffValue(singleUserCartDetail?.items?.final_price)) || 0,
        initial_price: singleUserCartDetail?.items?.initial_price || 0,
        tax: singleUserCartDetail?.items?.tax || 0,
      };
      // IF Payment Is Offline Than Update payment_type
      if (selectedPaymentMethod) {
        setIsLoading(true);
        // IF Payment Is Offline and it is Cheque or DD Than Update payment_type to Cheque/demand draft
        masterCreateOrderPayload = {
          ...masterCreateOrderPayload,
          payment_type: returnPaymentMethod(selectedPaymentMethod),
        };
        const resp = await CreateUserOrder(masterCreateOrderPayload)
        if (resp) {
          navigate('/order?tab=2', {replace: true});
        }
        setIsLoading(false);
      }
    }
  };
  let gstValidationSchema = {};

  if (selectedCountry === 'India') {
    gstValidationSchema = {
      gst_number: Yup.string()
        .trim()
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
          'Please enter valid GST Number',
        )
        .test(
          'isValidGST',
          'Please enter correct GST Number',
          function (value) {
            let isValid = value?.startsWith(
              selectedState?.state_code?.toString().length > 1
                ? selectedState?.state_code
                : `0${selectedState?.state_code}`,
            );
            return value === '' || value === null || value === undefined
              ? true
              : isValid;
          },
        ),
    };
  }
  const formik = useFormik({
    initialValues: {
      first_name: singleUserDetail?.items?.first_name || '',
      last_name: singleUserDetail?.items?.last_name || '',
      email: singleUserDetail?.items?.email?.trim() || '',
      company_name: singleUserDetail?.items?.company_name || '',
      country: singleUserDetail?.items?.countries?.country_name || '',
      state: singleUserDetail?.items?.states?.state_name || '',
      zip_code: singleUserDetail?.items?.zip_code || '',
      address: singleUserDetail?.items?.address || '',
      mobile: singleUserDetail?.items?.mobile || '+',
      gst_number: singleUserDetail?.items?.gst_number || '',
      client: singleUserDetail?.items?.client || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      first_name: Yup.string().required('First Name is required'),
      last_name: Yup.string().required('Last Name is required'),
      company_name: Yup.string().required('Company Name is required'),
      country: Yup.string().required('Country Name is required'),
      state:
        stateData.isStateAvailable === true &&
        Yup.string().required('State Name is required'),
      ...gstValidationSchema,
      zip_code: Yup.string().required('Zip Code is required'),
      address: Yup.string().required('Address is required'),
      client: Yup.string().required('Client name is required'),
      email: Yup.string()
        .email('Please Enter Email')
        .required('Please Enter Email'),
      mobile: Yup.string()
        .max(18, 'Mobile Number is Invalid')
        .min(10, 'Mobile Number is Invalid')
        .required('Mobile Number is required'),
    }),
    onSubmit: handleConfirm,
  });
  useEffect(() => {
    let isAnyError = false;
    if (
      Boolean(formik.errors.company_name) ||
      Boolean(formik.errors.country) ||
      Boolean(formik.errors.state) ||
      Boolean(formik.errors.zip_code) ||
      Boolean(formik.errors.mobile) ||
      Boolean(formik.errors.address) ||
      Boolean(formik.errors.client)
    ) {
      if (isAnyError === false && formik.isSubmitting === false) {
        toast.error('Please fill required fields', { autoClose: 2000 });
        isAnyError = true;
      }
    }
  }, [formik.isSubmitting]);

  const handleChangeCountry = async (e) => {
    formik.setFieldValue('country', e.target.value);
    formik.setFieldValue('state', '');
    formik.setFieldValue('gst_number', '');
    setSelcetedCountry(e.target.value || '');
    setSelectedState({});
    let country_id = 0;
    countryData?.items?.filter((val) => {
      if (val.country_name === e.target.value) {
        country_id = val.country_id;
        setCountryShortName(val?.country_short_name?.toLowerCase());
        formik.setFieldValue('mobile', '');
      }
    });
    dispatch(getState(country_id));
  };

  const handleStateChange = (val) => {
    let stateSelected = stateData?.items?.filter(
      (item) => item.state_name == val,
    );
    if (stateSelected.length) {
      setSelectedState(stateSelected[0]);
    }
  };
  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobile', val);
  };
  return (
    <>
      <Box
        component="main"
        sx={(theme) => {
          return {
            flexGrow: 1,
          };
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <CustomBox>
              <form onSubmit={formik.handleSubmit} className="fontWeight600">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <UserDetailInputField
                      disabled={true}
                      label="First Name"
                      className="textfield-research-form"
                      fullWidth
                      variant="outlined"
                      id="first_name"
                      name="first_name"
                      placeholder="Enter Your First Name"
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      value={formik.values.first_name}
                      // onChange={formik.handleChange}
                      error={
                        formik.touched.first_name &&
                        Boolean(formik.errors.first_name)
                      }
                      helperText={
                        formik.touched.first_name && formik.errors.first_name
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <UserDetailInputField
                      disabled={true}
                      label="Last Name"
                      className="textfield-research-form"
                      fullWidth
                      variant="outlined"
                      id="last_name"
                      name="last_name"
                      placeholder="Enter Your Last Name"
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      value={formik.values.last_name}
                      // onChange={formik.handleChange}
                      error={
                        formik.touched.last_name &&
                        Boolean(formik.errors.last_name)
                      }
                      helperText={
                        formik.touched.last_name && formik.errors.last_name
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <UserDetailInputField
                      disabled={true}
                      label="Email"
                      className="textfield-research-form"
                      fullWidth
                      variant="outlined"
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter Your Email ID"
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      value={formik.values.email}
                      // onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <UserDetailInputField
                      disabled={isDisabledState?.companyName}
                      label="Company Name"
                      className="textfield-research-form"
                      fullWidth
                      variant="outlined"
                      id="company_name"
                      name="company_name"
                      placeholder="Enter Your Company Name"
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      value={formik.values.company_name}
                      // onChange={formik.handleChange}
                      error={
                        formik.touched.company_name &&
                        Boolean(formik.errors.company_name)
                      }
                      helperText={
                        formik.touched.company_name &&
                        formik.errors.company_name
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <UserDetailInputField
                      disabled={isDisabledState?.country}
                      name="country"
                      label="Country"
                      id="country"
                      placeholder="Country"
                      SelectProps={{
                        native: true,
                      }}
                      select
                      value={formik.values.country}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      // onChange={(e) => {
                      //   formik.setFieldValue('country', e.target.value);
                      // }}
                      onChange={(e) => handleChangeCountry(e)}
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      helperText={
                        formik.touched.country && formik.errors.country
                      }
                    >
                      <option key={'Select Country'} value={''}>
                        Select Country
                      </option>
                      {countryList &&
                        countryList?.map((country) => (
                          <option key={country.value} value={country.label}>
                            {country.label}
                          </option>
                        ))}
                    </UserDetailInputField>
                  </Grid>
                  {selectedState && (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <UserDetailInputField
                        disabled={isDisabledState?.state}
                        name="state"
                        label="State"
                        placeholder="State"
                        SelectProps={{
                          native: true,
                        }}
                        select
                        value={formik.values.state}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleStateChange(e.target.value);
                        }}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                      >
                        <option key={'Select State'} value={''}>
                          {formik.values.country
                            ? 'Select state'
                            : 'Please select country first'}
                        </option>
                        {stateList &&
                          stateList?.map((state) => {
                            return (
                              <option key={state.value} value={state.label}>
                                {state.label}
                              </option>
                            );
                          })}
                      </UserDetailInputField>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <UserDetailInputField
                      disabled={isDisabledState?.zipCode}
                      name="zip_code"
                      label="Zip / Pin Code"
                      placeholder="Zip / Pin Code"
                      value={formik.values.zip_code}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={(e) => {
                        formik.setFieldValue('zip_code', e.target.value);
                      }}
                      error={
                        formik.touched.zip_code &&
                        Boolean(formik.errors.zip_code)
                      }
                      helperText={
                        formik.touched.zip_code && formik.errors.zip_code
                      }
                      inputProps={{ maxLength: 15 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <UserDetailInputField
                      disabled={isDisabledState?.address}
                      name="address"
                      label="Address"
                      placeholder="Address"
                      multiline
                      maxRows={4}
                      value={formik.values.address}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={(e) => {
                        formik.setFieldValue('address', e.target.value);
                      }}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CustomPhoneInput
                      label="Mobile"
                      id="mobile"
                      disabled={isDisabledState?.mobile}
                      name="mobile"
                      className="main-login-form"
                      fullWidth
                      placeholder="Mobile Number"
                      native={true}
                      value={formik.values.mobile}
                      defaultCountry={`${countryShortName}`}
                      country={`${countryShortName}`}
                      divStyle={{
                        paddingTop: 4,
                      }}
                      variant="outlined"
                      onChange={(newValue, info) => {
                        handleChangeMobile(newValue);
                      }}
                      onlyCountries={[countryShortName]}
                      error={
                        formik.touched.mobile && Boolean(formik.errors.mobile)
                      }
                      helperText={formik.touched.mobile && formik.errors.mobile}
                    />
                  </Grid>
                  {selectedCountry === 'India' && (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <UserDetailInputField
                        disabled={isDisabledState?.gst_number}
                        name="gst_number"
                        label="GSTIN No."
                        placeholder="GSTIN No."
                        value={formik.values.gst_number}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        onChange={(e) => {
                          formik.setFieldTouched('gst_number', true, true);
                          formik.setFieldValue(
                            'gst_number',
                            e.target.value.toUpperCase(),
                          );
                        }}
                        inputProps={{
                          maxLength: 15,
                        }}
                        error={
                          formik.touched.gst_number &&
                          Boolean(formik.errors.gst_number)
                        }
                        helperText={
                          formik.touched.gst_number && formik.errors.gst_number
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="p" sx={{ color: 'red' }}>
                      This will enable to avail credit of the taxes to be
                      charged on services directly to your account as per GST
                      regulations.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <UserDetailInputField
                      name="client"
                      label="Client Name"
                      placeholder="Enter Client Name"
                      value={formik.values.client}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={(e) => {
                        formik.setFieldTouched('client', true, true);
                        formik.setFieldValue(
                          'client',
                          e.target.value,
                        );
                      }}
                    
                      error={
                        formik.touched.client && Boolean(formik.errors.client)
                      }
                      helperText={formik.touched.client && formik.errors.client}
                      inputProps={{ maxLength: 100 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="p" sx={{ color: 'red' }}>
                      [The "Client/Designated End User" means the specific
                      product, service or entity that is being promoted by the
                      use of the item. It is important to name PRECISELY the
                      intended end-user so that you do, indeed, secure the
                      rights you actually need.]
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </CustomBox>
            <Divder spacing={1} />
            <CustomBox>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6">
                  Pay using different payment methods
                </Typography>
                <img src={PaymentMethods} alt="Payment Icon"></img>
              </Box>
              <Box sx={{ marginTop: '10px' }}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <UserDetailInputField
                    name="Payment Method"
                    label="Payment Method"
                    id="paymentmethod"
                    placeholder="Select Payment Method"
                    SelectProps={{
                      native: true,
                    }}
                    select
                    value={selectedPaymentMethod}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    onChange={(e) => changePaymentMethod(e.target.value)}
                  >
                    {chequePaymentSelected &&
                      chequePaymentSelected?.map((country) => (
                        <option key={country.value} value={country.value}>
                          {country.label}
                        </option>
                      ))}
                  </UserDetailInputField>
                </Grid>
              </Box>
            </CustomBox>
            <Divder spacing={1} />
            <CustomBox>
              <Grid item xs={12}>
                <Typography variant="p" sx={{ color: 'red' }}>
                  Pornographic, defamatory, libelous, immoral, obscene,
                  fraudulent or otherwise unlawful use of the item is strictly
                  prohibited.
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '5px' }}>
                <input
                  type="checkbox"
                  checked={isCheckedTerms}
                  onChange={(e) => setIsCheckedTerms(e.target.checked)}
                  name="policy-confirmation"
                />
                <Typography
                  variant="p"
                  sx={{ marginLeft: '10px', color: 'red' }}
                >
                  Please read the License Agreements before clicking on the
                  "Confirm Order" button. Your acceptance of these terms &
                  conditions is an absolute condition to your use of any item
                  available at {ApiConfig.LIVE_WEBSITE_URL}
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign="right" sx={{my: 2}}>
                <CustomButton
                  onClick={() => formik.handleSubmit()}
                  color={'buttonPrimary'}
                  loading={isLoading}
                >
                  Create Order
                </CustomButton>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ padding: '0px 20px', marginTop: '10px' }}
              >
                <ul>
                  <li>
                    <Typography variant="p" className="">
                      The charges will be billed to your Card/Bank Statement as
                      MASH AUDIO VISUALS PVT. LTD.(the parent company of
                      ImagesBazaar).
                    </Typography>
                  </li>
                  <li className="top-space-10">
                    <Typography variant="p" className="">
                      After submitting the page, please wait for the intimation
                      from the server. DO NOT press Back or Refresh button in
                      order to avoid double charge.
                    </Typography>
                  </li>
                  <li className="top-space-10">
                    <Typography variant="p" className="">
                      For Credit Card users: All prices are listed in Indian
                      Rupee (INR). If you use a non-Indian credit card, your
                      bank will convert to your local currency based on
                      prevailing exchange rates.
                    </Typography>
                  </li>
                  <li className="top-space-10">
                    <Typography variant="p" className="">
                      Security Advisory: To ensure the security of your data,
                      please close the browser window once your transaction is
                      completed.
                    </Typography>
                  </li>
                  <li className="top-space-10">
                    <Typography variant="p" className="">
                      We as a merchant shall be under no liability whatsoever in
                      respect of any loss or damage arising directly or
                      indirectly out of the decline of authorization for any
                      Transaction, on Account of the Cardholder having exceeded
                      the preset limit mutually agreed by us with our acquiring
                      bank from time to time.
                    </Typography>
                  </li>
                </ul>
              </Grid>
            </CustomBox>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <CustomBox padding={3}>
              <Typography
                variant="h5"
                className="fontWeight600"
                textAlign={'center'}
              >
                Order Cart Amount
              </Typography>
              <Divder spacing={1} />
              <Typography variant="h5" component={'p'} textAlign={'center'}>
                <span>Amount Total</span> : <span>INR {Number(singleUserCartDetail?.items?.initial_price)?.toFixed(2)}</span>
              </Typography>
              {singleUserCartDetail?.items?.discount > 0 && <>
                <Divder spacing={0.4} />
                <Typography variant="h5" component={'p'} textAlign={'center'}>
                  <span>Discount ({Number(singleUserCartDetail?.items?.discount_percentage)?.toFixed(2)}%)</span> : <span>INR {Number(singleUserCartDetail?.items?.discount)?.toFixed(2)}</span>
                </Typography>
              </>}
              <Divder spacing={0.4} />
              <Typography variant="h5" component={'p'} textAlign={'center'}>
                <span>Tax @18%</span> : <span>INR {Number(singleUserCartDetail?.items?.tax)?.toFixed(2)}</span>
              </Typography>
              <Divder spacing={0.4} />
              <Typography variant="h5" component={'p'} textAlign={'center'}>
                <span>Net Payable Amount</span> : <span>INR {Number(roundOffValue(singleUserCartDetail?.items?.final_price))?.toFixed(2)}</span>
              </Typography>
            </CustomBox>
            <Divder spacing={1.5} />
            <CustomBox padding={3}>
              <Typography variant="h3" className="fontWeight600">
                Order Summary
              </Typography>
              <Divder spacing={1} />
              <Box
                sx={{ height: 'auto', maxHeight: '400px', overflowY: 'auto' }}
              >
                {singleUserCartDetail?.items?.cart?.map((data, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        gap: '15px',
                        marginTop: '10px',
                        alignItems: 'center',
                        '@media only screen and (min-width: 280px) and (max-width: 1024px)':
                          {
                            flexDirection: 'column',
                          },
                      }}
                    >
                      <img
                        style={{
                          width: '100%',
                          maxWidth: '150px',
                          '@media only screen and (min-width: 280px) and (max-width: 1024px)':
                            {
                              maxWidth: '100px',
                            },
                          height: 'auto',
                          borderRadius: '5px',
                        }}
                        src={`${ApiConfig.BASE_IMAGE_URL}${data?.shootid}/${data?.rank}-${data?.image_name}.jpg`}
                        alt="ImageOfCartItem"
                      />
                      <div>
                        <Typography
                          variant="h6"
                          className="boldFont"
                          component="div"
                          sx={{ marginBottom: '5px' }}
                        >
                          {`ID : ${data?.image_name}`}
                        </Typography>
                        <Typography
                          variant="p"
                          component="div"
                          sx={{ marginBottom: '5px' }}
                        >
                          {`${data?.type == '2'? "Video Type" :"Image Type"} : ${data?.image_type}`}
                        </Typography>
                        <Typography
                          variant="p"
                          component="div"
                          sx={{ marginBottom: '5px' }}
                        >
                          {`File Type: ${data?.type == '2' ? data?.image_type :
                            data?.image_type?.toLowerCase()?.includes('large') ||
                            data?.image_type?.toLowerCase()?.includes('medium')
                              ? 'TIFF'
                              : 'JPEG'
                          }`}
                        </Typography>
                        <Typography
                          variant="p"
                          component="div"
                          sx={{ marginBottom: '5px' }}
                        >
                          {`INR: ${data?.price}`}
                        </Typography>
                      </div>
                    </Box>
                  );
                })}
              </Box>
            </CustomBox>
          </Grid>
        </Grid>
        <Divder spacing={1.2} />
      </Box>
    </>
  );
}

export default CheckoutPage;
