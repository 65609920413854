import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../lib/helper';
import { ConfirmedOrderListings, markAsPaidOrder } from '../../Services/OrderApis';

const limit = 10;

function OrderPaidProcessModal({ open, handleOpen, setPage, paidProcessArr, paidProcessUser, setPaidProcessArr, setPaidProcessUser }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let currentDate = new Date()
  const handleConfirm = async (values, { resetForm }) => {
    let payload = {
      ...values,
      paid_on: formatDate(values.paid_on, 'iso'),
    }

    setLoading(true)
    let resp = await markAsPaidOrder(payload)
    if(resp){
      resetForm({
        user_name : '',
        order_id : paidProcessArr.join(', ') || "",
        paid_on : formatDate(new Date(), "yyyy-mm-dd") || '',
        cc_mail : "aastha@imagesbazaar.com",
      })
      setPage(1)
      dispatch(ConfirmedOrderListings({
        page: String(1),
        limit: limit,
        order_status: String(1)
      }))
      handleOpen(false)
      setPaidProcessArr([])
      setPaidProcessUser('')
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      user_name : paidProcessUser?.trim() || '',
      order_id : paidProcessArr.join(', ') || "",
      paid_on : formatDate(new Date(), "yyyy-mm-dd") || '',
      cc_mail : "aastha@imagesbazaar.com",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      user_name: Yup.string().email('Please enter valid email.').required('Username is required.'),
      order_id: Yup.string().required('Order ID is required.'),
      paid_on: Yup.string().required('Paid on is requried.'),
      cc_mail: Yup.string().email('Please enter valid email.').required('CC mail is required.'),
    }),
    onSubmit: handleConfirm,
  }); 
  
  return (
    <div>
      <Dialog
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant='h3'>Mark As Paid Process</DialogTitle>
        <DialogContent>
        <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="order_id"
                  label="Order ID"
                  placeholder="Order ID"
                  value={formik.values.order_id}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.order_id && Boolean(formik.errors.order_id)}
                  helperText={formik.touched.order_id && formik.errors.order_id}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="user_name"
                  label="Username"
                  placeholder="Username"
                  value={formik.values.user_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  disabled
                  error={formik.touched.user_name && Boolean(formik.errors.user_name)}
                  helperText={formik.touched.user_name && formik.errors.user_name}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  name="paid_on"
                  type="date"
                  label="Paid On"
                  placeholder="Paid On"
                  value={formatDate(formik.values.paid_on, "yyyy-mm-dd")} //2017-06-13T13:00
                  onChange={(e)=>{
                    formik.handleChange(e)
                  }}
                  error={formik.touched.paid_on && Boolean(formik.errors.paid_on)}
                  helperText={formik.touched.paid_on && formik.errors.paid_on}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="cc_mail"
                  label="CC TO"
                  placeholder="CC TO"
                  value={formik.values.cc_mail}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.cc_mail && Boolean(formik.errors.cc_mail)}
                  helperText={formik.touched.cc_mail && formik.errors.cc_mail}
                />
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Confirm</CustomButton>
              <CustomButton
                onClick={() => {
                    formik.resetForm({
                      user_name : "",
                      order_id : "",
                    });
                    handleOpen(false)}
                }
                disabled={loading}
              >Cancel</CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OrderPaidProcessModal;
