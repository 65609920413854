import React from 'react';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import './button.style.css';
function CrossButton({ariaLabel, size, color, variant,disabled,handleCrossIcon, ...props }) {
    
  return (
    
    <Button variant="outlined" className='parentButtonCross' style={{padding: '2px','margin-bottom': '4px',fontSize:'10px'}} >
       {props.children}
       <CancelIcon className='childButtonCross' onClick={(e)=>{handleCrossIcon(props?.children);}}  style={{'font-size': '20px'}}/>
   </Button>
  );
}

export default CrossButton;

CrossButton.defaultProps = {
  color: 'buttonNormal',
  variant: 'contained',
  disabled: false,
};
