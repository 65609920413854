import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { deleteApiPayload, errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setLoading,
  setCompanyMasterListingLoading,
  setCompanyMasterListing,
  setCompanyMasterListingError,
  setCompanyAssignedListing, setCompanyAssignedListingError, setCompanyAssignedListLoading,
  setCompanyGroupsListing, setCompanyGroupsListingError, setCompanyGroupsListingLoading,
  setCompanyListing, setCompanyListingError, setCompanyListingLoading,
  setCompanyUnassignedListing, setCompanyUnassignedListingError, setCompanyUnassignedListLoading, setCompanyOverviewUserListingLoading, setCompanyOverviewUserListing, setCompanyOverviewUserListingError, setCompanyOverViewOrderListingLoading, setCompanyOverViewOrderListing, setCompanyOverViewOrderListingError, setCompanyListEarning, setCompanyListEarningLoading
} from '../redux/company';
import { resetShortCompanyData } from '../redux/master';

export const companyListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setCompanyListingLoading(true));
        const resp = await axios.post(`${ApiConfig.companyListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setCompanyListing(resp.data.data));
          dispatch(setCompanyListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setCompanyListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setCompanyListingLoading(false));
      }
    };
  }
};

export const companyEarningTotal = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setCompanyListingLoading(true));
        const resp = await axios.post(`${ApiConfig.totalEarning}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setCompanyListEarning(resp.data.data));
          dispatch(setCompanyListEarningLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setCompanyListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setCompanyListEarningLoading(false));
      }
    };
  }
};

export const companyUnassignedListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setCompanyUnassignedListLoading(true))
        const resp = await axios.post(`${ApiConfig.companyListing}`, payload);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          dispatch(setCompanyUnassignedListing(resp.data.data));
          dispatch(setCompanyUnassignedListLoading(false))
        } else {
          throw resp
        }
      } catch (e) {
        dispatch(setCompanyUnassignedListingError({
          isError: true,
          message: errorMessage(e)
        }));
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setCompanyUnassignedListLoading(false))
      }
    };
  }
};

export const companyOverviewUserListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setCompanyOverviewUserListingLoading(true))
        const resp = await axios.post(`${ApiConfig.overviewUserLists}`, payload);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          dispatch(setCompanyOverviewUserListing(resp.data.data));
          dispatch(setCompanyOverviewUserListingLoading(false))
        } else {
          throw resp
        }
      } catch (e) {
        dispatch(setCompanyOverviewUserListingError({
          isError: true,
          message: errorMessage(e)
        }));
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setCompanyOverviewUserListingLoading(false))
      }
    };
  }
};

export const companyOverviewOrderListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setCompanyOverViewOrderListingLoading(true))
        const resp = await axios.post(`${ApiConfig.orderByuser}`, payload);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          dispatch(setCompanyOverViewOrderListing(resp.data.data));
          dispatch(setCompanyOverViewOrderListingLoading(false))
        } else {
          throw resp
        }
      } catch (e) {
        dispatch(setCompanyOverViewOrderListingError({
          isError: true,
          message: errorMessage(e)
        }));
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setCompanyOverViewOrderListingLoading(false))
      }
    };
  }
};

export const companyAssignedListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setCompanyAssignedListLoading(true))
        const resp = await axios.post(`${ApiConfig.companyListing}`, payload);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          dispatch(setCompanyAssignedListing(resp.data.data));
          dispatch(setCompanyAssignedListLoading(false))
        } else {
          throw resp
        }
      } catch (e) {
        dispatch(setCompanyAssignedListingError({
          isError: true,
          message: errorMessage(e)
        }));
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setCompanyAssignedListLoading(false))
      }
    };
  }
};

export const companyAssignedListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.companyListing}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data
      } else {
        throw resp
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return null
    }
  }
};

export const companyGroupsListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setCompanyGroupsListingLoading(true));
        const resp = await axios.get(
          `${ApiConfig.companyGroupsListing}?page=${payload?.page || 1}&limit=${payload?.limit || 10
          }&search=${payload?.search || ''}`,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setCompanyGroupsListing(resp.data.data));
          dispatch(setCompanyGroupsListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setCompanyGroupsListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setCompanyGroupsListingLoading(false));
      }
    };
  }
};

export const deleteCompany = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.company}`,
        deleteApiPayload(payload),
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteCompanyGroup = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.companygroup}`,
        deleteApiPayload(payload),
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const addCompanyMaster = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.addCompanyMaster}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const addCompanyGroup = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.addCompanyGroups}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const companyGroupListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.companyGroupByName}?company_group_name=${payload.company_group_name}&page=${payload.page}&limit=${payload.limit}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const companyMasterListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setCompanyMasterListingLoading(true));
        // const resp = await axios.get(
        //   `${ApiConfig.companyMasterListing}?page=${payload?.page || 1}&limit=${payload?.limit || 10
        //   }&search=${payload?.search || ''}`,
        // );
        const resp = await axios.post(
          `${ApiConfig.companyMasterListing}`,payload)  
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setCompanyMasterListing(resp.data.data));
          dispatch(setCompanyMasterListingLoading(false));
          dispatch(resetShortCompanyData())
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setCompanyMasterListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setCompanyMasterListingLoading(false));
      }
    };
  }
};

export const addNewCompanyMaster = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.addCompanyMaster}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const editCompanyMaster = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.put(`${ApiConfig.editCompanyMaster}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};
export const updateNonRegisteredCompany = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.updateNonRegisteredCompany}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};
export const updateShortCompany = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.updateShortCompany}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};