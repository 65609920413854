import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import 
MasterMultipleFollowUps from './MasterMultipleFollowUps'

function MasterMultipleFollowUpsContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "Master",
    navSubTitle: "Welcome to ImagesBazaar"
  })
  const handleNavParams = (obj)=>{
    setNavParams({
        ...navParams,
        ...obj
    })
  }
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
      <MasterMultipleFollowUps handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default MasterMultipleFollowUpsContainer