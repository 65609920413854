import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchBy, SearchByUnAssigned } from '../../data/companyData';
import { breakStringIntoLines, formatDate, getArrayOfObjJoinToStringForKey, getNewParameters, getObjectSubset, getParameters, removeExtraSpace } from '../../lib/helper';
import { companyItemsSelector } from '../../redux/company';
import { companyListings, companyUnassignedListings, deleteCompany } from '../../Services/companyApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import UpdateShortCompanyNameModal from '../Modals/UpdateShortCompanyNameModal';
import MatTable from '../Tables/MatTable';

const column = [
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Email',
    id: 'user_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Group Name',
    id: 'company_group_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Identity',
    id: 'identity',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Company Short Name',
    id: 'user_id',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
      <CustomButton className='buttonDense' onClick={() => {
        option([columnData])
      }} sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}>Update</CustomButton>
    ),
  },
  {
    label: 'Associations',
    id: 'user_associations',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-word' }}
      >
        {removeExtraSpace(getArrayOfObjJoinToStringForKey(columnData, 'association'), ' , ') || '-'}
      </span>
    ),
  },
  {
    label: 'Company Address',
    id: 'address',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content', wordBreak: 'break-word' }}
      >
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}

      </span>
    ),
  }
];

function CompanyUnassignedList() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, companyUnassignedList } = useSelector(companyItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [clearSelectedCheckbox, setClearSelectedCheckbox] = useState(false);
  const [updateShortCompanyNameModal, setUpdateShortCompanyNameModal] = useState(false);
  const [editData, setEditData] = useState({});

  const handleAdd = (value = false) => {
    setAddCompanyModal(value)
  }
  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      filled: "0",
      limit: limit,
      // status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(companyUnassignedListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (companyUnassignedList?.items?.rows?.length >= 0) {
      setTableListing(companyUnassignedList?.items?.rows);
    }
  }, [companyUnassignedList.items]);

  const handleClearSelectedCheckbox = (value = false) => {
    setClearSelectedCheckbox(value || false)
  }

  const handleModal = (value = false) => {
    setUpdateShortCompanyNameModal(value);
    if (!value) {
      setTimeout(() => {
        setSelectedUserIds([]);
      }, 500);
    }
  };

  const handleBulkUpdate = async (data, resetState) => {
    handleModal(true);
    setSelectedUserIds(data)
  }

  return (
    <>
      <MatTable
        loading={companyUnassignedList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'search_by'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'search_by'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        showPageLimit={true}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={companyUnassignedList?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        columnWiseData={[
          {
            id: 'user_id',
            value: handleBulkUpdate,
          }
        ]}

        checkboxEnable={true}
        customCheckboxDataKey={'user_id'}
        onTableToolbarRightClick={handleBulkUpdate}
        clearSelectedCheckbox={clearSelectedCheckbox}
        handleClearSelectedCheckbox={handleClearSelectedCheckbox}// requred to clear filter and refresh state after api call 
        onTableToolbarRightSection={<CustomButton sx={(theme) => ({
          fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
          [theme.breakpoints.down("md")]: {
            flex: 1,
          },
        })}
        >Bulk Update</CustomButton>}
        dropdownFilter={true}
        selectFilterArray={[
          {
            name: 'search_by',
            label: 'Search By',
            placeholder: 'Search By',
            options: SearchByUnAssigned
          },
        ]}
      />
      {updateShortCompanyNameModal && <UpdateShortCompanyNameModal
        open={updateShortCompanyNameModal}
        handleOpen={handleModal}
        dataArray={selectedUserIds}
        getListing={getListing}
        handleClearSelectedCheckbox={handleClearSelectedCheckbox}
      />}
    </>
  );
}

export default CompanyUnassignedList;
