import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import {
  setLoading,
  setOrderSubscriptionError,
  setOrderSubscriptionListing,
  setOrderSubscriptionListingLoading,
  setSpecialLoading,
  setSpecialOrderSubscriptionError,
  setSpecialOrderSubscriptionListing,
  setSpecialOrderSubscriptionListingLoading,
  setStandardLoading,
  setStandardOrderSubscriptionError,
  setStandardOrderSubscriptionListing,
  setStandardOrderSubscriptionListingLoading,
} from '../redux/subscription';
import { STATUS_200, STATUS_300 } from '../data/constants';

export const subscriptionListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      if(payload?.pack_type){
        if(payload?.pack_type==1){
          try{
            if(payload?.pack_type==1){
              dispatch(setStandardOrderSubscriptionListingLoading(true));
              const resp = await axios.post(
                `${ApiConfig.orderSubscriptionListing}`,
                payload,
              );
              if (
                resp?.data?.status >= STATUS_200 &&
                resp?.data?.status < STATUS_300
              ) {
                
                dispatch(setStandardOrderSubscriptionListing(resp.data.data));
                dispatch(setStandardOrderSubscriptionListingLoading(false));
              } else {
                throw resp;
              }
  
            }

          }
          catch(e){
            dispatch(
              setStandardOrderSubscriptionError({
                isError: true,
                message: errorMessage(e),
              }),
            );
            toast.error(errorMessage(e), {
              autoClose: 2500,
            });
            dispatch(setStandardOrderSubscriptionListingLoading(false));

          }
        }
        else if(payload?.pack_type==2){
          try{
           
              dispatch(setSpecialOrderSubscriptionListingLoading(true));
              const resp = await axios.post(
                `${ApiConfig.orderSubscriptionListing}`,
                payload,
              );
              if (
                resp?.data?.status >= STATUS_200 &&
                resp?.data?.status < STATUS_300
              ) {
                dispatch(setSpecialOrderSubscriptionListing(resp.data.data));
                dispatch(setSpecialOrderSubscriptionListingLoading(false));
              } else {
                throw resp;
              }

          }
          catch(e){
            dispatch(
              setSpecialOrderSubscriptionError({
                isError: true,
                message: errorMessage(e),
              }),
            );
            toast.error(errorMessage(e), {
              autoClose: 2500,
            });
            dispatch(setSpecialOrderSubscriptionListingLoading(false));

          }
        }

      }
      else{
        try{
          dispatch(setOrderSubscriptionListingLoading(true));
          const resp = await axios.post(
            `${ApiConfig.orderSubscriptionListing}`,
            payload,
          );
          if (
            resp?.data?.status >= STATUS_200 &&
            resp?.data?.status < STATUS_300
          ) {
            dispatch(setOrderSubscriptionListing(resp.data.data));
            dispatch(setOrderSubscriptionListingLoading(false));
          } else {
            throw resp;
          }

        }
        catch (e) {
          dispatch(
            setOrderSubscriptionError({
              isError: true,
              message: errorMessage(e),
            }),
          );
          toast.error(errorMessage(e), {
            autoClose: 2500,
          });
          dispatch(setOrderSubscriptionListingLoading(false));
        }
      }
    
    };
  }
};

export const addSubscriptionPlan = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createsubscriptionplan}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const editSubscriptionPlan = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editsubscriptionplan}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const subscriptionListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.selectSubscriptionListing}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
    }
  }
};