import { Edit, InsertEmoticon } from '@material-ui/icons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Delete } from '../../Asset/svg';
import { formatDate, getObjectSubset, getParameters, getNewParameters, validDate, currencyConversion } from '../../lib/helper';
import { subscriptionItemsSelector } from '../../redux/subscription.js';
import { subscriptionListings } from '../../Services/SubscriptionApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomIconButtons from '../CommonComp/Buttons/IconButtons';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import AddSubscription from '../Modals/AddSubscription';
import EditSubscription from '../Modals/EditSubscription';
import MatTable from '../Tables/MatTable';
import { useNavigate, useLocation } from 'react-router-dom';
import AllFilters from '../Order/AllFilters.jsx';
import { toast } from 'react-toastify';
import ViewSubscription from '../Modals/ViewSubscription.jsx';

const limit = 10;

const column = [
  {
    label: 'Plan ID',
    id: 'plan_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData}</span>
    )
  },
  {
    label: 'Plan Name',
    id: 'plan_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: "Plan's Validity",
    id: 'plan_validity',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>),
    nextLine:true,
  },
  {
    label: "Plan's Price(INR)",
    id: 'plan_price',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
      {columnData && currencyConversion(columnData) || 'N/A'}
    </span>),
    nextLine:true,
  },
  {
    label: 'Plan Type',
    id: 'plan_type',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
 
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData == 1 ? 'Active' : 'Inactive'}</span>)
  },
  {
    label: 'Plan Description',
    id: 'plan_description',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: 200 }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Maximum Downloads',
    id: 'maximum_downloads',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>),
    nextLine:true,
  },

];

function StandardSubscriptionList() {
  const [orderSubListing, setOrderSubListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, subscriptionStandardList } = useSelector(subscriptionItemsSelector);
  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const navigate = useNavigate()
  const location = useLocation();
  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        if (!validDate(e.target.value, new Date(), true)) {
          setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[index].value = e.target.value;
            newState[1].value = e.target.value;
            return newState;
          });
        }
        else {
          toast.error("Date should not be greater than today's date.")
        }
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        if (!validDate(e.target.value, new Date(), true)) {
          setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[index].value = e.target.value;
            return newState;
          });
        }
        else {
          toast.error("Date should not be greater than today's date.")
        }
  
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Plan Type',
      id: 'plan_type',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'All', value: '' },
        { label: 'SMALL', value: 'SMALL' },
        { label: 'MEDIUM', value: 'MEDIUM' },
        { label: 'LARGE', value: 'LARGE' },
        { label: 'WEB', value: 'WEB' },
      ],
      props: {},
      isVisible: true,
    },
    {
      label: 'Status',
      id: 'status',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      dataArr: [
        { label: 'All', value: '' },
        { label: 'Inactive', value: 0 },
        { label: 'Active', value: 1 },
      ],
      isVisible: true,
    },
    {
      label: 'Search',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);
  const handleFilter = (data, allDatavalues, dataWithValue) => {
    let dataNew = {
      end_date: dataWithValue?.end_date ? `${dataWithValue?.end_date} 23:59:59` : undefined,
      search: dataWithValue?.search,
      status: dataWithValue?.status ? dataWithValue?.status : undefined,
      plan_type: dataWithValue?.plan_type ? dataWithValue?.plan_type : undefined,
      start_date: dataWithValue?.start_date ? `${dataWithValue?.start_date} 00:00:00` : undefined,
    }
    let obj = { tab: allParams?.tab, ...dataNew, page: String(1) };
    setAllParams({
      ...obj
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[3].isVisible = true;
      newState[4].isVisible = true;

      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      newState[3].value = '';
      newState[4].value = '';

      return newState;
    });
  };
  const handleAdd = (value = false) => {
    setAddModal(value)
  }
  const handleEdit = (value = false, data = null) => {
    setEditModal(value)
    setEditData(data)
  }
  const handleView = (value = false, data = null) => {
    setViewModal(value)
    setEditData(data)
  }
  const getOrderListing = async (params = {}) => {

    const payload = {
      page: String(1),
      limit: limit,
      pack_type: "1",
      ...allParams,
      ...params
    }
    dispatch(subscriptionListings(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getOrderListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getOrderListing();
  }, [allParams])

  useEffect(() => {
    if (subscriptionStandardList?.items?.plans?.length >= 0) {
      let data = subscriptionStandardList?.items?.plans?.map((item) => {
        return {
          plan_id: item.plan_id,
          plan_name: item.plan_name,
          plan_validity: item.plan_validity,
          created_at: item.created_at,
          plan_price: item.plan_price,
          plan_type: item.plan_type,
          per_image: item?.per_image,
          shot: item.shot,
          sort: item?.sort,
          status: item.status,
          plan_description: item.plan_description,
          maximum_downloads: item.maximum_downloads,
          coupon_amount: item.coupon_amount,
          coupon_name: item.coupon_name,
          discount_percentage: item.discount_percentage,
          pack_type: item?.pack_type
        }
      })
      setOrderSubListing(data)
    }
  }, [subscriptionStandardList.items]);


  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CustomIconButtons className="dark" onClick={(e) => { handleEdit(true, data) }}
            sx={{ padding: 1, marginRight: 0, marginLeft: 0, bgcolor: 'iconButtonPrimary.main' }}>
            <Edit />
          </CustomIconButtons>
          <CustomIconButtons className="dark" onClick={(e) => { handleView(true, data) }}
            sx={{ padding: 1, marginRight: 1, marginLeft: 1, bgcolor: 'iconButtonPrimary.main' }}>
            <VisibilityIcon />
          </CustomIconButtons>
        </div>
      </>
    );
  };


  return (
    <>
      <MatTable
        loading={subscriptionStandardList?.loading}
        showCheckbox={false}
        columns={column}
        data={orderSubListing}
        action={actionBody}
        page={page}
        perPage={limit}
        total={subscriptionStandardList?.items?.totalCount || 0}
        setPage={setPage}
        dateValidation={true}
        disableSearch={true}
        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            extraButton={() => {
              return <>
                <CustomButton onClick={() => { handleAdd(true) }} color={'buttonPrimary'} sx={(theme) => ({
                  padding: '10px 10px', marginRight: 1, marginTop: 1,
                  [theme.breakpoints.down("md")]: {
                    flex: 1,
                  }
                })}>Add Subscription</CustomButton>
              </>
            }}
          />
        )}
      />
      <AddSubscription open={addModal} handleOpen={handleAdd} setPage={setPage} handleLast={getOrderListing} />
      <EditSubscription open={editModal} handleOpen={handleEdit} data={editData} setPage={setPage} handleLast={getOrderListing} />
      <ViewSubscription open={viewModal} setOpen={setViewModal} data={editData} />
    </>
  );
}

export default StandardSubscriptionList;
