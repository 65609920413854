import { Close } from '@material-ui/icons';
import { Box, Typography } from '@mui/material';
import { bgcolor, maxWidth } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { commonItemsSelector } from '../../redux/common';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import Divder from '../CommonComp/Divder';
import PreviewImageComp from './PreviewImageComp';
import PreviewStarImageComp from './PreviewStarImageComponent';

function ImageSelectionComp({ data, handleData, updateData, proposal_of, handleDelete, getImageListing, onConfirm,
  error, helperText, exclusiveCheck, extraSection }) {
  const { appSetting } = useSelector(commonItemsSelector);
  const [showAllDone, setShowAllDone] = useState(false)
  useEffect(() => {
    let updateCheck = data?.length ? data?.filter((item, index) => exclusiveCheck == 2 ? (!item.price && !item.quality && !item.duration) : (!item.price && !item.quality)) : []
    if (!updateCheck.length) {
      setShowAllDone(true)
    } else {
      setShowAllDone(false)
    }
    filterData(data)
  }, [data])
  const filterData = async (data, index) => {
    let arr = data;
    let newArr = arr?.filter((a, index1) => a.content_type == "1");
    return newArr
  }
  return (
    <>
      <Typography variant="h5">Select Images/Videos</Typography>
      <Divder spacing={0.5} />
      <Box sx={{ display: "flex" }}>
        <CustomAsyncSelect
          promiseOptions={getImageListing}
          error={error}
          helperText={helperText}
          closeMenuOnSelect={false}
          controlStyle={{
            padding: 5
          }}
          cacheOptions={false}
          value={data}
          handleChange={(e) => {
            handleData(e);
          }}
          width={'100%'}
        />
        <div style={{ paddingTop: 7, marginLeft: 10 }}>
          <CustomButton color={"buttonPrimary"} sx={{ marginLeft: 2, mt: { md: 0, xs: 0.4 } }} onClick={() => { onConfirm(data) }}>
            Confirm
          </CustomButton>
        </div>
      </Box>
      {exclusiveCheck != 2 && exclusiveCheck != 3 ? <Box
        className="border-1 rounded-3"
        mt={2}
        px={2}
        py={1}
        minHeight={150}
      >
        <Typography variant="h6">Images/Videos Preview</Typography>
        {data?.length ? <Box sx={{ display: "flex", overflow: "hidden", overflowX: "auto" }}>
          {data?.map((item, index) => {
            if (((!item.price || !item.quality) && exclusiveCheck != 2) || (exclusiveCheck == 2 && (!item.price || !item.quality || !item.duration))) {
              return (
                <PreviewImageComp
                  item={item}
                  handleData={handleData}
                  handleDelete={handleDelete}
                  updateData={updateData}
                  index={index}
                  key={index}
                  packDetails={
                    item?.type === 'image' ?
                      appSetting?.items?.subscriptions?.singleImagePrice :
                      item?.type === 'video' ?
                        appSetting?.items?.subscriptions?.singleVideoPrice
                        : [] || []
                  }
                  {...(exclusiveCheck == 2 ? {
                    extraSection: () => extraSection(item, index) || <></>
                  } : {})}
                />
              );
            }
            // else if (exclusiveCheck == 3) {
            //   return (
            //     <PreviewStarImageComp
            //       item={item}
            //       handleData={handleData}
            //       handleDelete={handleDelete}
            //       updateData={updateData}
            //       index={index}
            //       key={index}
            //       packDetails={
            //         item?.type === 'image' ?
            //           appSetting?.items?.subscriptions?.singleImagePrice :
            //           item?.type === 'video' ?
            //             appSetting?.items?.subscriptions?.singleVideoPrice
            //             : [] || []
            //       }
            //       {...(exclusiveCheck == 2 ? {
            //         extraSection: () => extraSection(item, index) || <></>
            //       } : {})}
            //     />
            //   );
            // }
            // }
          })}
          {showAllDone && <Typography variant="p" component={'p'} flex="1" className="fontWeight500" textAlign={"center"} pt={4}>All Images/Videos Are Selected</Typography>}
        </Box> : <Typography variant="p" component={'p'} className="fontWeight500" textAlign={"center"} pt={4}>No Image/Video Available</Typography>}
      </Box> : null}
    </>
  );
}

export default ImageSelectionComp;
