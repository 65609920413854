import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { DATA_LIMIT } from '../../data/constants';
import { editAddCMS } from '../../Services/cmsService';
import { toast } from 'react-toastify';
import { fileUploadImage, fileUploadImages } from '../../Services/commonService';


const limit = DATA_LIMIT;

function EditPopularImage({ data, open, handleOpen, setPage, handleLast, cmsDataHome, editIndex }) {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedImageData, setSelectedImageData] = useState(null)
  const dispatch = useDispatch();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };
  const getImageName = (data) => {
    const file = data;
    const reader = file.split("/");
    //for clouds its reader[4]
    //for aws direct link its reader[5]
    // return reader[4];
    return reader[5];
  };
  const handleUpload = async () => {
    setLoader(true);
    try {
      if (selectedImageData) {
        const formData = new FormData();
        formData.append("uploaded_file", selectedImageData);
        formData.append("folder_name", 'HomeImages');
        let data = await fileUploadImages(formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        if (data?.status == 200) {
          toast.success("Image uploaded successfully!")
        }
      }
    } catch (e) { console.log(e, 'asdf') }
    setLoading(false)
  };
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    if (editIndex >= 0) {
      let dataCopy = { ...cmsDataHome };
      let arr = dataCopy['imageSection']?.content;
      arr[editIndex] = values;
      let newObj = { ...dataCopy, imageSection: { heading: dataCopy?.imageSection?.heading, content: arr } }
      //.log("26",dataCopy,dataCopy['imageSection']?.content[editIndex],values,newObj);
      let finalObj = { pagedata: JSON.stringify(newObj), pagename: 'homePage' }
      let resp = await editAddCMS(finalObj);
      if (resp) {
        resetForm({
          img: '',
          displayKeyword: '',
          searchKeyword: '',
          title: '',
          newImg: ''
        })

        handleLast();
        handleOpen(false)
        setSelectedImageData(null);
        //window.location.reload();
      }

    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      img: data?.img || '',
      displayKeyword: data?.displayKeyword || '',
      searchKeyword: data?.searchKeyword || '',
      title: data?.title || '',
      newImg: ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      img: Yup.string().required('Image is required.'),
      displayKeyword: Yup.string().required("Display Keyword is Required"),
      searchKeyword: Yup.string().required("Search Keyword is Required"),
      title: Yup.string().required("Title Keyword is Required"),
      // newImg:Yup
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Edit Image Info
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="title"
                  label="Title"
                  placeholder="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>


              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="displayKeyword"
                  label="Display Keyword"
                  placeholder="Display Keyword"
                  value={formik.values.displayKeyword}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.displayKeyword && Boolean(formik.errors.displayKeyword)}
                  helperText={formik.touched.displayKeyword && formik.errors.displayKeyword}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="searchKeyword"
                  label="Search Keyword"
                  placeholder="Search Keyword"
                  value={formik.values.searchKeyword}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.searchKeyword && Boolean(formik.errors.searchKeyword)}
                  helperText={formik.touched.searchKeyword && formik.errors.searchKeyword}
                />
              </Grid>

              <Grid item xs={12} >
                <img
                  className="home-image-list-image"
                  src={selectedImageData ? URL.createObjectURL(selectedImageData) : `${formik.values.img}?w=248&fit=crop&auto=format&v=${new Date().getTime()}`}
                  alt="2"
                  // loading="lazy"
                  style={{
                    maxWidth: 200,
                    marginTop: 5,
                    maxHeight: 130,
                    borderRadius: 10,
                    width: 195,
                    height: 130,
                  }}
                />
              </Grid>
              <Grid item xs={9} >

                <CustomButton
                  // color={'buttonPrimary'}
                  variant="contained" component="label"
                // type="submit"
                // loading={loading}
                >
                  Browse
                  <input hidden accept="image/*" type="file" onChange={(e) => {
                    if (e.target.files[0].name === getImageName(formik.values.img))
                      setSelectedImageData(e.target.files[0]);
                    else
                      toast.error(`Please name your image as ${getImageName(formik.values.img)}`)
                  }} />
                </CustomButton>
                <CustomButton
                  color={'buttonPrimary'}
                  variant="contained" component="label"
                  // type="submit"
                  // loading={loading}
                  onClick={handleUpload}
                > Upload </CustomButton>


              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
              <Typography variant="p" sx={{ color: 'red' }}>
                {`Please change the name of the image to be uploaded as:  ${getImageName(formik.values.img)}`}</Typography>
              <div
                style={{ display: 'flex', justifyContent: 'end', }}
              >
                <CustomButton
                  color={'buttonPrimary'}
                  type="submit"
                  loading={loading}
                >
                  Save
                </CustomButton>

                <CustomButton
                  onClick={() => {
                    formik.resetForm({
                      plan_name: "",
                      maximum_downloads: "",
                      plan_validity: "",
                      plan_price: "",
                      plan_type: "",
                      plan_description: "",
                      status: "",
                      coupon_name: "",
                      coupon_amount: "",
                      discount_percentage: "",
                      shot: "",
                      pack_type: ""
                    });
                    handleOpen(false)
                    setSelectedImageData(null)
                  }
                  }
                  disabled={loading}
                >
                  Cancel
                </CustomButton>
              </div>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div >
  );
}

export default EditPopularImage;
