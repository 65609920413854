import { IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { breakStringIntoLines, downloadBlobXLSFile, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { logsItemsSelector } from '../../redux/logs';
import MatTable from '../Tables/MatTable';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { deleteLogs, getLogsListingExportFile, getZeroLogsListing } from '../../Services/logsApis';
import { Delete } from '@material-ui/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { LogsSortData } from '../../data/logsData';

const column = [
  {
    label: 'ID',
    id: 'search_id',
    format: (columnData) => (
      <Typography
        variant='span'
        style={{ whiteSpace: 'nowrap' }}
      >
        {columnData}
      </Typography>
    ),
  },
  {
    label: 'Keywords',
    id: 'keywords',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Search Count',
    id: 'total_count',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? columnData : 'N/A'}
      </span>
    ),
    nextLine: true,
  },
  {
    label: 'Type',
    id: 'type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData === '1' ? 'Image' : 'Video'}
      </span>
    ),
  },
  {
    label: 'Total Record',
    id: 'total_record',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? columnData : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Created At',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd/mm/yyyy')}
      </span>
    ),
  },
];

function ZeroSearchLogs() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, zeroLogs } = useSelector(logsItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [deleteLogsData, setDeleteLogsData] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmAllDelete, setConfirmAllDelete] = useState(false);
  const [expLoading, setExpLoading] = useState(false);
  const [limit, setLimit] = useState(20);

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // order_status: String(1),
      type: "1",
      ...allParams,
      ...params
    }
    dispatch(getZeroLogsListing(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (zeroLogs?.items?.data?.length >= 0) {
      setTableListing(zeroLogs?.items?.data)
    }
  }, [zeroLogs.items]);

  const handleConfirmDelete = async (value) => {
    if (value && deleteLogsData) {
      const resp = await deleteLogs({ log_id: deleteLogsData?.search_id });
      if (resp) {
        getListing({ page: '1' })
        setPage(1)
      }
    } else {
      setDeleteLogsData(null)
    }
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            handleCofirmModalOpen(true)
            setDeleteLogsData(data)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete /></CustomButton>
        </div>
      </>
    );
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  }

  const handleConfirmAllDelete = async (value) => {
    if (value) {
      const resp = await deleteLogs({ flag: 'all', type: '1' });
      if (resp) {
        getListing({ page: '1' })
        setPage(1)
      }
    } else {
      setDeleteLogsData(null)
    }
  }

  const handlePendingOrderDelete = async (data, resetState) => {
    const resp = await deleteLogs({ log_id: data });
    if (resp) {
      getListing({ page: '1' });
      setPage(1);
      resetState([]);
    }
  }


  const getExportFile = async (params = {}) => {
    try {

      let payload = {
        ...allParams,
        ...params,
        type: "1"
      }
      setExpLoading(true)
      let resp = await getLogsListingExportFile(payload)
      if (resp) {
        downloadBlobXLSFile(resp?.data, `ZeroLogs`)
      } else {
        toast.error('Something went wrong.')
      }
      setExpLoading(false)
    } catch (e) {
      console.log(e)
      setExpLoading(false)
    }
  }

  const handleCofirmAllModalOpen = (value) => {
    setConfirmAllDelete(value);
  }

  return (
    <>
      <MatTable
        loading={zeroLogs?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date', 'sort_by'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date', 'sort_by'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20, 50, 100, 200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={zeroLogs?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        dropdownFilter={true}
        selectFilterArray={[
          {
            name: 'sort_by',
            label: 'Sort By',
            placeholder: 'Sort By',
            options: LogsSortData
          },
        ]}
        toolBarButton={() => {
          return (
            <>
              {zeroLogs?.items?.totalCount > 0 &&
                <>
                  <CustomButton
                    onClick={() => {
                      handleCofirmAllModalOpen(true)
                    }}
                    color={'buttonPrimary'}
                    sx={(theme) => ({
                      padding: '10px 10px', marginRight: 1,
                      [theme.breakpoints.down("md")]: {
                        flex: 1,
                      }
                    })}
                  >
                    Bulk Delete
                  </CustomButton>

                  <CustomButton sx={(theme) => ({
                    fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
                    [theme.breakpoints.down("md")]: {
                      flex: 1,
                    }
                  })} onClick={() => {
                    getExportFile()
                  }} loading={expLoading}>Export XLS</CustomButton>
                </>
              }
            </>
          );
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter

        checkboxEnable={true}
        customCheckboxDataKey={'search_id'}
        onTableToolbarRightClick={handlePendingOrderDelete}
        customLabel={'Delete'}
        onTableToolbarRightSection={<IconButton >
          <DeleteIcon />
        </IconButton>}

      />
      {confirmDelete && <ConfirmationModal open={confirmDelete} handleConfirm={handleConfirmDelete} setOpen={handleCofirmModalOpen} />}
      {confirmAllDelete && <ConfirmationModal open={confirmAllDelete} handleConfirm={handleConfirmAllDelete} setOpen={handleCofirmAllModalOpen} />}

    </>
  );
}

export default ZeroSearchLogs;
