import { Delete, Edit } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { currencyConversion, downloadBlobXLSFile, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { userItemsSelector } from '../../redux/user';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import { getImagePricing, getVideoPricing } from '../../Services/cmsService';
import { cmsItemsSelector } from '../../redux/cms';
import EditVideoPrice from '../Modals/EditVideoPrice';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'ID',
    id: 'video_type_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Video Type',
    id: 'video_type',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Resolution',
    id: 'resolution',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Dimensions',
    id: 'Dimensions',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'File Format',
    id: 'file_format',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
 
   {
    label: 'Price(INR)',
    id: 'price',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content"}}>
      {columnData && currencyConversion(columnData) || 'N/A'}
      </span>)
  }
];

function VideoPricing() {
  const [tableListing, setTableListing] = useState([])
  const [expLoading, setExpLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, videoPriceData} = useSelector(cmsItemsSelector);
  const [selectedVideoPrice, setSelectedVideoPrice] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [deleteUserData, setDeleteUserData] = useState({});
  const getListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        // user_type: "2",
        ...allParams,
        ...params
    }
    dispatch(getVideoPricing(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])


  useEffect(() => {
    if(videoPriceData?.items?.video_types?.length >= 0){    
      setTableListing(videoPriceData?.items?.video_types)
    }
  }, [videoPriceData.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{display: "flex"}}>
        <CustomButton className="noMinWidth buttonDense" onClick={()=>{
            setSelectedVideoPrice(data)
            handleEdit(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Edit/></CustomButton>
          </div>
      </>
    );
  };

  const handleEdit = (value=false) =>{
    setEditModal(value);
  }

  return (
    <>
      <MatTable
        loading={videoPriceData?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date', 'identity'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date', 'identity'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={videoPriceData?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
        dropdownFilter={true}
      />
      <EditVideoPrice open={editModal} handleOpen={handleEdit} data={selectedVideoPrice} setPage={setPage} handleLast={getListing}/>

    </>
  );
}

export default VideoPricing;
