import React, { useRef, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { DATA_LIMIT } from '../../data/constants';
import { fileUploadZip } from '../../Services/commonService';

const limit = DATA_LIMIT;

function UploadZipModal({ open, data, handleOpen, handleLast }) {
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState('')
  const [selectedImageData, setSelectedImageData] = useState('')
  const imageInputRef = useRef(null);
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setImageData(event.target.files[0].name);
      setSelectedImageData(event.target.files[0]);
    }
  }

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      if (selectedImageData) {
        setLoading(true)
        const formData = new FormData();
        formData.append("uploaded_file", selectedImageData);
        formData.append("orientation", data?.type)
        formData.append("type", data?.image_type);

        let resp = await fileUploadZip(formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        if (resp?.status == 200) {
          handleLast();
          handleOpen(false);
        }
        setLoading(false);
      }
    }
    catch (e) {
      console.log(e);
      setLoading(false)
    }
  }



  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        fullWidth
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Upload Zip

        </DialogTitle>
        <DialogContent>
          <Box
          >
            <Typography
              variant='span'
              paddingBottom={5}
            >

            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12}>
                <CustomButton variant="contained" component="label" sx={{ padding: 1 }}>
                  {imageData ?
                    imageData
                    : " Click Here "}
                  <input type="file"
                    accept=".zip,.rar,.7zip" ref={imageInputRef} hidden onChange={onImageChange} />
                </CustomButton>

              </Grid>

            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
                onClick={() => { handleSubmit(); }}
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={() => {

                  handleOpen(false);
                  setImageData('');
                  setSelectedImageData('')
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UploadZipModal;
