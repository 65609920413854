export const ExpiryEmailData = {
  ExpiryType: [
    {
      value: 'select',
      label: 'Select',
    },
    {
      value: 'today',
      label: 'Today',
    },
    {
      value: '7 days',
      label: '7 Days',
    },
    {
      value: '15 days',
      label: '15 Days',
    }
  ],
};

