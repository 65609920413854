import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import CreditNote from './CreditNote'
import Invoice from './Invoice'

function CreditNoteContainer() {
  const [navParams, setNavParams] = useState({
      navTitle: "Credit Note",
      navSubTitle: "Welcome to ImagesBazaar"
  })
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
      <CreditNote />
    </MainLayout>
  )
}

export default CreditNoteContainer