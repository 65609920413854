import { Box } from '@mui/material';
import React, { useState } from 'react';
import { getKeywordsList } from '../../Services/keywordsApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import AddImagesFields from './AddImagesFields';
import { ImageSelectData } from '../../data/imageData';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import { isNumeric } from '../../lib/helper';
import AddImagesFieldsBack from './AddImagesFieldsBack';
import AddImagesTable from './AddImagesTable/AddImagesTable';
// import CSVReader from 'react-csv-reader';
import { insertImages } from '../../Services/imageApis';

const column = [
  {
    label: 'S.No.',
    id: 's_no',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return (<span>{rowIndex +1}</span>);
    },
  },
  {
    label: 'Image Id',
    id: 'image_id',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return (<AddImagesFields name="image_id" label="Image Id" error={errors[rowIndex]} 
      helperText={'Please enter ImageId'} placeholder="Image Id" value={columnData} index={rowIndex} handleChange={formatCallback}/>);
    },
  },
  {
    label: 'Rank',
    id: 'rank',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return (<AddImagesFields  name="rank" label="Rank" placeholder="Rank" error={errors[rowIndex]}
      helperText={'Please enter Rank (It should be number only)'} 
      value={columnData} index={rowIndex} handleChange={formatCallback}/>);
    },
  },
  {
    label: 'Rank1',
    id: 'rank1',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return (<AddImagesFields  name="rank1" label="Rank1" placeholder="Rank1" error={errors[rowIndex]}
         helperText={'Please enter Rank1 (It should be number only)'} 
      value={columnData} index={rowIndex} handleChange={formatCallback}/>);
    },
  },
  {
    label: 'Rank5',
    id: 'rank5',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return (<AddImagesFields  name="rank5" label="Rank5" placeholder="Rank5" error={errors[rowIndex]} 
         helperText={'Please enter Rank5 (It should be number only)'} 
      value={columnData} index={rowIndex} handleChange={formatCallback}/>);
    },
  },
  {
    label: 'Group',
    id: 'group',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return (<AddImagesFields  name="group" label="Group" placeholder="Group" error={errors[rowIndex]} 
       helperText={'Please enter Group (It should be number only)'} 
      value={columnData} index={rowIndex} handleChange={formatCallback}/>);
    },
  },
  {
    label: 'Keyword',
    id: 'keyword',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return <CustomAsyncSelect name={'keyword'} helperText={'Please enter keywords'} promiseOptions={options} 
        error={errors[rowIndex]}
        handleChange={(e)=>{
          let data = {
            target:{
              name: 'keyword',
              value: e
            }
          }
          formatCallback(rowIndex, data)
        }}
      />
    },
  },
  {
    label: 'Pricing',
    id: 'pricing',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return (<AddImagesFields  name="pricing" error={errors[rowIndex]} label="Pricing" placeholder="Pricing" value={columnData} 
        helperText={'Please enter Pricing (It should be number only)'} 
      index={rowIndex} handleChange={formatCallback}/>);
    },
  },
  {
    label: 'Exclusive',
    id: 'exclusive_and_non_exclusive',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return (<>
      <CustomCheckbox
        name="exclusive_and_non_exclusive"
        helperText={'Please select Exclusive'} 
        labelPlacement={'end'}
        error={errors[rowIndex]}
        value={columnData == 1? true: false}
        handleChange={(e)=>{ 
          let data = {
            target:{
              name: 'exclusive_and_non_exclusive',
              value:  e.target.checked == true ? 1:0
            }
          }
          formatCallback(rowIndex, data)
        }}
      />
      </>)
    },
  },
  {
    label: 'Agency Name',
    id: 'agency_name',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return (<AddImagesFields  name="agency_name" error={errors[rowIndex]} label="Agency Name" placeholder="Agency Name" value={columnData} 
       helperText={'Please enter Agency Name'} 
      index={rowIndex} handleChange={formatCallback}/>);
    },
  },
  {
    label: 'NOC',
    id: 'noc',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return (<AddImagesFields  name="noc" error={errors[rowIndex]} label="NOC" placeholder="NOC" value={columnData} 
       helperText={'Please enter NOC'} 
      index={rowIndex} handleChange={formatCallback}/>);
    },
  },
  {
    label: 'Type',
    id: 'type',
    format: (columnData, rowIndex, formatCallback, options, errors) => {
      return (<>
      <AddImagesFields name="type" error={errors[rowIndex]} select width={100} label="Type" placeholder="Type" 
        helperText={'Please enter Type'} 
        index={rowIndex} handleChange={formatCallback}
          defaultValue={"I"}
          SelectProps={{
            value: columnData.length ? columnData.split(",") : [],
            name:"type",
            onChange: (e)=>{
              if(e.target.value.length){
                formatCallback(rowIndex, e)
              }
            },
            classes: {
              select: "dense"
            }
          }}
          options={options}
        />
      </>)
    },
  },
];

let emptyData = {
  image_id: "",
  rank: "",
  rank1: "",
  rank5: "",
  group: "",
  keyword: "",
  pricing: "",
  exclusive_and_non_exclusive: 0,
  agency_name: "",
  noc: "",
  type: "I",
};
let errorData = {
  image_id: false,
  rank: false,
  rank1: false,
  rank5: false,
  group: false,
  keyword: false,
  pricing: false,
  exclusive_and_non_exclusive: false,
  agency_name: false,
  noc: false,
  type: false,
};

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header => header?.toLowerCase()?.replace(/\W/g, "_")
};

function AddImages() {
  const [formValues, setFormValues] = useState([{ ...emptyData }]);
  const [errors, setErrors] = useState([{ ...errorData }]);
  
  function handleChange(i, e) {
    let formValuesUpdated = [...formValues];
    formValuesUpdated[i][e.target.name] = e.target.value;
    setFormValues(formValuesUpdated);
    checkValidation(i,e)
  }
  
  function addFormFields() {
    setFormValues([...formValues, { ...emptyData }]);
    setErrors([...errors, {...errorData}])
  }
  
  function removeFormFields(i) {
    let formValuesUpdated = [...formValues];
    let errorsUpdate = [...errors];
    formValuesUpdated.splice(i, 1);
    errorsUpdate.splice(i, 1);
    setFormValues(formValuesUpdated);
    setErrors(errorsUpdate)
  }

  // function removeFormFields(i) {
  //   let formValuesUpdated = [...formValues];
  //   let errorsUpdate = [...errors];
  //   formValuesUpdated.splice(i, 1);
  //   errorsUpdate.splice(i, 1);
  //   setFormValues(formValuesUpdated);
  //   setErrors(errorsUpdate)
  // }
  
  const checkValidation = (i)=>{
    let isValid = true;
    let errorsData = [...errors];
    
    // errorsData[index] = errorData;
    formValues?.map((item, index)=> {
      if(!item?.agency_name){
        errorsData[index]['agency_name'] = true
        isValid = false;
      } else {
        errorsData[index]['agency_name'] = false
      } 
      if(!item?.exclusive_and_non_exclusive && item?.exclusive_and_non_exclusive != 0){
        errorsData[index]['exclusive_and_non_exclusive'] = true
        isValid = false;
      } else {
        errorsData[index]['exclusive_and_non_exclusive'] = false
      } 
      if(!item?.group){
        errorsData[index]['group'] = true
        isValid = false;
      } else {
        // rank, group, pricing, rank5, rank1
        if(isNumeric(item?.group)){
          errorsData[index]['group'] = false
        }else{
          errorsData[index]['group'] = true
          isValid = false;
        }
      } 
      if(!item?.image_id){
        errorsData[index]['image_id'] = true
        isValid = false;
      } else {
        errorsData[index]['image_id'] = false
      } 
      if(item?.keyword?.length == 0){
        errorsData[index]['keyword'] = true
        isValid = false;
      } else {
        errorsData[index]['keyword'] = false
      } 
      if(!item?.noc){
        errorsData[index]['noc'] = true
        isValid = false;
      } else {
        errorsData[index]['noc'] = false
      } 
      if(!item?.pricing){
        errorsData[index]['pricing'] = true
        isValid = false;
      } else {
        // rank, group, pricing, rank5, rank1
        if(isNumeric(item?.pricing)){
          errorsData[index]['pricing'] = false
        }else{
          errorsData[index]['pricing'] = true
          isValid = false;
        }
      } 
      if(!item?.rank){
        errorsData[index]['rank'] = true
        isValid = false;
      } else {
        // rank, group, pricing, rank5, rank1
        if(isNumeric(item?.rank)){
          errorsData[index]['rank'] = false
        }else{
          errorsData[index]['rank'] = true
          isValid = false;
        }
      } 
      if(!item?.rank1){
        errorsData[index]['rank1'] = true
        isValid = false;
      } else {
        // rank, group, pricing, rank5, rank1
        if(isNumeric(item?.rank1)){
          errorsData[index]['rank1'] = false
        }else{
          errorsData[index]['rank1'] = true
          isValid = false;
        }
      } 
      if(!item?.rank5){
        errorsData[index]['rank5'] = true
        isValid = false;
      } else {
        // rank, group, pricing, rank5, rank1
        if(isNumeric(item?.rank5)){
          errorsData[index]['rank5'] = false
        }else{
          errorsData[index]['rank5'] = true
          isValid = false;
        }
      } 
      if(!item?.type){
        errorsData[index]['type'] = true
        isValid = false;
      } else {
        errorsData[index]['type'] = false
      } 
    });
    setErrors(errorsData)

    for (var i = 0; i < errorsData?.length; i++) {
      if (errorsData[i]) {
        let flag = Object?.keys(errorsData[i])?.every((k) => !errorsData[i][k])
        if(!flag){
          isValid = false;
          break;
        }
      }
    }
    return isValid
  }

  function handleSubmit(event) {
    event.preventDefault();
    let validated = checkValidation()
    if(validated && formValues.length){
      let payload = formValues?.map((item)=> {
        return {
          ...item,
          group: Number(item.group),
          pricing: Number(item.pricing),
          rank: Number(item.rank),
          rank1: Number(item.rank1),
          rank5: Number(item.rank5),
          ...(item?.keyword.length ? {keyword: item?.keyword?.map(function(item) {
            return item['value'];
          })?.join(',')}: {})
        }
      });
      console.table(payload);
    }
  }


  const getKeywordsData = async (inputValue) =>{
    if(inputValue && inputValue.length > 2){
      let data = await getKeywordsList({
          keyword: inputValue,
          page: "1",
          limit: '100'
      })   
      return data || []
    }
 }

  const actionBody = (data, index) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            className="buttonDense"
            sx={{
              top: 10,
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
            onClick={() => {
              removeFormFields(index);
            }}
          >
            Remove
          </CustomButton>
        </div>
      </>
    );
  };

  const handleForce = async(data, fileInfo) => {
    // let resp = await insertImages({images: data}); 
  };
  return (
    <Box
      component={'form'}
      onSubmit={handleSubmit}
    >
        <Box sx={{padding: 2, textAlign: 'right'}}>
          {/* <CSVReader
            cssClass="react-csv-input"
            label="Select CSV"
            onFileLoaded={handleForce}
            parserOptions={papaparseOptions}
          /> */}
          <CustomButton
            className="buttonDense"
            sx={{
              fontSize: '12px',
              bgcolor: 'buttonPrimary.main',
              color: 'buttonPrimary.contrastText',
            }}
            onClick={() => addFormFields()}
          >
            Add Row
          </CustomButton>
          <CustomButton
            className="buttonDense"
            sx={{
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
            type="submit"
          >
            Submit
          </CustomButton>
        </Box>
            {/* {formValues?.map((element, index)=>{
              return <AddImagesFieldsBack key={index} element={element} index={index} handleChange={handleChange} removeFormFields={removeFormFields}/>
            })} */}

        <MatTable
          showCheckbox={false}
          columns={column}
          // data={[]}
          data={formValues}
          formatCallback={handleChange}
          action={actionBody}
          columnWiseData={[
            {
              id: 'type',
              value: ImageSelectData.imageType
            },
            {
              id: "keyword",
              value: getKeywordsData
            }
          ]}
          // errors={[]}
          errors={errors}
          tableMaxHeight="600px"
          tdStyle={{
            verticalAlign: "baseline"
          }}
        />

        {/* <AddImagesTable data={formValues} handleChange={handleChange} removeFormFields={removeFormFields}
          errors={errors} options={ImageSelectData.imageType} getKeywordsData={getKeywordsData}
         /> */}

         <Box sx={{padding: 2, pt: 0, textAlign: 'right'}}>
          <CustomButton
            className="buttonDense"
            sx={{
              fontSize: '12px',
              bgcolor: 'buttonPrimary.main',
              color: 'buttonPrimary.contrastText',
            }}
            onClick={() => addFormFields()}
          >
            Add Row
          </CustomButton>
          <CustomButton
            className="buttonDense"
            sx={{
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
            type="submit"
          >
            Submit
          </CustomButton>
        </Box>
    </Box>
  );
}

export default AddImages;
