import React, { useState } from 'react'
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton'
import MainLayout from '../Layouts/MainLayout'
import AddImage from './AddImage'
import AddKeywords from './AddKeywords'
import { imageListings, imageListingsNew } from '../../Services/imageApis'
import { DATA_LIMIT, NEW_DATA_LIMIT } from '../../data/constants'
import { useDispatch } from 'react-redux'

function AddImageContainer() {
  const [navParams, setNavParams] = useState({
      navTitle: "Add Images",
      navSubTitle: "Welcome to ImagesBazaar"
  })
  const limit = NEW_DATA_LIMIT;

  const [uploadCsvModal, setUploadCsvModal] = useState(false);
  const [uploadKeywords,setUploadKeywords]=useState(false);
  const dispatch = useDispatch();

  const handleNavParams = (obj)=>{
    setNavParams({
        ...navParams,
        ...obj
    })
  }
  const handleCsvModal = (value)=>{
    setUploadCsvModal(value || false);
    if(!value){ getListing();}
  }

  const handleImageCsvModal = (value)=>{
    setUploadKeywords(value || false)
  }

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: 500,
      insertion_status:'new',
    };
    dispatch(imageListingsNew(payload));
  };
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}
      navBarRightComp={<>
       <CustomButton sx={{ padding: '12px 20px',whiteSpace:'nowrap', ml: (theme)=>(theme.spacing(2)) }} onClick={()=>{handleImageCsvModal(true)}} color={'buttonPrimary'} className="textDecoration-none">
              Upload Repair Keywords CSV
          </CustomButton>
          <CustomButton sx={{ padding: '12px 20px',whiteSpace:'nowrap', ml: (theme)=>(theme.spacing(2)) }} onClick={()=>{handleCsvModal(true)}} color={'buttonPrimary'} className="textDecoration-none">
              Upload Image CSV
          </CustomButton>
      </>}
    >
        <AddImage handleNavParams={handleNavParams} uploadCsvModal={uploadCsvModal} handleCsvModal={handleCsvModal} />
        <AddKeywords handleNavParams={handleNavParams} uploadKeywords={uploadKeywords} handleImageCsvModal={handleImageCsvModal} />
    </MainLayout>
  )
}

export default AddImageContainer