import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ApiConfig from '../../config/ApiConfig';
import { checkPaymentOnline, formatDate, getDataFromAppSetting, validDate } from '../../lib/helper';
import CustomLoading from '../CommonComp/CustomLoading/CustomLoading';
import { AppLogoNew } from '../../Asset/svg';
import QRCode from 'react-qr-code';
import { commonItemsSelector } from '../../redux/common';
import { useSelector } from 'react-redux';

const RenderPaymentInfo = ({ data }) => {
  if (!data) { return <> <span className="bold-font">Payment Status: </span>N/A</> }

  if (data) {
    if (data?.order) {
      if (data?.order?.order_status == '1') {
        if (data?.order?.payment_status == '0') {
          if (!checkPaymentOnline(data?.order)) {
            return <><span className="bold-font">Payment Due Date: </span> {formatDate(data?.order?.payment_on, 'dd/mm/yyyy')}</>
          } else {
            return <><span className="bold-font">Payment Status: </span> Unpaid</>
          }
        }
        else if (data?.order?.payment_status == '1') {
          return <><span className="bold-font">Payment Status: </span>Paid</>
        } else {
          return <><span className="bold-font">Payment Status: </span>N/A</>
        }
      }
      if (data?.order?.order_status == '2') {
        if (data?.order?.payment_status == '0') {
          if (!checkPaymentOnline(data?.order)) {
            return <><span className="bold-font">Payment Due Date: </span> {formatDate(data?.order?.payment_on, 'dd/mm/yyyy')}</>
          } else {
            return <><span className="bold-font">Payment Status: </span> Unpaid</>
          }
        }
        else if (data?.order?.payment_status == '1') {
          return <><span className="bold-font">Payment Status: </span>Paid</>
        } else {
          return <><span className="bold-font">Payment Status: </span>N/A</>
        }
      }
    }
  }
}

const RenderTax = ({ data }) => {

  if (validDate(data?.invoice_date, '07/01/2017')) {
    return <>
      {data?.state?.state_short_name?.toLowerCase() === "dl" ? (
        <>
          <div className="width-100 display-flex">
            <div
              className="text-right  padding-6 bold-font"
              style={{
                borderTop: "1px solid black",
                width:
                  data?.order?.order_type?.toLowerCase() === "nplan"
                    ? "85%"
                    : data?.order?.order_type === "IV"
                      ? "80%"
                      : "80%",
              }}
            >
              SGST Value @9% (INR)
            </div>
            <div
              className="text-center padding-6 bold-font"
              style={{
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
                width:
                  data?.order?.order_type?.toLowerCase() === "nplan"
                    ? "15%"
                    : data?.order?.order_type === "IV"
                      ? "20%"
                      : "20%",
              }}
            >
              {(Number(data?.tax) / 2)?.toFixed(2)}
            </div>
          </div>
          <div className="width-100 display-flex">
            <div
              className="text-right  padding-6 bold-font"
              style={{
                borderTop: "1px solid black",
                width:
                  data?.order?.order_type?.toLowerCase() === "nplan"
                    ? "85%"
                    : data?.order?.order_type === "IV"
                      ? "80%"
                      : "80%",
              }}
            >
              CGST Value @9% (INR)
            </div>
            <div
              className="text-center padding-6 bold-font"
              style={{
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
                width:
                  data?.order?.order_type?.toLowerCase() === "nplan"
                    ? "15%"
                    : data?.order?.order_type === "IV"
                      ? "20%"
                      : "20%",
              }}
            >
              {(Number(data?.tax) / 2)?.toFixed(2)}
            </div>
          </div>
        </>
      ) : (
        <div className="width-100 display-flex">
          <div
            className="text-right  padding-6 bold-font"
            style={{
              borderTop: "1px solid black",
              width:
                data?.order?.order_type?.toLowerCase() === "nplan"
                  ? "85%"
                  : data?.order?.order_type === "IV"
                    ? "80%"
                    : "80%",
            }}
          >
            IGST Value @18% (INR)
          </div>
          <div
            className="text-center padding-6 bold-font"
            style={{
              borderTop: "1px solid black",
              borderLeft: "1px solid black",
              width: data?.order?.order_type?.toLowerCase() === "nplan" ? "15%" : data?.order?.order_type === "IV" ? "20%" : "20%",
            }}
          >
            {(Number(data?.tax))?.toFixed(2)}
          </div>
        </div>
      )}
    </>
  } else {
    let taxPercent = ''
    if (validDate(data?.invoice_date, '02/01/2006') && validDate('02/25/2009', data?.invoice_date)) {
      taxPercent = '12.36'
    }
    if (validDate(data?.invoice_date, '02/26/2009') && validDate('03/31/2012', data?.invoice_date)) {
      taxPercent = '10.30'
    }
    if (validDate(data?.invoice_date, '04/01/2012') && validDate('05/30/2015', data?.invoice_date)) {
      taxPercent = '12.36'
    }
    if (validDate(data?.invoice_date, '06/01/2015') && validDate('11/14/2015', data?.invoice_date)) {
      taxPercent = '14'
    }
    if (validDate(data?.invoice_date, '11/15/2015') && validDate('05/30/2016', data?.invoice_date)) {
      taxPercent = '14.50'
    }
    if (validDate(data?.invoice_date, '06/01/2016') && validDate('06/30/2017', data?.invoice_date)) {
      taxPercent = '15'
    }
    if (taxPercent && data?.tax) {

      return <>
        <div className="width-100 display-flex">
          <div className="text-right  padding-6 bold-font" style={{ borderTop: "1px solid black", width: data?.order?.order_type?.toLowerCase() === "nplan" ? "85%" : "80%" }}>
            Service Tax @{taxPercent}% (INR)
          </div>
          <div className="text-center padding-6 bold-font"
            style={{ borderTop: "1px solid black", borderLeft: "1px solid black", width: data?.order?.order_type?.toLowerCase() === "nplan" ? "15%" : "20%" }}>
            {data?.tax?.toFixed(2)}
          </div>
        </div>
      </>
    }
  }
}

const InvoiceItems = ({ orderDetails, invoiceDetails, data }) => {

  return <div className="width-100">
    <table className="width-100 border-collapse">
      <thead>
        <tr style={{ backgroundColor: "#EEEEEE" }}>
          {data?.order?.order_type?.toLowerCase() === "nplan" && (
            <>
              <th
                className="padding-6"
                style={{
                  borderTop: "1px solid black",
                  width: "15%",
                }}
              ></th>
              <th
                className="border-1px padding-6"
                style={{ width: "10%" }}
              >
                Item ID
              </th>
              <th
                className="border-1px padding-6"
                style={{ width: "10%" }}
              >
                Type
              </th>
              <th
                className="border-1px padding-6"
                style={{ width: "25%" }}
              >
                Dimensions (Pixels)
              </th>
              <th
                className="border-1px padding-6"
                style={{ width: "25%" }}
              >
                Rights
              </th>
              <th
                className="padding-6"
                style={{
                  borderTop: "1px solid black",
                  width: "15%",
                }}
              >
                Value
              </th>
            </>
          )}
          {data?.order?.order_type === "IV" && (
            <>
              <th
                className="padding-6"
                style={{
                  borderTop: "1px solid black",
                  width: "20%",
                }}
              ></th>
              <th
                className="border-1px padding-6"
                style={{ width: "15%" }}
              >
                Plan Name
              </th>
              <th
                className="border-1px padding-6"
                style={{ width: "10%" }}
              >
                No. of I/V
              </th>
              <th
                className="border-1px padding-6"
                style={{ width: "15%" }}
              >
                Validity of Packages
              </th>
              <th
                className="padding-6"
                style={{
                  borderTop: "1px solid black",
                  width: "40%",
                }}
              >
                Packages Value
              </th>
            </>
          )}
          {data?.order?.order_type === "Plan" && (
            <>
              <th
                className="padding-6"
                style={{
                  borderTop: "1px solid black",
                  width: "20%",
                }}
              ></th>
              <th
                className="border-1px padding-6"
                style={{ width: "15%" }}
              >
                Plan Name
              </th>
              <th
                className="border-1px padding-6"
                style={{ width: "10%" }}
              >
                No. of I/V
              </th>
              <th
                className="border-1px padding-6"
                style={{ width: "15%" }}
              >
                Validity of Packages
              </th>
              <th
                className="padding-6"
                style={{
                  borderTop: "1px solid black",
                  width: "40%",
                }}
              >
                Packages Value
              </th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {data?.order?.order_type?.toLowerCase() === "nplan" &&
          invoiceDetails?.map(
            (item, index) => {
              return (
                <tr key={index}>
                  <td
                    className="text-center padding-6"
                    style={{
                      borderTop: "1px solid black",
                      width: "15%",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={`${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.image_name}.jpg`}
                      alt="image"
                      width="80%"
                      height="auto"
                    />
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      width: "10%",
                      borderLeft: "1px solid black",
                      borderTop: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    {item?.image_name || "N/A"}
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      width: "10%",
                      borderRight: "1px solid black",
                      borderTop: "1px solid black",
                    }}
                  >
                    {item?.is_star === "1"
                      ? `${item?.quality}`
                      : item?.image_type_up ? `${item?.image_type_up} to ${item?.image_type}` : item?.image_type || "N/A"}
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      width: "25%",
                      borderTop: "1px solid black",

                      borderRight: "1px solid black",
                    }}
                  >
                    {item?.dimension || "N/A"}
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      width: "25%",
                      borderRight: "1px solid black",
                      borderTop: "1px solid black",
                      wordBreak: 'break-all'
                    }}
                  >
                    {item?.is_star === "1" ? (
                      <>
                        <div>{item?.right || "Non-Exclusive"}</div>
                        {/* <div style={{ marginTop: "5px" }}>
                          License: {item?.license}
                        </div> */}
                        <div style={{ marginTop: "5px" }}>
                          Industry: {item?.industry}
                        </div>
                        <div style={{ marginTop: "5px" }}>
                          Territory:
                          {/* {String(item?.state).length ? item?.state: item?.country } */}
                          {item?.state ? item?.state : item?.country}
                        </div>
                        <div style={{ marginTop: "5px" }}>
                          Duration: {item?.duration_for_star}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>{item?.right || "Non-Exclusive"}</div>
                        {item?.duration ? (
                          <div>{item?.duration}</div>
                        ) : null}
                      </>
                    )}
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      borderTop: "1px solid black",
                      width: "15%",
                    }}
                  >
                    {item?.initial_price || "N/A"}
                  </td>
                </tr>
              );
            }
          )}
        {data?.order?.order_type === "IV" &&
          invoiceDetails?.map(
            (item, index) => {
              return (
                <tr key={index}>
                  <td
                    className="text-center padding-6"
                    style={{
                      borderTop: "1px solid black",
                      width: "20%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          // backgroundColor: `${item?.invoice_details ? item?.invoice_details[0]?.quality === "WEB" ? "#ba36c1" : "green" : "black"}`,
                          ...(item?.quality?.toLowerCase()?.includes('large') ?
                            { backgroundColor: "green" } :
                            item?.quality?.toLowerCase()?.includes('web') ?
                              { backgroundColor: '#ba36c1' } :
                              item?.quality?.toLowerCase()?.includes('small') ?
                                { backgroundColor: 'rgb(58 152 235)' } :
                                { backgroundColor: '#6536c1' }),
                          color: "white",
                          borderRadius: "10px",
                        }}
                      >
                        {item?.quality}
                        {/* {item?.invoice_details ? item?.invoice_details[0]?.quality === "WEB" ? "Web Size Pack" : "Large Size Pack" : "N/A"} */}
                      </div>
                    </div>
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      width: "15%",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    {item?.quality}-{item?.image_name}
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      width: "10%",
                      borderRight: "1px solid black",
                    }}
                  >
                    {item?.image_name}
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      width: "15%",
                      borderRight: "1px solid black",
                    }}
                  >
                    {item?.image_type_up || item?.image_type} days
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      borderTop: "1px solid black",
                      width: "40%",
                    }}
                  >
                    {item?.initial_price}
                  </td>
                </tr>
              );
            }
          )}
        {data?.order?.order_type === "Plan" &&
          invoiceDetails?.map(
            (item, index) => {
              return (
                <tr key={index}>
                  <td
                    className="text-center padding-6"
                    style={{
                      borderTop: "1px solid black",
                      width: "20%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          // backgroundColor: `${item?.invoice_details ? item?.invoice_details[0]?.quality === "WEB" ? "#ba36c1" : "green" : "black"}`,
                          ...(item?.quality?.toLowerCase()?.includes('large') ?
                            { backgroundColor: "green" } :
                            item?.quality?.toLowerCase()?.includes('web') ?
                              { backgroundColor: '#ba36c1' } :
                              item?.quality?.toLowerCase()?.includes('small') ?
                                { backgroundColor: 'rgb(58 152 235)' } :
                                { backgroundColor: '#6536c1' }),
                          color: "white",
                          borderRadius: "10px",
                        }}
                      >
                        {item?.quality}
                        {/* {item?.invoice_details ? item?.invoice_details[0]?.quality === "WEB" ? "Web Size Pack" : "Large Size Pack" : "N/A"} */}
                      </div>
                    </div>
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      width: "15%",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                    }}
                  >
                    {item?.quality}-{item?.image_name}
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      width: "10%",
                      borderRight: "1px solid black",
                    }}
                  >
                    {item?.image_name}
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      width: "15%",
                      borderRight: "1px solid black",
                    }}
                  >
                    {item?.image_type_up || item?.image_type} days
                  </td>
                  <td
                    className="text-center padding-6"
                    style={{
                      borderTop: "1px solid black",
                      width: "40%",
                    }}
                  >
                    {item?.initial_price}
                  </td>
                </tr>
              );
            }
          )}
      </tbody>
    </table>
  </div>
}

export const InvoiceTable = React.forwardRef(({ data, staticData, loading }, ref) => {

  const [invoiceDetailsData, setInvoiceDetailsData] = useState([]);
  const { appSetting } = useSelector(commonItemsSelector);



  const appSettingDurationMap = () => {
    if (appSetting && appSetting?.items) {
      const durationPriceDataFromAppSetting = getDataFromAppSetting(
        appSetting?.items?.features?.stars,
        "key_name",
        "duration"
      );
      const durationData = JSON.parse(
        durationPriceDataFromAppSetting[0]?.key_value || "{}"
      );
      const outputArray = [];
      for (const label in durationData) {
        const value = durationData[label];
        outputArray.push({ label, value });
      }
      return outputArray
    }
  }

  useEffect(() => {
    if (data) {
      if (data?.invoice_details?.length) {
        let newArr = data?.invoice_details?.map((a) => {
          let item = data?.order_details?.find((o) => o?.order_id
            === a?.order_id
          );
          if (item) {
            let newObj = { ...a };
            newObj['image_type_up'] = item?.image_type_up;
            let newApp = appSettingDurationMap();
            if (a?.is_star == 1) {
              if (newApp?.length) {
                let duration_for_star = newApp?.find((dur) => {
                  return dur?.value == a?.duration_for_star || dur?.label == a?.duration_for_star
                })
                if (duration_for_star) {
                  newObj['duration_for_star'] = duration_for_star?.label;
                }
              }
              if (a?.state) {
                let stateList = a?.state?.split(",");
                if (stateList?.length > 5)
                  newObj['state'] = 'Pan India'
              }
            }
            return newObj;
          }




          return a;
        });
        //  setInvoiceDetailsData(data?.invoice_details)
        setInvoiceDetailsData(newArr)
      } else {
        const invoiceData = data?.order_details?.map((item) => {
          return {
            dimension: item?.dimension,
            discounted_price: item?.discounted_price,
            final_price: item?.price,
            image_name: item?.image_name,
            image_type: item?.image_type,
            image_type_up: item?.image_type_up,
            initial_price: item?.price,
            quality: item?.quality,
            rank: item?.rank,
            shootid: item?.shootid
          }
        })
        setInvoiceDetailsData(invoiceData)
      }
    }
  }, [data])

  if (!data) {
    return <CustomLoading loading={loading} />;
  }

  return (
    <>
      <Box component={"div"} ref={ref} sx={{ width: "930px", marginBottom: "15px", marginLeft: "auto", marginRight: "auto" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10, marginTop: 10 }}>
          <div style={{ display: 'flex', alignItems: "center" }}>
            {/* <img src={MainLogo} alt="main-logo" style={{maxWidth: 180}} /> */}
            <AppLogoNew style={{
              maxWidth: 180,
              maxHeight: 40,
              width: '100%'
            }} />
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", fontSize: 28 }}>
            <Typography color="inherit" component="div" sx={{ textTransform: "uppercase" }} variant="mainpagetitle" className="boldFont">tax invoice</Typography>
          </div>
          <div style={{ width: 178 }}></div>
        </div>
        <div className="outer-section-invoice">
          <div className="main-section-invoice font-13">
            <div className="display-flex width-100">
              <div className="address-div padding-6">
                <div className="bold-font">
                  {staticData.company_name}
                </div>
                <div style={{ marginTop: "5px" }}>
                  {staticData.company_address}
                </div>
                <div style={{ marginTop: "5px" }}>
                  <span className="bold-font">Phone: </span>
                  {staticData.company_phone}
                </div>
                <div style={{ marginTop: "5px" }}>
                  <span className="bold-font">Fax: </span>
                  {staticData.company_fax}
                </div>
                <div style={{ marginTop: "5px" }}>
                  <span className="bold-font">CIN: </span>
                  {staticData.company_cin}
                </div>
                <div style={{ marginTop: "5px" }}>
                  <span className="bold-font">GSTIN: </span>
                  {staticData.company_gstin}
                </div>
                <div style={{ marginTop: "5px" }}>
                  <span className="bold-font">PAN: </span>
                  {staticData.pan}
                </div>
              </div>
              <div className="date-div">
                <div className="display-flex">
                  <div className="float-left-50 padding-6">
                    <span className="bold-font">Date: </span>
                    {formatDate(data?.invoice_date, 'dd-mm-yyyy hh:mm 12h')}
                  </div>
                  <div className="float-right-50 padding-6">
                    <span className="bold-font">Invoice No.: </span>
                    {data?.invoice_id}
                  </div>
                </div>
                <div className="display-flex">
                  <div className="float-left-50 padding-6 bold-font">
                    <span className="bold-font">
                      Order Confirmation No.:{" "}
                    </span>
                    {data?.order_id}
                  </div>
                  <div className="float-right-50 padding-6">
                    <span className="bold-font">HSN/SAC: </span>
                    {staticData.hsn_sac}
                  </div>
                </div>
                <div className="display-flex">
                  <div className="float-left-50 padding-6">
                    <span className="bold-font">Mode of Payment: </span>
                    {data?.order ? data?.order?.payment_type : 'N/A'}
                  </div>
                  <div className="float-right-50 padding-6">
                    <span className="bold-font">State Code: </span>
                    07 / DL
                  </div>
                </div>
                <div className="display-flex">
                  <div className="float-left-50 padding-6">
                    <RenderPaymentInfo data={data} />
                  </div>
                  <div className="float-right-50 padding-6">
                    <span className="bold-font">State: </span>
                    Delhi
                  </div>
                </div>
                <div className="full-100 padding-6">
                  <div>
                    <span className="bold-font">
                      Reverse Charges Applicability:
                    </span>{" "}
                    Not Applicable
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <span className="bold-font">Place of Supply: </span>
                    {data?.state?.state_name || data?.country?.country_name || "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="display-flex width-100">
              <div
                className="party-name padding-6"
                style={{
                  borderBottom: !!data?.invoice_irn ? "0px !important" : "1px",
                }}
              >
                <div className="bold-font">Party's Name:</div>
                <div style={{ marginTop: "5px" }}>
                  {
                    data?.address?.includes('M$M') ? <>
                      <span id="lblbilledto" >{data?.address?.split('M$M')[0] || data?.company_name}</span> <br />
                      <span id="lblbilledto" >{data?.address?.split('M$M')[1]}</span> <br />
                    </> : <>
                      <span id="lblbilledto" >{data?.company_name}</span> <br />
                      <span id="lblbilledto" >{data?.address}</span> <br />
                    </>
                  }
                  <div>{data?.country?.country_name}</div>
                </div>
                {data?.zip_code ? <div style={{
                  marginTop: "5px"
                }}>
                  <span className="bold-font">Pin Code: </span>
                  {data?.zip_code || "N/A"}
                </div> : null}
                <div style={{
                  marginTop: "5px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                >
                  {data?.state && data?.state?.state_name ? <div>
                    <span className="bold-font">State: </span>
                    {data?.state?.state_name || "N/A"}
                  </div> : null} &nbsp; &nbsp;&nbsp;
                  {data?.state && data?.state?.state_code ? <div>
                    <span className="bold-font">State Code: </span>
                    {(String(data?.state?.state_code)?.length < 2 && "0") + data?.state?.state_code + "/" + data?.state?.state_short_name}
                  </div> : ""}
                </div>

                {!!data?.pan_number && (
                  <div style={{ marginTop: "5px" }}>
                    <span className="bold-font">PAN: </span>
                    {data?.pan_number ||
                      "N/A"}
                  </div>
                )}
                {!!data?.gst_number && (
                  <div style={{ marginTop: "5px" }}>
                    <span className="bold-font">{data?.isd_number ? 'ISD:' : "GSTIN:"} </span>
                    {data?.gst_number ||
                      "N/A"}
                  </div>
                )}
              </div>
              <div
                className="client-name padding-6"
                style={{
                  borderBottom: !!data?.invoice_irn ? "0px !important" : "1px",
                }}
              >
                {data?.client ? <div className='bread-words'>
                  <span className="bold-font">Client Name : </span>
                  {data?.client}
                </div> : null}
                {data?.order?.order_status >= '1' ? <div style={{ marginTop: data?.client ? "5px" : '0px' }} className='bread-words'>
                  <span className="bold-font">Order By : </span>
                  {data?.ordered_by || data?.order?.ordered_by}
                </div> : data?.order?.order_status == '0' ? <div style={{ marginTop: data?.client ? "5px" : '0px' }} className='bread-words'>
                  <span className="bold-font">Order By : </span>
                  {data?.order?.ordered_by}
                </div> : null}
                {data?.order?.purchase_order ? <div style={{ marginTop: data?.client || data?.ordered_by || data?.order?.ordered_by ? "5px" : '0px' }} className='bread-words'>
                  <span className="bold-font">P.O No : </span>
                  {data?.order?.purchase_order}
                </div> : null}
              </div>
              <div
                className="qr-code padding-6"
                style={{
                  borderBottom: data?.invoice_irn ? "0px !important" : "1px",
                  textAlign: "center",
                }}
              >
                {!!data?.invoice_irn && (
                  <>
                    {data?.invoice_irn?.qrcode?.slice(0, 2) == 'ey' ? <QRCode
                      size={220}
                      style={{ height: "auto", maxWidth: "100%", margin: '8px 0' }}
                      value={data?.invoice_irn?.qrcode}
                      viewBox={`0 0 220 220`}
                    /> : <img
                      src={`data:image/png;base64, ${data?.invoice_irn?.qrcode}`}
                      alt="QR CODE"
                      style={{ maxWidth: '100%', height: 'auto' }}
                    />}
                  </>
                )}
              </div>
            </div>
            {!!data?.invoice_irn && (
              <div className="width-100">
                <div className="width-100 padding-6">
                  <div>
                    <span className="bold-font">IRN : </span>
                    {data?.invoice_irn?.irn}
                  </div>
                </div>
              </div>
            )}
            <InvoiceItems invoiceDetails={invoiceDetailsData} data={data} />
            <div className="width-100 display-flex">
              <div
                className="text-right padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  width:
                    data?.order?.order_type?.toLowerCase() === "nplan"
                      ? "85%"
                      : data?.order?.order_type === "IV"
                        ? "80%"
                        : "80%",
                }}
              >
                Total Value (INR)
              </div>
              <div
                className="text-center padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  borderLeft: "1px solid black",
                  width:
                    data?.order?.order_type?.toLowerCase() === "nplan"
                      ? "15%"
                      : data?.order?.order_type === "IV"
                        ? "20%"
                        : "20%",
                }}
              >
                {data?.initial_price?.toFixed(2)}
              </div>
            </div>
            {data?.discount && !data?.order?.previous_order_id ? (
              <div className="width-100 display-flex">
                <div
                  className="text-right  padding-6 bold-font"
                  style={{
                    borderTop: "1px solid black",
                    width:
                      data?.order?.order_type?.toLowerCase() === "nplan"
                        ? "85%"
                        : data?.order?.order_type === "IV"
                          ? "80%"
                          : "80%",
                  }}
                >
                  Discount (INR)
                </div>
                <div
                  className="text-center padding-6 bold-font"
                  style={{
                    borderTop: "1px solid black",
                    borderLeft: "1px solid black",
                    width:
                      data?.order?.order_type?.toLowerCase() === "nplan"
                        ? "15%"
                        : data?.order?.order_type === "IV"
                          ? "20%"
                          : "20%",
                  }}
                >
                  {data?.discount}
                </div>
              </div>
            ) : null}
            {data?.discount && data?.order?.previous_order_id ? (
              <div className="width-100 display-flex">
                <div
                  className="text-right  padding-6 bold-font"
                  style={{
                    borderTop: "1px solid black",
                    width:
                      data?.order?.order_type?.toLowerCase() === "nplan"
                        ? "85%"
                        : data?.order?.order_type === "IV"
                          ? "80%"
                          : "80%",
                  }}
                >
                  Coupon ({data?.plan_info?.coupon_name})
                </div>
                <div
                  className="text-center padding-6 bold-font"
                  style={{
                    borderTop: "1px solid black",
                    borderLeft: "1px solid black",
                    width:
                      data?.order?.order_type?.toLowerCase() === "nplan"
                        ? "15%"
                        : data?.order?.order_type === "IV"
                          ? "20%"
                          : "20%",
                  }}
                >
                  {data?.discount}
                </div>
              </div>
            ) : null}
            <RenderTax data={data} />
            <div
              className="width-100 display-flex"
              style={{ backgroundColor: "#EEEEEE" }}
            >
              <div
                className="text-left padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  width: data?.order?.order_type?.toLowerCase() === "nplan" ? "85%" : data?.order?.order_type === "IV" ? "80%" : "80%",
                }}
              >
                Total Amount before GST (SGST / UGST / CGST / IGST)
              </div>
              <div
                className="text-center padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  width: data?.order?.order_type?.toLowerCase() === "nplan" ? "15%" : data?.order?.order_type === "IV" ? "20%" : "20%",
                }}
              >
                {(Number(data?.initial_price) - Number(data?.discount))?.toFixed(2)}
              </div>
            </div>
            <div
              className="width-100 display-flex"
              style={{ backgroundColor: "#EEEEEE" }}
            >
              <div
                className="text-left padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  width:
                    data?.order?.order_type?.toLowerCase() === "nplan"
                      ? "85%"
                      : data?.order?.order_type === "IV"
                        ? "80%"
                        : "80%",
                }}
              >
                Total Amount Payable inclusive of GST (SGST / UGST / CGST / IGST)
              </div>
              <div
                className="text-center padding-6 bold-font"
                style={{
                  borderTop: "1px solid black",
                  width: data?.order?.order_type?.toLowerCase() === "nplan" ? "15%" : data?.order?.order_type === "IV" ? "20%" : "20%",
                }}
              >
                {data?.final_price?.toFixed(2)}
              </div>
            </div>
            <div
              className="width-100 display-flex"
              style={{
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
              }}
            >
              <div className="padding-6">
                ImagesBazaar is a unit of Mash Audio Visuals Pvt. Ltd. Usage
                of content subject to Mash Rights Agreement mentioned on{" "}
                <a href={`${ApiConfig.LIVE_WEBSITE_URL}/licensing`} target="_blank">
                  {ApiConfig.LIVE_WEBSITE_URL}/licensing
                </a>
              </div>
            </div>
            <div
              className="width-100 display-flex"
              style={{ height: "200px" }}
              id="declaration"
            >
              <div className="padding-6" style={{ width: "70%", borderRight: "1px solid black" }}>
                {!checkPaymentOnline(data?.order) && data?.country?.country_name == 'India' ? (
                  <>
                    <h3 style={{ fontSize: "14px" }}>
                      Terms & Conditions:
                    </h3>
                    <div>
                      <ol style={{
                        margin: "2px 25px",
                        padding: "0px"
                      }}>
                        <li>
                          Payment must reach us before due date. Interest
                          @24% p.a. will be levied on all delayed payments.
                        </li>
                        <li>
                          Payment to be made in favour of Mash Audio Visuals
                          Pvt. Ltd. payable at Delhi and send to our address
                          mentioned above.
                        </li>
                        <li>
                          Kindly mention on the reverse of the cheque the
                          order Confirmation No. and Invoice No. against
                          which the amount is paid.
                        </li>
                      </ol>
                    </div>
                  </>
                ) : null}
                <div>
                  If you have any problem with your order, please call us at{" "}
                  <a href="tel:+919911366666" className="font-red">
                    +91-9911366666
                  </a>{" "}
                  or{" "}
                  <a href="tel:+911166545466" className="font-red">
                    +91-1166545466
                  </a>{" "}
                  or send us a message at{" "}
                  <a
                    href="mailto:orders@imagesbazaar.com"
                    className="font-red"
                  >
                    orders@imagesbazaar.com
                  </a>
                </div>
              </div>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                className="padding-6"
              >
                <div className="bold-font">
                  For Mash Audio Visuals Pvt. Ltd.
                </div>
                <div className="bold-font">Authorised Signatory</div>
              </div>
            </div>
          </div>
          <div
            className="width-100 text-center bold-font"
            style={{ fontSize: "18px", marginTop: "10px" }}
          >
            WE THANK YOU FOR YOUR BUSINESS. WE VALUE YOUR PATRONAGE
          </div>
        </div>
        <style>
          {`
                      .main-section-invoice {
                      width: 100%;
                      max-width: 930px;
                      margin-left: auto;
                      margin-right: auto;
                      border: 1px solid black;
                      }
                      .bread-words{
                        word-break: break-word;
                      }
                      .address-div {
                      width: 40%;
                      border-left: 0px;
                      border-top: 0px;
                      border-right: 1px;
                      border-bottom: 0px;
                      border-style: solid;
                      border-color: black;
                      }
                      .font-red {
                      color: red;
                      }
                      .padding-6 {
                      padding: 6px;
                      }
                      .width-100 {
                      width: 100%;
                      }
                      .border-1px {
                      border: 1px solid black;
                      }
                      .font-13 {
                      font-size: 13px;
                      }
                      .border-collapse {
                      border-collapse: collapse;
                      }
                      .date-div {
                      width: 60%;
                      }
                      .text-center {
                      text-align: center;
                      }
                      .text-left {
                      text-align: left;
                      }
                      .bold-font {
                      font-weight: bold;
                      }
                      .text-right {
                      text-align: right;
                      }
                      .display-flex {
                      display: flex;
                      }
                      .float-left-50 {
                      width: 50%;
                      border-top: 0px;
                      border-bottom: 1px;
                      border-left: 0px;
                      border-right: 0px;
                      border-style: solid;
                      border-color: black;
                      }
                      .float-right-50 {
                      width: 50%;
                      border-top: 0px;
                      border-bottom: 1px;
                      border-left: 1px;
                      border-right: 0px;
                      border-style: solid;
                      border-color: black;
                      }
                      .full-100 {
                      width: 100%;
                      border-left: 0px;
                      border-top: 0px;
                      border-right: 0px;
                      border-bottom: 0px;
                      border-style: solid;
                      border-color: black;
                      }
                      .party-name {
                      width: 33%;
                      border-top: 1px;
                      border-right: 1px;
                      border-bottom: 1px;
                      border-left: 0px;
                      border-style: solid;
                      border-color: black;
                      }
                      .client-name {
                      width: 34%;
                      border-top: 1px;
                      border-right: 1px;
                      border-bottom: 1px;
                      border-left: 0px;
                      border-style: solid;
                      border-color: black;
                      }
                      .qr-code {
                      width: 33%;
                      border-top: 1px;
                      border-right: 0px;
                      border-bottom: 1px;
                      border-left: 0px;
                      border-style: solid;
                      border-color: black;
                      }
                      #declaration a {
                          color: #f54f0c;
                          text-decoration: none;
                      }
                      #declaration .left ol li {
                          list-style: none;
                          font-size: 12px;
                          font-weight: normal;
                      }
                      #declaration {
                          padding: 0;
                          border-bottom: 0px #000 solid;
                          background: #fff;
                          font-weight: 600;
                      }
                      @media print {
                        body {
                          -webkit-print-color-adjust: exact;
                          -moz-print-color-adjust: exact;
                          print-color-adjust: exact;
                        }
                      }
                  `}
        </style>
      </Box>
    </>
  );
})