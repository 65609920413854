import React from 'react';
import CustomAsyncSelect from '../../CommonComp/CustomInput/CustomAsyncSelect';
import CustomCheckbox from '../../CommonComp/CustomInput/CheckboxField';
import AddImagesFields from '../AddImagesFields';

function AddImagesTable({ data, handleChange, removeFormFields, errors, options, getKeywordsData }) {
  return (
    <div style={{overflowX: "auto"}}>
      <table className="" id="tab_logic">
        <thead>
          <tr>
            <th className="text-center"> # </th>
            <th className="text-center"> Image Id </th>
            <th className="text-center"> Rank </th>
            <th className="text-center"> Rank1 </th>
            <th className="text-center"> Rank5 </th>
            <th className="text-center"> Group </th>
            <th className="text-center"> Keyword </th>
            <th className="text-center"> Pricing </th>
            <th className="text-center"> Exclusive </th>
            <th className="text-center"> Agency Name </th>
            <th className="text-center"> NOC </th>
            <th className="text-center"> Type </th>
            <th className="text-center"> Action </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.map((item, idx) => (
            <tr id="addr0" key={idx}>
              <td>{idx + 1}</td>
              <td>
                <AddImagesFields
                  name="image_id"
                  label="Image Id"
                  error={errors[idx]}
                  helperText={'Please enter ImageId'}
                  placeholder="Image Id"
                  value={item.image_id}
                  index={idx}
                  handleChange={handleChange}
                />
              </td>
              <td>
                <AddImagesFields
                  name="rank"
                  label="Rank"
                  placeholder="Rank"
                  error={errors[idx]}
                  helperText={'Please enter Rank (It should be number only)'}
                  value={item.rank}
                  index={idx}
                  handleChange={handleChange}
                />
              </td>
              <td>
                <AddImagesFields
                  name="rank1"
                  label="Rank1"
                  placeholder="Rank1"
                  error={errors[idx]}
                  helperText={'Please enter Rank1 (It should be number only)'}
                  value={item.rank1}
                  index={idx}
                  handleChange={handleChange}
                />
              </td>
              <td>
                <AddImagesFields
                  name="rank5"
                  label="Rank5"
                  placeholder="Rank5"
                  error={errors[idx]}
                  helperText={'Please enter Rank5 (It should be number only)'}
                  value={item.rank5}
                  index={idx}
                  handleChange={handleChange}
                />
              </td>
              <td>
                <AddImagesFields
                  name="group"
                  label="Group"
                  placeholder="Group"
                  error={errors[idx]}
                  helperText={'Please enter Group (It should be number only)'}
                  value={item.group}
                  index={idx}
                  handleChange={handleChange}
                />
              </td>
              <td>
                <CustomAsyncSelect
                  name={'keyword'}
                  helperText={'Please enter keywords'}
                  promiseOptions={getKeywordsData}
                  error={errors[idx]}
                  handleChange={(e) => {
                    let data = {
                      target: {
                        name: 'keyword',
                        value: e,
                      },
                    };
                    handleChange(idx, data);
                  }}
                />
              </td>
              <td>
                <AddImagesFields
                  name="pricing"
                  error={errors[idx]}
                  label="Pricing"
                  placeholder="Pricing"
                  value={item.pricing}
                  helperText={'Please enter Pricing (It should be number only)'}
                  index={idx}
                  handleChange={handleChange}
                />
              </td>
              <td>
                <CustomCheckbox
                  name="exclusive_and_non_exclusive"
                  helperText={'Please select Exclusive'}
                  labelPlacement={'end'}
                  error={errors[idx]}
                  value={item.exclusive_and_non_exclusive == 1 ? true : false}
                  handleChange={(e) => {
                    let data = {
                      target: {
                        name: 'exclusive_and_non_exclusive',
                        value: e.target.checked == true ? 1 : 0,
                      },
                    };
                    handleChange(idx, data);
                  }}
                />
              </td>
              <td>
                <AddImagesFields
                  name="agency_name"
                  error={errors[idx]}
                  label="Agency Name"
                  placeholder="Agency Name"
                  value={item.agency_name}
                  helperText={'Please enter Agency Name'}
                  index={idx}
                  handleChange={handleChange}
                />
              </td>
              <td>
                <AddImagesFields
                  name="noc"
                  error={errors[idx]}
                  label="NOC"
                  placeholder="NOC"
                  value={item.noc}
                  helperText={'Please enter NOC'}
                  index={idx}
                  handleChange={handleChange}
                />
              </td>
              <td>
                <AddImagesFields
                  name="type"
                  error={errors[idx]}
                  select
                  width={100}
                  label="Type"
                  placeholder="Type"
                  helperText={'Please enter Type'}
                  index={idx}
                  handleChange={handleChange}
                  defaultValue={'I'}
                  SelectProps={{
                    value: item.type,
                    name: 'type',
                    onChange: (e) => {
                      if (e.target.value.length) {
                        handleChange(idx, e);
                      }
                    },
                    classes: {
                      select: 'dense',
                    },
                  }}
                  options={options}
                />
              </td>

              <td>
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={(e) => removeFormFields(idx)}
                >
                  Remove
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AddImagesTable;
