export const LogsSortData = [
    { 
      label: 'Sort By',
      value: ''
    },
    {
      label: 'Keyword',
      value: '1',
    },
    {
      label: 'Search Count',
      value: '2',
    }
  ];