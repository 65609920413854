import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setUserFollowUpData,
  setUserFollowUpError,
  setUserFollowUpLoading,
  setOrderFollowUpData,
  setOrderFollowUpLoading,
  setOrderFollowUpError,
  setPaymentFollowUpData,
  setPaymentFollowUpLoading,
  setPaymentFollowUpError,
  setDailySalesEntryData,
  setDailySalesEntryLoading,
  setDailySalesEntryError,
  setManageReportListingLoading,
  setManageReportListingData,
  setManageReportListingError,
  setOutstandingPaymentData,
  setOutstandingPaymentLoading,
  setOutstandingPaymentError,
  setLastUserFollowUpLoading,
  setLastUserFollowUpData,
  setLastUserFollowUpError,
  setSearchCompanyUserFollowUpLoading,
  setSearchCompanyUserFollowUpData,
  setSearchCompanyUserFollowUpError,
  setUnregisteredCompanyLoading,
  setUnregisteredCompanyData,
  setUnregisteredCompanyError,
} from '../redux/followUps';

export const createUserFollowUps = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.createUserFollowUps}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const followUpsByUserIdsListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setUserFollowUpLoading(true));
        const resp = await axios.post(
          `${ApiConfig.listOfFollowUpsByUserIds}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setUserFollowUpData(resp.data.data));
          dispatch(setUserFollowUpLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setUserFollowUpError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setUserFollowUpLoading(false));
      }
    };
  }
};

export const createOrderFollowUps = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.createOrderFollowUps}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const creatMultipleeOrderFollowUps = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.createMultipleOrderFollowUps}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const OrderFollowUpsData = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.orderFollowUps}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const followUpsByOrderIdsListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setOrderFollowUpLoading(true));
        const resp = await axios.post(
          `${ApiConfig.listOfFollowUpsByOrderIds}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setOrderFollowUpData(resp.data.data));
          dispatch(setOrderFollowUpLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setOrderFollowUpError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setOrderFollowUpLoading(false));
      }
    };
  }
};

export const createPaymentFollowUps = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.createPaymentFollowUps}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const followUpsByOrderIdsForPaymentListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setPaymentFollowUpLoading(true));
        const resp = await axios.post(
          `${ApiConfig.listOfFollowUpsByOrderIdsForPayments}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setPaymentFollowUpData(resp.data.data));
          dispatch(setPaymentFollowUpLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setPaymentFollowUpError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setPaymentFollowUpLoading(false));
      }
    };
  }
};

export const daliySalesEntryListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setDailySalesEntryLoading(true));
        const resp = await axios.post(`${ApiConfig.dailySalesEntry}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setDailySalesEntryData(resp.data.data));
          dispatch(setDailySalesEntryLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setDailySalesEntryError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setDailySalesEntryLoading(false));
      }
    };
  }
};
export const unRegisteredCompanyListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setUnregisteredCompanyLoading(true));
        const resp = await axios.post(`${ApiConfig.unregisteredCompanyList}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setUnregisteredCompanyData(resp.data.data));
          dispatch(setUnregisteredCompanyLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setUnregisteredCompanyError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setUnregisteredCompanyLoading(false));
      }
    };
  }
};
export const userByEmailListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.userByEmailList}?email=${payload.email}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const userByEmailListingsForDataNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.userByEmail}?email=${payload.email}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const userByMobileListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.userByMobile}?mobile=${payload.mobile}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const followUpByEmailListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.followupByEmail}?email=${payload.email}&page=${payload.page}&limit=${payload.limit}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const followUpByMobileListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.followupByEmail}?mobile=${payload.mobile_number}&page=${payload.page}&limit=${payload.limit}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};


export const companyByNameListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.companyByName}?company_name=${payload.company_name}&limit=${payload?.limit}&page=1`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};


export const companyMasterByNameListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.companyMasterByName}`,payload
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
//companyByMaster
export const companyShortNameListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.shortCompanyByName}?short_company_name=${payload.short_company_name}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      // toast.error(errorMessage(e), {
      //   autoClose: 2500,
      // });
      return false;
    }
  }
};

export const companyUserByStateListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.userByState}?state=${payload.state}&company_name=${payload.company_name}&limit=${payload?.limit}&page=${payload?.page}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const companyUserListingsByCompanyAssociationStateNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      let url = `${ApiConfig.userListingByStateAssociationCompany}`
      if (payload?.company_name) {
        url += `?company_name=${payload.company_name}&`
      }
      if (payload?.association) {
        url += `association=${payload.association}&`
      }

      if (payload?.state) {
        url += `state=${payload.state}&limit=${payload?.limit}&page=${payload?.page}`
      }
      const resp = await axios.get(
        url,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const companyUserByAssociationAndCompanyListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      let url = `${ApiConfig.stateByCompanyAndAssociation}`
      if (payload.company_name) {
        url += `?company_name=${payload.company_name}`
      }
      if (payload.association) {
        url += `&association=${payload.association}`
      }
      const resp = await axios.get(
        url,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};


export const addDailySalesEntry = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.addDailySales}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const saleFollowUpListingsNoRedux = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.saleFollowup}?daily_sale_id=${payload.daily_sale_id}&page=${payload.page}&limit=${payload.limit}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const addSaleFollowupFollowUps = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.addSaleFollowup}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const manageReportLising = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setManageReportListingLoading(true));
        const resp = await axios.post(`${ApiConfig.manageReport}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setManageReportListingData(resp.data.data));
          dispatch(setManageReportListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setManageReportListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setManageReportListingLoading(false));
      }
    };
  }
};
export const getCompanyNameListByFollowUpDate = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.stateListByCompanyName}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const getInitialShortCompanyNameWithoutDate = async () => {
  try {
    const resp = await axios.post(`${ApiConfig.stateListByCompanyName}`);
    if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
      return resp.data.data;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false;
  }
};

export const getStateListByFollowUpDate = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.stateListByFollowUp}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const companyByNameListingsNoReduxNew = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.get(
        `${ApiConfig.associateByCompany}?company_name=${payload.company_name}`,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        return resp.data.data;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};



//associateByCompany
export const getOutStandingPaymentData = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setOutstandingPaymentLoading(true));
        const resp = await axios.post(
          `${ApiConfig.getOustandingPaymentData}`,
          payload,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          //setAllReportsListing
          dispatch(setOutstandingPaymentData(resp.data.data));
          dispatch(setOutstandingPaymentLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setOutstandingPaymentError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setOutstandingPaymentLoading(false));
      }
    };
  }
};

export const getPaymentReportFile = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.getOustandingPaymentData}`,
        payload,
        { responseType: 'blob' },
      );
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        return { status: true, data: resp?.data };
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return { status: false, data: null };
    }
  }
};

export const getManageReportFile = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.manageReport}`, payload, {
        responseType: 'blob',
      });
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        return { status: true, data: resp?.data };
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return { status: false, data: null };
    }
  }
};

export const editSpecialCommentsAdmin = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editSpecialComment}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updatePartialPayment = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(
        `${ApiConfig.updatePartialPaymentInvoice}`,
        payload,
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateSaleFollowup = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.updateSaleFollowup}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const followUpByEmailListingsWithRedux = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLastUserFollowUpLoading(true));
        const resp = await axios.get(
          `${ApiConfig.followupByEmail}?email=${payload.email}&page=${payload.page}&limit=${payload.limit}`,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setLastUserFollowUpData(resp.data.data));
          dispatch(setLastUserFollowUpLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setLastUserFollowUpError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLastUserFollowUpLoading(false));
      }
    };
  }
};

export const followUpByMobileListingsWithRedux = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLastUserFollowUpLoading(true));
        const resp = await axios.get(
          `${ApiConfig.followupByEmail}?mobile=${payload.mobile}&page=${payload.page}&limit=${payload.limit}`,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setLastUserFollowUpData(resp.data.data));
          dispatch(setLastUserFollowUpLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setLastUserFollowUpError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setLastUserFollowUpLoading(false));
      }
    };
  }
};

export const followUpByCompanyListingsWithRedux = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setSearchCompanyUserFollowUpLoading(true));
        const resp = await axios.get(
          `${ApiConfig.userByState}?state=${payload.state}&company_name=${payload.company_name}&page=${payload.page}&limit=${payload.limit}`,
          //`${ApiConfig.companyByName}?company_name=${payload.company_name}&page=${payload.page}&limit=${payload.limit}`,
          // `${ApiConfig.followupByEmail}?email=${payload.email}&page=${payload.page}&limit=${payload.limit}`,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setSearchCompanyUserFollowUpData(resp.data));
          dispatch(setSearchCompanyUserFollowUpLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setSearchCompanyUserFollowUpError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSearchCompanyUserFollowUpLoading(false));
      }
    };
  }
};