import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { formatDate, getObjectSubset } from '../../lib/helper';
import {
  createOrderFollowUps,
  followUpsByOrderIdsListings,
} from '../../Services/CRMFollowups';
import { loginItemsSelector } from '../../redux/login';
import { useDispatch, useSelector } from 'react-redux';
import MatTable from '../Tables/MatTable';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { DATA_LIMIT } from '../../data/constants';
import {
  followUpsItemSelector,
  setOrderFollowUpData,
} from '../../redux/followUps';
const limit = DATA_LIMIT;

const column = [
  {
    label: 'Next Follow Ups Date',
    id: 'next_followup_date',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'mon dd yyyy')}
      </span>
    ),
  },
  {
    label: 'Heading',
    id: 'heading',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Description',
    id: 'description',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Create Date',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData)}
      </span>
    ),
  },
];

function ViewFollowUpsDetails({ open, handleOpen, selectedData }) {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState({});
  const { orderFollowUpData } = useSelector(followUpsItemSelector);
  const dispatch = useDispatch();

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: String(limit),
      order_id: selectedData?.order_id,
      ...allParams,
      ...params,
    };
    dispatch(followUpsByOrderIdsListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    dispatch(setOrderFollowUpData([]));
    getListing();
  }, []);

  //making data format for table input
  useEffect(() => {
    if (orderFollowUpData?.items?.data?.length >= 0) {
      setTableListing(orderFollowUpData?.items?.data);
    }
  }, [orderFollowUpData.items]);
  ////
  return (
    <div>
      <Dialog
        maxWidth={'lg'}
        fullWidth
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant="h3">Order Follow Ups By Order ID</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
          // sx={{
          //   width: 400
          // }}
          >
            <Box>
              <strong>Order ID:</strong> {selectedData?.order_id}
            </Box>
            <Box>
              <strong>Email:</strong> {selectedData?.email}
            </Box>
            <CustomBox padding={'50px 0 0'}>
              <MatTable
                loading={orderFollowUpData?.loading}
                showCheckbox={false}
                columns={column}
                data={tableListing}
                onApplyFilter={(data = {}) => {
                  getListing({
                    page: String(1),
                    start_date: '',
                    end_date: '',
                    ...getObjectSubset(data, 'start_date', 'end_date'),
                  });
                  if (Object.keys(data).length > 0) {
                    setAllParams({
                      ...allParams,
                      page: String(1),
                      ...getObjectSubset(data, 'start_date', 'end_date'),
                    });
                  } else {
                    setAllParams({
                      page: String(1),
                    });
                  }
                }}
                page={page}
                perPage={limit}
                total={orderFollowUpData?.items?.totalcount || 0}
                setPage={setPage}
                handlePageChange={(newPage) => {
                  getListing({
                    page: String(newPage),
                  });
                  setAllParams({
                    ...allParams,
                    page: String(newPage),
                  });
                }}
                toolbarEnable={false}
                pagination={true}
              />
            </CustomBox>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                onClick={() => {
                  handleOpen(false);
                }}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewFollowUpsDetails;
