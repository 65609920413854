import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { TableCell, Typography } from '@mui/material';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import MatTable from '../../Components/Tables/MatTable';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import { OrderData } from '../../data/OrderData';
import { OrderListing } from '../../Services/OrderApis';
import OrderTabBody from '../../Components/Order/OrderTabBody';



function Order({ handleNavParams }) {
  const [tabValue, setTabValue] = useState(1);
  const [orderListing, setOrderListing] = useState([]);
  const [page, setPage] = useState(1);

  // const getOrderListing = useCallback(async () => {
  //   let resp = await OrderListing({
  //     page: page,
  //     limit: limit,
  //   });
  //   if (resp.data?.status == 200) {
  //     setOrderListing(resp.data.data);
  //   }
  // }, [page]);

  // useEffect(() => {
  //   getOrderListing();
  // }, []);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={0}>
        <CustomTabs tabList={OrderData.tabs} tabClick={handleTabs} />
        {/* <button onClick={()=>{
         handleNavParams({
          navTitle: "Book Order"
         })
       }}>Hello</button> */}
      </CustomBox>
      <Divder spacing={1} />
      <OrderTabBody activeTab={tabValue} />
      <Divder spacing={1.2} />
    </Box>
  );
}

export default Order;
