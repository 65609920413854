import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Tooltip } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { addCompanyMaster, companyGroupListingsNoRedux, companyListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import { addSubscriptionPlan, subscriptionListings } from '../../Services/SubscriptionApis';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { editAddCMS } from '../../Services/cmsService';

const limit = DATA_LIMIT;

function AddContactModal({ open, handleOpen, setPage, handleLast, cmsDataHome, data, editIndex, type }) {
  const [loading, setLoading] = useState(false);
  const [countryShortName, setCountryShortName] = useState('in');
  const [contactArray, setContactArray] = useState(editIndex >= 0 ? data?.contacts : ['']);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {

    let arr = contactArray?.filter(a => a != "");

    if (arr?.length) {
      setLoading(true);
      let obj1 = { city: values?.city, contacts: contactArray };
      let newArr = [];
      if (type === 'edit') {
        newArr = [...cmsDataHome?.contactsSection];
        newArr[editIndex] = obj1;
      }
      else {
        newArr = [...cmsDataHome?.contactsSection, obj1]
      }
      let cmsObj = { ...cmsDataHome, contactsSection: newArr };
      let str1 = JSON.stringify(cmsObj);
      let obj = { pagename: 'homePage', pagedata: str1 }
      let resp = await editAddCMS(obj);
      if (resp) {
        resetForm({
          city: "",
          contacts: "",
        })
        handleLast();
        setLoading(false)
        handleOpen(false);
      }
      setLoading(false)
    }
    // setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      city: data?.city || "",
      contacts: [],
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      city: Yup.string().required('City is required.'),
      // contacts: Yup.string.required('City is required.'),
    }),
    onSubmit: handleConfirm,
  });

  const removeArray = (index) => {
    let arr = [...contactArray]
    arr.splice(index, 1);
    setContactArray(arr);
  }

  const handlemobileValue = (value, index) => {
    let arr = [...contactArray];
    arr[index] = value;
    setContactArray(arr);
  }

  return (
    <div>
      <Dialog
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Add Contacts
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="city"
                  label="City"
                  placeholder="City"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              {contactArray?.map((cont, index) => {
                return <> <Grid item xs={10}>
                  <CustomPhoneInput
                    label="Mobile"
                    id="mobile"
                    name="mobile"
                    className="main-login-form"
                    fullWidth
                    placeholder="Mobile Number"
                    native={true}
                    value={cont}
                    defaultCountry={`${countryShortName}`}
                    country={`${countryShortName}`}
                    divStyle={{
                      paddingTop: 4,
                    }}
                    variant="outlined"
                    onChange={(newValue, info) => {
                      handlemobileValue(newValue, index)
                    }}
                    onlyCountries={[countryShortName]}
                    error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                    helperText={formik.touched.mobile && formik.errors.mobile}
                  />
                </Grid>
                  <Grid item xs={2}>
                    <Box mt={2.2}>
                      {index !== 0 &&
                        <Tooltip title="Remove Contact" onClick={() => removeArray(index)}>
                          <RemoveCircleIcon />
                        </Tooltip>
                      }
                      <Tooltip title="Add More Contacts" onClick={() => setContactArray([...contactArray, ''])}>
                        <AddCircleIcon />
                      </Tooltip>
                    </Box>
                  </Grid>
                </>
              })}

            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    place: "",
                    contacts: ""

                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddContactModal;
