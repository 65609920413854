import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT } from '../../data/constants';
import { breakStringIntoLines, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { imageListingItemsSelector } from '../../redux/image';
import { soldImageListings } from '../../Services/imageApis';
import ImageDetailModal from '../Modals/ImageDetailModal';
import MatTable from '../Tables/MatTable';

const column = [
  {
    label: 'Image',
    id: 'image_url',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
      >
        <img style={{ maxWidth: 200, maxHeight: 200 }} src={   `${ApiConfig.BASE_IMAGE_URL}${row?.shoot_id}/${row?.rank}-${row?.image_name}.jpg`} />
      </span>
    ),
  },
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
      >
        {columnData}
      </span>
    ),
  },
  {
    label: 'Image ID',
    id: 'image_name',
    format: (columnData, rowIndex, formatCallback, options) => (
      <>
      {columnData?.substring(0,2)==='VD'? <Typography
        variant='span'
        style={{ whiteSpace: 'nowrap' }}
        className={'link-text'}
        onClick={() => {
          options(rowIndex, columnData)
        }}>
        {columnData || 'N/A'}
      </Typography>: <Typography
        variant='span'
        style={{ whiteSpace: 'nowrap' }}
        className={'link-text'}
        onClick={() => {
          options(rowIndex, columnData)
        }}>
        {columnData || 'N/A'}
      </Typography>}
     
      </>
    ),
  },

  {
    label: 'User',
    id: 'user_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content",overflowWrap: 'anywhere' }}>{columnData || 'N/A'}</span>)
   
  },
  {
    label: 'Client',
    id: 'client',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>
        {columnData?breakStringIntoLines(columnData,20):'N/A'}
      </span>)

  },
  {
    label: 'Company Name',
    id: 'company',
    format: (columnData) => (
      <span style={{ display: 'inline-block', width: 'max-content', maxWidth:200 }}>
          {columnData?breakStringIntoLines(columnData,20):'N/A'}
      </span>
    ),
  },
  {
    label: 'Quality',
    id: 'quality',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
 
  {
    label: 'Pack',
    id: 'pack',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },

  {
    label: 'Download On',
    id: 'Download_on    ',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(row?.Download_on)}
      </span>
    ),
    nextLine:true,
  },

  
];

function SoldImages() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [imageDetailModal, setImageDetialModal] = useState(false);
  const [imageName, setImageName] = useState(null);
  const { loading, soldImages } = useSelector(imageListingItemsSelector);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(20);
  const navigate = useNavigate()
  const location = useLocation();

  const handleModal = (value = false, image = null) => {
    setImageDetialModal(value);
    if (!value) {
      setTimeout(() => {
        setImageName(image);
      }, 500);
    }else{
      setImageName(image);
    }
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // order_status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(soldImageListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  //making data format for table input
  useEffect(() => {
    if (soldImages?.items?.rows?.length >= 0) {
      let newArr=soldImages?.items?.rows?.map((a=>({...a,company:a?.order?.company})));
      setTableListing(newArr);
    }
  }, [soldImages.items]);


  return (
    <>
      <MatTable
        loading={soldImages?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20,50,100,200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={soldImages?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        columnWiseData={[
            {
              id: "image_name",
              value: (index, data)=>{
                handleModal(true, data)
              }
            }
          ]}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
      <ImageDetailModal
        open={imageDetailModal}
        handleOpen={handleModal}
        imageName={imageName}
      />
    </>
  );
}

export default SoldImages;
