import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, MenuItem } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { createCommonSettingCategoryTableData, formatDate, handleFileReader } from '../../lib/helper';
import { fileUpload } from '../../Services/commonService';
import { uploadWeeklyImages } from '../../Services/DashboardApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import { ImageSelectData } from '../../data/imageData';
import { updateImage } from '../../Services/imageApis';
import { commonItemsSelector } from '../../redux/common';
import { useSelector } from 'react-redux';
import { updateVideo } from '../../Services/videosApis';

export default function EditVideosDetailModal({ open, handleOpen, data, getListing, type }) {
  const [loading, setLoading] = React.useState(false);
  const { appSetting } = useSelector(commonItemsSelector)
  const [categoryTableData, setCategoryTableData] = React.useState([]);


  const handleUpload = async (values, { resetForm }) => {
    try {
      setLoading(true)
      // let payload = {
      //   image_name: data.Name,
      //   image_type: values.image_type,
      //   image_rank: Number(values.image_rank),
      //   rank: Number(values.rank),
      //   is_star: values.is_star,
      //   pricing: Number(values.pricing),
      //   ...(type == "starimage" && { category: values.category })
      // }
      let resp = await updateVideo(values);
      if (resp) {
        toast.success(resp?.message)
        getListing();
       
        handleOpen(false);
      }
      setLoading(false);
      resetForm();
    } catch (e) {
      setLoading(false)
      console.log(e, 'error')
    }
  }

  const formik = useFormik({
    initialValues: {
      video_id: data?.video_id || '',
      video_name: data.Name || "",
      // image_type: data.type || "",
      rank: data.rank || "",
      shootid: data?.shootid || "",
      pricing: data.pricing || "",
      nonexclusive: data?.nonexclusive===1?1:0,
      suspendate: data?.suspendate || "",
      // vimeo_path: data?.vimeo_path || "",
      // vimeo_id: data?.vimeo_id || ""
    },
    //  // ...(type == "starimage" && {category: data.category || ""}),
    enableReinitialize: true,
    validationSchema: Yup.object({
      video_name: Yup.string()
        .required('Video name is required.'),
      // image_type: Yup.string()
      //   .required('Image type is required.'),
      rank: Yup.string()
        .required('Image rank is required.')
        .matches(/^\d+$/, 'Image rank should be number only.'),
      shootid: Yup.string()
        .required('Shoot Id is required.'),
      nonexclusive: Yup.string()
        .required("Non Exclusive is required"),
      pricing: Yup.string()
        .required('Pricing is required.')
        .matches(/^\d+$/, 'Pricing should be number only.'),
      suspendate: Yup.string()
        .required('Suspend Date is required.'),
      // vimeo_path: Yup.string()
      //   .required('Vimeo Path is required.'),
      // vimeo_id: Yup.string()
      //   .required('Vimeo Id is required.'),

      // ...(type == "starimage" && {category: Yup.string()
      //   .required('Category is required.')})

    }),
    onSubmit: handleUpload,
  });

  React.useEffect(() => {
    if (type == "starimage") {
      setCategoryTableData(createCommonSettingCategoryTableData(appSetting?.items?.features?.stars[0]?.key_value, 'stars'));
    }
  }, [appSetting?.items?.features?.stars])

  return (
    <>
      <Dialog
        maxWidth={'lg'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant='h3'>Edit Video Details</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={4} md={3}>
                <CustomImage
                  style={{ width: '100%', borderRadius: 8 }}
                  src={data.image_url}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Grid container spacing={2} mt={0}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="video_name"
                      label="Video Name"
                      placeholder="Video Name"
                      value={formik.values.video_name}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.video_name &&
                        Boolean(formik.errors.video_name)
                      }
                      helperText={
                        formik.touched.video_name && formik.errors.video_name
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4}>
                      <CustomInput
                        variant="outlined"
                        fullWidth
                        name="rank"
                        label="Video Rank"
                        placeholder="Video Rank"
                        value={formik.values.image_rank}
                        onChange={(e) => {
                          formik.setFieldValue('rank', e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          classes: {
                            notchedOutline: 'rounded',
                          },
                        }}
                        error={
                          formik.touched.rank &&
                          Boolean(formik.errors.rank)
                        }
                        helperText={
                          formik.touched.rank && formik.errors.rank
                        }
                      />
                    </Grid> */}
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="shootid"
                      label="Shoot Id"
                      placeholder="Shoot Id"
                      value={formik.values.shootid}
                      onChange={(e) => {
                        formik.setFieldValue('shootid', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.shootid &&
                        Boolean(formik.errors.shootid)
                      }
                      helperText={
                        formik.touched.shootid && formik.errors.shootid
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="pricing"
                      label="Pricing"
                      placeholder="Pricing"
                      value={formik.values.pricing}
                      onChange={(e) => {
                        formik.setFieldValue('pricing', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.pricing &&
                        Boolean(formik.errors.pricing)
                      }
                      helperText={
                        formik.touched.pricing && formik.errors.pricing
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="rank"
                      label="Rank"
                      placeholder="Rank"
                      value={formik.values.rank}
                      onChange={(e) => {
                        formik.setFieldValue('rank', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.rank &&
                        Boolean(formik.errors.rank)
                      }
                      helperText={
                        formik.touched.rank && formik.errors.rank
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      name="nonexclusive"
                      label="Non-Exclusive"
                      id="nonexclusive"
                      placeholder="Non-Exclusive"
                      SelectProps={{
                        native: true,
                      }}
                      select
                      value={formik.values.nonexclusive}
                      
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      onChange={(e) => {
                        formik.setFieldValue('nonexclusive', e.target.value);
                      }}
                      // onChange={formik.handleChange}
                      fullWidth
                      error={formik.touched.nonexclusive && Boolean(formik.errors.nonexclusive)}
                      helperText={formik.touched.nonexclusive && formik.errors.nonexclusive}
                    >
                      <option key={'0'} value={0}>
                        0
                      </option>
                      <option key={'1'} value={1}>
                        1
                      </option>
                    </CustomInput>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="vimeo_id"
                      label="Vimeo Id"
                      placeholder="Vimeo Id"
                      value={formik.values.vimeo_id}
                      onChange={(e) => {
                        formik.setFieldValue('vimeo_id', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.vimeo_id &&
                        Boolean(formik.errors.vimeo_id)
                      }
                      helperText={
                        formik.touched.vimeo_id && formik.errors.vimeo_id
                      }
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} sm={12} md={12}>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="vimeo_path"
                      label="Vimeo Path"
                      placeholder="Vimeo Path"
                      value={formik.values.vimeo_path}
                      onChange={(e) => {
                        formik.setFieldValue('vimeo_path', e.target.value);
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.vimeo_path &&
                        Boolean(formik.errors.vimeo_path)
                      }
                      helperText={
                        formik.touched.vimeo_path && formik.errors.vimeo_path
                      }
                    />
                  </Grid> */}
                
                  {/* 
                    <Grid item xs={12} sm={6} md={3}>
            <CustomInput
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: 'rounded',
                },
              }}
              name="suspendate"
              type="date"
              label="Suspend Date"
              placeholder="Suspend Date"
              value={formatDate(formik.values.from, "yyyy-mm-dd")} //2017-06-13T13:00
              onChange={(e)=>{
                // if(formik.values.accepted_or_rejected_on){
                //   if(validDate(formik.values.accepted_or_rejected_on, e.target.value)
                //     && validDate(e.target.value, formik.values.ordered_on)){
                //     formik.handleChange(e)
                //   }
                // }else{
                  // if(validDate(new Date(), e.target.value, true)){
                  //   formik.setValues((values)=>({
                  //     ...values,
                  //     'from': e.target.value + ' 00:00:00',
                  //   }))
                  // }else{
                  //   toast.error('From date should not be greater than Today date')
                  // }
                // }
              }}
              error={formik.touched.suspendate && Boolean(formik.errors.suspendate)}
              helperText={formik.touched.suspendate && formik.errors.suspendate}
            />
          </Grid> */}

                </Grid>
              </Grid>

              <Grid item xs={12} sm={8} md={9}>
                <Grid container spacing={2} mt={0}>



                </Grid>
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "end", marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Save</CustomButton>
              <CustomButton onClick={() => handleOpen(false)} disabled={loading}>Close</CustomButton>
            </div>
          </Box>
        </DialogContent>
        {/* <DialogActions>
					<CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Upload</CustomButton>
					<CustomButton onClick={() => handleOpen(false)}>Close</CustomButton>
        </DialogActions> */}
      </Dialog>
    </>
  );
}


EditVideosDetailModal.defaultProps = {
  open: false,
  handleOpen: () => { },
  data: null,
  getListing: () => { },
  type: 'allimage'
}