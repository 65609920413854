import axios from "axios";
import { toast } from "react-toastify";
import ApiConfig from "../config/ApiConfig";
import { deleteApiPayload, errorMessage } from "../lib/helper";
import { STATUS_200, STATUS_300 } from '../data/constants';
import { setCategoryListing, setCategoryListingError, setCategoryListingLoading, setLoading, setStarCategoryListing, setStarCategoryListingError, setStarCategoryListingLoading, setSubCategoryListing, setSubCategoryListingError, setSubCategoryListingLoading, setVideoCategoryListing, setVideoCategoryListingError, setVideoCategoryListingLoading } from "../redux/category";

export const categoryListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try{
        dispatch(setCategoryListingLoading(true))
        const  resp = await axios.post(`${ApiConfig.categoryListing}`, payload);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          dispatch(setCategoryListing(resp.data.data));
          dispatch(setCategoryListingLoading(false))
        } else {
          throw resp
        }
      }catch(e){
        dispatch(setCategoryListingError({
          isError: true,
          message: errorMessage(e)
        }));
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setCategoryListingLoading(false))
      }
    };
  }
};

export const starCategoryListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try{
        dispatch(setCategoryListingLoading(true))
        const  resp = await axios.post(`${ApiConfig.starcategoryListing}`, payload);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          dispatch(setStarCategoryListing(resp.data.data));
          dispatch(setStarCategoryListingLoading(false))
        } else {
          throw resp
        }
      }catch(e){
        dispatch(setStarCategoryListingError({
          isError: true,
          message: errorMessage(e)
        }));
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setStarCategoryListingLoading(false))
      }
    };
  }
};

export const subCategoryListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try{
        dispatch(setSubCategoryListingLoading(true))
        const  resp = await axios.post(`${ApiConfig.subCategoryListing}`, payload);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          dispatch(setSubCategoryListing(resp.data.data));
          dispatch(setSubCategoryListingLoading(false))
        } else {
          throw resp
        }
      }catch(e){
        dispatch(setSubCategoryListingError({
          isError: true,
          message: errorMessage(e)
        }));
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setSubCategoryListingLoading(false))
      }
    };
  }
};

export const createCategory = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createCategory}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const editCategory = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editCategory}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteCategory = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.delete(`${ApiConfig.deleteCategory}`, deleteApiPayload(payload));
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const createSubCategory = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createSubCategory}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const editSubCategory = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editSubCategory}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteSubCategory = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.delete(`${ApiConfig.deleteSubCategory}`, deleteApiPayload(payload));
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const videoCategoryListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try{
        dispatch(setVideoCategoryListingLoading(true))
        const  resp = await axios.post(`${ApiConfig.listOfVideoCategories}`, payload);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          dispatch(setVideoCategoryListing(resp.data.data));
          dispatch(setVideoCategoryListingLoading(false))
        } else {
          throw resp
        }
      }catch(e){
        dispatch(setVideoCategoryListingError({
          isError: true,
          message: errorMessage(e)
        }));
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setVideoCategoryListingLoading(false))
      }
    };
  }
};

export const createVideoCategory = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createVideoCategory}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const editVideoCategory = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editVideoCategory}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteVideoCategory = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.delete(`${ApiConfig.deleteVideoCategory}`, deleteApiPayload(payload));
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const createStarCategory = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createStarCategory}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const editStarCategory = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editStarCategory}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteStarCategory = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.delete(`${ApiConfig. deleteStarCategory}`, deleteApiPayload(payload));
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};
