import React from 'react';
import { Link } from 'react-router-dom';
import { crypt } from '../../lib/helper';
import ApiConfig from '../../config/ApiConfig';

function ProposalSuccess({proposalId}) {
    const host = window.location.hostname
    const origin = window.location.origin

  return (
    <div>
      <br />
      Thank you for your interest in ImagesBazaar. Please click on the link
      given below to view the quotation:
      <br />
      <br />
      <a href={`${ApiConfig.LIVE_WEBSITE_URL}/quotation?proposal_id=${proposalId}`} target={'_blank'}>{ApiConfig.LIVE_WEBSITE_URL}/quotation?proposal_id={proposalId}</a>
      <br />
      <br />
      If the link above is not clickable simply cut and paste it into the
      location bar of your browser and press enter.
      <br />
      <br />
      Kindly <a href={`${ApiConfig.LIVE_WEBSITE_URL}/ordering`} target={'_blank'}>click here</a> to view the Procedure to place
      an order at ImagesBazaar.com. Usage of images subject to Mash Rights
      Agreement mentioned on{' '}
      <a href={`${ApiConfig.LIVE_WEBSITE_URL}/licensing`} target={"_blank"}>{ApiConfig.LIVE_WEBSITE_URL}/licensing</a>
      <br />
      <br />
      <br />
      We appreciate your consideration, and upon receipt of approval, the
      image(s) download link will be activated immediately.
      <br />
      <br />
      <b>Delivery Schedules</b>
      <br />
      <br />
      For all orders placed through Credit Cards (VISA, Master Card or American
      Express), the link to download the image(s) gets immediately activated as
      soon as your transaction gets successful, whether it's day or night,
      weekday or weekend.
      <br />
      <br />
      For orders placed through Net Banking, Cheque/Demand Draft or Electronic
      Fund Transfer, the link to download the image(s) gets activated as soon as
      we receive your payment. Orders placed after the office timings given
      below will be activated at 10:00 AM on the next working day. Office
      Timings (IST): 10:00 AM to 6:30 PM (Closed on Sundays and National
      Holidays of India)
      <br />
      <br />
      Looking forward to a long term relationship!
      <br />
      Customer Support Team
      <br />
      <a href={`${ApiConfig.LIVE_WEBSITE_URL}`} target="_blank">{ApiConfig.LIVE_WEBSITE_URL}</a>
      <br />
      <br />
    </div>
  );
}

export default ProposalSuccess;
