import { Mail } from '@material-ui/icons';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiConfig from '../../config/ApiConfig';
import { FiltersData } from '../../data/filtersData';
import { breakStringIntoLines, breakStringIntoLinesBySymbol, crypt, currencyConversion, downloadBlobXLSFile, findObjectInArray, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { commonItemsSelector } from '../../redux/common';
import { orderListingItemsSelector } from '../../redux/orderListing';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { getIvsOrderListing, getIvsOrderListingExportFile } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import AllFilters from './AllFilters';


const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (<span style={{
      display: 'inline-block', whiteSpace: "nowrap",
    }}
    > {columnData}</span>)
  },
  {
    label: 'Plan Name',
    id: 'subscription_plan_name',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}> {columnData}</span>)
  },
  {
    label: 'Email',
    id: 'user_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
             {columnData || 'N/A'}
        </span>
    )
  },
  {
    label: 'Order Status',
    id: 'order_status',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <>
        <Typography
          variant="span"
          sx={{
            color:
              columnData == 1
                ? 'success.main'
                : columnData == '2'
                  ? 'danger.main'
                  : columnData == '0'
                    ? 'info.main'
                    : 'N/A',
            width: 90,
            display: 'inline-block',
            textAlign: "left"
          }}
        >
          {columnData == 1 ? 'C' : columnData == '2' ? 'R' : columnData == '0' ? 'P' : 'N/A'}
        </Typography>
      </>
    ),
    nextLine:true,
  },
  {
    label: 'Amount (INR)',
    id: 'withoutgst_price',
    format: (columnData) => (
        <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData?currencyConversion(columnData) : '0'}</span>
    ),
    nextLine:true
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', width: 'max-content', maxWidth: 200, wordBreak: 'break-word' }}>
        {columnData?breakStringIntoLines(columnData,20):'N/A'}
        </span>
    )
  },
  {
    label: 'Validity',
    id: 'date',
    format: (columnData) => (<span style={{ display: 'inline-block' }}>
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>Created At:</Typography> {formatDate(columnData?.created_at)}
      </span>
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>Confirmed On:</Typography> {formatDate(columnData?.confirmed_on)}</span>
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>Expiry:</Typography> {formatDate(columnData?.expired_on)}</span>
    </span>)
  },
  {
    label: 'Download Info',
    id: 'download_info',
    format: (columnData) => (<span style={{ display: 'inline-block' }}>
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>Total Download:</Typography> {columnData?.total_images}</span>
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>Downloaded:</Typography> {columnData?.downloaded}</span>
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>Remaining Download:</Typography> {columnData?.remaining_images}</span>
    </span>)
  },
  {
    label: 'Remaining Days',
    id: 'days',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}> {columnData}
    </span>),
    nextLine:true
  },
  {
    label: 'Comment',
    id: 'comment',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content', wordBreak: "break-word", maxHeight: 250, overflow: 'auto' }}>
       {columnData?breakStringIntoLines(columnData,20):'N/A'}
       </span>)
  },
  {
    label: 'Closed By',
    id: 'closed_by',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || "-"}</span>)
  },
];

function IvsOrderListing() {
  const [tableListing, setTableListing] = useState([])
  const [expLoading, setExpLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [allParams, setAllParams] = useState(getParameters());
  const { ivsOrderListing } = useSelector(orderListingItemsSelector);
  const { appSetting } = useSelector(commonItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [filtersArray, setFiltersArray] = useState([

    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },

    {
      label: 'Closed By',
      id: 'closed_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { value: 'CS', label: 'CS' },
        { value: 'Sales', label: 'Sales' },
        { value: 'Exclude', label: 'Exclude', },
        { value: 'Special package', label: 'Special package', },
        { value: 'Renew', label: 'Renew', },
        { value: 'Sales-Renew', label: 'Sales-Renew', },
        { value: 'Legal', label: 'Legal', },
      ],
      props: {},
      isVisible: true,
    },
    {
      label: 'Remaining Days',
      id: 'remaining_days',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { value: '0', label: '0 Day', },
        { value: '1', label: '1 Day' },
        { value: '6', label: '6 Days' },
        { value: '7', label: '7 Days', },
        { value: '8', label: '8 Days', },
        { value: '14', label: '14 Days', },
        { value: '15', label: '15 Days', },
        { value: '16', label: '16 Days', },
      ],
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },


  ]);

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params
    }

    if (payload?.start_date) {
      payload['start_date'] = formatDate(payload?.start_date, 'yyyy-mm-dd') + "  00:00:00"
    }
    if (payload?.end_date) {
      payload['end_date'] = formatDate(payload?.end_date, 'yyyy-mm-dd') + "  23:59:59"
    }
    dispatch(getIvsOrderListing(payload))
    setPage(Number(payload.page))
  }

  const getExportFile = async (params = {}, b, prepop) => {
    try {

      let payload = {
        ...allParams,
        ...params
      }
      delete payload.page
      delete payload.limit

      setExpLoading(true)
      if (prepop?.start_date) {
        prepop['start_date'] = formatDate(prepop?.start_date, 'yyyy-mm-dd') + "  00:00:00"
      }
      if (prepop?.end_date) {
        prepop['end_date'] = formatDate(prepop?.end_date, 'yyyy-mm-dd') + "  23:59:59"
      }
      let resp = await getIvsOrderListingExportFile(prepop);
      if (resp) {
        downloadBlobXLSFile(resp?.data, `IVSOrdersList`)
      } else {
        toast.error('Something went wrong.')
      }
      setExpLoading(false)
    } catch (e) {
      console.log(e)
      setExpLoading(false)
    }
  }

  const handleChangeFollowUpDate = async (dateValue, index, type) => {
    const payload = { type: dateValue };
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[index].dataArr = [{ label: 'Select', value: '' }];
      newState[index].value = '';
      return newState;
    });

  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);


  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[3].isVisible = true;
      newState[4].isVisible = true;

      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      newState[3].value = '';
      newState[4].value = '';
      // newState[5].isVisible = true;
      return newState;
    });
  };

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  useEffect(() => {
    if (ivsOrderListing?.items?.rows?.length >= 0) {
      let data = ivsOrderListing?.items?.rows?.map((item) => {
        let remainDays = new Date(item?.expired_on)?.getTime() - new Date()?.getTime();
        let TotalDays = Math.ceil(remainDays / (1000 * 3600 * 24));
        return {
          ivs_order_id: item?.ivs_order_id,
          order_id: item?.order_id,
          plan_id: item?.plan_id,
          user_name: item?.user_name,
          date: {
            confirmed_on: item?.confirmed_on,
            expired_on: item?.expired_on,
            created_at: item?.created_at,
          },
          days: TotalDays === -0 ? 0 : TotalDays > 0 ? TotalDays : 'Expired',
          download_info: {
            total_images: item?.total_images,
            downloaded: item?.total_images - item?.remaining_images,
            remaining_images: item?.remaining_images,
          },
          plan_type: item?.plan_type,
          subscription_plan: item?.subscription_plan,
          subscription_plan_name: item?.subscription_plan?.plan_name,
          closed_by: item?.grouping_manage?.closed_by,
          comment: item?.order?.comment,
          company_name: item?.grouping_manage?.company_name,
          order_status: item?.order?.order_status,
          amount:item?.invoice?.initial_price,
          withoutgst_price:item?.invoice?.withoutgst_price
        }
      })
      setTableListing(data)
    }
  }, [ivsOrderListing.items]);

  const generateFilters = (arr) => {
    try {
      let data = findObjectInArray(arr, 'key_name', 'remaining_days')
      if (data) {
        let mainData = data?.key_value?.split(",")?.map((item) => {
          if (item > 1) {
            return {
              value: item,
              label: `${item} days`,
            }
          } else {
            return {
              value: item,
              label: `${item} day`,
            }
          }
        })
        return mainData
      }
      return []
    } catch (e) {
      return []
    }
  }


  const actionBody = (data) => {

    return (
      <>
        <div style={{ display: "flex" }}>
          <Link to={`expiryEmail?email=${crypt(ApiConfig.REACT_APP_SALT + 'email', data?.user_name)}&orderId=${crypt(ApiConfig.REACT_APP_SALT + 'order_id', data?.order_id)}&planId=${crypt(ApiConfig.REACT_APP_SALT + 'plan_id', data?.plan_id)}&expiry=${crypt(ApiConfig.REACT_APP_SALT + 'expiry', data?.date?.expired_on)}&coupon_name=${crypt(ApiConfig.REACT_APP_SALT + 'coupon_name', data?.subscription_plan?.coupon_name)}&coupon_amount=${crypt(ApiConfig.REACT_APP_SALT + 'coupon_amount', data?.subscription_plan?.coupon_amount)}`}>
            <CustomButton toolTipTittle={"Expiry Email"} className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Mail /></CustomButton>
          </Link>
        </div>
      </>
    );
  };

  const handleDownloadExcel = async () => {
    const payload = {
      ...allParams,
    };
    let resp = await getManageReportFile(payload);
    if (resp.status) {
      // formik?.resetForm()
      downloadBlobFile(
        resp.data,
        `ManageReport${allParams?.follow_up_date ? allParams?.follow_up_date : ''
        }`,
      );
    }
  };

  return (
    <>
      <Grid container spacing={2} p={1.5}>
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>

            Total Records: {ivsOrderListing?.items?.count || 0}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
          Total Earnings(INR): {ivsOrderListing?.items?.totalAmount?currencyConversion(ivsOrderListing?.items?.totalAmount):0}
          </Typography>
        </Grid>
        <>
          <MatTable

            loading={ivsOrderListing?.loading}
            showCheckbox={false}
            columns={column}
            data={tableListing}
            // requestSearch={requestSearch}
            // cancelSearch={cancelSearch}
            onApplyFilter={(data = {}) => {
              getListing({
                page: String(1),
                start_date: "",
                end_date: "",
                ...getObjectSubset(data, 'start_date', 'end_date', 'closed_by', 'remaining_days'),
              });
              if (Object.keys(data).length > 0) {
                setAllParams({
                  ...allParams,
                  page: String(1),
                  ...getObjectSubset(data, 'start_date', 'end_date', 'closed_by', 'remaining_days'),
                });
              } else {
                setAllParams({
                  page: String(1),
                });
              }
            }}
            // showFilterButton={true}
            // showSerialNo={false}
            action={actionBody}
            // actionHead={actionHead}
            page={page}
            // changeFilter={changeFilter}
            // handleFilterPopup={toggleFilters}
            perPage={limit}
            showPageLimit={true}
            handleChangePageLimit={(e) => {
              setLimit(e.target.value);
              setAllParams({
                ...allParams,
                page: String('1'),
                limit: Number(e.target.value),
              });
            }}
            // setFilters={setFilters}
            // filters={filters}
            total={ivsOrderListing?.items?.count || 0}
            // defaultSort="transactionTime"
            // defaultSortOrder={isMobile ? sortOrder : 'desc'}
            // searchTerm={searchTerm}
            setPage={setPage}
            handleSearch={(searchTerm) => {
              getListing({
                page: String(1),
                search: searchTerm
              })
              setAllParams({
                ...allParams,
                page: String(1),
                search: searchTerm
              })
            }}
            handlePageChange={(newPage) => {
              getListing({
                page: String(newPage)
              })
              setAllParams({
                ...allParams,
                page: String(newPage)
              })
            }}
            toolbarEnable={true}
            // toolBarButton={()=>{
            //   return <CustomButton sx={(theme)=>({ fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
            //     [theme.breakpoints.down("md")]: {
            //       flex: 1,
            //     }
            //    })} onClick={()=>{
            //     getExportFile()
            //   }} loading={expLoading}>Export XLS</CustomButton>
            // }}
            pagination={true}
            filtersUrlData={allParams || {}}
            // filterEnable
            rangeDateFilter
            // dropdownFilter={true}
            disableSearch
            customFilter={() => (
              <AllFilters
                filtersArray={filtersArray}
                handleClick={handleFilter}
                handleClear={handleClear}
                hasDownloadExcelButton={true}
                handleDownloadExcel={getExportFile}
              />
            )}
          />
        </>
      </Grid>
    </>
  );
}

export default IvsOrderListing;
