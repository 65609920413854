import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { keywordItemsSelector } from '../../redux/keyword';
import { getVisibleHiddenKeywordListing } from '../../Services/keywordsApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import LinkHiddenKeyword from '../Modals/LinkHiddenKeyword';
import MatTable from '../Tables/MatTable';

const limit = 10;

const column = [
  {
    label: 'Id',
    id: 'aid',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'All Keyword',
    id: 'all_keyword',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", textAlign: "center"}}>{columnData.toString() == '1' ? 'Active' : 'Inactive'}</span>)
  },
];

function VisibleHiddenKeywordsList({vid}) {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { visibleHiddenKeywordList } = useSelector(keywordItemsSelector);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();

  const getListing = async (params={}) => {
    const payload = {
      visible_key_id: vid,
      page: String(1), 
      limit: limit,
      order_status: String(1),
      ...allParams,
      ...params
    }
    dispatch(getVisibleHiddenKeywordListing(payload));
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  useEffect(() => {
    if(visibleHiddenKeywordList?.items?.length >= 0){
      setTableListing(visibleHiddenKeywordList?.items)
    }
  }, [visibleHiddenKeywordList.items]);


  const actionBody = ({ data }) => {
    return (
      <>
        <div style={{display: "flex"}}>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '110px', bgcolor: 'buttonInfo.main' }}>USER DETIALS</CustomButton>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '135px' }}>VIEW FOLLOWUPS</CustomButton>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '150px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>CREATE FOLLOWUPS</CustomButton>
          {/* <CustomButton variant="raised" sx={{ padding: '1px 1px', marginRight: 1, fontSize: '12px', bgcolor: '#fff0', minWidth: 20 }}><Delete/></CustomButton> */}
        </div>
      </>
    );
  };

  const handleAdd = (value) => {
    setOpen(value);
  }

  // const actionHead = (data) => {
  //   return (<div style={{display: "flex"}}>
  //      hi
  //   </div>);
  // };

  return (
    <>
      <MatTable
        loading={visibleHiddenKeywordList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        // action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        // total={visibleHiddenKeywordList?.items?.count || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        toolBarButton={()=>{
          return <>
            <CustomButton onClick={()=>{handleAdd(true)}} color={'buttonPrimary'}  sx={(theme)=>({ padding: '10px 10px', marginRight: 1, 
                [theme.breakpoints.down("md")]: {
                  flex: 1,
                }
              })}>Link Hidden Keyword</CustomButton>
          </>
        }}
        filtersUrlData={allParams || {}}
        // pagination={true}
        // filterEnable
        // rangeDateFilter
      />
       {open && <LinkHiddenKeyword open={open} handleOpen={handleAdd} setPage={setPage} getListing={getListing} vid={vid} /> }
    </>
  );
}

export default VisibleHiddenKeywordsList;
