let AllConstant={
successMessages:{},
errorMessages:{},
formMessages:{
    fromRequired:'From Date is required.',
    toRequired:'To Date is required.',
    emailRequired:'Please enter Email',
    orderIdRequired:'Order ID is required',
    followUpStatusRequired:'Follow Up Status is required',
    followUpModeRequired:'Follow Up Mode is required',
    nextFollowUpDateRequired:'Next Followups Date is requried.',
    headingRequired:'Heading is required',
    assignRequired:'Assign is required',
    descriptionRequired:'Description is required',
    remarksText:'Remarks should be Text',
    createdByRequired:'Created By is required'
    
},
alertMessages:{
    bannerImageMessage:'You have already uploaded 5 images.',
    bannerDeleteconfirmMessage:'Are you sure you want to delete the Banner?'

}

}
export default AllConstant;