import { Delete } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { SearchBy, SearchUserBy, UserIdentityData } from '../../data/userData';
import { downloadBlobXLSFile, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { clearSingleUserDetails, userItemsSelector } from '../../redux/user';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { allUserListing, allUserListingExportFile, deleteUser } from '../../Services/userApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import ConfirmationModal from '../Modals/ConfirmationModal';
import MatTable from '../Tables/MatTable';
import CreateUserModal from '../Modals/CreateUserModal';
import { toast } from 'react-toastify';
import AllFilters from '../Order/AllFilters';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'ID',
    id: 'user_id',
       format: (columnData) => (
      <Link className='textDecoration-none link-text' to={`userDetail/${columnData}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
        >
          {columnData}
        </Typography>
      </Link>
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'First Name',
    id: 'first_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Last Name',
    id: 'last_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Identity',
    id: 'identity',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{columnData || '-'}</span>)
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word' }}>{columnData || '-'}</span>)
  },
  {
    label: 'Job Description',
    id: 'job_description',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Mobile',
    id: 'mobile',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, minWidth: 200, wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'User Status',
    id: 'status',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (<><Typography
      variant='span'
      sx={{
        ...(columnData == 1 ? { color: 'success.main' } : columnData == 2 ? { color: 'danger.main' } : columnData == 0 ? { color: 'info.main' } : {}),
        whiteSpace: "nowrap", display: 'inline-block'
      }}>
      {columnData == 0 ? 'Inactive' : columnData == 1 ? 'Active' : columnData == 2 ? 'Deleted' : 'N/A'}
    </Typography>
    <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>Joined At:</Typography> {formatDate(row?.created_at, 'dd-mm-yyyy')}</span>
    <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}><Typography variant='span' className='fontWeight600'>OTP Verified:</Typography> {row?.otp_verify==1?'Yes':'No'}</span>
    </>)
  },
  // {
  //   label: 'Joined At',
  //   id: 'created_at',
  //   format: (columnData) => (
  //   <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{formatDate(columnData, 'dd-mm-yyyy')}</span>
  //   )
  // },
];

function AllUserList() {
  const [tableListing, setTableListing] = useState([])
  const [expLoading, setExpLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, allUserList,singleDownloadImagelistDetail } = useSelector(userItemsSelector);
  const [confirmDeleteCategory, setConfirmDeleteCategory] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [clearSelectedCheckbox, setClearSelectedCheckbox] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false)
  const [deleteUserData, setDeleteUserData] = useState({});
 
  const [filtersArray, setFiltersArray] = useState([

    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },

    {
      label: 'Identity',
      id: 'identity',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: UserIdentityData,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: SearchUserBy,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },


  ]);

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // user_type: "2",
      ...allParams,
      ...params
    }
    if (payload?.start_date) {
      payload['start_date'] = formatDate(payload?.start_date, 'yyyy-mm-dd') + "  00:00:00"
    }
    if (payload?.end_date) {
      payload['end_date'] = formatDate(payload?.end_date, 'yyyy-mm-dd') + "  23:59:59"
    }
    dispatch(allUserListing(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[3].isVisible = true;
      newState[4].isVisible = true;

      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      newState[3].value = '';
      newState[4].value = '';
      return newState;
    });
  };

  //making data format for table input
  useEffect(() => {
    if (allUserList?.items?.users?.length >= 0) {
      setTableListing(allUserList?.items?.users)
    }
  }, [allUserList.items]);

  useEffect(()=>{
    dispatch(clearSingleUserDetails())
  },[])

  const handleAddUserModal = (value = false) => {
    setAddUserModal(value)
  }

  const handleBulkUpdate = async (data, resetState) => {

    let stateSelected = allUserList.items.users.filter(
      (item) => item.orders_count == false,
    );
    let userSelected = stateSelected.map(
      (item) => item.user_id,
    );
    const intersection = userSelected.filter(element => data.includes(element));
    handleCofirmModalOpen(true)
    setDeleteUserData(intersection)
  }

  const handleClearSelectedCheckbox = (value = false) => {
    setClearSelectedCheckbox(value || false)
  }

  const getExportFile = async (params = {},) => {
    try {
      let payload = {
        ...allParams,
        ...params
      }
      delete payload.page
      delete payload.limit
      setExpLoading(true)
      if (payload?.start_date) {
        payload['start_date'] = formatDate(payload?.start_date, 'yyyy-mm-dd') + "  00:00:00"
      }
      if (payload?.end_date) {
        payload['end_date'] = formatDate(payload?.end_date, 'yyyy-mm-dd') + "  23:59:59"
      }
      let resp = await allUserListingExportFile(payload)
      if (resp) {
        downloadBlobXLSFile(resp?.data, `UserList`)
      } else {
        toast.error('Something went wrong.')
      }
      setExpLoading(false)
    } catch (e) {
      console.log(e)
      setExpLoading(false)
    }
  }

  const actionBody = (data) => {
    return (
      <>
        {data?.orders_count == false ?
          <div style={{ display: "flex" }}>
            <CustomButton className="noMinWidth buttonDense" onClick={() => {
              handleCofirmModalOpen(true)
              setDeleteUserData(data?.user_id)
            }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete /></CustomButton>
                    </div>
          : null}
      </>
    );

  };


  const handleCofirmModalOpen = (value) => {
    setConfirmDeleteCategory(value);
  }

  const handleConfirmDeleteCategory = async (value) => {
    if (value && deleteUserData) {
      const resp = await deleteUser({ user_id: deleteUserData });
      if (resp) {
        getListing({ page: '1' })
        setPage(1)
        handleClearSelectedCheckbox(true)
      }
    } else {
      setDeleteUserData(null)
    }
  }

  return (
    <>
      <MatTable
        loading={allUserList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
          onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date', 'identity'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date', 'identity'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
         action={actionBody}
        // actionHead={actionHead}
        page={page}
         perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={allUserList?.items?.totalCount || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        // toolBarButton={() => {

        // }}
        columnWiseData={[
          {
            id: 'user_id',
            value: handleBulkUpdate,
          }
        ]}

        checkboxEnable={true}
        // customCheckboxEnable={true}
        customCheckboxDataKey={'user_id'}
        // customCheckboxStatusKey={'paid'} //add if need to show checkbox on the condition basis in row
        onTableToolbarRightClick={handleBulkUpdate}
        clearSelectedCheckbox={clearSelectedCheckbox}
        handleClearSelectedCheckbox={handleClearSelectedCheckbox}// requred to clear filter and refresh state after api call 
        onTableToolbarRightSection={<CustomButton sx={(theme) => ({
          fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
          [theme.breakpoints.down("md")]: {
            flex: 1,
          },
        })}
        >Bulk Delete</CustomButton>}
        pagination={true}
        filtersUrlData={allParams || {}}
        // filterEnable
        rangeDateFilter
        // dropdownFilter={true}
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            extraButton={() => {
              return (
                <>
                  <CustomButton sx={(theme) => ({
                    fontSize: '12px', padding: '12px 12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', marginTop: 1, color: 'buttonSecondary.contrastText',
                    [theme.breakpoints.down("md")]: {
                      flex: 1,
                    }
                  })} onClick={() => {
                    getExportFile()
                  }} loading={expLoading}>Export XLS</CustomButton>
                  <CustomButton
                    onClick={() => {
                      // handleAdd(true);
                      handleAddUserModal(true)
                    }}
                    color={'buttonPrimary'}
                    sx={(theme) => ({
                      padding: '12px 12px', fontSize: '12px', marginRight: 1, marginTop: 1,
                      [theme.breakpoints.down("md")]: {
                        flex: 1,
                      }
                    })}
                  >
                    Add User
                  </CustomButton>
                </>
              )
            }}
          />
        )}
      />
      {confirmDeleteCategory && <ConfirmationModal open={confirmDeleteCategory} handleConfirm={handleConfirmDeleteCategory} setOpen={handleCofirmModalOpen} />}
      {/* {addUser && <AddUser/>} */}
      {addUserModal && <CreateUserModal open={addUserModal} handleOpen={handleAddUserModal} handleLast={getListing} />}
    </>
  );
}

export default AllUserList;
