import React, { useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import { InputAdornment, MenuItem } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
// import Pagination from './pagination/Pagination'
// import getDevice from '../../styles/devices'
// import DataGridMobile from '../logs/DataGridMobile'
import SortIcon from '../Icons/SortIcon'
import SortAsc from '../Icons/SortAsc'
import CustomInput from '../CommonComp/CustomInput/CustomInput'
import { Search } from '@material-ui/icons'
import { ActiveFilter, InactiveFilter } from '../../Asset/svg'
import CustomIconButtons from '../CommonComp/Buttons/IconButtons'
import Pagination from './Pagination'

import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import { formatDate, validDate } from '../../lib/helper'
import CustomButton from '../CommonComp/Buttons/CustomButton'
// import { alpha, Tooltip } from '@material-ui/core'
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import { alpha } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import CustomLoading from '../CommonComp/CustomLoading/CustomLoading'
import { toast } from 'react-toastify'

// import dateRangeOptions from '../data/dateRangeOptions'
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}
function stableSort(array, comparator) {
    const stabilizedThis = array && array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
}
function EnhancedTableHead(props) {
    const {
        classes,
        order,
        orderBy,
        onRequestSort,
        columns,
        action,
        actionLast,
        openFilter,
        handleFilter,
        actionHead,
        handleSearch,
        onSelectAllClick, numSelected, rowCount, checkboxEnable
    } = props
    const createSortHandler = property => event => {
        onRequestSort(event, property)
    }
    return (
        <TableHead style={{ background: '#F8F9FD' }}>
            <TableRow>
                {checkboxEnable && <TableCell padding="checkbox" className={classes.tableHeader}
                    style={{ color: '#000', padding: '13px 8px', whiteSpace: "nowrap", verticalAlign: 'top' }}
                >
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'checkbox',
                        }}
                    />
                </TableCell>}
                {action && actionLast && <TableCell
                    key="action"
                    align="left"
                    padding="normal"
                    style={{
                        color: '#000', padding: '13px 8px', whiteSpace: "nowrap", verticalAlign: 'top'
                        //  right: 0, position: "sticky", zIndex: 900
                    }}
                    className={classes.tableHeader}
                >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {actionHead ? actionHead() : "Actions"}
                    </div>
                </TableCell>}
                {columns.map(headCell => (
                    <TableCell
                        width={`${100 / (columns.length)}%`}
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        style={{ color: '#000', padding: '13px 8px', whiteSpace: "nowrap", verticalAlign: 'top' }}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableHeader}
                    >
                        {/* <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={orderBy === headCell.id ? (order === 'asc' ? SortIcon : SortAsc) : () => (<></>)}
                        > */}
                        {headCell?.nextLine ? headCell?.label?.length?.length == 1 ? headCell?.label : headCell?.label?.split(" ")?.map((item) => <>{item}<br /></>) : headCell.label}
                        {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                        ) : null}
                        {/* </TableSortLabel> */}
                    </TableCell>
                ))}
                {action && !actionLast && <TableCell
                    key="action"
                    align="left"
                    padding="normal"
                    style={{
                        color: '#000', padding: '13px 8px', whiteSpace: "nowrap", verticalAlign: 'top'
                        //  right: 0, position: "sticky", zIndex: 900
                    }}
                    className={classes.tableHeader}
                >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {actionHead ? actionHead() : "Actions"}
                    </div>
                </TableCell>}
            </TableRow>
        </TableHead>
    )
}
EnhancedTableHead.defaultProps = {
    action: null,
    actionHead: null,
    handleSearch: () => { },
    checkboxEnable: false,
    actionLast:false
}
const useToolbarStyles = makeStyles(theme => ({
    root: {
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: '#ffffff',
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 50%',
    },
}))
function EnhancedTableToolbar({
    requestSearch,
    cancelSearch,
    searchTerm,
    onApplyFilter,
    handleFilterPopup,
    openFilter,
    handleFilter,
    actionHead,
    handleSearch,
    numSelected,
    disableSearch,
    filterEnable,
    toolBarButton,
    customFilter,
    loading,
    dataLength,
    onTableToolbarRightClick,
    onTableToolbarRightSection,
    filtersUrlData
}) {
    const classes = useToolbarStyles()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loader, setLoader] = React.useState(null);
    const [search, setSearch] = React.useState(filtersUrlData?.search || '');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (value) => {
        if (value) {
            onApplyFilter({ dateRange: value })
        }
        setAnchorEl(null);
    };

    // useEffect(() => {
    //     try{
    //         let cleanup = () =>{}
    //         if(dataLength && loading){
    //             setLoader(dataLength && loading)
    //         }else{
    //             cleanup = setTimeout(()=>{
    //                 setLoader(dataLength && loading)
    //             },[500]) 
    //         }
    //         return () => cleanup
    //     }
    //     catch(e){}
    // }, [dataLength, loading]);

    useEffect(() => {
        setSearch(filtersUrlData?.search || "")
    }, [filtersUrlData])
    return (<>
        <Toolbar
            className={clsx(classes.root)}
            // style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '16px', marginBottom: 16 }}
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                py: 1.5,
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                flexWrap: "wrap",
                ...(!filterEnable && !disableSearch ? { pt: 2 } : {})
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ marginRight: 'auto' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : <Box sx={{ display: "flex", flex: 1 }}>
                {filterEnable && <CustomIconButtons onClick={(e) => {
                    handleFilter(e)
                }} sx={{ padding: '8px 15px', marginRight: 3, marginLeft: 0, bgcolor: 'iconButtonPrimary.light' }}>
                    {openFilter ? <ActiveFilter /> : <InactiveFilter />}
                </CustomIconButtons>}
                {/* <PositionedPopper/> */}
                {!disableSearch && <CustomInput
                    variant="outlined"
                    fullWidth
                    placeholder="Search"
                    sx={(theme) => ({
                        minWidth: 195,
                        maxWidth: 300,
                        width: "100%",
                        bgcolor: '#E5F5FF',
                        margin: 0,
                        borderRadius: theme.shape.borderRadius(4),
                        "& fieldset": { border: 'none' },
                        [theme.breakpoints.down("md")]: {
                            flex: 1,
                            maxWidth: '100%',
                        }
                    })}
                    id="searchOnTable"
                    value={search}
                    onKeyDown={(e) => {
                        if (e.key == 'Enter') {
                            handleSearch(e?.target?.value?.trim())
                            setSearch(e?.target?.value?.trim())
                        }
                    }}
                    onChange={(e) => {
                        if (e.target.value == "") {
                            handleSearch('')
                            setSearch('')
                        } else {
                            setSearch(e?.target?.value)
                        }
                    }}
                    // InputLabelProps={{ shrink: false }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position={"end"} style={{ cursor: "pointer", marginTop: '6px' }}>
                                <span onClick={() => {
                                    let element = document.getElementById('searchOnTable')
                                    if (element) {
                                        handleSearch(element?.value?.trim())
                                    }
                                }}><Search /></span>
                            </InputAdornment>
                        )
                    }}
                // onChange={(e)=>{
                //     handleSearch(e.target.value)
                // }}
                />}
                {customFilter && customFilter()}
            </Box>}
            {numSelected > 0 ? (
                <Tooltip title={'Action'}>
                    <span onClick={onTableToolbarRightClick}>
                        {/* <IconButton >
                            <DeleteIcon />
                        </IconButton> */}
                        {onTableToolbarRightSection}
                    </span>
                </Tooltip>
            ) : null}
            {(toolBarButton && numSelected == 0) && <Box sx={(theme) => ({
                display: 'flex', flex: 1, flexWrap: "wrap", justifyContent: "flex-end", alignItems: "center", mt: { md: 0, xs: 1 },
                [theme.breakpoints.down("md")]: {
                    flexBasis: "100%",
                }
            })}>
                {toolBarButton()}
            </Box>}

        </Toolbar>
        {(dataLength && loading) ? <div style={{
            display: 'flex',
            justifyContent: 'center',
            background: '#d0d0d0',
            padding: 15,
            position: 'absolute',
            width: '100%',
            zIndex: 12,
        }}>
            <Typography variant='h6'>Loading...</Typography>
        </div> : null}
    </>
    )
}
EnhancedTableToolbar.defaultProps = {
    handleFilterPopup: null,
    onApplyFilter: null,
    searchTerm: '',
    selectedFiltersText: null,
    toolBarButton: null,
    disableSearch: false,
    filterEnable: false,
    customFilter: null,
    loading: false,
    dataLength: null,
    onTableToolbarRightClick: () => { },
    onTableToolbarRightSection: <></>,
    filtersUrlData: {}
}
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginBottom: "20px"
    },
    paper: {
        width: '100%',
        padding: '0px',
        // marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
        color: '#000'
    },
    tableRow: {
        "&:hover": {
            background: "#F3FAFE !important"
        }
    },
    tableCell: {
        borderBottom: "1px solid #EDEFEF !important",
    },
    tableHeader: {
        background: '#FFF',
        borderTop: "1px solid #EDEFEF !important",
        "&:hover": {
            background: "#EFF1F7 !important"
        }

    }
}))
function MatTable({
    columns,
    data,
    loading,
    requestSearch,
    cancelSearch,
    searchTerm,
    action,
    actionHead,
    actionLast,
    defaultSort,
    defaultSortOrder,
    handleFilterPopup,
    onApplyFilter,
    legendComponent,
    selectedFiltersText,
    page,
    perPage,
    showPageLimit,
    pageLimitArray,
    setPage,
    handlePageChange,
    handleChangePageLimit,
    total,
    handleShowMessage,
    logType,
    handleSearch,
    checkboxEnable,
    customCheckboxEnable,
    customCheckboxStatusKey,
    customCheckboxDataKey,
    customLabel,
    pagination,
    toolbarEnable,
    disableSearch,
    rangeDateFilter,
    filterEnable,
    singleDateFilter,
    dropdownFilter,
    dropdownFilterOptions,
    formatCallback,
    columnWiseData,
    tableHeight,
    tableMaxHeight,
    errors,
    tdStyle,
    dateValidation,
    toolBarButton,
    customFilter,
    onTableToolbarRightClick,
    onTableToolbarRightSection,
    selectFilterArray,
    intialData,
    filtersUrlData,
    clearSelectedCheckbox,
    handleClearSelectedCheckbox
}) {
    const classes = useStyles()
    const [order, setOrder] = React.useState(defaultSortOrder || 'asc')
    const [orderBy, setOrderBy] = React.useState(defaultSort)
    const [selected, setSelected] = React.useState([]);
    const [selectedArr, setSelectedArr] = React.useState([]);
    const [dense, setDense] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [fromDate, setFromDate] = React.useState(filtersUrlData?.start_date || "");
    const [toDate, setToDate] = React.useState(filtersUrlData?.end_date || "");
    // const [relatedTo, setRelatedTo] = React.useState("");
    const [selectFilter, setSelectFilter] = React.useState({});

    const { isMobile } = false

    useEffect(() => {
        setOrder(defaultSortOrder)
    }, [defaultSortOrder])

    useEffect(() => {
        if (selectFilterArray?.length) {
            let initialState = {};
            selectFilterArray?.forEach((ele) => {
                if (!ele?.disable) {
                    initialState[ele.name] = filtersUrlData[ele.name] || ele.value || '';
                }
            })
            setSelectFilter(initialState)
        }
        setFromDate(filtersUrlData?.start_date)
        setToDate(filtersUrlData?.end_date)
    }, [filtersUrlData])

    const handleSelectFilter = (key, data) => {
        setSelectFilter({
            ...selectFilter,
            [key]: data
        })
    }
    const clearSelectFilter = () => {
        let clearedData = selectFilter
        for (const key in clearedData) {
            if (Object.hasOwnProperty.call(clearedData, key)) {
                // clearedData[key] = ""  //changed to delete because giving some error in clear filter functionality in ivs order listing
                delete clearedData[key]
            }
        }

        setSelectFilter(clearedData)
        return clearedData
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }

    const handleFilterClick = (e, value = false, data) => {
        setAnchorEl(e.currentTarget);
        setOpen(value || !open);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            if (customCheckboxEnable && customCheckboxStatusKey && customCheckboxDataKey) {
                //?row[customCheckboxDataKey]
                const newSelecteds = data.reduce((acc, n) => {
                    if (!n[customCheckboxStatusKey])
                        return [...acc, n[customCheckboxDataKey]]
                    else return acc
                }, [])
                setSelected(newSelecteds);
            }
            else {
                if (customCheckboxDataKey) {
                    const newSelecteds = data.map(n => n[customCheckboxDataKey])
                    setSelected(newSelecteds);
                } else {
                    const newSelecteds = data.map(n => n.order_id)
                    setSelected(newSelecteds)
                }
            }
            return
        }
        setSelected([])
    }

    useEffect(() => {
        if (clearSelectedCheckbox) {
            setSelected([])
            handleClearSelectedCheckbox(false)
        }
    }, [clearSelectedCheckbox])

    const handleClick = (event, id, row) => {
        const selectedIndex = selected.indexOf(id)
        let newSelected = [];
        let newSelectedArr = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
            newSelectedArr = newSelectedArr.concat(selectedArr, row);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            newSelectedArr = newSelectedArr.concat(selectedArr.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            newSelectedArr = newSelectedArr.concat(selectedArr.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
            newSelectedArr = newSelectedArr.concat(
                selectedArr.slice(0, selectedIndex),
                selectedArr.slice(selectedIndex + 1)
            )

        }
        setSelected(newSelected);
        setSelectedArr(newSelectedArr);
    }

    const handleChangePage = (newPage) => {
        setPage(newPage)
        handlePageChange(newPage)
    }
    const isSelected = id => selected.indexOf(id) !== -1
    const emptyRows =
        perPage - Math.min(perPage, data.length - (page - 1) * perPage)
    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={0} sx={{
                position: "relative"
            }}>
                {toolbarEnable && <EnhancedTableToolbar
                    // searchTerm={searchTerm}
                    // requestSearch={requestSearch}
                    // cancelSearch={cancelSearch}
                    // onApplyFilter={onApplyFilter}
                    // selectedFiltersText={selectedFiltersText}
                    // handleFilterPopup={handleFilterPopup}
                    openFilter={open}
                    handleFilter={handleFilterClick}
                    handleSearch={handleSearch}
                    numSelected={selected?.length}
                    disableSearch={disableSearch}
                    filterEnable={filterEnable}
                    toolBarButton={toolBarButton}
                    customFilter={customFilter}
                    loading={loading}
                    dataLength={data.length || 0}
                    onTableToolbarRightClick={() => { onTableToolbarRightClick(selected, setSelected, selectedArr, setSelectedArr) }}
                    onTableToolbarRightSection={onTableToolbarRightSection}
                    filtersUrlData={filtersUrlData}
                />}
                {legendComponent && legendComponent}
                {!isMobile ? <TableContainer sx={{ height: tableHeight || 'auto', maxHeight: tableMaxHeight }}>
                    <Table
                        stickyHeader
                        aria-label="sticky table"
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={customCheckboxEnable ? (data?.length - data?.filter((item) => item[customCheckboxStatusKey])?.length) : data.length}
                            columns={columns}
                            action={action}
                            actionHead={actionHead}
                            actionLast={actionLast}
                            // openFilter={open}
                            // handleFilter={handleFilterClick}
                            // handleSearch={handleSearch}
                            numSelected={selected?.length}
                            onSelectAllClick={handleSelectAllClick}
                            checkboxEnable={checkboxEnable}
                            filterEnable={filterEnable}
                            filtersUrlData={filtersUrlData}
                        />
                        <TableBody>
                            {stableSort(data && data, getComparator(order, orderBy === 'transactionTime' ? 'transactionDate' : orderBy))
                                .map((row, rowIndex) => {
                                    const isItemSelected = isSelected(customCheckboxEnable && customCheckboxStatusKey && customCheckboxDataKey ? row[customCheckboxDataKey] : customCheckboxDataKey ? row[customCheckboxDataKey] : row.order_id)
                                    const labelId = `enhanced-table-checkbox-${rowIndex}`
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            className={classes.tableRow}
                                            key={`row${rowIndex + 1}`}
                                            selected={isItemSelected}
                                            columns={columns}
                                        >
                                            {checkboxEnable && <TableCell padding="checkbox">
                                                {customCheckboxEnable && customCheckboxStatusKey && customCheckboxDataKey ? !row[customCheckboxStatusKey] ? <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    onClick={(event) => { customCheckboxEnable && handleClick(event, row[customCheckboxDataKey], row) }}
                                                /> : <></> : <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                    onClick={(event) => { checkboxEnable ? customCheckboxDataKey ? handleClick(event, row[customCheckboxDataKey], row) : handleClick(event, row.order_id) : null }}
                                                />}
                                            </TableCell>}

                                            {action && actionLast &&
                                                (
                                                    <TableCell
                                                        className="row-action"
                                                        align="center"
                                                        style={{
                                                            padding: '10px 8px',
                                                            // position: 'sticky', right: 0, zIndex: 800,
                                                            background: 'white',
                                                            ...tdStyle
                                                        }}
                                                    >
                                                        {action(row, rowIndex, formatCallback)}
                                                    </TableCell>
                                                )}
                                            {columns &&
                                                columns.map(({ id, onClick, format, capitalize }) => {
                                                    return (
                                                        <TableCell
                                                            key={id}
                                                            onClick={
                                                                onClick
                                                                    ? () => onClick(row)
                                                                    : () => false
                                                            }
                                                            style={{
                                                                cursor: onClick ? 'pointer' : 'inherit',
                                                                textTransform: capitalize ? 'capitalize' : 'none',
                                                                color: '#000',
                                                                padding: '13px 8px',
                                                                ...tdStyle
                                                            }}
                                                            align="left"
                                                            className={classes.tableCell}
                                                        >
                                                            {format ? columnWiseData?.length ?
                                                                format(row[id], rowIndex, formatCallback, columnWiseData?.filter((item) => id == item.id)?.[0]?.value, errors, row) :
                                                                format(row[id], rowIndex, formatCallback, [], errors, row) : row[id]}
                                                        </TableCell>
                                                    )
                                                })}
                                            {action && ! actionLast &&
                                                (
                                                    <TableCell
                                                        className="row-action"
                                                        align="center"
                                                        style={{
                                                            padding: '10px 8px',
                                                            // position: 'sticky', right: 0, zIndex: 800,
                                                            background: 'white',
                                                            ...tdStyle
                                                        }}
                                                    >
                                                        {action(row, rowIndex, formatCallback)}
                                                    </TableCell>
                                                )}
                                        </TableRow>
                                    )
                                })}
                            {data.length === 0 && (
                                <TableRow style={{ height: (dense ? 5 : 10) * (total === 0 ? 10 : emptyRows) || 'auto' }}>
                                    {/* <TableRow style={{ height: (dense ? 5 : 10) * emptyRows || 'auto' }}> */}
                                    <TableCell colSpan={action ? columns.length + 1 : columns.length} align="center" >
                                        <CustomLoading loading={loading} />
                                    </TableCell>
                                </TableRow>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer> :
                    <></>}
                {pagination && <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={total}
                    siblingCount={0}
                    showPageLimit={showPageLimit}
                    pageLimitArray={pageLimitArray}
                    pageSize={perPage}
                    onPageChange={handleChangePage}
                    handleChangePageLimit={handleChangePageLimit}
                    loading={loading}
                />}
            </Paper>
            {toolbarEnable && filterEnable ? <Popper open={open} anchorEl={anchorEl} placement={"bottom-start"} transition sx={{
                zIndex: 16000
            }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper elevation={8} sx={(theme) => ({
                            borderRadius: theme.shape.borderRadius(4),
                            padding: theme.spacing(2),
                            width: "100%",
                            minWidth: "323px",
                            maxWidth: '370px'
                        })}>
                            <Box sx={{ display: "flex", mb: 1 }}>
                                <Typography variant='h3' sx={{ flex: "1" }}>Filter</Typography>
                                <span onClick={() => { setOpen(false) }} style={{ cursor: "pointer" }}><CloseIcon /></span>
                            </Box>
                            <Box>
                                <Typography sx={(theme) => ({ flex: "1", color: theme.palette.primary.contrastText, mb: 2 })} paragraph>Search the details according to you</Typography>
                                {rangeDateFilter && <>
                                    <CustomInput
                                        variant="outlined"
                                        fullWidth
                                        placeholder="from date"
                                        sx={(theme) => ({
                                            // bgcolor: '#E5F5FF',
                                            "& fieldset": {
                                                borderRadius: theme.shape.borderRadius(4),
                                            },
                                            mb: 3
                                        })}
                                        label="From"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        // InputProps={{
                                        //     endAdornment: (
                                        //     <InputAdornment position={"end"}>
                                        //         <Search />
                                        //     </InputAdornment>
                                        //     )
                                        // }}
                                        value={formatDate(fromDate, "yyyy-mm-dd")}
                                        onChange={(e) => {
                                            if (!validDate(e.target.value, new Date(), true)) {
                                                setFromDate(e.target.value)
                                                if (!validDate(toDate, e.target.value, true)) {
                                                    setToDate(formatDate(e.target.value, "yyyy-mm-dd"))
                                                }
                                            }
                                            else if (dateValidation) {
                                                setFromDate(e.target.value)
                                                if (!validDate(toDate, e.target.value, true)) {
                                                    setToDate(formatDate(e.target.value, "yyyy-mm-dd"))
                                                }
                                            }
                                            else {
                                                toast.error("Date should not be greater than today's date.")
                                            }
                                        }}
                                    />
                                    <CustomInput
                                        variant="outlined"
                                        fullWidth
                                        placeholder="to date"
                                        sx={(theme) => ({
                                            // bgcolor: '#E5F5FF',
                                            "& fieldset": {
                                                borderRadius: theme.shape.borderRadius(4),
                                            },
                                            mb: 2
                                        })}
                                        label="To"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        // InputProps={{
                                        //     endAdornment: (
                                        //     <InputAdornment position={"end"}>
                                        //         <Search />
                                        //     </InputAdornment>
                                        //     )
                                        // }}
                                        value={formatDate(toDate, "yyyy-mm-dd")}
                                        onChange={(e) => {
                                            if (!validDate(e.target.value, new Date(), true)) {
                                                if (validDate(e.target.value, fromDate, true)) {
                                                    setToDate(formatDate(e.target.value, "yyyy-mm-dd"))
                                                } else {
                                                    toast.error("To date should be greater than from date.")
                                                }
                                            }
                                            else if (dateValidation) {
                                                if (validDate(e.target.value, fromDate, true)) {
                                                    setToDate(formatDate(e.target.value, "yyyy-mm-dd"))
                                                } else {
                                                    toast.error("To date should be greater than from date.")
                                                }
                                            }
                                            else {
                                                toast.error("Date should not be greater than today's date.")
                                            }
                                        }}
                                    />
                                </>}
                                {singleDateFilter && <>
                                    <CustomInput
                                        variant="outlined"
                                        fullWidth
                                        placeholder="from date"
                                        sx={(theme) => ({
                                            // bgcolor: '#E5F5FF',
                                            "& fieldset": {
                                                borderRadius: theme.shape.borderRadius(4),
                                            },
                                            mb: 3
                                        })}
                                        label="Select Date"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        // InputProps={{
                                        //     endAdornment: (
                                        //     <InputAdornment position={"end"}>
                                        //         <Search />
                                        //     </InputAdornment>
                                        //     )
                                        // }}
                                        value={fromDate}
                                        onChange={(e) => {
                                            if (!validDate(e.target.value, new Date())) {
                                                setFromDate(e.target.value)
                                            } else {
                                                toast.error("Date should not be greater than today's date.")
                                            }
                                        }}
                                    />
                                </>}
                                {/* {dropdownFilter && dropdownFilterOptions.length ? <>
                                <CustomInput 
                                    variant="outlined"
                                    fullWidth
                                    sx={(theme)=> ({
                                        // bgcolor: '#E5F5FF',
                                        "& fieldset": {                    
                                            borderRadius: theme.shape.borderRadius(4),
                                        },
                                        mb: 3
                                    })}
                                    select
                                    InputLabelProps={{ shrink: true }}
                                    label="Related To"
                                    placeholder="Related To"
                                    value={relatedTo}
                                    InputProps={{
                                        classes: {
                                            notchedOutline: 'rounded',
                                        },
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    // InputProps={{
                                    //     endAdornment: (
                                    //     <InputAdornment position={"end"}>
                                    //         <Search />
                                    //     </InputAdornment>
                                    //     )
                                    // }}
                                    onChange={(e)=>{
                                        setRelatedTo(e.target.value)
                                    }}
                                >
                                    <option key={"Select Topic"} value={""}>
                                        Select Topic
                                    </option>
                                    {dropdownFilterOptions?.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </CustomInput>
                                </>:null} */}
                                {dropdownFilter && selectFilterArray.length ?
                                    selectFilterArray?.filter((item) => !item?.disable)?.map((item, index) => {
                                        return <React.Fragment key={index.toString()}>
                                            <CustomInput
                                                variant="outlined"
                                                fullWidth
                                                sx={(theme) => ({
                                                    // bgcolor: '#E5F5FF',
                                                    "& fieldset": {
                                                        borderRadius: theme.shape.borderRadius(4),
                                                    },
                                                    mb: 3
                                                })}
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                label={item.label}
                                                placeholder={item.placeholder}
                                                value={selectFilter[item?.name]}
                                                InputProps={{
                                                    classes: {
                                                        notchedOutline: 'rounded',
                                                    },
                                                }}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                // InputProps={{
                                                //     endAdornment: (
                                                //     <InputAdornment position={"end"}>
                                                //         <Search />
                                                //     </InputAdornment>
                                                //     )
                                                // }}
                                                onChange={(e) => {
                                                    handleSelectFilter(item?.name, e.target.value)
                                                    // setSelectFilter(e.target.value)
                                                }}
                                            >
                                                {item?.options?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </CustomInput>
                                        </React.Fragment>
                                    }) : null}
                                <div>
                                    <CustomButton
                                        sx={{ marginRight: 1, bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}
                                        onClick={() => {
                                            if (rangeDateFilter && fromDate && toDate || singleDateFilter && fromDate || dropdownFilter && selectFilterArray?.length) {
                                                onApplyFilter({
                                                    ...(fromDate ? { start_date: (rangeDateFilter || singleDateFilter ? formatDate(fromDate, "yyyy-mm-dd") : "") + " 00:00:00" } : {}),
                                                    ...(toDate ? { end_date: (rangeDateFilter ? formatDate(toDate, "yyyy-mm-dd") : "") + " 23:59:59" } : {}),
                                                    // ...(dropdownFilter && relatedTo ? {related_to: dropdownFilter ? relatedTo : ""} : {}),
                                                    ...(dropdownFilter && Object.keys(selectFilter)?.length ? selectFilter : {})
                                                })
                                                setOpen(false);
                                            }
                                        }}
                                    >Apply Filter</CustomButton>
                                    <CustomButton
                                        sx={{ marginRight: 1, bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}
                                        onClick={() => {
                                            setFromDate("");
                                            setToDate("");
                                            // setRelatedTo("");
                                            setOpen(false);
                                            let clearData = clearSelectFilter()
                                            onApplyFilter(clearData);
                                        }}
                                    >Clear Filter</CustomButton>
                                </div>
                            </Box>
                        </Paper>
                    </Fade>
                )}
            </Popper> : null}
        </div>
    )
}

MatTable.defaultProps = {
    action: null,
    handleFilterPopup: null,
    onApplyFilter: null,
    searchTerm: '',
    selectedFiltersText: null,
    handleChangePage: () => { },
    checkboxEnable: false,
    customCheckboxEnable: false,
    customCheckboxStatusKey: null,
    customCheckboxDataKey: null,
    customLabel: null,
    disableSearch: false,
    rangeDateFilter: false,
    singleDateFilter: false,
    filterEnable: false,
    dropdownFilter: false,
    dropdownFilterOptions: [],
    formatCallback: () => { },
    columnWiseData: [],
    tableHeight: 'auto',
    tableMaxHeight: '100%',
    errors: [],
    tdStyle: {},
    loading: false,
    toolBarButton: null,
    customFilter: null,
    onTableToolbarRightClick: () => { },
    onTableToolbarRightSection: <></>,
    selectFilterArray: [],
    intialData:false,
    filtersUrlData: {},
    setSelectedArr: [],
    dateValidation: false,
    clearSelectedCheckbox: false,
    handleClearSelectedCheckbox: () => { },
    actionLast:false,
}
export default React.memo(MatTable)