import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addSettingApi, updateSettingApi } from '../../Services/commonService';
import AppSettingsFields from '../AppSettings/AppSettingsFields';


export default function AddAppSettingDataModal({ open, handleOpen, extraData, parentKey, subParentKey, jsonParsing, getListing, headerTitle, validationAddSchema, keyLabelOne, keyLabelTwo}) {
  const [loading, setLoading] = React.useState(false);

  const handleDataSubmit = async (values, { resetForm }) => {
    try {
      if(parentKey){
        setLoading(true)      
        if(jsonParsing){
          let updatedValue = {...extraData?.data}
          updatedValue[values['key_name']] = values['key_value']
          let payload = {
            parent_key: parentKey,
            key_name: subParentKey,
            key_value: JSON.stringify(updatedValue)
          }      
          // Using update api here because adding new field here is going inside key_value as stringify json
          let resp = await updateSettingApi(payload)
          if(resp){
            await getListing()
            handleOpen(false);
            resetForm()
          }
        }else{
          let resp = await addSettingApi(values)
          if(resp){
            await getListing()
            handleOpen(false);
            resetForm()
          }
        }    
        setLoading(false)
      }
    } catch (e) {
      console.log(e, 'error');
      setLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: {
      parent_key: parentKey,
      key_name: '',
      key_value: ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      key_name: Yup.string().required('This field is required.'),
      ...validationAddSchema,
    }),
    onSubmit: handleDataSubmit,
  });
  
  return (
    <>
      <Dialog
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
				fullWidth
      >
        <DialogTitle variant="h3">{headerTitle}</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}  key={'key_name'}>
                <AppSettingsFields  
                  name={`key_name`}
                  label={keyLabelOne}
                  placeholder={`Enter Key Name`}
                  value={formik.values.key_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched?.key_name && Boolean(formik.errors?.key_name)
                  }
                  helperText={
                    formik.touched?.key_name && formik.errors?.key_name 
                  }
                />
              </Grid>
              <Grid item xs={12}  key={'key_value'}>
                <AppSettingsFields  
                  name={`key_value`}
                  label={keyLabelTwo}
                  placeholder={`Enter Key Value`}
                  value={formik.values.key_value}
                  onChange={formik.handleChange}
                  error={
                    formik.touched?.key_value && Boolean(formik.errors?.key_value)
                  }
                  helperText={
                    formik.touched?.key_value && formik.errors?.key_value 
                  }
                />
              </Grid>
              <Grid item xs={12} textAlign={'right'}>
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <CustomButton
                      color={'buttonPrimary'}
                      type="submit"
                      loading={loading}
                    >
                      Save
                    </CustomButton>
                    <CustomButton
                      onClick={() => {
                        handleOpen(false);
                        formik.resetForm()
                      }}
                      disabled={loading}
                    >
                      Close
                    </CustomButton>
                  </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

AddAppSettingDataModal.defaultProps = {
  open: false, 
  handleOpen: () => {}, 
  data: [],
  extraData: [],
  parentKey: "",
  subParentKey: "",
  jsonParsing: false,
  getListing: () => {},
  headerTitle: "Add AppSetting",
  getInitialDataFormikVlidationSchema: () => {},
  validationAddSchema: {},
  keyLabelOne: 'Key Name',
  keyLabelTwo: 'Key Value'
}