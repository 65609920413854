import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { errorMessage } from '../lib/helper';
import { STATUS_200, STATUS_300 } from '../data/constants';
import {
  setIrnListing,
  setIrnListingError,
  setLoading,
  setIrnListingLoading,
} from '../redux/irn';

export const irnListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setIrnListingLoading(true));
        const resp = await axios.get(
          `${ApiConfig.irnListing}?page=${payload?.page}&limit=${
            payload?.limit
          }&search=${payload?.search || ''}`,
        );
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setIrnListing(resp.data.data));
          dispatch(setIrnListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setIrnListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setIrnListingLoading(false));
      }
    };
  }
};
//markConfirmUnpaid

export const generateIrn = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.generateIrn}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const markConfirmInvoiceUnpaid = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.markConfirmUnpaid}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};

export const cancleIrn = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.deleteIrn}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp?.data?.message, {
        autoClose: 2500,
      });
      return true
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
    return false
  }
};