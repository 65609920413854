import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import {
  addCompanyMaster,
  companyGroupListingsNoRedux,
  companyListings,
} from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';
import {
  addSubscriptionPlan,
  editSubscriptionPlan,
  subscriptionListings,
} from '../../Services/SubscriptionApis';
import {
  editDownloadTimeData,
  editImagePrice,
} from '../../Services/cmsService';

const limit = DATA_LIMIT;

function EditTechnicalDownloadTime({
  data,
  open,
  handleOpen,
  setPage,
  handleLast,
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = await editDownloadTimeData(values);
    if (resp) {
      resetForm({
        download_time_id: '',
        file_size: '',
        compressed_file_size: '',
        time: '',
        isdn: '',
        broadband: '',
        time_one: '',
      });
      handleLast();
      handleOpen(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      download_time_id: data?.download_time_id || '',
      file_size: data?.file_size || '',
      compressed_file_size: data?.compressed_file_size || '',
      time: data?.time || '',
      isdn: data?.isdn || '',
      broadband: data?.broadband || '',
      time_one: data?.time_one || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      download_time_id: Yup.string().required('Download Time Id is required.'),
      file_size: Yup.string().required('File Size is required.'),
      compressed_file_size: Yup.string().required(
        'Compressed File Size is required.',
      ),
      time: Yup.string().required('Time is required.'),
      isdn: Yup.string().required('ISDN is required.'),
      broadband: Yup.string().required('Broadband is required.'),
      time_one: Yup.string().required('Time One is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Edit Download Time Data
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="file_size"
                  label="File Size"
                  placeholder="File Size"
                  value={formik.values.file_size}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.file_size && Boolean(formik.errors.file_size)
                  }
                  helperText={
                    formik.touched.file_size && formik.errors.file_size
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="compressed_file_size"
                  label="Compressed File Size"
                  placeholder="Compressed File Size"
                  value={formik.values.compressed_file_size}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.compressed_file_size &&
                    Boolean(formik.errors.compressed_file_size)
                  }
                  helperText={
                    formik.touched.compressed_file_size &&
                    formik.errors.compressed_file_size
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="time"
                  label="Time"
                  placeholder="Time"
                  value={formik.values.time}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.time && Boolean(formik.errors.time)}
                  helperText={formik.touched.time && formik.errors.time}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="isdn"
                  label="ISDN"
                  placeholder="ISDN"
                  value={formik.values.isdn}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.isdn && Boolean(formik.errors.isdn)}
                  helperText={formik.touched.isdn && formik.errors.isdn}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="broadband"
                  label="Broad Band"
                  placeholder="Broad Band"
                  value={formik.values.broadband}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.broadband && Boolean(formik.errors.broadband)
                  }
                  helperText={
                    formik.touched.broadband && formik.errors.broadband
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="time_one"
                  label="Time One"
                  placeholder="Time One"
                  value={formik.values.time_one}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.time_one && Boolean(formik.errors.time_one)
                  }
                  helperText={formik.touched.time_one && formik.errors.time_one}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    download_time_id: '',
                    file_size: '',
                    compressed_file_size: '',
                    time: '',
                    isdn: '',
                    broadband: '',
                    file_format: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
        {/* <DialogActions>
					<CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Upload</CustomButton>
					<CustomButton onClick={() => handleOpen(false)}>Close</CustomButton>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default EditTechnicalDownloadTime;
