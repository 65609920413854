import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { appSettingApi } from '../../Services/commonService';
import { commonItemsSelector } from '../../redux/common';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import { Edit } from '@mui/icons-material';
import EditAppSettingDataModal from '../Modals/EditAppSettingDataModal';
import AddAppSettingDataModal from '../Modals/AddAppSettingDataModal';

const getInitialDataFormikVlidationSchema = (arr, key)=>{
  let validations = {}
  if(arr.length){
    arr.forEach(element => {
      validations[element[key]] = Yup.string()
      .required('Emails are required')
      .test('valid-emails', 'Invalid email format', (value) => {
        if (!value) return true; // Allow empty value
        const emails = value.split(',');
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emails.every((email) => emailRegex.test(email.trim()));
      })
      // validations[element[key]] =  Yup.string().email(`Email is not valid`).required(`Please enter ${element[key]} email`)
    });
  }
  return validations
}

const validationAddSchema = {
  key_value: Yup.string()
    .required('Emails are required')
    .test('valid-emails', 'Invalid email format', (value) => {
      if (!value) return true; // Allow empty value
      const emails = value.split(',');
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emails.every((email) => emailRegex.test(email.trim()));
    })
}

const column = [
  {
    label: 'Department',
    id: 'key_name',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          wordBreak: 'break-word',
       }}
        onClick={() => {
          if (columnData >= 0 && columnData != null) {
            // customHistory.push(`image/soldImagesByGroupId/${columnData}`);
          }
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Email',
    id: 'key_value',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          wordBreak: 'break-word',
       }}
        onClick={() => {
          if (columnData >= 0 && columnData != null) {
            // customHistory.push(`image/soldImagesByGroupId/${columnData}`);
          }
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  }
];


function EmailSetup({data, parentKey}) {  
  const [tableListing, setTableListing] = useState([]);
  const [selectedField, setSelectedField] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false); 
  const [openAddModal, setOpenAddModal] = useState(false); 
  const { loading } = useSelector(commonItemsSelector);
  const dispatch = useDispatch();

  const getListing = () => {
    dispatch(appSettingApi());
  };

  //making data format for table input
  useEffect(() => {
    if (data?.length >= 0) {
      setTableListing(data || []);
    }
  }, [data]);

  const handleEditModal = (value = false) => {
    setOpenEditModal(value);
    if (!value) {
      setTimeout(() => {
        setSelectedField([]);
      }, 100);
    }
  };
  const handleAddModal = (value = false) => {
    setOpenAddModal(value);
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton toolTipTittle={'Edit Email'}
            onClick={()=>{
              handleEditModal(true);
              setSelectedField([data]);
            }}
            className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Edit/></CustomButton>
        </div>
      </>
    );
  }; 
 
  return (
    <>
      <CustomBox padding={0} sx={{minHeight: 200}}>
        <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap', pt: 2, px: 2}}>
          <Typography variant="h3">Email Setup</Typography>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "10px"}}>
            <CustomButton color={'buttonPrimary'}  onClick={()=>{
              handleAddModal(true);
              setSelectedField(null);
            }}>
              Add
            </CustomButton>
          </Box>
        </Box>
        <Divder spacing={1} />
        <MatTable
          loading={loading}
          showCheckbox={false}
          columns={column}
          data={tableListing}
          action={actionBody}
          disableSearch={true}
        />
        {openEditModal && <EditAppSettingDataModal open={openEditModal} handleOpen={handleEditModal} data={selectedField} parentKey={parentKey} getListing={getListing} headerTitle={'Edit Email Setup'} getInitialDataFormikVlidationSchema={getInitialDataFormikVlidationSchema}/>}
        {openAddModal && <AddAppSettingDataModal open={openAddModal} handleOpen={handleAddModal} parentKey={parentKey} getListing={getListing} headerTitle={'Add Email Setup'} validationAddSchema={validationAddSchema}
          keyLabelOne={'Department'} keyLabelTwo={'Email'}
        />}
      </CustomBox>
    </>
  );
}

export default EmailSetup;
