import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { LeftArrow } from '../../Asset/svg';
import { Typography, useTheme } from '@mui/material';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import OrderDetailsPage from '../../Components/Order/OrderDetailsPage';
import ExpiryEmailPage from '../../Components/Order/ExpiryEmailPage';
import ApiConfig from '../../config/ApiConfig';
import { decrypt } from '../../lib/helper';

function ExpiryEmail() {
  const theme = useTheme();
  const navigate = useNavigate()
  const [searchPrams] = useSearchParams();
  const [email, setEmail] = useState();
  const [orderId, setOrderId] = useState();
  const [planId, setPlanId] = useState();
  const [expiry, setExpiry] = useState();
  const [couponName, setCouponName] = useState();
  const [couponAmount, setcouponAmount] = useState();

  useEffect(()=>{
    let searchOrderId = decrypt(ApiConfig.REACT_APP_SALT + 'order_id', searchPrams.get('orderId'));
    let searchEmail = decrypt(ApiConfig.REACT_APP_SALT + 'email', searchPrams.get('email'));
    let searchPlanId = decrypt(ApiConfig.REACT_APP_SALT + 'plan_id', searchPrams.get('planId'));
    let searchExpiry = decrypt(ApiConfig.REACT_APP_SALT + 'expiry', searchPrams.get('expiry'));
    let searchCouponName = decrypt(ApiConfig.REACT_APP_SALT + 'coupon_name', searchPrams.get('coupon_name'));
    let searchAmount = decrypt(ApiConfig.REACT_APP_SALT + 'coupon_amount', searchPrams.get('coupon_amount'));
    setEmail(searchEmail)
    setOrderId(searchOrderId)
    setPlanId(searchPlanId)
    setExpiry(searchExpiry)
    setCouponName(searchCouponName)
    setcouponAmount(searchAmount)
  },[])

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox>
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}> 
            <Box sx={{
                display: "flex",
                alignItems: "center",
                cursor: 'pointer'
            }} onClick={()=>{
                navigate(-1)
            }}>
                <LeftArrow fill={theme.palette.textButton.main}/>
                <Typography variant='h5' sx={{
                    marginLeft: theme.spacing(1),
                    color: theme.palette.textButton.main
                }}>Back</Typography>
            </Box>
            <Typography variant='h5' sx={{marginLeft: (theme)=> theme.spacing(1)}}>Expiry Email</Typography>
            <Box sx={{ paddingLeft: 15 }}></Box>
            {/* <Typography */}
        </Box>
      </CustomBox>
      <Divder spacing={1} />
      <ExpiryEmailPage orderId={orderId} email={email} planId={planId}
        expiry={expiry} couponAmount={couponAmount} couponName={couponName}
       />
      <Divder spacing={1.2} />
    </Box>
  );
}

export default ExpiryEmail;
