export const subscriptionData = {
    tabs: [
      {
        label: 'All',
        id: 1,
      },
      {
        label: 'Standard',
        id: 2,
      },
      {
        label: 'Special',
        id: 3,
      }
    ],
  };
  