import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { getAccessMenuList, screensAssign, updateAdminUserPassword } from '../../Services/adminUsersApis';
import { MenuList, NewMenuList } from '../../data/SideMenu';
import CheckboxField from '../CommonComp/CustomInput/CheckboxField';

function AccessModal({ open, handleOpen, getListing, selectedData }) {
  const [loading, setLoading] = useState(false);
  const [menuList, setMenuList] = useState(NewMenuList?.map((a) => ({ ...a, checked: false })));
  useEffect(() => {
    handleAddedMenu();

  }, [selectedData])

  const handleAddedMenu = async () => {
    if (selectedData?.admin_id) {
      let payload = { admin_id: selectedData?.admin_id };
      let resp = await getAccessMenuList(payload);
      if (resp?.status === 200) {
        if (resp?.data?.length > 0) {
          let obj = resp?.data[0];
          let arrayList = [...menuList];
          arrayList = arrayList?.filter((a) => {
            if (a?.value === 'admin_users') {
              if (selectedData?.is_superadmin === '0') return true;
              else return false;
            }
            return true;
          })
          arrayList?.map((a) => {
            a['checked'] = obj[a?.value] === '1' ? true : false
          })
          arrayList
          setMenuList(arrayList);
        }
      }

    }

  }

  const handleConfirm = async () => {
    setLoading(true);
    let obj = {}
    obj['admin_id'] = selectedData?.admin_id;
    menuList?.filter((item) => {
      obj[item?.value] = item?.checked ? '1' : '0';
    })
    let resp = await screensAssign(obj)
    if (resp) {
      // resetForm();
      getListing()
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      admin_id: selectedData?.admin_id || "",

    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      admin_id: Yup.string().required('Admin id is required.'),
    }),
    onSubmit: handleConfirm,
  });

  const handleCheckBox = (e, val, index) => {
    let arr = [...menuList];
    arr[index]['checked'] = e.target.checked;
    setMenuList(arr);
  }

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Access Panel
        </DialogTitle>
        <DialogTitle variant="h3" padding={3}>
          {`${selectedData?.first_name ? selectedData?.first_name : ''}  ${selectedData?.last_name ? selectedData?.last_name : ''}`}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', my: 2 }} >
                  {menuList?.map((menu, index) => {
                    return <Box key={index.toString()} sx={{}}>
                      <CheckboxField
                        key={index}
                        labelPlacement={'end'}
                        subLableText={menu?.label}
                        value={menu?.checked}
                        handleChange={(e) => { handleCheckBox(e, menu?.label, index) }}
                      />
                      {/* <span >{img.label}</span> */}
                    </Box>
                  })}
                </Box>
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={() => {

                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AccessModal;
