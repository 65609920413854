import axios from "axios";
import { toast } from "react-toastify";
import ApiConfig from "../config/ApiConfig";
import { errorMessage } from "../lib/helper";
import {setContactQueries, setContactQueriesError, setContactQueriesLoading, setLoading } from "../redux/contactQueries";
import { STATUS_200, STATUS_300 } from '../data/constants';

export const getContactUsQueries = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try{
        dispatch(setContactQueriesLoading(true))
        const  resp = await axios.post(`${ApiConfig.contactUsQueries}`, payload);
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          dispatch(setContactQueries(resp.data.data));
          dispatch(setContactQueriesLoading(false))
        } else {
          throw resp
        }
      }catch(e){
        dispatch(setContactQueriesError({
          isError: true,
          message: errorMessage(e)
        }));
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setContactQueriesLoading(false))
      }
    };
  }
};