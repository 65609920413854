import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, DialogTitle, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomIconButtons from '../CommonComp/Buttons/IconButtons';
import CustomInput from '../CommonComp/CustomInput/CustomInput';

export default function ShareLinkModal({ open, onClose, exclusiveImageLink }) {
  const copyTextToClipBoard = () => {
    const input = document.getElementById('link-imageURL');
    input.focus();
    input.select();
    navigator.clipboard.writeText(exclusiveImageLink);
    toast.success('Copied Text', { autoClose: 2500 });
  };
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        className="filter-dialog-product-search"
        aria-labelledby="category-filter-dialog"
      >
        <DialogTitle
          id="category-filter-dialog"
          className="category-filter-dialog-main-title"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h3" sx={{ mb: { md: 0, xs: 1 } }}>
              Share Link
            </Typography>
            <CustomIconButtons
              onClick={onClose}
              sx={{ padding: '6px 7px', ml: 'auto', borderRadius: '50%' }}
              className="buttonSecondary"
            >
              <CloseIcon />
            </CustomIconButtons>
          </Box>
        </DialogTitle>
        <DialogContent className="category-filter-dialog-main-content">
          <Box component="div" sx={{ marginTop: '20px' }}>
            <TextField
              label="Link"
              className="textfield-research-form textfield-without-icon"
              fullWidth
              maxRows={1}
              id="link-imageURL"
              name="link-imageURL"
              multiline
              value={exclusiveImageLink}
            />
            <Box
              component="div"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <CustomButton
                color={'buttonPrimary'}
                onClick={() => {
                  copyTextToClipBoard();
                }}
              >
                Copy Downloaded Url
              </CustomButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
