import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import CreateProposal from './CreateProposal'

function CreateProposalContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "Create Proposal",
    navSubTitle: "Welcome to ImagesBazaar"
  })


  const handleNavParams = (obj) => {
    setNavParams({
      ...navParams,
      ...obj
    })
  }
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
      <CreateProposal handleNavParams={handleNavParams} />
    </MainLayout>
  )
}

export default CreateProposalContainer