import { FormControl, FormHelperText, FormLabel, Input, InputLabel, useTheme } from '@mui/material';
import React from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';

const CustomAsyncSelect = React.forwardRef(({
  width,
  handleChange,
  promiseOptions,
  error,
  helperText,
  name,
  controlStyle,
  dropDownZIndex,
  label,
  labelShrink,
  ...props
}, ref) => {
  const theme = useTheme();
  let errorStatus = error?.constructor === Object? name ? error[name] : null : error ? error : false;
  return (
    <div style={{ width: width || 450 }}>
      <FormControl
        required
        error={errorStatus}
        sx={{
          width: '100%'
        }}
        component="fieldset"
        variant="standard"
      >
        <InputLabel shrink={labelShrink} sx={{zIndex: 1, top: -6, left:10, padding: '0 6px' , background: "#fff"}} required={props.required}>{label}</InputLabel>
        <AsyncSelect
          isMulti
          defaultOptions
          loadOptions={promiseOptions}
          onChange={handleChange}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: dropDownZIndex }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: errorStatus ? '#d32f2f' :state.isFocused
                ? theme.palette.appColor.contrastText
                : theme.palette.appColor.contrastText,
              borderWidth: state.isFocused ? '2px' : '1px',
              boxShadow: '0 0 0 0',
              borderRadius: theme.shape.borderRadius(8),
              padding: 1.5,
              ...controlStyle
            }),
          }}
          maxMenuHeight={200}
          {...props}
          ref={ref}
        />
         {error?.constructor === Object ? (helperText && error[name]) ? <FormHelperText>{helperText}</FormHelperText>: null : (helperText && error) ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </div>
  );
})

export default React.memo(CustomAsyncSelect);

CustomAsyncSelect.defaultProps = {
  promiseOptions: () => [],
  width: 450,
  dropDownZIndex: 100,
  handleChange: () => {},
  label: "",
  labelShrink: false,
  required: false
};


const CustomAsyncCreatable = React.forwardRef(({
  width,
  handleChange,
  promiseOptions,
  error,
  helperText,
  name,
  controlStyle,
  dropDownZIndex,
  label,
  labelShrink,
  ...props
}, ref) => {
  const theme = useTheme();
  let errorStatus = error?.constructor === Object? name ? error[name] : null : error ? error : false;
  return (
    <div style={{ width: width || 450 }}>
      <FormControl
        required
        error={errorStatus}
        sx={{
          width: '100%'
        }}
        component="fieldset"
        variant="standard"
      >
        <InputLabel shrink={labelShrink} sx={{zIndex: 1, top: -6, left:10, padding: '0 6px' , background: "#fff"}} required={props.required}>{label}</InputLabel>
        <AsyncCreatableSelect
          isMulti
          cacheOptions
          defaultOptions
          loadOptions={promiseOptions}
          onChange={handleChange}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: dropDownZIndex }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: errorStatus ? '#d32f2f' :state.isFocused
                ? theme.palette.appColor.contrastText
                : theme.palette.appColor.contrastText,
              borderWidth: state.isFocused ? '2px' : '1px',
              boxShadow: '0 0 0 0',
              borderRadius: theme.shape.borderRadius(8),
              padding: 1.5,
              ...controlStyle
            }),
          }}
          maxMenuHeight={200}
          {...props}
          ref={ref}
        />
         {error?.constructor === Object ? (helperText && error[name]) ? <FormHelperText>{helperText}</FormHelperText>: null : (helperText && error) ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </div>
  );
})

const CustomAsyncCreatableSelect = React.memo(CustomAsyncCreatable)
export {CustomAsyncCreatableSelect};

CustomAsyncCreatable.defaultProps = {
  promiseOptions: () => [],
  width: 450,
  dropDownZIndex: 100,
  handleChange: () => {},
  label: "",
  labelShrink: false,
  required: false
};
