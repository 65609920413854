import axios from "axios";
import { toast } from "react-toastify";
import ApiConfig from "../config/ApiConfig";
import { deleteApiPayload, errorMessage } from "../lib/helper";
import { STATUS_200, STATUS_300 } from '../data/constants';
import { setAllLogsListing, setAllLogsListingError, setAllLogsListingLoading, setZeroLogsListing, setZeroLogsListingError, setZeroLogsListingLoading } from "../redux/logs";


export const getAllLogsListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllLogsListingLoading(true));
        const resp = await axios.post(`${ApiConfig.listOfSearchlog}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllLogsListing(resp.data.data));
          dispatch(setAllLogsListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllLogsListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllLogsListingLoading(false));
      }
    };
  }
};

export const getZeroLogsListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setZeroLogsListingLoading(true));
        const resp = await axios.post(`${ApiConfig.listOfSearchlog}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setZeroLogsListing(resp.data.data));
          dispatch(setZeroLogsListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setZeroLogsListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setZeroLogsListingLoading(false));
      }
    };
  }
};

export const deleteLogs = async (payload) => {
  if (payload !== undefined) {
    try{
      const  resp = await axios.delete(`${ApiConfig.deleteLogs}`, deleteApiPayload(payload));
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message,{
          autoClose: 2500
        })
        return true
      } else {
        throw resp
      }
    }catch(e){
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const getLogsListingExportFile = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.exportLogs}`, payload, {
        responseType: 'arraybuffer'
      });
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        return resp
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};
