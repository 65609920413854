import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { proposalDetailApi, temporaryUserListing } from '../../Services/userApis';
import { useDispatch } from 'react-redux';
import {ProposalQuotation} from './ProposalQuotation';
import { useReactToPrint } from 'react-to-print';
import { useSearchParams } from 'react-router-dom';
import { decrypt } from '../../lib/helper';

function ProposalQuotationPage() {
  const [proposalDetails, setProposalDetails] = useState(null)
  const componentRef = useRef();
  const [searchParams] = useSearchParams();
  const proposalDId = decrypt('proposal_id', searchParams.get('proposal_id'))
  const [proposalId, setProposalId] = useState(proposalDId);
  const generatePDFFunction = async (data)=>{
   
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    pageStyle: "@page { size: auto; margin: 12mm 5mm; } }",
    // print: async (printIframe) => {
    //   // Do whatever you want here, including asynchronous work
    //   // get generated pdf content from generatePDFFunction function
    //   // await generatePDFFunction(printIframe);
    // },
  });

  const handleProposal = async(proposal) =>{
    if(proposal){
      let resp = await proposalDetailApi({proposal_id: proposal})
      if(resp){
        setProposalDetails(resp)
      }
    }
  }

  useEffect(()=>{
    handleProposal(proposalId)
  },[proposalId])

  return (
    <div style={{display: 'flex', justifyContent: "center"}}>
      <Box sx={{maxWidth: 800, marginTop:2}}>
        {proposalDetails && <div style={{position: 'relative', top: 7, right: 0, textAlign: 'right', height: 0}} >
          <CustomButton onClick={()=>{handlePrint()}} className="buttonDense" color={'buttonPrimary'}>Print</CustomButton>
        </div>}
        <ProposalQuotation ref={componentRef} handlePrint={handlePrint} data={proposalDetails} />
        {/* <div
          style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
        >
          <CustomButton
            color={'buttonPrimary'}
            type="submit"
            loading={loading}
          >
            Create User
          </CustomButton>
          <CustomButton
            onClick={() => {
                formik.resetForm({
                    email: '',
                    password: '',
                    fName: '',
                    lName: '',
                });
                handleOpen(false)}
            }
            disabled={loading}
          >
            Cancle
          </CustomButton>
        </div> */}
      </Box>
    </div>
  );
}

export default ProposalQuotationPage;
