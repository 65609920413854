import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  starIndustriesList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const starIndustriesSlice = createSlice({
  name: 'starIndustries',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStarIndustriesListing: (state, action) => {
      state.starIndustriesList.items = action.payload;
      state.starIndustriesList.error = {
        isError: false,
        message: '',
      };
    },
    setStarIndustriesListingLoading: (state, action) => {
      state.starIndustriesList.loading = action.payload;
    },
    setStarIndustriesListingError: (state, action) => {
      state.starIndustriesList.items = {};
      state.starIndustriesList.error = action.payload;
    },
  },
});

export default starIndustriesSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setStarIndustriesListing,
  setStarIndustriesListingError,
  setStarIndustriesListingLoading,
} = starIndustriesSlice.actions;

export const starIndustriesItemsSelector = (state) => state.starIndustries;
