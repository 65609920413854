import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import ContributorPaymentDetail from './contributorPaymentDetail'





function ContributorPaymentDetailsContainer() {
  return (
    <MainLayout navTitle={"Contributor Details"} navSubTitle={"Welcome to ImagesBazaar"}>
      <ContributorPaymentDetail />
    </MainLayout>
  )
}

export default ContributorPaymentDetailsContainer