import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT } from '../../data/constants';
import { crypt, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { imageListingItemsSelector } from '../../redux/image';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { imageListings, imageStartListings } from '../../Services/imageApis';
import { AllOrderListing } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import EditImageDetailModal from '../Modals/EditImageDetailModal';
import ImageKeywordModal from '../Modals/ImageKeywordModal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SuspendImageModal from '../Modals/SuspendImageModal';
import MatTable from '../Tables/MatTable';
import { getListOfImageKeywords } from '../../Services/keywordsApis';

// const limit = DATA_LIMIT;

const column = [
  {
    label: 'Image',
    id: 'image_url',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
      >
        <img style={{ maxWidth: 200, maxHeight: 200 }} src={`${columnData}`} />
      </span>
    ),
  },
  {
    label: 'Image ID',
    id: 'Name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap', }} 
       >
        {columnData}
      </span>
    ),
  },
  {
    label: 'Rank',
    id: 'rank',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Shoot ID',
    id: 'shootid',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Category',
    id: 'category',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Sold Count',
    id: 'sold_count',
    format: (columnData) => (
      <span style={{ display: 'block', textAlign: "center", whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Suspended Date',
    id: 'suspendate',
    format: (columnData) => (
      <>
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          {formatDate(columnData, 'dd/mm/yyyy')}
        </span>

      </>
    ),
    nextLine:true,
  },
];

function StarImages() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [imageEditModal, setImageEditModal] = useState(false);
  const [imageSuspendModal, setImageSuspendModal] = useState(false);
  const [imageKeyword, setImageKeyword] = useState(false);
  const [editImageData, setEditImageData] = useState({});
  const { loading, starImages } = useSelector(imageListingItemsSelector);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(20);
  const [keywordForTheChoosenImage, setKeywordForTheChoosenImage] = useState([]);
  const navigate = useNavigate()
  const location = useLocation();

  const handleModal = (value = false) => {

    setImageEditModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const handleSuspendModal = (value = false) => {
    setImageSuspendModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };
  const handleImageKeywordModal = (value = false) => {
    setImageKeyword(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // order_status: String(1),
      is_star: 1,
      ...allParams,
      ...params,
    };
    dispatch(imageStartListings(payload));
    setPage(Number(payload.page));
    setEditImageData({});
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  const handleAllKeywordsForimage = async (data) => {

    let response = await getListOfImageKeywords(data?.Name);
    if (response?.status == 200) {
      setKeywordForTheChoosenImage(response?.data);
      handleImageKeywordModal(true)
    }

  }

  const handleAllFunctions = (data) => {
    setEditImageData(data);
    handleAllKeywordsForimage(data);
  }

  //making data format for table input
  useEffect(() => {
    if (starImages?.items?.users?.length >= 0) {
      let data = starImages?.items?.users?.map((item) => {
        return {
          image_url: `${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.Name}.jpg`,
          Name: item?.Name,
          contributor: item,
          rank: item.rank, //=>edit
          shootid: item.shootid,
          pricing: item.pricing, //=>edit
          suspendate: item.suspendate,
          createddate: item?.createddate,
          contributor: item.contributor,
          available: item.available,
          type: item.type, //=>edit dropdown value [I, S]
          createddate: item.createdAt,
          imgstatus: item.imgstatus,
          suspend: item.imgstatus,
          rank1: item.rank1, //=>edit
          keywords: item.Name, //=>edit
          sold_count: item.sold_count,
          category: item.category,
          is_star: item?.is_star,
          watermark: item?.watermark
        };
      });
      setTableListing(data);
    }
  }, [starImages.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
            <Link className='textDecoration-none' to={`${crypt(ApiConfig.REACT_APP_SALT + 'image_name', data?.Name)}`} target={'_blank'} >
            <CustomButton className="buttonDense" onClick={() => {
            }} sx={{ bgcolor: 'buttonInfo.main' }}>Keywords</CustomButton>
          </Link>
          <CustomButton className="buttonDense" onClick={() => {
            handleSuspendModal(true);
            setEditImageData(data);
          }}>Suspend</CustomButton>
          <CustomButton
            className="buttonDense"
            sx={{
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
            onClick={() => {
              handleModal(true);
              setEditImageData(data);
            }}
          >
            Edit
          </CustomButton>
        </div>
      </>
    );
  };

  const actionHead = (data) => {
    return (<div style={{display: "flex"}}>
       hi
    </div>);
  };

  return (
    <>
      <MatTable
        loading={starImages?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20, 50, 100, 200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={starImages?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
      // filterEnable
      // rangeDateFilter
      />
      <EditImageDetailModal
        open={imageEditModal}
        handleOpen={handleModal}
        data={editImageData}
        getListing={getListing}
        type={'starimage'}
      />
      <SuspendImageModal
        open={imageSuspendModal}
        handleOpen={handleSuspendModal}
        data={editImageData}
        getListing={getListing}
      />
    </>
  );
}

export default StarImages;
