import axios from "axios";
import { toast } from "react-toastify";
import ApiConfig from "../config/ApiConfig";
import { deleteApiPayload, errorMessage } from "../lib/helper";
import { STATUS_200, STATUS_300 } from '../data/constants';
import { setContributorListing } from "../redux/contributor";
import { setAllContributorReportsListing,setAllContributorReportsListingError,setMainLoading } from "../redux/contributorReport";
import { setAllPrivilegeListing, setAllPrivilegeListingError, setAllReportsListing, setAllReportsListingError } from "../redux/reports";

export const getDailySalesReport = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.dailySalesReport}`, payload, { responseType: 'blob' });
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        return {status: true, data: resp?.data}
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return {status: false, data: null}
    }
  }
};

export const getContrubutorsReport = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.contributorReport}`, payload, { responseType: 'blob' });
      if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
        return {status: true, data: resp?.data}
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return {status: false, data: null}
    }
  }
};

export const getReportListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setMainLoading(true));
        const resp = await axios.post(`${ApiConfig.getDailyReportData}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          //setAllReportsListing
          dispatch(setAllReportsListing(resp.data.data));
          dispatch(setMainLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllReportsListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setMainLoading(false));
      }
    };
  }
};


export const getContributorReportListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        
        dispatch(setMainLoading(true));
        const resp = await axios.post(`${ApiConfig.contributorgetReportData}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllContributorReportsListing(resp.data.data));
          dispatch(setMainLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllContributorReportsListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setMainLoading(false));
      }
    };
  }
};


export const getPrivilegeListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        
        dispatch(setMainLoading(true));
        const resp = await axios.post(`${ApiConfig.privillageList}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllPrivilegeListing(resp.data.data));
          dispatch(setMainLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllPrivilegeListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setMainLoading(false));
      }
    };
  }
};

export const countryListings = async () => {
  try {
    const resp = await axios.get(`${ApiConfig?.countryListReport}`);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp?.data;
    }
  }
  catch (e) {
    toast.error(errorMessage(e), { autoClose: 2500 })
  }
};

export const countryListingsUnpaid = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig?.countryListReportUnPaid}`,payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp?.data;
    }
  }
  catch (e) {
    toast.error(errorMessage(e), { autoClose: 2500 })
  }
};

export const stateListingsUnpaid = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig?.stateListReportUnPaid}`,payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp?.data;
    }
  }
  catch (e) {
    toast.error(errorMessage(e), { autoClose: 2500 })
  }
};
export const paidStatusChange = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig?.paymentStatusChange}`,payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp?.data;
    }
  }
  catch (e) {
    toast.error(errorMessage(e), { autoClose: 2500 })
  }
};


export const stateListings = async (payload) => {
  try {
    const resp = await axios.get(`${ApiConfig?.stateListReport}`,{params:payload});
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp?.data;
    }
  }
  catch (e) {
    toast.error(errorMessage(e), { autoClose: 2500 })
  }
};
//companyListReport

export const companyListings = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig?.companyListReport}`,payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp?.data;
    }
  }
  catch (e) {


    toast.error(errorMessage(e), { autoClose: 2500 })
  }
};


