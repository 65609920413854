import { Delete, Edit, EditAttributes } from '@material-ui/icons';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import Divder from '../CommonComp/Divder';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextEditor from '../CommonComp/TextEditor/TextEditor';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import EditPopularImage from '../Modals/EditPopularImage';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import './commonStyle.css';
import { useDispatch, useSelector } from 'react-redux';
import { cmsItemsSelector } from '../../redux/cms';
import { editAddCMS, getCMSInfo } from '../../Services/cmsService';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import PopularSearch from './PopularSearch';
import Purpose from './Purpose';
import { setLoading } from '../../redux/company';

function Images() {
  const { cmsData } = useSelector(cmsItemsSelector);
  let cmsDataHome = cmsData ? cmsData?.items?.length > 0 && JSON?.parse(cmsData?.items[0]?.key_value) : [];
  const [editIndex, setEditIndex] = useState(-1);
  const dispatch = useDispatch();
  const [addModal, setAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading,setImageLoading]=useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const buttonList = [
    "Family",
    "Diwali",
    "Business",
    "Jewellery",
    "Couples",
    "Wedding",
    "Farmer",
    "Kitchen",
    "Doctor",
    "Celebration",
    "Office",
    "Travel",
    "Shopping",
    "Education",
    "Students",
    "Insurance",
    "Mobile",
    "Computer",
    "Food",
    "Baby",
    "Fashion",
  ];
  let arr =
  {
    searchData: {
      heading: 'Popular Searches',
      content: ["Family", "Diwali", "Business", "Jewellery", "Couples", "Wedding", "Farmer", "Kitchen", "Doctor", "Celebration", "Office", "Travel", "Shopping", "Education", "Students", "Insurance", "Mobile", "Computer", "Food", "Baby", "Fashion"]
    },
    sectionBottom: {
      heading: 'WHY IMAGESBAZAAR?',
      content: "India's diversity and complexity can be witnessed through its people, traditions and values associated with distinctive regional customs, habits, lifestyle and festivals. That's why our content reflects the innate expressions of deep-rooted feelings of individuals through which spring their day-to-day actions. From contemporary concepts and ideas to the broadest range of categories depicting Indians in virtually all walks of life, age groups and expressions - you will find it all here!"
    },

    imageSection: {
      heading: "Popular Categories",
      content: [
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Concept-and-Ideas.jpg",
          title: "Concepts & Ideas",
          displayKeyword: "Concepts & Ideas >> View All",
          searchKeyword: "concepts",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Lifestyle-Families.jpg",
          title: "Lifestyle: Families",
          displayKeyword: "Lifestyle: Families >> View All",
          searchKeyword: "families",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Lifestyle-Children.jpg",
          title: "Lifestyle: Children",
          displayKeyword: "Lifestyle: Children >> View All",
          searchKeyword: "Kids only",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Banking-and-Finance.jpg",
          title: "Banking & Finance",
          displayKeyword: "banking finance",
          searchKeyword: "banking finance",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Shopping-and-Retail.jpg",
          title: "Shopping & Retail",
          displayKeyword: "Shopping & Retail >> View All",
          searchKeyword: "shopping",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Lifestyle-Teenagers.jpg",
          title: "Lifestyle: Teenagers",
          displayKeyword: "Lifestyle: Teenagers >> View All",
          searchKeyword: "Teenagers",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Lifestyle-Seniors.jpg",
          title: "Lifestyle: Seniors",
          displayKeyword: "Lifestyle: Seniors >> View All",
          searchKeyword: "Seniors",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Vacation-and-Holidays.jpg",
          title: "Vacations & Holidays",
          displayKeyword: "Vacations & Holidays >> View All",
          searchKeyword: "Vacations",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Without-People.jpg",
          title: "Without People",
          displayKeyword: "Without People Concepts >> View All",
          searchKeyword: "Without People",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Wedding.jpg",
          title: "Wedding",
          displayKeyword: "wedding",
          searchKeyword: "Wedding",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Healthcare-and-Medical.jpg",
          title: "Healthcare & Medical",
          displayKeyword: "medical",
          searchKeyword: "medical",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Indian-Culture.jpg",
          title: "Indian Culture",
          displayKeyword: "Indian Culture >> View All",
          searchKeyword: "Indian Culture",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Fashion71.jpg",
          title: "Fashion",
          displayKeyword: "fashion",
          searchKeyword: "Fashion",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Beauty.jpg",
          title: "Beauty",
          displayKeyword: "beauty",
          searchKeyword: "Beauty",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Fitness.jpg",
          title: "Fitness",
          displayKeyword: "Fitness",
          searchKeyword: "Fitness",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Lifestyle-Adult.jpg",
          title: "Lifestyle: Adults",
          displayKeyword: "Lifestyle: Adults >> View All",
          searchKeyword: "lifestyle adults only",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Festivals-and-Occasions.jpg",
          title: "Festivals & Occasions",
          displayKeyword: "Festivals & Occasions >> View All",
          searchKeyword: "Occasion",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Education-and-Learning.jpg",
          title: "Education & Learning",
          displayKeyword: "Education & Learning >> View All",
          searchKeyword: "Learning",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Food-and-Drink1.jpg",
          title: "Food & Drink",
          displayKeyword: "Food & Drink >> View All",
          searchKeyword: "Food",
        },

        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Business-and-Corporate.jpg",
          title: "Business & Corporate",
          displayKeyword: "Business & Corporate >> View All",
          searchKeyword: "Business",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Friends.jpg",
          title: "Friends",
          displayKeyword: "friends",
          searchKeyword: "Friends",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Rural-India.jpg",
          title: "Rural India",
          displayKeyword: "Rural India >> View All",
          searchKeyword: "Rural",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Nature.jpg",
          title: "Nature",
          displayKeyword: "Nature & Landscapes >> View All",
          searchKeyword: "Nature",
        },
        {
          img: "https://d3nn873nee648n.cloudfront.net/HomeImages/Lifestyle-Couple1.jpg",
          title: "Lifestyle: Couples",
          displayKeyword: "Lifestyle: Couples >> View All",
          searchKeyword: "Couples",
        }
      ]

    }
  }
  const handleOpenEditImageModel = () => {

  }
  useEffect(() => {
    getListing();
  }, [])

  const getListing = () => {
    let payload = { pagename: 'homePage' }
    dispatch(getCMSInfo(payload));
  }

  const handleAdd = (value = false) => {
    setAddModal(value)
  }
  const handleEdit = (value = false, data = null, index) => {
    setEditModal(value)
    setEditData(data);
    setEditIndex(index);
  }

  const handleFinalSubmit = async () => {
    let newObj = { ...cmsDataHome, imageSection: {} }
    let str1 = JSON.stringify(arr);
    let obj = { pagename: 'homePage', pagedata: str1 }
    let resp = await editAddCMS(obj);
    if (resp) {
      getListing();
    }
  }

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let newObj = { ...cmsDataHome, imageSection: values };
    let str1 = JSON.stringify(newObj);
    let obj = { pagename: 'homePage', pagedata: str1 }
    let resp = await editAddCMS(obj);
    if (resp) {
      getListing();
    }
    setLoading(false);

  };


  const formik = useFormik({
    initialValues: {
      heading: cmsDataHome?.imageSection?.heading,
      content: cmsDataHome?.imageSection?.content
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      heading: Yup.string().required('Heading is required'),
      content: Yup.array().min(24, "Minimum 24 images required!").required('Required')
      ,

    }),
    onSubmit: handleConfirm,
  });


  return (
    <>
      <CustomBox padding={1} >
        <Box
          noValidate
          component="form"
          onSubmit={formik.handleSubmit}
          marginTop={2}
          marginLeft={2}

        >

          <Typography variant='h5' mb={2} >Image Section</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="heading"
                label="Images Heading"
                placeholder="Images Heading"
                value={formik.values.heading}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.heading && Boolean(formik.errors.heading)}
                helperText={formik.touched.heading && formik.errors.heading}
              />
            </Grid>
          </Grid>


          <div className="image-liked-section">
            <div >
              <Box sx={{ width: "100%" }}>
                <ImageList
                  variant="masonry"
                  cols={4}
                  gap={8}
                  className="imagelist-adjust-mob"
                >
                  {cmsDataHome?.imageSection?.content.map((item, index) => {
                    return (
                      <ImageListItem
                        key={`indexkey${item.img}${new Date().getTime()+index}`}
                        //key={Date.now()+item?.img}
                        sx={{ display: "contents" }}
                      >
                        <div
                          className="home-image-list-div"
                        >
                          <img
                            className="home-image-list-image"
                            src={`${item.img}?w=248&fit=crop&auto=format&v=${new Date().getTime()}`}
                            alt={item.title}
                            loading="lazy" 
                          />
                          <Typography
                            variant="mainpagetitle"
                            className="home-image-list-text primaryColor"
                            component="div"
                          >
                            {item.title}
                          </Typography>
                          <Box className='icon-for-edit'>
                            <IconButton sx={{
                              ml: 1,
                              "&.MuiButtonBase-root:hover": {
                                // bgcolor: "transparent"
                                bgcolor: 'white'
                              }
                            }} onClick={(e) => handleEdit(true, item, index)}>

                              <EditIcon />
                            </IconButton>
                          </Box>
                        </div>
                      </ImageListItem>
                    );
                  })}
                </ImageList>
              </Box>
            </div>
          </div>
          <div
            style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
          >
            <CustomButton
              color={'buttonPrimary'}
              type="submit"
              loading={loading}
            >
              Save
            </CustomButton>
          </div>
        </Box>
        <Divider sx={{ marginBottom: '5px',marginTop:'8px' }} />
        <PopularSearch searchKeyword={cmsDataHome?.searchData} cmsDataHome={cmsDataHome} handleLast={getListing} />
        <Divider sx={{ marginBottom: '5px',marginTop:'8px' }} />
        <Purpose purposeData={cmsDataHome?.sectionBottom} cmsDataHome={cmsDataHome} handleLast={getListing} />
        <EditPopularImage open={editModal} editIndex={editIndex} cmsDataHome={cmsDataHome} handleOpen={handleEdit} data={editData} handleLast={getListing} />
      </CustomBox>
    </>
  );
}

export default Images;