import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { deleteApiPayload, errorMessage } from '../lib/helper';
import { DATA_LIMIT, STATUS_200, STATUS_300 } from '../data/constants';
import { setAllAdminUsersListing, setAllAdminUsersListingError, setAllAdminUsersListingLoading } from '../redux/adminUsers';

export const adminUsersListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllAdminUsersListingLoading(true));
        const resp = await axios.post(`${ApiConfig.listOfAdminUser}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllAdminUsersListing(resp.data.data));
          dispatch(setAllAdminUsersListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllAdminUsersListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllAdminUsersListingLoading(false));
      }
    };
  }
};

export const createAdminUser = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.createAdminUser}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};
export const updateAdminUser = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editAdminUser}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateLoggedInAdminPassword= async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.updateLoggedinAdminPWD}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateAdminUserPassword = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.changeAdminUserPassword}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const updateContributorPassword = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.changeContributorPassword}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const deleteAdminUser = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(
        `${ApiConfig.deleteAdminUser}`,
        deleteApiPayload(payload),
      );
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const getAccessMenuList=async(payload)=>{
  try{
    const resp=await axios.post(`${ApiConfig?.userAccessMenu}`,payload);
    if(resp?.status>=STATUS_200 && resp?.status<STATUS_300){
       return resp?.data;
    }
  }
  catch(e){
    toast.error(errorMessage(e),{autoClose:2500})
  }
}

export const screensAssign = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.giveUserAccess}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data?.message, {
          autoClose: 2500,
        });
        return true;
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false;
    }
  }
};

export const getAdminById= async(payload) => {
      try{
        const resp=await axios.post(`${ApiConfig.adminById}`, payload);
        if(resp?.status>=STATUS_200 && resp?.status<STATUS_300){
           return resp?.data;
        }
      }
      catch(e){
        toast.error(errorMessage(e),{autoClose:2500})
      }
  
};


