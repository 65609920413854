import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  loading: false,
  masterList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  shortCompanyDetail: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
 
 
};

export const masterSlice = createSlice({
  name: 'master',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setMasterListing: (state, action) => {
      state.masterList.items = action.payload;
      state.masterList.error = {
        isError: false,
        message: '',
      };
    },
    setMasterListingLoading: (state, action) => {
      state.masterList.loading = action.payload;
    },
    setMasterListingError: (state, action) => {
      state.masterList.items = {};
      state.masterList.error = action.payload;
    },

    setShortCompanyDetail: (state, action) => {
      state.shortCompanyDetail.items = action.payload;
      state.shortCompanyDetail.error = {
        isError: false,
        message: '',
      };
    },
    setShortCompanyDetailLoading: (state, action) => {
      state.shortCompanyDetail.loading = action.payload;
    },
    setShortCompanyDetailError: (state, action) => {
      state.shortCompanyDetail.items = {};
      state.shortCompanyDetail.error = action.payload;
    },
    resetShortCompanyData:(state, action)=>{
      state.shortCompanyDetail.items = '';
      state.shortCompanyDetail.error = {
        isError: false,
        message: '',
      };
    }

  },
});

export default masterSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setMasterListing,
  setMasterListingLoading,
  setMasterListingError,
  setShortCompanyDetailLoading,
  setShortCompanyDetailError,
  setShortCompanyDetail,
  resetShortCompanyData
} = masterSlice.actions;

export const masterItemsSelector = (state) => state.master;
