import React from 'react'
import CustomInput from '../CommonComp/CustomInput/CustomInput'

function OrderDetailsInput(props) {
  return ( <CustomInput
    variant="outlined"
    fullWidth
    InputLabelProps={{ shrink: true }}
    InputProps={{
      classes: {
        notchedOutline: 'rounded',
      },
    }}
    {...props}
  >{props.children}</CustomInput>)
}

export default OrderDetailsInput