import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import ExpiryOrderEmail from './ExpiryOrderEmail'

function SendOrderEmailContainer() {
  return (
    <MainLayout navTitle={"Send Order Mail"} navSubTitle={"Welcome to ImagesBazaar"}>
        <ExpiryOrderEmail/>
    </MainLayout>
  )
}

export default SendOrderEmailContainer