import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import ImageKeywordDetail from './ImageKeywordDetail'

function ImageKeywordContainer() {
  return (
    <MainLayout navTitle={"Image Keyword Details"} navSubTitle={"Welcome to ImagesBazaar"}>
      <ImageKeywordDetail/>
    </MainLayout>
  )
}

export default ImageKeywordContainer