import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { addCompanyGroup, companyGroupsListings } from '../../Services/companyApis';
import { DATA_LIMIT } from '../../data/constants';

const limit = DATA_LIMIT;

function AddCompanyGroup({ open, handleOpen }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await addCompanyGroup(values)
    if (resp) {
      resetForm({
        company_group_name: ''
      })
      dispatch(companyGroupsListings({
        page: String(1),
        limit: limit,
      }))
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      company_group_name: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      company_group_name: Yup.string().required('Group name is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Add Company Group
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              width: 400
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="company_group_name"
                  label="Company Group Name"
                  placeholder="Company Group Name"
                  value={formik.values.company_group_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.company_group_name && Boolean(formik.errors.company_group_name)}
                  helperText={formik.touched.company_group_name && formik.errors.company_group_name}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Add
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    company_group_name: ''
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddCompanyGroup;
