import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomIconButtons from '../CommonComp/Buttons/IconButtons';
import { Menu, Notifications } from '@material-ui/icons';
import { Avatar, Badge, Box, Tooltip } from '@mui/material';
import Menus from './menus';
import { loginItemsSelector } from '../../redux/login';
import { useSelector } from 'react-redux';
import MenusProfile from './menusProfile';

// const drawerWidth = 340;

function Navbar({ visible, headingTitle, headingSubtitle, rightComp, handleDrawerToggle }) {
  const { user } = useSelector(loginItemsSelector);
  if (!visible) {
    return null;
  }
  return (
    <>
      <CssBaseline />
      <AppBar
        position="relative"
        sx={{
          //  width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`
          boxShadow: 'none',
          '.MuiToolbar-root': {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
        color="appColor"
      >
        <Toolbar
          sx={{
            padding: '19px 0',
            flexWrap: "wrap"
          }}
        >
          <CustomIconButtons
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, ml: 0, display: { md: 'none' } }}
          >
            <Menu/>
          </CustomIconButtons>

          {/* HEADER TITLE MOBILE NAV */}
          <Box sx={{ marginRight: 10, flex: 1, display: { md: 'block', xs: 'none'} }}>
            <Typography variant="h2" noWrap component="div">
              {headingTitle}
            </Typography>
            <Typography variant="h6" noWrap component="div">
              {headingSubtitle}
            </Typography>
          </Box>

          {/* ICONS AND BUTTONS */}
          <Box sx={{ flex: 1, justifyContent: "end", display: 'flex' }}>
            {rightComp && rightComp}
            <CustomIconButtons
              sx={{ padding: '6px 8px' }}
              color={'iconButtonPrimary'}
            >
            
                {/* <MailIcon color="action" /> */}
                {/* <Notifications fontSize="inherit" /> */}
                <Menus/>
             
            </CustomIconButtons>


            <CustomIconButtons
              sx={{ padding: '13px 15px' }}
              color={'iconButtonPrimary'}
            >
              <Badge badgeContent={4} color="badgePrimary">
                {/* <MailIcon color="action" /> */}
                <Notifications fontSize="inherit" />
              </Badge>
            </CustomIconButtons>

            {/* <CustomIconButtons
              sx={{ padding: '0' }}
              color={'iconButtonPrimary'}
            >
              <Tooltip title={user?.userDetails?.first_name}>
              <Avatar
                sx={{
                  height: 50,
                  width: 50,
                  borderRadius: 5,
                }}
              />
              </Tooltip>
            </CustomIconButtons> */}

            <CustomIconButtons
              sx={{ padding: '6px 8px' }}
              color={'iconButtonPrimary'}
            >
            
                {/* <MailIcon color="action" /> */}
                {/* <Notifications fontSize="inherit" /> */}
                <MenusProfile/>
             
            </CustomIconButtons>


          </Box>

          {/* HEADER TITLE MOBILE NAV */}
          <Box sx={{ marginRight: 30, flex: 1, display: {md: 'none'}, flexBasis: "100%", marginTop: 2 }}>
            <Typography variant="h2" noWrap component="div">
              {headingTitle}
            </Typography>
            <Typography variant="h6" noWrap component="div">
              {headingSubtitle}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Navbar;

Navbar.defaultProps = {
  visible: true,
  rightComp: null,
};
