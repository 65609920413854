import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { breakStringIntoLines, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { keywordItemsSelector } from '../../redux/keyword';
import { getVisibleHiddenKeywordListing } from '../../Services/keywordsApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import LinkHiddenKeyword from '../Modals/LinkHiddenKeyword';
import MatTable from '../Tables/MatTable';
import { autoSuggestionListingItemsSelector } from '../../redux/autoSugg';
import { autoListings, deleteAutoSuggestion } from '../../Services/autoSuggestion';
import { Delete, Edit } from '@material-ui/icons';
import ConfirmationModal from '../Modals/ConfirmationModal';
import EditAutoSuggestion from '../Modals/EditAutoSuggestion';
import AddAutoSuggestion from '../Modals/AddAutoSuggestion';
import UploadSuggestionCSVModal from '../Modals/UploadSuggestionCSVModal';



const limit = 10;

const column = [
  {
    label: 'SNO',
    id: 'sno',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Keyword',
    id: 'Keyword',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
      {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}
    </span>)
  },
  {
    label: 'Display Keyword',
    id: 'displayKeyword',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
      {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}
    </span>)
  },
  {
    label: 'Rank',
    id: 'rank',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
];

function AutoSuggestionList({ vid }) {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { allAutoSuggest } = useSelector(autoSuggestionListingItemsSelector)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [addCSV, setAddCSV] = useState(false);

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params
    }
    dispatch(autoListings(payload));
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  useEffect(() => {
    if (allAutoSuggest?.items?.data?.length >= 0) {
      setTableListing(allAutoSuggest?.items?.data)
    }
  }, [allAutoSuggest.items]);

  const handleEdit = (value = false, data = null) => {
    setEditModal(value);
    setEditData(data)
  }
  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  }

  const handleConfirmDelete = async (value) => {
    if (value && deleteData?.sno) {
      const resp = await deleteAutoSuggestion({ autosuggest_id: deleteData?.sno });
      if (resp) {
        getListing({ page: '1' })
        setPage(1)
        setDeleteData(null)
      }
    } else {
      setDeleteData(null)
    }
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton toolTipTittle={'Edit Suggestion'}
            onClick={() => {
              handleEdit(true, data);
            }}
            className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit /></CustomButton>
          <CustomButton toolTipTittle={'Delete Suggestion'}
            onClick={() => {
              handleCofirmModalOpen(true)
              setDeleteData(data)
            }}
            className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonDanger.main', color: 'buttonDanger.contrastText' }}><Delete /></CustomButton>
        </div>
      </>
    );
  };

  const handleAdd = (value = false) => {
    setAddModal(value)
  }
  const handleCSV = (value = false) => {
    setAddCSV(value)
  }

  return (
    <>
      <MatTable
        loading={allAutoSuggest?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={allAutoSuggest?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        toolBarButton={() => {
          return <>
            <CustomButton onClick={() => { handleCSV(true) }} color={'buttonPrimary'} sx={(theme) => ({
              padding: '10px 10px', marginRight: 1,
              [theme.breakpoints.down("md")]: {
                flex: 1,
              }
            })}>Upload CSV</CustomButton>
            <CustomButton onClick={() => { handleAdd(true) }} color={'buttonPrimary'} sx={(theme) => ({
              padding: '10px 10px', marginRight: 1,
              [theme.breakpoints.down("md")]: {
                flex: 1,
              }
            })}>Add Auto Suggestion</CustomButton>
          </>
        }}
        filtersUrlData={allParams || {}}
        pagination={true}
      />
      {confirmDelete && <ConfirmationModal open={confirmDelete} handleConfirm={handleConfirmDelete} setOpen={handleCofirmModalOpen} />}
      {editModal && <EditAutoSuggestion open={editModal} handleOpen={handleEdit} data={editData} setPage={setPage} handleLast={getListing} />}
      {addModal && <AddAutoSuggestion open={addModal} handleOpen={handleAdd} setPage={setPage} handleLast={getListing} />}
      {addCSV && <UploadSuggestionCSVModal open={addCSV} handleOpen={handleCSV} setPage={setPage} handleLast={getListing} />}
    </>
  );
}

export default AutoSuggestionList;
