import React, { useState } from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import { useFormik } from 'formik';
import { updateImageStatus } from '../../Services/ContributorApis';

export default function ConfirmationImageModal({ open, setOpen, setUpdateData, setPage, status, updateData, confirmationTitle, getListing, confirmationDesc, confirmText, cancleText, data, render }) {
    const [loading, setLoading] = useState(false);
    const handleConfirm = async (values, { resetForm }) => {
        setLoading(true)
        if (status && updateData) {
            const resp = await updateImageStatus({ contributor_upload_image_id: updateData, status: status, comment: values.comment });
            if (resp) {
                getListing({ page: '1' });
                setPage(1);
                setUpdateData(null);
                resetForm({
                    comment: "",
                })
                setOpen(false);
                setLoading(false)
            }
            else {
                setUpdateData(null);
            }
            // handleLast();
        };
    }
    const formik = useFormik({
        initialValues: {
            comment: data?.comment || "",
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            comment: Yup.string().required('Comment is required.'),

        }),
        onSubmit: handleConfirm,
    });
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {!!confirmationTitle && <DialogTitle component={'div'} id="alert-dialog-title">
                <Typography variant='h3'>{confirmationTitle}</Typography>
            </DialogTitle>}
            {!!confirmationDesc || !!render ? <DialogContent>
                <Box
                    noValidate
                    component="form"
                    onSubmit={formik.handleSubmit}
                >
                    {!!confirmationDesc && <DialogContentText id="alert-dialog-description">
                        <Typography variant='p' color={'red'}>{confirmationDesc}</Typography>
                    </DialogContentText>}
                    {render && render()}
                    <Grid item xs={12} sm={12} md={12} sx={{ display: "flex", justifyContent: "center", marginTop: "10px", marginLeft: "5px", marginRight: "5px" }}>
                        <CustomInput
                            variant="outlined"
                            fullWidth
                            name="comment"
                            label="Comment"
                            placeholder="Comment"
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                classes: {
                                    notchedOutline: 'rounded',
                                },
                            }}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                        />
                    </Grid>
                    <div
                        style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
                    >
                        <CustomButton
                            color={'buttonPrimary'}
                            type="submit"
                            loading={loading}
                        >
                            Confirm
                        </CustomButton>
                        <CustomButton
                            onClick={() => {
                                formik.resetForm({
                                    comment: "",
                                });
                                setOpen(false)
                            }
                            }
                            disabled={loading}
                        >
                            Cancel
                        </CustomButton>
                    </div>
                </Box>
            </DialogContent> : null}
        </Dialog>
    );
}

ConfirmationImageModal.defaultProps = {
    open: false,
    setOpen: () => { },
    handleConfirm: () => { },
    confirmationTitle: 'Alert!!',
    confirmationDesc: "Are you sure you want to perform this action?",
    confirmText: "Confirm",
    cancleText: "Cancel",
    render: null
}