import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, MenuItem } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import { ImageSelectData } from '../../data/imageData';
import { addCountry, addState, updateCountry, updateJobDescription, updateState } from '../../Services/commonService';

export default function StateListModal({ open, handleOpen, data, countryId, getListing }) {
  const [loading, setLoading] = React.useState(false);

  const handleDataSubmit = async (values, { resetForm }) => {
    try {
			setLoading(true)
			if(data){
				let resp = await updateState({
					country_id: data.country_id,
          state_id: data.state_id,
          state_name: values.state_name,
          state_name: values.state_name,
          state_short_name: values.state_short_name,
          state_code: values.state_code,
          status: values.status
				})
				if(resp){
					getListing({country_id: countryId})
					handleOpen(false)
          formik.resetForm()
				}
				setLoading(false)
			}else{
				let resp = await addState({
          country_id: countryId,
          state_name: values.state_name,
          state_short_name: values.state_short_name,
          state_code: values.state_code,
          status: values.status
				})
				if(resp){
					getListing({country_id: countryId})
					handleOpen(false)
          formik.resetForm()
				}
				setLoading(false)
      }
    } catch (e) {
      console.log(e, 'error');
    }
  };
  const formik = useFormik({
    initialValues: {
      state_name: data?.state_name || '',
      state_short_name: data?.state_short_name || '',
      state_code: data?.state_code || '',
      status: data?.status || '1'
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      state_name: Yup.string().required('This field is required.'),
      state_short_name: Yup.string().required('This field is required.'),
      state_code: Yup.number().min(0, 'Minimum value should be 0.').required('This field is required.'),
      status: Yup.string().required('This field is required.')
    }),
    onSubmit: handleDataSubmit,
  });

  return (
    <>
      <Dialog
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
				fullWidth
      >
        <DialogTitle variant="h3">State Add/Update</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="state_name"
                  label="State Name"
                  placeholder="State Name"
                  value={formik.values?.state_name}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched?.state_name &&
                    Boolean(formik.errors?.state_name)
                  }
                  helperText={
                    formik.touched?.state_name && formik.errors?.state_name
                  }
									onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="state_short_name"
                  label="State Short Name"
                  placeholder="State Short Name"
                  value={formik.values?.state_short_name}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched?.state_short_name &&
                    Boolean(formik.errors?.state_short_name)
                  }
                  helperText={
                    formik.touched?.state_short_name && formik.errors?.state_short_name
                  }
									onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="state_code"
                  label="States Code"
                  placeholder="States Code"
                  value={formik.values?.state_code}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched?.state_code &&
                    Boolean(formik.errors?.state_code)
                  }
                  helperText={
                    formik.touched?.state_code && formik.errors?.state_code
                  }
									onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="status"
                  label="Status"
                  placeholder="Status"
									select
                  value={formik.values?.status}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched?.status &&
                    Boolean(formik.errors?.status)
                  }
                  helperText={
                    formik.touched?.status && formik.errors?.status
                  }
									onChange={formik.handleChange}
                >
									<MenuItem key={"0"} value={"0"}>Inactive</MenuItem>
									<MenuItem key={"1"} value={"1"}>Active</MenuItem>
								</CustomInput>
              </Grid>
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}>
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
              <CustomButton
                onClick={() => {
                  handleOpen(false);
                  formik.resetForm()
                }}
                disabled={loading}
              >
                Close
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
