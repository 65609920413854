import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import Subscription from './Subscription'

function SubscriptionContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "Subscription",
    navSubTitle: "Welcome to ImagesBazaar"
  })

  const handleNavParams = (obj)=>{
    setNavParams({
      ...navParams,
      ...obj
    })
  }
  return (
    <MainLayout navTitle={"Subscription"} navSubTitle={"Welcome to ImagesBazaar"}>
      <Subscription handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default SubscriptionContainer