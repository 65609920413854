import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import CustomIconButtons from '../CommonComp/Buttons/IconButtons';
import ApiConfig from '../../config/ApiConfig';
import { useNavigate } from 'react-router-dom';
import { crypt } from '../../lib/helper';

function SelectPlanModal({ open, handleOpen, heading, selectRenewPlan, handleSelectPlan }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  return (
    <div>
      <Dialog
        maxWidth={'lg'}
        open={open}
        fullWidth
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle component='div' sx={{ display: 'flex', alignItems: "center", padding: '10px 24px 0px' }}>
          <Typography variant="h3">{heading}</Typography>
          {handleOpen && <CustomIconButtons onClick={() => { handleOpen(false); }} sx={{ padding: '13px 15px', ml: 'auto' }}
            color={'iconButtonPrimary'} disabled={loading}><CloseIcon /></CustomIconButtons>}
        </DialogTitle>
        <DialogContent>
          {selectRenewPlan?.planListing?.length > 0 ? (
            <Box className="top-reverse-table">
              <Typography
                variant="h5"
                className="boldFont text-center"
                component="div"
                sx={{ marginBottom: "15px" }}
              >
                Subscribe & Save Upto 60%
              </Typography>
              <Typography
                className="boldFont text-center"
                variant="h5"
                component="div"
              >
                RENEW NOW and Get INR{" "}
                {selectRenewPlan?.currentPlan &&
                  selectRenewPlan?.currentPlan?.coupon_amount}{" "}
                OFF!
              </Typography>
              <Box
                component="div"
                className="main-single-image-price-table"
                sx={{
                  marginTop: "25px",
                  padding: '20px 40px 20px 40px',
                  backgroundColor:
                    selectRenewPlan?.planListing?.length > 0 &&
                      selectRenewPlan?.type == 'web'
                      ? "pricingBoxColors.webPackBackgroundColor"
                      : selectRenewPlan?.type == 'small'
                        ? "pricingBoxColors.smallPackBackgroundColor"
                        : "pricingBoxColors.largePackBackgroundColor",
                  overflow: "auto"
                }}
              >
                <Box className="subscriber-package-details" sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginbottom: "15px",
                }}>
                  <div className="subscriber-package-instructions">
                    <Typography
                      className="fontWeight600"
                      variant="h5"
                      component="div"
                    >
                      {selectRenewPlan?.currentPlan &&
                        `${selectRenewPlan?.currentPlan?.plan_type} Pack`}
                    </Typography>
                    <Typography
                      className="fontWeight600"
                      variant="h6"
                      component="div"
                    >
                      {selectRenewPlan?.planListing?.length > 0 && selectRenewPlan?.type == 'web' &&
                        `800 X 1200 at
                        72 dpi (JPEG)`}
                      {selectRenewPlan?.planListing?.length > 0 && selectRenewPlan?.type == 'small' &&
                        `800 X 1200 at
                        72 dpi (JPEG)`}
                      {selectRenewPlan?.planListing?.length > 0 && selectRenewPlan?.type == 'large' &&
                        `5400 X 8100 pixels at 300 dpi (18” X 27” TIFF)`}
                    </Typography>
                  </div>
                  <CustomButton sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}
                    onClick={() => {
                      navigate(`/renewalCheckout?userId=${crypt(ApiConfig.REACT_APP_SALT + 'user_id', selectRenewPlan?.userId)}&planId=${crypt(ApiConfig.REACT_APP_SALT + 'plan_id', selectRenewPlan?.currentPlan?.plan_id)}&preOrderId=${crypt(ApiConfig.REACT_APP_SALT + 'order_id', selectRenewPlan?.order_id)}&client=${crypt(ApiConfig.REACT_APP_SALT + 'client', selectRenewPlan?.client)}`);
                    }}
                  >
                    Subscribe
                  </CustomButton>
                </Box>
                <Table aria-label="Large Pack">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                        }}
                      >
                        <Typography
                          component="div"
                          variant="mainsectiontitle"
                          className="alignText-center boldFont"
                        >
                          Select
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                        }}
                      >
                        <Typography
                          component="div"
                          variant="mainsectiontitle"
                          className="alignText-center boldFont"
                        >
                          Images/Videos
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                        }}
                      >
                        <Typography
                          component="div"
                          variant="mainsectiontitle"
                          className="alignText-center boldFont"
                        >
                          Discount
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                        }}
                      >
                        <Typography
                          component="div"
                          variant="mainsectiontitle"
                          className="alignText-center boldFont"
                        >
                          Price (INR)
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                        }}
                      >
                        <Typography
                          component="div"
                          variant="mainsectiontitle"
                          className="alignText-center boldFont"
                        >
                          Download Within
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectRenewPlan?.planListing?.map((data, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor:
                              selectRenewPlan?.currentPlan?.plan_id === data?.plan_id
                                ? selectRenewPlan?.type == 'web'
                                  ? "pricingBoxColors.webPackSelectedBackgroundColor"
                                  : selectRenewPlan?.type == 'small'
                                    ? "pricingBoxColors.smallPackSelectedBackgroundColor"
                                    : "pricingBoxColors.largePackSelectedBackgroundColor"
                                : "inherit",
                          }}
                        >
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                            }}
                          >
                            <div className="sub-package-main-checkbox">
                              <input
                                className="sip-table-checkbox"
                                id={`SelectedPlan${index}`}
                                type="radio"
                                value={data?.plan_id}
                                name="payment-method-name"
                                checked={
                                  data?.plan_id === selectRenewPlan?.currentPlan?.plan_id
                                }
                                onChange={() => {
                                  handleSelectPlan(data);
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {data?.maximum_downloads}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {`${data?.discount_percentage}%`}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {data?.plan_price.toLocaleString()}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {`${data?.plan_validity} days`}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <div className="subscribe-package-perios-time">
                  <Typography
                    component="div"
                    variant="h6"
                    className="text-center fontWeight600"
                    sx={{ mt: 3 }}
                  >
                    The downloaded images/videos can be used for Lifetime.
                  </Typography>
                </div>
              </Box>
            </Box>
          ) : (
            <div
              style={{ fontWeight: "600", marginTop: "10px", textAlign: "center" }}
            >
              No Data Found
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SelectPlanModal;
