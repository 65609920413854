import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { UserRegister } from '../../Services/LoginApi';
import { temporaryUserListing } from '../../Services/userApis';
import { useDispatch, useSelector } from 'react-redux';
import { getCountry, getJobDescription, getState, } from '../../Services/commonService';
import { commonItemsSelector } from '../../redux/common';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';


function CreateUserModal({ open, handleOpen, handleLast }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [jobDesc, setJobDesc] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [countryShortName, setCountryShortName] = useState('in');
  const [stateList, setStateList] = useState([]);
  const { jobData, countryData, stateData } = useSelector(commonItemsSelector);
  const [selectedCountry, setSelcetedCountry] = useState('');
  const [selectedState, setSelectedState] = useState({});



  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let payload = {
      ...values,
      mobile: values?.mobile?.includes('+')
        ? values?.mobile
        : '+' + values?.mobile,
    }
    let resp = await UserRegister(payload)
    if (resp) {
      handleLast();
      resetForm({
        email: '',
        password: '',
        fName: '',
        lName: '',
        companyName: '',
        country: '',
        state: '',
        jobDescription: '',
        mobile: '',
        address: '',
      })
      dispatch(temporaryUserListing({
        page: String(1),
        limit: 10,
        user_type: "1",
      }))
      handleOpen(false)
    }
    setLoading(false)
  };

  const getJobDescriptionDetails = async () => {
    dispatch(getJobDescription());
  };


  const getCountryDetails = async () => {
    dispatch(getCountry());
  };

  useEffect(() => {
    if (jobData && Array.isArray(jobData?.items)) {
      let data = jobData?.items?.map((item) => {
        return {
          label: item?.name,
          value: item?.job_description_id,
        };
      });
      setJobDesc(data);
    }
  }, [jobData]);

  useEffect(() => {

    getJobDescriptionDetails();

    getCountryDetails();



  }, []);

  useEffect(() => {
    if (countryData && Array.isArray(countryData?.items)) {
      let data = countryData?.items?.map((item) => {
        return {
          label: item?.country_name,
          value: item?.country_id,
        };
      });
      setCountryList(data);
    }
  }, [countryData]);

  const handleStateChange = (val) => {
    let stateSelected = stateData?.items?.filter((item) => item.state_name == val,);
    if (stateSelected.length) {
      setSelectedState(stateSelected[0]);
    }
  };

  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  }, [stateData]);

  const handleChangeCountry = async (e) => {
    formik.setFieldValue('country', e.target.value);
    formik.setFieldValue('state', '');
    setSelcetedCountry(e.target.value || '');
    setSelectedState({});
    let country_id = 0;
    countryData?.items?.filter((val) => {
      if (val.country_name === e.target.value) {
        country_id = val.country_id;
        setCountryShortName(val?.country_short_name?.toLowerCase());
        formik.setFieldValue('mobile', '');
      }
    });
    dispatch(getState(country_id));
  };

  const handleChangeMobile = (val) => {

    formik.setFieldValue('mobile', val);

  };


  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      fName: '',
      lName: '',
      companyName: '',
      jobDescription: '',
      country: '',
      state: '',
      mobile: '',
      address: '',
      zip_code: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter Email')
        .required('Please enter Email'),
      password: Yup.string()
        .required('Password is required.')
        .max(16, 'Password should be less then 16 Character')
        .min(8, 'Password should be more then 8 Character')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        ),
      fName: Yup.string().required('First name is required.'),
      lName: Yup.string().required('Last name is required.'),
      companyName: Yup.string().required('Company name is required.'),
      jobDescription: Yup.string().required('Job description is required.'),
      country: Yup.string().required('Country is required.'),
      address: Yup.string().required('Address is required.'),
      zip_code: Yup.string().required("Zip Code is required.")
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Create User
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="email"
                  label="Email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="fName"
                  label="First Name"
                  placeholder="First Name"
                  value={formik.values.fName}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.fName && Boolean(formik.errors.fName)}
                  helperText={formik.touched.fName && formik.errors.fName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="lName"
                  label="Last Name"
                  placeholder="Last Name"
                  value={formik.values.lName}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.lName && Boolean(formik.errors.lName)}
                  helperText={formik.touched.lName && formik.errors.lName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="companyName"
                  label="Company Name"
                  placeholder="Company Name"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                  helperText={formik.touched.companyName && formik.errors.companyName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  name="jobDescription"
                  label="Job Description"
                  placeholder="Job Description"
                  SelectProps={{ native: true, }}
                  select
                  value={formik.values.jobDescription}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  onChange={formik.handleChange}
                  error={formik.touched.jobDescription && Boolean(formik.errors.jobDescription)}
                  helperText={
                    formik.touched.jobDescription &&
                    formik.errors.jobDescription}
                >
                  <option key={'Select Job Description'} value={''}>
                    Select Job Description
                  </option>
                  {jobDesc &&
                    jobDesc?.map((role) => (
                      <option key={role.value} value={role.label}>
                        {role.label}
                      </option>
                    ))}
                </CustomInput>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  name="country"
                  label="Country"
                  id="country"
                  placeholder="Country"
                  SelectProps={{ native: true, }}
                  select
                  value={formik.values.country}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  onChange={(e) => handleChangeCountry(e)}
                  error={formik.touched.country && Boolean(formik.errors.country)}
                  helperText={formik.touched.country && formik.errors.country}
                >

                  <option key={'Select Country'} value={''}>
                    Select Country
                  </option>

                  {countryList &&
                    countryList?.map((country) => (
                      <option key={country.value} value={country.label}>
                        {country.label}
                      </option>
                    ))}

                </CustomInput>
              </Grid>


              {stateData.isStateAvailable === true ? (
                <Grid item xs={12} md={6}>
                  <CustomInput
                    name="state"
                    label="State"
                    placeholder="State"
                    SelectProps={{
                      native: true,
                    }}
                    select
                    value={formik.values.state}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      classes: {
                        notchedOutline: 'rounded',
                      },
                    }}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleStateChange(e.target.value);
                    }}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  >
                    <option key={'Select State'} value={''}>
                      {formik.values.country
                        ? 'Select state'
                        : 'Please select country first'}
                    </option>
                    {stateList &&
                      stateList?.map((state) => {
                        return (
                          <option key={state.value} value={state.label}>
                            {state.label}
                          </option>
                        );
                      })}

                  </CustomInput>

                </Grid>

              ) : null}

              <Grid item xs={12} md={6} >

                <CustomPhoneInput
                  label="Mobile"
                  id="mobile"
                  name="mobile"
                  className="main-login-form"
                  fullWidth
                  placeholder="Mobile Number"
                  native={true}
                  value={formik.values.mobile}
                  defaultCountry={`${countryShortName}`}
                  country={`${countryShortName}`}
                  divStyle={{
                    paddingTop: 4,
                  }}
                  variant="outlined"
                  onChange={(newValue, info) => {
                    handleChangeMobile(newValue);
                  }}
                  onlyCountries={[countryShortName]}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                />

              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="zip_code"
                  label="Zip Code"
                  placeholder="Zip Code"
                  value={formik.values.zip_code}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
                  helperText={formik.touched.zip_code && formik.errors.zip_code}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="address"
                  label="Address"
                  placeholder="Address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Create User
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    password: '',
                    fName: '',
                    lName: '',
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateUserModal;
