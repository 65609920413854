import React, { useState } from 'react'
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton'
import MainLayout from '../Layouts/MainLayout'
import ContributorOne from './ContributorOne'

function ContributorOneContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "Buyer User",
    navSubTitle: "Welcome to ImagesBazaar"
})
const [addUserModal, setAddUserModal] = useState(false)
const handleAddUserModal = (value=false) =>{
  setAddUserModal(value)
}

const handleNavParams = (obj)=>{
  setNavParams({
      ...navParams,
      ...obj
  })
  // navBarRightComp={
  //   <CustomButton sx={{ padding: '12px 15px', ml: (theme)=>(theme.spacing(2)) }} color={'buttonPrimary'} onClick={()=>handleAddUserModal(true)}>
  //    Add User
  //  </CustomButton>}
}
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle} >
      <ContributorOne handleNavParams={handleNavParams} addUserModal={addUserModal} handleAddUserModal={handleAddUserModal}/>
    </MainLayout>
  )
}

export default ContributorOneContainer