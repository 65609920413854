import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, MenuItem } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { formatDate, handleFileReader } from '../../lib/helper';
import { fileUpload } from '../../Services/commonService';
import { uploadWeeklyImages } from '../../Services/DashboardApi';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import { suspendImage } from '../../Services/imageApis';

export default function SuspendImageModal({ open, handleOpen, data, getListing }) {
  const [loading, setLoading] = React.useState(false);

  const handleUpload = async (values, { resetForm }) => {
    try {
      setLoading(true)
      let payload = {
        image_name: data.Name,
        suspended_date: values.suspended_date,
      }
      let resp = await suspendImage(payload)
      if (resp) {
        getListing();
        handleOpen(false);
      }
      setLoading(false);
      resetForm({
        image_name: "",
        suspended_date: ""
      });
    } catch (e) {
      setLoading(false)
      console.log(e, 'error')
    }
  }
  const formik = useFormik({
    initialValues: {
      image_name: data.Name || "",
      suspended_date: formatDate(data.suspendate, "yyyy-mm-dd") || ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      image_name: Yup.string()
        .required('Image name is required.'),
      suspended_date: Yup.date()
        .required('Date is required.')

    }),
    onSubmit: handleUpload,
  });


  return (
    <>
      <Dialog
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant='h3'>Suspend Image</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} textAlign={'center'}>
                <CustomImage
                  style={{ width: 'auto', maxHeight: 200, borderRadius: 8 }}
                  src={data.image_url}
                />
              </Grid>
              <Grid item xs={12} >
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="image_name"
                  label="Image name"
                  placeholder="Image name"
                  value={formik.values.image_name}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.image_name &&
                    Boolean(formik.errors.image_name)
                  }
                  helperText={
                    formik.touched.image_name && formik.errors.image_name
                  }
                />
              </Grid>
              <Grid item xs={12} >
                <CustomInput
                  variant="outlined"
                  fullWidth
                  type="date"
                  name="suspended_date"
                  label="Suspended Date"
                  placeholder="Suspended Date"
                  value={formik.values.suspended_date}
                  onChange={(e) => {
                    formik.setFieldValue('suspended_date', formatDate(e.target.value, "yyyy-mm-dd"));
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.suspended_date &&
                    Boolean(formik.errors.suspended_date)
                  }
                  helperText={
                    formik.touched.suspended_date && formik.errors.suspended_date
                  }
                />
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "end", marginTop: 15 }}>
              <CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Suspend</CustomButton>
              <CustomButton onClick={() => handleOpen(false)} disabled={loading}>Close</CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
