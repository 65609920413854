import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { LeftArrow } from '../../Asset/svg';
import { commonItemsSelector } from '../../redux/common';
import { getInvoiceDetails } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import Divder from '../CommonComp/Divder';
import { CreditNoteTable } from './CreditNoteTable';
import { InvoiceTable } from './InvoiceTable';

function CreditNotePage({ orderId }) {
  const { appSetting } = useSelector(commonItemsSelector)
  const navigate = useNavigate()
  const theme = useTheme()
  const [staticData, setStaticData] = useState({
    company_name: "",
    company_address: "",
    company_phone: "",
    company_fax: "",
    company_cin: "",
    company_gstin: "",
    pan: "",
    hsn_sac: ""
  })
  const [invoiceDetails, setInvoiceDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const componentRef = useRef(null);
  
  const generatePDFFunction = async (data)=>{
    console.log(data,'pdf data')
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    pageStyle: "@page { size: auto; margin: 12mm 5mm; } }",
    // print: async (printIframe) => {
    //   // Do whatever you want here, including asynchronous work
    //   // get generated pdf content from generatePDFFunction function
    //   // await generatePDFFunction(printIframe);
    // },
  });

  let getInvoiceData = async (orderId)=>{
    setLoading(true)
    let resp = await getInvoiceDetails({
      order_id: orderId
    })
    if(resp){
      setInvoiceDetails(resp || null)
    }
    setLoading(false)
  }

  useEffect(()=>{
    if(orderId){
      getInvoiceData(orderId)
    }
  },[orderId])

  useEffect(()=>{
    if(appSetting.items){
      let companyData = {
        company_name: appSetting?.items?.features?.support[0]['key_value'] || "",
        company_address: appSetting?.items?.features?.support[1]['key_value'] || "",
        company_phone: appSetting?.items?.features?.support[2]['key_value'] || "",
        company_fax: appSetting?.items?.features?.support[3]['key_value'] || "",
        company_cin: appSetting?.items?.features?.support[4]['key_value'] || "",
        company_gstin: appSetting?.items?.features?.support[5]['key_value'] || "",
        pan: appSetting?.items?.features?.support[6]['key_value'] || "",
        hsn_sac: appSetting?.items?.features?.support[7]['key_value'] || ""
      }
      setStaticData(companyData)
    }
  },[appSetting])

  return (
    <Box>
      {/* <CustomBox>
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}> 
            <Box sx={{
                display: "flex",
                alignItems: "center",
                cursor: 'pointer'
            }} onClick={()=>{
                navigate(-1)
            }}>
                <LeftArrow fill={theme.palette.textButton.main}/>
                <Typography variant='h5' sx={{
                    marginLeft: theme.spacing(1),
                    color: theme.palette.textButton.main
                }}>Back</Typography>
            </Box>
            <Typography variant='h5' sx={{marginLeft: (theme)=> theme.spacing(1)}}>Invoice Detail</Typography>
            <div style={{paddingLeft: 50}}></div>
         
            <Typography
        </Box>
      </CustomBox>
      <Divder spacing={1}/> */}
      <CustomBox>
        <div style={{maxWidth: 930, margin: 'auto'}}>
          {invoiceDetails && <div style={{position: 'relative', top: 17, right: 10, textAlign: 'right', height: 0}} >
            <CustomButton onClick={()=>{handlePrint()}} className="buttonDense" color={'buttonPrimary'}>Print</CustomButton>
          </div>}
          <CreditNoteTable data={invoiceDetails} loading={loading} staticData={staticData} ref={componentRef}/>
        </div>
      </CustomBox>
    </Box>
  );
}

export default CreditNotePage;
