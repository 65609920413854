import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createSubString, downloadBlobFile, getObjectSubset, formatDate, getNewParameters, getParameters, validDate, currencyConversion, breakStringIntoLines } from '../../lib/helper';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import MatTable from '../Tables/MatTable';
import { contributorItemsSelector, setResetContributorDailySalesReport } from '../../redux/contributor';
import { getDailySalesReport, getReportListing } from '../../Services/ContributorApis';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AllFilters from '../Order/AllFilters';

const limit = 10;

const column = [
  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (

      <Typography
        variant='span'
        style={{ maxWidth: 300, width: 'max-content' }}
      >
        {columnData}
      </Typography>

    ),
  },

  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData?breakStringIntoLines(columnData,12):'N/A'}
      </span>
    ),
  },
  {
    label: 'Invoice Date',
    id: 'invoice_date',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {formatDate(columnData, 'dd/mm/yyyy')}
      </span>
    ),
  },
  {
    label: 'Initial Price(INR)',
    id: 'initial_price',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData ? currencyConversion(columnData) : '0'}

      </span>
    ),
    nextLine:true,
  },

  {
    label: 'Discount (INR)',
    id: 'discount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData ? currencyConversion(columnData) : '0'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'After Discount (INR)',
    id: 'after_discount',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData ? currencyConversion(columnData) : '0'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Tax(%)',
    id: 'tax_percentage',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'IGST (INR)',
    id: 'igst',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData ? currencyConversion(columnData) : '0'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'CGST (INR)',
    id: 'cgst',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData ? currencyConversion(columnData) : '0'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'SGST (INR)',
    id: 'sgst',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData ? currencyConversion(columnData) : '0'}
      </span>
    ),
    nextLine:true,
  },
  {
    label: 'Total Tax(INR)',
    id: 'total_tax',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData ? currencyConversion(columnData) : '0'}
      </span>
    ),
    nextLine:true,
  },
  //
  {
    label: 'After Tax(INR)',
    id: 'after_tax',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData ? currencyConversion(columnData) : '0'}

      </span>
    ),
    nextLine:true
  },
  {
    label: 'Address',
    id: 'address',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData?breakStringIntoLines(columnData,20):'N/A'}
      </span>
    ),
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
                {columnData?breakStringIntoLines(columnData,20):'N/A'}
      </span>
    ),
  },
  {
    label: 'State Short Name',
    id: 'state_short_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData || '-'}
      </span>
    ),
    nextLine:true
  },
  {
    label: 'State Code',
    id: 'state_code',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData || '-'}
      </span>
    ),
    nextLine:true
  },
  {
    label: 'Country',
    id: 'country',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData || '-'}
      </span>
    ),
  },

  {
    label: 'GST Number',
    id: 'gst_number',
    format: (columnData) => (
      <span style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}>
        {columnData || '-'}
      </span>
    ),
  },
];


function ContributorsDailySalesReport() {
  const [fileResp, setFileResp] = useState({
    status: false,
    data: null
  })
  const [loading, setLoading] = useState(false)
  const [tableListing, setTableListing] = useState([]);
  const { contributorDailySalesReport } = useSelector(contributorItemsSelector);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();


  const [filtersArray, setFiltersArray] = useState([

    {
      label: 'From Date',
      id: 'from',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'to',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
   
  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getOrderListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';

      return newState;
    });
    dispatch(setResetContributorDailySalesReport());
    setTableListing([])

  }

  useEffect(() => {
    if (contributorDailySalesReport?.items?.result?.length >= 0) {
      setTableListing(contributorDailySalesReport?.items?.result)
    }
  }, [contributorDailySalesReport.items]);

  const handleCSVDownload = async () => {
    if (formik?.values.to && formik?.values.from) {
      let resp = await getDailySalesReport(formik.values)
      if (resp.status) {
        downloadBlobFile(resp.data, `ContributorDailySalesReport${formik?.values.from}-/-${formik?.values.to}`)
      }
    }
    else {
      toast.error("To and From Date Required")
    }
  }

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (formik?.values?.start_date && formik?.values?.end_date) {
      if (data?.search !== location.search) {
        navigate(data.newUrl, { replace: false })
        if (data?.parameters?.page) {
          setPage(Number(data?.parameters?.page))
        }
      }

      getOrderListing();
    }
  }, [allParams])


  useEffect(() => {
    dispatch(setResetContributorDailySalesReport());
    setTableListing([])
  }, [])

  const getOrderListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // order_status: String(1),
      from: formik?.values?.from,
      to: formik?.values?.to,
      ...allParams,
      ...params
    }
    if (payload?.from) {
      payload['from'] = formatDate(payload?.from, 'yyyy-mm-dd') + "  00:00:00"
    }
    if (payload?.to) {
      payload['to'] = formatDate(payload?.to, 'yyyy-mm-dd') + "  23:59:59"
    }
    dispatch(getReportListing(payload))
    setPage(Number(payload.page))
  }


  const handleConfirm = async (values, resetForm) => {
    setLoading(true)
    if (values.to && values.from) {
      getOrderListing()
    }
    else {
      toast.error("To and From Date Required")
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      from: "",
      to: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      from: Yup.string().required('From Date is required.'),
      to: Yup.string().required('To Date is required.'),
    }),
    onSubmit: handleConfirm,
  });

  const getExportFile = async (params = {}, b, prepop) => {
    try {

      let payload = {
        ...allParams,
        ...params
      }
      delete payload.page
      delete payload.limit
      setLoading(true);
      if (prepop?.from && prepop?.to) {

        if (prepop?.from) {
          prepop['from'] = formatDate(prepop?.from, 'yyyy-mm-dd') + "  00:00:00"
        }
        if (prepop?.to) {
          prepop['to'] = formatDate(prepop?.to, 'yyyy-mm-dd') + "  23:59:59"
        }
        let resp = await getDailySalesReport(prepop)
        if (resp.status) {
          setFileResp(resp)
          downloadBlobFile(resp.data, `ContributorDailySalesReport${formik?.values.from}-/-${formik?.values.to}`)

        }
      }
      else {
        toast.error("Start and End Date required!")
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <CustomBox padding={0} sx={{ minHeight: 200 }}>
      <MatTable
        loading={contributorDailySalesReport?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}

        page={page}
        perPage={limit}
        total={contributorDailySalesReport?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            hasDownloadExcelButton={true}
            handleDownloadExcel={getExportFile}
          />
        )}
        pagination={true}
        filtersUrlData={allParams || {}}

      />
    </CustomBox>

  );
}

export default ContributorsDailySalesReport;
