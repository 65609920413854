import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import { modelData } from '../../data/modelData';
import ModelTabBody from '../../Components/Models/ModelTabBody';

function Model({handleNavParams}) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  }


  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={0}>
        <CustomTabs tabList={modelData.tabs} tabClick={handleTabs} />
        {/* <SubscriptionList/> */}
       
      </CustomBox>
      <Divder spacing={1} />
      <ModelTabBody activeTab={tabValue} />
      <Divder spacing={1.2} />
    </Box>
  );
}

export default Model;
