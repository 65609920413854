import { Typography } from '@mui/material';
import React from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CommonCMS from './CommonCMS';
import SearchTips from './SearchTips';
import Ordering from './Ordering';
import Downloading from './Downloading';
import Images from './HomePage';
import AboutUS from './AboutUS';
import Testimonials from './Testimonials';
import Contacts from './Contacts';
import FAQPage from './FAQPage';
import Downloadings from './Downloadings';
import Licensing from './Licensing';
import Wishlist from './Wishlist';
import MyAccount from './MyAccount';
import Glosarry from './Glosarry';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';
import OfficeAddress from './OfficeAddress';
import CustomerSupport from './CustomerSupport';
import ImagePricing from './ImagePricing';
import VideoPricing from './VideoPricing';
import TechnicalDownloadTime from './TechnicalDownloadTime';
// import Banners from './Banners';
import BannersBackground from './BannersBackground';
import Banners from './Banners';
import BannersCrousel from './BannersCrousel';
import TechnicalPage from './TechnicalPage';

function CmsTabBody({ activeTab }) {
  return (
    <>
      {/* <CustomBox padding={0} sx={{minHeight: 200, display: "flex", justifyContent: "center", alignItems: "center"}}> */}
      {activeTab == 1 && <CommonCMS />}
      {activeTab == 2 && <Images />}
      {activeTab == 3 && <AboutUS />}
      {activeTab == 4 && <Testimonials />}
      {activeTab == 5 && <Contacts />}
      {activeTab == 6 && <FAQPage />}
      {activeTab == 7 && <SearchTips />}
      {activeTab == 8 && <Ordering />}
      {activeTab == 9 && <Downloadings />}
      {activeTab == 10 && <Licensing />}
      {activeTab == 11 && <Wishlist />}
      {activeTab == 12 && <MyAccount />}
      {activeTab == 13 && <Glosarry />}
      {activeTab == 14 && <TermsOfUse />}
      {activeTab == 15 && <PrivacyPolicy />}
      {activeTab == 16 && <OfficeAddress />}
      {activeTab == 17 && <CustomerSupport />}
      {activeTab == 18 && <ImagePricing />}
      {activeTab == 19 && <VideoPricing />}
      {activeTab == 20 && <TechnicalPage />}
      {activeTab == 21 && <BannersBackground/>}
      {activeTab == 22 && <BannersCrousel/>}

      {/* </CustomBox> */}
    </>
  );
}

export default CmsTabBody;
