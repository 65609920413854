import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import ImageTabBody from '../../Components/Image/ImageTabBody';
import { ImageData } from '../../data/imageData';
import { useNavigate, useSearchParams } from 'react-router-dom';
import InvoicePage from '../../Components/Receipts/InvoicePage';
import { decrypt } from '../../lib/helper';
import { toast } from 'react-toastify';
import CreditNotePage from '../../Components/Receipts/CreditNotePage';
import ApiConfig from '../../config/ApiConfig';

function CreditNote({ handleNavParams }) {
  const [orderId, setOrderId] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(()=>{
    try{
      let orderId = decrypt(ApiConfig.REACT_APP_SALT + 'order_id', searchParams.get('orderId')) 
      if(!orderId || orderId == null){
        toast.error('Please provide order id.')  
        navigate('../dashboard', {replace: true})
      }else{
        setOrderId(orderId)
      }
    }catch(e){
      toast.error('Please provide order id.')  
      navigate('../dashboard', {replace: true})
    }
  },[searchParams])  
  
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CreditNotePage orderId={orderId}/>
      <Divder spacing={1.2} />
    </Box>
  );
}

export default CreditNote;
