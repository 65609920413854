import React, {  } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { LeftArrow } from '../../Asset/svg';
import { Typography, useTheme } from '@mui/material';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import UserDetailPage from '../../Components/User/UserDetailPage';
import { useDispatch } from 'react-redux';
import { clearSingleUserDetails } from '../../redux/user';

function UserDetails() {
  const theme = useTheme();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {userId} = useParams();

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox>
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}> 
            <Box sx={{
                display: "flex",
                alignItems: "center",
                cursor: 'pointer'
            }} onClick={()=>{
                navigate(-1)
                dispatch(clearSingleUserDetails())
            }}>
                <LeftArrow fill={theme.palette.textButton.main}/>
                <Typography variant='h5' sx={{
                    marginLeft: theme.spacing(1),
                    color: theme.palette.textButton.main
                }}>Back</Typography>
            </Box>
            <Typography variant='h5' sx={{marginLeft: (theme)=> theme.spacing(1)}}>User Details</Typography>
            <div style={{width: 100}}></div>
            {/* <CustomButton sx={{ padding: '12px 15px' }} color={'buttonPrimary'}>Print</CustomButton> */}
            {/* <Typography */}
        </Box>
      </CustomBox>
      <Divder spacing={1} />
      <UserDetailPage userId={userId}/>
      <Divder spacing={1.2} />
    </Box>
  );
}

export default UserDetails;
