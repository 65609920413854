import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  modelList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  newmodelsList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  modelImagesList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  modelName:{
    modelNameForimage:''
  },
  modelImageUnAssignedList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  modelImageAssignedList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  modelImageList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
 
};

export const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setModelListing: (state, action) => {
      state.modelList.items = action.payload;
      state.modelList.error = {
        isError: false,
        message: '',
      };
    },

    setModelListingLoading: (state, action) => {
      state.modelList.loading = action.payload;
    },

    setModelListingError: (state, action) => {
      state.modelList.items = {};
      state.modelList.error = action.payload;
    },
    //modelsList
    setnewModelsListing: (state, action) => {
      state.newmodelsList.items = action.payload;
      state.newmodelsList.error = {
        isError: false,
        message: '',
      };
    },

    setnewModelsListingLoading: (state, action) => {
      state.newmodelsList.loading = action.payload;
    },

    setnewModelListingError: (state, action) => {
      state.newmodelsList.items = {};
      state.newmodelsList.error = action.payload;
    },

    setModelImagesListing: (state, action) => {
      state.modelImagesList.items = action.payload;
      state.modelImagesList.error = {
        isError: false,
        message: '',
      };
    },

    setModelImagesListingLoading: (state, action) => {
      state.modelImagesList.loading = action.payload;
    },

    setModelImagesListingError: (state, action) => {
      state.modelImagesList.items = {};
      state.modelImagesList.error = action.payload;
    },

    setModelImageUserName:(state,action)=>{
      state.modelName.modelNameForimage=action.payload;
    },


    setModelUnAssignedImageListing: (state, action) => {
      state.modelImageUnAssignedList.items = action.payload;
      state.modelImageUnAssignedList.error = {
        isError: false,
        message: '',
      };
    },
    setModelUnAssignedListingLoading: (state, action) => {
      state.modelImageUnAssignedList.loading = action.payload;
    },
    setModelUnAssignedListingError: (state, action) => {
      state.modelImageUnAssignedList.items = {};
      state.modelImageUnAssignedList.error = action.payload;
    },

    setModelAssignedImageListing: (state, action) => {
      state.modelImageAssignedList.items = action.payload;
      state.modelImageAssignedList.error = {
        isError: false,
        message: '',
      };
    },
    setModelAssignedListingLoading: (state, action) => {
      state.modelImageAssignedList.loading = action.payload;
    },
    setModelAssignedListingError: (state, action) => {
      state.modelImageAssignedList.items = {};
      state.modelImageAssignedList.error = action.payload;
    },

    setAllModelImageListing: (state, action) => {
      state.modelImageList.items = action.payload;
      state.modelImageList.error = {
        isError: false,
        message: '',
      };
    },
    setAllModelListingLoading: (state, action) => {
      state.modelImageList.loading = action.payload;
    },
    setAllModelListingError: (state, action) => {
      state.modelImageList.items = {};
      state.modelImageList.error = action.payload;
    },
  },
});

export default modelSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setModelListing,
  setModelListingLoading,
  setModelListingError,
  setnewModelsListing,
  setnewModelsListingLoading,
  setnewModelListingError,
  setModelImagesListing,
  setModelImagesListingLoading,
  setModelImagesListingError,
  setModelImageUserName,
  setModelUnAssignedImageListing,
  setModelUnAssignedListingError,
  setModelUnAssignedListingLoading,
  setModelAssignedImageListing,
  setModelAssignedListingLoading,
  setModelAssignedListingError,
  setAllModelListingError,
  setAllModelListingLoading,
  setAllModelImageListing
} = modelSlice.actions;

export const modelItemsSelector = (state) => state.model;
