import React, { useState } from 'react';
import MainLayout from '../Layouts/MainLayout';
import FollowUpsByOrderIdForPayment from './FollowUpsByOrderIdForPayment';

function PaymentFollowUpsList() {
  const [navParams, setNavParams] = useState({
    navTitle: 'Payment Follow Ups By Order ID',
    navSubTitle: 'Welcome to ImagesBazaar',
  });

  return (
    <MainLayout
      navTitle={navParams.navTitle}
      navSubTitle={navParams.navSubTitle}
    >
      <FollowUpsByOrderIdForPayment />
    </MainLayout>
  );
}

export default PaymentFollowUpsList;
