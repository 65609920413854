import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loginItemsSelector } from '../../redux/login';
import { adminUsersListing, getAdminById, updateAdminUser } from '../../Services/adminUsersApis';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box,Grid } from '@mui/material';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { DepartmentData } from '../../data/contributorsData';
const limit = 10;

function AccountUpdate() {
 
  const { user } = useSelector(loginItemsSelector);
  const [selectedData,setSelectedData]=useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
  
    getListing()
  }, [user?.userDetails?.admin_id])

  const getListing = async() => {
    if(user?.userDetails?.admin_id){
    let resp=await getAdminById({admin_id: user?.userDetails?.admin_id})
    setSelectedData(resp?.data)
    }
  }

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await updateAdminUser(values);
    if (resp) {
      resetForm();
      getListing()
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      admin_id:selectedData?.admin_id || "",
      email: selectedData?.email || '',
      first_name: selectedData?.first_name || '',
      last_name: selectedData?.last_name || '',
      phone_number: String(selectedData?.phone_number) || '',
      is_superadmin: selectedData?.is_superadmin || '0',
      department:selectedData?.department || '',
      status: selectedData?.status || '0',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      admin_id: Yup.string().required('Admin id is required.'),
      email: Yup.string().email('Please enter Email').required('Please enter email'),
      first_name: Yup.string().required('First Name is required.'),
      last_name: Yup.string().required('Last Name is required.'),
      phone_number: Yup.string().required('Mobile Number is required')
        .max(18, "Mobile Number is Invalid")
        .min(10, "Mobile Number is Invalid"),
    }),
    onSubmit: handleConfirm,
  });
  return (
    <>
      <CustomBox>
        <Box
          noValidate
          component="form"
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="email"
                label="Email"
                placeholder="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                disabled
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="first_name"
                label="First Name"
                placeholder="First Name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                helperText={formik.touched.first_name && formik.errors.first_name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="last_name"
                label="Last Name"
                placeholder="Last Name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomPhoneInput
                label="Mobile"
                id="phone_number"
                name="phone_number"
                className="main-login-form"
                fullWidth
                placeholder="Mobile Number"
                native={true}
                value={formik.values.phone_number}
                defaultCountry={`in`}
                country={`in`}
                divStyle={{
                  paddingTop: 4
                }}
                variant="outlined"
                onChange={(newValue, info) => {
                  formik.setFieldValue("phone_number", newValue);
                }}
                onlyCountries={['in']}
                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                helperText={formik.touched.phone_number && formik.errors.phone_number}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="department"
                label="Department"
                placeholder="Department"
                value={formik.values.department}
                select
                SelectProps={{
                  native: true,
                }}
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
              >
                <option value={''}>Select</option>
                {DepartmentData?.map((dep,index)=>{
                  return <option value={dep?.value}>{dep?.value}</option>
                })}
              </CustomInput>
            </Grid>
          </Grid>
          <div
            style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
          >
            <CustomButton
              color={'buttonPrimary'}
              type="submit"
              loading={loading}
            >
              Submit
            </CustomButton>
            <CustomButton
              onClick={() => {
                formik.resetForm({
                  email: '',
                  password: '',
                  first_name: '',
                  last_name: '',
                });
              }
              }
              disabled={loading}
            >
              Cancel
            </CustomButton>
          </div>
        </Box>
      </CustomBox>

    </>
  );
}

export default AccountUpdate;
