import React from 'react';

const SortAsc = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.39992 9H13.6001C13.6792 8.99998 13.7565 8.97331 13.8223 8.92336C13.888 8.87342 13.9393 8.80243 13.9696 8.71939C13.9998 8.63634 14.0077 8.54497 13.9923 8.45681C13.9769 8.36865 13.9388 8.28766 13.8829 8.22409L10.2828 4.1332C10.2457 4.09097 10.2016 4.05747 10.1531 4.03462C10.1045 4.01176 10.0525 4 10 4C9.94747 4 9.89546 4.01176 9.84693 4.03462C9.79841 4.05747 9.75432 4.09097 9.71719 4.1332L6.11712 8.22409C6.06119 8.28766 6.02311 8.36865 6.00768 8.45681C5.99225 8.54497 6.00018 8.63634 6.03044 8.71939C6.06071 8.80243 6.11197 8.87342 6.17774 8.92336C6.2435 8.97331 6.32082 8.99998 6.39992 9Z" fill="#D6D9DE" />
            <path d="M6.39992 11H13.6001C13.6792 11 13.7565 11.0267 13.8223 11.0766C13.888 11.1266 13.9393 11.1976 13.9696 11.2806C13.9998 11.3637 14.0077 11.455 13.9923 11.5432C13.9769 11.6314 13.9388 11.7123 13.8829 11.7759L10.2828 15.8668C10.2457 15.909 10.2016 15.9425 10.1531 15.9654C10.1045 15.9882 10.0525 16 10 16C9.94747 16 9.89546 15.9882 9.84693 15.9654C9.79841 15.9425 9.75432 15.909 9.71719 15.8668L6.11712 11.7759C6.06119 11.7123 6.02311 11.6314 6.00768 11.5432C5.99225 11.455 6.00018 11.3637 6.03044 11.2806C6.06071 11.1976 6.11197 11.1266 6.17774 11.0766C6.2435 11.0267 6.32082 11 6.39992 11Z" fill="#5C677D" />
        </svg>

    );
};

export default SortAsc;