export const CategoryData = {
    tabs: [
      {
        label: 'Image Categories',
        id: 1,
      },
      {
        label: 'Video Categories',
        id: 2,
      },
      {
        label: 'Star Categories',
        id: 3,
      }
    ],
  };