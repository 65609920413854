import React from 'react'
import CustomBox from '../CommonComp/CustomBox/CustomBox'
import ContributorsReport from './ContributorsReport';
import DailySalesReport from './DailySalesReport';
import PrivilegeReport from './PrivilegeReport';
import MultipleDailyReport from './MultipleDailyReport';
import DailyReportMultiple from './DailyReportMultiple';

function ReportsTabBody({activeTab}) {
  return (
    <CustomBox padding={0} sx={{minHeight: 200}}>
       {activeTab == 1 && <DailySalesReport/>}
       {activeTab == 2 && <ContributorsReport/>}
       {activeTab == 3 && <PrivilegeReport/>}
       {activeTab == 4 && <MultipleDailyReport/>}
    </CustomBox>
  )
}

export default ReportsTabBody