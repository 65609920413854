import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import ShortCompanies from './ShortCompanies'



function ShortCompanyContainer() {
  return (
    <MainLayout navTitle={"Short Company"} navSubTitle={"Welcome to ImagesBazaar"}>
      <ShortCompanies/>
    </MainLayout>
  )
}

export default ShortCompanyContainer