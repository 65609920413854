import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import ExpiryEmail from './ExpiryEmail'

function ExpiryEmailContainer() {
  return (
    <MainLayout navTitle={"Expiry Email"} navSubTitle={"Welcome to ImagesBazaar"}>
        <ExpiryEmail/>
    </MainLayout>
  )
}

export default ExpiryEmailContainer