import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allImages: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  allImagesNew: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  starImages: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  suspendedImages: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  soldImages: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  soldImagesPending: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  listOfSoldImagesByGroup: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  listOfSoldImagesByGroupIds: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  allShootIds: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },

  shootImagesList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllImagesListing: (state, action) => {
      state.allImages.items = action.payload;
      state.allImages.error = {
        isError: false,
        message: '',
      };
    },
    setAllImagesListingLoading: (state, action) => {
      state.allImages.loading = action.payload;
    },
    setAllImagesListingError: (state, action) => {
      state.allImages.items = {};
      state.allImages.error = action.payload;
    },

    setAllImagesNewListing: (state, action) => {
      state.allImagesNew.items = action.payload;
      state.allImagesNew.error = {
        isError: false,
        message: '',
      };
    },
    setAllImagesNewListingLoading: (state, action) => {
      state.allImagesNew.loading = action.payload;
    },
    setAllImagesNewListingError: (state, action) => {
      state.allImagesNew.items = {};
      state.allImagesNew.error = action.payload;
    },

    setStarImagesListing: (state, action) => {
      state.starImages.items = action.payload;
      state.starImages.error = {
        isError: false,
        message: '',
      };
    },
    setStarImagesListingLoading: (state, action) => {
      state.starImages.loading = action.payload;
    },

    setStarImagesListingError: (state, action) => {
      state.starImages.items = {};
      state.starImages.error = action.payload;
    },

    setSuspendedImagesListing: (state, action) => {
      state.suspendedImages.items = action.payload;
      state.suspendedImages.error = {
        isError: false,
        message: '',
      };
    },
    setSuspendedImagesListingLoading: (state, action) => {
      state.suspendedImages.loading = action.payload;
    },
    setSuspendedImagesListingError: (state, action) => {
      state.suspendedImages.items = {};
      state.suspendedImages.error = action.payload;
    },
    setSoldImagesListing: (state, action) => {
      state.soldImages.items = action.payload;
      state.soldImages.error = {
        isError: false,
        message: '',
      };
    },
    
    setSoldImagesListingLoading: (state, action) => {
      state.soldImages.loading = action.payload;
    },
    setSoldImagesListingError: (state, action) => {
      state.soldImages.items = {};
      state.soldImages.error = action.payload;
    },
    setSoldImagesPendingListing: (state, action) => {
      state.soldImagesPending.items = action.payload;
      state.soldImagesPending.error = {
        isError: false,
        message: '',
      };
    },
    setSoldImagesPendingListingLoading: (state, action) => {
      state.soldImagesPending.loading = action.payload;
    },
    setSoldImagesPendingListingError: (state, action) => {
      state.soldImagesPending.items = {};
      state.soldImagesPending.error = action.payload;
    },
    setSoldImagesByGroupListing: (state, action) => {
      state.listOfSoldImagesByGroup.items = action.payload;
      state.listOfSoldImagesByGroup.error = {
        isError: false,
        message: '',
      };
    },
    setSoldImagesByGroupListingLoading: (state, action) => {
      state.listOfSoldImagesByGroup.loading = action.payload;
    },
    setSoldImagesByGroupListingError: (state, action) => {
      state.listOfSoldImagesByGroup.items = {};
      state.listOfSoldImagesByGroup.error = action.payload;
    },
    setSoldImagesByGroupIdsListing: (state, action) => {
      state.listOfSoldImagesByGroupIds.items = action.payload;
      state.listOfSoldImagesByGroupIds.error = {
        isError: false,
        message: '',
      };
    },
    setSoldImagesByGroupIdsListingLoading: (state, action) => {
      state.listOfSoldImagesByGroupIds.loading = action.payload;
    },
    setSoldImagesByGroupIdsListingError: (state, action) => {
      state.listOfSoldImagesByGroupIds.items = {};
      state.listOfSoldImagesByGroupIds.error = action.payload;
    },
    clearImagesListings: (state, action) => {
      state.listOfSoldImagesByGroupIds.items = {};
    },

    setAllShootListing: (state, action) => {
      state.allShootIds.items = action.payload;
      state.allShootIds.error = {
        isError: false,
        message: '',
      };
    },
    setAllShootListingLoading: (state, action) => {
      state.allShootIds.loading = action.payload;
    },
    setAllShootListingError: (state, action) => {
      state.allShootIds.items = {};
      state.allShootIds.error = action.payload;
    },

    setModelShootImageListing: (state, action) => {
      state.shootImagesList.items = action.payload;
      state.shootImagesList.error = {
        isError: false,
        message: '',
      };
    },
    setModelShootImageListingLoading: (state, action) => {
      state.shootImagesList.loading = action.payload;
    },
    setModelShootImageListingError: (state, action) => {
      state.shootImagesList.items = {};
      state.shootImagesList.error = action.payload;
    },
  },
});

export default imageSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllImagesListing,
  setAllImagesListingError,
  setSuspendedImagesListing,

  setAllImagesNewListing,
  setAllImagesNewListingError,
  setAllImagesNewListingLoading,

  setSuspendedImagesListingError,
  setSoldImagesListing,
  setSoldImagesListingError,
  setSoldImagesByGroupListing,
  setSoldImagesByGroupListingError,
  setSoldImagesByGroupIdsListing,
  setSoldImagesByGroupIdsListingError,
  clearImagesListings,
  setAllImagesListingLoading,
  setSuspendedImagesListingLoading,
  setSoldImagesListingLoading,
  setSoldImagesByGroupListingLoading,
  setSoldImagesByGroupIdsListingLoading,
  setSoldImagesPendingListing,
  setSoldImagesPendingListingLoading,
  setSoldImagesPendingListingError,
  setStarImagesListing,
  setStarImagesListingLoading,
  setStarImagesListingError,

  setAllShootListing,
  setAllShootListingLoading,
  setAllShootListingError,

  setModelShootImageListing,
  setModelShootImageListingLoading,
  setModelShootImageListingError
 
} = imageSlice.actions;

export const imageListingItemsSelector = (state) => state.imageList;
