import { Close } from '@material-ui/icons';
import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ApiConfig from '../../config/ApiConfig';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomImage from '../CommonComp/CustomImage/CustomImage';
import Divder from '../CommonComp/Divder';

function PreviewStarImageComp({
    item,
    updateData,
    handleDelete,
    showImageType,
    index,
    extraSection,
}) {

    return (
        <Box
            sx={{
                display: 'flex',
                //   justifyContent: "center",
                flexDirection: 'column',
                // maxWidth: 175,
                marginRight: 2,
                marginBottom: 2,
                bgcolor: (theme) => theme.backgroundColor.box.primary,
                px: 0.5,
                pb: 1,
                borderRadius: 5,
                position: 'relative',
            }}
        >
            <CustomImage
                src={`${ApiConfig.BASE_IMAGE_URL}${item?.shootid}/${item?.rank}-${item?.image_name}.jpg`}
                style={{
                    maxWidth: 170,
                    marginTop: 5,
                    maxHeight: 110,
                    borderRadius: 10,
                    width: 165,
                    height: 110,
                }}
            />
            <Typography variant="h6" mt={1} pl={1}>
                {item?.image_name}
            </Typography>

            {extraSection && (
                <Box pl={1}>
                    <Divder spacing={0.3} />
                    {extraSection()}
                </Box>
            )}
            {handleDelete && (
                <div
                    onClick={() => { handleDelete(index) }}
                    style={{
                        position: 'absolute',
                        top: 4,
                        cursor: 'pointer',
                        right: 4,
                        height: 20,
                        width: 20,
                        background: '#fff',
                        borderRadius: '6px',
                        boxShadow: '0 0 9px -3px #000',
                    }}
                >
                    <Close style={{ fontSize: 20 }} />
                </div>
            )}
        </Box>
    );
}

export default PreviewStarImageComp;

PreviewStarImageComp.defaultProps = {
    showImageType: true,
};
