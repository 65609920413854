import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Tooltip } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { DATA_LIMIT } from '../../data/constants';
import { createTestimonail, editTestimonial, getTestimonial } from '../../Services/cmsService';
import { fileUploadImage } from '../../Services/commonService';
import ApiConfig from '../../config/ApiConfig';
import { Edit } from '@material-ui/icons';

const limit = DATA_LIMIT;

function AddEditTestimonialModal({ open, handleOpen, data, setPage, type, setSelectedTestimonal }) {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(type == 'add' ? true : false);
  const dispatch = useDispatch();
  let baseurl = ApiConfig?.S3_BASE_URL;

  const handleConfirm = async (values, { resetForm }) => {
    try {
      setLoading(true)
      let uploadedUrls = {
        image_url: type == 'edit' ? values?.image_url || '' : "",
      }
      if (editMode) {
        for await (const [key, value] of Object.entries(values)) {
          if (key == 'image_url') {
            const formData = new FormData();
            formData.append("uploaded_file", value);
            formData.append("folder_name", 'testimonials');
            let data = await fileUploadImage(formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            uploadedUrls[key] = data.data
          }
        }
      }
// in above code we are uploading image only in case of adding image now, eithe addingor editing
      if (uploadedUrls.image_url) {
        let payload = {
          ...values,
          image_url: uploadedUrls.image_url
        }
        let resp = false;
        if (type == 'add') {
          resp = await createTestimonail(payload)
        }
        if (type == 'edit') {
          resp = await editTestimonial({ ...payload, testimonial_id: data?.testimonial_id })
        }
        if (resp) {
          resetForm({
            name: '',
            image_url: '',
            subject: '',
            description: '',
          })
          dispatch(getTestimonial({
            page: String(1),
            limit: limit,
          }))
          setPage(1)
          if (setSelectedTestimonal) {
            setSelectedTestimonal()
          }
          handleOpen(false)
        }
        setEditMode(false)
        setLoading(false);

      }

    } catch (e) {
      setLoading(false)
      console.log(e, 'error')
    }
  };

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      image_url: data?.image_url || '',
      subject: data?.subject || '',
      description: data?.description || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required.'),
      ...(editMode && {
        image_url: Yup.mixed()
          .required('Image is required.')
          .test('fileType', "Unsupported File Format. It should be Image(.jpeg, .png) file only.", value => ['image/jpeg', 'image/png'].includes(value?.type))
      }),
      subject: Yup.string().required('Subject is required.'),
      description: Yup.string().required('Description is required.'),
    }),
    onSubmit: handleConfirm,
  });

  const handleFileName = (file) => {
    if (type == 'edit') {
      let newFile = new File(file, data?.image_url, { type: file.type });
    }
    else {
      formik.setFieldValue('image_url', file);

    }
  }

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        fullWidth
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          {type == 'add' ? "Add Testimonial" : "Edit Testimonial"}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="name"
                  label="Name"
                  placeholder="Name"
                  value={formik.values.name}
                  // multiline
                  // rows={4}
                  onChange={(e) => formik.setFieldValue("name", e.target.value?.toUpperCase())}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {formik?.values?.image_url && !editMode && type == 'edit' ? <div style={{ display: 'flex', }}>

                  <img src={`${baseurl}testimonials/${formik?.values?.image_url}`}
                    style={{ width: 80, height: 80, borderRadius: '50%' }}
                  />
                  <Tooltip title="Change Image">
                    <Edit onClick={(e) => setEditMode(true)} />
                  </Tooltip>
                </div> :
                  <>
                    <CustomInput
                      variant="outlined"
                      fullWidth
                      name="image_url"
                      label="Image"
                      placeholder="Image"
                      type="file"
                      onChange={(e) => {
                        if (type == 'edit') {
                          if (data?.image_url) {
                            let newFile = new File([e.target.files[0]], data?.image_url, { type: e.target.files[0].type });
                            formik.setFieldValue('image_url', newFile);
                          }
                          else {
                            formik.setFieldValue('image_url', e.target.files[0]);
                          }

                        } else {
                          formik.setFieldValue('image_url', e.target.files[0]);
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        classes: {
                          notchedOutline: 'rounded',
                        },
                      }}
                      error={
                        formik.touched.image_url &&
                        Boolean(formik.errors.image_url)
                      }
                      helperText={
                        formik.touched.image_url && formik.errors.image_url
                      }
                    />

                  </>}



              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="subject"
                  label="Subject"
                  placeholder="Subject"
                  // multiline
                  // rows={10}
                  value={formik.values.subject}
                  onChange={(e) => formik.setFieldValue("subject", e.target.value?.toUpperCase())}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.subject && Boolean(formik.errors.subject)}
                  helperText={formik.touched.subject && formik.errors.subject}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="description"
                  label="Description"
                  placeholder="Description"
                  multiline
                  rows={5}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                {type == 'add' ? 'Add' : 'Update'}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    name: '',
                    image_url: '',
                    subject: '',
                    description: '',
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddEditTestimonialModal;
