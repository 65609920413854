import React, { useEffect, useState } from 'react'
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton'
import MainLayout from '../Layouts/MainLayout'
import AddKeywords from './AddKeywords'
import AddVideo from './AddVideo'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getParameters } from '../../lib/helper'
import { videosItemsSelector } from '../../redux/videos'
import { DATA_LIMIT, NEW_DATA_LIMIT } from '../../data/constants'
import { videosListings, videosListingsNew } from '../../Services/videosApis'
const limit = NEW_DATA_LIMIT;

function AddVideoContainer() {
  const [navParams, setNavParams] = useState({
      navTitle: "Add Video",
      navSubTitle: "Welcome to ImagesBazaar"
  })

  const [uploadCsvModal, setUploadCsvModal] = useState(false);
  const [uploadKeywords,setUploadKeywords]=useState(false);
  const dispatch = useDispatch();

  const handleModal = (value = false) => {
    setImageEditModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      insertion_status:'new',
    };
    dispatch(videosListingsNew(payload));
  };


  const handleSuspendModal = (value = false) => {
    setImageSuspendModal(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };
  const handleImageKeywordModal = (value = false) => {
    setImageKeyword(value);
    if (!value) {
      setTimeout(() => {
        setEditImageData({});
      }, 500);
    }
  };
  const handleAllKeywordsForimage=async(data)=>{
   
      let response=await getListOfImageKeywords(data?.Name);
      if( response?.status==200){
        setKeywordForTheChoosenImage(response?.data);
        handleImageKeywordModal(true)
      }
   
  }

  const handleNavParams = (obj)=>{
    setNavParams({
        ...navParams,
        ...obj
    })
  }
  const handleCsvModal = (value)=>{
    setUploadCsvModal(value || false);
    if(!value){ getListing();}
   
  }

  const handleImageCsvModal = (value)=>{
    setUploadKeywords(value || false)
  }

  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}
      navBarRightComp={<>
       <CustomButton sx={{ padding: '10px 20px',whiteSpace:'nowrap', ml: (theme)=>(theme.spacing(2)) }} onClick={()=>{handleImageCsvModal(true)}} color={'buttonPrimary'} className="textDecoration-none">
              Upload Repair Keywords CSV 
          </CustomButton>
          <CustomButton sx={{ padding: '10px 20px',whiteSpace:'nowrap', ml: (theme)=>(theme.spacing(2)) }} onClick={()=>{handleCsvModal(true)}} color={'buttonPrimary'} className="textDecoration-none">
              Upload Video CSV
          </CustomButton>
      </>}
    >
        <AddVideo handleNavParams={handleNavParams} uploadCsvModal={uploadCsvModal} handleCsvModal={handleCsvModal} />
        <AddKeywords handleNavParams={handleNavParams} uploadKeywords={uploadKeywords} handleImageCsvModal={handleImageCsvModal} />
       
    </MainLayout>
  )
}

export default AddVideoContainer