import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import OrderDetails from './OrderDetails'

function OrderDetailsContainer() {
  return (
    <MainLayout navTitle={"Order Details"} navSubTitle={"Welcome to ImagesBazaar"}>
        <OrderDetails/>
    </MainLayout>
  )
}

export default OrderDetailsContainer