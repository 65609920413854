import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ApiConfig from '../../config/ApiConfig';
import { breakStringIntoLines, crypt, getArrayOfObjJoinToStringForKey, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { keywordItemsSelector } from '../../redux/keyword';
import { deleteVisibleKeyword, getVisibleKeywordListing } from '../../Services/keywordsApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import AddVisibleKeyword from '../Modals/AddVisibleKeyword';
import ConfirmationModal from '../Modals/ConfirmationModal';
import EditVisibleKeyword from '../Modals/EditVisibleKeyword';
import UploadVisbileCsvModal from '../Modals/UploadVisbileCsvModal';
import MatTable from '../Tables/MatTable';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkHiddenKeyword from '../Modals/LinkHiddenKeyword';

const limit = 10;
const column = [
  {
    label: 'ID',
    id: 'vid',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData}</span>)
  },
  {
    label: 'Visible Keyword',
    id: 'visible_keyword',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>
     {columnData?breakStringIntoLines(columnData,20):'N/A'}
      </span>)
  },
  {
    label: 'Hidden Keyword',
    id: 'hiddenkeywords',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 250, width: "max-content"}}>{getArrayOfObjJoinToStringForKey(columnData, 'all_keyword') || '-'}</span>)
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", textAlign: "center"}}>{columnData.toString() == '1' ? 'Active' : 'Inactive'}</span>)
  },
];

function VisibleKeywordsList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { visibleKeywordList } = useSelector(keywordItemsSelector);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openLinkKeyword, setOpenLinkKeyword] = useState(false);
  const [openVisibleUpload, setOpenVisibleUpload] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [keywordsForId,setHiddenKeywordsForId]=useState([]);
  const navigate = useNavigate()
  const location = useLocation();

  const getListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        order_status: String(1),
        ...allParams,
        ...params
    }
    dispatch(getVisibleKeywordListing(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  //making data format for table input
  useEffect(() => {
    if(visibleKeywordList?.items?.rows?.length >= 0){
      setTableListing(visibleKeywordList?.items?.rows)
    }
  }, [visibleKeywordList.items]);


  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{display: "flex"}}>
          <CustomButton toolTipTittle={'Link Keyword'} 
           onClick={()=>{
            handleLinkKeyword(true, data);
            setHiddenKeywordsForId(data?.hiddenkeywords);
           }}
           className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><AddLinkIcon/></CustomButton>
          <CustomButton toolTipTittle={'Edit Visible Keyword'} 
           onClick={()=>{
            handleEdit(true, data);
            
           }}
           className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit/></CustomButton>
              </div>
      </>
    );
  };

  const handleAdd = (value) => {
    setOpen(value);
  }

  const handleLinkKeyword = (value, data={}) => {
    setOpenLinkKeyword(value);
    setSelectedData(data)
    if(!value){
      setSelectedData(null)
    }
  }

  const handleVisibleCsvUpload = (value) => {
    setOpenVisibleUpload(value);
  }

  const handleEdit = (value, data={}) => {
    setEditOpen(value);
    setSelectedData(data)
  }

  const handleConfirmDelete = async(value) =>{
    if(value && deleteData){
      const resp = await deleteVisibleKeyword({visible_keyword_id: deleteData});
      if(resp){
        getListing({page: '1'})
        setPage(1)
        setDeleteData(null)
      }
    }else{
      setDeleteData(null)
    }
  }

  return (
    <>
      <MatTable
        loading={visibleKeywordList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={visibleKeywordList?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        toolBarButton={()=>{
          return <>
            <CustomButton onClick={()=>{handleVisibleCsvUpload(true)}} color={'buttonPrimary'} 
              sx={(theme)=>({ padding: '10px 10px', marginRight: 1, 
                [theme.breakpoints.down("md")]: {
                  flex: 1,
                }
              })}>Upload CSV</CustomButton>
            <CustomButton onClick={()=>{handleAdd(true)}} color={'buttonPrimary'} 
              sx={(theme)=>({ padding: '10px 10px', marginRight: 1, 
                [theme.breakpoints.down("md")]: {
                  flex: 1,
                }
              })}>Add Visible Keyword</CustomButton>
          </>
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
         />

      {open && <AddVisibleKeyword open={open} handleOpen={handleAdd} setPage={setPage} getListing={getListing} /> }
      {openVisibleUpload && <UploadVisbileCsvModal open={openVisibleUpload} handleOpen={handleVisibleCsvUpload} getListing={getListing}/>}
      {editOpen && <EditVisibleKeyword open={editOpen} handleOpen={handleEdit} setPage={setPage} getListing={getListing} selectedData={selectedData} /> }
      {confirmDelete && <ConfirmationModal open={confirmDelete} handleConfirm={handleConfirmDelete} setOpen={handleCofirmModalOpen}/>}
      {openLinkKeyword && <LinkHiddenKeyword open={openLinkKeyword} handleOpen={handleLinkKeyword} setPage={setPage} getListing={getListing} vid={selectedData?.vid} keywordsForId={keywordsForId} /> }
    </>
  );
}

export default VisibleKeywordsList;
