import React, {  } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { LeftArrow } from '../../Asset/svg';
import { Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearSingleUserDetails } from '../../redux/user';
import ShortCompanyNameDetails from '../../Components/Company/ShortCompanyNameDetails';

function ShortCompanies() {
  const theme = useTheme();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {shortCompanyName} = useParams();
 

  return (
    <Box
      component="main"
      spacing={2}
      sx={(theme) => {
        return {
          flexGrow: 1,
        
        };
      }}
    >
    
      <CustomBox>
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}> 
            <Box sx={{
                display: "flex",
                alignItems: "center",
                cursor: 'pointer'
            }} onClick={()=>{
                navigate(-1)
                dispatch(clearSingleUserDetails())
            }}>
                <LeftArrow fill={theme.palette.textButton.main}/>
                <Typography variant='h5' sx={{
                    marginLeft: theme.spacing(1),
                    color: theme.palette.textButton.main
                }}>Back</Typography>
            </Box>
            <Typography variant='h5' sx={{marginLeft: (theme)=> theme.spacing(1)}}>{shortCompanyName}</Typography>
            <div style={{width: 100}}></div>
           
        </Box>
      </CustomBox>
      <Divder spacing={1} />
      <ShortCompanyNameDetails/>

    </Box>
  );
}

export default ShortCompanies;
