import React, { useEffect } from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { Box, Grid } from '@mui/material';
import ImageUpload from './ImageUpload';


function Banners({data, parentKey}) {  

  return (
    <>
      <CustomBox padding={3} sx={{minHeight: 200}}>
          <Grid container spacing={2}>
            {
              data?.map((item, index)=>{
                return <Grid item xs={12} key={item.key_name}>
                    <ImageUpload item={item} parentKey={parentKey} />
                </Grid>
              })
            }
          </Grid>
      </CustomBox>
    </>
  );
}

export default Banners;
