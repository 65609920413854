import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { breakStringIntoLines, formatDate, getObjectSubset, statusText } from '../../lib/helper';
import { categoryItemsSelector } from '../../redux/category';
import { OrderFollowUpsData, followUpsByOrderIdsListings, saleFollowUpListingsNoRedux } from '../../Services/CRMFollowups';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import MatTable from '../Tables/MatTable';
import EditFollowupHistoryEntryModal from '../Modals/EditFollowupHistoryEntryModal';
import { followUpsItemSelector } from '../../redux/followUps';
import CreateOrderFollowUps from './CreateOrderFollowUps';
import Divder from '../CommonComp/Divder';

const limit = 5;


const column = [
  {
    label: 'Next Follow Ups Date',
    id: 'next_followup_date',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'mon dd yyyy')}
      </span>
    ),
  },
  {
    label: 'Description',
    id: 'description',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {breakStringIntoLines(columnData,20)}
      </span>
    ),
  },
  {
    label: 'Create Date',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData)}
      </span>
    ),
  },
];

function MasterListFollowUps({ order_id }) {
  const [tableListing, setTableListing] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState(null);
  const [allParams, setAllParams] = useState({});
  const { subCategoryList } = useSelector(categoryItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderFollowUpData } = useSelector(followUpsItemSelector);

  if (!order_id) {
    navigate('/crm', { replace: true })
  }

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: String(limit),
      order_id: order_id,
      ...allParams,
      ...params,
    };
    dispatch(followUpsByOrderIdsListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    getFollowUpdata();

  }, [order_id])

  useEffect(() => {
    if (orderFollowUpData?.items?.data?.length >= 0) {
      setTableListing(orderFollowUpData?.items?.data);
    }
  }, [orderFollowUpData.items]);

  const getFollowUpdata = async () => {
    let payload = { order_id: order_id };
    let resp = await OrderFollowUpsData(payload);
    let invoiceIds=resp?.data?.data?.results?.map((inv=>inv?.invoice?.invoice_id))?.join(",");
    let addionalInfo = { 'order_id': order_id }
    let respAll = { ...resp?.data?.data?.results?.[0]?.user, ...addionalInfo};
    respAll['invoice_id']=invoiceIds;
    setSelectedData(respAll)


  }
  const handleEditModal = (value = false) => {
    setOpen(value)
    if (!value) {
      setSelectedData(null)
    }
  }
  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth buttonDense" toolTipTittle={'Edit'} onClick={() => {
            setSelectedData({
              ...data,
              order_id: tableListing?.order_id,
              daily_sale_id: dailySaleId
            })
            handleEditModal(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit /></CustomButton>
        </div>
      </>
    );
  };


  return (
    <div>
      <CustomBox padding={0}>
      <CustomBox padding={1}>
        <CreateOrderFollowUps selectedData={selectedData} getListing={getListing} />
      </CustomBox>
      <Divder spacing={1} />
        <MatTable
          loading={loading}
          showCheckbox={false}
          columns={column}
          data={tableListing || []}
          onApplyFilter={(data = {}) => {
            getListing({
              page: String(1),
              start_date: "",
              end_date: "",
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
            if (Object.keys(data).length > 0) {
              setAllParams({
                ...allParams,
                page: String(1),
                ...getObjectSubset(data, 'start_date', 'end_date'),
              });
            } else {
              setAllParams({
                page: String(1),
              });
            }
          }}
          page={page}
          perPage={limit}
          total={orderFollowUpData?.items?.totalCount || 0}
          setPage={setPage}
          handleSearch={(searchTerm) => {
            getListing({
              page: String(1),
              search: searchTerm
            })
            setAllParams({
              ...allParams,
              page: String(1),
              search: searchTerm
            })
          }}
          handlePageChange={(newPage) => {
            getListing({
              page: String(newPage)
            })
            setAllParams({
              ...allParams,
              page: String(newPage)
            })
          }}

          columnWiseData={[
            {
              id: 'order_id',
              value: tableListing?.order_id || "-",
            }
          ]}
          // toolbarEnable={true}
          pagination={true}
          // filterEnable
          rangeDateFilter
        />
        {open && <EditFollowupHistoryEntryModal open={open} handleOpen={handleEditModal} selectedData={selectedData} getListing={getListing} />}

      </CustomBox>
     
    </div>
  );
}

export default MasterListFollowUps;


