import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AddTaskIcon from '@mui/icons-material/AddTask';
import {
  breakStringIntoLines,
  createSubString,
  crypt,
  currencyConversion,
  dateDiffInDaysOfDates,
  deleteObjectKeys,
  downloadBlobFile,
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
} from '../../lib/helper';
import MatTable from '../Tables/MatTable';
import MasterReportFilters from './MasterReportFilters';
import {
  companyByNameListingsNoRedux,
  companyByNameListingsNoReduxNew,
  companyUserByAssociationAndCompanyListingsNoRedux,
  editSpecialCommentsAdmin,
  getCompanyNameListByFollowUpDate,
  getInitialShortCompanyNameWithoutDate,
  getManageReportFile,
  getStateListByFollowUpDate,
  manageReportLising,
} from '../../Services/CRMFollowups';
import { followUpsItemSelector, setInvoiceIdDropDown } from '../../redux/followUps';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CreateOrderFollowUps from '../Modals/CreateOrderFollowUps';
import CreateMultipleOrderFollowups from '../Modals/CreateMultipleOrderFollowups';
import ViewFollowUpsDetails from '../Modals/ViewFollowUpsDetails';
import LoaderSuspense from '../CommonComp/LoaderSuspense';

const column = [
  {
    label: 'Order Details',
    id: 'payment_type',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span
        style={{
          display: 'inline-block',
          wordBreak: 'break-all',
          maxWidth: '250px',
          width: 'max-content',
        }}
      >
        <div>
          <strong>Pay Mode:</strong> {row?.payment_type}
        </div>
        <div>
          <strong>Due Date:</strong> {formatDate(row?.payment_on, 'dd-mm-yyyy')}
        </div>
        <div>
          <strong>Order Date:</strong>{' '}
          {formatDate(row?.ordered_on, 'dd-mm-yyyy')}
        </div>
        <div>
          <strong>Next Follows Date:</strong>{' '}
          {formatDate(row?.next_followup_date, 'dd-mm-yyyy')}
        </div>
      </span>
    ),
  },
  {
    label: 'Due Days',
    id: 'payment_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {dateDiffInDaysOfDates(columnData)>=0?dateDiffInDaysOfDates(columnData):0 || 0}
      </span>
    ),
  },
  {
    label: 'Invoice Amt.(INR)',
    id: 'invoice_amt',
    // format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
    //   <span
    //     style={{
    //       display: 'inline-block',
    //       wordBreak: 'break-all',
    //       maxWidth: '250px',
    //       width: 'max-content',
    //     }}
    //   >
    //     <div>
    //       {columnData && currencyConversion(columnData) || 'N/A'}
    //     </div>
    //   </span>
    // ),

    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span
        style={{
          display: 'inline-block',
          wordBreak: 'break-all',
          maxWidth: '250px',
          width: 'max-content',
        }}
      >
        <div>

          <strong>Final Amount:</strong> {' '}
          {currencyConversion(row?.final_price)}
        </div>
        <div>
          <strong style={{color:'green'}}>Partial Paid:</strong> {currencyConversion(row?.partial_paid)}
        </div>
        <div>
          
          <strong style={{color:'#993300'}}>Remaining Amount:</strong> {columnData && currencyConversion(columnData) || 'N/A'}

        </div>
        
      </span>
    ),
    
  },
  {
    label: 'Invoice ID',
    id: 'invoice_id',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        <div>
          <Link
            className="textDecoration-none link-text"
            target={'_blank'}
            to={`orderManage/invoice?orderId=${crypt(
              'order_id',
              row?.order_id,
            )}`}
          >
            {columnData}
          </Link>
        </div>
        <div style={{ marginTop: '10px' }}>
          <Link
            className="textDecoration-none link-text"
            target={'_blank'}
            to={`partPay/invoice?orderId=${crypt('order_id', row?.order_id)}`}
          >
            Part Pay
          </Link>
        </div>
      </span>
    ),
  },
  {
    label: 'Client Name',
    id: 'client',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span
        style={{
          display: 'inline-block',
          wordBreak: 'break-all',
          maxWidth: '250px',
          width: 'max-content',
        }}
      >
        <div> 
          {row?.client ? breakStringIntoLines(row?.client, 20) : 'N/A'}
        </div>

      </span>
    ),
  },
  {
    label: 'Client Contact',
    id: 'client',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span
        style={{
          display: 'inline-block',
          wordBreak: 'break-all',
          maxWidth: '250px',
          width: 'max-content',
        }}
      >
        <div>
          <strong>Email:</strong> {row?.email}
        </div>
        <div>
          <strong>Mobile:</strong> {row?.mobile}
        </div>
        <div>
          <strong>Phone:</strong> {row?.phone}
        </div>
        <div>
          <strong>Remarks:</strong> {row?.remarks}
        </div>
      </span>
    ),
  },
  {
    label: 'Ordered By',
    id: 'ordered_by',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span
        style={{
          display: 'inline-block',
          wordBreak: 'break-all',
          maxWidth: '250px',
          width: 'max-content',
        }}
      >
        <div>{row?.ordered_by}</div>
      </span>
    ),
  },
  {
    label: 'Comments',
    id: 'comment',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (<Box style={{ maxWidth: 300, width: 'max-content', wordBreak: "break-word" }}>
      {!!columnData && <Tooltip title={columnData}><span style={{ display: 'inline-block', marginBottom: 10 }}>
        {/* {createSubString(columnData, () => { }, 62)} */}
       comment: {breakStringIntoLines(columnData,20)}
      </span></Tooltip>}
      {!!row?.special_comment && <Tooltip title={row?.special_comment}><Box className="colorHiglight fontWeight500" style={{ display: 'block' }}>
        Spl comment: 
        {/* {createSubString(row?.special_comment, () => { }, 62)} */}
        {breakStringIntoLines(row?.special_comment,20)}
      </Box></Tooltip>}
      {columnData  || row?.special_comment ?'':'N/A' }
    </Box>
    ),
  },
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Purchase Order NO.',
    id: 'purchase_order',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span
        style={{
          display: 'inline-block',
          wordBreak: 'break-all',
          maxWidth: '250px',
          width: 'max-content',
        }}
      >
        <div>{columnData || '-'}</div>
      </span>
    ),
  },
];

function MasterReportList() {
  const [orderListing, setOrderListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(20);
  const [loadingUpdateComment, setLoadingUpdateComment] = useState(false);
  const [showSelectOptions, setShowSelectOptions] = useState(false);
  const [showDownloadExcelButton, setShowDownloadExcelButton] = useState(false);
  const [shortCompanyNamesList, setShortCompanyNamesList] = useState([
    { label: 'Select', value: '' },
  ]);
  const [userDataByCompanyState, setUserDataByCompanyState] = useState([]);
  const [companyWiseStateList, setCompanyWiseStateList] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [multiSelectedOrderId, setMultiSelectedOrderId] = useState([]);
  const [invoiceIdlist,setInvoiceIdlist]=useState([]);
  const [companyData, setCompanyData] = useState(null);
  const { loading, manageReportingData } = useSelector(followUpsItemSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [companySearch, setCompanySearch] = useState(null);
  const [openCreateFollowup, setOpenCreateFollowup] = useState(false);
  const [openMultiCreateFollowup, setOpenMultiCreateFollowup] = useState(false);
  const [openViewFollowupsDetails, setOpenViewFollowupsDetails] =
    useState(false);
  const [selectedOrderData, setSelectedOrderData] = useState({});

  const getCompanyListing = async (inputValue) => {
    if (inputValue && inputValue.length > 2) {
      let data = await companyByNameListingsNoRedux({
        company_name: inputValue,
        limit: 4,
      });
      if (data) {
        let updatedData = data?.map((item, index) => {
          return {
            value: item.company_name,
            label: item.company_name,
            states: item.state,
          };
        });
        return updatedData || [];
      }
      return [];
    }
  };

  const handleCompanyOrGroupSelect = async (e) => {
    let data1 = await companyByNameListingsNoReduxNew({
      company_name: e.value,
    });
    if (data1) {
      if (data1?.length === 1 && data1?.length > 0) {
        let updatedData = {
          value: data1[0]?.company_name,
          label: data1[0]?.company_name,
          associations: data1[0]?.association,
        };
        let companyDataCopy = { ...e, associations: updatedData };
        setCompanyData(companyDataCopy?.associations);
      } else {
        setCompanyData(e);
        if (e?.value) {
          let resp = await companyUserByAssociationAndCompanyListingsNoRedux({
            company_name: e?.value,
          });
          setStateData(resp);
          setCompanySearch({ state: e?.value, company_name: e?.value });
          setUserDataByCompanyState(resp?.rows);
        }
      }
    }
  };

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Select Search Criteria',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[4].value = '';
          if (e.target.value) {
            if (e.target.value === 'payment_type') {
              newState[1].isVisible = false;
              newState[2].isVisible = false;
              newState[3].isVisible = false;
              newState[4].isVisible = false;
              newState[5].isVisible = true;
              newState[6].isVisible = false;
              newState[7].isVisible = false;
            } else if (e.target.value === 'short_company_name') {
              newState[1].isVisible = false;
              newState[2].isVisible = false;
              newState[3].isVisible = false;
              newState[4].isVisible = false;
              newState[5].isVisible = false;
              newState[6].isVisible = true;
              newState[7].isVisible = true;
              callInitialShortCompanyName(6);
            } else {
              newState[1].isVisible = false;
              newState[2].isVisible = false;
              newState[3].isVisible = false;
              newState[4].isVisible = true;
              newState[5].isVisible = false;
              newState[6].isVisible = false;
              newState[7].isVisible = false;
            }
          } else {
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = true;
            newState[4].isVisible = false;
            newState[5].isVisible = false;
            newState[6].isVisible = false;
            newState[7].isVisible = false;
          }
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { label: 'Username', value: 'user_name' },
        { label: 'Order ID', value: 'order_id' },
        { label: 'Invoice ID', value: 'invoice_id' },
        { label: 'Short Company Name', value: 'short_company_name' },
        { label: 'Payment Mode', value: 'payment_type' },
        { label: 'Order By', value: 'ordered_by' },
        { label: 'Client Name', value: 'client' },
        { label: 'Purchase No.', value: 'purchase_order' },
      ],
      props: {},
      isVisible: true,
    },
    {
      label: 'Follows Date',
      id: 'follow_up_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[3].value = '';
          return newState;
        });
        handleChangeFollowUpDate(e.target.value, 2);
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Short Company Name',
      id: 'short_company_name',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        let date = null;
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          date = newState[1].value;
          newState[index].value = e.target.value;
          return newState;
        });
        if (date) {
          handleChangeCompanyName(e.target.value, 3, {
            follow_up_date: date,
          });
        }
      },
      style: {},
      dataArr: [{ label: 'Select', value: '' }],
      props: {},
      isVisible: true,
    },

    {
      label: 'Select State',
      id: 'state',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [{ label: 'Select', value: '' }],
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: false,
    },
    {
      label: 'Payment Type',
      id: 'search',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [
        { label: 'Select', value: '' },
        { label: 'Cheque/demand draft', value: 'Cheque/demand draft' },
        { label: 'NEFT/RTGS/WT', value: 'NEFT/RTGS/WT' },
        { label: 'RazorPay', value: 'RazorPay' },
        { label: 'American Express', value: 'American Express' },
        { label: 'Pay10', value: 'Pay10' },
        { label: 'Zaakpay', value: 'Zaakpay' },
        { label: 'Paypal', value: 'Paypal' },
      ],
      props: {},
      isVisible: false,
    },
    {
      label: 'Short Company Name',
      id: 'short_company_name',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[7].value = '';
          newState[index].value = e.target.value;
          return newState;
        });
        getStateByShortCompanyName(e.target.value, 7);
      },
      style: {},
      dataArr: [{ label: 'Select', value: '' }],
      props: {},
      isVisible: false,
    },
    {
      label: 'Select State',
      id: 'state',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [{ label: 'Select', value: '' }],
      props: {},
      isVisible: false,
    },
  ]);

  const [selectedEdit, setSelectedEdit] = useState(null);

  const getOrderListing = async (params = {}, isSubmitFilter) => {
    setIsLoading(true);
    let payload = {};
    if (isSubmitFilter) {
      payload = {
        page: String(1),
        limit: limit,
        ...params,
      };
    } else {
      payload = {
        page: String(1),
        limit: limit,
        ...allParams,
        ...params,
      };
    }
    dispatch(manageReportLising(payload));
    setPage(Number(payload.page));
    setIsLoading(false);
  };

  const callInitialShortCompanyName = async (index) => {
    const companyData = await getInitialShortCompanyNameWithoutDate();
    if (companyData && companyData?.length > 0) {
      const newPayload = companyData?.map((data) => {
        return {
          label: data?.short_company_name,
          value: data?.short_company_name,
        };
      });
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[index].dataArr = [
          { label: 'Select', value: '' },
          ...newPayload,
        ];
        return newState;
      });
    } else {
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[index].dataArr = [{ label: 'Select', value: '' }];
        newState[index].value = '';
        return newState;
      });
    }
  };
  const handleChangeFollowUpDate = async (dateValue, index) => {
    const payload = { followUp_Date: dateValue };
    const companyData = await getCompanyNameListByFollowUpDate(payload);
    if (companyData && companyData?.length > 0) {
      const newPayload = companyData?.map((data) => {
        return {
          label: data?.short_company_name,
          value: data?.short_company_name,
        };
      });
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[index].dataArr = [
          { label: 'Select', value: '' },
          ...newPayload,
        ];
        return newState;
      });
    } else {
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[index].dataArr = [{ label: 'Select', value: '' }];
        newState[index].value = '';
        return newState;
      });
    }
  };
  const handlePendingOrderDelete = async (data, resetState) => {
    setMultiSelectedOrderId(data);
    if(data && data?.length){
      let orderId=data?.map((id)=>id)?.join(",");
      let newParam='';
      if(allParams){
        if(allParams?.search_by){
          console.log(allParams)
          newParam=newParam+'search_by='+allParams?.search_by;
          if(allParams?.search_by=='user_name'){
            newParam=newParam+'&user_name='+allParams?.search;
          }
          if(allParams?.search_by=='order_id'){
            newParam=newParam+'&order_id='+allParams?.search;
          }
          if(allParams?.search_by=='invoice_id'){
            newParam=newParam+'&invoice_id='+allParams?.search;
          }
          if(allParams?.search_by=='short_company_name'){
            newParam=newParam+'&short_company_name='+allParams?.search;
          }
          if(allParams?.search_by=='payment_type'){
            newParam=newParam+'&payment_type='+allParams?.search;
          }
          if(allParams?.search_by=='ordered_by'){
            newParam=newParam+'&ordered_by='+allParams?.search;
          }
          if(allParams?.search_by=='client'){
            newParam=newParam+'&client='+allParams?.search;
          }
          if(allParams?.search_by=='purchase_order'){
            newParam=newParam+'&purchase_order='+allParams?.search;
          }

        }
      }
      let url=`../crm/masterMultipleFollowups?order_ids=${crypt('order_id', orderId)}`;
      if(newParam)url=url+'&'+newParam;
      window.open(url);
    }
    if(orderListing?.length && data){
      let invoiceIdList=orderListing?.map((item)=>item?.invoice_id)?.join(",");
      let newArray=[];
      newArray=orderListing?.map((inv)=>({
      label:inv?.invoice_id,
      value:inv?.order_id
    }));
    localStorage.setItem("dropDownArray", JSON.stringify(newArray));
      setInvoiceIdlist(invoiceIdList);
      //dispatch(setInvoiceIdDropDown(newArray))
    }
   
  };
  const getStateByShortCompanyName = async (short_company_name, index) => {
    const payload = { short_company_name: short_company_name };
    const stateData = await getStateListByFollowUpDate(payload);
    if (stateData && stateData?.length > 0) {
      const newPayload = stateData?.map((data) => {
        return {
          label: data?.state,
          value: data?.state,
        };
      });
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[index].dataArr = [
          { label: 'Select', value: '' },
          ...newPayload,
        ];
        return newState;
      });
    } else {
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[index].dataArr = [{ label: 'Select', value: '' }];
        return newState;
      });
    }
  };

  const handleChangeCompanyName = async (
    dateValue,
    index,
    extraPayload = {},
  ) => {
    if (dateValue && String(dateValue)?.length > 0) {
      const payload = { short_company_name: dateValue, ...extraPayload };
      const stateData = await getStateListByFollowUpDate(payload);
      if (stateData && stateData?.length > 0) {
        const newPayload = stateData?.map((data) => {
          return {
            label: data?.state,
            value: data?.state,
          };
        });
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].dataArr = [
            { label: 'Select', value: '' },
            ...newPayload,
          ];
          return newState;
        });
      }
    }
  };

  useEffect(() => {
    if (manageReportingData?.items?.orders?.length >= 0) {
      let data = manageReportingData?.items?.orders?.map((item) => {
        return {
          order_id: item.order_id,
          user_id: item.user_id,
          user_name: item?.user?.user_name,
          email: item.user.email,
          payment_type: item.payment_type,
          ordered_on: item.ordered_on,
          accepted_or_rejected_on: item.accepted_or_rejected_on,
          previous_order_id: item.previous_order_id,
          payment_on: item.payment_on,
          order_type: item.order_type,
          order_status: item.order_status,
          paid_on: item.paid_on,
          payment_status: item.payment_status,
          invoice_id: item?.invoice?.invoice_id || null,
          gst_number: item?.invoice?.gst_number || null,
          comment: item?.comment,
          special_comment: item?.grouping_manage?.special_comment || null,
          irn_status: item?.irn_status,
          purchase_order_detail: item?.purchase_order_detail,
          purchase_order: item?.purchase_order,
          client: item?.client,
          ordered_by: item?.invoice?.ordered_by,
          invoice_amt:
            Number(item?.invoice?.final_price) -
            Number(item?.partial_paid || 0),
          assign_to: null,
          remarks: item?.remarks,
          mobile: item?.user?.mobile,
          phone: item?.user?.phone,
          next_followup_date: item?.next_followup_date,
          company_group_name: item?.user?.company_group_name,
          company_name: item?.user?.company_name,
          short_company_name: item?.user?.short_company_name,
          partial_paid:Number(item?.partial_paid || 0),
          final_price: Number(item?.invoice?.final_price) 
        };
      });
      setOrderListing(data);
    }
  }, [manageReportingData.items]);

  useEffect(() => {
    getOrderListing();
  }, []);

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getOrderListing();
    if (allParams) {
      let data = deleteObjectKeys({ ...allParams }, 'tab', 'page');
      if (data?.follow_up_date) {
        handleChangeFollowUpDate(data?.follow_up_date, 2);
      }
      if (data?.short_company_name) {
        handleChangeCompanyName(data?.short_company_name, 3, {
          follow_up_date: data?.follow_up_date,
        });
      }
      if (data?.search_by) {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[0].value = data?.search_by;
          if (data?.search_by) {
            if (data?.search_by === 'payment_type') {
              newState[1].isVisible = false;
              newState[2].isVisible = false;
              newState[3].isVisible = false;
              newState[4].isVisible = false;
              newState[5].isVisible = true;
              newState[6].isVisible = false;
              newState[7].isVisible = false;
            } else if (data?.search_by === 'short_company_name') {
              newState[1].isVisible = false;
              newState[2].isVisible = false;
              newState[3].isVisible = false;
              newState[4].isVisible = false;
              newState[5].isVisible = false;
              newState[6].isVisible = true;
              newState[7].isVisible = true;
              callInitialShortCompanyName(6);
              if (data?.short_company_name) {
                getStateByShortCompanyName(data?.short_company_name, 7);
              }
            } else {
              newState[1].isVisible = false;
              newState[2].isVisible = false;
              newState[3].isVisible = false;
              newState[4].isVisible = true;
              newState[5].isVisible = false;
              newState[6].isVisible = false;
              newState[7].isVisible = false;
            }
          } else {
            newState[1].isVisible = true;
            newState[2].isVisible = true;
            newState[3].isVisible = true;
            newState[4].isVisible = false;
            newState[5].isVisible = false;
            newState[6].isVisible = false;
            newState[7].isVisible = false;
          }
          return newState;
        });
      }
      if (data && Object?.keys(data)?.length > 0) {
        setFiltersArray((prev) => {
          let newState = prev?.map((item) => {
            return {
              ...item,
              value: data[item.id],
            };
          });
          return newState;
        });
      } else {
        setFiltersArray((prev) => {
          let newState = prev?.map((item) => {
            if (item?.id === 'state' || item?.id === 'short_company_name') {
              return {
                ...item,
                value: '',
                dataArr:
                  item['dataArr'] && item['dataArr'].length > 0
                    ? [{ label: 'Select', value: '' }]
                    : [],
              };
            } else {
              return {
                ...item,
                value: '',
              };
            }
          });
          return newState;
        });
      }
    }
    if (allParams?.follow_up_date && allParams?.short_company_name) {
      setShowSelectOptions(true);
    } else if (allParams?.search_by == 'user_name' && allParams?.search) {
      setShowSelectOptions(true);
    } else if (allParams?.search_by == 'short_company_name') {
      setShowSelectOptions(true);
    } else if (allParams?.search_by == 'ordered_by' && allParams?.search) {
      setShowSelectOptions(true);
    } else {
      setShowSelectOptions(false);
    }
  }, [allParams]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getOrderListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[3].isVisible = true;
      newState[4].isVisible = false;
      newState[5].isVisible = false;
      newState[6].isVisible = false;
      newState[7].isVisible = false;
      return newState;
    });
  };
  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          {/* commented for test will remove in next PR */}
          <Link className='textDecoration-none' to={`../crm/masterFollowups/${data?.order_id}`} target='_blank'>
            <CustomButton toolTipTittle={'Create and View'} className="noMinWidth" sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}> <AddTaskIcon/> </CustomButton>
          </Link>
        </div>
      </>
    );
  };

  const handleAdd = (value) => {
    setOpenCreateFollowup(value);
  };
  const handleMultiCreateAdd = (value) => {
    setOpenMultiCreateFollowup(value);
  };
  const handleViewFollowUps = (value) => {
    setOpenViewFollowupsDetails(value);
  };
  const handleUpdateComment = async (comment, orderId) => {
    setLoadingUpdateComment(true);
    const payload = {
      order_id: orderId,
      special_comment: comment,
    };
    let resp = await editSpecialCommentsAdmin(payload);
    if (resp) {
      setSelectedEdit(null);
      getOrderListing();
    }
    setLoadingUpdateComment(false);
  };
  const handleDownloadExcel = async () => {
    const payload = {
      ...allParams,
    };
    let resp = await getManageReportFile(payload);
    if (resp.status) {
      // formik?.resetForm()
      downloadBlobFile(
        resp.data,
        `ManageReport${allParams?.follow_up_date ? allParams?.follow_up_date : ''
        }`,
      );
    }
  };
  return (
    <>
      {isLoading === true && <LoaderSuspense />}
      <Grid container spacing={2} p={1.5} sx={{ placeItems: 'center' }}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Total Records : {manageReportingData?.items?.totalCount}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Total Amount(INR) :{' '}
            {/* {manageReportingData.items?.totalEarning || 0} */}
            {manageReportingData.items?.totalEarning && currencyConversion(manageReportingData.items?.totalEarning) || '0'}
          </Typography>
        </Grid>

      </Grid>
      <>
        <MatTable
          loading={manageReportingData?.loading}
          showCheckbox={false}
          columns={column}
          data={orderListing}
          actionLast={true}
          // requestSearch={requestSearch}
          // cancelSearch={cancelSearch}
          onApplyFilter={(data = {}) => {
            getOrderListing({
              page: String(1),
              start_date: '',
              end_date: '',
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
            if (Object.keys(data).length > 0) {
              setAllParams({
                ...allParams,
                page: String(1),
                ...getObjectSubset(data, 'start_date', 'end_date'),
              });
            } else {
              setAllParams({
                page: String(1),
              });
            }
          }}
          action={actionBody}
          page={page}
          perPage={limit}
          showPageLimit={true}
          handleChangePageLimit={(e) => {
            setLimit(e.target.value);
            setAllParams({
              ...allParams,
              page: String('1'),
              limit: Number(e.target.value),
            });
          }}
          total={manageReportingData?.items?.totalCount || 0}
          setPage={setPage}
          handleSearch={(searchTerm) => {
            getOrderListing({
              page: String(1),
              search: searchTerm,
            });
            setAllParams({
              ...allParams,
              page: String(1),
              search: searchTerm,
            });
          }}
          handlePageChange={(newPage) => {
            getOrderListing({
              page: String(newPage),
            });
            setAllParams({
              ...allParams,
              page: String(newPage),
            });
          }}
          columnWiseData={[
            {
              id: 'comments',
              value: {
                selectedData: selectedEdit,
                setSelectedEdit: setSelectedEdit,
                loading: loadingUpdateComment,
                onSubmit: (data, orderId) => {
                  handleUpdateComment(data, orderId);
                },
              },
            },
          ]}
          toolbarEnable={true}
          pagination={true}
          filtersUrlData={allParams || {}}
          disableSearch
          rangeDateFilter
          customFilter={() => (
            <MasterReportFilters
              filtersArray={filtersArray}
              handleClick={handleFilter}
              handleClear={handleClear}
              hasDownloadExcelButton={true}
              handleDownloadExcel={handleDownloadExcel}
            />
          )}
          checkboxEnable={showSelectOptions}
          onTableToolbarRightClick={handlePendingOrderDelete}
          customLabel={'Delete'}
          onTableToolbarRightSection={
            <CustomButton
              sx={{
                padding: '5px 10px',
                marginRight: 1,
                fontSize: '12px',
              }}
              color={'buttonSecondary'}
              onClick={() => {
              }}
            >
              Create Followups
            </CustomButton>
          }
        />
        {openCreateFollowup && (
          <CreateOrderFollowUps
            open={openCreateFollowup}
            handleOpen={handleAdd}
            setPage={setPage}
            getListing={getOrderListing}
            selectedData={selectedOrderData}
          />
        )}
        {openMultiCreateFollowup && (
          <CreateMultipleOrderFollowups
            open={openMultiCreateFollowup}
            handleOpen={handleMultiCreateAdd}
            setPage={setPage}
            getListing={getOrderListing}
            orderIdList={multiSelectedOrderId}
            invoiceIdList={invoiceIdlist}
          />
        )}
        {openViewFollowupsDetails && (
          <ViewFollowUpsDetails
            open={openViewFollowupsDetails}
            handleOpen={handleViewFollowUps}
            setPage={setPage}
            getListing={getOrderListing}
            selectedData={selectedOrderData}
          />
        )}
      </>
    </>
  );
}

export default MasterReportList;
