import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { crypt, formatDate, statusText } from '../../lib/helper';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import Divder from '../CommonComp/Divder';
import MatTable from '../Tables/MatTable';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Add } from '@material-ui/icons';
import ApiConfig from '../../config/ApiConfig';

const limit = DATA_LIMIT;

const column = [
  // {
  //   label: 'Id',
  //   id: 'user_id',
  //   format: (columnData) => (
  //     <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
  //       {columnData || 'N/A'}
  //     </span>
  //   ),
  // },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'User Name',
    id: 'first_name',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content', wordBreak: 'break-all'}}
      >
        {row?.first_name} {row?.last_name ? ' ' + row?.last_name : "" || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Phone',
    id: 'phone_number',
    format: (columnData) => (
      <span style={{ display: 'inline-block',  maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Mobile',
    id: 'mobile_number',
    format: (columnData) => (
      <span style={{ display: 'inline-block',  maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (
      <span style={{ display: 'inline-block',  maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block',  maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'User Type',
    id: 'user_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block',  maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Created By',
    id: 'first_created_by',
    format: (columnData) => (
      <span style={{ display: 'inline-block',  maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Status',
    id: 'followup_status',
    format: (columnData) => (
      <Typography variant="span">{statusText(columnData)}</Typography>
    ),
  },
  {
    label: 'Creation Date',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block',  maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {formatDate(columnData, 'dd-mm-yyyy')}
      </span>
    ),
  },
  {
    label: 'Description Type',
    id: 'description_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block',  maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
       {columnData}
      </span>
    ),
  },
  {
    label: 'Requirement Type',
    id: 'requirement_type',
    format: (columnData) => (
      <span style={{ display: 'inline-block',  maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Discount Terms',
    id: 'discount',
    format: (columnData) => (
      <span style={{ display: 'inline-block',  maxWidth: 200, width: 'max-content', wordBreak: 'break-all' }}>
        {columnData}
      </span>
    ),
  },
  // {
  //   label: 'FollowUps',
  //   id: 'followups',
  //   format: (columnData, rowIndex, formatCallback, options, errors, row) => (<>
  //     <CustomButton className="buttonDense"
  //       onClick={()=>{options(row)}}
  //       sx={{ fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>Create</CustomButton>
  //     </>
  //   ),
  // },
  // {
  //   label: 'History FollowUps',
  //   id: 'daily_sale_id',
  //   format: (columnData) => (
  //     <Link className='textDecoration-none' to={`../../crm/saleEntryHistory/${columnData}`} target='_blank'>
  //       {/* <Typography
  //         variant='span'
  //         style={{ whiteSpace: 'nowrap' }}
  //         // className={'link-text'}
  //         // onClick={() => {
  //         //   customHistory.push(`order/orderDetails/${columnData}`);
  //         // }}
  //       >
  //         View
  //       </Typography> */}
  //       <CustomButton className="buttonDense"
  //         // onClick={()=>{options(row)}}
  //         sx={{ fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>View</CustomButton>
  //     </Link>
  //   ),
  // },
];

function LastUserFollowUps({data, handleSelectedData,userData}) {
  const [tableListing, setTableListing] = useState([]);

  let path=`lastFollowUp`
  // path+=userData?.mobile?
  //     `?mobile=${ crypt(ApiConfig.REACT_APP_SALT + 'mobile', userData?.mobile)}`:
  //     `?email=${crypt(ApiConfig.REACT_APP_SALT + 'email', userData?.label)}`
  path+=userData?.mobile?
  `/mobile/${ userData?.mobile}`:
  `/email/${userData?.label}`
//  to={`${crypt(ApiConfig.REACT_APP_SALT + 'image_name', data?.Name)}`}
  useEffect(()=>{
    setTableListing(data);
  },[data])

  const actionBody = (data) =>{
 
    return (<>
      <div style={{display: "flex"}}>
        <CustomButton className="noMinWidth" onClick={()=>{
          handleSelectedData(data)
        }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Add/></CustomButton>
        <Link className='textDecoration-none' to={`../../crm/saleEntryHistory/${data?.daily_sale_id}`} target='_blank'>
          <CustomButton className="noMinWidth" sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText'}}><VisibilityIcon/> </CustomButton>
        </Link>

        {/* <CustomButton variant="raised" sx={{ padding: '1px 1px', marginRight: 1, fontSize: '12px', bgcolor: '#fff0', minWidth: 20 }}><Delete/></CustomButton> */}
      </div>
    </>)
  }

  return (
    <CustomBox padding={0}>
     
       <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: 'wrap', pt: 2, px: 2}}>
          <Typography variant="h5">Last User Follows-Ups</Typography>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "10px"}}>
            <CustomButton color={'buttonPrimary'}  onClick={()=>{
            
            }}>
              {/* <Link to={`lastFollowUp/${userData?.label}`} target={'_blank'}  className='textDecoration-none' style={{'color':'white'}}>
              View All
              </Link> */}
              <Link to={path} target={'_blank'}  className='textDecoration-none' style={{'color':'white'}}>
              View All
              </Link>
            </CustomButton>
          </Box>
        </Box>
      {/* <Typography variant="h5" pt={2} px={2}>Last User Follows-Ups</Typography>
      <Typography variant="h5" pt={2} px={2}>View All</Typography> */}
      <Divder spacing={1} />
      <MatTable
        loading={false}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // onApplyFilter={(data = {}) => {
        //   getListing({
        //     page: String(1),
        //     start_date: '',
        //     end_date: '',
        //     ...getObjectSubset(data, 'start_date', 'end_date'),
        //   });
        //   if (Object.keys(data).length > 0) {
        //     setAllParams({
        //       ...allParams,
        //       page: String(1),
        //       ...getObjectSubset(data, 'start_date', 'end_date'),
        //     });
        //   } else {
        //     setAllParams({
        //       page: String(1),
        //     });
        //   }
        // }}
        action={actionBody}

        // page={page}
        // perPage={limit}
        // total={allUserList?.items?.totalCount || 0}
        // setPage={setPage}
        // handleSearch={(searchTerm) => {
        //   getListing({
        //     page: String(1),
        //     search: searchTerm,
        //   });
        //   setAllParams({
        //     ...allParams,
        //     page: String(1),
        //     search: searchTerm,
        //   });
        // }}
        // handlePageChange={(newPage) => {
        //   getListing({
        //     page: String(newPage),
        //   });
        //   setAllParams({
        //     ...allParams,
        //     page: String(newPage),
        //   });
        // }}
        // toolbarEnable={true}
        // pagination={true}
        // filtersUrlData={allParams || {}}
        // filterEnable
        // rangeDateFilter
        tableMaxHeight={300}
        // columnWiseData={[
        //   {
        //     id: 'followups',
        //     value: handleSelectedData,
        //   },
        // ]}
      />
    </CustomBox>
  );
}

export default LastUserFollowUps;
