import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import Master from './Master'

function MasterContainer() {
  return (
    <MainLayout navTitle={"Master"} navSubTitle={"Welcome to ImagesBazaar"}>
      <Master/>
    </MainLayout>
  )
}

export default MasterContainer