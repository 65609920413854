import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allLogs: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  zeroLogs: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const logSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAllLogsListingLoading: (state, action) => {
      state.allLogs.loading = action.payload;
    },
    setAllLogsListing: (state, action) => {
      state.allLogs.items = action.payload;
      state.allLogs.error = {
        isError: false,
        message: '',
      };
    },
    setAllLogsListingError: (state, action) => {
      state.allLogs.items = {};
      state.allLogs.error = action.payload;
    },
    setZeroLogsListingLoading: (state, action) => {
      state.zeroLogs.loading = action.payload;
    },
    setZeroLogsListing: (state, action) => {
      state.zeroLogs.items = action.payload;
      state.zeroLogs.error = {
        isError: false,
        message: '',
      };
    },
    setZeroLogsListingError: (state, action) => {
      state.zeroLogs.items = {};
      state.zeroLogs.error = action.payload;
    },
  },
});

export default logSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setAllLogsListing,
  setAllLogsListingError,
  setAllLogsListingLoading,
  setZeroLogsListing,
  setZeroLogsListingError,
  setZeroLogsListingLoading
} = logSlice.actions;

export const logsItemsSelector = (state) => state.logs;
