import { IconButton, Typography, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Delete } from '../../Asset/svg';
import ApiConfig from '../../config/ApiConfig';
import { crypt, formatDate, getNewParameters, getObjectSubset, updateUrl, getParameters, paymentModeModification, breakStringIntoLinesBySymbol } from '../../lib/helper';
import { orderListingItemsSelector } from '../../redux/orderListing';
import { deletePendingOrders, PendingOrderListing } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { Add, Mail } from '@material-ui/icons';
import { Link, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { SearchBy, SearchByPending } from '../../data/userData';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateDailySaleFollow from '../Modals/CreateDailySaleFollow';
import { daliySalesEntryListing } from '../../Services/CRMFollowups';
import AllFilters from './AllFilters';

const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (
      <RouterLink className='textDecoration-none link-text' to={`orderDetails/${columnData}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
        >
          {columnData}
        </Typography>
      </RouterLink>
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (<>
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
        {columnData || 'N/A'}
        </span>

    </>
    )
  },
  {
    label: 'Payment Mode',
    id: 'payment_type',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
        {columnData?paymentModeModification(columnData) : 'N/A'}
       </span>),
     nextLine:true,
  },
  {
    label: 'Payment Status',
    id: 'payment_status',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <>
        <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
          {columnData == '0' ? 'Unpaid' : 'Paid'}
        </span>
        {row?.paid_on ? <Typography variant='p' display={'block'} className='colorHiglight2'>
          {formatDate(row.paid_on, 'dd/mm/yyyy')}
        </Typography> : null}
        {row?.previous_order_id ? <Typography variant='p' display={'block'} className='colorHiglight'>
          RefID:{row?.previous_order_id}
        </Typography> : null}
      </>
    ),
    nextLine:true,
  },
  {
    label: 'Order Status',
    id: 'order_status',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <>
        <Typography
          variant="span"
          sx={{ color: 'info.main', width: 90, display: 'inline-block' }}
        >
          P
        </Typography>
        <Typography variant="span" component={'div'}>{row?.order_type}</Typography>
      </>
    ),
    nextLine:true,
  },
  {
    label: 'Order Date',
    id: 'ordered_on',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}> {formatDate(columnData)}</span>)
  }
];

function PendingOrder() {
  const [orderListing, setOrderListing] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, pendingOrders } = useSelector(orderListingItemsSelector);
  const dispatch = useDispatch();
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation();
  const [openCreateDaliySale, setOpenCreateDaliySale] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: SearchByPending,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          // newState[index].value = e.target.value;
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);
  const getOrderListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      order_status: String(0),
      ...allParams,
      ...params,
    }
    if (payload?.start_date) {
      payload['start_date'] = formatDate(payload?.start_date, 'yyyy-mm-dd') + "  00:00:00"
    }
    if (payload?.end_date) {
      payload['end_date'] = formatDate(payload?.end_date, 'yyyy-mm-dd') + "  23:59:59"
    }
    dispatch(PendingOrderListing(payload))
    setPage(Number(payload.page))
  }
  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getOrderListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';
      return newState;
    });
  };
  const handlePendingOrderDelete = async (data, resetState) => {
    let resp = await deletePendingOrders({
      order_id: data
    })
    if (resp) {
      getOrderListing()
      resetState([])
    }
  }

  useEffect(() => {
    getOrderListing();

  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getOrderListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (pendingOrders?.items?.orders?.length >= 0) {
      let data = pendingOrders?.items?.orders?.map((item) => {
        return {
          order_id: item.order_id,
          user_id: item.user_id,
          email: item.user.email,
          first_name: item?.user?.first_name,
          last_name: item?.user?.last_name,
          payment_type: item.payment_type,
          payment_status: item.payment_status,
          ordered_on: item.ordered_on,
          order_type: item.order_type,
          order_status: item.order_status,
          invoice_id: item?.invoice?.invoice_id || null,
          paid_on: item?.paid_on,
          previous_order_id: item.previous_order_id,
          daily_sales: item.daily_sales
        }
      })
      setOrderListing(data)
    }
  }, [pendingOrders.items]);


  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "end" }}>
            {data?.daily_sales?.length >= 1 ?
            <>
              <CustomButton className="noMinWidth" onClick={() => { handleSelectedData(data.daily_sales[0]); }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Add /></CustomButton>
              <Link className='textDecoration-none' to={`../crm/saleEntryHistory/${data.daily_sales[0]?.daily_sale_id}`} target='_blank'>
                <CustomButton className="noMinWidth" sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><VisibilityIcon /> </CustomButton>
              </Link>
            </>
            : null}
          {data?.order_type.toLowerCase() != 'iv' && <a className='textDecoration-none'
            href={`${ApiConfig.LIVE_WEBSITE_URL}/deliverImages?order_id=${crypt(ApiConfig.REACT_APP_SALT + 'order_id', data?.order_id)}`}
            target={'_blank'} style={{ color: theme.palette.primary.contrastText }}>
            <CustomButton toolTipTittle={'Deliver Order'} className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText', whiteSpace: 'nowrap' }}><PermMediaIcon /></CustomButton>
          </a>}
          {data?.order_type.toLowerCase() != 'iv' && <RouterLink className='textDecoration-none' to={`sendEMailOrder/${crypt(ApiConfig.REACT_APP_SALT + 'order_id', data?.order_id)}`} target={'_blank'} style={{ color: theme.palette.primary.contrastText }}>
            <CustomButton toolTipTittle={'Send Mail'} className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Mail /></CustomButton>
          </RouterLink>}
        </div>
      </>
    );
  };
  const handleCreate = (value) => {
    setOpenCreateDaliySale(value);
    if (!value) {
      setSelectedData(null)
    }
  };
  const handleSelectedData = (data) => {
    setSelectedData(data);
    handleCreate(true)
  }
  const actionHead = (data) => {
    return (<div style={{display: "flex"}}>
       hi
    </div>);
  };


  return (
    <>
      <MatTable
        loading={pendingOrders?.loading}
        columns={column}
        data={orderListing}
        onApplyFilter={(data = {}) => {
          getOrderListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date', 'search_by'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date', 'search_by'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        showPageLimit={true}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        perPage={limit}
        total={pendingOrders?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getOrderListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })

        }}

        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        checkboxEnable={true}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        rangeDateFilter
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          />
        )}
        onTableToolbarRightClick={handlePendingOrderDelete}
        customLabel={'Delete'}
        onTableToolbarRightSection={<IconButton >
          <DeleteIcon />

        </IconButton>
        }
      />
      {openCreateDaliySale && <CreateDailySaleFollow
        open={openCreateDaliySale}
        handleOpen={handleCreate}
        getListing={getOrderListing}
        selectedData={selectedData}
      />}
    </>
  );
}

export default PendingOrder;
