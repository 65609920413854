import React, {  } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import { LeftArrow } from '../../Asset/svg';
import { Typography, useTheme } from '@mui/material';
import CustomButton from '../../Components/CommonComp/Buttons/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import MasterListFollowUps from '../../Components/FollowUps/MasterListFollowUps';

function MasterFollowUps() {
  const theme = useTheme();
  const navigate = useNavigate();
  const {order_id} = useParams();

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
        };
      }}
    >
      <CustomBox>
        <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }}> 
            <Box sx={{
                display: "flex",
                alignItems: "center",
                cursor: 'pointer'
            }} onClick={()=>{
                window.close();
                // navigate(-1);
              // navigate('/crm?tab=2') 

            }}>
                <LeftArrow fill={theme.palette.textButton.main}/>
                <Typography variant='h5' sx={{
                    marginLeft: theme.spacing(1),
                    color: theme.palette.textButton.main
                }}>Back</Typography>
            </Box>
            <Typography variant='h5' sx={{marginLeft: (theme)=> theme.spacing(1)}}>History</Typography>
            <div style={{paddingLeft: 30}}></div>
        </Box>
      </CustomBox>
      <Divder spacing={1} />
      <MasterListFollowUps order_id={order_id}/>
      <Divder spacing={1.2} />
    </Box>
  );
}

export default MasterFollowUps;
