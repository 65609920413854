import { Link, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContactUsFiltersData } from '../../data/contactusData';
import { breakStringIntoLines, breakStringIntoLinesByComa, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { contactQueriesItemsSelector } from '../../redux/contactQueries';
import { getContactUsQueries } from '../../Services/contactQueriesService';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';

const limit = 10;

const column = [
  {
    label: 'S.No.',
    id: 'contact_us_id',
    format: (columnData) => (
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'nowrap',
        }}
      >
        {columnData}
      </span>
    ),
  },
  {
    label: 'Related To',
    id: 'related_to',
    format: (columnData) => (
      <Typography variant="span" sx={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </Typography>
    ),
  },
  {
    label: 'Name',
    id: 'name',
    format: (columnData) => (
      <span style={{
        display: 'inline-block', width: '250px',
        wordWrap: 'break-word'
      }} onClick={() => {
      }} >
        {columnData ? columnData : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Mailed On',
    id: 'mailed_on',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? breakStringIntoLinesByComa(columnData, 20) : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Message',
    id: 'message',
    format: (columnData) => (
      <span style={{
        display: 'inline-block', width: '250px',
        wordWrap: 'break-word'
      }} onClick={() => {
      }} >
        {columnData ? columnData : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Phone Number',
    id: 'phone_number',
    format: (columnData) => (
      <Typography variant="span" sx={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        +{columnData}
      </Typography>
    ),
  },
  {
    label: 'Date',
    id: 'created_at',
    format: (columnData) => (
      <Typography variant="span" sx={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData)}
      </Typography>
    ),
  },
];

function AllQueries() {
  const [queriesList, setQueriesList] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, allContactQueries } = useSelector(
    contactQueriesItemsSelector,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const getOrderListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // order_status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(getContactUsQueries(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getOrderListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getOrderListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (allContactQueries?.items?.elements?.length >= 0) {
      let data = allContactQueries?.items?.elements?.map((item) => {
        return {
          contact_us_id: item?.contact_us_id,
          name: item?.name,
          email: item?.email,
          mailed_on: item?.mailed_on,
          message: item?.message,
          phone_number: item?.phone_number,
          created_at: item?.created_at,
          related_to: item?.related_to,
        };
      });
      setQueriesList(data);
    }
  }, [allContactQueries.items]);


  const actionBody = (data) => {
    return <CustomButton component={Link} href={`mailto:${data?.email}`} sx={{
      paddingTop: (theme) => theme.spacing(10),
      paddingTop: (theme) => theme.spacing(1)
    }} color="buttonPrimary" className="buttonDense">
      Reply
    </CustomButton>
  };

  return (
    <>
      <MatTable
        loading={allContactQueries?.loading}
        showCheckbox={false}
        columns={column}
        data={queriesList}
        onApplyFilter={(data = {}) => {
          getOrderListing({
            page: String(1),
            related_to: "",
            ...getObjectSubset(data, 'related_to'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'related_to'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={allContactQueries?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getOrderListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getOrderListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        disableSearch={true}
        filtersUrlData={allParams || {}}
        filterEnable={true}
        dropdownFilter={true}
        selectFilterArray={[
          {
            name: 'related_to',
            label: 'Related To',
            placeholder: 'Related To',
            options: [{ label: "Select Topic", value: '' }, ...ContactUsFiltersData.related_to]
          }
        ]}
      />
    </>
  );
}

export default AllQueries;
