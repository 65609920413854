import { Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { breakStringIntoLines, breakStringIntoLinesBySymbol, currencyConversion, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { orderListingItemsSelector } from '../../redux/orderListing';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { getIvsDownloadListing } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import EditIvsDownloadClientModal from '../Modals/EditIvsDownloadClientModal';
import MatTable from '../Tables/MatTable';
import { SearchIVSDownloadBy } from '../../data/userData';
import AllFilters from './AllFilters';

const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (<span style={{
      display: 'inline-block', whiteSpace: "nowrap",
    }}
    > {columnData}</span>)
  },

  {
    label: 'Email',
    id: 'user_name',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
         {columnData || 'N/A'}
        </span>
    )
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word' }}>
        {row?.grouping_manage?.company_name?  breakStringIntoLines(row?.grouping_manage?.company_name,20):'N/A'}</span>
    ),
    nextLine:true,
  },
  {
    label: 'Image Name',
    id: 'image_name',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}> {columnData || 'N/A'}</span>)
  },
  {
    label: 'Amount (INR)',
    id: 'amount',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
      {columnData && currencyConversion(columnData) || 'N/A'}
    </span>),
    nextLine:true,
  },
  {
    label: 'Pack',
    id: 'pack',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}> {columnData || 'N/A'}</span>)
  },
  {
    label: 'Client',
    id: 'client',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (<div style={{ display: 'flex', alignItemsCenter: "center" }}>
      <span style={{ maxWidth: 200, width: 'max-content', wordBreak: "break-word" }}> {columnData || 'N/A'}</span>
      <span onClick={() => { options(row) }}><Edit style={{ fontSize: 18, cursor: 'pointer', marginLeft: 5 }} /></span>
    </div>)
  },
  {
    label: 'Ordered By',
    id: 'ordered_by',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}> {columnData || 'N/A'}</span>)
  },
  {
    label: 'Date',
    id: 'created_at',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}> {formatDate(columnData)}</span>)
  },

];

function IvsDownloadListing() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, ivsDownloadListing } = useSelector(orderListingItemsSelector);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const navigate = useNavigate()
  const location = useLocation();
  const [filtersArray, setFiltersArray] = useState([

    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          return newState;
        });

      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
   
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },

    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: SearchIVSDownloadBy,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },


  ]);
  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params
    }
    if (payload?.start_date) {
      payload['start_date'] = formatDate(payload?.start_date, 'yyyy-mm-dd') + "  00:00:00"
    }
    if (payload?.end_date) {
      payload['end_date'] = formatDate(payload?.end_date, 'yyyy-mm-dd') + "  23:59:59"
    }
    dispatch(getIvsDownloadListing(payload))
    setPage(Number(payload.page))
  }

  const handleOpen = (value, data = null) => {
    setSelectedData(data);
    setOpen(value);
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])
  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[3].isVisible = true;

      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      newState[3].value = '';
      return newState;
    });
  };


  useEffect(() => {
    if (ivsDownloadListing?.items?.rows?.length >= 0) {
      setTableListing(ivsDownloadListing?.items?.rows)
    }
  }, [ivsDownloadListing.items]);

  return (
    <>
      <MatTable
        loading={ivsDownloadListing?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date', 'search_by'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date', 'search_by'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        showPageLimit={true}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={ivsDownloadListing?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        columnWiseData={[
          {
            id: "client",
            value: (data) => { handleOpen(true, data) }
          }
        ]}
        toolbarEnable={true}
        pagination={true}
        rangeDateFilter
        disableSearch
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
          />
        )}
      />
      {open && <EditIvsDownloadClientModal open={open} handleOpen={handleOpen} setPage={setPage} getListing={getListing} data={selectedData} />}
    </>
  );
}

export default IvsDownloadListing;
