import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import DailySalesEntryCreate from './DailySalesEntryCreate'
import FollowUps from './FollowUps'

function DailySalesEntryCreateContainer() {
  const [navParams, setNavParams] = useState({
    navTitle: "Create Daily Sales Entry",
    navSubTitle: "Welcome to ImagesBazaar"
})
const [addUserModal, setAddUserModal] = useState(false)
const handleAddUserModal = (value=false) =>{
  setAddUserModal(value)
}

const handleNavParams = (obj)=>{
  setNavParams({
      ...navParams,
      ...obj
  })
}
  return (
    <MainLayout navTitle={navParams.navTitle} navSubTitle={navParams.navSubTitle}>
      <DailySalesEntryCreate handleNavParams={handleNavParams} addUserModal={addUserModal} handleAddUserModal={handleAddUserModal}/>
    </MainLayout>
  )
}

export default DailySalesEntryCreateContainer