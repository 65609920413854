import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Delete, Edit } from '@material-ui/icons';
import { DATA_LIMIT } from '../../data/constants';
import { Grid, Typography, useTheme, Checkbox, IconButton } from '@mui/material';

import {
  breakStringIntoLines,
  encryptData,
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
  makeUrlForNextPage,
  makeUrlForNextPageLocation,
} from '../../lib/helper';
import { companyItemsSelector, setCompanyRedux, setResetCompanyOverviewList, setResetCompanyReduxData } from '../../redux/company';
import {
  companyGroupsListings,
  companyListings,
  companyMasterListings,
  companyOverviewUserListings,
  deleteCompany,
  deleteCompanyGroup,
} from '../../Services/companyApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import ConfirmationModal from '../Modals/ConfirmationModal';
import MatTable from '../Tables/MatTable';
import AddNewCompanyMaster from '../Modals/AddNewCompanyMaster';
import EditCompanyMasterDetails from '../Modals/EditCompanyMasterDetails';
import AllFilters from '../Order/AllFilters';
import { companyByNameListingsNoRedux, companyByNameListingsNoReduxNew, companyMasterByNameListingsNoRedux, companyUserByAssociationAndCompanyListingsNoRedux } from '../../Services/CRMFollowups';
import { SearchByBill } from '../../data/userData';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import Divder from '../CommonComp/Divder';
import CompanyMasterTotalEarning from './CompanyMasterTotalEarning';

const column = [
  {
    label: 'ID',
    id: 'user_id',
    format: (columnData) => (

      <Typography
        variant='span'
        style={{ whiteSpace: 'nowrap' }}
      >
        {columnData}
      </Typography>

    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'First Name',
    id: 'first_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Last Name',
    id: 'last_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", wordBreak: 'break-word' }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Mobile',
    id: 'mobile',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'NPlan',
    id: 'NplanTotal',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (

      <Link className='textDecoration-none link-text' target="_blank" to={`orders?${makeUrlForNextPageLocation(location?.search, row?.user_id, 'nplan')}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => {
            localStorage.removeItem('order_type');
            localStorage.removeItem('user_id');
            localStorage.setItem("user_id", row?.user_id);
            localStorage.setItem("order_type", 'nplan');
          }}
        >
          {columnData}
        </Typography>
      </Link>
    )
  },
  {
    label: 'IV',
    id: 'IVTotal',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <Link className='textDecoration-none link-text' target="_blank" to={`orders?${makeUrlForNextPageLocation(location?.search)}&user_id=${row?.user_id}&order_type=iv`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => {
            localStorage.removeItem('order_type');
            localStorage.removeItem('user_id');
            localStorage.setItem("user_id", row?.user_id);
            localStorage.setItem("order_type", 'iv');
          }}
        >
          {columnData}
        </Typography>
      </Link>
    )
  },
  {
    label: 'Plan',
    id: 'PlanTotal',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <Link className='textDecoration-none link-text' target="_blank" to={`orders?${makeUrlForNextPageLocation(location?.search)}&user_id=${row?.user_id}&order_type=plan`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => {
            localStorage.removeItem('order_type');
            localStorage.removeItem('user_id');
            localStorage.setItem("user_id", row?.user_id);
            localStorage.setItem("order_type", 'plan');
          }}
        >
          {columnData}
        </Typography>
      </Link>
    )
  },
  {
    label: 'Total',
    id: 'total_orders',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <Link className='textDecoration-none link-text' target="_blank" to={`orders?${makeUrlForNextPageLocation(location?.search)}&user_id=${row?.user_id}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => {
            localStorage.removeItem('order_type');
            localStorage.removeItem('user_id');
            localStorage.setItem("user_id", row?.user_id);
            localStorage.setItem("order_type", 'all');
          }}
        >
          {columnData}
        </Typography>
      </Link>
    )
  },

  {
    label: 'Special Comment',
    id: 'lastSpecialComment',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}</span>)
  },

  {
    label: 'Gift Policy',
    id: 'gift_policy',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData == 0 ? 'No' : 'Yes' || 'N/A'}</span>)
  },
  {
    label: 'Discount(%)',
    id: 'discount',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData >= 0 ? columnData : 'N/A'}</span>)
  },
  {
    label: 'Credit Period',
    id: 'lastOrderCreditPeriod',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
      {isNaN(columnData) || columnData == '' ? 'N/A' : columnData}
    </span>)
  },
  {
    label: 'Approval Mode',
    id: 'lastOrderApprovalMode',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  //lastSpecialComment

];

function CompanyMasterListOverview() {
  const [tableListing, setTableListing] = useState([]);
  const [showCompanyTotal, setShowCompanyTotal] = useState(false)
  const [page, setPage] = useState(1);
  const [editOpen, setEditOpen] = useState(false);
  const [limit, setLimit] = useState(20);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, companyGroupsList, companyOverviewUserList, companyDataRedux } =
    useSelector(companyItemsSelector);
  const dispatch = useDispatch();
  const [addCompanyMasterModal, setAddCompanyMasterModal] = useState(false);
  const [confirmDeleteCompanyGroup, setConfirmDeleteCompanyGroup] =
    useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [deleteCompanyGroupData, setDeleteCompanyGroupData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const [companyData, setCompanyData] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [companySearch, setCompanySearch] = useState(null);
  const [userDataByCompanyState, setUserDataByCompanyState] = useState([])
  const selectRefCompany = useRef(null);

  const getCompanyListing = async (inputValue, index) => {
    if (inputValue && inputValue?.length > 2) {
      let data = await companyByNameListingsNoRedux({
        company_name: inputValue,
        limit: 4,
        page: 1
      })

      if (data) {
        let updatedData = data?.map((item, index) => {
          return {
            value: item.company_name,
            label: item.company_name,
            states: item.state
          }
        })
        setCompanyData(updatedData)
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[2]['value'] = inputValue
          return newState;
        });

        return updatedData || []
      }
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[0]['value'] = inputValue;
        return newState;
      });

      setCompanyData([])
      return []
    }
  }

  const handleCompanyOrGroupSelect1 = async (e) => {
    let data1 = await companyByNameListingsNoReduxNew({
      company_name: e.value,
    })

    if (data1) {
      let companyDataCopy = { ...e };
      let associationArray = [];
      if (data1?.length === 1 && data1?.length > 0) {
        let updatedData = {
          value: data1[0]?.company_name,
          label: data1[0]?.company_name,
          associations: data1[0]?.association
        }
        associationArray = data1[0]?.association?.map((ass) => ({ label: ass?.association, value: ass.association }))
        companyDataCopy['associations'] = updatedData;
        // associationArray.push({ 'label': data1[0]?.association?.[0]?.association, value: data1[0]?.association?.[0]?.association })
        associationArray?.unshift({ 'label': 'Select', value: '' })//setCompanyData(companyDataCopy?.associations)
        companyDataCopy['associationArray'] = associationArray;
      }
      let stateArray = [];
      if (e?.value) {
        let resp = await companyUserByAssociationAndCompanyListingsNoRedux({ company_name: e?.value })
        setStateData(resp);
        if (resp) {
          stateArray = resp?.map((st) => ({ 'label': st?.state, value: st?.state }))
          stateArray?.unshift({ 'label': 'Select', value: '' })
        }
        setCompanySearch({ state: e?.value, company_name: e?.value })

        setUserDataByCompanyState(resp?.rows);
      }
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[2]['value'] = companyDataCopy?.value;
        newState[2]['listingData'] = companyDataCopy;
        if (companyDataCopy?.states) {
          let newArr = companyDataCopy?.states?.map((a) => {
            return {
              value: a?.state, label: a?.state
            }
          })
          companyDataCopy['statesList'] = stateArray;
          newArr?.unshift({ 'label': 'Select', value: '' });
          companyDataCopy['selectedArray'] = newArr;
          setStateData(newArr);
          if (data1[0]?.association?.[0]?.association) {
            newState[3]['isVisible'] = true;
            newState[3]['dataArr'] = associationArray;
          }
          setCompanyData(companyDataCopy);

          newState[4]['isVisible'] = true;
          newState[4]['dataArr'] = stateArray;
        }
        return newState;
      });

    }

    blurAsyncCompany();
  }

  const setResetListingData = () => {
    setCompanyData([]);
    handleClear(true, true);
    dispatch(setResetCompanyOverviewList());
    setTableListing([]);
    setShowCompanyTotal(false);
    dispatch(setResetCompanyReduxData());
    dispatch(setResetCompanyOverviewList());
  }


  const handleCompanyOrGroupSelect = async (e, index) => {
    let companyDataCopy = { ...e };
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0]['value'] = companyDataCopy?.value;
      newState[0]['listingData'] = companyDataCopy;
      if (companyDataCopy?.states) {
        let newArr = companyDataCopy?.states?.map((a) => {
          return {
            value: a?.state, label: a?.state
          }
        })
        newArr?.unshift({ 'label': 'Select', value: '' })
        setStateData(newArr);
        newState[1]['isVisible'] = true;
        newState[1]['dataArr'] = newArr;
        newState[2]['isVisible'] = true;
        newState[2]['dataArr'] = newArr;
      }
      return newState;
    });
    setCompanyData(companyDataCopy);

    blurAsyncCompany();
  }

  const blurAsyncCompany = () => {
    selectRefCompany.current?.blur();
  };

  const getttingStateData = async (e, copyData) => {
    let association = e?.target?.value;
    let stateArray = [];
    if (e?.target?.value) {
      //companyUserByAssociationAndCompanyListingsNoRedux
      let resp = await companyUserByAssociationAndCompanyListingsNoRedux({ company_name: filtersArray?.[2]?.value, association: e.target?.value })
      setStateData(resp);
      if (resp) {
        stateArray = resp?.map((st) => ({ 'label': st?.state, value: st?.state }))
        stateArray?.unshift({ 'label': 'Select', value: '' })
      }
    }
    else {
      let resp = await companyUserByAssociationAndCompanyListingsNoRedux({ company_name: filtersArray?.[2]?.value })
      setStateData(resp);
      if (resp) {
        stateArray = resp?.map((st) => ({ 'label': st?.state, value: st?.state }))
        stateArray?.unshift({ 'label': 'Select', value: '' })
      }

    }
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      let companyDataCopy = { ...newState[2]['wholeData'] };
      if (stateArray?.length) {
        companyDataCopy['statesList'] = stateArray;
        newState[4]['dataArr'] = stateArray;
      }
      // companyDataCopy['statesList'] = stateArray;
      // newState[4]['dataArr'] = stateArray;
      setCompanyData(companyDataCopy);
      newState[3]['value'] = association;
      newState[4]['isVisible'] = true;
      newState[4]['value'] = '';
      // newState[3].value = e.target.value;
      return newState;
    });


  }

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: SearchByBill,
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          // newState[index].value = e.target.value;
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
    {
      label: 'Search Company',
      id: 'short_company_name',
      type: 'async-select',
      linkedWith: null,
      value: companyDataRedux?.items?.value || '',
      onChange: (e, index, value) => {
        getCompanyListing(e, index);
        handleCompanyOrGroupSelect1(e);

      },
      style: {},
      dataArr: [],
      getListings: getCompanyListing,
      setListingData: setResetListingData,
      handleChangeAsyncSelect: handleCompanyOrGroupSelect1,
      // asyncRef: blurAsyncCompany,
      asyncRef:selectRefCompany,
      props: {},
      listingData: allParams?.short_company_name && companyDataRedux?.items ? companyDataRedux?.items : companyData,
      // selectRefCompany: selectRefCompany,
      isVisible: true,
    },
    {
      label: 'Select Association',
      id: 'association',
      type: 'select',
      linkedWith: null,
      value: allParams?.association || '',
      onChange: (e, index, value) => {
        // setFiltersArray((prevState) => {
        //   let newState = [...prevState];
        //   newState[index].value = e.target.value;
        //   return newState;
        // });
        getttingStateData(e, companyData);

      },
      style: {},
      dataArr: allParams?.association ? companyDataRedux?.items?.associationArray :
        [
          { label: 'Select', value: '' },
        ],
      getttingStateData: getttingStateData,
      props: {},
      isVisible: allParams?.association && companyDataRedux?.items?.associationArray ? true : false,
    },
    {
      label: 'Select State',
      id: 'state',
      type: 'select',
      linkedWith: null,
      value: allParams?.state || '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });

      },
      style: {},
      dataArr: allParams?.state && companyDataRedux?.items?.statesList ? companyDataRedux?.items?.statesList :
        [
          { label: 'Select', value: '' },
        ],
      props: {},
      isVisible: allParams?.state && companyDataRedux?.items?.statesList ? true : false,
    },

  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );

    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
    dispatch(setCompanyRedux(companyData));

  };

  const handleClear = (index1 = null, index2 = null) => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      //0-to 1-from 2-company 3- association 4- state
      //2=>0-company 3=>1-asscoation    
      let newState = [...prevState];
      newState[3].isVisible = true;
      newState[3].isVisible = true;
      newState[3].value = '';
      newState[3].value = '';
      newState[3].isVisible = false;
      newState[4].value = '';
      newState[4].isVisible = false;
      newState[2]['listingData'] = '';
      newState[2].value = '';
      if (index1 && index2) {

      }
      else {
        newState[0].value = '';
        newState[1].value = '';
      }
      return newState;
    });
    dispatch(setResetCompanyOverviewList());
    dispatch(setResetCompanyReduxData());
    setTableListing([]);
    setShowCompanyTotal(false);
    dispatch(setResetCompanyOverviewList());
  }

  const handleAdd = (value = false) => {
    setAddCompanyMasterModal(value);
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      order_status: String(1),
      limit: limit,
      ...allParams,
      ...params,
    };
    if (payload?.start_date) delete payload.start_date;
    if (payload?.end_date) delete payload.end_date;
    if (payload?.short_company_name || (payload?.search && payload?.search_by)) {
      dispatch(companyOverviewUserListings(payload));
      setPage(Number(payload.page));
    }
  };

  useEffect(() => {
    if (allParams?.short_company_name) {
    }
    else {
      setTableListing([]);
      setShowCompanyTotal(false);
      dispatch(setResetCompanyOverviewList());
      dispatch(setResetCompanyReduxData());
      dispatch(setResetCompanyOverviewList());
    }
  }, [])

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getListing();
  }, [allParams]);

  //making data format for table input
  useEffect(() => {
    if (companyOverviewUserList?.items?.rows?.length > 0 && (allParams?.short_company_name|| (allParams?.search && allParams?.search_by))) {
      let data = companyOverviewUserList?.items?.rows?.map((item) => {
        return {
          user_id: item.user_id,
          user_name: item?.user_name,
          first_name: item?.first_name,
          email: item?.email,
          last_name: item?.last_name,
          job_description: item?.job_description,
          mobile: item?.mobile,
          company_name: item?.company_name,
          status: item?.status,
          totalEarnings: item?.totalEarnings,
          total_orders: item?.total_orders,
          state: item?.state,
          identity: item?.identity,
          created_at: item?.created_at,
          IVTotal: item?.IVTotal,
          NplanTotal: item?.NplanTotal,
          PlanTotal:item?.PlanTotal,
          gift_policy: item?.lastOrderGiftPolicy,
          lastOrderApprovalMode: item?.lastOrderApprovalMode,
          lastOrderCreditPeriod: item?.lastOrderCreditPeriod,
          lastOrderDiscount: item?.lastOrderDiscount,
          lastSpecialComment: item?.lastSpecialComment,
          discount: item?.discount,

        };

      });
      setTableListing(data);
      setShowCompanyTotal(true)
    }
    else {
      setTableListing([]);
      setShowCompanyTotal(false);
      dispatch(setResetCompanyOverviewList());
      dispatch(setResetCompanyReduxData());
      dispatch(setResetCompanyOverviewList());

    }
  }, [companyOverviewUserList.items?.rows]);

  const handleConfirmDeleteCompanyGroup = async (value) => {
    if (value && deleteCompanyGroupData) {
      let resp = deleteCompanyGroup({
        company_group_id: deleteCompanyGroupData,
      });
      getListing();
    } else {
      setDeleteCompanyGroupData(null);
    }
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDeleteCompanyGroup(value);
  };

  const handleEdit = (value, data = {}) => {
    setEditOpen(value);
    setSelectedData(data);
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            toolTipTittle={'Edit Company Master'}
            onClick={() => {
              handleEdit(true, data);
            }}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonPrimary.main',
              color: 'buttonPrimary.contrastText',
            }}
          >
            <Edit />
          </CustomButton>
          <CustomButton
            disabled={true}
            toolTipTittle={'Delete Company Master'}
            className="buttonDense noMinWidth"
            sx={{
              bgcolor: 'buttonDanger.main',
              color: 'buttonDanger.contrastText',
            }}
          >
            <Delete />
          </CustomButton>
        </div>
      </>
    );
  };

  return (
    <div>
      <CustomBox padding={0}>
        <MatTable
          loading={companyOverviewUserList?.loading}
          showCheckbox={false}
          columns={column}
          data={tableListing}
          onApplyFilter={(data = {}) => {
            getListing({
              page: String(1),
              start_date: '',
              end_date: '',
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
            if (Object.keys(data).length > 0) {
              setAllParams({
                ...allParams,
                page: String(1),
                ...getObjectSubset(data, 'start_date', 'end_date'),
              });
            } else {
              setAllParams({
                page: String(1),
              });
            }
          }}
          page={page}
          perPage={limit}
          showPageLimit={true}
          handleChangePageLimit={(e) => {
            setLimit(e.target.value);
            setAllParams({
              ...allParams,
              page: String('1'),
              limit: Number(e.target.value),
            });
          }}
          total={companyOverviewUserList?.items?.count || 0}
          setPage={setPage}
          handleSearch={(searchTerm) => {
            getListing({
              page: String(1),
              search: searchTerm,
            });
            setAllParams({
              ...allParams,
              page: String(1),
              search: searchTerm,
            });
          }}
          handlePageChange={(newPage) => {
            getListing({
              page: String(newPage),
            });
            setAllParams({
              ...allParams,
              page: String(newPage),
            });
          }}
          toolbarEnable={true}
          pagination={true}
          filtersUrlData={allParams || {}}
          disableSearch

          customFilter={() => (
            <AllFilters
              filtersArray={filtersArray}
              handleClick={handleFilter}
              handleClear={handleClear}
            />
          )}
        />
        {addCompanyMasterModal && (
          <AddNewCompanyMaster
            open={addCompanyMasterModal}
            handleOpen={handleAdd}
          />
        )}
        {confirmDeleteCompanyGroup && (
          <ConfirmationModal
            open={confirmDeleteCompanyGroup}
            handleConfirm={handleConfirmDeleteCompanyGroup}
            setOpen={handleCofirmModalOpen}
          />
        )}
        {editOpen && (
          <EditCompanyMasterDetails
            open={editOpen}
            handleOpen={handleEdit}
            setPage={setPage}
            getListing={getListing}
            selectedData={selectedData}
          />
        )}
      </CustomBox>
      {tableListing?.length ? <hr /> : ''}
      <Divder spacing={2} />
      {showCompanyTotal ? <CompanyMasterTotalEarning /> : <></>}
    </div>

  );
}

export default CompanyMasterListOverview;
