import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allUserList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  buyerUserList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  temporaryUserList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleUserDetail: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleUserCartDetail: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleUserWishlistDetail: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleDownloadImagelistDetail: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleDownloadPrivillegeImagelistDetail: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleUserOutstandingDetail: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleUserActiveIvsOrderDetail: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  singleUserProposalDetail: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBuyerUserListing: (state, action) => {
      state.buyerUserList.items = action.payload;
      state.buyerUserList.error = {
        isError: false,
        message: '',
      };
    },
    setBuyerUserListingLoading: (state, action) => {
      state.buyerUserList.loading = action.payload;
    },
    setBuyerUserListingError: (state, action) => {
      state.buyerUserList.items = {};
      state.buyerUserList.error = action.payload;
    },
    setAllUserListing: (state, action) => {
      state.allUserList.items = action.payload;
      state.allUserList.error = {
        isError: false,
        message: '',
      };
    },
    setAllUserListingLoading: (state, action) => {
      state.allUserList.loading = action.payload;
    },
    setAllUserListingError: (state, action) => {
      state.allUserList.items = {};
      state.allUserList.error = action.payload;
    },
    setTemporaryUserListing: (state, action) => {
      state.temporaryUserList.items = action.payload;
      state.temporaryUserList.error = {
        isError: false,
        message: '',
      };
    },
    setTemporaryUserListingLoading: (state, action) => {
      state.temporaryUserList.loading = action.payload;
    },
    setTemporaryUserListingError: (state, action) => {
      state.temporaryUserList.items = {};
      state.temporaryUserList.error = action.payload;
    },
    setSingleUserDetails: (state, action) => {
      state.singleUserDetail.items = action.payload;
      state.singleUserDetail.error = {
        isError: false,
        message: '',
      };
    },
    setSingleUserDetailsLoading: (state, action) => {
      state.singleUserDetail.loading = action.payload;
    },
    setSingleUserDetailsError: (state, action) => {
      state.singleUserDetail.items = {};
      state.singleUserDetail.error = action.payload;
    },
    setSingleUserCartDetails: (state, action) => {
      state.singleUserCartDetail.items = action.payload;
      state.singleUserCartDetail.error = {
        isError: false,
        message: '',
      };
    },
    setSingleUserCartDetailsLoading: (state, action) => {
      state.singleUserCartDetail.loading = action.payload;
    },
    setSingleUserCartDetailsError: (state, action) => {
      state.singleUserCartDetail.items = {};
      state.singleUserCartDetail.error = action.payload;
    },
    setSingleUserWishlistDetails: (state, action) => {
      state.singleUserWishlistDetail.items = action.payload;
      state.singleUserWishlistDetail.error = {
        isError: false,
        message: '',
      };
    },
    setSingleUserWishlistDetailsLoading: (state, action) => {
      state.singleUserWishlistDetail.loading = action.payload;
    },
    setSingleUserWishlistDetailsError: (state, action) => {
      state.singleUserWishlistDetail.items = {};
      state.singleUserWishlistDetail.error = action.payload;
    },
    setSingleUserOutstandingDetails: (state, action) => {
      state.singleUserOutstandingDetail.items = action.payload;
      state.singleUserOutstandingDetail.error = {
        isError: false,
        message: '',
      };
    },
    setSingleUserOutstandingDetailsLoading: (state, action) => {
      state.singleUserOutstandingDetail.loading = action.payload;
    },
    setSingleUserOutstandingDetailsError: (state, action) => {
      state.singleUserOutstandingDetail.items = {};
      state.singleUserOutstandingDetail.error = action.payload;
    },
    setSingleUserActiveIvsOrderDetails: (state, action) => {
      state.singleUserActiveIvsOrderDetail.items = action.payload;
      state.singleUserActiveIvsOrderDetail.error = {
        isError: false,
        message: '',
      };
    },
    setSingleUserActiveIvsOrderDetailsLoading: (state, action) => {
      state.singleUserActiveIvsOrderDetail.loading = action.payload;
    },
    setSingleUserActiveIvsOrderDetailsError: (state, action) => {
      state.singleUserActiveIvsOrderDetail.items = {};
      state.singleUserActiveIvsOrderDetail.error = action.payload;
    },
    setSingleUserProposalDetails: (state, action) => {
      state.singleUserProposalDetail.items = action.payload;
      state.singleUserProposalDetail.error = {
        isError: false,
        message: '',
      };
    },
    setSingleUserProposalDetailsLoading: (state, action) => {
      state.singleUserProposalDetail.loading = action.payload;
    },
    setSingleUserProposalDetailsError: (state, action) => {
      state.singleUserProposalDetail.items = {};
      state.singleUserProposalDetail.error = action.payload;
    },
    setSingleUserImageDownloadDetails: (state, action) => {
      state.singleDownloadImagelistDetail.items = action.payload;
      state.singleDownloadImagelistDetail.error = {
        isError: false,
        message: '',
      };
    },
    setSingleUserImageDownloadDetailsLoading: (state, action) => {
      state.singleDownloadImagelistDetail.loading = action.payload;
    },
    setSingleUserImageDownloadDetailsError: (state, action) => {
      state.singleDownloadImagelistDetail.items = {};
      state.singleDownloadImagelistDetail.error = action.payload;
    },
    setSingleUserPrivillegeImageDownloadDetails: (state, action) => {
      state.singleDownloadPrivillegeImagelistDetail.items = action.payload;
      state.singleDownloadPrivillegeImagelistDetail.error = {
        isError: false,
        message: '',
      };
    },
    setSingleUserPrivillegeImageDownloadDetailsLoading: (state, action) => {
      state.singleDownloadPrivillegeImagelistDetail.loading = action.payload;
    },
    setSingleUserPrivillegeImageDownloadDetailsError: (state, action) => {
      state.singleDownloadPrivillegeImagelistDetail.items = {};
      state.singleDownloadPrivillegeImagelistDetail.error = action.payload;
    },

    clearSingleUserDetails: (state, action) => {
      state.singleUserDetail.items = {};
      state.singleUserCartDetail.items = {};
      state.singleUserWishlistDetail.items = {};
      state.singleUserProposalDetail.items = {};
      state.singleDownloadImagelistDetail.items = {};
      state.singleDownloadPrivillegeImagelistDetail.items={};
      state.singleUserActiveIvsOrderDetail.items={}
     
    },
  },
});

export default userSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setBuyerUserListing,
  setBuyerUserListingError,
  setAllUserListing,
  setAllUserListingLoading,
  setAllUserListingError,
  setTemporaryUserListing,
  setTemporaryUserListingError,
  setSingleUserDetails,
  setSingleUserDetailsError,
  setSingleUserCartDetails,
  setSingleUserCartDetailsError,
  setSingleUserWishlistDetails,
  setSingleUserWishlistDetailsError,
  setSingleUserProposalDetails,
  setSingleUserProposalDetailsError,
  clearSingleUserDetails,
  setBuyerUserListingLoading,
  setTemporaryUserListingLoading,
  setSingleUserDetailsLoading,
  setSingleUserCartDetailsLoading,
  setSingleUserWishlistDetailsLoading,
  setSingleUserProposalDetailsLoading,
  setSingleUserOutstandingDetails,
  setSingleUserOutstandingDetailsError,
  setSingleUserOutstandingDetailsLoading,
  setSingleUserActiveIvsOrderDetails,
  setSingleUserActiveIvsOrderDetailsError,
  setSingleUserActiveIvsOrderDetailsLoading,
  setSingleUserImageDownloadDetails,
  setSingleUserImageDownloadDetailsLoading,
  setSingleUserImageDownloadDetailsError,
  setSingleUserPrivillegeImageDownloadDetails,
  setSingleUserPrivillegeImageDownloadDetailsLoading,
  setSingleUserPrivillegeImageDownloadDetailsError
} = userSlice.actions;

export const userItemsSelector = (state) => state.user;
