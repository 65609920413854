import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  userFollowUpData: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  orderFollowUpData: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  paymentFollowUpData: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  manageReportingData: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  dailySalesEntry: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  unregisteredCompany: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  outstandingPaymentData: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  lastUserFollowUpData: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },

  searchCompanyUserFollowUpData: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  companyInvoiceDropDown:{
    items:{}
  }
};

export const followUpSlice = createSlice({
  name: 'followUps',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setUserFollowUpData: (state, action) => {
      state.userFollowUpData.items = action.payload;
      state.userFollowUpData.error = {
        isError: false,
        message: '',
      };
    },
    setUserFollowUpLoading: (state, action) => {
      state.userFollowUpData.loading = action.payload;
    },
    setUserFollowUpError: (state, action) => {
      state.userFollowUpData.items = {};
      state.userFollowUpData.error = action.payload;
    },
    setOrderFollowUpData: (state, action) => {
      state.orderFollowUpData.items = action.payload;
      state.orderFollowUpData.error = {
        isError: false,
        message: '',
      };
    },
    setOrderFollowUpLoading: (state, action) => {
      state.orderFollowUpData.loading = action.payload;
    },
    setOrderFollowUpError: (state, action) => {
      state.orderFollowUpData.items = {};
      state.orderFollowUpData.error = action.payload;
    },
    setPaymentFollowUpData: (state, action) => {
      state.paymentFollowUpData.items = action.payload;
      state.paymentFollowUpData.error = {
        isError: false,
        message: '',
      };
    },
    setPaymentFollowUpLoading: (state, action) => {
      state.paymentFollowUpData.loading = action.payload;
    },
    setPaymentFollowUpError: (state, action) => {
      state.paymentFollowUpData.items = {};
      state.paymentFollowUpData.error = action.payload;
    },
    setDailySalesEntryData: (state, action) => {
      state.dailySalesEntry.items = action.payload;
      state.dailySalesEntry.error = {
        isError: false,
        message: '',
      };
    },
    setDailySalesEntryLoading: (state, action) => {
      state.dailySalesEntry.loading = action.payload;
    },

    setDailySalesEntryError: (state, action) => {
      state.dailySalesEntry.items = {};
      state.dailySalesEntry.error = action.payload;
    },
    setUnregisteredCompanyData: (state, action) => {
      state.unregisteredCompany.items = action.payload;
      state.unregisteredCompany.error = {
        isError: false,
        message: '',
      };
    },
    setUnregisteredCompanyLoading: (state, action) => {
      state.unregisteredCompany.loading = action.payload;
    },

    setUnregisteredCompanyError: (state, action) => {
      state.unregisteredCompany.items = {};
      state.unregisteredCompany.error = action.payload;
    },
    setResetOutstandingPaymenttData: (state, action) => {
      state.outstandingPaymentData.items = {};
      state.outstandingPaymentData.error = {
        isError: false,
        message: '',
      };
      state.outstandingPaymentData.loading = false;
    },
    setOutstandingPaymentData: (state, action) => {
      state.outstandingPaymentData.items = action.payload;
      state.outstandingPaymentData.error = {
        isError: false,
        message: '',
      };
    },
    setOutstandingPaymentLoading: (state, action) => {
      state.outstandingPaymentData.loading = action.payload;
    },
    setOutstandingPaymentError: (state, action) => {
      state.outstandingPaymentData.items = {};
      state.outstandingPaymentData.error = action.payload;
    },
    setManageReportListingData: (state, action) => {
      state.manageReportingData.items = action.payload;
      state.manageReportingData.error = {
        isError: false,
        message: '',
      };
    },
    setManageReportListingLoading: (state, action) => {
      state.manageReportingData.loading = action.payload;
    },
    setManageReportListingError: (state, action) => {
      state.manageReportingData.items = {};
      state.manageReportingData.error = action.payload;
    },


    setLastUserFollowUpData: (state, action) => {
      state.lastUserFollowUpData.items = action.payload;
      state.lastUserFollowUpData.error = {
        isError: false,
        message: '',
      };
    },
    setLastUserFollowUpLoading: (state, action) => {
      state.lastUserFollowUpData.loading = action.payload;
    },
    setLastUserFollowUpError: (state, action) => {
      state.lastUserFollowUpData.items = {};
      state.lastUserFollowUpData.error = action.payload;
    },


    setSearchCompanyUserFollowUpData: (state, action) => {
      state.searchCompanyUserFollowUpData.items = action.payload;
      state.searchCompanyUserFollowUpData.error = {
        isError: false,
        message: '',
      };
    },
    setSearchCompanyUserFollowUpLoading: (state, action) => {
      state.searchCompanyUserFollowUpData.loading = action.payload;
    },
    setSearchCompanyUserFollowUpError: (state, action) => {
      state.searchCompanyUserFollowUpData.items = {};
      state.searchCompanyUserFollowUpData.error = action.payload;
    },
    setInvoiceIdDropDown:(state,action)=>{
      state.companyInvoiceDropDown.items=action?.payload;
    }

  },
});

export default followUpSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setUserFollowUpData,
  setUserFollowUpLoading,
  setUserFollowUpError,
  setOrderFollowUpData,
  setOrderFollowUpLoading,
  setOrderFollowUpError,
  setPaymentFollowUpData,
  setPaymentFollowUpLoading,
  setPaymentFollowUpError,
  setResetOutstandingPaymenttData,
  setOutstandingPaymentData,
  setOutstandingPaymentLoading,
  setOutstandingPaymentError,
  setDailySalesEntryData,
  setDailySalesEntryError,
  setDailySalesEntryLoading,
  setUnregisteredCompanyLoading,
  setUnregisteredCompanyData,
  setUnregisteredCompanyError,
  setManageReportListingData,
  setManageReportListingError,
  setManageReportListingLoading,
  setLastUserFollowUpData,
  setLastUserFollowUpLoading,
  setLastUserFollowUpError,
  setSearchCompanyUserFollowUpData,
  setSearchCompanyUserFollowUpLoading,
  setSearchCompanyUserFollowUpError,
  setInvoiceIdDropDown

} = followUpSlice.actions;

export const followUpsItemSelector = (state) => state.followUpsDataList;
