import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { deleteApiPayload, errorMessage } from '../lib/helper';
import { DATA_LIMIT, STATUS_200, STATUS_300 } from '../data/constants';
import { setAllAutoSuggestListing, setAllAutoSuggestListingLoading } from '../redux/autoSugg';

export const autoListings = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setAllAutoSuggestListingLoading(true));
        const resp = await axios.post(`${ApiConfig.autoListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
          dispatch(setAllAutoSuggestListing(resp.data.data));
          dispatch(setAllAutoSuggestListingLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
        dispatch(
          setAllImagesListingError({
            isError: true,
            message: errorMessage(e),
          }),
        );
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        dispatch(setAllAutoSuggestListingLoading(false));
      }
    };
  }
};

export const editAutoSuggestion = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.editAutoSuggestion}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const addAutoSuggestion = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.post(`${ApiConfig.addAutoSuggestion}`, payload);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp.data.message, {
          autoClose: 2500,
        });
        return true
      } else {
        throw resp;
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};

export const deleteAutoSuggestion = async (payload) => {
  if (payload !== undefined) {
    try {
      const resp = await axios.delete(`${ApiConfig.deleteAutoSuggestion}`, deleteApiPayload(payload));
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        toast.success(resp?.data?.message, {
          autoClose: 2500
        })
        return true
      } else {
        throw resp
      }
    } catch (e) {
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      return false
    }
  }
};
export const uploadSuggestions= async (payload, header) => {
  try {
    const resp = await axios.post(`${ApiConfig.uploadSuggestionCSV}`, payload, header);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      toast.success(resp.data.message, {
        autoClose: 2500,
      });
      return resp.data;
    } else {
      throw resp;
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
  
};