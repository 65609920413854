import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import Model from './Model'

function ModelContainer() {

  const [navParams, setNavParams] = useState({
    navTitle: "Subscription",
    navSubTitle: "Welcome to ImagesBazaar"
  })

  const handleNavParams = (obj)=>{
    setNavParams({
      ...navParams,
      ...obj
    })
  }
  return (
    <MainLayout navTitle={"Model"} navSubTitle={"Welcome to ImagesBazaar"}>
      <Model  handleNavParams={handleNavParams}/>
    </MainLayout>
  )
}

export default ModelContainer