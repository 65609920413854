import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  visibleKeywordList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  hiddenKeywordList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
  visibleHiddenKeywordList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const keywordSlice = createSlice({
  name: 'keyword',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setVisibleKeywordListingLoading: (state, action) => {
      state.visibleKeywordList.loading = action.payload
    },
    setVisibleKeywordListing: (state, action) => {
      state.visibleKeywordList.items = action.payload;
      state.visibleKeywordList.error = {
        isError: false,
        message: '',
      };
    },
    setVisibleKeywordListingError: (state, action) => {
      state.visibleKeywordList.items = {};
      state.visibleKeywordList.error = action.payload;
    },
    setHiddenKeywordListingLoading: (state, action) => {
      state.hiddenKeywordList.loading = action.payload
    },
    setHiddenKeywordListing: (state, action) => {
      state.hiddenKeywordList.items = action.payload;
      state.hiddenKeywordList.error = {
        isError: false,
        message: '',
      };
    },
    setHiddenKeywordListingError: (state, action) => {
      state.hiddenKeywordList.items = {};
      state.hiddenKeywordList.error = action.payload;
    },
    setVisibleHiddenKeywordListingLoading: (state, action) => {
      state.visibleHiddenKeywordList.loading = action.payload
    },
    setVisibleHiddenKeywordListing: (state, action) => {
      state.visibleHiddenKeywordList.items = action.payload;
      state.visibleHiddenKeywordList.error = {
        isError: false,
        message: '',
      };
    },
    setVisibleHiddenKeywordListingError: (state, action) => {
      state.visibleHiddenKeywordList.items = {};
      state.visibleHiddenKeywordList.error = action.payload;
    },
  },
});

export default keywordSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setHiddenKeywordListing,
  setHiddenKeywordListingError,
  setHiddenKeywordListingLoading,
  setVisibleKeywordListing,
  setVisibleKeywordListingError,
  setVisibleKeywordListingLoading,
  setVisibleHiddenKeywordListing,
  setVisibleHiddenKeywordListingError,
  setVisibleHiddenKeywordListingLoading
} = keywordSlice.actions;

export const keywordItemsSelector = (state) => state.keyword;
