import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatDate, getObjectSubset, statusText } from '../../lib/helper';
import { categoryItemsSelector } from '../../redux/category';
import { categoryListings, deleteSubCategory, subCategoryListings } from '../../Services/categoryApis';
import { saleFollowUpListingsNoRedux } from '../../Services/CRMFollowups';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AddEditSubCategoryModal from '../Modals/AddEditSubCategoryModal';
import ConfirmationModal from '../Modals/ConfirmationModal';
import MatTable from '../Tables/MatTable';
import EditFollowupHistoryEntryModal from '../Modals/EditFollowupHistoryEntryModal';

const limit = 10;

const column = [
  {
    label: 'Creation Date',
    id: 'created_at',
    format: (columnData) => (
        <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{formatDate(columnData, 'dd-mm-yyyy') || 'N/A'}</span>
    )
  },
  {
    label: 'Followups Date',
    id: 'next_followup_date',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{formatDate(columnData, 'dd-mm-yyyy') || 'N/A'}</span>)
  },
  {
    label: 'Description',
    id: 'description',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content", wordBreak: 'break-all'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Contact Person',
    id: 'contact_person',
    format: (columnData) => (<span style={{ display: 'inline-block', wordBreak: 'break-all', width: "max-content"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Contact No.',
    id: 'contact_number',
    format: (columnData) => (<span style={{ display: 'inline-block', wordBreak: 'break-all', width: "max-content"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (<span style={{ display: 'inline-block', wordBreak: 'break-all', width: "max-content"}}>{statusText(columnData) || 'N/A'}</span>)
  },
  {
    label: 'Requirement Type',
    id: 'requirement',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Created By',
    id: 'created_by',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Follow up by',
    id: 'followup_by',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{option || '-'}</span>)
  },
];

function DailySaleEntryHistoryList({dailySaleId}) {
  const [tableListing, setTableListing] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState(null);
  const [allParams, setAllParams] = useState({});
  const { subCategoryList } = useSelector(categoryItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if(!dailySaleId){
    navigate('/crm', {replace: true})
  }

  const getListing = async (params={}) => {
    try{

      const payload = {
        daily_sale_id: dailySaleId,
        page: String(1), 
        limit: limit,
        ...allParams,
        ...params
      }
      setLoading(true)
      const salesListing = await saleFollowUpListingsNoRedux(payload)
      // dispatch(subCategoryListings(payload))
      if(salesListing?.length){
        setTableListing(salesListing[0])
      }
      setLoading(false)
      setPage(Number(payload.page))
    }catch(e){
      setLoading(false)
    }
  }

  useEffect(() => {
    getListing();
  }, []);


  const handleEditModal = (value=false)=>{
    setOpen(value)
    if(!value){
      setSelectedData(null)
    }
  }
  const actionBody = (data) => {
    return (
      <>
        <div style={{display: "flex"}}>
          <CustomButton className="noMinWidth buttonDense" toolTipTittle={'Edit'} onClick={()=>{
            setSelectedData({
              ...data, 
              order_id: tableListing?.order_id,
              daily_sale_id: dailySaleId
            })
            handleEditModal(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit/></CustomButton>
        </div>
      </>
    );
  };


  return (
    <CustomBox padding={0}>
      <MatTable
        loading={loading}
        showCheckbox={false}
        columns={column}
        data={tableListing?.daily_sale_followups || []}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={subCategoryList?.items?.totalCount || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}

        columnWiseData={[
          {
            id: 'order_id',
            value: tableListing?.order_id || "-",
          }
        ]}
        toolbarEnable={true}
        pagination={true}
        filterEnable
        rangeDateFilter
      />
      {open && <EditFollowupHistoryEntryModal open={open} handleOpen={handleEditModal} selectedData={selectedData} getListing={getListing}/>}
    </CustomBox>
  );
}

export default DailySaleEntryHistoryList;
