import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import CompanyOrderDetails from './CompanyOrderDetails'
function CompanyOrdersDetailsContainer() {
  return (
    <MainLayout navTitle={"Order List"} navSubTitle={"Welcome to ImagesBazaar"}>
      <CompanyOrderDetails />
    </MainLayout>
  )
}

export default CompanyOrdersDetailsContainer