import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { errorMessage } from '../lib/helper';
import {
  setLoading,
  setDashboardCount,
  setDashboardCountError,
} from '../redux/dashboard';

export const dashboardCountData = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.get(`${ApiConfig.dashboardCounts}`);
      if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
        dispatch(setDashboardCount(resp.data.data));
        dispatch(setLoading(false));
      }  else {
        throw resp
      }
    } catch (e) {
      dispatch(
        setDashboardCountError({
          isError: true,
          message: errorMessage(e)
        }),
      );
      toast.error(errorMessage(e), {
        autoClose: 2500,
      });
      dispatch(setLoading(false));
    }
  };
};

export const uploadWeeklyImages = async (payload) => {
  try {
    const resp = await axios.post(`${ApiConfig.uploadWeeklyImage}`, payload);
    if (resp?.status >= STATUS_200 && resp?.status < STATUS_300) {
      return resp.data
    }  else {
      throw resp
    }
  } catch (e) {
    toast.error(errorMessage(e), {
      autoClose: 2500,
    });
  }
};
