import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  allContactQueries: {
    error: {
      isError: false,
      message: '',
    },
    items: [],
    loading: false
  }
};

export const contactQueries = createSlice({
  name: 'contactQueries',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setContactQueriesLoading: (state, action) => {
      state.allContactQueries.loading = action.payload;
    },
    setContactQueries: (state, action) => {
      state.allContactQueries.items = action.payload;
      state.allContactQueries.error = {
        isError: false,
        message: '',
      };
    },
    setContactQueriesError: (state, action) => {
      state.allContactQueries.items = [];
      state.allContactQueries.error = action.payload;
    }
  },
});

export default contactQueries.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setContactQueries,
  setContactQueriesError,
  setContactQueriesLoading
} = contactQueries.actions;

export const contactQueriesItemsSelector = (state) => state.contactQueries;
