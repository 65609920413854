import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import Divder from '../CommonComp/Divder';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import { useDispatch, useSelector } from 'react-redux';
import { cmsItemsSelector } from '../../redux/cms';
import { editAddCMS, getCMSInfo } from '../../Services/cmsService';

function AboutUS() {
  const [loading, setLoading] = useState(false);
  const { cmsData } = useSelector(cmsItemsSelector);
  const dispatch = useDispatch();
  let cmsDataHome = cmsData ? cmsData?.items?.length > 0 && JSON?.parse(cmsData?.items[0]?.key_value) : [];

  const handleConfirm = async (values, { resetForm }) => {
    let newObj = { ...cmsDataHome, AboutUsSection: values };
    setLoading(true);
    let str1 = JSON.stringify(newObj);
    let obj = { pagename: 'homePage', pagedata: str1 }
    let resp = await editAddCMS(obj);
    if (resp) {
      getListing();
    }
    setLoading(false);
  };

  useEffect(() => {
    getListing();
  }, [])

  const getListing = () => {
    let payload = { pagename: 'homePage' }
    dispatch(getCMSInfo(payload));
  }

  const formik = useFormik({
    initialValues: {
      aboutUs: cmsDataHome?.AboutUsSection?.aboutUs || '',
      heading1: cmsDataHome?.AboutUsSection?.heading1 || '',
      content1: cmsDataHome?.AboutUsSection?.content1 || '',
      heading2: cmsDataHome?.AboutUsSection?.heading2 || '',
      content2: cmsDataHome?.AboutUsSection?.content2 || '',
      heading3: cmsDataHome?.AboutUsSection?.heading3 || '',
      content3: cmsDataHome?.AboutUsSection?.content3 || '',
      content4: cmsDataHome?.AboutUsSection?.content4 || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      aboutUs: Yup?.string().required("About US is Required!"),
      heading1: Yup?.string().required("Heading is Required!"),
      content1: Yup?.string().required("Content is Required!"),
      heading2: Yup?.string().required("Heading is Required!"),
      content2: Yup?.string().required("Content is Required!"),
      heading3: Yup?.string().required("Heading is Required!"),
      content3: Yup?.string().required("Content is Required!"),
      content4: Yup?.string().required("Content is Required!"),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <>
      <CustomBox padding={1} >
        <Box
          noValidate
          component="form"
          onSubmit={formik.handleSubmit}
          marginTop={2}
          marginLeft={2}

        >

          <Typography variant='h5' mb={2} >About Us</Typography>
          <Grid container spacing={2}>

            <Grid item xs={12} md={8} lg={12}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="aboutUs"
                label="About ImagesBazaar"
                placeholder="Content"
                value={formik.values.aboutUs}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                multiline
                rows={10}
                error={
                  formik.touched.aboutUs && Boolean(formik.errors.aboutUs)
                }
                helperText={
                  formik.touched.aboutUs && formik.errors.aboutUs
                }
              />
            </Grid>


            <Grid item xs={12} md={12} lg={12} textAlign={'center'}>
              <Typography variant='h5' mt={2} >1.Philosophy</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="heading1"
                label="Philosophy"
                placeholder="Philosophy"
                value={formik.values.heading1}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.heading1 && Boolean(formik.errors.heading1)}
                helperText={formik.touched.heading1 && formik.errors.heading1}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={12}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="content1"
                label="Content"
                placeholder="Content"
                value={formik.values.content1}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                multiline
                rows={10}
                error={
                  formik.touched.content1 && Boolean(formik.errors.content1)
                }
                helperText={
                  formik.touched.content1 && formik.errors.content1
                }
              />
            </Grid>


            <Grid item xs={12} md={12} lg={12} textAlign={'center'}>
              <Typography variant='h5' mt={2} >2.People Behind ImagesBazaar</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="heading2"
                label="Contributors"
                placeholder="Contributors"
                value={formik.values.heading2}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.heading2 && Boolean(formik.errors.heading2)}
                helperText={formik.touched.heading2 && formik.errors.heading2}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={12}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="content2"
                label="Content"
                placeholder="Content"
                value={formik.values.content2}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                multiline
                rows={10}
                error={
                  formik.touched.content2 && Boolean(formik.errors.content2)
                }
                helperText={
                  formik.touched.content2 && formik.errors.content2
                }
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12} textAlign={'center'}>
              <Typography variant='h5' mt={2} textAlign={'center'} >3.ImagesBazaar Offers</Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={12}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="heading3"
                label="ImagesBazaar Offers"
                placeholder="ImagesBazaar Offers"
                value={formik.values.heading3}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.heading3 && Boolean(formik.errors.heading3)}
                helperText={formik.touched.heading3 && formik.errors.heading3}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={12}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="content3"
                label="Content"
                placeholder="Content"
                value={formik.values.content3}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                multiline
                rows={10}
                error={
                  formik.touched.content3 && Boolean(formik.errors.content3)
                }
                helperText={
                  formik.touched.content3 && formik.errors.content3
                }
              />
            </Grid>



            <Grid item xs={12} md={12} lg={12} textAlign={'center'}>
              <Typography variant='h5' mt={2} textAlign={'center'} >4.Additional Information</Typography>
            </Grid>


            <Grid item xs={12} md={8} lg={12}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="content4"
                label="Content"
                placeholder="Content"
                value={formik.values.content4}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                multiline
                rows={10}
                error={
                  formik.touched.content4 && Boolean(formik.errors.content4)
                }
                helperText={
                  formik.touched.content4 && formik.errors.content4
                }
              />
            </Grid>
          </Grid>

          <div
            style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
          >
            <CustomButton
              color={'buttonPrimary'}
              type="submit"
              loading={loading}
            >
              Save
            </CustomButton>
          </div>
        </Box>

      </CustomBox>
    </>

  );
}

export default AboutUS;