export const UserData = {
  tabs: [
    {
      label: 'All',
      id: 1,
    },
    {
      label: 'Buyer User',
      id: 2,
    },
    {
      label: 'Temporary User',
      id: 3,
    }
  ],
};

export const UserDataOne = {
  tabs: [
    {
      label: 'User Detail',
      id: 1,
    },
    {
      label: 'WishList',
      id: 2,
    },
    {
      label: 'Cart',
      id: 3,
    },
    {
      label: 'Outstanding Payment Detail',
      id: 4,
    },
    {
      label: 'IVS Order',
      id: 5,
    },
    {
      label: 'Download History',
      id: 6,
    },
    {
      label: 'Privillege Download History',
      id: 7
    }
  ],
};

export const AdminUserData = {
  tabs: [
    {
      label: 'All Admins',
      id: 1,
    }
  ],
};


export const UserIdentityData = [
  {
    label: 'Select Identity',
    value: ''
  },
  {
    label: 'Buyer',
    value: 'Buyer',
  },
  {
    label: 'Confirmed',
    value: 'Confirmed',
  },
  {
    label: 'International',
    value: 'International',
  },
  {
    label: 'Photographer',
    value: 'Photographer',
  },
  {
    label: 'Student',
    value: 'Student',
  },
  {
    label: 'Unconfirmed',
    value: 'Unconfirmed',
  },
  {
    label: 'Photographer by us',
    value: 'Photographer by us',
  },
  {
    label: 'Student by us',
    value: 'Student by us',
  },
  {
    label: 'Model',
    value: 'Model',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
export const SearchIVSDownloadBy = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Order ID',
    value: 'order_id',
  },
  {
    label: 'User ID',
    value: 'user_name',
  },
  {
    label: 'Image Name',
    value: 'image_name',
  },
];
export const SearchBy = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Order ID',
    value: 'order_id',
  },
  {
    label: 'User ID',
    value: 'user_id',
  },
  {
    label: 'User Name',
    value: 'user_name',
  },
  {
    label: 'Invoice ID',
    value: 'invoice_id',
  },
  {
    label: 'Image Name',
    value: 'image_name',
  },
  {
    label: 'Purchase Order',
    value: 'purchase_order',
  },
  {
    label: 'GST Number',
    value: 'gst_number',
  },
  {
    label: 'Payment Type',
    value: 'payment_type',
  }
];
export const SearchUserBy = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Company Name',
    value: 'company_name',
  },
  {
    label: 'First Name',
    value: 'first_name',
  },
  {
    label: 'Phone Number',
    value: 'mobile',
  },
  {
    label: 'Email',
    value: 'email',
  },
];
export const SearchImageBy = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Image Name',
    value: 'image_name',
  },
  {
    label: 'Status',
    value: 'status',
  },
  {
    label: 'Comment',
    value: 'comment',
  },
];
export const SearchFolderBy = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Folder Name',
    value: 'folder_name',
  },
];
export const SearchByConfirmed = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Order ID',
    value: 'order_id',
  },
  {
    label: 'Email',
    value: 'user_name',
  },
  {
    label: 'Invoice ID',
    value: 'invoice_id',
  },
  {
    label: 'GST Number',
    value: 'gst_number',
  },
  {
    label: 'Payment Mode',
    value: 'payment_type',
  },
];

export const SearchByPending = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Order ID',
    value: 'order_id',
  },
  // {
  //   label: 'User Id',
  //   value: 'user_id',
  // },
  {
    label: 'Email',
    value: 'user_name',
  },
  {
    label: 'GST Number',
    value: 'gst_number',
  },
  {
    label: 'Payment Mode',
    value: 'payment_type',
  }
];
export const SearchByBill = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Bill To',
    value: 'client',
  },
];

export const SearchInCart = [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'Image Name',
    value: 'image',
  },
  {
    label: 'Email',
    value: 'user_name',
  },
 
];









