import { Box, InputAdornment, Typography, debounce } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Search } from '@material-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiConfig, { BASE_IMAGE_URL } from '../../config/ApiConfig';
import { crypt, getNewParameters, getParameters } from '../../lib/helper';
import {
  AutoSuggestForExclusiveImage,
  searchExclusiveImageByName,
  searchExclusiveImageResult,
} from '../../Services/imageApis';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomLoading from '../CommonComp/CustomLoading/CustomLoading';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import ShareLinkModal from '../Modals/ShareLinkModal';
const SEARCH_LIMIT = 50;
function ExclusiveImages() {
  const [sliderNumber, setSliderNumber] = useState(0);
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [finalData, setFinalData] = useState([]);
  const [exclusiveImageLink, setExclusiveImageLink] = useState();
  const [openShareLinkDialog, setOpenShareLinkDialog] = useState(false);
  const [mainSearchResultData, setMainSearchResultData] = useState([]);
  const [cursor, setCursor] = useState(-1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [autoComplete, setAutoComplete] = useState([]);
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleCloseDialog = () => {
    setOpenShareLinkDialog(false);
  };
  const ScrollGallery = React.memo(
    ({ finalData, handleInformationImage, makeImageUrl, setHoveredImage }) => {
      return (
        <div className="custom-gallery">
          {finalData.length
            ? finalData?.map((item, index) => {
              return (
                <div
                  key={index.toString()}
                  className={`${item?._source.alignment == 'V'
                    ? 'imageItem-Vertical'
                    : item?._source.alignment == 'S'
                      ? 'imageItem-square'
                      : 'imageItem-Horizontal'
                    } pie-menu-main-div`}
                  onMouseOver={() => {
                    setHoveredImage(item?._source?.imagename);
                  }}
                >
                  <img
                    onClick={() => handleInformationImage(item)}
                    className="w-100"
                    src={makeImageUrl(item)}
                    alt={item?._source?.imagename}
                    loading="lazy"
                  />
                  <div className="image-menu-main">
                    <Typography
                      variant="h5"
                      sx={{
                        mb: { md: 0, xs: 1 },
                        fontWeight: 500,
                        color: 'white',
                        padding: '8px 0',
                        textAlign: 'center',
                      }}
                    >
                      {item?._source?.imagename}
                    </Typography>
                  </div>
                </div>
              );
            })
            : null}
        </div>
      );
    },
  );
  const ImageGallery = ({ finalData, fetchData, hasMore, isLoadingImages }) => {
    const [hoveredImage, setHoveredImage] = useState(null);
    const makeImageUrl = (imageData) => {
      if (imageData) {
        const dataOfImage = imageData?._source?.url?.split('/');
        return `${BASE_IMAGE_URL}${dataOfImage[0]}/${dataOfImage[1]}-${dataOfImage[2]}.jpg`;
      }
    };
    const handleInformationImage = (item) => { };
    return (
      <>
        <Box sx={{ marginTop: '20px' }}>
          {finalData.length > 0 ? (
            <InfiniteScroll
              dataLength={finalData.length * 10}
              next={fetchData}
              hasMore={hasMore}
              loader={
                <h4
                  style={{
                    textAlign: 'center',
                    paddingTop: '10px',
                    color: 'black',
                  }}
                >
                  Loading...
                </h4>
              }
              endMessage={
                <h4
                  style={{
                    textAlign: 'center',
                    paddingTop: '30px',
                    color: 'black',
                  }}
                >
                  {'You got all data'}
                </h4>
              }
              scrollThreshold={0.65}
            >
              <ScrollGallery
                finalData={finalData}
                handleInformationImage={handleInformationImage}
                makeImageUrl={makeImageUrl}
                hoveredImage={hoveredImage}
                setHoveredImage={setHoveredImage}
              />
            </InfiniteScroll>
          ) : (
            <CustomLoading
              loading={isLoadingImages}
              loadingStyle={{ marginTop: 30 }}
              loadingFinishText={'No images found'}
            />
          )}
        </Box>
      </>
    );
  };
  const searchImageResultList = async (finalDataArr, pageNumber) => {
    try {
      let response = {};
      let isValid = false;
      let regex = '^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$';
      let regexForStringCheck = '^[a-zA-Z][a-zA-Z][0-9]{3,}$';

      let searchStr = searchText?.trim()?.split(' ');

      searchStr?.every(validateStr);
      function validateStr(item) {
        let check = item?.match(regex);
        let checkStr = item?.match(regexForStringCheck);
        if (item && checkStr) isValid = check ? true : false;
        return check ? true : false;
      }
      setIsLoadingImages(true);

      if (isValid) {
        response = await searchExclusiveImageByName(searchText);
      } else {
        response = await searchExclusiveImageResult(
          searchText,
          SEARCH_LIMIT,
          pageNumber,
        );
      }
      if (response?.status === 200) {
        setSearchPageNumber(pageNumber + 1);
        setMainSearchResultData(response?.data?.hits);
        if (isValid) {
          setFinalData([...response?.data?.hits?.hits]);
        } else {
          setFinalData([...finalDataArr, ...response?.data?.hits?.hits]);
        }
      }
      if (
        response?.data?.hits?.total?.value === finalDataArr.length ||
        response?.data?.hits?.total?.value <= SEARCH_LIMIT
      ) {
        setHasMore(false);
      } else {
        let documentHeight = document.body.scrollHeight;
        let currentScroll = window.scrollY + window.innerHeight
        let modifier = 200;
        if (currentScroll + modifier > documentHeight) {
          window.scrollTo(0, window.pageYOffset - 200);
        }
        setHasMore(true);
      }
      setIsLoadingImages(false);
    } catch (error) {
      setIsLoadingImages(false);
    }
  };

  useEffect(() => {
    searchImageResultList([], 1);
    setFinalData([]);
    setSearchPageNumber(1);
  }, []);
  const makeImageUrl = (imageData) => {
    const dataOfImage = imageData?._source?.url?.split('/');
    return `${BASE_IMAGE_URL}${dataOfImage[0]}/${dataOfImage[1]}-${dataOfImage[2]}.jpg`;
  };
  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);
  const fetchData = () => {
    if (
      allParams?.searchKeyword !== undefined &&
      isLoadingImages === false &&
      finalData.length > 0
    ) {
      searchImageResultList(finalData, searchPageNumber);
    }
  };
  useEffect(() => {
    document.onclick = (args) => {
      if (autoComplete) {
        setShowAutoComplete(false);
      }
    };
  }, []);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
    }
    if (data?.parameters) {
      setSearchText(data?.parameters?.searchKeyword);
      searchImageResultList([], 1);
    }
  }, [allParams]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 38 && cursor > 0) {
      setCursor(cursor - 1);
    } else if (e.keyCode === 40 && cursor < autoComplete.length - 1) {
      setCursor(cursor + 1);
    }
    if (e.keyCode == 13) {
      setShowAutoComplete(false);
      if (autoComplete[cursor]) {
        setSearchText(autoComplete[cursor]?.displayKeyword);
        setAllParams({
          ...allParams,
          searchKeyword: autoComplete[cursor]?.displayKeyword,
        });
      } else {
        setAllParams({
          ...allParams,
          searchKeyword: searchText,
        });
      }
    }
  };
  const prevSlide = () => {
    sliderNumber === 0
      ? setSliderNumber(finalData.length - 1)
      : setSliderNumber(sliderNumber - 1);
  };
  const nextSlide = () => {
    sliderNumber + 1 === finalData.length
      ? setSliderNumber(0)
      : setSliderNumber(sliderNumber + 1);
  };
  return (
    <>
      <CustomBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'visible',
          gap: '2rem',
          '@media only screen and (min-width: 280px) and (max-width: 1256px)': {
            flexDirection: 'column',
            alignItems: 'start',
            gap: '1rem',
          },
        }}
      >
        <Box
          sx={{
            paddingTop: 2,
            paddingBottom: 2,
            width: '100%',
            display: 'flex',
            maxWidth: 700,
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <CustomInput
              variant="outlined"
              fullWidth
              placeholder="Search"
              sx={(theme) => ({
                width: '100%',
                maxWidth: 700,
                margin: '0 auto',
                bgcolor: '#E5F5FF',
                borderRadius: theme.shape.borderRadius(4),
                '& fieldset': { border: 'none' },
                [theme.breakpoints.down('md')]: {
                  flex: 1,
                  maxWidth: '100%',
                  width: '100%',
                  margin: '0 auto',
                },
              })}
              id="searchForExclusiveImage"
              value={searchText}
              autoComplete="off"
              onFocus={(event) => {
                if (searchText && searchText.length > 0) {
                  let callSearch = debounce(async function () {
                    let res = await AutoSuggestForExclusiveImage(searchText);
                    setAutoComplete(res?.rows);
                    setShowAutoComplete(true);
                  }, 150);
                  callSearch();
                } else {
                  setAutoComplete([]);
                }
              }}
              onKeyDown={handleKeyDown}
              onChange={(event) => {
                setSearchText(event.target.value);
                if (event.target.value && event.target.value.length > 0) {
                  let callSearch = debounce(async function () {
                    let res = await AutoSuggestForExclusiveImage(
                      event.target.value,
                    );
                    setAutoComplete(res?.rows);
                    setShowAutoComplete(true);
                  }, 150);
                  callSearch();
                } else {
                  setAutoComplete([]);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position={'end'}
                    style={{ cursor: 'pointer', marginTop: '6px' }}
                  >
                    <span
                      onClick={() => {
                        let element = document.getElementById(
                          'searchForExclusiveImage',
                        );
                        if (element) {
                          setSearchText(element?.value?.trim());
                          setShowAutoComplete(false);
                          setAllParams({
                            ...allParams,
                            searchKeyword: element?.value?.trim(),
                          });
                          searchImageResultList([], 1);
                        }
                      }}
                    >
                      <Search />
                    </span>
                  </InputAdornment>
                ),
              }}
            />
            {autoComplete?.length && showAutoComplete ? (
              <Box
                className="autocomplete"
                sx={{ maxWidth: 700, position: 'absolute', zIndex: 1 }}
              >
                {autoComplete?.map((item, index) => {
                  return (
                    <Box
                      className={`autocomplete_inner ${cursor === index ? 'active' : ''
                        }`}
                      key={Math.random().toString()}
                      onClick={async () => {
                        setSearchText(item?.displayKeyword);
                        setShowAutoComplete(false);
                        setAllParams({
                          ...allParams,
                          searchKeyword: item?.displayKeyword,
                        });
                      }}
                    >
                      {item?.displayKeyword}
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </Box>
        </Box>
        <CustomButton
          color={'buttonSecondary'}
          onClick={() => {
            setOpenShareLinkDialog(true);
            const exclusiveImageLink = `${ApiConfig?.LIVE_WEBSITE_URL
              }/exclusiveSearchResults/${crypt(ApiConfig.REACT_APP_SALT +
                'searchKeyword',
                allParams?.searchKeyword,
              )}`;
            setExclusiveImageLink(exclusiveImageLink);
          }}
          disabled={finalData && finalData?.length > 0 ? false : true}
        >
          Share URL
        </CustomButton>
      </CustomBox>
      {finalData && finalData?.length > 0 && (
        <Box sx={{ margin: '3rem 0', textAlign: 'center' }}>
          <Typography
            variant="h3"
            sx={{ mb: { md: 0, xs: 1 }, fontWeight: 600 }}
          >
            {`${mainSearchResultData?.total === undefined
              ? 'Total'
              : mainSearchResultData?.total?.value >= 10000
                ? '1,00,000' + '+'
                : mainSearchResultData?.total?.value
              } images for "${allParams?.searchKeyword}"`}
          </Typography>
        </Box>
      )}
      <div>
        <Box
          sx={{
            '@media only screen and (min-width: 280px) and (max-width: 749px)':
            {
              margin: '0px -15px',
            },
          }}
        >
          <ImageGallery
            finalData={finalData}
            fetchData={fetchData}
            hasMore={hasMore}
            imageURL={makeImageUrl}
            isLoadingImages={isLoadingImages}
            sliderNumber={sliderNumber}
            prevSlide={prevSlide}
            nextSlide={nextSlide}
          />
        </Box>
      </div>
      <ShareLinkModal
        open={openShareLinkDialog}
        exclusiveImageLink={exclusiveImageLink}
        onClose={handleCloseDialog}
      />
    </>
  );
}

export default ExclusiveImages;
