import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiConfig from '../../config/ApiConfig';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { imageListingItemsSelector } from '../../redux/image';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { soldImagesByGroupListings } from '../../Services/imageApis';
import MatTable from '../Tables/MatTable';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Group ID',
    id: 'group_id',
    format: (columnData) => (
      <span
        style={{ 
          display: 'inline-block', whiteSpace: 'nowrap', fontWeight: 600,
          ...(columnData >= 0 && columnData != null ? {borderBottom: '1px solid', cursor: 'pointer'}: {}) }}
        onClick={() => {
          if(columnData >=0 && columnData != null){
            customHistory.push(`image/soldImagesByGroupId/${columnData}`);
          }
        }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Images',
    id: 'images',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  }
];

function ImagesGroup() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, listOfSoldImagesByGroup } = useSelector(imageListingItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // order_status: String(1),
      ...allParams,
      ...params,
    };
    dispatch(soldImagesByGroupListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  //making data format for table input
  useEffect(() => {
    if (listOfSoldImagesByGroup?.items?.elements?.length >= 0) {
      setTableListing(listOfSoldImagesByGroup?.items?.elements);
    }
  }, [listOfSoldImagesByGroup.items]);

  return (
    <>
      <MatTable
        loading={listOfSoldImagesByGroup?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        page={page}
        perPage={limit}
        total={listOfSoldImagesByGroup?.items?.totalcount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        disableSearch={true}
      />
    </>
  );
}

export default ImagesGroup;
