import { Mail } from '@material-ui/icons';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiConfig from '../../config/ApiConfig';
import { FiltersData } from '../../data/filtersData';
import { crypt, downloadBlobXLSFile, findObjectInArray, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { commonItemsSelector } from '../../redux/common';
import { orderListingItemsSelector } from '../../redux/orderListing';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { getIvsOrderListing, getIvsOrderListingExportFile } from '../../Services/OrderApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';

const column = [
  {
    label: 'Order ID',
    id: 'order_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", 
  }}
    > {columnData}</span>)
  },
  {
    label: 'Plan Name',
    id: 'subscription_plan_name',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}> {columnData}</span>)
  },
  {
    label: 'Email',
    id: 'user_name',
    format: (columnData) => (
        <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Order Status',
    id: 'order_status',
    format: (columnData, rowIndex, formatCallback, arr, errors, row) => (
      <>
        <Typography
          variant="span"
          sx={{
            color:
              columnData == 1
                ? 'success.main'
                : columnData == '2'
                ? 'danger.main'
                : columnData == '0'
                ? 'info.main'
                : 'N/A',
            width: 90,
            display: 'inline-block',
            textAlign: "center"
          }}
        >
          {columnData == 1 ? 'C' : columnData == '2' ? 'R' : columnData == '0' ? 'P' : 'N/A'}
        </Typography>
      </>
    ),
  },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (
        <span style={{ display: 'inline-block', width: 'max-content', maxWidth:200, wordBreak: 'break-word'}}>{columnData}</span>
    )
  },
  {
    label: 'Validity',
    id: 'date',
    format: (columnData) => (<span style={{ display: 'inline-block'}}>
     {/* {columnData || 'N/A'} */}
      <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}><Typography variant='span' className='fontWeight600'>Created At:</Typography> {formatDate(columnData?.created_at)}</span>
      <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}><Typography variant='span' className='fontWeight600'>Confirmed On:</Typography> {formatDate(columnData?.confirmed_on)}</span>
      <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}><Typography variant='span' className='fontWeight600'>Expiry:</Typography> {formatDate(columnData?.expired_on)}</span>
    </span>)
  },
  {
    label: 'Download Info',
    id: 'download_info',
    format: (columnData) => (<span style={{ display: 'inline-block'}}>
     {/* {columnData || 'N/A'} */}
       <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}><Typography variant='span' className='fontWeight600'>Total Download:</Typography> {columnData?.total_images}</span>
       <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}><Typography variant='span' className='fontWeight600'>Downloaded:</Typography> {columnData?.downloaded}</span>
       <span style={{ display: 'inline-block', whiteSpace: "nowrap"}}><Typography variant='span' className='fontWeight600'>Remaining Download:</Typography> {columnData?.remaining_images}</span>
     </span>)
  },
  {
    label: 'Remaining Days',
    id: 'days',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}> {columnData || 'Expired'}</span>)
  },
  {
    label: 'Comment',
    id: 'comment',
    format: (columnData) => (<span style={{display: 'inline-block', maxWidth: 300, width: 'max-content', wordBreak: "break-word", maxHeight: 250, overflow: 'auto' }}> {columnData || '-'}</span>)
  },
  {
    label: 'Closed By',
    id: 'closed_by',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || "-"}</span>)
  },
];

function IvsOrderListingNew() {
  const [tableListing, setTableListing] = useState([])
  const [expLoading, setExpLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [allParams, setAllParams] = useState(getParameters());
  const { ivsOrderListing } = useSelector(orderListingItemsSelector);
  const { appSetting } = useSelector(commonItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const getListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        ...allParams,
        ...params
    }
    dispatch(getIvsOrderListing(payload))
    setPage(Number(payload.page))
  }

  const getExportFile = async (params={}) => {
    try{

      let payload = {
        ...allParams,
        ...params
      }
      delete payload.page
      delete payload.limit
      
      setExpLoading(true)
      let resp = await getIvsOrderListingExportFile(payload)
      if(resp){
        downloadBlobXLSFile(resp?.data, `IVSOrdersList`)
      }else{
        toast.error('Something went wrong.')
      }
      setExpLoading(false)
    }catch(e){
      console.log(e)
      setExpLoading(false)
    }
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  //making data format for table input
  useEffect(() => {
    if(ivsOrderListing?.items?.rows?.length >= 0){
      let data = ivsOrderListing?.items?.rows?.map((item)=>{
        let remainDays = new Date(item?.expired_on)?.getTime() - new Date()?.getTime();
        let TotalDays = Math.ceil(remainDays / (1000 * 3600 * 24));
        return {
          ivs_order_id: item?.ivs_order_id,
          order_id: item?.order_id,
          plan_id: item?.plan_id,
          user_name: item?.user_name,
          date: {
            confirmed_on: item?.confirmed_on,
            expired_on: item?.expired_on,
            created_at: item?.created_at,
          },
          days: TotalDays>0?TotalDays:0,
          download_info: {
            total_images: item?.total_images,
            downloaded: item?.total_images - item?.remaining_images,
            remaining_images: item?.remaining_images,
          }, 
          plan_type: item?.plan_type,
          subscription_plan: item?.subscription_plan,
          subscription_plan_name: item?.subscription_plan?.plan_name,
          closed_by: item?.grouping_manage?.closed_by,
          comment: item?.order?.comment,
          company_name: item?.grouping_manage?.company_name,
          order_status: item?.order?.order_status,
        }
      })
      setTableListing(data)
    }
  }, [ivsOrderListing.items]);
  
  const generateFilters = (arr)=>{
    try{
      let data = findObjectInArray(arr, 'key_name', 'remaining_days') 
      if(data){
        let mainData = data?.key_value?.split(",")?.map((item)=>{
          if(item > 1){
            return {
              value: item,
              label: `${item} days`,
            }
          }else{
            return {
              value: item,
              label: `${item} day`,
            }
          }
        })
        return mainData
      }
      return []
    }catch(e){
      return []
    }
  }


  const actionBody = (data) => {
    
    return (
      <>
        <div style={{display: "flex"}}>
          <Link to={`expiryEmail?email=${crypt(ApiConfig.REACT_APP_SALT + 'email', data?.user_name)}&orderId=${crypt(ApiConfig.REACT_APP_SALT + 'order_id', data?.order_id)}&planId=${crypt(ApiConfig.REACT_APP_SALT + 'plan_id', data?.plan_id)}&expiry=${crypt(ApiConfig.REACT_APP_SALT + 'expiry', data?.date?.expired_on)}&coupon_name=${crypt(ApiConfig.REACT_APP_SALT + 'coupon_name', data?.subscription_plan?.coupon_name)}&coupon_amount=${crypt(ApiConfig.REACT_APP_SALT + 'coupon_amount', data?.subscription_plan?.coupon_amount)}`}>
            <CustomButton toolTipTittle={"Expiry Email"} className="buttonDense noMinWidth" sx={{ bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Mail/></CustomButton>
          </Link>
        </div>
      </>
    );
  };

  return (
    <>
      <MatTable
        loading={ivsOrderListing?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date', 'closed_by', 'remaining_days'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date', 'closed_by', 'remaining_days'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        showPageLimit={true}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        // setFilters={setFilters}
        // filters={filters}
        total={ivsOrderListing?.items?.count || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        toolBarButton={()=>{
          return <CustomButton sx={(theme)=>({ fontSize: '12px', whiteSpace: "nowrap", bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText',
            [theme.breakpoints.down("md")]: {
              flex: 1,
            }
           })} onClick={()=>{
            getExportFile()
          }} loading={expLoading}>Export XLS</CustomButton>
        }}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
        dropdownFilter={true}
        selectFilterArray={[
          {
            name: 'closed_by',
            label: 'Closed By',
            placeholder: 'Closed By',
            options: FiltersData?.closedBy
          },
          {
            name: 'remaining_days',
            label: 'Remaining Days',
            placeholder: 'Remaining Days',
            options: [...FiltersData?.remainingDays, ...generateFilters(appSetting?.items?.features?.cms)]
          },

        ]}
      />
    </>
  );
}

export default IvsOrderListingNew;
