import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

export default function RadioField({helperText, label, error, radioGroupValue, handleChange, radioData, variant, formControlStyle, ...props}) {
  return (
    <FormControl sx={{ my: 0, ...formControlStyle }} error={error} variant={variant}>
        {/* {!!label && <FormLabel id={props?.id || "radioBox"} >{label}</FormLabel>} */}
        {!!label && <Typography id={props?.id || "radioBox"} >{label}</Typography>}
        <RadioGroup
            aria-labelledby={props?.id || "radioBox"}
            name="formRadio"
            onChange={handleChange}
            {...props}
        >
            {radioData?.map((item, index)=>{
                return <FormControlLabel key={index.toString()} value={item?.value} control={<Radio />} label={item?.radioLable} />
            })}
        </RadioGroup>
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

RadioField.defaultProps = {
    helperText: '',
    error: false,
    handleChange: ()=>{},
    radioData: [],
    variant: "standard",
    formControlStyle: {},
}