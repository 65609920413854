import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';
import ImageTabBody from '../../Components/Image/ImageTabBody';
import { ImageData } from '../../data/imageData';
import ImageDetailPage from '../../Components/Image/ImageDetailPage';

function ImageDetail({ handleNavParams }) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  };
  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <ImageDetailPage/>
    </Box>
  );
}

export default ImageDetail;
