import React, { useState } from 'react'
import MainLayout from '../Layouts/MainLayout'
import StarIndustries from './StarIndustries'

function StarIndustriesContainer() {
  return (
    <MainLayout navTitle={"Star Industries"} navSubTitle={"Welcome to ImagesBazaar"}>
      <StarIndustries/>
    </MainLayout>
  )
}

export default StarIndustriesContainer