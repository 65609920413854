import { Grid } from '@mui/material';
import React from 'react';
import CustomInput from '../CommonComp/CustomInput/CustomInput';

function AddImagesFieldsBack({ element, index, handleChange, removeFormFields }) {
  return (
    <div className="form-inline" key={index}>
      <Grid container spacing={2} mt={0}>
        <Grid item xs={12} sm={4} md={2}>
          <CustomInput
            variant="outlined"
            fullWidth
            name="image_id"
            label="Image Id"
            placeholder="Image Id"
            value={element.image_id}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            inputProps={{
              style:{
                padding: "8px 10px",
                fontSize: 14
              }
            }}
            onChange={(e) => handleChange(index, e)}
            // error={
            //   formik.touched.image_name && Boolean(formik.errors.image_name)
            // }
            // helperText={formik.touched.image_name && formik.errors.image_name}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <CustomInput
            variant="outlined"
            fullWidth
            name="rank"
            label="Rank"
            placeholder="Rank"
            value={element.rank}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            inputProps={{
              style:{
                padding: "8px 10px",
                fontSize: 14
              }
            }}
            onChange={(e) => handleChange(index, e)}
            // error={
            //   formik.touched.image_name && Boolean(formik.errors.image_name)
            // }
            // helperText={formik.touched.image_name && formik.errors.image_name}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <CustomInput
            variant="outlined"
            fullWidth
            name="group"
            label="Group"
            placeholder="Group"
            value={element.group}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            inputProps={{
              style:{
                padding: "8px 10px",
                fontSize: 14
              }
            }}
            onChange={(e) => handleChange(index, e)}
            // error={
            //   formik.touched.image_name && Boolean(formik.errors.image_name)
            // }
            // helperText={formik.touched.image_name && formik.errors.image_name}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <CustomInput
            variant="outlined"
            fullWidth
            name="keyword"
            label="Keyword"
            placeholder="Keyword"
            value={element.keyword}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            inputProps={{
              style:{
                padding: "8px 10px",
                fontSize: 14
              }
            }}
            onChange={(e) => handleChange(index, e)}
            // error={
            //   formik.touched.image_name && Boolean(formik.errors.image_name)
            // }
            // helperText={formik.touched.image_name && formik.errors.image_name}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <CustomInput
            variant="outlined"
            fullWidth
            name="pricing"
            label="Pricing"
            placeholder="Pricing"
            value={element.pricing}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            inputProps={{
              style:{
                padding: "8px 10px",
                fontSize: 14
              }
            }}
            onChange={(e) => handleChange(index, e)}
            // error={
            //   formik.touched.image_name && Boolean(formik.errors.image_name)
            // }
            // helperText={formik.touched.image_name && formik.errors.image_name}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <CustomInput
            variant="outlined"
            fullWidth
            name="exclusive_and_non_exclusive"
            label="Exclusive & Non-exclusive"
            placeholder="Exclusive & Non-exclusive"
            value={element.exclusive_and_non_exclusive}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            inputProps={{
              style:{
                padding: "8px 10px",
                fontSize: 14
              }
            }}
            onChange={(e) => handleChange(index, e)}
            // error={
            //   formik.touched.image_name && Boolean(formik.errors.image_name)
            // }
            // helperText={formik.touched.image_name && formik.errors.image_name}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <CustomInput
            variant="outlined"
            fullWidth
            name="agency_name"
            label="Agency Name"
            placeholder="Agency Name"
            value={element.agency_name}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            inputProps={{
              style:{
                padding: "8px 10px",
                fontSize: 14
              }
            }}
            onChange={(e) => handleChange(index, e)}
            // error={
            //   formik.touched.image_name && Boolean(formik.errors.image_name)
            // }
            // helperText={formik.touched.image_name && formik.errors.image_name}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <CustomInput
            variant="outlined"
            fullWidth
            name="noc"
            label="NOC"
            placeholder="NOC"
            value={element.noc}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            inputProps={{
              style:{
                padding: "8px 10px",
                fontSize: 14
              }
            }}
            onChange={(e) => handleChange(index, e)}
            // error={
            //   formik.touched.image_name && Boolean(formik.errors.image_name)
            // }
            // helperText={formik.touched.image_name && formik.errors.image_name}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <CustomInput
            variant="outlined"
            fullWidth
            name="type"
            label="Type"
            placeholder="Type"
            value={element.type}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
            inputProps={{
              style:{
                padding: "8px 10px",
                fontSize: 14
              }
            }}
            onChange={(e) => handleChange(index, e)}
            // error={
            //   formik.touched.image_name && Boolean(formik.errors.image_name)
            // }
            // helperText={formik.touched.image_name && formik.errors.image_name}
          />
        </Grid>
      </Grid>
      {index >= 0 ? (
        <button
          type="button"
          className="button remove"
          onClick={() => removeFormFields(index)}
        >
          Remove
        </button>
      ) : null}
    </div>
  );
}
export default AddImagesFieldsBack;
