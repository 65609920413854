import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { formatDate } from '../../lib/helper';
import { createUserFollowUps } from '../../Services/CRMFollowups';
import { useSelector } from 'react-redux';
import { loginItemsSelector } from '../../redux/login';

function CreateUserFollowUps({ open, handleOpen, getListing, selectedData }) {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(loginItemsSelector);
  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    const payloadData = {
      user_id: values?.user_id,
      followup_status: values?.status,
      next_followup_date: values?.nextFollowUpsDate,
      heading: values?.heading,
      description: values?.description,
      created_by: values?.createdBy,
    };
    let resp = await createUserFollowUps(payloadData);
    if (resp) {
      resetForm({
        email: '',
        status: '',
        nextFollowUpsDate: '',
        heading: '',
        description: '',
        createdBy: '',
      });
      getListing();
      handleOpen(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: selectedData?.email || '',
      user_id: selectedData?.user_id || '',
      status: '',
      nextFollowUpsDate: formatDate(new Date(), 'yyyy-mm-dd') || '',
      heading: '',
      description: '',
      createdBy: user
        ? `${user?.userDetails?.first_name} ${user?.userDetails?.last_name}`
        : 'admin',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter Email')
        .required('Please enter Email'),
      status: Yup.string().required('Status is required'),
      nextFollowUpsDate: Yup.string().required(
        'Next Followups Date is requried.',
      ),
      heading: Yup.string().required('Heading is required'),
      description: Yup.string().required('Description is required'),
      createdBy: Yup.string().required('Created By is required'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={open}
        onClose={() => {
          handleOpen(false);
        }}
      >
        <DialogTitle variant="h3">Follow Up</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="email"
                  label="Email"
                  placeholder="Email"
                  disabled
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="status"
                  label="Status"
                  placeholder="Status"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                >
                  <option key={'Select Status'} value={''}>
                    Select Status
                  </option>
                  <option key="done" value="done">
                    Done
                  </option>
                  <option key="not-reachable" value="not-reachable">
                    Not Reachable
                  </option>
                </CustomInput>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  name="nextFollowUpsDate"
                  type="date"
                  label="Next Follow Ups Date"
                  placeholder="Next Follow Ups Date"
                  value={formatDate(
                    formik.values.nextFollowUpsDate,
                    'yyyy-mm-dd',
                  )} //2017-06-13T13:00
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  error={
                    formik.touched.nextFollowUpsDate &&
                    Boolean(formik.errors.nextFollowUpsDate)
                  }
                  helperText={
                    formik.touched.nextFollowUpsDate &&
                    formik.errors.nextFollowUpsDate
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="heading"
                  label="Heading"
                  placeholder="Heading"
                  value={formik.values.heading}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.heading && Boolean(formik.errors.heading)
                  }
                  helperText={formik.touched.heading && formik.errors.heading}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="description"
                  label="Description"
                  placeholder="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Confirm
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    status: '',
                    nextFollowUpsDate: '',
                    heading: '',
                    description: '',
                    createdBy: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateUserFollowUps;
