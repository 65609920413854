export const FiltersData = {
  closedBy: [
    {
      value: '',
      label: 'Select Closed By',
    },
    {
      value: 'CS',
      label: 'CS',
    },
    {
      value: 'Sales',
      label: 'Sales',
    },
    {
      value: 'Exclude',
      label: 'Exclude',
    },
    {
      value: 'Special package',
      label: 'Special package',
    },
    {
      value: 'Renew',
      label: 'Renew',
    },
    {
      value: 'Sales-Renew',
      label: 'Sales-Renew',
    },
    {
      value: 'Legal',
      label: 'Legal',
    },
  ],
  remainingDays: [
    {
      value: '',
      label: 'Select Remaining Days',
    }
  ]
};


