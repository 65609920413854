import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  subscriptionList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },

  subscriptionStandardList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
 
  subscriptionSpecialList: {
    error: {
      isError: false,
      message: '',
    },
    items: {},
    loading: false,
  },
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setOrderSubscriptionListing: (state, action) => {
      state.subscriptionList.items = action.payload;
      state.subscriptionList.error = {
        isError: false,
        message: '',
      };
    },
    setOrderSubscriptionListingLoading: (state, action) => {
      state.subscriptionList.loading = action.payload;
    },
    setOrderSubscriptionError: (state, action) => {
      state.subscriptionList.items = {};
      state.subscriptionList.error = action.payload;
    },

  
    setStandardOrderSubscriptionListing: (state, action) => {
      state. subscriptionStandardList.items = action.payload;
      state. subscriptionStandardList.error = {
        isError: false,
        message: '',
      };
    },
    setStandardOrderSubscriptionListingLoading: (state, action) => {
      state. subscriptionStandardList.loading = action.payload;
    },
    setStandardOrderSubscriptionError: (state, action) => {
      state. subscriptionStandardList.items = {};
      state. subscriptionStandardList.error = action.payload;
    },

   
    setSpecialOrderSubscriptionListing: (state, action) => {
      state.subscriptionSpecialList.items = action.payload;
      state.subscriptionSpecialList.error = {
        isError: false,
        message: '',
      };
    },
    setSpecialOrderSubscriptionListingLoading: (state, action) => {
      state.subscriptionSpecialList.loading = action.payload;
    },
    setSpecialOrderSubscriptionError: (state, action) => {
      state.subscriptionSpecialList.items = {};
      state.subscriptionSpecialList.error = action.payload;
    },
  },
});

export default subscriptionSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setOrderSubscriptionListing,
  setOrderSubscriptionError,
  setOrderSubscriptionListingLoading,
  setStandardOrderSubscriptionListing,
  setStandardOrderSubscriptionListingLoading,
  setStandardOrderSubscriptionError,
  setSpecialOrderSubscriptionError,
  setSpecialOrderSubscriptionListingLoading,
  setSpecialOrderSubscriptionListing
} = subscriptionSlice.actions;

export const subscriptionItemsSelector = (state) => state.subscription;
