import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { currencyConversion, formatDate, getNewParameters, getObjectSubset, getParameters, makeUrlForNextPage, makeUrlForNextPageLocation, } from '../../lib/helper';
import { companyItemsSelector } from '../../redux/company';
import { companyEarningTotal, companyListings, deleteCompany } from '../../Services/companyApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import AllFilters from '../Order/AllFilters';
import { Grid, Typography } from '@mui/material';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'Nplan',
    id: 'nplan_orders',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <Link className='textDecoration-none link-text' target="_blank" to={`allOrders?${makeUrlForNextPageLocation(location?.search, 'nplan')}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => {
            localStorage.removeItem('order_type');
            localStorage.setItem("order_type", 'nplan');
          }}
        >
          {columnData}
        </Typography>
      </Link>
    )
  },
  {
    label: 'Plan',
    id: 'PlanTotal',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <Link className='textDecoration-none link-text' target="_blank" to={`allOrders?${makeUrlForNextPageLocation(location?.search, 'plan')}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => {
            localStorage.removeItem('order_type');
            localStorage.setItem("order_type", 'plan');
          }}
        >
          {columnData}
        </Typography>
      </Link>
    )
  },
  {
    label: 'IV',
    id: 'iv_orders',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <Link className='textDecoration-none link-text' target="_blank" to={`allOrders?${makeUrlForNextPageLocation(location?.search, 'iv')}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => {
            localStorage.removeItem('order_type');
            localStorage.setItem("order_type", 'iv');
          }}
        >
          {columnData}
        </Typography>
      </Link>
    )
  },
  {
    label: 'Total orders',
    id: 'total_orders',
    format: (columnData, rowIndex, formatCallback, options, errors, row) => (
      <Link className='textDecoration-none link-text' target="_blank" to={`allOrders?${makeUrlForNextPageLocation(location?.search, 'all')}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => {
            localStorage.removeItem('order_type');
            localStorage.setItem("order_type", 'all');
          }}
        >
          {columnData}
        </Typography>
      </Link>
    )
  },
];

function CompanyMasterTotalEarning() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, companyEarningList } = useSelector(companyItemsSelector);
  const dispatch = useDispatch();
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();

  const handleAdd = (value = false) => {
    setAddCompanyModal(value)
  }

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          return newState;
        });

      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });

      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },

    // {
    //   label: 'Search By',
    //   id: 'search_by',
    //   type: 'select',
    //   linkedWith: null,
    //   value: '',
    //   onChange: (e, index, value) => {
    //     setFiltersArray((prevState) => {
    //       let newState = [...prevState];
    //       newState[index].value = e.target.value;
    //       return newState;
    //     });
    //   },
    //   style: {},
    //   dataArr: SearchIVSDownloadBy,
    //   props: {},
    //   isVisible: true,
    // },
    // {
    //   label: 'Search Text',
    //   id: 'search',
    //   type: 'input',
    //   linkedWith: null,
    //   value: '',
    //   onChange: (e, index, value) => {
    //     setFiltersArray((prevState) => {
    //       let newState = [...prevState];
    //       newState[index].value = e?.target?.value?.trim();
    //       return newState;
    //     });
    //   },
    //   style: {},
    //   dataArr: [],
    //   props: {},
    //   isVisible: true,
    // },


  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    let isDate=false;
    if(dataWithValue?.start_date && dataWithValue?.end_date){
      localStorage.removeItem('start');
      localStorage.setItem("start",dataWithValue?.start_date );
      localStorage.removeItem('end');
      localStorage.setItem("end",dataWithValue?.end_date );
      isDate=true;
    }
    let paramsCopy = { ...allParams }
    if (allParams) {
      if (allParams?.start_date) {
        delete paramsCopy?.start_date;
      }
      if (allParams?.end_date) {
        delete paramsCopy?.end_date;
      }
    }
    setAllParams(paramsCopy);
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
      isDate
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
      ...allParams
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    let paramsCopy = { ...allParams }
    if (allParams) {
      if (allParams?.start_date) {
        delete paramsCopy?.start_date;
      }
      if (allParams?.end_date) {
        delete paramsCopy?.end_date;
      }
    }
    setAllParams(paramsCopy);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';
      return newState;
    });
    localStorage.removeItem('start');
    localStorage.removeItem('end');
    getListing();
  };
  const getListing = async (params = {},second,third) => {
    const payload = {
      page: String(1),
      limit: limit,
      status: String(1),
      order_status: String(1),
      ...allParams,
      ...params,
    };
    if (payload?.start_date) payload['start_date'] = formatDate(payload?.start_date, 'yyyy-mm-dd') + " 00:00:00";
    if (payload?.end_date) payload['end_date'] = formatDate(payload?.end_date, 'yyyy-mm-dd') + " 23:59:59";
    if (!payload?.end_date || !payload?.start_date) {
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[0].isVisible = true;
        newState[1].isVisible = true;
        newState[0].value = '';
        newState[1].value = '';
        return newState;
      });
    }
    dispatch(companyEarningTotal(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    handleClear();
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);


  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    if(allParams?.start_date && allParams?.end_date){
    }
    else
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (companyEarningList?.items?.[0]?.orders?.length >= 0) {
      let data = companyEarningList?.items?.[0]?.orders?.map((item) => {
        return {
          iv_orders: item?.iv_orders,
          nplan_orders: item?.nplan_orders,
          paid_amount: item?.paid_amount,
          total_amount: item?.total_amount,
          PlanTotal:item?.plan_orders,
          total_orders: item?.total_orders,
          unpaid_amount: item?.unpaid_amount
        };
      });
      setTableListing(data);
    }
  }, [companyEarningList.items]);


  return (
    <>
      <Grid container spacing={2} p={1.5} pt={3}>
        <Grid item xs={12} sm={4} md={4} pb={1}>
          
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            With Tax:
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Total Amount(INR): {companyEarningList?.items?.[0]?.orders?.[0]?.withTax_total_amount? currencyConversion(companyEarningList?.items?.[0]?.orders?.[0]?.withTax_total_amount) : 0}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Unpaid Amount(INR):  {companyEarningList?.items?.[0]?.orders?.[0]?.withTax_unpaid_amount ? currencyConversion(companyEarningList?.items?.[0]?.orders?.[0]?.withTax_unpaid_amount) : 0}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Paid Amount(INR):  {companyEarningList?.items?.[0]?.orders?.[0]?.withTax_paid_amount ? currencyConversion(companyEarningList?.items?.[0]?.orders?.[0]?.withTax_paid_amount) : 0}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} md={3} pb={1}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Without Tax:
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>

            Total Amount(INR): {companyEarningList?.items?.[0]?.orders?.[0]?.withoutTax_total_amount ? currencyConversion(companyEarningList?.items?.[0]?.orders?.[0]?.withoutTax_total_amount) : 0}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Unpaid Amount(INR):  {companyEarningList?.items?.[0]?.orders?.[0]?.withoutTax_unpaid_amount ? currencyConversion(companyEarningList?.items?.[0]?.orders?.[0]?.withoutTax_unpaid_amount) : 0}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Paid Amount(INR):  {companyEarningList?.items?.[0]?.orders?.[0]?.withoutTax_paid_amount? currencyConversion(companyEarningList?.items?.[0]?.orders?.[0]?.withoutTax_paid_amount) : 0}
          </Typography>
          
        </Grid>
        <MatTable
          loading={companyEarningList?.loading}
          showCheckbox={false}
          columns={column}
          data={tableListing}
          onApplyFilter={(data = {}) => {
            getListing({
              page: String(1),
              start_date: '',
              end_date: '',
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
            if (Object.keys(data).length > 0) {
              setAllParams({
                ...allParams,
                page: String(1),
                ...getObjectSubset(data, 'start_date', 'end_date'),
              });
            } else {
              setAllParams({
                page: String(1),
              });
            }
          }}
          page={page}
          perPage={limit}
          total={companyEarningList?.items?.totalCount || 0}
          setPage={setPage}
          handleSearch={(searchTerm) => {
            getListing({
              page: String(1),
              search: searchTerm,
            });
            setAllParams({
              ...allParams,
              page: String(1),
              search: searchTerm,
            });
          }}
          handlePageChange={(newPage) => {
            getListing({
              page: String(newPage),
            });
            setAllParams({
              ...allParams,
              page: String(newPage),
            });
          }}
          toolbarEnable={true}
          disableSearch
          filtersUrlData={allParams || {}}
          customFilter={() => (
            <AllFilters
              filtersArray={filtersArray}
              handleClick={handleFilter}
              handleClear={handleClear}

            />
          )}
        />


      </Grid>
    </>
  );
}

export default CompanyMasterTotalEarning;

