import React, { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import Divder from '../../Components/CommonComp/Divder';
import CustomBox from '../../Components/CommonComp/CustomBox/CustomBox';
import SubscriptionList from '../../Components/Subscription/SubscriptionList';
import SubscriptionTabBody from '../../Components/Subscription/subcriptionTabBody';
import { subscriptionData } from '../../data/subscriptionData';
import CustomTabs from '../../Components/CommonComp/CustomTabs/CustomTabs';

function Subscription({handleNavParams}) {
  const [tabValue, setTabValue] = useState(1);

  const handleTabs = (data) => {
    setTabValue(data.id);
    handleNavParams({
      navTitle: data.label,
    });
  }

  return (
    <Box
      component="main"
      sx={(theme) => {
        return {
          flexGrow: 1,
          // bgcolor: theme.backgroundColor.box.main,
          // p: 4,
          // pl: 0,
        };
      }}
    >
      <CustomBox padding={0}>
        <CustomTabs tabList={subscriptionData.tabs} tabClick={handleTabs} />
        {/* <SubscriptionList/> */}
       
      </CustomBox>
      <Divder spacing={1} />
      <SubscriptionTabBody activeTab={tabValue} />
      <Divder spacing={1.2} />
    </Box>
  );
}

export default Subscription;
