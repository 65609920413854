import { createSlice } from '@reduxjs/toolkit';

const initialState = {
 
  allMenus: []
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
   
    setAllMenuListing: (state, action) => {
      state.allMenus = action.payload;
     
    },
   
    
  },
});

export default menuSlice.reducer;

// Action creators are generated for each case reducer function
export const {

  setAllMenuListing,

} = menuSlice.actions;

export const menuListingItemsSelector = (state) => state.menuList;
