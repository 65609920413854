import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { userItemsSelector } from '../../redux/user';
import { customHistory } from '../../Route/CustomBrowserRouter';
import { buyerUserListing } from '../../Services/userApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'ID',
    id: 'user_id',
    format: (columnData) => (
      <Link className='textDecoration-none link-text' to={`userDetail/${columnData}`}>
        <Typography
          variant='span'
          style={{ whiteSpace: 'nowrap' }}
          // className={'link-text'}
          // onClick={() => {
          //   customHistory.push(`order/orderDetails/${columnData}`);
          // }}
        >
          {columnData}
        </Typography>
      </Link>
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'First Name',
    id: 'first_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Last Name',
    id: 'last_name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  // {
  //   label: 'Signup As',
  //   id: 'signup_as',
  //   format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content"}}>{columnData || 'N/A'}</span>)
  // },
  // {
  //   label: 'Job Description',
  //   id: 'job_description',
  //   format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content"}}>{columnData || 'N/A'}</span>)
  // },
  // {
  //   label: 'State',
  //   id: 'state',
  //   format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content"}}>{columnData || 'N/A'}</span>)
  // },
  {
    label: 'State',
    id: 'state',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || '-'}</span>)
  },
  // {
  //   label: 'Telephone',
  //   id: 'phone',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  // },
  {
    label: 'Mobile',
    id: 'mobile',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap", wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  // {
  //   label: 'Zip',
  //   id: 'zip_code',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  // },
  {
    label: 'Company Name',
    id: 'company_name',
    format: (columnData) => (<span style={{ display: 'inline-block',  maxWidth: 200, minWidth: 200, wordBreak: 'break-word'}}>{columnData || 'N/A'}</span>)
  },
  // {
  //   label: 'Group Company Name',
  //   id: 'company_group_name',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  // },
  // {
  //   label: 'GST Number',
  //   id: 'gst_number',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  // },
  // {
  //   label: 'ISD Number',
  //   id: 'isd_number',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  // },
  // {
  //   label: 'Discount',
  //   id: 'discount',
  //   format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData >= 0 ? columnData : 'N/A'}</span>)
  // },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (<Typography 
      variant='span'
      sx={{ ...(columnData == 1 ?{color: 'success.main'} : columnData == 2 ?{color: 'danger.main'} : columnData == 0 ? {color: 'info.main'} : {} ),
       whiteSpace: "nowrap",  display: 'inline-block' }}>
      {columnData == 0 ? 'Inactive' : columnData == 1 ? 'Active' : columnData == 2  ? 'Deleted' : 'N/A'}
    </Typography>)
  },
  {
    label: 'Joined At',
    id: 'created_at',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{formatDate(columnData, 'dd-mm-yyyy')}</span>)
  },
];

function BuyerUserList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, buyerUserList } = useSelector(userItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();

  const getListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        user_type: "2",
        ...allParams,
        ...params
    }
    dispatch(buyerUserListing(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  //making data format for table input
  useEffect(() => {
    if(buyerUserList?.items?.users?.length >= 0){
      // let data = buyerUserList?.items?.users?.map((item)=>{
      //   return {
      //     PG_ID: item.plan_name,
      //     plan_validity: item.plan_validity,
      //     created_at: item.created_at,
      //     plan_price: item.plan_price,
      //     plan_type: item.plan_type,
      //     shot: item.shot,
      //     plan_description: item.plan_description,
      //     maximum_downloads: item.maximum_downloads,
      //     discount_percentage: item.discount_percentage
      //   }
      // })
      setTableListing(buyerUserList?.items?.users)
    }
  }, [buyerUserList.items]);


  const actionBody = ({ data }) => {
    return (
      <>
        <div style={{display: "flex"}}>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '110px', bgcolor: 'buttonInfo.main' }}>USER DETIALS</CustomButton>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '135px' }}>VIEW FOLLOWUPS</CustomButton>
          <CustomButton sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', width: '150px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}>CREATE FOLLOWUPS</CustomButton>
          {/* <CustomButton variant="raised" sx={{ padding: '1px 1px', marginRight: 1, fontSize: '12px', bgcolor: '#fff0', minWidth: 20 }}><Delete/></CustomButton> */}
        </div>
      </>
    );
  };

  // const actionHead = (data) => {
  //   return (<div style={{display: "flex"}}>
  //      hi
  //   </div>);
  // };

  return (
    <>
      <MatTable
        loading={buyerUserList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        // action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={buyerUserList?.items?.totalCount || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
    </>
  );
}

export default BuyerUserList;
